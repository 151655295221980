import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {
  DropdownOptions,
  TableHeaderOptions,
  TableOptions,
} from "src/app/common/Models/ag-models";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
// import { RetailTaxesDataService } from "../../retail-taxes-data.service";
import { FromTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { CreateNewTaxTypeBusiness } from "../create-new-tax-type.business";
import { ActionMode, AlertType, ButtonType } from "src/app/common/enums/shared-enums";
import { TaxTypeBusiness } from "../../tax-type.business";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { UI as TaxTypeUI, TaxBased, TaxReferenceType, taxMaintanceCriteria } from '../../tax-type.model';
import { TaxType } from "src/app/common/shared/shared/setupConstants";

@Component({
  selector: "app-versa-tax-type",
  templateUrl: "./versa-tax-type.component.html",
  styleUrls: ["./versa-tax-type.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [CreateNewTaxTypeBusiness]
})
export class VersaTaxTypeComponent implements OnInit {
  // taxTypeInputs:any;
  @Input() taxTypeInputs;
  @Input() rangeMaintenanceTblContent: any;
  captions: any;
  currencySymbol: string;
  floatLabel: string;
  postTypeItems = [];
  selectedFromList = [];
  autoCompleteKeys: string[] = ["name"];
  selectedChipKey: string[] = ["name"];
  selectedNewChipKey: string[] = ["name"];
  isChipDisabled: boolean;
  searchPlaceholder: string = "";
  searchKey;
  versaTaxTypeForm;
  ageCategoryTableOptions: TableOptions;
  ageCategoryheaderOptions: TableHeaderOptions[];
  ageCategoryTableContent: taxMaintanceCriteria[] = [];
  ageCategoryCopyTableContent: taxMaintanceCriteria[] = [];
  personCountTableOptions: TableOptions;
  personCountHeaderOptions: TableHeaderOptions[];
  personCountTableContent: taxMaintanceCriteria[] = [];
  personCountCopyTableContent: taxMaintanceCriteria[] = [];
  @Input() parentForm;
  @Output() tableEmit = new EventEmitter();
  isFocusSearchEmitRequired: boolean = true;
  postTypes: DropdownOptions[];
  taxTypes: TaxTypeUI.TaxType[];
  changeInputValues: any = '';
  editPostTypeId: number;
  isValidPostType: boolean = false;
  isPostTypeSelected: boolean = false;
  taxReferenceType: TaxReferenceType;
  isTaxBasedOnAge: boolean = false;
  isTaxBasedOnPerson: boolean = false;
  invalidReferencevalue: boolean = false;
  indexForAddRow: number = 0;
  searchPostTypeLength = TaxType.SEARCH_POSTTYPE_MAXLENGTH;
  referncekeywordsMaxLength = TaxType.REFERNCEKEYWORDS_MAXLENGTH;
  maxTaxAmountLength = TaxType.TAXAMOUNTMAXLENGTH
  tableContentValid : boolean = false;
  taxInclusiveEnabled : boolean = false;
  isSpecializedTaxEnabled : boolean = false;
  constructor(
    private localization: RetailLocalization,
    private fb: FormBuilder,
    private business: CreateNewTaxTypeBusiness,
    private taxBusiness: TaxTypeBusiness,
    private utilities: RetailUtilities
  ) {
    this.captions = this.localization.captions;
    this.currencySymbol = this.localization.currencySymbol;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.versaTaxTypeForm = this.fb.group({
      id: 0,
      isDefaultToAllRetail: [],
      // isSpecialisedUse: [],
      reference: [],
      isTaxBasedOnAge: [],
      isTaxBasedOnPerson: [],
      selectedPostType: 0,
      postTypeId: ['', Validators.required],
    });
    if (this.parentForm) {
      this.parentForm.addControl("versaTaxType", this.versaTaxTypeForm);
    }
    this.searchPlaceholder = this.captions.lbl_srch_by_post_type_no_name;
    this.getTableHeaders();
    this.ageCategoryTableOptions = this.business.getTableOptions(false);
    this.personCountTableOptions = this.business.getNewTableOptions(true);
    this.GetPostTypes();
    this.patchForm();
    this.isSpecializedTaxEnabled = this.parentForm.value.taxDetails.isSpecialisedUse;
  }
  async getTableHeaders()
  {
    this.ageCategoryheaderOptions = await this.business.getNewHeaderOptions(false);
    this.personCountHeaderOptions = await this.business.getNewHeaderOptions(true);
  }

  async patchForm() {
    this.taxTypes = await this.taxBusiness.getTaxType(true);
    if ((this.taxTypeInputs.mode === ActionMode.update || this.taxTypeInputs.mode === ActionMode.copy)
      && this.taxTypeInputs.form) {
      this.postTypes = this.taxTypeInputs.postTypes;
      this.taxTypes = this.taxTypes.filter(x => x.postTypeId != this.taxTypeInputs.form.taxMaintenance.postTypeId);
      this.bindForm(this.taxTypeInputs.form.taxMaintenance, this.taxTypeInputs.form.tableContent);
      this.rangeMaintenanceTblContent = this.taxTypeInputs.form.tableContent.filter(f=>f.isActive).map(item => ({
        active: item.isActive ? { isDisabled: false, value: true } : { isDisabled: false, value: false },
        taxBasedOn: item.taxBasedOn,
        effectiveFrom: item.fromDate,
        effectiveUntil: item.toDate,
        value: item.taxBasedOn == TaxBased.TaxAmount ? item.taxFlatAmount : item.taxPercent,
        id: item.id
      }));
      const hasTaxByAgeCategory = this.taxTypeInputs.form.tableContent
        .some(x => x.taxMaintenanceByCriteria[0]?.referenceType === TaxReferenceType.TaxByAgeCategory);

      const hasTaxByPerperson = this.taxTypeInputs.form.tableContent
        .some(x => x.taxMaintenanceByCriteria[0]?.referenceType === TaxReferenceType.TaxByPerperson);
      if (hasTaxByAgeCategory) {
        this.isTaxBasedOnAge = true;
        this.isTaxBasedOnPerson = false;
        this.taxReferenceType = TaxReferenceType.TaxByAgeCategory;
        this.ageCategoryTableContent = this.taxTypeInputs.form.tableContent
          .flatMap(x => x.taxMaintenanceByCriteria.map(item => ({
            taxMaintenanceId: item.taxMaintenanceId,
            referenceType: item.referenceType,
            effectiveFrom: x.fromDate,
            effectiveUntil: x.toDate,
            taxBasedOn: item.isTaxAmount ? TaxBased.TaxAmount : TaxBased.TaxPercent,
            referenceValue: item.referenceValue,
            value: item.taxAmount,
            id: item.id,
            age: item.referenceValue,
          })));
          this.sortByEffectiveFrom();
      }
      else if (hasTaxByPerperson) {
        this.isTaxBasedOnAge = false;
        this.isTaxBasedOnPerson = true;
        this.taxReferenceType = TaxReferenceType.TaxByPerperson;
        this.personCountTableContent = this.taxTypeInputs.form.tableContent
          .flatMap(x => x.taxMaintenanceByCriteria.map(item => ({
            taxMaintenanceId: item.taxMaintenanceId,
            referenceType: item.referenceType,
            effectiveFrom: x.fromDate,
            effectiveUntil: x.toDate,
            taxBasedOn:  item.isTaxAmount ? TaxBased.TaxAmount : TaxBased.TaxPercent,
            referenceValue: item.referenceValue,
            value: item.taxAmount,
            id: item.id,
            person: item.referenceValue
          })));
          this.sortByEffectiveFrom();
      }
      this.emitTableOutputData(false);
    }
  }

  private bindForm(data, tableContent) {
    const hasTaxByAgeCategory = tableContent
      .some(x => x.taxMaintenanceByCriteria[0]?.referenceType === TaxReferenceType.TaxByAgeCategory);

    const hasTaxByPerperson = tableContent
      .some(x => x.taxMaintenanceByCriteria[0]?.referenceType === TaxReferenceType.TaxByPerperson);
    this.changeInputValues = {
      id: data.id,
      isDefaultToAllRetail: data.isDefaultToAllRetail,
      selectedPostType: data.selectedPostType ? data.selectedPostType : '',
      isTaxBasedOnAge: hasTaxByAgeCategory,
      isTaxBasedOnPerson: hasTaxByPerperson,
      postTypeId: data.postTypeId
    };
    this.versaTaxTypeForm.patchValue({
      id: data.id,
      isDefaultToAllRetail: data.isDefaultToAllRetail,
      selectedPostType: data.selectedPostType ? data.selectedPostType : '',
      // reference: data.reference,
      isTaxBasedOnAge: hasTaxByAgeCategory,
      isTaxBasedOnPerson: hasTaxByPerperson,
      postTypeId: data.postTypeId
    });
    if (data.postTypeId > 0) {
      this.versaTaxTypeForm.get('postTypeId').setValue(data.postTypeId);
      this.editPostTypeId = data.postTypeId;
      let postType = this.postTypes.filter(x => x.id == data.postTypeId);
      if (postType.length > 0) {
        this.selectedFromList = [{ id: postType[0].id, name: `${postType[0].value} - ${postType[0].viewValue}` }];
        this.isValidPostType = true;
        this.isPostTypeSelected = true;
      }
    }
  }

  selectedChipDataEmit(event: { id: number, name: string }[]) {
    let selectedPostType = '';
    let postTypeId = 0;
    if (event && event.length > 0) {
      let isExists = this.taxTypes.some(x => x.postTypeId == event[0].id);
      if (isExists) {
        this.utilities.showAlert(this.captions.err_alreadyPostTypeTaxExists, AlertType.Error, ButtonType.Ok, (res) => {
          this.selectedFromList = [];
        });
        this.onChipRemoveEvent(event);
        this.isValidPostType = false;
        this.isPostTypeSelected = false;
      }
      else {
        postTypeId = event[0].id ? event[0].id : 0;
        selectedPostType = event[0].name ? event[0].name.split('-')[0].trim() : '';
        this.versaTaxTypeForm.markAsDirty();
        this.versaTaxTypeForm.get('postTypeId').setValue(postTypeId);
        this.versaTaxTypeForm.get('selectedPostType').setValue(selectedPostType);
        this.isValidPostType = true;
        this.isPostTypeSelected = true;
        this.versaTaxTypeForm.controls.taxType.markAsTouched();
      }
    }
  }
  onChipRemoveEvent(event) {
    this.isValidPostType = false;
    this.versaTaxTypeForm.get('postTypeId').setValue('');
    this.isPostTypeSelected = false;
  }
  checkChange(eve) { }
  change(eve) { }
  onTaxBasedOnAgeChange(eve) {
    if (!eve[0]) {
      this.taxReferenceType = null;
    }
    this.indexForAddRow = 0;
    if (eve[0]) {
      this.isTaxBasedOnAge = true;
      this.isTaxBasedOnPerson = false;
      this.versaTaxTypeForm.patchValue({
        isTaxBasedOnAge: true,
        isTaxBasedOnPerson: false
      });
      this.taxReferenceType = TaxReferenceType.TaxByAgeCategory;
    }
    this.ageCategoryTableContent = this.rangeMaintenanceTblContent?.filter(f=>f.active.value).map((item, index) => ({
      taxMaintenanceId: item.id,
      referenceType: TaxReferenceType.TaxByAgeCategory,
      effectiveFrom: item.effectiveFrom,
      effectiveUntil: item.effectiveUntil,
      taxBasedOn: TaxBased.TaxAmount,
      referenceValue: "",
      taxAmount: 0,
      age: 0,
      value : 0,
      id: index//this.ageCategoryTableContent.length > 0 ? Math.max(...this.ageCategoryTableContent.map(x => x.id)) + 1 : 0
    }));
    this.indexForAddRow = this.ageCategoryTableContent?.length ?? 0;   
     this.invalidReferencevalue = true;
     this.sortByEffectiveFrom();
    this.emitTableOutputData(false);
  }
  onTaxBasedOnPersonChange(eve) {
    this.indexForAddRow = 0;
    if (!eve[0]) {
      this.taxReferenceType = null;
    }
    if (eve[0]) {
      this.isTaxBasedOnAge = false;
      this.isTaxBasedOnPerson = true;
      this.versaTaxTypeForm.patchValue({
        isTaxBasedOnAge: false,
        isTaxBasedOnPerson: true
      });
      this.taxReferenceType = TaxReferenceType.TaxByPerperson;
    }

    this.personCountTableContent = this.rangeMaintenanceTblContent?.filter(f=>f.active.value).map((item, index) => ({
      taxMaintenanceId: item.id,
      referenceType: TaxReferenceType.TaxByPerperson,
      effectiveFrom: item.effectiveFrom,
      effectiveUntil: item.effectiveUntil,
      taxBasedOn: TaxBased.TaxAmount,
      referenceValue: "",
      taxAmount: 0,
      person: 0,
      id: index,
      value : 0
    }));
    this.indexForAddRow = this.personCountTableContent?.length ?? 0;
    this.invalidReferencevalue = true;
    this.sortByEffectiveFrom();
    this.emitTableOutputData(false);
  }
  selectionChange(eve) { }

  onTableAction(eve) {
    if (eve.fromType === 'delete') {
      this.ageCategoryTableContent = this.ageCategoryTableContent.filter(x => x.id !== eve.Obj.id);
      let isExists = this.ageCategoryTableContent.some(s => s.effectiveFrom == eve.Obj.effectiveFrom && s.effectiveUntil == eve.Obj.effectiveUntil && s.referenceValue == eve.value);
        if (!isExists) {
          this.invalidReferencevalue = false;
        }
      this.sortByEffectiveFrom();
      this.emitTableOutputData(true);
    }
    else if (eve.fromType === 'dropdown') {
      if (this.taxTypeInputs.mode === ActionMode.update) {
        let isExists = this.ageCategoryTableContent.some(s => s.effectiveFrom == eve.Obj.effectiveFrom && s.effectiveUntil == eve.Obj.effectiveUntil && s.referenceValue == eve.value);
        if (isExists) {
          this.invalidReferencevalue = true;
          this.utilities.showError(this.captions.err_duplicateAgeCategory);
          eve.value = "";
          eve.Obj.age = 0;
          this.emitTableOutputData(true);
        }
        else {
          this.invalidReferencevalue = false;
          const index = this.ageCategoryTableContent.findIndex(tableData => tableData.id === eve.Obj.id);

          if (index !== -1) {
            this.ageCategoryTableContent[index].referenceValue = eve.Obj.age;
            this.ageCategoryTableContent[index].age = eve.Obj.age
          }
          this.emitTableOutputData(true);
        }
      }
      else {
        let isExists = this.ageCategoryTableContent.some(s => s.effectiveFrom.getTime() == eve.Obj.effectiveFrom.getTime() && s.effectiveUntil.getTime() == eve.Obj.effectiveUntil.getTime() && s.referenceValue == eve.value);
        if (isExists) {
          this.invalidReferencevalue = true;
          this.utilities.showError(this.captions.err_duplicateAgeCategory);
          eve.value = "";
          eve.Obj.age = 0;
          this.emitTableOutputData(true);
        }
        else {
          this.invalidReferencevalue = false;
          const index = this.ageCategoryTableContent.findIndex(tableData => tableData.id === eve.Obj.id);

          if (index !== -1) {
            this.ageCategoryTableContent[index].referenceValue = eve.Obj.age;
            this.ageCategoryTableContent[index].age = eve.Obj.age
          }
          this.emitTableOutputData(true);
        }
      }

    }
    else if (eve.fromType == 'copy') {
      this.indexForAddRow = this.indexForAddRow + 1
      this.ageCategoryCopyTableContent = {
        ...eve.Obj,
        id: this.indexForAddRow,
        age: 0
      };
      this.ageCategoryTableContent = this.ageCategoryTableContent.concat(this.ageCategoryCopyTableContent);
      this.sortByEffectiveFrom();
    }
  }

  onTablePersonCountAction(eve) {
    if (eve.fromType === 'delete') {
      this.personCountTableContent = this.personCountTableContent.filter(x => x.id !== eve.Obj.id);
      this.sortByEffectiveFrom();
      this.emitTableOutputData(true);
    }
    else if (eve.fromType === 'input') {
      if (this.taxTypeInputs.mode === ActionMode.update) {
        let isExists = this.personCountTableContent.some(s => s.effectiveFrom == eve.Obj.effectiveFrom && s.effectiveUntil == eve.Obj.effectiveUntil && s.referenceValue == eve.value);
        if (isExists) {
          this.invalidReferencevalue = true;
          this.utilities.showError(this.captions.err_duplicatePerPersonCount);
          eve.value = "";
          eve.Obj.person = 0;
          this.emitTableOutputData(true);
        }
        else {
          this.invalidReferencevalue = false;
          const index = this.personCountTableContent.findIndex(tableData => tableData.id === eve.Obj.id);

          if (index !== -1) {
            this.personCountTableContent[index].referenceValue = eve.Obj.person;
            this.personCountTableContent[index].person = eve.Obj.person
          }
          this.emitTableOutputData(true);
        }
      }
      else {
        let isExists = this.personCountTableContent.some(s => s.effectiveFrom.getTime() == eve.Obj.effectiveFrom.getTime() && s.effectiveUntil.getTime() == eve.Obj.effectiveUntil.getTime() && s.referenceValue == eve.value);
        if (isExists) {
          this.invalidReferencevalue = true;
          this.utilities.showError(this.captions.err_duplicatePerPersonCount);
          eve.value = "";
          eve.Obj.person = 0;
          this.emitTableOutputData(true);
        }
        else {
          this.invalidReferencevalue = false;
          const index = this.personCountTableContent.findIndex(tableData => tableData.id === eve.Obj.id);

          if (index !== -1) {
            this.personCountTableContent[index].referenceValue = eve.Obj.person;
            this.personCountTableContent[index].person = eve.Obj.person
          }
          this.emitTableOutputData(true);
        }
      }

    }
    else if (eve.fromType == 'copy') {
      this.indexForAddRow = this.indexForAddRow + 1
      this.personCountCopyTableContent = {
        ...eve.Obj,
        id: this.indexForAddRow,
        referenceValue: 0,
        person: 0
      };
      this.personCountTableContent = this.personCountTableContent.concat(this.personCountCopyTableContent);
      this.sortByEffectiveFrom();
    }
  }
  emitTableData(eve, element, key) {
    if (this.taxReferenceType === TaxReferenceType.TaxByAgeCategory) {
      const index = this.ageCategoryTableContent.findIndex(tableData => tableData.id === element.id);

      if (index !== -1) {
        this.ageCategoryTableContent[index] = element;
        this.ageCategoryTableContent[index].referenceValue = element.age;
      }
      if (element.age > 0) {
        this.emitTableOutputData(true);
      }
      else {
        this.emitTableOutputData(false);
      }

    }
    else {
      const index = this.personCountTableContent.findIndex(tableData => tableData.id === element.id);
      if (index !== -1) {
        this.personCountTableContent[index] = element;
        this.personCountTableContent[index].referenceValue = element.person
      }
      if (element.person > 0) {
        this.emitTableOutputData(true);
      }
      else {
        this.emitTableOutputData(false);
      }
    }

  }

  addAgeCategory() {
    if (this.rangeMaintenanceTblContent === undefined || this.rangeMaintenanceTblContent?.length == 0) {
      this.utilities.showError(this.captions.err_createTaxRange);
    }
    this.ageCategoryCopyTableContent = this.rangeMaintenanceTblContent?.filter(f=>f.active.value).map((item, index) => {
      this.indexForAddRow = this.indexForAddRow + index + 1; // Increment ID based on index   
      return {
        taxMaintenanceId: item.id,
        referenceType: TaxReferenceType.TaxByAgeCategory,
        effectiveFrom: item.effectiveFrom,
        effectiveUntil: item.effectiveUntil,
        taxBasedOn: this.taxInclusiveEnabled ? TaxBased.TaxPercent : TaxBased.TaxAmount,
        referenceValue: "",
        taxAmount: 0,
        id: this.indexForAddRow
      };
    });

    this.ageCategoryTableContent = this.ageCategoryTableContent.concat(this.ageCategoryCopyTableContent);
    this.invalidReferencevalue = true;
    this.sortByEffectiveFrom();
    this.emitTableOutputData(false);
  }

  addPersonCount() {
    if (this.rangeMaintenanceTblContent === undefined || this.rangeMaintenanceTblContent?.length == 0) {
      this.utilities.showError(this.captions.err_createTaxRange);
    }
    this.personCountCopyTableContent = this.rangeMaintenanceTblContent?.filter(f=>f.active.value).map((item, index) => {
      this.indexForAddRow = this.indexForAddRow + index + 1; // Increment ID based on index   
      return {
        taxMaintenanceId: item.id,
        referenceType: TaxReferenceType.TaxByPerperson,
        effectiveFrom: item.effectiveFrom,
        effectiveUntil: item.effectiveUntil,
        taxBasedOn: this.taxInclusiveEnabled ? TaxBased.TaxPercent : TaxBased.TaxAmount,
        referenceValue: "",
        taxAmount: 0,
        id: this.indexForAddRow
      };
    });

    this.personCountTableContent = this.personCountTableContent.concat(this.personCountCopyTableContent);
    this.invalidReferencevalue = true;
    this.sortByEffectiveFrom();
    this.emitTableOutputData(false);
  }
  async searchByKeyword(arg) {
    this.searchMasterPostTypes(arg);
  }

  searchMasterPostTypes(pattern: string) {
    pattern = (pattern ? pattern.toLowerCase() : "").trim();
    if (this.postTypes && this.postTypes.length > 0) {
      this.postTypeItems = this.postTypes && pattern ? this.postTypes.filter(x => x.viewValue.toLowerCase().includes(pattern) ||
        x.value.toString().toLowerCase().includes(pattern)).map(x => {
          return {
            id: x.id,
            name: `${x.value} - ${x.viewValue}`
          };
        }) : [];
    }
    else {
      this.utilities.showError(this.captions.err_searchMasterPostType);
      this.isFocusSearchEmitRequired = false;
    }
  }
  async GetPostTypes() {
    this.utilities.ToggleLoader(true);
    this.postTypes = await this.taxBusiness.getPostTypes();
    this.utilities.ToggleLoader(false);
    // }
  }
  rangeMaintenanceByCriteria() {
    if (this.taxReferenceType === TaxReferenceType.TaxByAgeCategory) {
      if (this.ageCategoryTableContent.length > 0) {
        this.ageCategoryTableContent.forEach(ageCategoryItem => {
          const matchedItem = this.rangeMaintenanceTblContent?.find(
            rangeItem => rangeItem.id === ageCategoryItem.taxMaintenanceId
          );
          if (matchedItem) {
            ageCategoryItem.effectiveFrom = matchedItem.effectiveFrom;
            ageCategoryItem.effectiveUntil = matchedItem.effectiveUntil;
          }
        });
        const validIds = new Set(this.rangeMaintenanceTblContent?.map(item => item.id));
        this.ageCategoryTableContent = this.ageCategoryTableContent.filter(item => validIds.has(item.taxMaintenanceId));
        this.sortByEffectiveFrom();
        this.emitTableOutputData(false);
      }
    }
    else {
      if (this.personCountTableContent.length > 0) {
        this.personCountTableContent.forEach(ageCategoryItem => {
          const matchedItem = this.rangeMaintenanceTblContent?.find(
            rangeItem => rangeItem.id === ageCategoryItem.taxMaintenanceId
          );
          if (matchedItem) {
            ageCategoryItem.effectiveFrom = matchedItem.effectiveFrom;
            ageCategoryItem.effectiveUntil = matchedItem.effectiveUntil;
          }
        });
        const validIds = new Set(this.rangeMaintenanceTblContent?.filter(f=>f.active.value).map(item => item.id));
        this.personCountTableContent = this.personCountTableContent.filter(item => validIds.has(item.taxMaintenanceId));
        this.sortByEffectiveFrom();
        this.emitTableOutputData(false);
      }
    }
  }
  emitTableOutputData(isEdited: boolean) {
    if (!this.isPostTypeSelected) {
      isEdited = false
    }
    this.checkIfTableContentValid();
    if (this.taxReferenceType === TaxReferenceType.TaxByAgeCategory) {
      this.tableEmit.emit({
        table: this.ageCategoryTableContent,
        type: "age",
        edited: this.tableContentValid ? isEdited : false,
        tabChange: false,
        invalid: !this.tableContentValid,
        isPostTypeSelected : this.isPostTypeSelected
      });
    }
    else if (this.taxReferenceType === TaxReferenceType.TaxByPerperson) {
      this.tableEmit.emit({
        table: this.personCountTableContent,
        type: "person",
        edited: this.tableContentValid ? isEdited : false,
        tabChange: false,
        invalid: !this.tableContentValid
      });
    }
    else {
      this.tableEmit.emit({
        table: [],
        type: "",
        edited: this.tableContentValid ? isEdited : false,
        tabChange: false,
        invalid: !this.tableContentValid
    });
    }
  }
  checkIfTableContentValid(){
    let invalid : boolean =false
    if (this.taxReferenceType === TaxReferenceType.TaxByAgeCategory) {
      let length = this.ageCategoryTableContent?.length??0
      let emptyValueCheck = this.ageCategoryTableContent?.some(x =>
        x.age == null || x.age == 0 || x.value == 0 || x.value == null);
      invalid = length == 0 || this.invalidReferencevalue || emptyValueCheck
    }
    else if (this.taxReferenceType === TaxReferenceType.TaxByPerperson) {
      let length = this.personCountTableContent?.length??0
      let emptyCheck = this.personCountTableContent?.some(x =>
        x.person == null || x.person == 0 || x.value ==0 || x.value == null);
      invalid = length == 0 || this.invalidReferencevalue || emptyCheck
    }
    else {
      invalid = false;
    }
    this.tableContentValid = !invalid;
  }

  inclusiveChange(inclusive:boolean) {
    this.taxInclusiveEnabled = inclusive;
    if(inclusive){
      if (this.taxReferenceType === TaxReferenceType.TaxByAgeCategory) {
        if (this.ageCategoryTableContent.length > 0) {
          this.ageCategoryTableContent.filter(f=>f.taxBasedOn == TaxBased.TaxAmount).map(x => {
            x.taxBasedOn = TaxBased.TaxPercent
            x.value = 0,
            x.taxAmount=0
          });
          this.ageCategoryTableContent = [...this.ageCategoryTableContent];
          this.sortByEffectiveFrom();
          this.emitTableOutputData(false);
        }
      }
      else {
        if (this.personCountTableContent.length > 0) {
          this.personCountTableContent.filter(f=>f.taxBasedOn == TaxBased.TaxAmount).map(x => {
            x.taxBasedOn = TaxBased.TaxPercent
            x.value = 0,
            x.taxAmount=0
          });
          this.personCountTableContent = [...this.personCountTableContent];
          this.sortByEffectiveFrom();
          this.emitTableOutputData(false);
        }
      }
    }
  }
  sortByEffectiveFrom(){
    if(this.taxReferenceType === TaxReferenceType.TaxByAgeCategory){
      if(this.ageCategoryTableContent.length>0){
        this.ageCategoryTableContent = this.ageCategoryTableContent.sort((a, b) => this.localization.getDate(a.effectiveFrom) > this.localization.getDate(b.effectiveFrom) ? 1 : -1);
      }
    }
    else{
      if(this.personCountTableContent.length>0){
        this.personCountTableContent = this.personCountTableContent.sort((a, b) => this.localization.getDate(a.effectiveFrom) > this.localization.getDate(b.effectiveFrom) ? 1 : -1);
      }
    }
  }
  specializedTaxChange(isSpecailizedTax:boolean) {
    this.isSpecializedTaxEnabled=false;
    if(isSpecailizedTax){
      this.isTaxBasedOnAge = false;
      this.isTaxBasedOnPerson = false;
      this.isSpecializedTaxEnabled=true;
      this.versaTaxTypeForm.patchValue({
        isTaxBasedOnAge: false,
        isTaxBasedOnPerson: false
      });
      this.taxReferenceType=null;
      this.ageCategoryTableContent=[];
      this.personCountTableContent=[];
      this.emitTableOutputData(false);
    }
  }
}
