import { Injectable } from '@angular/core';
import { NotificationCommunication } from '../../communication/services/notification-communication-service';
import { CommonControllersRoutes } from '../../communication/common-route';
import { EmailLogs, EmailSetting } from '../../shared/shared/components/email-logs/email-logs.model';

@Injectable( { providedIn: 'root' })
export class EmailProcessingDataService{
    constructor(private _notificationCommunication : NotificationCommunication){

    }

    public generateEmailReferenceNumber(referenceNumber, confirmationNumber, emailCategory): Promise<string> {
        let result = this._notificationCommunication.getPromise<string>(
            { route: CommonControllersRoutes.GenerateEmailReferenceNumber,
                uriParams: { referenceNumber, confirmationNumber,emailCategory },
            }, true, true);
        return result;
    }

    public getEmailLogs(referenceNumber: string) : Promise<EmailLogs[]>{
        let result = this._notificationCommunication.getPromise<EmailLogs[]>(
            { route: CommonControllersRoutes.GetEmailLogs,
                uriParams: { referenceNumber },
            }, true, true);
        return result;
    }

    public getEmailLogByMessageId(messageId: string) : Promise<EmailLogs>{
        let result = this._notificationCommunication.getPromise<EmailLogs>(
            { route: CommonControllersRoutes.GetEmailLogByMessageId,
                uriParams: { messageId },
            }, true, true);
        return result;
    }

    public refreshEmailLogs() : Promise<EmailLogs[]>{
        let result = this._notificationCommunication.getPromise<EmailLogs[]>(
            { route: CommonControllersRoutes.RefreshEmailLogs
            }, true, true);
        return result;
    }

    public deleteEmailLogs(ids: number[]): Promise<boolean>{
        let result = this._notificationCommunication.deletePromise<boolean>(
            { route: CommonControllersRoutes.DeleteEmailLogs,
                body: ids
            }, true, true);
        return result;
    }

    public getEmailSetting() : Promise<EmailSetting>{
        let result = this._notificationCommunication.getPromise<EmailSetting>(
            { route: CommonControllersRoutes.GetEmailSetting,
            }, true, true);
        return result;
    }

    public createEmailSetting(emailSetting) : Promise<EmailSetting>{
        let result = this._notificationCommunication.postPromise<EmailSetting>(
            { route: CommonControllersRoutes.CreateEmailSetting,
                body: emailSetting
            }, true, true);
        return result;
    }

    public updateEmailSetting(emailSetting) : Promise<EmailSetting>{
        let result = this._notificationCommunication.putPromise<EmailSetting>(
            { route: CommonControllersRoutes.UpdateEmailSetting,
                body: emailSetting 
            }, true, true);
        return result;
    }

    public CreateSubscriptionAndProcessInboxMessages(): Promise<string>{
        let result = this._notificationCommunication.postPromise<string>(
            { route: CommonControllersRoutes.CreateSubscriptionAndProcessInboxMessages
            }, true, true);
        return result;
    }
}