import { Injectable } from '@angular/core';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { TaxGroups } from '../../retail.modals';
import { RetailTaxGroupDataService } from './retail-tax-groups-data.service';
import { Localization } from 'src/app/common/localization/localization';
import { UI } from '../retail-taxes/tax-type/tax-type.model';

@Injectable()
export class RetailTaxGroupsBusiness {
  captions: any;
  currencySymbol: string;

  constructor(private localization: Localization, private retailTaxGroupDataService: RetailTaxGroupDataService) {
    this.captions = this.localization.captions;
    this.currencySymbol = this.localization.currencySymbol;
  }

  public async getTableData(_includeInactive: boolean): Promise<any[]> {
    const apiModels = await this.retailTaxGroupDataService.getAllTaxGroups(_includeInactive);
    return Promise.resolve(
      apiModels.map((x: any) => ({
        id: x.id,
        taxgroupName: x.name,
        listOrder: x.listOrder,
        isActive: {
          value: x.isActive,
          isDisabled: false,
        },
      }))
    );
  }

  public async getTaxTypeById(id: number): Promise<TaxGroups> {
    try {
      return await this.retailTaxGroupDataService.getTaxGroupById(id);
    } catch (e) {
      console.error(e);
      throw e;
    }

  }

  public async createTaxGroup(taxgroup: TaxGroups): Promise<TaxGroups[]> {
    try {
      return await this.retailTaxGroupDataService.CreateTaxGroup(taxgroup);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async UpdateTaxGroup(taxgroup: TaxGroups): Promise<TaxGroups[]> {
    try {
      return await this.retailTaxGroupDataService.updateTaxGroups(taxgroup);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async deleteTaxGroup(id: number): Promise<void> {
    try {
      return await this.retailTaxGroupDataService.deleteTaxGroup(id);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public getHeaderOptions() {
    return [

      {
        key: 'taxgroupName',
        displayNameId: 'tbl_hdr_taxgroupName',
        displayName: this.captions.tbl_hdr_taxgroupName,
        sortingKey: 'taxgroupName',
        sorting: true
      },
      {
        key: 'listOrder',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_listOrder,
        sortingKey: 'listOrder',
        sorting: true
      },
      {
        key: 'isActive',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_active,
        sortingKey: 'isActive',
        templateName: ActionTypeEnum.toggle
      },
      {
        key: ActionTypeEnum.action,
        displayName: this.captions.tbl_hdr_action,
        displayNameId: 'tbl_hdr_action',
        templateName: ActionTypeEnum.action
      }

    ];
  }

  public getTableOptions() {
    return {
      actions: [
        {
          type: TableActions.edit,
          disabled: false
        }, {
          type: TableActions.delete,
          disabled: false
        },
        {
          type: TableActions.drag,
          disabled: false
        }
      ],
      defaultsortingColoumnKey: 'listOrder',
      showTotalRecords: false,
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: false,
        lastColumn: false
      },
      isDragDisabled: false,
      isHeaderCheckboxAllowed: true,
      checkboxKey: 'checked',
      ignoreSort: true
    };
  }

  public async getAllTaxes(): Promise<{ id: number; value: string; viewValue: string }[]> {
    const apiModels: UI.TaxType[] = await this.retailTaxGroupDataService.getAllTaxTypes();
    const taxOptions = apiModels.map(x => ({
      id: x.id,
      value: x.taxType,
      viewValue: x.taxType
    }));
    return taxOptions;
  }

  UpdateTaxGroupFromGrid(taxGroupData): Promise<TaxGroups> {
    return this.retailTaxGroupDataService.UpdateTaxGroupFromGrid(taxGroupData);
  }

  async GetNextListOrder(): Promise<number> {
    return await this.retailTaxGroupDataService.getNextListOrder();
  }
}
