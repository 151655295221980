export enum GridType {
  group = 1,
  location,
  therapist,
  medical,
  addons,
  equipment,
  spaServices,
  commission,
  spaPackage,
  appointmentsearch,
  spaPackageService,
  deletepackage,
  packageClasses,
  colorLink,
  lunchsetup,
  breakType,
  priceType,
  cancelReasons,
  password,
  locationMaintenance,
  equipmentMaintenance,
  therapistUnavailability,
  customfield,
  multipleAppointments,
  outlet,
  retail,
  quickSale,
  quickSaleCategories,
  creditcardterminal,
  categorygroup,
  commissionTemplate,
  retailtransactions,
  waitlist,
  roleSetup,
  userSetup,
  userRoleConfiguration,
  packageYielding,
  taxes,
  retailCategories,
  subCategory,
  retailSuppliers,
  retailOutlet,
  supplierDetails,
  unitOfMeasure,
  discountTypes,
  userMachineConfiguration,
  recieptConfiguration,
  templates,
  distributionList,
  exception,
  combineGuestRecords,
  dedupeGuestProfiles,
  member,
  lostDeniedreason,
  lostDeniedBusiness,
  inventorysync,
  relationship,
  logtype,
  guestType
}


export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}

export enum LogTypeErrorCodes{
  DUPLICATE_LOGTYPE_INSTANCE = -301,
  LOGTYPE_ID_DOES_NOT_EXIST = -302,
  LOGTYPE_IS_IN_USE = -304,
  LOGTYPE_INACTIVE_RESTRICT = -306
}