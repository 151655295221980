import { Injectable } from '@angular/core';
import { EventActorsDataProvider, NotificationDataProvider } from './Models/notification.model';
import { MenuSearchBusiness } from './shared/shared/components/menu-search/menu-search.business';

@Injectable()
export class AppService implements IAppService {
    hosts: Hosts;
    productId: number;
    notificationEventDataProvider: EventActorsDataProvider;
    notificationDataProvider : NotificationDataProvider;
    MenuSearchBusiness : MenuSearchBusiness;
}


export interface IAppService {
    productId: number;
    notificationEventDataProvider: EventActorsDataProvider;
    notificationDataProvider ?: NotificationDataProvider;
    hosts: Hosts
}

export interface Hosts {
    TenantManagement: string;
    EnterpriseManagement:string;
    Report: string;
    Payment: string;
    Common: string;
    PMS: string;
    PMSManagerService: string;
    V1ReservationService: string;
    V1FolioService :string;
    RetailManagement:string;
    PayAgent: string;
    SalesCateringService:string;
    CommonGateway: string;
    InterfaceIntegration :string;
}