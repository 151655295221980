export enum TableActions {
    add = 'add',
    cancel = 'cancel',
    comment = 'comment',
    copy = 'copy',
    delete = 'delete',
    discount = 'discount',
    done = 'done',
    drag = 'drag',
    edit = 'edit',
    editBooking = 'editBooking',
    menu = 'menu',
    preview = 'preview',
    salescallletter = 'salescallletter',
    splitFunction = 'splitFunction',
    userBlock = 'userBlock',
    replace = 'replace',
    sync = 'sync',
    history = 'history',
    customAction = 'customAction',
    folioAction = 'folioAction',
    download = 'download',
    denialAction ='denialAction',
    reservationAction ='reservationAction',
    switch = "switch",
    reply= 'reply',
    replyAll = 'replyAll',
    forward = 'forward'
}
export enum DefaultPhoneTypes {
    mobile = 'mobile',
    home = 'home',
    office = 'office',
    business = 'business',
    work = 'work',
    others = 'others'
}
export enum DefaultMailTypes {
    personal = 'personal',
    office = 'office',
    home = 'home',
    business = 'business',
}

export enum AlertType {
    Success = 1,
    Warning = 2,
    Error = 3,
    WellDone = 4,
    Info = 5,
    AccessDenied = 6,
    Done = 7,
    Confirmation = 8,
    CustomDefault = 9,
    Attention = 10
}

export enum ButtonType {
    YesNo = 1,
    YesNoCancel = 2,
    OkCancel = 3,
    Ok = 4,
    SaveCancel = 5,
    Continue = 6,
    ContinueCancel = 7,
    AddCancel = 8,
    Custom = 11,
    SplitMoreAction = 12

}
export enum ContactType {
    email = 'Email',
    phone = 'Phone'
}

export enum PhoneTypes {
    mobile = 1,
    home = 2,
    office = 3,
    fax = 4,
    others = 11,
    business = 12, 
    work = 13,
}

export enum MailTypes {
    personal = 9,
    office = 10,
    home = 14,
    business = 15
}

export enum ContactReferenceType {
    MANAGER = 1,
    CONTACT = 2,
    ACCOUNT = 3,
    INQUIRY = 4
}
export enum ContactInformationReferenceType {
    CONTACT = 1,
    ACCOUNT
}
export enum ContactAddressReferenceType {
    CONTACT = 1,
    ACCOUNT
}

export enum ActionMode {
    create,
    update,
    cancel,
    delete,
    add,
    view,
    copy,
    back
}

export enum LocationTypes{
    MaintenanceEquipmentLocation,
    StorageRoomLocation
}

export enum AlertAction {
    CONTINUE = 'CONTINUE',
    CANCEL = 'CANCEL',
    YES = 'YES',
    NO = 'NO'
}

export enum ButtonTypes {
    YesNo = 1,
    YesNoCancel = 2,
    OkCancel = 3,
    Ok = 4,
    SaveCancel = 5,
    Continue = 6,
    ContinueCancel = 7,
    AddCancel = 8,
    Save = 9,
    Update = 10
}

export const enum Product {
    SPA = 1,
    RETAIL = 2,
    GOLF = 3,
    COMMON = 4,
    SALESANDCATERING = 5,
    PMS = 6,
    TENANT = 7,
    ACTIVITY = 12,
    DINING = 13,
    ACCOUNTING = 10
}

export enum formTypes {
    fromType,
    toType
}

export enum DialogCloseEnum {
    Close,
    Cancel,
    Action
}
export enum EventConstants{
    UserEvents = "UserEvents",
    SPAEvents = 'SPAEvents',
    RetailEvents = 'RetailEvents',
    GolfEvents = 'GolfEvents',
    CommonEvents = 'CommonEvents',
    SalesNCateringEvents = 'SalesNCateringEvents',
    PropertyEvents = 'PropertyEvents',
    TenantEvents = 'TenantEvents',
    EnhancedInventoryEvents = 'EnhancedInventoryEvents',
    HouseKeepingEvents = 'HouseKeepingEvents',
    AccountingEvents = 'AccountingEvents',
    CacheEvents = 'CacheEvents',
    CondoEmail = "CondoEmail"
}

export enum SessionConstants{
    IsCustomFeeEnabled = 'isCustomFeeEnabled'
}

export enum TransactionType {
    Sale = 1,
    Refund
}

export enum PlayerCategory {
    Player = 1,
    HotelReservation,
    Member,
    ExistingPlayer,
    Guest
}
export enum DefaultNameFormat {
    firstName = 'firstName',
    lastName = 'lastName',
}

export enum ActivityConstants {
    Activities = 'Activities'
}
export enum PackageName {
    TeeTime = 'TeeTime',
    Lesson = 'Lesson'
}

export enum ConfigValidationStatus{
    success=1,
    Fail=2
}

export enum ValidationErrorStatus{
    CheckKeyAloneNotValues='Kindly add this Config Key',
    MandatoryKeyValue='Expected value is not there',
    AleastAnyValues='No values present for this key',
    caseSensitive='Kindly check the case sensitive',
    checkWhiteSpace='Kindly Check the Extra White Space',
    dataType='Kindly Check the dataType',
    urlValidation='Url validation fail',
    encrypt='Kindly encrypt the correct value',
    urlnoConfigure='URL not configured'
}

export enum ProductIcons {
    PMS = 'icon-pms',
    Spa = 'icon-spa',
    Golf = 'icon-golf',
    Seat = 'icon-seat',
    Transport = 'icon-Transportation-Activity-Schedule',
    Activity = 'icon-activity-none',
    CustomEvent = 'icon-Custom-Event'
}

export enum LogApplicableType{
    Client=1,
    Staff=2,
    Both=3
}

export enum ActivitiesInfoSearchBy {
    StayIdOrGuestId,
    ActivityInfoIds,
    ConfirmationNumber,
}