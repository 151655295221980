import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { CommonAlertMessagePopupComponent } from '../alert-message-popup/alert-message-popup.component';
import { Localization } from "src/app/common/localization/localization";
@Component({
  selector: 'app-card-swipe-popup',
  templateUrl: './card-swipe-popup.component.html',
  styleUrls: ['./card-swipe-popup.component.scss']
})
export class CardSwipePopupComponent implements OnInit, AfterViewInit, OnDestroy {

  noButton = false;
  IDTHiddenField: string;
  dataform: UntypedFormGroup;
  @ViewChild('cardfield') cardfield: ElementRef;
  inputSubscription$: any;
  floatLabel: string;
  captions: any;
  cardEntryModeGif: string;
  spinnerClass: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<CommonAlertMessagePopupComponent>, private localization: Localization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.noButton = data.noButton ? data.noButton : false;
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.dataform = new UntypedFormGroup({
      carddata: new UntypedFormControl('')
    });
    this.inputSubscription$ = this.dataform.valueChanges.pipe(debounceTime(1000))
      .subscribe(val => {
        this.dialog.close(val.carddata);
      });
    this.cardEntryModeGif = this.data && this.data.isManualEntry ? './assets/images/CardKeyIn.gif' : './assets/images/Cardpayment.gif';
    this.spinnerClass = this.data && this.data.isManualEntry ? 'spinner-image-key-in' : 'spinner-image';
    if(this.data?.isRFID){
      this.cardEntryModeGif =  './assets/images/RFID_Scan.gif';
    }
  }

  ngAfterViewInit() {
    if (this.data.isHiddenFieldRequired && this.cardfield) {
      setTimeout(() => {
        this.cardfield.nativeElement.focus();
      }, 0);
    }
  }

  ngOnDestroy() {
    this.inputSubscription$.unsubscribe();
  }

  DialogClose = (result) => {
    this.dialog.close(result);
  }
}
