import { HttpServiceCall, HttpMethod } from "../shared/service/http-call.service";
import { Injectable } from "@angular/core";
import { RetailRoutes } from 'src/app/retail/retail-route';
import { Host } from "../shared/globalsContant";

@Injectable({
    providedIn: 'root'
})
export class DiscountMappingService {
    constructor(private http: HttpServiceCall) {
    }

    async GetDiscountTypes(): Promise<any[]> {
        var result = await this.http.CallApiAsync<any[]>({
            callDesc: RetailRoutes.GetDiscountTypes,
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return result.result;
    }

    async GetMembershipTypes(): Promise<any[]> {
        var result = await this.http.CallApiAsync<any[]>({
            callDesc: RetailRoutes.GetMembershipTypes,
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return result.result;
    }

    async GetDiscountTypeLinking(): Promise<any[]> {
        var result = await this.http.CallApiAsync<any[]>({
            callDesc: RetailRoutes.GetDiscountTypeLinking,
            host: Host.retailManagement,
            method: HttpMethod.Get
        });
        return result.result;
    }

    async CreateDiscountTypeLinking(linkData : any): Promise<boolean> {
        var result = await this.http.CallApiAsync<boolean>({
            callDesc: RetailRoutes.CreateDiscountTypeLinking,
            host: Host.retailManagement,
            method: HttpMethod.Post,
            body: linkData
        });
        return result.result;
    }

    async CreateBulkDiscountTypeLinking(linkData : any): Promise<boolean> {
        var result = await this.http.CallApiAsync<boolean>({
            callDesc: RetailRoutes.CreateBulkDiscountTypeLinking,
            host: Host.retailManagement,
            method: HttpMethod.Post,
            body: linkData
        });
        return result.result;
    }

    async GetDiscountFromMapping(discountTypeMapping:any[]): Promise<any[]> {
        var result = await this.http.CallApiAsync<any[]>({
            callDesc: RetailRoutes.GetDiscountFromMapping,
            host: Host.retailManagement,
            method: HttpMethod.Put,
            body: discountTypeMapping
        });
        return result.result;
    }


}
