import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { AgInputFieldConfig, ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { DmConfig } from 'src/app/common/Models/common.models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertType, AlertAction } from 'src/app/common/Models/common.models';
import { ButtonType, Product } from 'src/app/common/enums/shared-enums';
import { takeUntil } from 'rxjs/operators';
import { mailTemplate } from 'src/app/common/data-magine/dm-eforms/dm-eforms.model';
import { SPAEformLinkMapping } from 'src/app/common/data-magine/data-magine-integration/data-magine-models';
import { EformsBusiness } from 'src/app/common/data-magine/dm-eforms/dm-eforms.business';
@Component({
  selector: 'app-dm-eforms-spa',
  templateUrl: './dm-eforms-spa.component.html',
  styleUrls: ['./dm-eforms-spa.component.scss'],
  providers: [EformsBusiness]
})
export class DmEformsSpaComponent implements OnInit {

  captions: any;
  includeInactive: boolean = false;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  eforms: UntypedFormGroup;
  emailConfig: AgInputFieldConfig;
  filteredItems = [];
  searchText: any;
  unitItems = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  options: TableOptions;
  emailHeaderOptions: TableHeaderOptions[];
  emailTableContent: mailTemplate[];
  selectedtemplate: mailTemplate;
  max: number = -1;
  count: number;
  dmConfig: DmConfig;
  token: string = '';
  senderEmail = '';
  FromName = '';
  MailBody = '';
  MailSubject = '';
  emailValue: string;
  confNo: string;
  guestId: string;
  eformLinks: SPAEformLinkMapping[] = [];
  isFromNotiicationConfig: boolean = false;
  appointmentId: number;
  isGenerateEform: boolean = false;
  isFromSpaEditService: boolean = false;
  eFormData: any = [];
  fromScreenId: string = '';
  skipDMCall: boolean = false;
  appointmentDate : string;
  eformsPatchList = [];
  isEnableEFormExpiry: string = '';
  productId;
  constructor(@Inject(MAT_DIALOG_DATA) data: any, private localization: Localization, private business:
    EformsBusiness, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<DmEformsSpaComponent>, private utilities: CommonUtilities) {
    this.isFromNotiicationConfig = data ? data.isFromNotiicationConfig : false;
    this.isGenerateEform = data ? data.isGenerateEform : false;
    this.isFromSpaEditService = data ? data.isFromSpaService : false;
    this.appointmentId = data ? data.appointmentId : 0;
    this.guestId = data ? data.guestId : "";
    this.appointmentDate = data?data.appointmentDate:"";
    if (data.formList) {
      this.eformsPatchList = data.formList;
      this.patchValue();
    }

    if (data?.dataSource && data?.dataSource.length > 0) {
      data.dataSource.map(x =>
        this.filteredItems.push({
          id: x.eformId,
          code: x.eformName,
          checked: x.checked ? x.checked : false
        }));
    }
    this.eFormData = data?.data ?? [];
    this.fromScreenId = data?.screenId ?? '';
    this.skipDMCall = data?.skipDMCall ?? false;
    this.isEnableEFormExpiry = data.isEnableEFormExpiry;
  }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.productId = this.localization.GetPropertyInfo("ProductId");
    this.setActionButtons();
    this.business.getDmConfigSession().then(dm => {
      if (dm && dm?.enableDataMagine) {
        this.dmConfig = dm;
        this.checkDM();
        this.saveButton.disabledproperty = false;
      } else {
        this.utilities.showAlert(this.captions.lbl_DMConfig_empty_msg, AlertType.Error, ButtonType.Ok, async (res) => {
        });
      }
    });
  }

  async checkDM() {
    this.business.getDMToken(this.dmConfig?.dmEformsConfig?.authURL, this.dmConfig?.dmEformsConfig?.clientId, this.dmConfig?.dmEformsConfig?.clientSecret).then(res => {
      if (res && res?.access_token) {
        this.token = res?.access_token;
        this.initializeEforms();
      } else {
        this.utilities.showAlert(this.captions.lbl_DMConfig_empty_msg, AlertType.Error, ButtonType.Ok, async (res) => {
        });
      }
    });

  }

  initializeEforms() {
    this.FromName = this.localization.GetPropertyInfo('PropertyName');
    this.buildForm();
    this.initialize();
    //this.generateTable();
  }

  buildForm() {
    this.eforms = this.fb.group({
      selectContact: '',
      emailAddress: [this.emailValue, Validators.required],
      emailTemplateId: ''
    });
  }

  setActionButtons() {
    if (this.isFromNotiicationConfig || !this.isFromSpaEditService) {
      this.saveButton = {
        type: 'primary',
        label: this.captions.btn_save,
        disabledproperty: true
      }
    }
    else if (this.isGenerateEform) {
      this.saveButton = {
        type: 'primary',
        label: "Generate",
        disabledproperty: true
      }

    }
    else if (this.isFromSpaEditService) {
      this.saveButton = {
        type: 'primary',
        label: this.captions.btn_update,
        disabledproperty: true
      }
    }
    else {
      this.saveButton = {
        type: 'primary',
        label: this.captions.btn_sendEmail,
        disabledproperty: true
      }
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
  }

  async initialize() {
    if ( !this.skipDMCall) {
      this.filteredItems = await this.business.getEformsDropDownList(this.token, this.dmConfig?.dmEformsConfig?.eformsListURL, this.dmConfig?.dmEformsConfig?.eformsTenantId, this.dmConfig?.dmEformsConfig?.eformsPropertyId);

    }
    if (this.eFormData.length > 0) {
      this.eFormData.forEach(x => {
        this.filteredItems.find(y => y.id === x.eFormID).checked = true;
      });
    }
    this.unitItems = this.filteredItems;
    this.patchValue();
    this.emailConfig = {
      className: 'ag_form_wrapper--xs ag-pl-8',
      form: this.eforms,
      formControlName: 'emailAddress',
      placeHolderId: 'lbl_email',
      placeHolder: this.captions.lbl_guest_email,
      inputType: 'email',
      minlength: 1,
      maxlength: 50,
      showRequired: true,
      errorMessageId: 'err_missingEmail',
      errorMessage: this.captions.err_guestemail
    };

    this.eforms.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.saveButton.disabledproperty = !(this.eforms.valid && this.eforms.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }

  patchValue() {
    if (this.filteredItems.length > 0) {
      this.filteredItems.forEach(x => {
        this.eformsPatchList.forEach(y => {
          if (x.id === y.eFormId) {
            x.checked = true;
          }
        })
      })
      this.filteredItems = [...this.filteredItems];
    }
  }



  toggle(item, eve) {
    this.count = 0;
    this.filteredItems.forEach(x => {
      if (x.id == item.id) {
        if (eve) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      }
      if (x.checked) {
        this.count++;
      }
    });
    this.filteredItems = [...this.filteredItems];
    this.eforms.markAsDirty();
  }

  async onSave(eve) {
    this.saveButton.disabledproperty = true;
    this.saveButton = { ...this.saveButton };
    let eformsLinkURL = this.dmConfig?.dmEformsConfig?.eformsLinkURL;
    let eligible = this.filteredItems.filter(x => x.checked == true);
    if(this.dmConfig!=undefined && this.dmConfig.dmEformsConfig.configExpiryDate !=undefined && this.dmConfig.dmEformsConfig.configExpiryDate.toString() == 'true' && this.appointmentDate){
      eformsLinkURL = eformsLinkURL + "&ExpiryDate={3}";
      eformsLinkURL = eformsLinkURL.replace("{3}",this.appointmentDate.split('T')[0]);
    }
    if (eligible && eligible.length > 0) {

      await Promise.all(eligible.map(async x => {
        let tkn = await this.business.getDMToken(this.dmConfig?.dmEformsConfig?.authURL, this.dmConfig?.dmEformsConfig?.clientId, this.dmConfig?.dmEformsConfig?.clientSecret);
        if (tkn && tkn?.access_token) {
          this.token = tkn?.access_token;
          try{
            let rs = await this.business.getSpaformsLinkURL(x.id, this.token, eformsLinkURL
            , { appointmentId: this.appointmentId, guestId: this.guestId });
            if (rs && rs.result) {
              this.eformLinks.push({
                link: rs.result,
                eFormName: x.code,
                eFormId: x.id
              });
            }
          }
          catch(error){
            this.utilities.showAlert(error.error.errorMessage, AlertType.Error, ButtonType.Ok, async (res) => {
            });
          }
        }
      }));


    }
    this.dialogRef.close(this.eformLinks);
  }

  searchValueChange(e) {
    this.searchText = e.trim().toLowerCase();
    if (this.searchText) {
      this.filteredItems = this.unitItems.filter(x => x.code.toLowerCase().includes(this.searchText));
    } else {
      this.filteredItems = this.unitItems;
    }
  }

  onCancel(eve) {
    if (this.eforms && this.eforms.dirty) {
      this.utilities.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  onDrop(e) {

  }
}
