import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AlertAction,
  AlertType,
  ButtonType,
  ButtonTypes,
  Product,
  PlayerCategory
} from "src/app/common/enums/shared-enums";
import * as AlertOption from "../../shared/shared.modal";
import { ButtonValue } from "src/app/common/Models/ag-models";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { FolioBusiness } from "../../Folio/folio-business";
import {
  CreateDefaultFolioRequest,
  ErrorConstants,
  FolioLookup,
  FolioSearchConstants,
  LengthConstants,
  MiscConfigurationSwitches,
  SearchType,
  SourceType,
} from "../../Folio/Model/folioDetails-model";
import { PayeeInfo } from "../../shared/business/shared.modals";
import { ReplaySubject, Subject } from "rxjs";
import { debounceTime, mergeMap, takeUntil } from "rxjs/operators";
import { SEARCH_DEBOUNCE_TIME } from "src/app/common/shared/shared/setupConstants";
import { RetailDataAwaiters } from "../../shared/events/awaiters/retail.data.awaiters";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { Router } from "@angular/router";
import { FacadeService } from "src/app/common/services/facade.service";
import { UserAccessService } from "src/app/common/services/user-access.service";
import { UserAccessBreakPoints } from "../../shared/constants/useraccess.constants";
import { MemberBusinessService } from "../../shared/business/Member-business.service";
import * as GlobalConst from "../../shared/globalsContant";
import { NotifierBar } from "src/app/common/components/note/note.model";
import { BreakPointAccess } from "../../shared/service/breakpoint.service";

@Component({
  selector: "app-folio-dialog-popup",
  templateUrl: "./folio-dialog-popup.component.html",
  styleUrls: ["./folio-dialog-popup.component.scss"],
  providers: [FacadeService, UserAccessService]
})
export class FolioDialogPopupComponent implements OnInit {
  floatLabel: string;
  captions: any;
  form: UntypedFormGroup;
  folioTypeSearch: boolean = false;
  allData: any = [];
  selectedGuestTagKey: string[] = ["name"];
  autoCompleteKeys: string[] = ['name'];
  searchGuestMaxLength = 50;
  tagSearchKey: string[] = ["name"];
  selectedTagData: any = [];
  proceedButton: ButtonValue;
  cancelButton: ButtonValue;
  folios: any = [];
  selectedFolio: any;
  showSearch: boolean = false;
  payeeInfo: PayeeInfo;
  SearchString: string = "";
  searchByFolio: boolean = true;
  searchByMember: boolean = false;
  searchByBooking: boolean = false;
  guestSearchTextChanged = new Subject<string>();
  folioSearchTextChanged = new Subject<string>();
  memberSearchTextChanged = new Subject<string>();
  bookingSearchTextChanged = new Subject<string>();
  rfidDataSearchTextChanged = new Subject<string>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedGuest: any;
  isSncFlow: boolean = false;
  commonCaptions = this.localization.captions;
  folioInvoiceNumber: string = '';
  overAllBalance: string = '';
  defaultFolio = {
    name: this.commonCaptions.lbl_default,
    number: this.commonCaptions.lbl_default,
    default: true,
    balance: '0',
    outstandingBalance: '0',
    id: 0,
    folioInvoiceNumber: '',
    sourceType: '',
    interfaceGuestId: ''
  }
  folioMenubp: boolean = false;
  addOnMember = SourceType.AddOnMember;
  isFolioEngageTurnedOn: boolean = false;
  selectedGuestInfoId: number = 0;
  noteBar: NotifierBar;
  noteFlag: boolean = false;
  isFromShop: boolean = false;
  currentDate: Date;
  RFIDDialog: MatDialogRef<any, any>;
  disableSearchBox: boolean = false;
  searchByRFIDFlag: boolean;
  selectedType: string;
  RFIDNumber: string;
  RFIDDataToCreateFolio: string;
  createAndLinkRFIDFlow: boolean = false;
  RFIDEnabled: boolean;
  hideFolioCloseSelection: boolean;
  rfidData: any;

  constructor(
    public dialogRef: MatDialogRef<FolioDialogPopupComponent>,
    private fb: UntypedFormBuilder,
    public localization: RetailLocalization,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: CommonUtilities,
    private business: FolioBusiness,
    private cd: ChangeDetectorRef,
    private retailUtils: RetailUtilities,
    private route: Router,
    private facadeService: FacadeService,
    private userAccessService: UserAccessService,
    private memberService: MemberBusinessService,
    private BP: BreakPointAccess,
  ) {
    this.captions = this.localization.captions.shop;
    this.floatLabel = this.localization.setFloatLabel;
    this.SearchString = this.captions.srch_folioname;
    let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
    this.isSncFlow = productId == Product.SALESANDCATERING;
    this.currentDate = this.utils.PropertyInfo.CurrentDate
  }



  async ngOnInit() {
    this.selectedGuest = this.data.data.payeeInfo;
    this.hideFolioCloseSelection = this.selectedGuest && this.isSncFlow;
    this.isFromShop = this.data.isFromShop;
    this.initializeForm();
    let sessionValue = sessionStorage.getItem('FolioConfigurationSwitches');
    let config = sessionValue ? JSON.parse(sessionValue) : null;
    if (config?.configValue) {
      var configValues = JSON.parse(config.configValue),
        result = Object.keys(configValues).map(k => ({
          configurationKey: [k][0],
          configurationValue: configValues[k]
        }));
    }
    const productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
    const isEngageFeatureOn: boolean = result?.find(x => x.configurationKey === MiscConfigurationSwitches.agilysysEngage)?.configurationValue ?? false;
    this.RFIDEnabled = result?.find(x => x.configurationKey === MiscConfigurationSwitches.rfidSupportForFolio)?.configurationValue ?? false
    if (productId == Product.SPA && isEngageFeatureOn) {
      this.isFolioEngageTurnedOn = true;
    }
    if (this.selectedGuest) {
      this.selectedGuest.guestId = (this.selectedGuest?.playerCategoryId == PlayerCategory.Member || this.selectedGuest?.guesttype == GlobalConst.ClientType.Member) ? this.data.data.memberGuestId : this.selectedGuest?.guestProfileId;
    }

    this.guestSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchGuestByName(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();

    this.memberSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchMemberByName(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();

    this.folioSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchByFolioInvoiceNumber(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();
    this.bookingSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchBookingForFolio(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();

    this.rfidDataSearchTextChanged
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        mergeMap((source) => this.searchByRFIDData(source))
      ).pipe(takeUntil(this.destroyed$)).subscribe();

    if (this.data.data.isGuest && !this.isSncFlow) {
      try {
        await this.getFolioDetails(this.selectedGuest.guestId);
        if (this.folios?.length > 0) {
          this.folioTypeSearch = true;
        } else {
          this.utils.showCommonAlert(this.captions.warn_create_new_folio, AlertType.Warning, ButtonTypes.YesNo, (res) => {
            if (res === AlertAction.YES) {
              this.createDefaultFolioForGuest();
              this.patchSelectedFolio();
            } else {
              this.selectedGuest = null;
            }
          });
        }
      }
      catch (e) {
        this.retailUtils.ToggleLoaderWithMessage(false);
        this.handleError(e, SearchType.GUESTINFO);
      }

    } else if (this.data.data.isGuest && this.isSncFlow) {
      await this.searchByBookingFolioInvoiceNumber(this.selectedGuest.folioInvoiceNumber);
      if (this.selectedGuest?.folios?.length > 0) {
        this.patchSelectedFolio();
      }
    }
    this.CheckBreakPointAccess();
  }

  CheckBreakPointAccess() {
    this.facadeService.getUserAccess(UserAccessBreakPoints.FOLIOMENU, false).then(o => {
      this.folioMenubp = o.isAllow;
    });
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  initializeForm() {
    let type = this.selectedGuest?.playerCategoryId == PlayerCategory.Member ? "member" : "folio";
    this.form = this.fb.group({
      folio: [""],
      type: [type],
    });
    this.proceedButton = {
      label: this.captions.btn_proceed,
      type: "primary",
      disabledproperty: true
    };
    this.cancelButton = {
      label: this.captions.cancel,
      type: "secondary",
    };
    this.selectedType = "folio";
  }

  onGuestChipRemoveEvent(event: any) {
    this.allData = [];
    this.selectedGuest = null;
    this.enableDisableProceedBtn(true);
    this.folios = [];
    this.folioInvoiceNumber = ''
    this.overAllBalance = '';
    this.selectedFolio = null;
  }

  tagSearchTextEmit(searchInput: string) {
    if (!this.searchByFolio && !this.searchByMember && !this.searchByBooking && !this.searchByRFIDFlag && searchInput?.length >= LengthConstants.guestLen) {
      this.guestSearchTextChanged.next(searchInput);
    }
    else if (this.searchByMember && !this.searchByRFIDFlag && searchInput?.length >= LengthConstants.guestLen) {
      this.memberSearchTextChanged.next(searchInput);
    }
    else if (this.searchByFolio && !this.searchByRFIDFlag && searchInput?.length > LengthConstants.FolioLen) {
      this.folioSearchTextChanged.next(searchInput);
    }
    else if (this.searchByBooking && searchInput?.length >= LengthConstants.guestLen) {
      this.bookingSearchTextChanged.next(searchInput);
    }
    else if (this.searchByRFIDFlag && !this.searchByFolio && !this.searchByMember && searchInput?.length > LengthConstants.FolioLen) {
      this.rfidDataSearchTextChanged.next(searchInput);
    }
    else {
      this.allData = [];
    }
  }

  enableDisableProceedBtn(value: boolean) {
    this.proceedButton.disabledproperty = value;
    this.proceedButton = { ...this.proceedButton };
  }

  onBlur(e) { }

  setGuestId(id, GuestId) {
    this.selectedGuest.guestId = GuestId;
    this.data.data.memberGuestId = GuestId;
    this.selectedGuestInfoId = id;
  }

  async selectedTagEmit(eve) {
    this.selectedGuest = eve[0];
    if (this.searchByFolio) {
      this.patchSelectedFolio();
    }
    else if (this.searchByMember) {
      let scheduleDate = new Date();
      this.retailUtils.ToggleLoaderWithMessage(true, this.commonCaptions.lbl_inProgress)
      const memberInfo = await this.memberService.getMemberInfo(this.selectedGuest?.guestId, scheduleDate.toISOString())
      if (memberInfo == null) {
        this.retailUtils.ToggleLoaderWithMessage(false)
        this.closeFolio();
        return;
      }
      await RetailDataAwaiters.CreatePlayer(this.selectedGuest, this.setGuestId.bind(this));
      await this.searchByMemberGuestId(this.selectedGuest?.guestId);
      console.log(this.selectedGuest)
      if (this.selectedGuest?.folios?.length > 0) {
        this.patchSelectedFolio();
      }
    }
    else if (this.searchByBooking) {
      await this.searchByBookingFolioInvoiceNumber(this.selectedGuest.folioInvoiceNumber);
      if (this.selectedGuest?.folios?.length > 0) {
        this.patchSelectedFolio();
      }
    }
    else {
      this.selectedGuestInfoId = this.selectedGuest.id;
      await this.searchByGuestId(this.selectedGuest.guestId);
      console.log(this.selectedGuest)
      if (this.selectedGuest?.folios?.length > 0) {
        this.patchSelectedFolio();
      }
      // else {
      //   this.utils.showAlert(this.captions.warn_create_new_folio, AlertType.Warning, ButtonType.YesNo,
      //     (res) => {
      //       if (res === AlertAction.YES) {
      //         this.createDefaultFolioForGuest();
      //       } else {
      //         this.selectedGuest = null;
      //         this.dialogRef.close({ from: 'cancel' });
      //       }
      //     }
      //   );
      // }
    }
  }

  MaskRFIDNumber(cardNo: string): string {
    if (cardNo.length <= 10) {
      return 'X'.repeat(cardNo.length - 4) + cardNo?.substring(cardNo.length - 4);
    } else {
      return 'XXXXXX' + cardNo?.substring(cardNo.length - 4);
    }
  }

  patchSelectedFolio() {
    this.folioTypeSearch = true;
    this.folios = [];
    this.folios = this.selectedGuest?.folios;
    this.folioInvoiceNumber = this.selectedGuest?.folio?.folioInvoiceNumber;
    this.overAllBalance = this.localization.localizeCurrency(this.selectedGuest?.folio?.overAllBalance);
    this.RFIDNumber = this.createAndLinkRFIDFlow ? this.MaskRFIDNumber(this.RFIDDataToCreateFolio) : this.folios && this.folios[0] && this.folios[0]?.rfidData ? this.MaskRFIDNumber(this.folios[0].rfidData) : ' ';
    this.defaultFolio.outstandingBalance = this.folios?.find(x => x.isDefault)?.outstandingBalance;
    this.defaultFolio.balance = this.folios?.find(x => x.isDefault)?.balance;
    this.defaultFolio.sourceType = this.folios && this.folios[0] ? this.folios[0].sourceType : SourceType.AddOn.toString();
    this.defaultFolio.folioInvoiceNumber = this.folios && this.folios[0] ? this.folios[0].folioInvoiceNumber : '';
    this.defaultFolio.interfaceGuestId = this.folios && this.folios[0] ? this.folios[0].interfaceGuestId : '0';
    this.folios.unshift(this.defaultFolio);
    this.selectedFolio = this.folios[0];
    this.enableDisableProceedBtn(false);
  }

  async createDefaultFolioForGuest() {
    this.retailUtils.ToggleLoaderWithMessage(true, this.commonCaptions.lbl_inProgress)
    let guestResortFinanceFolioCardTokenReference = 0;
    const guestCardOnFileTokenReference = this.selectedGuest?.cardInfo ? this.selectedGuest?.cardInfo[0]?.tokenTransId : 0;
    if (guestCardOnFileTokenReference) {
      this.business.GetResortFinanceCardTokenReference(guestCardOnFileTokenReference).then(async result => {
        guestResortFinanceFolioCardTokenReference = result;
        await this.createFolio(guestResortFinanceFolioCardTokenReference);
      });
    }
    else {
      await this.createFolio(0);
    }
    this.retailUtils.ToggleLoaderWithMessage(false);
    this.retailUtils.ToggleLoaderWithMessage(false);
  }

  async createDefaultFolioForBooking() {
    this.retailUtils.ToggleLoaderWithMessage(true, this.commonCaptions.lbl_inProgress)
    try {
      await this.createFolioForBooking();
    }
    catch (e) {
      this.retailUtils.ToggleLoaderWithMessage(false);
    }
  }

  async createFolio(guestCardTokenReference) {
    const propertyDate = this.retailUtils.PropertyInfo.CurrentDTTM;
    const request: CreateDefaultFolioRequest = {
      sourceType: this.business.getSourceType(this.data?.data),
      sourceTypeId: this.selectedGuest.guestId,
      paymentTransactionId: guestCardTokenReference,
      startDate:this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      RFIDData: this.RFIDDataToCreateFolio ?? null
    };
    await this.createDefaultFolio(request);  // Wait for this to complete first
    await this.getFolioDetails(this.selectedGuest.guestId); // Now wait for getFolioDetails to complete
    this.folioTypeSearch = true;
    this.retailUtils.ToggleLoaderWithMessage(false);
  }

  async createFolioForBooking() {
    try {
      let model = {
        bookingId: this.selectedGuest.id,
        bookingNumber: this.selectedGuest.bookingId,
        bookingName: this.selectedGuest.bookingName ?? this.selectedGuest.name,
      }
      RetailDataAwaiters.createFolioForBooking(model).then(async (x) => {
        await this.getFolioDetailsForBooking(
          x.folioInvoiceNumber
        );
        this.folioTypeSearch = true;
        this.retailUtils.ToggleLoaderWithMessage(false);
      });
    } catch (er) {

    }
  }


  onAction() {
    this.selectedFolio.folioInvoiceNumber = this.folioInvoiceNumber;
    this.dialogRef.close({
      from: 'save',
      selectedGuest: this.selectedGuest,
      selectedFolio: this.selectedFolio,
      folios: this.folios
    });
  }

  folioChange(eve, i) {
    this.folios.forEach((x) => {
      x.default = false;
    });
    this.selectedFolio = eve;
    this.folios[i].default = true;
  }

  closeFolio() {
    this.selectedTagData = [];
    this.showSearch = true;
    this.folioTypeSearch = false;
    this.allData = [];
    this.selectedGuest = null;
    this.enableDisableProceedBtn(true);
    this.folios = [];
    this.selectedFolio = null;
    this.folioInvoiceNumber = '';
    this.overAllBalance = '';
    this.RFIDDataToCreateFolio = '';
  }

  onRadioButtonClick(value: string) {
    if (this.selectedType === value) {
      const tempValue = null;
      this.selectedType = tempValue;
      setTimeout(() => {
        this.selectedType = value;
      }, 0);
    } else {
      this.selectedType = value;
    }
    this.typeChange(value);
  }

  typeChange(eve) {
    this.disableSearchBox = false;
    switch (eve) {
      case FolioSearchConstants.Folio:
        this.SearchString = this.captions.srch_folioname;
        this.searchByFolio = true;
        this.searchByMember = false;
        this.searchByRFIDFlag = false;
        this.noteFlag = false;
        this.searchByBooking = false;
        break;
      case FolioSearchConstants.Guest:
        this.SearchString = this.captions.srch_guest;
        this.searchByFolio = false;
        this.searchByMember = false;
        this.searchByRFIDFlag = false;
        this.noteFlag = false;
        break;
      case FolioSearchConstants.Member:
        if (this.isFromShop) {
          this.noteBar = {
            class: '',
            header: this.captions.MemberNoteHeader,
            value1: this.captions.MemberDiscountNoteInfo,
            value2: this.captions.MemberDiscountNoteConfirmation,
            isRemovable: false
          }
          this.noteFlag = true;
        }
        this.SearchString = this.captions.srch_member;
        this.searchByFolio = false;
        this.searchByMember = true;
        this.searchByRFIDFlag = false;
        break;
      case FolioSearchConstants.RFIDData:
        this.performRFIDOperation();
        this.searchByFolio = false;
        this.searchByRFIDFlag = true;
        this.searchByMember = false;
        this.noteFlag = false;
        break;
      case FolioSearchConstants.Booking:
        this.SearchString = this.captions.srch_booking;
        this.searchByFolio = false;
        this.searchByMember = false;
        this.noteFlag = false;
        this.searchByBooking = true;
        this.searchByRFIDFlag = false;
        break;
    }
  }
  performRFIDOperation() {
    const popupMessage = this.commonCaptions.shop.SwipeRFID;
    const dataObj = { 'text': popupMessage, 'buttonname': this.localization.captions.common.Close, 'headertext': '', 'isloaderenable': true, 'cardpayment': true, 'isHiddenFieldRequired': true, 'isRFID': true };
    this.disableSearchBox = true;
    this.RFIDDialog = this.utils.OpenCardSwipeDialog(dataObj, this.CloseDialog.bind(this));
  }

  async CloseDialog(data: string): Promise<void> {
    if (data && data.toLowerCase() != this.localization.captions.common.Close.toLowerCase()) {
      if (this.searchByRFIDFlag && !this.createAndLinkRFIDFlow && data.length > LengthConstants.FolioLen) {
        this.rfidDataSearchTextChanged.next(data);
      }
      if (this.createAndLinkRFIDFlow && data.length > LengthConstants.FolioLen) {
        this.RFIDDataToCreateFolio = data;
      }
      else{
        return ;
      }
    } else {
      this.RFIDDataToCreateFolio = '';
      return ;
    }
    this.RFIDDialog.close();
    if (this.createAndLinkRFIDFlow) {
      if (this.RFIDDataToCreateFolio && this.RFIDDataToCreateFolio.trim() !== '') {
        try {
          await this.createDefaultFolioForGuest();
        } catch (e) {
          if (e.error.errorCode == ErrorConstants.RFIDEXISTS) {
            let errorMessage = this.localization.getError(e.error.errorCode);
            errorMessage = this.localization.replacePlaceholders(errorMessage, ['Guest'], [e.error.result.toString()]);
            this.utils.showError(errorMessage);
            this.selectedTagData = [];
            this.selectedGuest = null;
            this.allData = [];
          }
          return;
        }
        this.patchSelectedFolio();
        this.createAndLinkRFIDFlow = false;
        this.utils.showCommonAlert(this.captions.rfidScanSuccess, AlertType.Success);
        this.RFIDDataToCreateFolio = '';
      } else {
        this.selectedTagData = [];
        this.selectedGuest = null;
        this.allData = [];
        this.RFIDDataToCreateFolio = ''
        return;
      }
    }
  }

  async getFolioDetails(guestId: string) {
    this.retailUtils.ToggleLoaderWithMessage(true, this.commonCaptions.lbl_inProgress)
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      folioInvoiceNumber: [],
      sourceTypeId: [guestId],
      status: [0],
      startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      checkDate:!this.isSncFlow
    }
    const foliodetail = await this.business.folioLookup(request);
    this.folios = [];
    if (foliodetail && foliodetail.length > 0) {
      const data = foliodetail[0];
      data.folioLookupData.forEach((x) => {
        this.folios.push({
          id: x.folioId,
          name: x.folioName,
          number: x.folioNumber,
          balance: this.localization.localizeCurrency(x.creditLimit),
          isDefault: x.isDefault,
          default: false,
          folioInvoiceNumber: data.folioInvoiceNumber,
          outstandingBalance: this.localization.localizeCurrency(x.outstandingBalance),
          sourceType: data.sourceType,
          interfaceGuestId: data.guestDetailInfo?.interfaceGuestId,
          rfidData: x?.rfidData
        });
      });
      this.folioInvoiceNumber = this.folios[0]?.folioInvoiceNumber;
      this.overAllBalance = this.localization.localizeCurrency(data.overAllBalance.toString());
      this.defaultFolio.outstandingBalance = this.folios.find(x => x.isDefault)?.outstandingBalance;
      this.RFIDNumber = this.MaskRFIDNumber(this.folios.find(x => x.isDefault)?.rfidData);
      this.defaultFolio.balance = this.folios.find(x => x.isDefault)?.balance;
      this.defaultFolio.sourceType = data.sourceType;
      this.defaultFolio.folioInvoiceNumber = data.folioInvoiceNumber;
      this.defaultFolio.interfaceGuestId = data.guestDetailInfo?.interfaceGuestId
      this.folios.unshift(this.defaultFolio);
      this.selectedFolio = this.folios[0];
      this.selectedGuest =
      {
        id: 0,
        sourceType: data.sourceType,
        guestId: data.guestDetailInfo.guestId,
        sourceTypeId: data.sourceTypeId,
        guestInfoId: this.selectedGuestInfoId,
        folioNumber: data.folioInvoiceNumber,
        overAllBalance: this.localization.localizeCurrency(data.overAllBalance),
        lastName: data.guestDetailInfo.lastName,
        firstName: data.guestDetailInfo.firstName,
        name: data.guestDetailInfo.firstName + ' ' + data.guestDetailInfo.lastName,
        phoneNumber: data.guestDetailInfo.phoneNumber?.length > 0 ? data.guestDetailInfo.phoneNumber[0] : '',
        email: data.guestDetailInfo.email?.length > 0 ? data.guestDetailInfo.email[0] : '',
        folio: data,
        folios: this.folios,
      }

      this.enableDisableProceedBtn(false);
    } else {
      this.enableDisableProceedBtn(true);
      this.selectedFolio = null;
      this.folioInvoiceNumber = ''
      this.overAllBalance = '';
    }
    this.retailUtils.ToggleLoaderWithMessage(false);
    return foliodetail;
  }

  async createDefaultFolio(folio: CreateDefaultFolioRequest) {
    const foliodetail = await this.business.createDefaultFolio(folio);
    return foliodetail;
  }

  async searchGuestByName(name: string) {
    const requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
    const guestInfo = await RetailDataAwaiters.searchPayee(name, 2, requestUid);
    if (guestInfo) {
      console.log(guestInfo);
      this.allData = this.uiMapper(guestInfo[1]);
      this.cd.detectChanges();
      console.log(this.allData);
    }
  }

  async searchMemberByName(name: string) {
    const guestInfo = await this.memberService.searchGuest(name, 1);
    if (guestInfo) {
      console.log(guestInfo);
      this.allData = this.uiMapper(guestInfo[1]);
      this.cd.detectChanges();
      console.log(this.allData);
    }
  }

  uiMapper(guests) {
    const guestInfos = [];
    guestInfos.push(
      guests.map((guest) => {
        return {
          id: guest.id,
          name: guest.name,
          address: guest.address,
          guestId: guest.guestProfileId,
          phoneNumber: guest.phoneNumber,
          country: guest.country,
          zip: guest.zip,
          city: guest.city,
          cardInfo: guest.cardInfo,
          folios: [],
          playerLinkId: guest.guestProfileId,
          firstName: guest.firstName,
          lastName: guest.lastName,
          pronounced: '',
          paymentReferenceId: guest?.paymentReferenceId
        };
      })
    );
    return guestInfos[0];
  }

  async searchByMemberGuestId(guestId: string) {
    if (guestId) {
      const request: FolioLookup = {
        sourceType: SourceType.AddOnMember.toString(),
        sourceTypeId: [guestId],
        folioInvoiceNumber: [],
        status: [0],
        startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
        endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
        checkDate:!this.isSncFlow
      };
      await this.folioSearch(request, SearchType.GUESTINFO);
    }
  }

  async searchByFolioInvoiceNumber(folioNumber: string) {
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      sourceTypeId: [],
      folioInvoiceNumber: [folioNumber],
      status: [0],
      includeMemberFolio: true,
      startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      checkDate: !this.isSncFlow
    };
    await this.folioSearch(request, SearchType.FOLIOINVOICENUMBER);
  }

  async searchBookingForFolio(name: string) {
    const bookingInfo = await RetailDataAwaiters.getBookingSearchForFolio(name);
    if (bookingInfo) {
      this.allData = bookingInfo.map(o => {
        return {
          ...o,
          name: o.bookingId + " - " + o.bookingName
        };
      });
      this.cd.detectChanges();
    }
  }

  async searchByBookingFolioInvoiceNumber(folioNumber: string) {
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      sourceTypeId: [],
      folioInvoiceNumber: [folioNumber],
      status: [0],
      includeMemberFolio: true,
      startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      checkDate: !this.isSncFlow
    };
    await this.folioSearch(request, SearchType.BOOKING);
  }
  async searchByRFIDData(RFIDData: string) {
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      sourceTypeId: [],
      folioInvoiceNumber: [],
      status: [0],
      RFIDData: RFIDData,
      startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate)
    };
    await this.folioSearch(request, SearchType.RFIDDATA);
  }

  async searchByGuestId(guestId: string) {
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(),
      sourceTypeId: [guestId],
      folioInvoiceNumber: [],
      status: [0],
      startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      checkDate:!this.isSncFlow
    };
    await this.folioSearch(request, SearchType.GUESTINFO);
  }

  async folioSearch(requestWithFolioInvoiceNumber: FolioLookup, searchType: SearchType) {
    let folioInfo;
    try {
      this.retailUtils.ToggleLoaderWithMessage(true, this.commonCaptions.lbl_inProgress)
      folioInfo = await this.business.folioLookup(requestWithFolioInvoiceNumber);
      this.retailUtils.ToggleLoaderWithMessage(false)
    }
    catch (e) {
      console.log(e);
      this.retailUtils.ToggleLoaderWithMessage(false)
      await this.handleError(e, searchType);
    }


    if (folioInfo && folioInfo?.length > 0) {
      const data = [];
      folioInfo.forEach(r => {
        const folios = [];
        if (r.folioLookupData?.length > 0) {
          r.folioLookupData.forEach((x) => {
            folios.push({
              id: x.folioId,
              name: x.folioName,
              number: x.folioNumber,
              balance: this.localization.localizeCurrency(x.creditLimit),
              default: false,
              folioInvoiceNumber: r.folioInvoiceNumber,
              isDefault: x.isDefault,
              outstandingBalance: this.localization.localizeCurrency(x.outstandingBalance),
              sourceType: r.sourceType,
              interfaceGuestId: r.guestDetailInfo?.interfaceGuestId,
              rfidData: x?.rfidData
            });
          });
        }

        this.defaultFolio.outstandingBalance = folios?.find(x => x.isDefault)?.outstandingBalance;
        this.defaultFolio.balance = folios?.find(x => x.isDefault)?.balance;

        data.push({
          id: 0,
          sourceType: r.sourceType,
          guestId: r.guestDetailInfo.guestId,
          sourceTypeId: r.sourceTypeId,
          guestInfoId: this.selectedGuestInfoId,
          folioNumber: r.folioInvoiceNumber,
          overAllBalance: this.localization.localizeCurrency(r.overAllBalance),
          lastName: r.guestDetailInfo.lastName,
          firstName: r.guestDetailInfo.firstName,
          name: r.guestDetailInfo.firstName + ' ' + r.guestDetailInfo.lastName,
          phoneNumber: r.guestDetailInfo.phoneNumber?.length > 0 ? r.guestDetailInfo.phoneNumber[0] : '',
          email: r.guestDetailInfo.email?.length > 0 ? r.guestDetailInfo.email[0] : '',
          folio: r,
          folios: folios
        })
      });
      if (searchType == SearchType.GUESTINFO || searchType == SearchType.BOOKING) {
        this.selectedGuest = data[0];
      }
      else if (searchType == SearchType.RFIDDATA) {
        this.selectedGuest = data[0];
        this.patchSelectedFolio();
      }
      this.allData = data;
      this.cd.detectChanges();
    }
  }

  async handleError(e: any, searchType: SearchType) {
    if (e.error && e.error.errorCode && e.error.errorCode == ErrorConstants.NOFOLIODATA) {
      if (searchType == SearchType.GUESTINFO && this.RFIDEnabled) {
        this.retailUtils.showAlert(this.captions.warn_create_new_folio_RFID, AlertType.Warning, GlobalConst.ButtonType.folioRfidButton,
          (res) => {
            if (res === AlertOption.AlertAction.CreateFolio) {
              this.createDefaultFolioForGuest();
              this.patchSelectedFolio();
            } else if (res === AlertOption.AlertAction.CreateFolioAndAssignRfid) {
              if (!this.BP.CheckForAccess([GlobalConst.RetailBreakPoint.LinkRFID])) {
                this.selectedGuest = null;
                this.selectedTagData = [];
                this.allData = [];
                return;
              }
              this.createAndLinkRFIDFlow = true;
              this.performRFIDOperation();
              this.disableSearchBox = false;
            }
            else {
              this.selectedGuest = null;
              this.selectedTagData = [];
              // this.dialogRef.close({ from: 'cancel' });
              this.allData = [];
            }
          }
        );
      }
      else if (searchType == SearchType.GUESTINFO && !this.RFIDEnabled) {
        this.retailUtils.showAlert(this.captions.warn_create_new_folio, AlertType.Warning, GlobalConst.ButtonType.YesNo,
          (res) => {
            if (res === AlertOption.AlertAction.YES) {
              this.createDefaultFolioForGuest();
              this.patchSelectedFolio();
            } else {
              this.selectedGuest = null;
              this.selectedTagData = [];
              // this.dialogRef.close({ from: 'cancel' });
              this.allData = [];
            }
          }
        );
      }
      else if (searchType == SearchType.BOOKING) {
        await this.utils.showCommonAlert(this.captions.warn_create_new_folio, AlertType.Warning, ButtonTypes.YesNo,
          async (res) => {
            if (res === AlertAction.YES) {
              await this.createDefaultFolioForBooking();
              await this.patchSelectedFolio();
            } else {
              this.selectedGuest = null;
              this.allData = [];
            }
          }
        );
      }
      else if (searchType == SearchType.RFIDDATA) {
        this.utils.showAlert(this.localization.captions.common.NoDataFound, AlertType.Info, ButtonType.Ok);
        return;
      }
      else {
        this.allData = [];
      }

    }
    else {
      if (e.error && e.error.result) {
        this.utils.showError(e.error.result);
      }
      else {
        this.utils.showError("Unexpected error");
      }


    }

  }

  async getFolioDetailsForBooking(folioInvoiceNumber: string) {
    this.retailUtils.ToggleLoaderWithMessage(true, this.commonCaptions.lbl_inProgress)
    const request: FolioLookup = {
      sourceType: this.business.getSourceType(this.data?.data),
      folioInvoiceNumber: [folioInvoiceNumber],
      sourceTypeId: [],
      status: [0],
      startDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.currentDate),
      checkDate:!this.isSncFlow
    }
    const foliodetail = await this.business.folioLookup(request);
    this.folios = [];
    if (foliodetail && foliodetail.length > 0) {
      const data = foliodetail[0];
      data.folioLookupData.forEach((x) => {
        this.folios.push({
          id: x.folioId,
          name: x.folioName,
          number: x.folioNumber,
          balance: this.localization.localizeCurrency(x.creditLimit),
          isDefault: x.isDefault,
          default: false,
          folioInvoiceNumber: data.folioInvoiceNumber,
          outstandingBalance: this.localization.localizeCurrency(x.outstandingBalance),
          sourceType: data.sourceType,
          interfaceGuestId: data.guestDetailInfo?.interfaceGuestId
        });
      });
      this.folioInvoiceNumber = this.folios[0]?.folioInvoiceNumber;
      this.overAllBalance = this.localization.localizeCurrency(data.overAllBalance.toString());
      this.defaultFolio.outstandingBalance = this.folios.find(x => x.isDefault)?.outstandingBalance;
      this.defaultFolio.balance = this.folios.find(x => x.isDefault)?.balance;
      this.defaultFolio.sourceType = data.sourceType;
      this.defaultFolio.folioInvoiceNumber = data.folioInvoiceNumber;
      this.defaultFolio.interfaceGuestId = data.guestDetailInfo?.interfaceGuestId
      this.folios.unshift(this.defaultFolio);
      this.selectedFolio = this.folios[0];
      this.enableDisableProceedBtn(false);
    } else {
      this.enableDisableProceedBtn(true);
      this.selectedFolio = null;
      this.folioInvoiceNumber = ''
      this.overAllBalance = '';
    }
    this.retailUtils.ToggleLoaderWithMessage(false);
    return foliodetail;
  }


  onCancel() {
    this.dialogRef.close({ from: 'cancel' });
  }
  viewFolioInNewTab() {
    if (this.folioMenubp) {
      let folioInput = {
        sourceType: this.selectedFolio?.sourceType,
        sourceTypeId: this.selectedGuest.sourceTypeId,
        folioNumber: this.folioInvoiceNumber,
        fullName: this.selectedGuest?.name,
        interfaceGuestId: this.selectedFolio?.interfaceGuestId,
        status: 'Open'
      }
      localStorage.setItem("checkFromNewTab", JSON.stringify(folioInput));
      let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
      let url;
      if (productId == Product.SPA) {
        url = '/Spa/folio/foliosearch';
      } else if (productId == Product.GOLF) {
        url = '/Golf/folio/foliosearch';
      } else if (productId == Product.SALESANDCATERING) {
        url = 'folio/foliosearch';
      }
      this.route.navigate([], {
      }).then(result => { window.open(url, '_blank'); });
    } else {
      this.userAccessService.showBreakPointPopup(this.localization.captions[UserAccessBreakPoints.FOLIOMENU]);
    }
  }
}
