import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RetailSharedVariableService } from '../shared/retail.shared.variable.service';
import { Product, RetailTransactions } from '../shared/globalsContant';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { CommonVariablesService } from '../shared/service/common-variables.service';
import { RetailValidationService } from '../shared/retail.validation.service';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import * as _ from 'lodash';
import { PropertySettingDataService } from '../sytem-config/property-setting.data.service';
import { EventNotificationGroup, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { ReplaySubject } from 'rxjs';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailService } from '../retail.service';
import { SettleRefundTransactionBusiness } from '../shared/business/Settle-Refund-Transaction-business.service';
@Component({
  selector: 'shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShopComponenet implements OnInit, OnDestroy {
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  productId: number;
  constructor(private retailSharedService: RetailSharedVariableService
    , private shopService: CommonVariablesService
    , private PropertyInfo: RetailPropertyInformation
    , private retailValidationService: RetailValidationService
    , private _propertySettingDataService: PropertySettingDataService
    , private notificationconfigService: NotificationConfigurationService
    , private util: RetailUtilities
    , private rs: RetailService
    ,private _settleRefundTransBusiness: SettleRefundTransactionBusiness
  ) { }

  static getRandomDecimal(): number {
    const crypto = window.crypto;
    return crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
  }

  async ngOnInit() {
    let outlet = RetailDataAwaiters.GetDefaultOutlet();
    if (outlet && outlet.id && !this.retailSharedService.isTeeTimeAsPaid && !this.retailSharedService.SelectedOutletId)
      this.retailSharedService.SelectedOutletId = Number(outlet.id);
    if (!this.retailSharedService.navigationWithinRetail) { //Bug 28282 - Fix
      this.MapToShopService();
    }
    await this.InitializeConfigurationMethods();
    this.MapPMSProperties();
  }

  ngOnDestroy() {
    this.productId = Number(this.util.GetPropertyInfo('ProductId'));
    this.rs.clientSelect = 1
    this.retailValidationService.RemoveTransactionLock(this.retailValidationService.transactionLockId);
    let isFromReservation = this.retailSharedService.isFromReservation;
    this.retailSharedService.Destroy();
    //Bug - 35888,36945,35722,51321 Issue fix - Retaining selectedProducts only when using continue booking
    this.retailSharedService.selectedProducts = this.shopService.isFromContinueBooking ? this.shopService.selectedProducts : [];
    this.retailSharedService.SelectedPlayers = this.shopService.isFromContinueBooking ? this.shopService.SelectedPlayers : [];
    this.retailSharedService.payeeId = this.shopService.isFromContinueBooking ? this.shopService.selectedPayeeId : 0;
    this.retailSharedService.isFromEditTeeTime = this.shopService.isFromContinueBooking ? this.shopService.isFromEditTeeTime : false;
    this.shopService.SelectedPlayers = this.shopService.isFromContinueBooking ? this.shopService.SelectedPlayers : [];

    this.shopService.selectedappointments = this.shopService.isFromContinueBooking ? this.shopService.selectedappointments : [];

    this.retailSharedService.selectedappointments = this.shopService.isFromContinueBooking ? this.retailSharedService.selectedappointments : [];


    this.shopService.isFromContinueBooking = false;
    this.shopService.propertyVATConfiguration = null;
    this.shopService.isAutoTriggerNotificationEnabled = false;
    if (isFromReservation) {
      this.shopService.destroy();
    }
  }

  MapToShopService() {
    switch (this.shopService.ProductId) {
      case Product.SPA:
        this.MapSPAProperties();
        break;
      case Product.GOLF:
        this.MapGOLFProperties();
        break;
      case Product.SNC:
        this.MapSNCProperties();
        break;
      case Product.RETAIL:
        this.MapRetailSpecificProperties();
        break;
      case Product.PMS:
        this.MapPMSProperties();
        break;
      default: break;
    }
    this.MapCommonProperties();
    this.MapCommonConfig();
  }

  MapSPAProperties() {
    this.shopService.AppoinmentIdForCheckOut = this.retailSharedService.checkOutIds;
    this.shopService.isAppointmentCheckOut = this.retailSharedService.isFromCheckOut;
    this.shopService.isClassCheckOut = this.retailSharedService.isFromClassCheckOut;
    this.shopService.GuaranteeMethodData = this.retailSharedService.GuaranteeMethodData;
    this.shopService.CheckOutCallback = this.retailSharedService.checkOutCallback;
    this.shopService.excludeTaxForDeposit = this.retailSharedService.excludeTax;
    this.shopService.cancelDepositAppointments = this.retailSharedService.cancelDeposit;
    this.shopService.cancelDayPassAppointments = this.retailSharedService.cancelDayPass;
    this.shopService.cancellationNoShowCharge = this.retailSharedService.cancellationNoShowCharge;
    this.shopService.CancellationNoShowWaiveOffId = this.retailSharedService.cancellationNoShowWaiveOffId;
    this.shopService.cancelFlag = this.retailSharedService.cancelFlag;
    this.shopService.statusChangeFlag = this.retailSharedService.statusChangeFlag;
    this.shopService.waiveOffFlag = this.retailSharedService.waiveOffFlag;
    this.shopService.ticketNumber = this.retailSharedService.ticketNumber;
    this.shopService.isReopenViewOnly = this.retailSharedService.isReopenViewOnly;
    this.shopService.retailTransaction.tabState = 0;
    this.shopService.isFromSpaWizard = this.retailSharedService.isFromSpaWizard;
    this.shopService.isFromAppointment = this.retailSharedService.isFromAppointment;
    this.shopService.addRetailItemToSource = this.retailSharedService.addRetailItemToSource;
    this.shopService.appoinmentId = this.retailSharedService.appoinmentId;
    this.shopService.LinkedRetailItemDetails = this.retailSharedService.LinkedRetailItemDetails; 
    this.shopService.isDayPass = this.retailSharedService.isDayPass;  
    this.shopService.lockerAssignmentDetails = this.retailSharedService.lockerAssignmentDetails; 
  }

  MapGOLFProperties() {
    if (this.retailSharedService.SelectedPlayers && !this.retailSharedService.DeletePreviousPlayer) {
      this.shopService.SelectedPlayers.push(...this.retailSharedService.SelectedPlayers);
    }
    else if (this.retailSharedService.SelectedPlayers) {
      this.shopService.SelectedPlayers = this.retailSharedService.SelectedPlayers;
    }
    if (this.retailSharedService.selectedTransaction) {
      this.shopService.selectedTransaction = this.retailSharedService.selectedTransaction;
    }
    this.shopService.SelectedPlayers = _.uniqBy(this.shopService.SelectedPlayers, 'playerId');
    this.shopService.isFromRefund = this.retailSharedService.isFromRefund;
    this.shopService.canSelectProducts = this.retailSharedService.canSelectProducts;
    this.shopService.RefundTicketTransactionIDs = this.retailSharedService.RefundTicketTransactionIDs;
    this.shopService.returnExchangeType = this.retailSharedService.returnExchangeType;
    this.shopService.retailTransaction.dropdownState = this.retailSharedService.dropdownState;
    this.shopService.isFromEditTeeTime = this.retailSharedService.isFromEditTeeTime;
    this.shopService.isFromTeetimeGraphicalView = this.retailSharedService.isFromTeeTimeGraphicalView;
    this.shopService.ReturnMethod = this.retailSharedService.RefundMethod;
    this.shopService.SeletedCourseId = this.retailSharedService.SeletedCourseId;
    this.shopService.SelectedDate = this.retailSharedService.SelectedDate;
    this.shopService.ticketNumber = this.retailSharedService.selectedRetailItem && this.retailSharedService.selectedRetailItem.ticketNumber;    
  }

  MapSNCProperties() {
    this.shopService.isFromSncBeo = this.retailSharedService.isFromSncBeo;
    this.shopService.isFromSncBeoWithFolioPayment = this.retailSharedService.isFromSncBeoWithFolioPayment;
    this.shopService.arAccountNumber = this.retailSharedService.arAccountNumber;
    this.shopService.arAccountName = this.retailSharedService.arAccountName;
    this.shopService.paymentTokenReferenceId = this.retailSharedService.paymentTokenReferenceId;
    this.shopService.bookingId = this.retailSharedService.bookingId;
  }

  async MapRetailSpecificProperties() { 
    this.shopService.ticketNumber = this.retailSharedService.ticketNumber ?? this.shopService.ticketNumber;    
    const emailSMSConfigurationSetting: EventNotificationGroup[] =
      await this.notificationconfigService.GetEventNotificationGroupByEventId(EventNotification.RetailProductRetailReceipts);

    if (emailSMSConfigurationSetting.length > 0) {
      this.shopService.isAutoTriggerNotificationEnabled = true;
    }
  }

  MapCommonProperties() {
    this.shopService.selectedPayeeId = this.retailSharedService.payeeId;   //To do   
    this.shopService.sourceCorpId = this.retailSharedService.sourceCorpId; 
    this.shopService.selectedGuestGuid = this.retailSharedService.guestGuid;
    this.shopService.transactionId = this.retailSharedService.transactionId;
    this.shopService.isFromDeposit = this.shopService.depositFlag = this.retailSharedService.isFromDeposit;
    this.shopService.depositArray = this.retailSharedService.depositArray;
    this.shopService.RedirectModule = this.retailSharedService.RedirectModule;
    this.shopService.settleOpenTransaction = this.retailSharedService.settleOpenTransaction;
    this.shopService.reOpenTransaction = this.retailSharedService.reOpenTransaction;
    this.shopService.isFromDayEnd = this.retailSharedService.isFromDayEnd;
    this.shopService.selectedTransaction = this.retailSharedService.selectedTransaction ? this.retailSharedService.selectedTransaction : this.shopService.selectedTransaction;
    this.shopService.isFromRetailDashBoard = this.retailSharedService.isFromRetailDashBoard;
    this.shopService.TaxValue = this.retailSharedService.TaxValue;
    this.shopService.GoToRetailTransaction = this.retailSharedService.GoToRetailTransaction;
    this.shopService.memberCardNumber = this.retailSharedService.memberCardNumber;
    this.shopService.ReceiptComment = this.retailSharedService.receiptComment;
    this.shopService.selectedRetailItem = this.retailSharedService.selectedRetailItem;
    this.shopService.sectionName = this.retailSharedService.sectionName;
    if (this.retailSharedService.selectedProducts && this.retailSharedService.selectedProducts.length > 0) {
      this.shopService.selectedProducts = this.retailSharedService.selectedProducts;      
    }
    if (this.retailSharedService.dependentTransactions && this.retailSharedService.dependentTransactions.size > 0) {
      this.shopService.dependentTransactions = this.retailSharedService.dependentTransactions;
    }
    if(this.retailSharedService.settleOpenTransaction){
      this.shopService.isReturnWithoutTicket = this.retailSharedService.isReturnWithoutTicket;
    }
  }

  MapCommonConfig() {
    this.shopService.SelectedOutletId = this.retailSharedService.SelectedOutletId;
    this.shopService.SelectedTerminalId = Number(this.retailSharedService.SelectedTerminalId);
    this.PropertyInfo.SetDefaultDataOnLoad();
    if (!this.PropertyInfo.UseRetailInterface) { // Setting terminalId as '1' by default for all embedded Transactions
      this.shopService.SelectedTerminalId = 1;
    }
    const RWT_TransactionTypes = [
      "RETURN WITH TICKET WITHOUT EXCHANGE",
      "RETURN WITH TICKET WITH EXCHANGE"
    ];
    if(this.shopService.isFromDayEnd && this.shopService.settleOpenTransaction && this.shopService.selectedRetailItem 
      && RWT_TransactionTypes.includes(this.shopService.selectedRetailItem.retailTransactionType)){//ReturnwithTicket settle from audit screen
       this._settleRefundTransBusiness.FormReturnedRetainedExchangedItems(this.shopService.selectedRetailItem);
    }
  }

  async InitializeConfigurationMethods() {
    if (!this.PropertyInfo.UseRetailInterface) {
      if (!this.PropertyInfo.IsPaymentConfigExist) {
        const paymentConfig = await this._propertySettingDataService.GetPaymentConfigurationByProperty(this.PropertyInfo.PropertyId);
        this.PropertyInfo.SetPaymentConfiguration(paymentConfig);
      }
    }
  }

  MapPMSProperties() {
    this.shopService.isFromReservation = this.retailSharedService.isFromReservation;
    this.shopService.selectedProducts = this.retailSharedService.selectedProducts || [];    
  }

}
