import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { DayPassManagementBusiness } from './day-pass-management.business';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateDayPassManagementComponent } from './create-day-pass-management/create-day-pass-management.component';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { T } from '@angular/cdk/keycodes';
import { EntryPeriodUtilizationComponent } from './entry-period-utilization/entry-period-utilization.component';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { result } from 'lodash';
import { event } from '@fullstory/browser';


@Component({
  selector: 'app-day-pass-management',
  templateUrl: './day-pass-management.component.html',
  styleUrls: ['./day-pass-management.component.scss'],
  providers: [DayPassManagementBusiness],
  encapsulation: ViewEncapsulation.None
})
export class DayPassManagementComponent implements OnInit {
  searchHeaderOption: TableSearchHeader;
  captions: any;
  isViewOnly: boolean;
  headerOptions: any;
  tableContent: any;
  tableOptions: any;
  searchText: string;
  IsEditModeEnabledFlag: boolean;
  proceedButton: ButtonValue;
  utilizationButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  AllDayPackage: any;
  guestTypes:any;
  checkedItem:any=[];

  constructor(
    private localization: Localization,
    private business: DayPassManagementBusiness,
    private http: HttpServiceCall,
    public dialog: MatDialog
  ) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.initialize();
  }
  async GetAllDayPackageDetails(includeInactive) {
    this.AllDayPackage = [];
    var result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllDayPackage",
      method: HttpMethod.Get,
      uriParams: { IncludeInactive: includeInactive },
    });
    this.AllDayPackage = result.result;
    return result.result;
  }
  async GetDayPackageDetailById(id) {
    this.AllDayPackage = [];
    var result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetDayPackageDetailById",
      method: HttpMethod.Get,
      uriParams: { id: id },
    });
    this.AllDayPackage = result.result;
    return result.result;
  }
  async DeleteDayPackageByIds(dayPackageIds) {
    var result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "DeleteDayPackage",
      method: HttpMethod.Delete,
      uriParams: {},
      body:dayPackageIds
    });
    this.AllDayPackage = result.result;
    return result.result;
  }
  searchChange(e) {
    this.searchText = e;
  }
  async initialize() {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.btn_createEntryPeriod,
      searchPlaceHolder: this.captions.lbl_searchbyEntryPeriod,
      advancedSearch: this.captions.lbl_advancedSearch,
      createBtnDisabled: this.isViewOnly,
    };
    var dayPackageDetails = await this.GetAllDayPackageDetails(true);
    this.searchText = '';
    this.proceedButton = {
      type: "primary",
      label: this.captions.btn_proceed,
      // disabledproperty: true
    }
    this.utilizationButton = {
      type: 'secondary',
      label: this.captions.btn_utilization,
      // disabledproperty: true
    }
    this.checkedItem=[];
    this.tableContent = this.business.getTableContent(dayPackageDetails);
    this.headerOptions = this.business.getHeaderOptions();
    this.tableOptions = this.business.getTableOptions(this.isViewOnly);
  }

  create(event) {
    this.dialog
      .open(CreateDayPassManagementComponent, {
        width: "90%",
        height: "90%",
        disableClose: true,
        data: {
          title: this.captions.lbl_createEntryPeriod
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.initialize();
        this.searchText = '';
      });
  }

  showInactive(e) {}

  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.edit:
        this.IsEditModeEnabledFlag = true;
        this.edit(eve.Obj);
        break;
      case FromTypeEnum.delete:
        this.delete(eve.Obj);
        break;
      case FromTypeEnum.rowcheck:
      case FromTypeEnum.allcheckbox:
        this.checkedItem = eve.checkedData;
        break;

    }
  }
  async edit(e) {
    console.log();
    this.guestTypes = await this.business.GetAllGuestTypes(false);
    var DayPackage = await this.GetDayPackageDetailById(e.id);

    const dayMapping: { [key: number]: string } = {
      0: "Sun",
      1: "Mon",
      2: "Tue",
      3: "Wed",
      4: "Thu",
      5: "Fri",
      6: "Sat",
    };
    const entryForm = {
      id:DayPackage.id,
      activeToggle: DayPackage.isActive,
      code: DayPackage.code,
      name: DayPackage.description,
      startDate: new Date(DayPackage.startDate),
      startTime: this.localization.LocalizeTime(new Date(DayPackage.startDate)),
      endDate: new Date(DayPackage.endDate),
      endTime: this.localization.LocalizeTime(new Date(DayPackage.endDate)),
      ...Object.fromEntries(
        DayPackage.dayPackageSetupCount
          .filter((item) => item.guestTypeId === 0) // EntryForm days only
          .map((item) => [dayMapping[item.dayOfWeek], item.availableCount])
      ),
    };
    
    const guestArr = [];
    const guestType = [];
    const groupedGuestEntries = DayPackage.dayPackageSetupCount
      .filter((item) => item.guestTypeId !== 0) // Guest entries only
      .reduce((acc, item) => {
        if (!acc[item.guestTypeId]) {
          acc[item.guestTypeId] = { guestId: item.guestTypeId, dayPackageGroupId: item.dayPackageDetailsId};
          var guest = this.guestTypes.find(x=>x.id==item.guestTypeId);
          guestType.push({
            id:item.guestTypeId,
            value:guest.value,
            viewValue:guest.value
          });
        }
        acc[item.guestTypeId][dayMapping[item.dayOfWeek]] = item.availableCount;
        return acc;
      }, {});
    
    for (const guestId in groupedGuestEntries) {
      guestArr.push(groupedGuestEntries[guestId]);
    }

    
    const guestForm = {
      guestType:guestType ,
      guestArr:guestArr
    };
    
    // let obj = [
    //   {
    //     entryForm: {
    //       activeToggle: response.isActive,
    //       allDays: 0,
    //       code: response.code,
    //       endDate: new Date("2024-07-05T00:00"),
    //       endTime: "9:00 AM",
    //       fri: 3,
    //       mon: 3,
    //       name: response.description,
    //       sat: 3,
    //       startDate: new Date("2024-07-05T00:00"),
    //       startTime: "6:00 AM",
    //       sun: 3,
    //       thu: 3,
    //       tue: 3,
    //       wed: 3,
    //     },
    //     guestForm: {
    //       guestType: [
    //         { id: 1, value: "Resort Guest", viewValue: "Resort Guest" },
    //         { id: 2, value: "Hotel Guest", viewValue: "Hotel Guest" },
    //       ],
    //       guestArr: [
    //         {
    //           guestId:0,
    //           dayPackageGroupId:0,
    //           name: "",
    //           sun: 1,
    //           mon: 1,
    //           tue: 1,
    //           wed: 1,
    //           thu: 1,
    //           fri: 1,
    //           sat: 1,
    //         },
    //         {
    //           guestId:0,
    //           dayPackageGroupId:0,
    //           name: "",
    //           sun: 2,
    //           mon: 2,
    //           tue: 2,
    //           wed: 2,
    //           thu: 2,
    //           fri: 2,
    //           sat: 2,
    //         },
    //       ],
    //     },
    //   },
    // ];
    this.dialog
      .open(CreateDayPassManagementComponent, {
        width: "90%",
        height: "90%",
        disableClose: true,
        data: {
          title: this.captions.lbl_createEditEntryPeriod,
          fromEdit: true,
          entryForm: entryForm,
          guestForm: guestForm,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.initialize();
      });
  }
 async delete(e) {
  var ids=[e.id];
    await this.DeleteDayPackageByIds(ids);
    this.initialize();
  }
  async deleteAll() {
    if(this.checkedItem?.length>0)
    {
      var ids= this.checkedItem.map(x=>x.id);    
      await this.DeleteDayPackageByIds(ids);
      this.initialize();
    }

  }
  onUtilizationClick(e) {
    this.dialog.open(EntryPeriodUtilizationComponent, {
      width: '100%',
      height: '100%',
      disableClose: true,
      data: {
        title : this.captions.hdr_entryPeriodUtilization,
        // fromEdit : true,
        // entryForm : obj[0].entryForm,
        // guestForm : obj[0].guestForm,
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
     
    this.initialize();

    });
  }

}
