import { Injectable } from "@angular/core";
import { HandleRequest, HandleResponse, PaymentMethods, TokentransactionInfo } from "../shared/business/shared.modals";
import { PMAgentActionType, RGuestPayRequestBuildParams, SaleResponse, TransactionState } from "../shared/service/payment/payment-model";
import { HttpResponseStatus, IntegrationOperationsTransactionType } from "../retail.modals";
import { SaleRequest } from "../shared/service/payment/payment-business.model";
import { RGuestSaleResponse, TokenResponse, TransactionType } from "../shared/business/Giftcard/rGuestPayRequestModel";
import { RetailPropertyInformation } from "../common/services/retail-property-information.service";
import { RetailUtilities } from "../shared/utilities/retail-utilities";
import { Router } from "@angular/router";
import { GiftCardMethods } from "./PaymentFlowHelpers";
import { MachineInfo } from "../shared/business/Giftcard/rGuestPayRequestModel";

@Injectable({ providedIn: 'root' })
export class PMAgentToRGuestPayRequestAdapter {
    constructor(private _propertyInfo: RetailPropertyInformation,
        private _utils: RetailUtilities, private _router: Router) {
    }

    MapHandleRequest(request: HandleRequest, outletId: number): RGuestPayRequestBuildParams {
        return {
            actionType: PMAgentActionType.GetHandles,
            outletId: outletId,
            tenderId: Number(request.tenderId),
            manualCardEntry: request?.inquiryInfo?.manualCardEntry,
            encryptedCardData: request?.inquiryInfo?.cardData?.encryptedData,
            integrationOperationType: IntegrationOperationsTransactionType.CreditCardGetHandlesForRetailTransaction,
            params: { "route": this._router.url, "originalTenderId": request.originalTenderId ? request.originalTenderId.toString() : '' }
        } as RGuestPayRequestBuildParams;
    }

    MapSaleRequest(request: SaleRequest, outletId: number): RGuestPayRequestBuildParams {
        const tenderId = Number(request.inquirerInfo.tenderId);
        const postId = Number(request.inquirerInfo?.postId ?? 0 ) > 0 ? request.inquirerInfo.postId : null;
        return {
            actionType: PMAgentActionType.Sale,
            outletId: outletId, 
            tenderId: tenderId,
            manualCardEntry: request?.inquirerInfo?.manualCardEntry,
            integrationOperationType:this.GetIntergationOperationLogType(PMAgentActionType.Sale,request?.inquirerInfo?.isFolioPosting),
            deviceGuid: tenderId == PaymentMethods.IDTECH ? "" : request.handle,
            encryptedCardData: tenderId == PaymentMethods.IDTECH ? JSON.parse(request.handle)?.inquiryInfo?.cardData?.encryptedData : "",
            amount: request.amount.requestAmount,
            ticketNumber: request.inquirerInfo.orderNumber,
            sourceTypeId: request.inquirerInfo.sourceTypeId,
            sourceType: request.transactionId,
            params: { "route": this._router.url, "originalTenderId":  request.originalTenderId ? request.originalTenderId.toString() : '' , "postId": postId}
        } as RGuestPayRequestBuildParams;
    }    
    MapAuthRequest(request: SaleRequest, outletId: number): RGuestPayRequestBuildParams {
        const tenderId = Number(request.inquirerInfo.tenderId);
        return {
            actionType: PMAgentActionType.Auth,
            outletId: outletId,
            tenderId: Number(request.inquirerInfo.tenderId),
            manualCardEntry: request?.inquirerInfo?.manualCardEntry,
            integrationOperationType: IntegrationOperationsTransactionType.CreditCardAuthForFolioPosting,
            deviceGuid: tenderId == PaymentMethods.IDTECH ? "" : request.handle,
            encryptedCardData: tenderId == PaymentMethods.IDTECH ? JSON.parse(request.handle)?.inquiryInfo?.cardData?.encryptedData : "",
            amount: request.amount.requestAmount,
            ticketNumber: request.inquirerInfo.orderNumber,
            sourceTypeId: request.inquirerInfo.sourceTypeId,
            sourceType: request?.transactionId ?? 0,
            params: { "route": this._router.url, "originalTenderId": request.originalTenderId ? request.originalTenderId.toString() : '' }
        } as RGuestPayRequestBuildParams;
    }

    MapTokenRequest(handle: string, tenderID: number, outletId: number, manualCardEntry: boolean): RGuestPayRequestBuildParams {
        return {
            actionType: PMAgentActionType.CreateToken,
            outletId: outletId,
            tenderId: tenderID,
            manualCardEntry: manualCardEntry,
            integrationOperationType: IntegrationOperationsTransactionType.CreditCardGetHandlesForRetailTransaction,
            deviceGuid: tenderID == PaymentMethods.IDTECH ? "" : handle,
            encryptedCardData: tenderID == PaymentMethods.IDTECH ? JSON.parse(handle)?.inquiryInfo?.cardData?.encryptedData : "",
            amount: 0,
            sourceTypeId: "",
            params: { "route": this._router.url }
        } as RGuestPayRequestBuildParams;
    }

    MapTokenResponse(tokenRes: TokenResponse): TokentransactionInfo {
        let response: any = {
            status: HttpResponseStatus.Success,
            account: {
                id: tokenRes.cardInfo?.accountNumberMasked,
                name: tokenRes.cardInfo?.cardHolderName,
                type: ""
            }
        };
        if (tokenRes.cardInfo != null) {
            response.cardInfo = {
                entryMode: tokenRes?.cardInfo?.entryMode,
                issuer: tokenRes?.cardInfo?.cardIssuer,
                cardExpiration: tokenRes?.cardInfo?.expirationYearMonth,
                cardType: tokenRes?.cardInfo?.cardType
            };
        }
        const machineInfo = this.GetTerminalInfo();
        if(machineInfo){
            response.ipAddress = machineInfo?.ipAddress,
            response.hostName = machineInfo?.hostName,
            response.agentVersion = machineInfo?.payAgentVersion
        }
        return response as TokentransactionInfo;
    }

    MapCreditRequest(request: SaleRequest, outletId: number): RGuestPayRequestBuildParams {
        const tenderId = Number(request.inquirerInfo.tenderId);
        return {
            actionType: PMAgentActionType.Credit,
            outletId: outletId,
            tenderId: tenderId,
            manualCardEntry: request?.inquirerInfo?.manualCardEntry,
            integrationOperationType: this.GetIntergationOperationLogType(PMAgentActionType.Credit,request?.inquirerInfo?.isFolioPosting),
            deviceGuid: tenderId == PaymentMethods.IDTECH ? "" : request.handle,
            encryptedCardData: tenderId == PaymentMethods.IDTECH ? JSON.parse(request.handle)?.inquiryInfo?.cardData?.encryptedData : "",
            amount: request.amount.requestAmount,
            ticketNumber: request.inquirerInfo.orderNumber,
            sourceTypeId: request.inquirerInfo.sourceTypeId,
            sourceType: request?.transactionId ?? 0,
            params: { "route": this._router.url, "originalTenderId": request.originalTenderId ? request.originalTenderId.toString() : '' }
        } as RGuestPayRequestBuildParams;
    }

    MapSaleResponse(saleRequest: any, transactionType: TransactionType, rguestResponse: RGuestSaleResponse, requestPayload: any): SaleResponse {
        let saleResponse: any = {};
        const MapGeneralPropertiesFromResponse = () => {
            saleResponse.requestId = requestPayload.RequestId;
            saleResponse.status = HttpResponseStatus.Success;
            saleResponse.transactionDetails = JSON.stringify(rguestResponse);
            saleResponse.lodgingData = JSON.stringify(requestPayload.LodgingData);
            saleResponse.amount = {
                requestAmount: Number(requestPayload?.TransactionData?.TransactionAmount),
                authorisedAmount: Number(rguestResponse?.transactionResponseData?.authorizedAmount),
                tipAmount  : Number(rguestResponse?.transactionResponseData?.tipAmount ?? 0),
            };
            if (rguestResponse.cardInfo != null) {
                saleResponse.cardInfo = {
                    entryMode: rguestResponse?.cardInfo?.entryMode,
                    issuer: rguestResponse?.cardInfo?.cardIssuer,
                    cardExpiration: rguestResponse?.cardInfo?.expirationYearMonth,
                    cardType: rguestResponse?.cardInfo?.cardType
                };
            }
            saleResponse.account = {
                id: rguestResponse?.cardInfo?.accountNumberMasked,
                name: rguestResponse?.cardInfo?.cardHolderName,
            };
            const machineInfo = this.GetTerminalInfo();
            if (machineInfo) {
                    saleResponse.ipAddress = machineInfo.ipAddress,
                    saleResponse.hostName = machineInfo.hostName,
                    saleResponse.agentVersion = machineInfo.payAgentVersion
            }
            
        }

        MapGeneralPropertiesFromResponse();
        switch (transactionType) {
            case TransactionType.Auth:
                saleResponse.transactionState = TransactionState.Authorised;
                break;
            case TransactionType.Sale:
                saleResponse.transactionState = TransactionState.Sale;
                break;
            case TransactionType.Credit:
                saleResponse.transactionState = TransactionState.Credit;
                break;
            default:
                break;
        }
        return saleResponse as SaleResponse;
    }

    GetIntergationOperationLogType(actionType, isFolioPosting: boolean): IntegrationOperationsTransactionType {
        let integrationType;
        if (isFolioPosting) {
            switch (actionType) {
                case PMAgentActionType.Sale:
                    integrationType = IntegrationOperationsTransactionType.CreditCardSalePaymentForFolioPosting;
                    break;
                case PMAgentActionType.Auth:
                    integrationType = IntegrationOperationsTransactionType.CreditCardAuthForFolioPosting;
                    break;
                case PMAgentActionType.Credit:
                    integrationType = IntegrationOperationsTransactionType.CreditCardCreditPaymentForFolioPosting;
                    break;
            }
        }
        else {
            switch (actionType) {
                case PMAgentActionType.Sale:
                    integrationType = IntegrationOperationsTransactionType.CreditCardSalePaymentForRetailTransaction;
                    break;
                case PMAgentActionType.Credit:
                    integrationType = IntegrationOperationsTransactionType.CreditCardCreditPaymentForRetailTransaction;
                    break;

            }

        }
        return integrationType;
    }

    MapGetTerminalRequest(outletId: number): RGuestPayRequestBuildParams {
        return {
            actionType: PMAgentActionType.GetTerminalInfo,
            outletId: outletId,
            tenderId: PaymentMethods.CreditCard,
            manualCardEntry: false,
            integrationOperationType: IntegrationOperationsTransactionType.TerminalInfo,
            params: { "route": this._router.url }
        } as RGuestPayRequestBuildParams;
    }

    GetTerminalInfo() {
        const storedTerminalDetails = sessionStorage.getItem('terminalDetails');
        let machineInfo: MachineInfo | null = null;

        if (storedTerminalDetails) {
            try {
                machineInfo = JSON.parse(storedTerminalDetails) as MachineInfo;
            } catch (error) {
                console.error('Error parsing terminal details from sessionStorage:', error);
            }
        }
        return machineInfo
    }

}