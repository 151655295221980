import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject, Subscription } from 'rxjs';
// import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { Localization } from 'src/app/common/localization/localization';
// import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

// import { OperationType } from 'src/app/shared/globalsContant';
import { CreateContactLogComponent } from './create-contact-log/create-contact-log.component';
// import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { takeUntil } from 'rxjs/operators';
// import {ContactLog} from 'src/app/shared/business/new-booking.model';
// import { SpaUtilities } from '../../../utilities/spa-utilities';
import { EventEmitter } from '@angular/core';
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ContactLogService } from './contact-log.service';
import {MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-contact-log',
  templateUrl: './contact-log.component.html',
  styleUrls: ['./contact-log.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactLogComponent implements OnInit {
  @Input() fromAlert;
  @Input('clientContactLogs')  set clientContactLogsData(value){
   if (value){
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
    this.clientContactLogs = value.filter(c=> !c.isPrivate || (c.isPrivate && c.productId == this.currentProductId));
    this.otherProductPrivateContactLogs = value.filter(c=> c.isPrivate && c.productId != this.currentProductId);
   }
  };
  @Input() isPurged;
  @Input() applicableType;

  @Output() initializeDummyForm = new EventEmitter();
  @Output() createRecord = new EventEmitter();
  @Output() editRecord = new EventEmitter();
  @Output() deleteRecord = new EventEmitter();
  private _isFromTherapist: boolean;

@Input()
set isFromTherapist(value: any) {
  this._isFromTherapist = value === undefined ? false : !!value;
}
  minToDate: Date;
  panelOpenState = false;
  public tableoptions: any;
  public filteredPriority;
  priority;
  public dateGrp: UntypedFormGroup;
  public tableDataArray: any = [];
  logTypeArray:any = [];
  captions: any;
  commonCaptions: any;
  placeholderFormat: string;
  floatLabel: string;
  actionDialogSubscription: Subscription;
  isFirstTime: boolean = true;
  clientWindowConvertion: Subscription;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showGrid: boolean = false
  messageForWarning: string;
  isGuestRecordPurged: boolean = false;
  showClose: boolean = true;
  isViewOnly:boolean = false;
  clientContactLogs:any = [];
  otherProductPrivateContactLogs: any = [];
  emitContactLogs:any =[];
  currentProductId;

  constructor( public dialogRef: MatDialogRef<ContactLogComponent>,@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog, public fb: UntypedFormBuilder, public localization: Localization, private PropertyInfo: CommonPropertyInformation, private utils: CommonUtilities, public contactLogService : ContactLogService) {
    this.captions = this.localization.captions;
    this.commonCaptions = this.localization.captions.common;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
    this.clientContactLogs = this.data.therapistLog;
  }

  ngOnInit(): void {
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
    this.initializeFormData();
    this.onFormChanges();
  }

  async initializeFormData() {
    this.InitializeDummyForm();
    this.buildForm();
    this.isViewOnly = await CommonDataAwaiters.IsContactLogViewOnly();
    //await this.contactLogService.getAllLogType(false);
    var logTyps = await this.contactLogService.getAllLogType(false,this.applicableType)
      if(logTyps){
        if(logTyps.result){
          this.logTypeArray = logTyps.result;
        }
        else {
          this.logTypeArray = logTyps;
        }
      }
    this.loadData();
    // this.clientWindowConvertion = this.appoinmentPopupService.convertToEdit.subscribe(x => {
    //   if (x && x.id > 0 && this.isFirstTime) {
    //     this.isFirstTime = false;
    //     this.initializeFormData();
    //   }
    // });
    // this.messageForWarning = this.appoinmentPopupService?.allCaptions?.lbl_guestRecordPurged;
    // this.isGuestRecordPurged = this.appoinmentPopupService?.clientEditData?.clientDetail?.isPurged;

    this.messageForWarning = this.commonCaptions?.lbl_guestRecordPurged;
    this.isGuestRecordPurged = this.isPurged;
    this.isGuestRecordPurged ? this.dateGrp.disable() : false;
  }

  onFormChanges(){
    this.dateGrp.get("fromDate").valueChanges.subscribe(val => {
      this.minToDate = val;
      this.loadData();
    });
    this.dateGrp.get("toDate").valueChanges.subscribe(val => {
      this.loadData();
    });
  }

  async loadData() {
    this.contactLogService.tableDataTemp = [];
    let fromDate = this.localization.ConvertDateToISODateTime(this.dateGrp.controls['fromDate'].value);
    let toDate = this.localization.ConvertDateToISODateTime(this.dateGrp.controls['toDate'].value);
    if (!this.fromAlert && this.utils.getDate(fromDate).getTime() > this.utils.getDate(toDate).getTime()) {
      this.contactLogService.tableDataTemp = [];
      // this.dateGrp.controls.toDate.setValue(this.dateGrp.controls['fromDate'].value);
    }
    else {
      // let result = this.contactLogService.filterContactLogsByDate(fromDate,toDate);
      // if (result?.length > 0) {
      //   this.contactLogService.tableDataTemp = [...this.contactLogService.tableDataTemp];
      // }
      // else {
      //   this.contactLogService.tableDataTemp = [];
      // }
      let result = this.filterContactLogsByDate(fromDate,toDate);
      if (result?.length > 0) {
        this.contactLogService.tableDataTemp = [...this.contactLogService.tableDataTemp];
      }
      else {
        this.contactLogService.tableDataTemp = [];
      }
    }
    this.contactLogService.BindTable(this.fromAlert, this.isViewOnly , this._isFromTherapist);
  }


  InitializeDummyForm(){
    this.initializeDummyForm.emit()
    // this.appoinmentPopupService.contactLogsFormGrp = this.fb.group({});
}

  buildForm() {
    this.dateGrp = this.fb.group({
      fromDate: [''],
      toDate: ['']
    });
    this.minToDate = this.PropertyInfo.CurrentDate;
    this.dateGrp.controls['fromDate'].setValue(this.PropertyInfo.CurrentDate);
    this.dateGrp.controls['toDate'].setValue(this.PropertyInfo.CurrentDate);
  }
  ResetFilters() {
    this.dateGrp.controls['fromDate'].setValue(this.PropertyInfo.CurrentDate);
    this.dateGrp.controls['toDate'].setValue(this.PropertyInfo.CurrentDate);

  }

  EditRecords(eve) {
    let editContactLog = eve[0];

    let index = this.contactLogService.tableDataTemp.indexOf(editContactLog);
    let editContactLogData = this.contactLogService.tableDataTemp.slice(index)[0];


    // let length = this.appoinmentPopupService.clientContactLogs.length;
    let length = this.clientContactLogs.length;
    let indexOfLog : number;
    // let filteredLog = editContactLog.id == 0 ? this.appoinmentPopupService.clientContactLogs.filter(x=>x.id == 0) : this.appoinmentPopupService.clientContactLogs.filter((x,i)=>{
    //   if(x.id == editContactLog.id)
    //     indexOfLog = i;
    // });
    let filteredLog = editContactLog.id == 0 ? this.clientContactLogs.filter(x=>x.id == 0) : this.clientContactLogs.filter((x,i)=>{
      if(x.id == editContactLog.id)
        indexOfLog = i;
    });
    indexOfLog = indexOfLog != undefined && indexOfLog != -1 ? indexOfLog : (length - filteredLog.length) + index;

    // let clientContactLog = this.appoinmentPopupService.clientContactLogs.slice(indexOfLog)[0];
    let clientContactLog = this.clientContactLogs.slice(indexOfLog)[0];


    let dialogRef = this.dialog.open(CreateContactLogComponent, {
      width: '80%',
      height: '80%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        headername: this.captions.pg_title_editContactLog,
        //form: eve,
        currentdata: clientContactLog,
        isEdit: true,
        closebool: true,
        logTypeList:this.logTypeArray,
        isFromTherapist : this._isFromTherapist
      }
    });
    this.actionDialogSubscription = dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (typeof result == 'object') {
        if (clientContactLog) {
          clientContactLog.logTypeId = result.logTypeId;
          let logNotes_shortdata = result.logNotes.length > 75 ? `${result.logNotes.substring(0, 75)}...` : result.logNotes;
          clientContactLog.logNotes = result.logNotes;
          clientContactLog.logNotes_short = logNotes_shortdata;
          //clientContactLog.logDate = result.logDate;
          clientContactLog.dueDate = result.dueDate ? this.localization.ConvertDateToISODateTime(result.dueDate) : '';
          clientContactLog.expiryDate = result.expiryDate ? this.localization.ConvertDateToISODateTime(result.expiryDate) : '';
          clientContactLog.isAlert = result.isAlert;
          clientContactLog.isGroup = result.isGroup;
          clientContactLog.isPrivate = result.isPrivate;
        }
      }
      this.emitContactLogs = this.clientContactLogs;
      if(this.otherProductPrivateContactLogs.length>0)this.emitContactLogs = this.emitContactLogs.concat(this.otherProductPrivateContactLogs);
      this.editRecord.emit(this.emitContactLogs);
      this.loadData();
    });
  }
  dragDrop(e) {

  }
  DeleteLogList(e) {
      let deleteContactLog = e[0];
      let index = this.contactLogService.tableDataTemp.indexOf(deleteContactLog);
      this.contactLogService.tableDataTemp.splice(index,1);

      // let length = this.appoinmentPopupService.clientContactLogs.length;
      let length = this.clientContactLogs.length;

      let indexOfLog : number;
      // let filteredLog = deleteContactLog.id == 0 ? this.appoinmentPopupService.clientContactLogs.filter(x=>x.id == 0) : this.appoinmentPopupService.clientContactLogs.filter((x,i)=>{
      //   if(x.id == deleteContactLog.id)
      //     indexOfLog = i;
      // });
      // indexOfLog = indexOfLog != undefined && indexOfLog != -1 ? indexOfLog : (length - filteredLog.length) + index;
      // this.appoinmentPopupService.clientContactLogs.splice(indexOfLog,1);
      // this.loadData();
      // this.appoinmentPopupService.contactLogsFormGrp.markAsDirty();
      let filteredLog = deleteContactLog.id == 0 ? this.clientContactLogs.filter(x=>x.id == 0) : this.clientContactLogs.filter((x,i)=>{
        if(x.id == deleteContactLog.id)
          indexOfLog = i;
      });
      indexOfLog = indexOfLog != undefined && indexOfLog != -1 ? indexOfLog : (length - filteredLog.length) + index;
      this.clientContactLogs.splice(indexOfLog,1);
      this.emitContactLogs = this.clientContactLogs;
      if(this.otherProductPrivateContactLogs.length>0)this.emitContactLogs = this.emitContactLogs.concat(this.otherProductPrivateContactLogs);
      this.deleteRecord.emit(this.emitContactLogs);
      this.loadData();
      // this.appoinmentPopupService.contactLogsFormGrp.markAsDirty();
  }
  openDialog() {
    let dialogRef = this.dialog.open(CreateContactLogComponent, {
      width: '80%',
      height: '80%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        headername: this.captions.pg_title_createNewLog,
        form: null,
        isEdit: false,
        logTypeList:this.logTypeArray,
        automationId:"CreateContactLog",
        isFromTherapist : this._isFromTherapist
      }
    });
    this.actionDialogSubscription = dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (typeof result == 'object') {
        let logType = this.logTypeArray.filter(x=>x.id == result.logTypeId)[0];
        let logNotes_shortdata = result.logNotes.length > 75 ? `${result.logNotes.substring(0, 75)}...` : result.logNotes;
        //this.contactLogService.tableDataTemp.push({ 'id': 0, 'logDate': this.localization.LocalizeDate(result.logDate), 'logType': logType.logTypeInstance, 'logNotes': result.logNotes, 'logNotes_short': logNotes_shortdata, 'dueDate': result.dueDate ? this.localization.LocalizeDate(result.dueDate) : " ", 'expireDate': result.expiryDate ? this.localization.LocalizeDate(result.expiryDate) : " ", 'isAlert': result.isAlert, 'isGroup': result.isGroup, 'isPrivate': result.isPrivate });
        // this.appoinmentPopupService.clientContactLogs.push({ 'id': 0, 'logTypeId': result.logTypeId, 'logNotes': logNotes_shortdata, 'logNotes_short': logNotes_shortdata, 'logDate' : this.localization.ConvertDateToISODateTime(result.logDate) ,'dueDate': result.dueDate ? this.localization.ConvertDateToISODateTime(result.dueDate) : null, 'expiryDate': result.expiryDate ? this.localization.ConvertDateToISODateTime(result.expiryDate) : null, 'isAlert': result.isAlert, 'isGroup': result.isGroup, 'isPrivate': result.isPrivate });
        this.clientContactLogs.push({ 'id': 0, 'logTypeId': result.logTypeId, 'productId': this.currentProductId, 'logNotes': result.logNotes, 'logNotes_short': logNotes_shortdata, 'logDate' : this.localization.ConvertDateToISODateTime(result.logDate) ,'dueDate': result.dueDate ? this.localization.ConvertDateToISODateTime(result.dueDate) : null, 'expiryDate': result.expiryDate ? this.localization.ConvertDateToISODateTime(result.expiryDate) : null, 'isAlert': result.isAlert, 'isGroup': result.isGroup, 'isPrivate': result.isPrivate });
        this.emitContactLogs = this.clientContactLogs;
        if(this.otherProductPrivateContactLogs.length>0)this.emitContactLogs = this.emitContactLogs.concat(this.otherProductPrivateContactLogs);
        this.createRecord.emit(this.emitContactLogs)

       }
      this.loadData();
    });
  }


  ngOnDestroy(): void {
    if (this.clientWindowConvertion) {
      this.clientWindowConvertion.unsubscribe()
    }
    this.isFirstTime = true;
    // this.appoinmentPopupService.clientContactLogs = [];
    this.clientContactLogs = [];
    this.contactLogService.tableDataTemp = [];
  }
  closeWarning(){
    this.showClose = false;
  }


 filterContactLogsByDate(fromDate,toDate) {
    // if(this.contactLogService.tableDataTemp.length == 0)
    //   this.PrepareContactLogsData();
      // this.filterRecord.emit();


      // let clientContactLogDateFiltered =  this.appoinmentPopupService.clientContactLogs.filter(x=> fromDate <= x.logDate && toDate >= x.logDate);
      // if(clientContactLogDateFiltered.length > 0){
      //   clientContactLogDateFiltered.forEach((x,i)=>{
      //     let logType =  this.logTypeList.filter(y=>y.id == x.logTypeId)[0];
      //     let logNotes_shortdata = x?.logNotes.length > 75 ? `${x?.logNotes.substring(0, 75)}...` : x.logNotes;
      //     this.tableDataTemp.push({ 'id': x.id, 'logDate': this.localization.LocalizeDate(x.logDate), 'logType': logType.logTypeInstance, 'logNotes': x.logNotes, 'logNotes_short': logNotes_shortdata, 'dueDate': x.dueDate ? this.localization.LocalizeDate(x.dueDate) : "", 'expireDate': x.expiryDate ? this.localization.LocalizeDate(x.expiryDate) : " ", 'isAlert': x.isAlert, 'isGroup': x.isGroup, 'isPrivate': x.isPrivate });
      // });
      // }
      if(this.clientContactLogs && this.clientContactLogs.length>0)
      {
        let clientContactLogDateFiltered =  this.clientContactLogs.filter(x=> fromDate <= x.logDate && toDate >= x.logDate);
        let propertyDate = this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate);
        clientContactLogDateFiltered  = clientContactLogDateFiltered.filter(x=>x.expiryDate ==null || x.expiryDate == "" ||  propertyDate <= this.utils.GetDateWithoutTime(this.utils.getDate(x.expiryDate)));
        if(this.fromAlert) clientContactLogDateFiltered =  this.clientContactLogs;
        if(clientContactLogDateFiltered.length > 0){
          clientContactLogDateFiltered.forEach((x,i)=>{
              let logType =  this.logTypeArray.filter(y=>y.id == x.logTypeId)[0];
              let logNotes_shortdata = x?.logNotes.length > 75 ? `${x?.logNotes.substring(0, 75)}...` : x.logNotes;
              this.contactLogService.tableDataTemp.push({ 'id': x.id, 'logDate': this.localization.LocalizeDate(x.logDate), 'logType': logType?.logTypeInstance, 'logNotes': x.logNotes, 'logNotes_short': logNotes_shortdata, 'dueDate': x.dueDate ? this.localization.LocalizeDate(x.dueDate) : "", 'expireDate': x.expiryDate ? this.localization.LocalizeDate(x.expiryDate) : " ", 'isAlert': x.isAlert, 'isGroup': x.isGroup, 'isPrivate': x.isPrivate });
          });
        }
      }

    return this.contactLogService.tableDataTemp;
  }

  PrepareContactLogsData() {
    // if (this.appoinmentPopupService.clientEditData && this.appoinmentPopupService.clientWidowActionType == "EDIT") {
    //   this.appoinmentPopupService.clientContactLogs = this.appoinmentPopupService.clientEditData.clientContactLogs;
    // }
  }
}
