import { PaymentStatus } from "../globalsContant";
import { OrderSummaryComponenet } from "../../shop/order-summary/order-summary.component";
import { RetailShopItem } from "../../shop/shop.modals";
import { TransactionDetail } from "../business/shared.modals";
import { CustomFeeSourceType } from "../globalsContant";
import { SelectedProducts } from "../service/common-variables.service";
import { Amount, DepositTransactionStatus, InquirerInformation, PaymentDetails } from "../service/payment/payment-business.model";
import { SearchTypes } from "../service/retail.feature.flag.information.service";

export const retailEventName: string = "retailEvent";

export interface RetailEventParameters<T> {
  eventType: RetailEventType;
  data: T;
  callBack?: (data: T) => void;
}



export enum RetailEventType {
  // Common Events
  Deposit = 1,
  CancelDeposit,
  Void,
  Cancel,
  ReOpen,
  DeleteTransaction,
  ReturnWithTicket,
  Correct,
  SyncUpTransaction,
  CancelOrderSummary,
  LinkRetailItem,
  TeeTimeLinkRetailItem,
  CancellationNoShowCharge,
  WaiveOffCancellationNoShowCharge,
  DayPass,
  ClassCheckOut,
  CancelDayPass,
  UpdateCancellationNoShowCharge,
  TeeTimeCancellation,
  TeeTimeNoShow,
  UpdateAppointmentFee
}

export interface VoidEventModel {
  transactionId: number;
  isDeposit?: boolean;
  ticketNumber?: string;
  lstTransactionDetailId?: number[]
  sourceType?: CustomFeeSourceType | null
}

export interface TicketChangeEventModel {
  transactionId: number;
  oldTransactionId: number;
  transactionDetail: TransactionDetail[];
  ticketNumber?: string;
  oldTicketNumber?: string;
  returnData?: RetailShopItem[];
}

export interface UpdateAppointmentFeeEventModel{
  appointmentIds: number[];
  status: PaymentStatus
}

export interface DepositEventModel {
  id: number;
  typeId: number;
  amount: number;
  gratuity: number;
  serviceCharge: number;
  refundAmount?: number;
  depositTransactionId?: number;
  depositTransactionDetailId?: number;
  refundTransactionId?: number;
  isVoided?: boolean;
  status?: DepositTransactionStatus;
  clientId?: number;
  clientName?: string;
  clienttype?: number;
  clientLinkId?: string;
  sourceType?: CustomFeeSourceType;
  sourceTypeId?: number;
  retailItemId?: number;
  packageGroupId?: string;
  typeMappingId?: number;
  sncDepositPostPaymentRequest?: SNCDepositPostPaymentRequest;
  isConvenienceFee?: boolean;
}

export interface DepositFromSNCToPMS
{
  bookingDepositId: number;
  depositActionType: DepositActionType;
  depositPostPaymentRequest?: SNCDepositPostPaymentRequest;
}

 export enum DepositActionType {
  PostToPMS,
  Correction,
  Void
}

export interface SNCDepositPostPaymentRequest {
  ticketNumber: string;
  transactionId: number;
  tenderId: number;
  parentTenderId: number;
  roomNumber: string;
  roomReference: string;
  bookingId: string;
  amount: number;
  propName: string;
  paymentMethodId: number;
  transactionPaymentId: number;
  multiPMSPropCode: string;
  binLevelSplitUp: number;
  allowEarn: boolean;
  surcharge: number;
  isAutoRemoveTax: boolean;
  isAllowZeroPosting: boolean;
  pmsIntegrationHostId: string;
  discountExemptRatio: number;
  taxExemptRatio: number;
  guestName: string;
  paymentTypeId: number;
  parentTypeId: number;
  paymentMethodTableId: number;
  paymentReferenceComments: string;
  paymentMethodAdditionalConfigurations: any;
  versaPostRequest?: versaPostRequest;
  otherPMSPostRequest?: otherPMSPostRequest;
  outletId: number;
  multiPMSPropertyIndex: string;
  terminalId: number;
}

export interface versaPostRequest{
  checkHandleGuid: string;
  folioInvoiceNumber: string;
  roomChargePostTypeNumber: string;
  sourceSystem: string;
  clerkId: number;
  clerkUserName: string;
  retailTransactionType: string;
}

export interface otherPMSPostRequest {
  handle:string;
  originalTenderId?: number;
  inquirerInfo: InquirerInformation;
  paymentDetails: PaymentDetails;
  amount: Amount;
  transactionAmount?: Number;
  agentVersion?: string;
  hostName?: string;
  ipAddress?: string;
  pMSIntegrationHostId?: string;
  searchValue?: string;
  searchType: SearchTypes;
  allowTip?: boolean;
  requestId: string;
}
export interface DayPassEventModel {
  transactionId: number;
  transactionDetailsIds: any;
  sourceTypeId: number;
  amount: number;
  status: number;
}

export interface CancelDepositEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
  canPerformCancel?: boolean;
  customFeeSourceType?: CustomFeeSourceType
}
export interface CancelDayPassEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
  canPerformCancel?: boolean;
  customFeeSourceType?: CustomFeeSourceType
}
export interface CancelDayPassEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
  canPerformCancel?: boolean;
}

export interface CancellationNoShowChargeEventModel {
  id: number;
  appointmentId: number;
  appointmentDate: string;
  transactionId: number;
  ticketNumber: string;
  cancelReason: number;
  cancelComments: string;
  isCancellation: boolean;
  policyId: number;
  actualAmount: number;
  chargedAmount: number;
  status: CancellationNoShowChargeStatus;
  outletId: number;
}

export interface UpdateCancellationNoShowChargeEventModel {
  sourceTypeId: number;
  transactionId: number;
  isSettle: boolean;
  selecetedProducts?: SelectedProducts[];
  orderSummaryComponentScope?: OrderSummaryComponenet;
}

export enum CancellationNoShowChargeStatus {
  Charged = 1,
  NotCharged,
  WaivedOff,
  PendingSettlement
}

export interface TransactionCompletedModel {
  ticketNumber: number;
  checkNumber: string;
  transactionId: string;
}

export interface IClassCheckOutEventModel {
  classClientId: number,
  transactionId: number,
  transactionDetailId: number,
  tax: number,
  retailTicketNumber: string
}

