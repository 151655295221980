import { Injectable } from "@angular/core";
import { ActionTypeEnum, aligment } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { PSTCodeDataService } from "src/app/retail/shared/service/pstcode.data.service";
import { RevenueTypeDataService } from "src/app/retail/shared/service/revenuetype.data.service";
import { TaxTypeDataService } from 'src/app/retail/shared/service/taxtype.data.service';
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { Category } from './revenue-types.model';
import { API as TaxTypeAPI, TaxBased, TaxMaintenance, UI as TaxTypeUI, TaxTypeOptions, TaxMaintenanceByCriteria } from './tax-type.model';
import { API as PropertyAPI } from 'src/app/common/Models/property-settings.model';
import { MISCELLANEOUSCONFIGURATION } from 'src/app/common/constants';
import { PropertySettingDataService } from "src/app/common/dataservices/authentication/propertysetting.data.service";

@Injectable()
export class TaxTypeBusiness {

  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private _localization: RetailLocalization, private localization: RetailLocalization, private _taxTypeDataService: TaxTypeDataService
    , private _pSTCodeDataService: PSTCodeDataService, private _revenueTypeDataService: RevenueTypeDataService,
      public propertyInfo: RetailPropertyInformation,private utils: RetailUtilities, private _propertySettingDataService: PropertySettingDataService) {
    this.captions = this._localization.captions;
  }

  async getPostTypes(): Promise<DropdownOptions[]> {
    let postTypes = await this._taxTypeDataService.getAllPostTypes(false);
    postTypes = postTypes.filter(x => x.postTypeCatergoryId == Category.nonRevenueDebits);
    return postTypes.map(x => ({ id: x.id, value: x.postTypeNumber, viewValue: x.postTypeName, checked: false }));
  }

  mapTaxesForGrid(taxes: TaxTypeUI.TaxType[], postTypes: any[]): TaxTypeUI.TaxGrid[] {
    const UITaxes: TaxTypeUI.TaxGrid[] = [];
    taxes.forEach(x => {
      const postType = postTypes.find(y => y.id === x.postTypeId);
      UITaxes.push({
        id: x.id, taxType: x.taxType, listOrder: x.listOrder,
        postTypeNumber: postType ? postType.postNumber : '', isActive: { value: x.isActive.value }
      });
    });
    return UITaxes;
  }

  async getPSTTax(): Promise<DropdownOptions[]> {
    const pstTaxes = await this._taxTypeDataService.getAllPSTCodes(false);
    return pstTaxes.map(x => ({ id: x.id, value: x.id, viewValue: x.name, checked: false }));

  }

  public async getTaxType(includeInactive: boolean): Promise<TaxTypeUI.TaxType[]> {
    const apiModels: TaxTypeAPI.Tax[] = await this._taxTypeDataService.getAllTaxTypes(includeInactive);
    return apiModels.map(x => this.uiMapper(x));
  }

  public async getGridTaxType(includeInactive: boolean): Promise<TaxTypeUI.TaxGrid[]> {
    const apiModels = await this._taxTypeDataService.getAggregatedTaxTypes(includeInactive);
    return apiModels ? apiModels.map(x => this.gridUiMapper(x)) : [];
  }

  public async create(taxType): Promise<TaxTypeUI.TaxType[]> {
    const apiModels: TaxTypeAPI.Tax[] = await this._taxTypeDataService.createTaxType(this.APIMapperCreate(taxType));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async update(taxType): Promise<TaxTypeUI.TaxType[]> {
    const apiModels: TaxTypeAPI.Tax[] = await this._taxTypeDataService.updateTaxTypes(this.APIMapperCreate(taxType));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async getTaxTypeById(id: number): Promise<TaxTypeAPI.Tax> {
    try {
      return await this._taxTypeDataService.getTaxById(id);
    } catch (e) {
      console.error(e);
      throw e;
    }

  }

  public async delete(id: number): Promise<void> {
    try {
      return await this._taxTypeDataService.deleteTaxType(id);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  updateTaxTypeFromGrid(eventObj): Promise<TaxTypeAPI.Tax[]> {
    const taxTypeData: TaxTypeAPI.Tax = {
      id: eventObj.id,
      taxType: eventObj.taxType,
      postTypeId: eventObj.postTypeId,
      listOrder: eventObj.listOrder,
      exemptDays: eventObj.exemptDays,
      effectiveDate: eventObj.effectiveDate,
      isTaxable: eventObj.isTaxable,
      isPostWithRoom: eventObj.isPostWithRoom,
      isSpecializedUse: eventObj.isSpecializedUse,
      isShowOnSplits: eventObj.isShowOnSplits,
      isGSTTax: eventObj.isGSTTax,
      isOccupancyTax: eventObj.isOccupancyTax,
      isOCCTaxNoOfRooms: eventObj.isOCCTaxNoOfRooms,
      isPostTaxToFirstFolio: eventObj.isPostTaxToFirstFolio,
      pstCodeId: eventObj.pSTCodeId,
      taxExemptCategoryId: eventObj.taxExemptCategory,
      extractCode: eventObj.extractCode,
      isActive: eventObj.isActive.value,
      outletId: this.propertyInfo.GetDefaultOutlet(),
      taxMaintenances: [],
      excludeFlatTaxOnZeroCharge: eventObj.excludeFlatTaxOnZeroCharge,
      isInclusive : this.propertyInfo.IsVATEnabled ?? false,
      maxTaxAmount : 0,
      minTaxAmount : 0,
      minThresholdAmount : 0,
      specializedTaxId : 0
    };
    return this._taxTypeDataService.updateTaxTypesFromGrid(taxTypeData);
  }

  private uiMapper(TaxTypeAPIModel: TaxTypeAPI.Tax): TaxTypeUI.TaxType {
    return {
      id: TaxTypeAPIModel.id,
      taxType: TaxTypeAPIModel.taxType,
      postTypeNumber: TaxTypeAPIModel.postTypeId,
      postTypeId: TaxTypeAPIModel.postTypeId,
      listOrder: TaxTypeAPIModel.listOrder,
      isActive: { value: TaxTypeAPIModel.isActive, isDisabled: false },
      exemptDays: TaxTypeAPIModel.exemptDays,
      effectiveDate: TaxTypeAPIModel.effectiveDate,
      isTaxable: TaxTypeAPIModel.isTaxable,
      isPostWithRoom: TaxTypeAPIModel.isPostWithRoom,
      isSpecializedUse: TaxTypeAPIModel.isSpecializedUse,
      isShowOnSplits: TaxTypeAPIModel.isShowOnSplits,
      isGSTTax: TaxTypeAPIModel.isGSTTax,
      isOccupancyTax: TaxTypeAPIModel.isOccupancyTax,
      isOCCTaxNoOfRooms: TaxTypeAPIModel.isOCCTaxNoOfRooms,
      isPostTaxToFirstFolio: TaxTypeAPIModel.isPostTaxToFirstFolio,
      pSTCodeId: TaxTypeAPIModel.pstCodeId,
      taxExemptCategory: TaxTypeAPIModel.taxExemptCategoryId,
      extractCode: TaxTypeAPIModel.extractCode,      
      taxMaintenances: [],
      excludeFlatTaxOnZeroCharge: TaxTypeAPIModel.excludeFlatTaxOnZeroCharge,
      isInclusive : TaxTypeAPIModel.isInclusive,
      maxTaxAmount : 0,
      minTaxAmount : 0,
      minThresholdAmount : 0,
      specializedTaxId : 0
    } as TaxTypeUI.TaxType;
  }

  private gridUiMapper(TaxTypeAPIModel): TaxTypeUI.TaxGrid {
    return {
      id: TaxTypeAPIModel.id,
      taxType: TaxTypeAPIModel.taxType,
      postTypeNumber: TaxTypeAPIModel.postTypeNumber,
      postTypeId: TaxTypeAPIModel.postTypeId,
      listOrder: TaxTypeAPIModel.listOrder,
      isActive: { value: TaxTypeAPIModel.isActive, isDisabled: false },
    } as TaxTypeUI.TaxGrid;
  }

  public UITaxMapper(TaxTypeAPIModel: TaxTypeAPI.Tax, postTypes: DropdownOptions[]): TaxTypeUI.TaxType {
    return {
      id: TaxTypeAPIModel.id,
      taxType: TaxTypeAPIModel.taxType,
      postTypeNumber: TaxTypeAPIModel.postTypeId,
      postTypeId: TaxTypeAPIModel.postTypeId,
      listOrder: TaxTypeAPIModel.listOrder,
      isActive: { value: TaxTypeAPIModel.isActive, isDisabled: false },
      exemptDays: TaxTypeAPIModel.exemptDays,
      effectiveDate: TaxTypeAPIModel.effectiveDate,
      isTaxable: TaxTypeAPIModel.isTaxable,
      isPostWithRoom: TaxTypeAPIModel.isPostWithRoom,
      isSpecializedUse: TaxTypeAPIModel.isSpecializedUse,
      isShowOnSplits: TaxTypeAPIModel.isShowOnSplits,
      isGSTTax: TaxTypeAPIModel.isGSTTax,
      isOccupancyTax: TaxTypeAPIModel.isOccupancyTax,
      isOCCTaxNoOfRooms: TaxTypeAPIModel.isOCCTaxNoOfRooms,
      isPostTaxToFirstFolio: TaxTypeAPIModel.isPostTaxToFirstFolio,
      pSTCodeId: { id: TaxTypeAPIModel.pstCodeId },
      taxExemptCategory: TaxTypeAPIModel.taxExemptCategoryId,
      extractCode: TaxTypeAPIModel.extractCode,
      taxMaintenances: this.UITaxMaintenanceMapper(TaxTypeAPIModel.taxMaintenances, postTypes),
      excludeFlatTaxOnZeroCharge: TaxTypeAPIModel.excludeFlatTaxOnZeroCharge,
      isInclusive : TaxTypeAPIModel.isInclusive,
      maxTaxAmount : TaxTypeAPIModel.maxTaxAmount,
      minTaxAmount : TaxTypeAPIModel.minTaxAmount,
      minThresholdAmount : TaxTypeAPIModel.minThresholdAmount,
      specializedTaxId : TaxTypeAPIModel.specializedTaxId
    } as TaxTypeUI.TaxType;
  }

  private UITaxMaintenanceMapper(taxMaintenance: TaxMaintenance[], postTypes: DropdownOptions[]): TaxTypeUI.TaxMaintenance[] {
    let range: TaxTypeUI.TaxMaintenance[] = [];
    if (taxMaintenance && taxMaintenance.length > 0) {
      taxMaintenance.forEach(x => {
        const postType = postTypes.find(y => y.id === x.newPostTypeId);
        range.push({
          id: x.id,
          taxId: x.taxId,
          fromDate: x.fromDate,
          toDate: x.toDate,
          localizedFromDate: this._localization.LocalizeDate(x.fromDate),
          localizedToDate: this._localization.LocalizeDate(x.toDate),
          taxBasedOn: x.isTaxAmount ? TaxBased.TaxAmount : TaxBased.TaxPercent,
          newTaxBasedOn: x.isNewTaxAmount ? TaxBased.TaxAmount : TaxBased.TaxPercent,
          taxPercent: x.isTaxAmount ? 0 : this._localization.localizePercentage(x.taxAmount.toString()),
          taxFlatAmount: x.isTaxAmount ? this._localization.localizeCurrency(x.taxAmount, false) : 0,
          newTaxPercent: x.isNewTaxAmount ? 0 : this._localization.localizePercentage(x.newTaxAmount.toString()),
          newTaxFlatAmount: x.isNewTaxAmount ? this._localization.localizeCurrency(x.newTaxAmount, false) : 0,
          newPostTypeId: x.newPostTypeId,
          newPostTypeNumber: postType ? postType.value : 0,
          taxReference: x.taxReference,
          taxDept: '',
          taxPType: '',
          taxRound: 0,
          gaffile: 0,
          isDisable: true,
          taxMaintenanceByCriteria :  this.UITaxMaintenanceByCriteriaMapper(x.taxMaintenanceByCriteria),
          isActive : x.isActive
        });
      });
    }
    return range;
  }
  private UITaxMaintenanceByCriteriaMapper(taxMaintenanceByCriteria: TaxMaintenanceByCriteria[]): TaxTypeUI.TaxMaintenanceByCriteria[] {
    let range: TaxTypeUI.TaxMaintenanceByCriteria[] = [];
    if (taxMaintenanceByCriteria && taxMaintenanceByCriteria.length > 0) {
      taxMaintenanceByCriteria.forEach(x => {
        range.push({
          id : x.id,
          isActive : x.isActive,
          isTaxAmount : x.isTaxAmount,
          listOrderLinkId : x.listOrderLinkId,
          referenceType : x.referenceType,
          referenceValue : x.referenceValue,
          taxAmount : x.taxAmount,
          taxMaintenanceId : x.taxMaintenanceId
        });
      });
    }
    return range;
  }

  public getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'postTypeNumber',
        displayNameId: 'tbl_hdr_postTypeNumber',
        displayName: this.captions.tbl_hdr_postTypeNumber,
        searchable: true,
        sorting: true,
        sortingKey: 'postTypeNumber'
      },
      {
        key: 'taxType',
        displayNameId: 'tbl_hdr_taxType',
        displayName: this.captions.tbl_hdr_taxType,
        searchable: true,
        sorting: true,
        sortingKey: 'taxType'
      },
      {
        key: 'listOrder',
        displayNameId: 'tbl_hdr_listOrder',
        displayName: this.captions.tbl_hdr_listOrder,
        sorting: true,
        sortingKey: 'listOrder',
        alignment: aligment.right
      },
      {
        key: 'isActive',
        displayNameId: 'tbl_hdr_active',
        displayName: this.captions.tbl_hdr_active,
        sortingKey: 'isActive',
        templateName: ActionTypeEnum.toggle
      },
      {
        key: ActionTypeEnum.action,
        displayNameId: 'tbl_hdr_actions',
        displayName: this.captions.tbl_hdr_actions,
        searchable: false,
        sorting: false,
        templateName: ActionTypeEnum.action
      },
    ];
  }

  getSelectedItemArray(taxTypeData: TaxTypeUI.TaxType) {
    return [{ value: 1, viewValue: this.captions.lbl_taxable, checked: taxTypeData.isTaxable },
    { value: 2, viewValue: this.captions.lbl_Occ_Tax, checked: taxTypeData.isOccupancyTax },
    { value: 3, viewValue: this.captions.lbl_Post_With_Room, checked: taxTypeData.isPostWithRoom },
    { value: 4, viewValue: this.captions.lbl_Occ_Tax_Based_on_Number_of_Rooms, checked: taxTypeData.isOCCTaxNoOfRooms },
    { value: 5, viewValue: this.captions.lbl_Specialized_Use, checked: taxTypeData.isSpecializedUse },
    { value: 6, viewValue: this.captions.lbl_Post_This_Tax_To_First_Folio_In_Rooms, checked: taxTypeData.isPostTaxToFirstFolio },
    { value: 7, viewValue: this.captions.lbl_GST_Tax, checked: taxTypeData.isGSTTax },
    { value: 8, viewValue: this.captions.lbl_Show_On_Defined_Splits, checked: taxTypeData.isShowOnSplits },
    { value: 9, viewValue: this.captions.lbl_ExcludeFlatTaxOnZeroCharge, checked: taxTypeData.excludeFlatTaxOnZeroCharge }
    ];
  }

  getItemArray() {
    return [{ value: 1, viewValue: this.captions.lbl_taxable, checked: false },
    { value: 2, viewValue: this.captions.lbl_Occ_Tax, checked: false },
    { value: 3, viewValue: this.captions.lbl_Post_With_Room, checked: false },
    { value: 4, viewValue: this.captions.lbl_Occ_Tax_Based_on_Number_of_Rooms, checked: false },
    { value: 5, viewValue: this.captions.lbl_Specialized_Use, checked: false },
    { value: 6, viewValue: this.captions.lbl_Post_This_Tax_To_First_Folio_In_Rooms, checked: false },
    { value: 7, viewValue: this.captions.lbl_GST_Tax, checked: false },
    { value: 8, viewValue: this.captions.lbl_Show_On_Defined_Splits, checked: false },
    { value: 9, viewValue: this.captions.lbl_ExcludeFlatTaxOnZeroCharge, checked: false }
    ];
  }


  private APIMapperCreate(taxTypeInput): TaxTypeAPI.Tax {
    return {
      id: taxTypeInput.formValues.id,
      taxType: taxTypeInput.formValues.taxType,
      postTypeId: taxTypeInput.formValues.postTypeId,
      exemptDays: taxTypeInput.formValues.changeDays ? taxTypeInput.formValues.changeDays : 0,
      effectiveDate: this.localization.convertDateObjToAPIdate(taxTypeInput.formValues.effectiveDays),
      isTaxable: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.Taxable].checked,
      isPostWithRoom: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.PostWithRoom].checked,
      isSpecializedUse: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.SpecializedUse].checked,
      isShowOnSplits: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.ShowOnSplits].checked,
      isGSTTax: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.GSTTax].checked,
      isOccupancyTax: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.OccTax].checked,
      isOCCTaxNoOfRooms: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.OccTaxNoOfRooms].checked,
      isPostTaxToFirstFolio: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.PostFirstFolioInRooms].checked,
      pstCodeId: taxTypeInput.formValues.pstTax.id,
      taxExemptCategoryId: taxTypeInput.formValues.taxExemptCategories ? taxTypeInput.formValues.taxExemptCategories : 0,
      extractCode: taxTypeInput.formValues.extractCode ? taxTypeInput.formValues.extractCode : '',
      listOrder: taxTypeInput.formValues.listOrder ? taxTypeInput.formValues.listOrder : 0,
      isActive: taxTypeInput.formValues.isActive,
      outletId: this.propertyInfo.GetDefaultOutlet(),
      taxMaintenances: this.APITaxMaintenanceMapper(taxTypeInput.rangeformValues),
      excludeFlatTaxOnZeroCharge: taxTypeInput.taxTypeCheckBox[TaxTypeOptions.ExcludeFlatTaxOnZeroCharge].checked,
      isInclusive : this.propertyInfo.IsVATEnabled ?? false,
      maxTaxAmount : 0,
      minTaxAmount : 0,
      minThresholdAmount : 0,
      specializedTaxId : 0
    } as TaxTypeAPI.Tax;
  }

  private APITaxMaintenanceMapper(taxMaintenace): TaxMaintenance[] {
    let range: TaxMaintenance[] = [];
    if (taxMaintenace && taxMaintenace.length > 0) {
      taxMaintenace.forEach(x => {
        range.push({
          id: 0,
          taxId: 0,
          fromDate: this.localization.convertDateObjToAPIdate(x.fromDate),
          toDate: this.localization.convertDateObjToAPIdate(x.toDate),
          isTaxAmount: x.taxBasedOn == TaxBased.TaxPercent ? false : true,
          taxAmount: x.taxBasedOn == TaxBased.TaxPercent ? this.delocalizePercentage(x.taxPercent) : this.localization.currencyToSQLFormat(x.taxFlatAmount),
          isNewTaxAmount: x.newTaxBasedOn == TaxBased.TaxPercent ? false : true,
          newTaxAmount: x.newTaxBasedOn == TaxBased.TaxPercent ? this.delocalizePercentage(x.newTaxPercent) : this.localization.currencyToSQLFormat(x.newTaxFlatAmount),
          newPostTypeId: x.newPostTypeId ? x.newPostTypeId : 0,
          taxReference: x.taxReference ? x.taxReference : '',
          taxDept: '',
          taxPType: '',
          taxRound: 0,
          gaffile: 0,
          taxMaintenanceByCriteria : [],
          isActive : true
        });
      });
    }
    return range;
  }

  private uiMapperCreate(TaxTypeAPIModel: TaxTypeAPI.Tax): TaxTypeUI.TaxType {
    return {
      id: TaxTypeAPIModel.id,
      taxType: TaxTypeAPIModel.taxType,
      postTypeNumber: TaxTypeAPIModel.postTypeId,
      postTypeId: TaxTypeAPIModel.postTypeId,
      listOrder: TaxTypeAPIModel.listOrder,
      isActive: { value: TaxTypeAPIModel.isActive, isDisabled: false },
      exemptDays: TaxTypeAPIModel.exemptDays,
      effectiveDate: TaxTypeAPIModel.effectiveDate,
      isTaxable: TaxTypeAPIModel.isTaxable,
      isPostWithRoom: TaxTypeAPIModel.isPostWithRoom,
      isSpecializedUse: TaxTypeAPIModel.isSpecializedUse,
      isShowOnSplits: TaxTypeAPIModel.isShowOnSplits,
      isGSTTax: TaxTypeAPIModel.isGSTTax,
      isOccupancyTax: TaxTypeAPIModel.isOccupancyTax,
      isOCCTaxNoOfRooms: TaxTypeAPIModel.isOCCTaxNoOfRooms,
      isPostTaxToFirstFolio: TaxTypeAPIModel.isPostTaxToFirstFolio,
      pSTCodeId: TaxTypeAPIModel.pstCodeId,
      taxExemptCategory: TaxTypeAPIModel.taxExemptCategoryId,
      extractCode: TaxTypeAPIModel.extractCode,
      taxMaintenances: [],
      excludeFlatTaxOnZeroCharge: TaxTypeAPIModel.excludeFlatTaxOnZeroCharge,
      isInclusive :TaxTypeAPIModel.isInclusive,
      maxTaxAmount : 0,
      minTaxAmount : 0,
      minThresholdAmount : 0,
      specializedTaxId : 0
    } as TaxTypeUI.TaxType;
  }

  getTaxExemptcategoriesOptions() {
    return [
      {
        id: 1,
        value: '1',
        viewValue: this.captions.lbl_state
      },
      {
        id: 2,
        value: '2',
        viewValue: this.captions.lbl_country
      },
      {
        id: 3,
        value: '3',
        viewValue: this.captions.lbl_occ
      },
      {
        id: 4,
        value: '4',
        viewValue: this.captions.lbl_other
      }
    ];
  }

  public getTaxBasedOptions() {
    let options = [
      {
        id: TaxBased.TaxPercent,
        value: this.captions.lbl_taxPercent
      },
      {
        id: TaxBased.TaxAmount,
        value: this.captions.lbl_flatAmount
      }
    ];

    if(this.propertyInfo?.IsVATEnabled){
      options.splice(1,1);
    }
    return options
  }

  public getNewTaxBasedOptions() {
    let options = [
      {
        id: TaxBased.TaxPercent,
        value: this.captions.lbl_newTaxPercent
      },
      {
        id: TaxBased.TaxAmount,
        value: this.captions.lbl_flatAmount
      }
    ];
    
    if(this.propertyInfo?.IsVATEnabled){
      options.splice(1,1);
    }

    return options
  }

  public getHeaderOptionsCreate(): TableHeaderOptions[] {
    return [
      {
        key: 'localizedFromDate',
        displayNameId: 'tbl_hdr_fromDate',
        displayName: this.captions.tbl_hdr_fromDate,
        searchable: true,
        sorting: false,
        sortingKey: 'fromDate'
      },
      {
        key: 'localizedToDate',
        displayNameId: 'tbl_hdr_toDate',
        displayName: this.captions.tbl_hdr_toDate,
        searchable: true,
        sorting: false,
        sortingKey: 'toDate'
      },
      {
        key: 'taxPercent',
        displayNameId: 'tbl_hdr_taxPercent',
        displayName: this.captions.tbl_hdr_taxPercent,
        searchable: true,
        sorting: false,
        sortingKey: 'taxPercent'
      },
      {
        key: 'taxFlatAmount',
        displayNameId: 'tbl_hdr_taxAmount',
        displayName: this.captions.tbl_hdr_taxAmount,
        searchable: true,
        sorting: false,
        sortingKey: 'taxFlatAmount'
      },
      {
        key: 'newTaxPercent',
        displayNameId: 'tbl_hdr_newTaxPercent',
        displayName: this.captions.tbl_hdr_newTaxPercent,
        searchable: true,
        sorting: false,
        sortingKey: 'newTaxPercent'
      },
      {
        key: 'newTaxFlatAmount',
        displayNameId: 'tbl_hdr_newTaxAmount',
        displayName: this.captions.tbl_hdr_newTaxAmount,
        searchable: true,
        sorting: false,
        sortingKey: 'newTaxFlatAmount'
      },
      {
        key: 'taxReference',
        displayNameId: 'tbl_hdr_taxReference',
        displayName: this.captions.tbl_hdr_taxReference,
        searchable: true,
        sorting: false,
        sortingKey: 'taxReference'
      },
      {
        key: 'newPostTypeNumber',
        displayNameId: 'lbl_postTypeNumber',
        displayName: this.captions.lbl_postTypeNumber,
        searchable: true,
        sorting: false,
        sortingKey: 'newPostTypeNumber'
      },
      {
        key: ActionTypeEnum.action,
        displayNameId: 'tbl_hdr_actions',
        displayName: this.captions.tbl_hdr_actions,
        searchable: false,
        sorting: false,
        templateName: ActionTypeEnum.action
      }
    ];
  }

  public getTableOptions(isViewOnly): TableOptions {
    return {
      actions: [
        {
          type: TableActions.edit,
          disabled: false
        }, {
          type: TableActions.delete,
          disabled: false
        }
      ],
      ignoreSort: true,
      defaultsortingColoumnKey: '',
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: true,
        lastColumn: true
      },
      isDragDisabled: isViewOnly,
      showDeleteMsg: false
    };
  }

  delocalizePercentage(percentage: string): number {
    percentage = percentage ? percentage.toString() : '';
    if (percentage.trim() === '') {
      return 0;
    }
    const v = percentage.replace(this.localization.decimalSeparator, '.');
    return parseFloat(v);
  }
    public async getMiscConfigurations() {
      let propertyMiscConfig: PropertyAPI.MiscellaneousConfigurationSettings<any> = {
        configurationName: MISCELLANEOUSCONFIGURATION,
        propertyId: Number(this._localization.GetPropertyInfo("PropertyId")),
        productId: Number(this._localization.GetPropertyInfo("ProductId"))
      } as PropertyAPI.MiscellaneousConfigurationSettings<any>;
      return await this._propertySettingDataService.GetMiscConfigurationSettingTenantByPropertyId(propertyMiscConfig);
    }
}
