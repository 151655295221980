import { Injectable } from '@angular/core';
import { CommonApiRoutes } from '../common-route';
import { AccountingCommunication } from '../communication/services/accounting-communication.sercices';
import { ReportCommunication } from '../communication/services/account-report.service';
import { AccountingReportRoutes } from '../communication/common-route';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountingDataService
{
    constructor(private _communication: AccountingCommunication, private _accountingReportcommunication: ReportCommunication) {
    }

    public getAllDocumentIdsForScheduler(schedulerId, jobHistoryId) 
    {
          const result = this._communication.getPromise<string[]>(
             { route : CommonApiRoutes.GetAlldocumentIdsForScheduler, uriParams : {'schedulerId': schedulerId, 'jobHistoryId':jobHistoryId }}, false ); 
              return result;
    }
    public async getReportZipfile(documentIds : string[]) : Promise<Observable<Blob>>
    {
          const result = await this._accountingReportcommunication.putHTTPBlobData(
             {  body : documentIds,
                route : environment["AccountingReport"] + '/' + AccountingReportRoutes.GetZipFileByDocumentIds}, 'blob', true); 
              return result;
    }
    public async CheckIfARAccountExists() : Promise<boolean> {
        return await this._communication.getPromise<boolean>(
            { route: CommonApiRoutes.CheckIfARAccountExists },
            false
        )
    }
}