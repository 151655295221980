import { FormGroup } from '@angular/forms';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
export const MAX_DATEPICKER_RANGE_IN_YEARS = 9999;

export const enum ReportBreakPoint {
  TransactionLog = 1234,
  Discrepancies = 100025,
  Soldoutroomtypes = 100010,
  Unassignedroomreport = 100005,
  Outoforder = 100015,
  Currentoooaging = 100020,
  GuestTypeRoomTypeBreakdownByDate = 100055,
  BirthdayList = 100060,
  Segment = 100040,
  OriginGuestType = 100045,
  DENYLOST = 100030,
  UsersRecurringChecklists = 100690,
  RecurringChecklists = 100695,
  NOSHOWREPORT = 100035,
  Adjustments = 100315,
  GUESTLEDGERREPORTINCLUDINGRESERVATION = 100460,
  TransferredRooms = 100285,
  GuestSpecialRequestAndComments = 100295,
  GuestCountOnTheBooks = 100275,
  ExceededCreditLimit = 100400,
  RoomCondition = 100740,
  AssignedRoomReport = 100425,
  SummaryCancellations = 100100,
  CashPayingGuest = 100305,
  CodeSetUpCommon = 100735,
  RevenueAnalysisbyReservationlist = 100230,
  SummaryCancellationsByRoomNumber = 100190,
  InHouseSpecialRequest = 100120,
  SpecialRequestArrivals = 100115,
  CheckedOutWithBalance = 100630,
  RevenueByPostType = 100390,
  PossibleReservationDuplicate = 100210,
  InhouseGuestComplaint = 100290,
  Corrections = 100320,
  AllTransactions = 100310,
  BackToBackReservation = 100205,
  GuestOnWaitingList = 100150,
  FolioBalances = 100455,
  WalkedGuestInformation = 100105,
  PostingTransferred = 100445,
  InHouseLicensePlate = 100270,
  Weeklybusiness = 100435,
  GuestStayTask = 100125,
  GuestMessage = 100340,
  PostingBy = 100450,
  GuestPreference = 100175,
  StayOver = 100420,
  PostingAllocation = 100160,
  RoomNumberHistory = 100415,
  SevenDayFlash = 100250,
  GuestsExceedingMaxNights = 100235,
  PreviousStayInformation = 100520,
  Departures = 100090,
  WalkIns = 100325,
  ReservationOnTheBooks = 100050,
  TodaysDueOutandCheckOutFolios = 100601,
  TodaysCheckInsWithDeposits = 100565,
  Rooms = 100415,
  DepartmentRevenue = 100335,
  DepartmentRevenueBySettlement = 100336,
  DailyFlash = 100260,
  SystemPasscodeAudit = 100261,
  EarlyDeparture = 101470,
  FamilyTracking = 101485,
  TopGuest = 101475,
  GuestRequestSummaryArrival = 100070,
  DerivativeRates = 100840,
  DetailArrivals = 100065,
  DetailArrivalsByAreaCode = 100080,
  SummaryArrivalsByAreaCode = 100085,
  ExpandedDetailArrivals = 100075,
  PropertyStatus = 100345,
  AllDepositsSummary = 100575,
  ReservationsEntered = 100110,// dummy break point, Yet to get actual one
  DepositBalance = 100555,
  InHouseDetail = 100870,
  InHouseSummary = 100875,
  PackageArrivalDetail = 100880,
  PackageArrivalSummary = 100885,
  DetailedCancellations = 100095,
  GuestList = 100135,
  MarketAnalysiswithUnlimitedDays = 100410,
  DepositBalanceReportWithPayment = 100570,
  StayTotalReportbyPostType = 100485,
  RevenueAndOccupancyByGuestType = 100355,
  RoomRateSetup = 100625,
  RoomRateReport = 100490,
  WeeklyForecast = 100215,
  ForecastRevenueByStateCountry = 100225,
  PackageDiscounts = 100130,
  CompPosting = 100770,
  PostScan = 100545,
  AuthorizerList = 100765,
  ProjectedRevenue = 100170,
  PackageDiscountAnalysis = 100915,
  FrequentFlyer = 100685,
  RoomIncome = 100525,
  DepositTransaction = 100560,
  AllDepositDetails = 100550,
  LateCheckout = 100265,
  SummaryGraphReport = 100330,
  GuestMealProjectionReportDetail = 100900,
  GuestMealProjectionReportSummary = 100905,
  RevenueByDriver = 100675,
  CompError = 100795,
  CompIssue = 100785,
  CompRedemption = 100780,
  PreventativeMaintenance = 101045,
  PartsNeededByStaff = 101050,
  PartsForecast = 101060,
  PrintAllSheet = 101120,
  TaxExempt = 100590,
  CompVoid = 100790,
  CompRoomCount = 100850,
  ForecastRevenueSummary = 100220,
  TariffYield = 100835,
  TimeTravelFlash = 100395,
  RevenueAndOccupancyByOrigin = 100360,
  RevenueAndOccupancyBySegment2 = 100380,
  RevenueAndOccupancyBySegment1 = 100375,
  RevenueAndOccupancyByTariff = 100385,
  RevenueAndOccupancyByRoomType = 100370,
  CurrentDayFlash = 100255,
  ReservationsPostingDetailActivityReport = 100585,
  TenDaysOnBooksReport = 100185,
  PackageGroupMembers = 100930,
  CompRoom = 100845,
  InterfacePosting = 0,
  DestinationTravel = 100680,
  RecurringPostings = 100540,
  PackagePlanPostingDetails = 100925,
  SevenDayGroupAttendeePackage = 100895,
  RoomScan = 100535,
  SocialPickUpReport = 100745,
  RevenueByDestination = 100670,
  RevenueByBooking = 100676,
  PackageByEntryArrivalDate = 0,  //to get the breakpoint
  TripConfirmation = 100665,
  CancelledDepositList = 100615,
  VIPReports = 100140,
  DailyMarketCodeAnalysis = 100350,
  MarketCodeAnalysis31Days = 100405,
  RoomsOnTheBooks = 100530,
  DriverTrip = 100660,
  GroupWaitingList = 100965,
  UserID = 100855,
  UserName = 100860,
  UserNameByRole = 100865,
  GroupRoomPickupAnalisys = 100985,
  DepositReturned = 100620,
  AnticipatedFolioBalance = 100605,
  PackageBreakDown = 100890,
  ProjectedPackageRevenue = 100920,
  SecurityBreakpoint = 100935,
  ArrivalsGuestCard = 100750,
  ReservationByState = 100750,
  GroupArrival = 100700,
  GroupInHouseSummary = 100710,
  PackageTariffChangeReport = 100245,
  PmsSalesCall = 100955,
  PmsRoomPace = 100950,
  GroupInHouseDetail = 100705,
  TaskReport = 100970,
  SoftCheckInCheckOut = 100640,
  AIRPORTTRAVEL = 100155,
  GroupActivityReport = 100960,
  ReservationCutOff = 100725,
  OverDueDeposit = 100580,
  AccountListing = 100975,
  GroupResume = 100980,
  MaintenaceVendorListing = 101065,
  MaintenacePartsListing = 101085,
  MaintenanceEquipmentListing = 101095,
  CreditCardSwipeVerification = 100655,
  TravelAgentListReport = 101025,
  ReservationActivity = 101000,
  RevOccByPlan = 100365,
  OutStandingCommission = 101035,
  InhouseTravelAgentStays = 101010,
  LinenInventoryRequirement = 101115,
  TravelAgentStatistics = 101020,
  TopTenTravelAgent = 101030,
  CashSummary = 100475,
  AccrualEarnPoints = 100830,
  ActivitiesByItinerary = 100995,
  SecurityAccessLog = 100940,
  MaintenanceStaff = 101070,
  LostandFound = 101125,
  MaintenancePartsListingByVendor = 101090,
  TurnDown = 101105,
  ScreenviewDataLog = 100240,
  PartsInventoryReport = 101055,
  HouseKeeperProjectedPoints = 101135,
  MaintenancePartsUsedSummary = 101075,
  MaintenancePartsUsedDetail = 101080,
  GuestActivitiesByDate = 100990,
  TurndownSections = 101100,
  HouseKeepingSheet = 101130,
  Turnaround = 101110,
  ShiftDropLog = 101140,
  ShiftReport = 101145,
  PrioritySheet = 101280,
  CustomSection = 101150,
  Summary = 101285,
  ARTraceMaintenance = 101245,
  CustomerLabel = 101165,
  UnappliedCreditPayment = 101195,
  Invoice = 101220,
  ARDeletedInvoice = 101230,
  ARUnInvoicedTransactions = 101225,
  //QUERY BUILDER  
  QueryBuilder = 101290,
  Folio = 101295,
  GenericExtract = 101300,
  Group = 101305,
  Guest = 101310,
  Reservations = 101315,
  Retail = 101320,
  RoomInventory = 101325,
  FrontDesk = 101331,
  Sales = 101332,
  Default = 101500,
  CustomerDiscounts = 101160,
  CreditCardProcessing = 101250,
  CustomerListing = 101155,
  PaymentHistory = 101180,
  AppliedCredits = 101190,
  CreditLimit = 101200,
  ARAging = 101175,
  PaymentHistoryAnalysis = 101185,
  ARStatements = 101235,
  TransactionReport=101170,
  WorkOrders=101440,
  GroupFolioDetailedandSummary = 101445,
  GroupReservationFolioReport = 101495,
  GroupGuestListReport =101450,
  GroupRoster = 101455,
  ClosingBalanceReport = 101460,
  ReservationsWithPets = 101490,
  MultipropertyReports = 500010
}
export enum ReportCode {
  TransactionLog = 'PMSTransactionLog',
  OutOfOrder = 'OutOfOrder',
  LostandFound = 'LostandFound',
  UnassignedRooms = 'UnassignedRooms',
  SoldOutRoomTypes = 'SoldOutRoomTypes',
  Discrepancies = 'Discrepancies',
  OutOfOrderAging = 'OutOfOrderAging',
  GuestTypeRoomTypeBreakdownByDate = 'GuestTypeandRoomTypeBreakdownDate',
  PartsForecast = 'PartsForecast',
  PrintAllSheet = 'PrintAllSheet',
  PartsNeededByStaff = 'PartsNeededByStaff',
  PreventativeMaintenance = 'PreventativeMaintenance',
  BirthdayList = 'BirthdayList',
  Segments = 'Segments',
  OriginGuestType = 'OriginGuestType',
  DenyLost = 'LostDeny',
  ReservationBySegment1 = 'ReservationBySegment1',
  ReservationBySegment2 = 'ReservationBySegment2',
  ReservationByOrigin = 'ReservationByOrigin',
  ReservationByGuestType = 'ReservationByGuestType',
  UsersRecurringChecklists = 'UserRecurringCheckLists',
  RecurringChecklists = 'RecurringCheckLists',
  NoShow = 'ReservationNoShow',
  Adjustments = 'Adjustments',
  DepositBalanceMP = 'DepositBalanceMP',
  FolioBalancesMP = 'FolioBalancesMP',
  RevenueAndOccupancyByGuestTypeMP = 'RevenueAndOccupancyByGuestTypeMP',
  RevenueAndOccupancyByOriginMP = 'RevenueAndOccupancyByOriginMP',
  RevenueAndOccupancyByRoomTypeMP = 'RevenueAndOccupancyByRoomTypeMP',
  RevenueAndOccupancyBySegment1MP = 'RevenueAndOccupancyBySegment1MP',
  RevenueAndOccupancyBySegment2MP = 'RevenueAndOccupancyBySegment2MP',
  RevenueAndOccupancyByTariffMP = 'RevenueAndOccupancyByTariffMP',
  AdjustmentsGroupByBuilding = 'AdjustmentsGroupByBuilding',
  PostingBy = 'PostingBy',
  GuestLedgerBalanceIncludingReservation = 'GuestLedgerBalanceIncludingReservation',
  CashPayingGuest = 'CashPayingGuest',
  TransferredRooms = 'TransferredRooms',
  GuestSpecialRequestAndComments = 'GuestSpecialRequestAndComments',
  GuestCountOnBooksSummary = 'GuestCountOnBooksSummary',
  GuestCountOnBooksDetail = 'GuestCountOnBooksDetail',
  ExceededCreditLimit = 'ExceededCreditLimit',
  RoomCondition = 'RoomCondition',
  CustomerLabel = 'CustomerLabel',
  VATInvoice = 'VATInvoice',

  ARInvoice = 'ARInvoice',
  CreditLimit = 'CreditLimit',
  AssignedRooms = 'AssignedRooms',
  CodeSetUpCommon = 'CodeSetUpCommon',
  SummaryCancellations = 'SummaryCancellations',
  CheckedOutWithBalance = 'CheckedOutWithBalance',
  RevenueByPostType = 'RevenueByPostType',
  RevenueAnalysisbyReservationlist = 'RevenueAnalysisbyReservationlist',
  SummaryCancellationsByRoomNumber = 'SummaryCancellationsByRoomNumber',
  InHouseSpecialRequest = 'InHouseSpecialRequest',
  SpecialRequestArrivals = 'SpecialRequestArrivals',
  PossibleReservationDuplicate = 'PossibleDuplicateReservation',
  InhouseGuestComplaint = 'InhouseGuestComplaints',
  Corrections = 'Corrections',
  CorrectionsGroupByBuilding = 'CorrectionsGroupByBuilding',
  AllTransactions = 'AllTransactions',
  AllTransactionsGroupByBuilding = 'AllTransactionsGroupByBuilding',
  BackToBackReservation = 'BackToBackReservation',
  GuestOnWaitingListDetail = 'GuestOnWaitingListDetail',
  GuestOnWaitingListSummary = 'GuestOnWaitingListSummary',
  FolioBalances = 'FolioBalances',
  FolioBalancesGroupByBuilding = 'FolioBalancesGroupByBuilding',
  FolioBalancesGroupByPostType ='FolioBalancesGroupByPostType',
  WalkedGuestInformation = 'WalkedGuestInformation',
  PostTransferredByRoom = 'PostTransferredByRoom',
  PostTransferredByPosting = 'PostTransferredByPosting',
  PostTransferredByDate = 'PostTransferredByDate',
  InHouseLicensePlate = 'InHouseLicensePlate',
  Weeklybusiness = 'Weeklybusiness',
  GuestStayTask = 'GuestStayTasks',
  GuestMessage = 'GuestMessage',
  GuestPreference = 'GuestPreference',
  ARDeletedInvoice = 'ARDeletedInvoice',
  ARUninvoicedTransactions = 'ARUninvoicedTransactions',
  Rooms = 'Rooms',
  PreviousStayInformation = 'PreviousStayInformation',
  StayOver = 'StayOver',
  PostingAllocation = 'PostingAllocation',
  RoomNumberHistory = 'RoomNumberHistory',
  SevenDayFlash = 'SevenDayFlash',
  GuestsExceedingMaxNights = 'GuestsExceedingMaxNights',
  GuestsExceedingMaxNightsRoomType = 'GuestsExceedingMaxNightsRoomType',
  Departures = 'Departures',
  WalkIns = "WalkIns",
  TodaysDueOutandCheckOutFolios = 'TodaysDueOutandCheckoutFolios',
  InHouseSummary = "InHouseSummary",
  ReservationOnTheBooks = 'ReservationOnTheBooks',
  TodaysCheckInsWithDeposits = 'TodaysCheckInsWithDeposits',
  DepartmentRevenue = 'DepartmentRevenue',
  DepartmentRevenueBySettlement = 'DepartmentRevenueBySettlement',
  DepartmentRevenueByBuildingId = 'DepartmentRevenueByBuildingId',
  GuestRequestSummaryArrival = "GuestRequestSummaryArrival",
  GuestRequestSummaryArrivalGroupByBuildingCode = "GuestRequestSummaryArrivalGroupByBuilding",
  DailyFlash = "DailyFlash",
  SystemPasscodeAudit = "SystemPasscodeAudit",
  EarlyDeparture = "Earlydepature",
  FamilyTracking = "FamilyTracking",
  DerivativeRates = 'DerivativeRates',
  DetailArrivals = 'DetailArrivals',
  SummaryArrivals = 'SummaryArrivals',
  DetailArrivalsByAreaCode = 'DetailArrivalsByAreaCode',
  SummaryArrivalsByAreaCode = 'SummaryArrivalsByAreaCode',
  ExpandedDetailArrivals = 'ExpandedDetailArrivals',
  PropertyStatus = 'PropertyStatus',
  AllDepositsSummary = 'AllDepositsSummary',
  MarketAnalysis = 'DayMarketingAnalysisUnlimitedDays',
  ReservationsEntered = 'ReservationsEntered',
  ReservationsEnteredDetail = 'ReservationsEnteredDetail',
  InHouseDetail = "InHouseDetail",
  PackageArrivalDetail = "PackageArrivalDetail",
  PackageArrivalSummary = "PackageArrivalSummary",
  DepositBalance = 'DepositBalance',
  DepositReturned = "DepositReturned",
  ProjectedLinedRequirement = "LinenInventoryRequirement",
  PrioritySheet = "PrioritySheet",
  CustomSection = "CustomSection",
  HouseKeepingSheet = 'HouseKeepingSheet',
  TaxExempt = 'TaxExempt',
  TurnDownReport = "TurnDown",
  AllDepositsSummaryGroupByBuilding = 'AllDepositsSummaryGroupByBuilding',
  DetailedCancellations = 'DetailedCancellations',
  GuestListByNameRoomNo = 'GuestListByNameRoomNo',
  GuestListByNameRoomNoPackageMrkChannel = 'GuestListByNameRoomNoPackageMrkChannel',
  GuestListByArrivalTime = 'GuestListByArrivalTime',
  GuestList = 'GuestList',
  DepositBalanceReportWithPayment = 'DepositBalanceWithPayment',
  StayTotalReportbyPostType = 'StayTotalReportbyPostType',
  StayTotalReportByGusetType = 'StayTotalReportbyGuestType',
  StayTotalReportByRoomType = 'StayTotalReportbyRoomType',
  RoomRateReport = 'RoomRateReport',
  RevenueAndOccupancyByGuestType = 'RevenueAndOccupancyByGuestType',
  RevenueAndOccupancyByOrigin = 'RevenueAndOccupancyByOrigin',
  RevenueAndOccupancyByRoomType = 'RevenueAndOccupancyByRoomType',
  RevenueAndOccupancyBySegment2 = 'RevenueAndOccupancyBySegment2',
  RevenueAndOccupancyBySegment1 = 'RevenueAndOccupancyBySegment1',
  RevenueAndOccupancyByTariff = 'RevenueAndOccupancyByTariff',
  RevenueAndOccupancyByGuestTypeGroupByBuilding = 'RevenueAndOccupancyByGuestTypeGroupByBuilding',
  RevenueAndOccupancyByOriginGroupByBuilding = 'RevenueAndOccupancyByOriginGroupByBuilding',
  RevenueAndOccupancyByRoomTypeGroupByBuilding = 'RevenueAndOccupancyByRoomTypeGroupByBuilding',
  RevenueAndOccupancyBySegment1GroupByBuilding = 'RevenueAndOccupancyBySegment1GroupByBuilding',
  RevenueAndOccupancyBySegment2GroupByBuilding = 'RevenueAndOccupancyBySegment2GroupByBuilding',
  RevenueAndOccupancyByTariffGroupByBuilding = 'RevenueAndOccupancyByTariffGroupByBuilding',
  RoomRateSetup = 'RoomRateSetup',
  WeeklyForecast = 'WeeklyForecast',
  ForecastRevenueByStateCountry = 'ForecastRevenueByStateCountry',
  PackageDiscounts = "PackageDiscounts",
  CompPosting = "CompPosting",
  AuthorizerList = "AuthorizerList",
  ProjectedRevenue = "ProjectedRevenue",
  PackageDiscountAnalysis = "PackageDiscountsAnalysis",
  RoomIncome = "RoomIncome",
  FrequentFlyer = "FrequentFlyer",
  Turndown = "TurndownReport",
  TurndownNoGuestNameReport = "TurndownNoGuestNameReport",
  TurndownReportWithoutGroupByBuilding = "TurndownReportWithoutGroupByBuilding",
  TurndownNoGuestNameReportWithoutGroupByBuilding = "TurndownNoGuestNameReportWithoutGroupByBuilding",
  PostScan = "PostScan",
  PostScanlandscape = "PostScanlandscape",
  DepositTransaction = 'DepositTransactions',
  AllDepositDetails = 'AllDepositDetails',
  AccountListing = 'PMSAccountsListing',
  DailyMarketAnalysis = 'DailyMarketCodeAnalysis',
  MarketAnalysis31Days = 'DayMarketingAnalysis31Days',
  LateCheckout = 'LateCheckoutReport',
  AnticipatedFolioBalance = 'AnticipatedFolioBalance',
  LateCheckoutGroupByBuildingReport = 'LateCheckoutGroupByBuildingReport',
  SummaryGraphReport = 'SummaryGraphReport',
  GuestMealProjectionReportDetail = 'GuestMealProjectionReportDetail',
  GuestMealProjectionReportSummary = 'GuestMealProjectionReportSummary',
  RevenueByDriver = 'RevenueByDriver',
  CompError = "CompError",
  CompIssue = "CompIssue",
  CompRedemption = "CompRedemption",
  CompVoid = "CompVoid",
  CompRoomCount = "CompRoomCount",
  ForecastRevenueSummary = "ForecastSummaryReport",
  TariffYield = 'TariffYield',
  MaintenanceVendorListing = 'MaintenanceVendorListing',
  MaintenancePartsListing = 'MaintenancePartsListing',
  MaintenanceEquipmentListing = 'MaintenanceEquipmentListing',
  CurrentDayFlash = 'CurrentDayFlash',
  TimeTravelFlash = 'TimeTravelFlash',
  RecurringPostings = 'RecurringPostings',
  ReservationsPostingDetailActivityReport = 'ReservationsPostingDetailActivityReport',
  TenDaysOnBooksReport = 'TenDayOnBooks',
  PackageGroupMembers = 'PackageGroupMembers',
  CompRoom = "CompRoom",
  InterfacePosting = "InterfacePosting",
  DestinationTravel = 'DestinationTravel',
  PackagePlanPostingDetails = "PackagePlanPostingDetails",
  SevenDayGroupAttendeePackage = "SevenDayGroupAttendeePackage",
  RevenueByDestination = 'RevenueByDestination',
  RevenueByBooking = 'RevenueByBooking',
  PackageByEntryArrivalDate = "PackageByEntryArrivalDate",
  ProjectedRev = "ProjectedRev",
  ProjectedRevByMonth = "ProjectedRevByMonth",
  TripConfirmation = "TripConfirmation",
  RoomScan = 'RoomScan',
  SocialPickUpReprt = 'SocialPickUpReport',
  CancelledDeposits = "CancelledDeposits",
  VIPReports = 'vipreports',
  VIPSummaryReport = 'VIPSummaryReport',
  VIPDetailReport = 'VIPDetailReport',
  RoomsOnTheBooks = 'RoomsOnTheBooks',
  RoomsOnTheBooksTest = 'RoomsOnTheBooksTest',
  DriverTrip = "DriverTrip",
  Recap = "Recap",
  GroupWaitingListSummary = 'GroupWaitingListSummary',
  GroupWaitingListDetail = 'GroupWaitingListDetail',
  CheckoutStatement = 'CheckoutStatement',
  FolioInvoice = 'FolioInvoice',
  ProFormaFolio = 'ProFormaFolio',
  CurrencyConversion = 'EncashmentReceipt',
  EncashmentReceipt = 'CurrencyConversion',
  UserId = 'UserID',
  GroupRoomPickupAnalisys = "PMSGroupRoomPickupAnalysis",
  PackageBreakDown = "PackageBreakdown",
  SecurityAccessLog = 'SecurityAccessLog',
  ProjectedPackageRevenue = "ProjectedPackageRevenue",
  ProjectedPackageRevenueNonGroup = "ProjectedPackageRevenueNonGroup",
  SecurityBreakpoint = 'SecurityBreakpoint',
  ArrivalsGuestCard = 'ArrivalsGuestCard',
  GroupArrival = "GroupArrival",
  GroupInHouseSummary = "GroupInHouseSummary",
  PackageTariffChangeReport = "PackageandTariffchangeReport",
  PMSSalesCallSummary = 'PMSSalesCallSummary',
  PMSSalesCallDetail = 'PMSSalesCallDetail',
  PMSRoomPace = 'PMSRoomPace',
  HouseStatusOfRoomTypes = 'HouseStatusOfRoomTypes',
  OverDueDeposit = "OverDueDeposit",
  CreditCardSwipeVerification = 'CreditCardSwipeVerification',
  GroupInHouseDetail = 'GroupInHouseDetail',
  GroupFolioBalance = 'GroupFolioBalance',
  TaskReport = 'PMSTaskReport',
  SoftCheckInCheckOut = 'SoftCheckInCheckOut',
  GroupActivityReport = "GroupActivityReport",
  AirportTravel = 'AirportTravel',
  UserName = 'UserName',
  UserInfo = 'UserInfo',
  UserInfoByClass = 'UserInfoByClass',
  ReservationCutOff = 'ReservationCutOff',
  PMSGroupResume = 'PMSGroupResume',
  TravelAgentList = 'TravelAgentList',
  TravelAgentStatistics = 'TravelAgentStatistics',
  TopTenTravelAgent = 'TopTenTravelAgent',
  ReservationActivity = 'ReservationActivity',
  RevOccByPlan = 'RevOccByPlan',
  TopGuest = "TopGuest",
  OutStandingCommission = "OutStandingCommission",
  BatchCheckinStatement = 'BatchCheckinStatement',
  InhouseTravelAgentStays = 'InhouseTravelAgentStays',
  LinenInventoryRequirement = 'LinenInventoryRequirement',
  InterfacePostingLog = 'InterfacePostingLog',
  CashSummary = 'CashSummary',
  ActivitiesByItinerary = 'ActivityByItinerary',
  MaintenanceStaff = 'MaintenanceStaff',
  AccrualEarnPoints = 'AccrualEarnPoints',
  AccrualPoints = 'AccrualPoints',
  MaintenancePartsListingByVendor = 'MaintenancePartsListingByVendor',
  ScreenViewDataLog = 'ScreenViewDataLog',
  PartsInventoryReport = 'PartsInventoryReport',
  GuestActivitiesbyDate = 'GuestActivitiesByDate',
  HouseKeeperProjectedPoints = 'HouseKeeperProjectedPoints',
  MaintenancePartsUsedSummary = 'MaintenancePartsUsedSummary',
  MaintenancePartsUsedDetail = 'MaintenancePartsUsedDetail',
  TurndownSections = 'TurndownSections',
  BookingAccountTransLog = 'BookingAccountTransLog',
  Turnaround = 'Turnaround',
  ShiftDropLog = 'ShiftDropLog',
  ShiftReport = 'ShiftReport',
  Summary = 'Summary',
  CustomerDiscounts = 'CustomerDiscounts',
  CreditCardProcessing = 'CreditCardProcessing',
  WakeUpCall = 'WakeUpCall',
  CustomersWithDiscount = 'CustomersWithDiscount',
  ARAging = 'ARAging',
  ARTraceMaintenance = 'ARTraceMaintenance',
  PaymentHistoryAnalysis = 'PaymentHistoryAnalysis',
  ARStatements = 'ARStatements',
  CustomerListing = "CustomerListing",
  PaymentHistory = 'PaymentHistory',
  AppliedCredits = 'AppliedCredits',
  WorkOrder = 'WorkOrder',
  WorkOrderSummary = 'WorkOrderSummary',
  ARTrace = 'ARTraces',
  CustomerListingSummary = 'CustomerListingSummary',
  CustomerListingDetail = 'CustomerListingDetail',
  UnappliedCreditPayment = 'UnappliedCreditPayment',
  TransactionsByBatch = 'TransactionsByBatch',
  TransactionsByTransactionCode = 'TransactionsByTransactionCode',
  TransactionsByCustomerAccount = 'TransactionsByCustomerAccount',
  TransactionsByBatchTemplate = 'TransactionsByBatchTemplate',
  TransactionsByAccountCategory = 'TransactionsByAccountCategory',
  TransactionsByTransactionCodeSummarized = 'TransactionsByTransactionCodeSummarized',
  TransactionsByCustomerAccountCode = 'TransactionsByCustomerAccountCode',
  TransactionsByPostDate = 'TransactionsByPostDate',
  ARTRansactionReport = 'ARTransactionReport',
  GuestSummary='GuestSummary',
  WorkOrders = 'WorkOrders',
  ConciergeServices = 'ConciergeServices',
  RegistrationCard = 'RegistrationCard',
  GroupReservationFolio = "GroupReservationFolio",
  GroupFolioSummary = "GroupFolioSummary",
  GroupFolioDetail = "GroupFolioDetail",
  GroupGuestList = "GroupGuestList",
  ClosingBalanceDetails = 'ClosingBalanceDetails',
 GroupRoster = "GroupRoster",
 GroupMemberList = "GroupMemberList",
 ReservationByState = "ReservationByState",
 GroupMemberListForAllAgeGroups = "GroupMemberListForAllAgeGroups",
 RoomStatusHistory = "RoomStatusHistory",
 ReservationsWithPets = "ReservationsWithPets",
 ARDetailedCheckRegister = "ARDetailedCheckRegister",
 CustomerCheckPaymentPrint = 'CustomerCheckPaymentPrint',
 CheckRunPreviewReport = 'CheckRunPreviewReport',
 ARSummarizedCheckRegister = "ARSummarizedCheckRegister",
 PreviewCheckPrint = 'PreviewCheckPrint',
 CheckRegister = 'CheckRegister',
 DetailedCheckRegister = 'DetailedCheckRegister',
 PaymentBatchEMVReceipt="PaymentBatchEMVReceipt",
 ForeignExchangeForm = 'ForeignExchangeForm',
 DailySummaryforGroups = "DailySummaryforGroups",
 PersonSummaryforGroups = "PersonSummaryforGroups",
 PostPerDayStatement = "PostPerDayStatement",
 TransactionCodeSummaryperStay = "TransactionCodeSummaryperStay",
 CheckoutStatementLocalLang = 'CheckoutStatementArabic',
 GuestFolioStatement = 'GuestFolioStatement',
 PackageEntitlement = "PackageEntitlement",

}

export enum ReportGroup {
  Transaction,
  Rooms,
  Managers,
  Reservation,
  Checklist,
  FrontDesk,
  Packages,
  Revenue,
  GuestService,
  CompAccounting,
  Accounting,
  Security,
  Groups,
  Retail,
  GiftCards,
  Commissiongratuity,
  Activities,
  Maintenance,
  HouseKeeping,
  Multiproperty
}
export enum ReportType {
  PDF,
  WORD
}

export enum ReportMenu {
  transaction = 'transaction',
  rooms = 'rooms',
  managers = 'managers',
  reservation = 'reservation',
  checklist = 'checklists',
  frontDesk = 'frontDesk',
  Packages = 'packages',
  revenue = 'revenue',
  guestservice = 'guestservice',
  compAccounting = 'compaccounting',
  security = 'security',
  groups = 'groups',
  Retail = 'retail',
  GiftCards = 'giftcards',
  Commissiongratuity = 'commissiongratuity',
  activities = 'activities',
  maintenance = 'maintenance',
  houseKeeping = 'housekeeping',
  accounting = 'accounting',
  querybuilder = 'querybuilder',
  multiProperty = 'multiproperty'
}

export enum TransLogType {
  OTACodeSetup = 'OTACode Setup',
  KeyEncoderStationSetup = 'KeyEncoderStation Setup',
  CancellationCodeSetup = 'CancellationCode Setup',
  CancellationPolicySetup = 'CancellationPolicy Setup',
  BillingCodeSetup = 'BillingCode Setup',
  ContactTypeSetup = 'ContactType Setup',
  PreferenceDetailSetup = 'PreferenceDetail Setup',
  ClassesSetup = 'Classes Setup',
  ComtrolPostTypeSetup = 'ComtrolPostType Setup',
  IndustryCodeSetup = 'IndustryCode Setup',
  MarketSegmentSetup = 'MarketSegment Setup',
  Segment1Setup = 'Segment1 Setup',
  Segment2Setup = 'Segment2 Setup',
  CreditCardTerminalSetup = 'CreditCardTerminal Setup',
  RoomLocationSetup = 'RoomLocation Setup',
  PostingRevenueBucketsSetup = 'PostingRevenueBuckets Setup',
  VIPTypeSetup = 'VIPType Setup',
  PreferenceCategorySetup = 'PreferenceCategory Setup',
  TariffCodeSetup = 'TariffCode Setup',
  GuestSpecialRequirementSetup = 'GuestSpecialRequirement Setup',
  SpecialGuaranteeCodeSetup = 'SpecialGuaranteeCode Setup',
  ComplaintCodeSetup = 'ComplaintCode Setup',
  DenyAndLostCodeSetup = 'DenyAndLostCode Setup',
  OriginSetup = 'Origin Setup',
  NewspaperTypeSetup = 'NewspaperType Setup',
  RoomFeatureSetup = 'RoomFeature Setup',
  CurrencyCodeSetup = 'CurrencyCode Setup',
  RegionSetup = 'Region Setup',
  DepositMethodsSetup = 'DepositMethods Setup',
  ChecklistCodeSetup = 'ChecklistCode Setup',
  ArrivalTypeSetup = 'ArrivalType Setup',
  PSTCodeSetup = 'PSTCode Setup',
  BuildingSetup = 'Building Setup',
  MarketChannelTypeSetup = 'MarketChannelType Setup',
  GuestTypeSetup = 'GuestType Setup',
  OTAFeeMaintenanceSetup = 'OTAFeeMaintenance Setup',
  RoomTypeSetup = 'RoomType Setup',
  PotentialCodeSetup = 'PotentialCode Setup',
  ContactRelationshipMaintenanceSetup = 'ContactRelationshipMaintenance Setup',
  NotificationTriggers = 'NotificationTriggers',
  GroupActivityReport = "GroupActivityReport"
  // Codegen#ReportsTransLog



}
export enum CustomTemplate {
    FormName = 'CustomTemplateForm',
    BirthdayList = 'BirthdayList',
    Segments = 'Segments',
    OriginGuestType = 'OriginGuestType',
    NoShow = 'NoShow',
    PossibleReservationDuplicate = "PossibleReservationDuplicate",
    GuestStayTasks = 'GuestStayTasks',
    PreviousStayInformation = 'PreviousStayInformation',
    GuestsExceedingMaxNights = 'GuestsExceedingMaxNights',
    DerivativeRates = 'DerivativeRates',
    MarketAnalysis = 'MarketAnalysis',
    GuestList = 'GuestList',
    CompPosting = "CompPosting",
    DailyMarketAnalysis = 'DailyMarketAnalysis',
    MarketAnalysis31Days = 'MarketAnalysis31Days',
    TariffYield = 'TariffYield',
    RoomCondition = 'RoomCondition',
    CustomerLabel = 'CustomerLabel',
    ARUninvoicedTransactions = 'ARUninvoicedTransactions',
    CreditLimit = 'Credit Limit',
    InvoiceReport = 'InvoiceReport',
    ReservationEntered = 'ReservationEntered',
    PostScan = 'PostScan',
    DestinationTravel = 'DestinationTravel',
    ArrivalReportsDetailedAndSummary = 'ArrivalReportsDetailedAndSummary',
    GuestOnWaitingList = 'GuestOnWaitingList',
    GroupRoomPickupAnalisys = 'GroupRoomPickupAnalisys',
    TransferredRooms = "TransferredRooms",
    ProjectedPackageRevenueComponent = "ProjectedPackageRevenueComponent",
    GroupWaitingList = 'GroupWaitingList',
    SalesCall = "SalesCall",
    RoomPace = "RoomPace",
    GroupInHouseDetail = 'GroupInHouseDetail',
    TaskReportComponent = 'TaskReportComponent',
    GroupActivityReport = 'GroupActivityReport',
    GroupResumeTemplateComponent = 'GroupResumeTemplateComponent',
    RecurringPostings = 'RecurringPostings',
    AirportTravel = 'AirportTravel',
    RoomIncome = 'RoomIncome',
    PackageBreakDown = 'PackageBreakdown',
    ForecastRevenueByStateCountry = "ForecastRevenueByStateCountry",
    ReservationActivity = 'ReservationActivity',
    RevOccByPlan = 'RevOccByPlan',
    VIPReportsSummaryAndDetailed = 'VIPReportsSummaryAndDetailed',
    SocialPickup = 'SocialPickup',
    AccrualEarnPoints = 'AccrualEarnPoints',
    RoomRate = 'RoomRate',
    ShiftReport = 'ShiftReport',
    CustomerDiscounts = 'CustomerDiscounts',
    ARAging = 'ARAging',
    ARTraceMaintenance = 'ARTraceMaintenance',
    PaymentHistoryAnalysis = 'PaymentHistoryAnalysis',
    ARStatements = 'ARStatements',
    CustomerListing = 'CustomerListing',
    PaymentHistory = 'PaymentHistory',
    AppliedCredits = 'AppliedCredits',
    DepartmentRevenueBySettlement = 'DepartmentRevenueBySettlement',
    UnappliedCreditPayment = 'UnappliedCreditPayment',
    GroupFolio="GroupFolio",
    GroupReservationFolio = "GroupReservationFolio",
    GroupGuestList = "GroupGuestList",
    RegistrationCard = "RegistrationCard",
    // Add your custom template Here
    
    ARTransactionReport = "ARTransaction",
    WorkOrders = "WorkOrders",
    GroupRoster = "GroupRoster",
    PackageGroupMember = "PackageGroupMembers",
    TopGuest = "TopGuest"
}
export enum JasperReportUrlPath {
  AppointmentListingUrlPath='/public/SPA/Appointment/AppointmentListing/Appointment_Listing',
  NoShowUrlPath='/public/SPA/Appointment/NoShowAppointment/No_Show',
  OnlineAppointmentUrlPath='/public/SPA/Appointment/OnlineAppointment/Online_Appointment',
  CustomField4UrlPath='/public/SPA/Appointment/CustomField4/Appointment_CustomFiels_4',
  CustomField5UrlPath='/public/SPA/Appointment/CustomField5/Appointment_CustomFiels_5',
  ForeignExchangeForm = "/public/PMS/GuestService/ForeignExchangeForm/ForeignExchangeForm"
}
export interface ARTransactionsFilter {
  dropDownIds: number[];
  startDate: string;
  endDate: string;
  selectOptions: number;
  batchStatus: number;
  isBatchRange: boolean;
  fromBatchRange: number;
  toBatchRange: number

}

export interface ARDeletedInvoiceFilter {
  fromDate: string,
  toDate: string
}