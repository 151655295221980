import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CommonVariablesService } from '../../shared/service/common-variables.service';
import { Product } from '../../shared/globalsContant';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import _ from 'lodash';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ItemType } from '../shop.modals';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { RedirectToModules, RetailUtilities } from '../../shared/utilities/retail-utilities';
import { takeUntil} from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'app-view-retail-items',
  templateUrl: './view-retail-items.component.html',
  styleUrls: ['./view-retail-items.component.scss']
})
export class ViewRetailItemsComponent implements OnInit,OnDestroy {
  createModalData: createModal;
  captions: any;
  routerState: any;
  routePathFrom: string;
  title: string;
  playerInfo: any;
  @Input() frompage;
  @Input() buyretailItem;
  GolfId  = Product.GOLF;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  itemTypeEnum = ItemType;
  playerRetailItems:any;
  playerRentalItems: any;
  outletId: any;
  addorUpdateLabel : string;
  isFromCreateScreen = false;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  mandatoryRentalItemIds: number[] = [];
  bulkPlayersInfo: any = [];
  courseId: number;
  isFromBulkTeeTime: boolean = false;

  constructor(private localization: RetailLocalization, private _retailService: RetailSharedVariableService, private router: Router,
     public _shopservice: CommonVariablesService, private PropertyInfo: RetailPropertyInformation
     , private featureFlagInformation: RetailFeatureFlagInformationService, private utils: RetailUtilities,public route: ActivatedRoute ) { 
    const currentNavigation = this.router.getCurrentNavigation();
    this.routerState = currentNavigation ? currentNavigation.extras.state : null;
    this.routePathFrom = this.routerState?.routePathFrom ? this.routerState?.routePathFrom : null;
    this.title = this.routerState?.title ? this.routerState?.title : null;
    this.playerInfo = this.routerState?.playerInfo?.playerDetail ? this.routerState?.playerInfo.playerDetail: null; 
    this.bulkPlayersInfo = this.routerState?.bulkPlayerInfo?.flatMap(x => x.playerDetail);
    this.playerRetailItems = this.routerState?.playerRetailItems; 
    this.playerRentalItems = this.routerState?.playerRentalItems;
    this.courseId = this.routerState?.playerInfo?.course?.id ?? 0;
    this.isFromBulkTeeTime = this.routerState?.isBulkTeeTime;
    this.mandatoryRentalItemIds = this.routerState?.mandatoryRentalItemIds;
    this.outletId = this.routerState?.outletId ? this.routerState?.outletId: this._shopservice.SelectedOutletId; 
    if(this._shopservice.ProductId == this.GolfId && !this.routerState){
      this.router.navigate(['/tee-time/teesheet/teeSheet']);
    }
    route.queryParams.pipe(takeUntil(this.$destroyed)).subscribe(val => {
      if (val) {
        this.isFromCreateScreen = val?.fromCreateScreen;
      }
    })
  }

  ngOnInit(): void {
      this.captions = this.localization.captions;
      if(this._shopservice.ProductId == this.GolfId){
        let course = this.routerState?.playerInfo?.course.course ? ' | ' + this.captions.lbl_courses + ' - ' + this.routerState?.playerInfo.course.course : '';
        let TeeTimevalue = this.localization.getDate(this.routerState?.playerInfo.time);
        let localizedDate = this.localization.localizeDisplayDate(TeeTimevalue);
        let localizedTime = this.localization.LocalizeTime(TeeTimevalue, true);
        let teeTime = '';
        if(localizedDate && localizedTime){
          teeTime =  ' | '  + this.captions.lbl_teetime + ' - ' + localizedDate + " " + localizedTime;
        }      
        this.title = this.title + teeTime + course;
      }
      this.createModalData = {
        title: this.title
      };
      this.actionButton = {
        type: 'primary',
        label: this.captions.shop.AddToTeeTime,
        disabledproperty: true
      };
      this.cancelButton = {
        type: 'tertiary',
        label: this.captions.btn_cancel,
        disabledproperty: false
      };
      this.addorUpdateLabel = (this.playerRetailItems?.length > 0 || this.playerRentalItems?.length > 0) ? this.captions.shop.UpdateToTeeTime : this.captions.shop.AddToTeeTime; 
      this._shopservice.isdisableUpdate = (this.playerRetailItems?.length > 0 || this.playerRentalItems?.length > 0) ;   
      this._shopservice.golfPlayerInfo = this.playerInfo;
      this._shopservice.golfBulkPlayerInfo = this.bulkPlayersInfo;
      this._shopservice.addRetailItemToSource = true;
      this._shopservice.groupedRetailItems = this.playerRetailItems;
      this._shopservice.groupedRentalItems = this.playerRentalItems;
      this._shopservice.removedRentalItems = [];
      this._shopservice.courseId = this.courseId;
      this._shopservice.isFromBulkTeeTime = this.isFromBulkTeeTime;
      this._shopservice.SelectedOutletId = this.outletId;
      this._shopservice.mandatoryRentalItemIds = this.mandatoryRentalItemIds ?? [];
      this.MapTo_shopservice();
  }

  MapTo_shopservice() {
    switch (this._shopservice.ProductId) {
      case Product.SPA:
        this.MapSPAProperties();
        break;
      default: break;
    }
    this.MapCommonProperties();
    this.MapCommonConfig();
  }

  MapSPAProperties() {
    this._shopservice.AppoinmentIdForCheckOut = this._retailService.checkOutIds;
    this._shopservice.isAppointmentCheckOut = this._retailService.isFromCheckOut;
    this._shopservice.isClassCheckOut = this._retailService.isFromClassCheckOut;
    this._shopservice.GuaranteeMethodData = this._retailService.GuaranteeMethodData;
    this._shopservice.CheckOutCallback = this._retailService.checkOutCallback;
    this._shopservice.excludeTaxForDeposit = this._retailService.excludeTax;
    this._shopservice.cancelDepositAppointments = this._retailService.cancelDeposit;
    this._shopservice.cancelDayPassAppointments = this._retailService.cancelDayPass;
    this._shopservice.cancelRetailItems = this._retailService.cancelRetailItems;
    this._shopservice.cancelFlag = this._retailService.cancelFlag;
    this._shopservice.ticketNumber = this._retailService.ticketNumber;
    this._shopservice.isReopenViewOnly = this._retailService.isReopenViewOnly;
    this._shopservice.retailTransaction.tabState = 0;
    this._shopservice.isFromSpaWizard = this._retailService.isFromSpaWizard;
    this._shopservice.isFromAppointment = this._retailService.isFromAppointment;
    this._shopservice.addRetailItemToSource = this._retailService.addRetailItemToSource;
    this._shopservice.appoinmentId = this._retailService.appoinmentId;
    this._shopservice.LinkedRetailItemDetails = this._retailService.LinkedRetailItemDetails;  
    this._shopservice.isFromTherapistPortal = this._retailService.isFromTherapistPortal;
    this._shopservice.isDayPass = this._retailService.isDayPass;
  }

  MapCommonProperties() {
    this._shopservice.selectedPayeeId = this._retailService.payeeId;   //To do   
    this._shopservice.sourceCorpId = this._retailService.sourceCorpId; 
    this._shopservice.selectedGuestGuid = this._retailService.guestGuid;
    this._shopservice.transactionId = this._retailService.transactionId;
    this._shopservice.isFromDeposit = this._shopservice.depositFlag = this._retailService.isFromDeposit;
    this._shopservice.depositArray = this._retailService.depositArray;
    this._shopservice.RedirectModule = this._retailService.RedirectModule;
    this._shopservice.settleOpenTransaction = this._retailService.settleOpenTransaction;
    this._shopservice.reOpenTransaction = this._retailService.reOpenTransaction;
    this._shopservice.isFromDayEnd = this._retailService.isFromDayEnd;
    this._shopservice.selectedTransaction = this._retailService.selectedTransaction ? this._retailService.selectedTransaction : this._shopservice.selectedTransaction;
    this._shopservice.isFromRetailDashBoard = this._retailService.isFromRetailDashBoard;
    this._shopservice.TaxValue = this._retailService.TaxValue;
    this._shopservice.GoToRetailTransaction = this._retailService.GoToRetailTransaction;
    this._shopservice.memberCardNumber = this._retailService.memberCardNumber;
    this._shopservice.ReceiptComment = this._retailService.receiptComment;
    this._shopservice.selectedRetailItem = this._retailService.selectedRetailItem;
    this._shopservice.sectionName = this._retailService.sectionName;
  }

  MapCommonConfig() {
    this._shopservice.SelectedOutletId = this._retailService.SelectedOutletId;
    this._shopservice.SelectedTerminalId = Number(this._retailService.SelectedTerminalId);
    this.PropertyInfo.SetDefaultDataOnLoad();
    if (!this.PropertyInfo.UseRetailInterface) { // Setting terminalId as '1' by default for all embedded Transactions
      this._shopservice.SelectedTerminalId = 1;
    }
    const RWT_TransactionTypes = [
      "RETURN WITH TICKET WITHOUT EXCHANGE",
      "RETURN WITH TICKET WITH EXCHANGE"
    ];
    
  }

  onCancel(e){
    this.releaseTempHold();
    this.clearInfo();
    if(this._shopservice.isFromTherapistPortal){
      this.router.navigateByUrl('appointment/newappointment');
    }
    else {
      this.isFromCreateScreen ? this.utils.RedirectTo(RedirectToModules.TeeTime, false, true) : this.utils.RedirectTo(RedirectToModules.TeeTime);
    }
  }
  clearInfo(){
    this._shopservice.groupedRetailItems = [];
    this._shopservice.groupedRentalItems = [];
    this._shopservice.removedRentalItems = [];
    this._shopservice.mandatoryRentalItemIds = [];
    this._shopservice.isFromBulkTeeTime = false;
    this._shopservice.golfPlayerInfo = [];
  }
  ngOnDestroy(){
    this._shopservice.golfPlayerInfo = [];
    this._shopservice.removedRentalItems = [];
    this.clearInfo()
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  onSave(e) {
    this.releaseTempHold();
    this._shopservice.addToTeeTime.next();
  }
  selectedTabValue($event){
    this._shopservice.detectRetailRentalTabChange.next();
  }

  releaseTempHold(){
    if(!this.isFromCreateScreen){ //restricting release temphold when rental/retail from booking screen
      let teeTimeInfo = {
        scheduleDateTime : this.routerState.playerInfo?.time,
        courseId: this.routerState.playerInfo?.course?.id,
        holeNumber: this.routerState.playerInfo?.hole,
        originalHoleNumber: this.routerState.playerInfo?.originalHoleNumber
      };
      this._shopservice.releaseTempHold.next(teeTimeInfo);
    }
  }

}
