import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from '../../utilities/common-utilities';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { emailLogsBusiness } from '../email-logs/email-logs.business';
import { MsGraphApiCommunication } from 'src/app/common/communication/services/ms-graph.service';

@Component({
  selector: 'app-view-email-logs',
  templateUrl: './view-email-logs.component.html',
  styleUrls: ['./view-email-logs.component.scss'],
  providers: [emailLogsBusiness]
})
export class ViewEmailLogsComponent implements OnInit {
  
  captions: any;
  mailLogData: any;
  isViewOnly: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Output() deleteLogEmit = new EventEmitter();

  @Input('isViewOnly')
  set setViewOnly(value){
    if(value){
      this.isViewOnly = value;
    }
  }
  @Input('mailLogData')
  set setMailLogData(value){
    if(value){
      this.mailLogData = value;
    }
  }
  constructor(private localization: Localization,private utils: CommonUtilities, private location: Location,public dialog: MatDialog, private emailClient: MsGraphApiCommunication, private emailLogBusiness: emailLogsBusiness) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
  }

  deleteLog(event){
    this.utils.showAlert(this.captions.warn_delete_emailLog, AlertType.Warning, ButtonType.YesNo, async res => {
      if(res === AlertAction.YES){
        this.deleteLogEmit.emit(event);
      }
    })

  }

  downloadFile(attachment,index){
    this.downloadLogicFunction(attachment,index)
  }

  downloadAllFile(attachment){
    
    for(var attachIndex = 0;attachIndex <attachment.length;attachIndex++){
      this.downloadLogicFunction(attachment,attachIndex)
    }
  }
    downloadLogicFunction(file,index){
      const base64Data = atob(file[index].contentBytes); // Example base64 data
      const byteCharacters = base64Data; // Decode base64 to binary
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray]);

      // Create a temporary object URL for the Blob
      const url = window.URL.createObjectURL(blob);
    
      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = file[index].name;  // Set the filename for the download
    
      // Trigger the download
      anchor.click();
    
      // Clean up: revoke the object URL after download
      window.URL.revokeObjectURL(url);
    }

  async composeMail(subject, ccAddress: string[], toAddress: string[], mailContent: string, attachmentList: any[], mailData: any) {
    const emailSetting = await this.emailLogBusiness.getEmailSetting();
    if (emailSetting === null) {
      const proceed = await this.showEmailTrackingAlert();
      if (!proceed) {
        return;
      }
    } else {
      ccAddress = this.assignCCAddress(ccAddress, emailSetting.sentEmail);
    }
    mailContent = mailContent != '' ? this.emailLogBusiness.patchMailBody(mailData) : '';
    this.emailLogBusiness.prepareAndComposeMailMessage(subject, ccAddress, toAddress, mailContent, attachmentList);
  }

  assignCCAddress(ccAddress: string[], sentEmail: string): string[] {
    if (ccAddress.length == 0 || !ccAddress.find(x => x == sentEmail)) {
      ccAddress.push(sentEmail);
    }
    return ccAddress;
  }

  async showEmailTrackingAlert() {
    return new Promise<boolean>((resolve) => {
      this.utils.showAlert(
        this.captions.warn_emailTracking,
        AlertType.Warning,
        ButtonType.YesNo,
        (res) => {
          resolve(res === AlertAction.YES);
        }
      );
    });
  }

  async validateAuthentication() : Promise<boolean>{
    const result = await this.emailClient.isAuthenticatedToAction();
    return result;
  }

    replyMail(data){
      if(!this.validateAuthentication())
        return;
      const copiedData = JSON.parse(JSON.stringify(data));
      let subject = !copiedData.emailSubject.includes('RE: ') ? 'RE: ' + copiedData.emailSubject : copiedData.emailSubject;
      if(subject.includes('FW: '))
        subject = subject.replace('FW: ', '');
      let toAddress: string[] = [];
      toAddress.push(copiedData.fromAddress);
      this.composeMail(subject, [], toAddress, copiedData.bodyContent, [], copiedData);
    }

    replyAllMail(data){
      if(!this.validateAuthentication())
        return;
      const copiedData = JSON.parse(JSON.stringify(data));
      let subject = !copiedData.emailSubject.includes('RE: ') ? 'RE: ' + copiedData.emailSubject : copiedData.emailSubject;
      if(subject.includes('FW: '))
        subject = subject.replace('FW: ', '');
      let toAddress: string[] = []; let ccAddress: string[] = [];
      this.emailClient.currentUser.subscribe(x => {
        if(x != ''){
          let signedInuser = x.replace(this.captions.mail_signedInAs, '');
          toAddress = copiedData.toAddress != null ? this.assignToAddressForReplyAll(signedInuser, copiedData) : [];
          ccAddress = copiedData.ccAddress != null ? copiedData.ccAddress.filter(x => x != signedInuser) : [];
        }
      })
      this.composeMail(subject, ccAddress, toAddress, copiedData.bodyContent, [], copiedData);
    }

    assignToAddressForReplyAll(signedInuser: string, mailData: any) :string[]{
      let toAddress: string[] = [];
      toAddress = mailData.toAddress.filter(x => x != signedInuser);
      if(mailData.fromAddress != signedInuser)
        toAddress.push(mailData.fromAddress);
  
      return toAddress;
    }

    forwardMail(data){
      if(!this.validateAuthentication())
        return;
      const copiedData = JSON.parse(JSON.stringify(data));
      let subject = !this.mailLogData.emailSubject.includes('FW: ') ? 'FW: ' + this.mailLogData.emailSubject : this.mailLogData.emailSubject;
      if(subject.includes('RE: '))
        subject = subject.replace('RE: ', '');

      this.composeMail(subject, [], [], copiedData.bodyContent, copiedData.attachmentList, copiedData);
    }
    


}
