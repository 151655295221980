import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { EmailActions, EmailSenderType } from './custom-email-ckeditor.model';
import { MsGraphRoutes } from 'src/app/common/common-route';
import { MsGraphApiCommunication } from 'src/app/common/communication/services/ms-graph.service';
import { EmailAddress, GraphServiceParams, MailAttachment, MailBody, MailContact, MailMessage, MimeType, ODataTypes, SendEmailRequest } from 'src/app/common/Models/ms-graph-http.model';
import { CommonUtilities } from '../../utilities/common-utilities';
import { AlertType } from 'src/app/common/Models/common.models';
import { ButtonType } from 'src/app/common/enums/shared-enums';

@Injectable()
export class emailCkEditorBusiness {
    captions: any;
    constructor(private localization: Localization, private gClient: MsGraphApiCommunication, private utils: CommonUtilities) {
        this.captions = this.localization.captions;
    }

    getEmailActionList(){
        return [
            {
                id: EmailActions.delete,
                value: EmailActions.delete,
                viewValue: this.captions.lbl_discard,
                icon:'icon-delete'
            },
            {
                id: EmailActions.attachFile,
                value: EmailActions.attachFile,
                viewValue: this.captions.lbl_attachFile,
                icon:'icon-link'
            }
        ]
    }

    //need to do R&D for getting users email in a domain
    // getMailArrayList(){
    //     // let userList = await this.gClient.isAuthenticatedToAction(' for getting users').then(async res => {
    //     //     if (res) {
    //     //         return await this.gClient.getPromise<any>({
    //     //             route: MsGraphRoutes.MeUsers,
    //     //              isBeta:true,
    //     //         }, false).then(res => {
    //     //             return res;
    //     //         });
    //     //     } else {
    //     //         return Promise.reject('Authentication Error');
    //     //     }
    //     // });
    //     return [
    //         {
    //             id: 0,
    //             value: 'venkatesan.kaliyugavarathan@agilysys.com',
    //             viewValue:'venkatesan.kaliyugavarathan@agilysys.com',
    //             isVisible: true,
    //         },
    //         {
    //             id: 1,
    //             value: 'amarnath.karuppasamy@agilysys.com',
    //             viewValue:'amarnath.karuppasamy@agilysys.com',
    //             isVisible: true,    
    //         },
    //         {
    //             id: 1,
    //             value: 'akulurusai.vamsikrishna@agilysys.com',
    //             viewValue:'akulurusai.vamsikrishna@agilysys.com',
    //             isVisible: true,    
    //         }            
    //     ]
    // }

    mapMailAddress(mailAddress : any[]) : any[]{
        return mailAddress.map(mail => {
            return{
                id: 0,
                value: mail,
                viewValue: mail,
                isVisible: true
            }
        })
    }

    sendMail(formValue) {
        let graphParams = this.mapSendMailRequestInput(formValue);
        this.gClient.postPromise(graphParams, true).then(x => {
            this.utils.showAlert('Email Sent Successfully', AlertType.Success, ButtonType.Ok);
        });
    }

    mapSendMailRequestInput(formValue) {
        const mailMessage: SendEmailRequest = {
            message:{
                subject: formValue.subject,
                toRecipients: formValue.senderContent && formValue.senderContent.filter(x => x.sectionId == EmailSenderType.to).length > 0 ? this.mapRecipientsEmailAddress(formValue.senderContent.find(x => x.sectionId == 0).emailList) : [],
                ccRecipients: formValue.senderContent && formValue.senderContent.filter(x => x.sectionId == EmailSenderType.cc).length > 0 ? this.mapRecipientsEmailAddress(formValue.senderContent.find(x => x.sectionId == 1).emailList) : [],
                attachments: formValue.attachmentList && formValue.attachmentList.length > 0 ? this.mapAttachmentList(formValue.attachmentList) : [],
                body: this.mapMailBody(formValue.emailThreadValue),
            }
        };
        const graphParams: GraphServiceParams = {
            forceLogin: true,
            route: MsGraphRoutes.MeSendMail,
            body: mailMessage
        };
        return graphParams;
    }

    mapMailBody(body){
        const mailBody: MailBody = {
            content: body,
            contentType: 'html'
        }
        return mailBody;
    }

    mapRecipientsEmailAddress(emailList: any[]) {
        const mailRecipients: MailContact[] = [];
        emailList.forEach(email => {
            let mailRecipient = {} as MailContact;
            mailRecipient.emailAddress = {
                address: email.value
            } as EmailAddress;
            mailRecipients.push(mailRecipient);
        })
        return mailRecipients;
    }

    mapAttachmentList(attachmentList: any[]){
        const attachments: MailAttachment[] = [];
        attachmentList.forEach(attachment => {
            let contentBytes = attachment.content.toString();
            let base64String = contentBytes.split(',').pop();
            let mailAttachment = {
                '@odata.type': ODataTypes.FILEATTACHMENT,
                name: attachment.fileName,
                contentType: attachment.extension,
                contentBytes: base64String
            } as MailAttachment;
            attachments.push(mailAttachment);
        })
        return attachments;
    }

    patchMailBody(data){
        if (data) {
            let toAddress = data.toAddress && data.toAddress != null ? data.toAddress.join(", ") : "";
            let ccAddress = data.ccAddress && data.ccAddress != null ? data.ccAddress.join(", ") : "";
            let emailHeader = `<div><br><br><br><br><p style="background:'red'">
            <p><strong>From:</strong> ${data.fromAddress}</p>
            <p><strong>Sent:</strong> ${data.emailDateTime}</p>
            <p><strong>To:</strong> ${toAddress}</p>
            <p><strong>CC:</strong> ${ccAddress}</p>
            <p><strong>Subject:</strong> ${data.emailSubject}</p>
            </div>`
            if(!data.bodyContent || data.bodyContent == null){
                data.bodyContent = "<p></p>";
            }
            data.bodyContent = data.bodyContent.replace('<body>', `<body>${emailHeader}`);
            return data.bodyContent;
        }
        
    }
    
}
