import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entry-period-packages',
  templateUrl: './entry-period-packages.component.html',
  styleUrls: ['./entry-period-packages.component.scss']
})
export class EntryPeriodPackagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
