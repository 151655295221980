import { Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { AgDropdownConfig, DropdownOptions } from 'src/app/common/Models/ag-models';
import { DayPassManagementBusiness } from '../day-pass-management.business';
import { cloneDeep } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Days } from 'src/app/appointment/spa-wizard/spa-wizard.modal';

@Component({
  selector: 'app-entry-period-guest-types',
  templateUrl: './entry-period-guest-types.component.html',
  styleUrls: ['./entry-period-guest-types.component.scss'],
  providers : [DayPassManagementBusiness],
  encapsulation : ViewEncapsulation.None
})
export class EntryPeriodGuestTypesComponent implements OnInit {

  form: UntypedFormGroup;
  captions : any;
  isViewOnly: boolean;
  guestTypeInput : AgDropdownConfig;
  guestTypeOptions : DropdownOptions[];
  headerOptions :any = [];
  updatedheaderOptions :any = [];
  floatLabel : string;
  setAvailable: any;
  guestArr: UntypedFormArray;
  selectedGuestArr : any =[];
  validFlags: boolean[] = [];
  availableCount:any = [];
  exceededDays = [];
  entryPeriodForm : any;
  @Output() emitGuestForm: EventEmitter<any> = new EventEmitter();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  days = ['Sun','Mon','Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


  constructor(private fb: FormBuilder,private localization: Localization,private business: DayPassManagementBusiness) { 
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }
  @Input() editGuestData;
 @Input('available')
  set available(value) {
    if (value) {
      this.entryPeriodForm = value;
      this.setAvailable = value.value;
      this.availableCount = [
        this.setAvailable.Sun ?? 0,
        this.setAvailable.Mon ?? 0,
        this.setAvailable.Tue ?? 0,
        this.setAvailable.Wed ?? 0,
        this.setAvailable.Thu ?? 0,
        this.setAvailable.Fri ?? 0,
        this.setAvailable.Sat ?? 0
      ]
      
      this.disableDays();
    }
  }

  @Input('triggerDaysValidation')
  set ValidationDays(value) {
    if(value) {
      if(this.headerOptions.length > 0) {
        this.headerOptions.forEach(x=>{
          if(x.key != "guestType")
          this.validateAndAdjust(x.key)
        })
      }
    }
  }
  ngOnInit(): void {
    this.initialize();
    if(this.editGuestData) {
      this.editGuestData.guestType.forEach(x=> {
        this.addGuest(x);
      })
      this.selectedGuestArr = this.editGuestData.guestType;
      this.form.patchValue(this.editGuestData);
      console.log(this.form.controls)
      this.setAvailable = this.editGuestData;
      this.availableCount = [
        this.setAvailable.Sun ?? 0,
        this.setAvailable.Mon ?? 0,
        this.setAvailable.Tue ?? 0,
        this.setAvailable.Wed ?? 0,
        this.setAvailable.Thu ?? 0,
        this.setAvailable.Fri ?? 0,
        this.setAvailable.Sat ?? 0
      ]
    }
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      // if(this.exceededDays.length > 0) {
      //   this.form.controls.guestType.setErrors({incorrect : true});
      //   this.form.updateValueAndValidity();
      // } else {
      //   this.form.controls.guestType.setErrors({incorrect : false});
      //   this.form.updateValueAndValidity();
      // }
      if(this.editGuestData)
        this.form.markAsDirty();
      this.emitGuestForm.emit({form:this.form,isValid:this.exceededDays.length > 0 ? true : false});
    });
  }
  onGuestChange(e){
    let index,idx;
    if(this.form.controls.guestType.value.length > 0) {
      this.form.controls.guestType.value?.forEach(element => {
        if(this.selectedGuestArr.length == 0 || !this.selectedGuestArr.find(x=>x.id == element.id)) {
          this.selectedGuestArr.push(element)
          this.addGuest(element);
        } else {
           index = this.selectedGuestArr.findIndex(
            (item) => item.id === e.id
          );
          if (index !== -1) {
            this.selectedGuestArr.splice(index, 1);
          }
          idx = this.guestArr.value?.findIndex(
            (item) => item.guestId === e.id
          );
          if (idx !== -1) {
            this.guestArr.value?.splice(idx, 1);
            (this.form.get('guestArr') as FormArray).removeAt(idx);
          }
        }
      });
      // this.selectedGuestArr?.forEach(x=>{
      //   this.addGuest();
      // });
    } else {
      this.selectedGuestArr = [];
      this.exceededDays = [];
      if (this.guestArr.length > 0) {
        this.guestArr.clear();
      }
    }
    if(this.headerOptions.length > 0) {
      this.headerOptions.forEach(x=>{
        if(x.key != "guestType")
        this.validateAndAdjust(x.key)
      })
    }
    this.disableDays();
    this.form.get('guestArr').setValue([]);
    this.form.get('guestArr').setValue(this.guestArr);
    this.emitGuestForm.emit({form:this.form,isValid:this.exceededDays.length > 0 ? true : false});

  }
  addGuest(e) {
    let idx
    this.guestArr = this.form.get('guestArr') as UntypedFormArray
    this.guestArr.push(this.createFormArray(e));
  }
  createFormArray(e){
    return this.fb.group({
      guestId: e.id ?? 0,
      dayPackageGroupId:0,
      name: '',
      Sun : '',
      Mon : '',
      Tue : '',
      Wed : '',
      Thu : '',
      Fri : '',
      Sat : ''
    })
  }
  async initialize(){
    this.form = this.fb.group({
      guestType : '',
      guestArr: this.fb.array([])
    });
    this.guestTypeOptions = await this.business.GetAllGuestTypes(false);
    this.guestTypeInput = {
      className: '',
      form: this.form,
      formControlName: 'guestType',
      isFirstEmpty: false,
      placeHolderId: 'lbl_guestType',
      placeHolder: this.captions.lbl_guestType,
      selectOptions: of(this.guestTypeOptions),
      showRequired: false,
      isAll : true,
      isMultiSelect : true,
      automationId : "Dd_GuestType_GuestType"
    };
    this.headerOptions = [
      {
        displayName : this.captions.lbl_guestType,
        key:'guestType'
      },
      {
        displayName : this.captions.setting.lbl_sun,
        key : 'Sun'
      },
      {
        displayName : this.captions.setting.lbl_mon,
        key : 'Mon'
      },
      {
        displayName : this.captions.setting.lbl_tue,
        key : 'Tue'
      },
      {
        displayName : this.captions.setting.lbl_wed,
        key : 'Wed'
      },
      {
        displayName : this.captions.setting.lbl_thurs,
        key : 'Thu'
      },
      {
        displayName : this.captions.setting.lbl_fri,
        key : 'Fri'
      },
      {
        displayName : this.captions.setting.lbl_sat,
        key : 'Sat'
      }
   ]
  }
  updateValidationFlags(): void {
  //   this.headerOptions.forEach((day, index) => {
  //     const total =this.form.get('guestArr').controls.reduce(
  //       (sum, row) => sum + +row.get(day.key)?.value || 0,
  //       0
  //     );
  //     this.validFlags[index] = total <= this.available[index];
  //   });
  }

  onInputChange(day: string): void {
    this.validateAndAdjust(day);
  }

  validateAndAdjust(day: string): void {
    
    if(this.headerOptions[0].key == 'guestType') {
      this.updatedheaderOptions = cloneDeep(this.headerOptions);
      this.updatedheaderOptions.splice(0,1);
    }
    const dayIndex = this.updatedheaderOptions.findIndex((val) => val.key === day);
    
    if (dayIndex === -1) return;

    const total = this.calculateTotal(day);
    const availableSlots = this.availableCount[dayIndex];

    if (total > availableSlots) {
      this.exceededDays.push(day);
    } else {
      this.exceededDays = this.exceededDays.filter(d => d !== day); 
    }
   this.form.markAsTouched();
   this.form.updateValueAndValidity();
    this.emitGuestForm.emit({form:this.form, isValid:this.exceededDays.length > 0 ? true : false});
  }

  calculateTotal(day: string): number {
    let total = 0;
    this.form.get('guestArr')['controls'].forEach((row) => {
      const value = row.get(day)?.value || 0;
      total += +value; // Convert to number and add
    });
    return total;
  }

  disableDays() {
    const dayControls = Object.keys(this.entryPeriodForm.controls).filter(key => this.days.includes(key));
    this.form.get('guestArr')['controls'].forEach((guestGroup) => {
      // Loop through each day in the main form
      dayControls.forEach(day => {
        if (guestGroup.controls[day]) {
          // Check if the control in the main form is disabled
          if (this.entryPeriodForm.controls[day]?.disabled) {
            // Disable the corresponding control in the guest form
            guestGroup.controls[day].disable();
            guestGroup.controls[day].setValue('');
          } 
          else {
            // Enable it if the main form control is enabled
            guestGroup.controls[day].enable();
          }
        }
      });
    });
  }
}
