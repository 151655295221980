import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { Guid } from 'guid-typescript';
import { DefaultGUID } from '../globalsContant';
import { ChargeType, DataPayload, DepositPolicy, DepositPolicyDetails, MainGroup, MappingDataPayload, MappingSubjectDataType, SubjectDataType, TeeTimePriceType } from './deposit-policy.model';
import { Localization } from 'src/app/common/localization/localization';

@Injectable({providedIn:'root'})
export class DepositPolicySharedService{
    private dataSubject = new BehaviorSubject<DataPayload | null>(null);
    private mappingDataSubject = new BehaviorSubject<MappingDataPayload | null>(null);
    public policyGroupId: string = Guid.EMPTY;

    data$ = this.dataSubject.asObservable();
    mappingData$ = this.mappingDataSubject.asObservable();

    constructor( private localization: Localization){}

    sendData(type: SubjectDataType, data: any, errorMessage: string = '') {
        this.dataSubject.next({type, data, errorMessage});  
    }

    clearSendData() {
        this.dataSubject.next(null);
    }

    sendMappingData(type: MappingSubjectDataType, data: any, errorMessage: string = '') {
        this.mappingDataSubject.next({type, data, errorMessage});
    }

    clearSendMappingData() {
        this.mappingDataSubject.next(null);
    }

    mapPolicyToObject(policyFormDetails: any, isUpdate: boolean = false): DepositPolicy {
        var obj: DepositPolicy = {
            id: isUpdate ? policyFormDetails.id : 0,
            code: policyFormDetails.policyCode,
            name: policyFormDetails.policyName,
            description: policyFormDetails.policyDescription,
            isActive: policyFormDetails.isActive,
            depositPolicyDetails: this.mapPolicyDetails(policyFormDetails, isUpdate)
        }
        return obj;
    }

    mapPolicyDetails(policyFormDetails: any, isUpdate: boolean = false): DepositPolicyDetails[] {
        var detailsObj: DepositPolicyDetails[] = [];      

        policyFormDetails.charges.forEach(charge => {
            detailsObj.push({
                                id: isUpdate ? charge.id : 0,
                                poicyId: isUpdate ? policyFormDetails.id : 0, 
                                includeCharges: Array.isArray(policyFormDetails.includeCharges) && policyFormDetails.includeCharges.length>0 ? policyFormDetails.includeCharges.map(({id})=>id).join(','): null,                                                                                     
                                scheduleType: charge.scheduleType,
                                numberOfDays: charge.applyWithinHoursDays == null ? 0 : charge.applyWithinHoursDays,
                                isFullPrice: charge.priceType == TeeTimePriceType.FullPrice,
                                isPercentage: charge.chargeType == ChargeType.Percentage,
                                charge: this.localization.currencyToSQLFormat(charge.charge)
                            })
        })      
        return detailsObj;
    }

    mapPolicyMappingDetails(mappingDetails: any, guestCategoryOptions: any, mainGroupList: any,isUpdate: boolean = false)
    {

        return {
            id: 0,
            startDate: this.localization.convertDateObjToAPIDateOnly(mappingDetails.startDate),
            endDate: this.localization.convertDateObjToAPIDateOnly(mappingDetails.endDate),
            policyId: mappingDetails.policyId,
            guestCategory: guestCategoryOptions.filter(a => a.selected).map(a => a.id),
            guestType: Array.isArray(mappingDetails.guestType) && mappingDetails.guestType.length>0 ? mappingDetails.guestType.map(({id})=>id).join(','): null,
            mappingGroupId: isUpdate ? this.policyGroupId : DefaultGUID,
            policyMapping: mainGroupList
        };
    }

    clearMainGroupList(mainGroup: MainGroup[]){
        mainGroup.forEach(m => {
            if(m.selected){
                m.subGroup.forEach(s => s.selected = false);
                m.selected = false;
                m.isSubGroupSelected = false;
            }
        });
    }

    clearGuestCategoryOptions(guestCategoryOptions: any){
        guestCategoryOptions?.forEach(a => a.selected = false);
    }

}