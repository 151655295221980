import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { DiaryBusiness } from './diary.business'
import { UntypedFormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {  Subject, ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { API, CalenderCount, UI } from '../Models/diary.model';
import { ButtonValue, FilterGroup, AsideFilterConfig,MultiImageUpload,TableSearchHeader,DropdownOptions } from '../Models/ag-models';
import { createModal } from '../components/create-modal/create-model';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import { AlertAction, AlertType, ButtonType } from '../enums/shared-enums';


import { SEARCH_DEBOUNCE_TIME,SEARCH_TEXT_LENGTH } from '../shared/shared/setupConstants';
import { ViewImageComponent } from '../shared/shared/view-image/view-image.component';
import { AgPreviewReportComponent } from '../components/preview-report/preview-report.component';
import { ReportAPIOptions, ReportParams } from '../shared/retail.modals';
import { Localization } from '../localization/localization';
import { CopyDiaryComponent } from './copy-diary/copy-diary.component';
import { X } from '@angular/cdk/keycodes';
import { DayType, DiaryOccurrenceType, monthlyType, recurringTypeConst, YearlyType } from '../components/recurring-event/recurring-event.model';
@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
  encapsulation:ViewEncapsulation.None,
  providers: [DiaryBusiness]

})
export class DiaryComponent implements OnInit {

  createModalData: createModal;
  captions: any;
  searchHeaderOption: TableSearchHeader;
  title: string;
  form: UntypedFormGroup;
  floatLabel: string;
  actionButton: ButtonValue;
    cancelButton: ButtonValue;
    showError: boolean = false;
  applyButton: ButtonValue;
  resetButton: ButtonValue;
  searchButton: ButtonValue;
  createButton: ButtonValue;
  alertsArr :any =[];
  // alertsOriginalArr :any =[];
  diaryEventList : UI.DiarySearchResult[]=[];
  showCreate : boolean = false;
  showRightSection : boolean = false;
  viewDiaryData: UI.DiarySearchResult;
  showEditAction: boolean = false;
  noResult: boolean = false;
  headerFilter : any =[];
  index: number = 0;
  isEdit : boolean = false;
  userName : string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  allTagData : UI.DiaryTag[] =[];
  allSearchTagData : UI.DiaryTag[] =[];
  searchTag : DropdownOptions[];
  selectedGuestTagKey: string[] = ['name'];
  autoCompleteKeys: string[] =  ['name'];
  searchGuestMaxLength = 50;
  tagSearchKey: string[] =  ['name'];
  searchselectedTagData: UI.DiaryTag[] = [];
  selectedTagData: UI.DiaryTag[] = [];
  tagArr : any[] =[];
  isSlideOpen: boolean = false;
  filtergroups: FilterGroup[] = [];
  filterConfig: AsideFilterConfig;
  @Output() onClose = new EventEmitter();
  imageList: MultiImageUpload[];
  tagSearchTextChanged = new Subject<string>();
  tagsearchSearchTextChanged = new Subject<string>();
  maxYear: number;
  minYear: number;
  maxMonth: number;
  minMonth: number;
  diaryAuditInfos : UI.DiaryAuditInfo[]= [];
  subjectMaxLength : number = 100;
  eventMaxLength : number = 2000;
  minFromDate : Date;
  maxFromDate : Date;
  minStartDate:Date;
  maxStartDate: Date
  minEndDate:Date;
  maxEndDate: Date
  selecteddiaryDate : any;
  dateCount : CalenderCount[]=[];
  pastEventYears: string[] = [];
  @ViewChild('chipSearch') chipSearch: ElementRef;
  recurringEventInput: { group: UntypedFormGroup; formControlName: string; label: any; };
  repeatList: { id: number; value: string; viewValue: string;selected:boolean }[];
  recurringData: { description: string; id: number; name: any; disabled: boolean; }[];
  recurringEventMappedData: API.DiaryRecurringInfo;
  isProcessing: boolean = false;
  recurringpathValue: { recurringType: number; dayType: number; monthlyType: number; every: string; date: any; month: any; yearlyType: number; onEvery: any; onday: any; onMonth: any; specificDays: any[]; };
  constructor(private cd: ChangeDetectorRef,private localization: Localization,private utilities: CommonUtilities,
     private business: DiaryBusiness,private fb: FormBuilder,private propertyInfo: CommonPropertyInformation,
     private dialog: MatDialog) { 
    this.captions = this.localization.captions;
    this.title = this.captions.lbl_Alerts;
    this.floatLabel=this.localization.setFloatLabel;
    this.userName = this.localization.GetUserInfo('userName');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id : [0],
      subject: [''],
      event: ['', Validators.required],
      tag: [''],
      startDate : [this.propertyInfo.CurrentDate],
      endDate : [this.propertyInfo.CurrentDate],
      searchTag: [''],
      reccuringEvent:"",
      recurring:'',
      diaryDate: [this.propertyInfo.CurrentDate]
    });
    this.recurringpathValue={
      "recurringType": 1,
      "dayType": 1,
      "monthlyType": 1,
      "every": "",
      "date": null,
      "month": null,
      "yearlyType": 0,
      "onEvery": null,
      "onday": null,
      "onMonth": null,
      "specificDays": [
          null,
          null,
          null,
          null,
          null,
          null,
          null
      ]
  }
    this.repeatList=[
      {
        id:1,
        value:"Daily",
        viewValue:"Daily",
        selected:false
      },
      {
        id:2,
        value:"Weekly",
        viewValue:"Weekly",
        selected:false
      },
      {
        id:3,
        value:"Monthly",
        viewValue:"Monthly",
        selected:false
      },
      {
        id:4,
        value:"Yearly",
        viewValue:"Yearly",
        selected:false
      }
    ]
    const propertyDate = this.localization.getDate(this.propertyInfo.CurrentDate)
    
    const minDate = new Date(this.localization.getDate(this.propertyInfo.CurrentDate).setFullYear(propertyDate.getFullYear() - 20));
    const maxDate = new Date(this.localization.getDate(this.propertyInfo.CurrentDate).setFullYear(propertyDate.getFullYear() + 20));
    this.minFromDate = minDate;
    this.maxFromDate = maxDate
    this.minStartDate = minDate;
    this.minEndDate = minDate;
    this.maxEndDate = maxDate;
    this.maxStartDate = maxDate
    this.initialize();
    this.initializeButton();
    this.initializesearchHeaderOption();
    this.getYearsForCopyEvent()
  }

  private async LoadCalanderCount(Cdate : Date){
    let date = this.localization.getDate(Cdate); 
    let monthIndex = date.getMonth(); 
    const days = this.getDays(date.getFullYear(),monthIndex);
    this.dateCount = await this.business.GetDiaryCount(days[0] ,days[days.length-1]);
    this.dateCount = [...this.dateCount];
  }
  getDays(year, month){
    let daysArr =[];
    let days = new Date(year, month+1, 0).getDate();
    for(let i=1;i<=days;i++){
      let dateObj = new Date(year,month,i);
      daysArr.push(this.localization.convertDateObjToAPIdate(dateObj));
    }
    return daysArr;
  };
  initialize(){
    this.createModalData = {
      title: this.captions.lbl_diary
    };
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.actionButton.disabledproperty = !(this.form.valid && this.form.dirty);
      this.actionButton = { ...this.actionButton }
    });
    this.filterConfig = {
      filterText: this.captions.lbl_filter,
      resetText : this.captions.lbl_reset,
      displayCount: 4,
      isMultiSelect: true,
      viewMoreConfig: {
        apply: this.captions.btn_apply,
        cancel: this.captions.btn_cancel,
        searchByPlaceHolder: this.captions.lbl_searchBy
      },
    };

    this.tagSearchTextChanged.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      mergeMap(source => this.searchtagChange(source))
    ).pipe(takeUntil(this.destroyed$)).subscribe();
    this.tagsearchSearchTextChanged.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      mergeMap(source => this.searchsearchtagChange(source))
    ).pipe(takeUntil(this.destroyed$)).subscribe();
    
  }

  repeatListselect(obj)
  {
    this.repeatList.forEach(x=>{
      x.selected=false;
    })

    obj['selected']=true;
  }

  onDateChange()
  {

  }

  async getYearsForCopyEvent() {
    this.pastEventYears = await this.business.GetPastDiaryEventYears();
  }

  initializeButton(){
    this.recurringData= [
      { description: 'daily', id: recurringTypeConst.daily, name: this.captions.common.Daily, disabled: false },
      { description: 'weekly', id: recurringTypeConst.weekly, name: this.captions.common.Weekly, disabled: false },
      { description: 'monthly', id: recurringTypeConst.monthly, name: this.captions.common.Monthly, disabled: false },
      { description: 'yearly', id: recurringTypeConst.yearly, name: this.captions.common.Yearly, disabled: false }
  ]
    this.actionButton = {
      type: 'primary',
      label: this.isEdit ? this.captions.btn_SAVE : this.captions.btn_create,
      disabledproperty : true
    };
    this.recurringEventInput = {
      group: this.form,
      formControlName: 'reccuringEvent',
      label: this.captions.lbl_reccuringEvent
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.applyButton = {
      type: 'primary',
      label: this.captions.btn_apply
    },
    this.resetButton = {
      type: 'tertiary',
      label: this.captions.btn_reset,
      disabledproperty: false
    };
    this.searchButton = {
      type: 'primary',
      label: this.captions.btn_search
    }
    this.createButton = {
      type: 'primary',
      label: this.captions.btn_create
    }
    }

    onBlur(event: any)
    {

    }

    onGuestChipRemoveEvent(event: any)
    {
      //remove from list
     }
     onSearchGuestChipRemoveEvent(event: any)
     {
       //remove from list
     }

  initializesearchHeaderOption() {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.btn_create,
      searchPlaceHolder: this.captions.lbl_searchByEvent,
      showInactive: false,
      hideActionBtn: false,
      advancedSearch: this.captions.lnk_advancedSearch
    };

  }

  async searchtagChange(pattern) {
    const tagSearchText = (pattern ? pattern : "").trim();
    if (tagSearchText.length > SEARCH_TEXT_LENGTH) {
      this.allTagData= await this.business.getTagBySearchParams(tagSearchText);      
    }
    else {
      this.allTagData = [];
    }
    this.cd.detectChanges();
  }
  async searchsearchtagChange(pattern) {
    const tagSearchText = (pattern ? pattern : "").trim();
    if (tagSearchText.length > SEARCH_TEXT_LENGTH) {
      this.allSearchTagData= await this.business.getTagBySearchParams(tagSearchText);      
    }
    else {
      this.allSearchTagData = [];
    }
    this.cd.detectChanges();
  }
  onSubjectChange(e){

  }
 async onAction(e){
    const diaryvalues = this.form.getRawValue();
    if (diaryvalues.reccuringEvent) {
      this.recurringEventMappedData = await this.recurringEventMapper(diaryvalues.recurring);
    }
    if(this.isEdit) {
      const tags = this.selectedTagData.map(x=>x.name);
      const date = this.localization.convertDateObjToAPIdate(diaryvalues.diaryDate);
      const propDateTime = this.localization.convertDateTimeToAPIDateTime(this.propertyInfo.CurrentDate)
      this.utilities.ToggleLoader(true);
      const issucess = await this.business.UpdateDiaryEvent(diaryvalues.id,diaryvalues.subject,diaryvalues.event,date,propDateTime,tags,this.imageList,this.viewDiaryData.diaryInfo.diaryDocuments);
      this.utilities.ToggleLoader(false);
      if(issucess){
        this.utilities.showAlert(this.captions.msg_savedSuccesfully, AlertType.Success, ButtonType.Ok);
        await this.refreshList();
      }


    } else {
      const tags = this.selectedTagData.map(x=>x.name);
      const date = this.localization.convertDateObjToAPIdate(diaryvalues.diaryDate);
      const propDateTime = this.localization.convertDateTimeToAPIDateTime(this.propertyInfo.CurrentDate)
      this.utilities.ToggleLoader(true);
      const issucess = await this.business.CreateDiaryEvent(diaryvalues.id,diaryvalues.subject,diaryvalues.event,date,propDateTime,tags,this.imageList, this.recurringEventMappedData);
      this.utilities.ToggleLoader(false);
      if(issucess){
        this.utilities.showAlert(this.captions.msg_savedSuccesfully, AlertType.Success, ButtonType.Ok);
        await this.refreshList();
      }else{
        this.utilities.showAlert(this.captions.lbl_noRecurring, AlertType.Info, ButtonType.Ok);
      }
    }
    this.imageList = [];
    this.showCreate = false;
    this.noResult = true;
    this.tagArr = [];
    this.resetCreateForm()
  }

  async recurringEventMapper(recurring) : Promise<API.DiaryRecurringInfo>{

    let occurrenceType = 0;
    let interval = 0;
    let weeklyDays = 0;
    let occurrence = 0;
    let month = 0;

    switch (recurring.recurringType) {
      case 1:
        if (recurring.dayType == DayType.Every) {
          occurrenceType = DiaryOccurrenceType.Every;
          interval = recurring.every;
        }
        else {
          occurrenceType = DiaryOccurrenceType.EveryWeekDay;
        }
        break;
      
      case 2:
        interval = recurring.every;
        weeklyDays = recurring.selectedDays.reduce((acc, current) => acc + current, 0);
        break;

      case 3:
        if (recurring.monthlyType == monthlyType.day) {
          occurrenceType = DiaryOccurrenceType.day;
          interval = recurring.every;
          occurrence = recurring.date;
        }
        else if (recurring.monthlyType == monthlyType.lastDay) {
          occurrenceType = DiaryOccurrenceType.lastDay;
          interval = recurring.every;
        }
        else {
          occurrenceType = DiaryOccurrenceType.onThe;
          occurrence = recurring.onEvery;
          interval =  recurring.every;
          weeklyDays = recurring.onday
        }
        break;

      case 4:
        if (recurring.yearlyType == YearlyType.onDay) {
          occurrenceType = DiaryOccurrenceType.onDay
          interval = recurring.every;
          month = recurring.month + 1;
        }
        else {
          occurrenceType = DiaryOccurrenceType.onThe;
          weeklyDays = recurring.onday;
          occurrence = recurring.onEvery;
          month = recurring.onMonth + 1;
        }
        break;

      default:
        break;
    }

    let data: API.DiaryRecurringInfo = {
      id: 0,
      recurringType: recurring.recurringType,
      ocuurenceType: occurrenceType,
      interval: interval,
      weeklyDays: weeklyDays,
      occurrence: occurrence,
      month: month,
      startDate: this.localization.convertDateToAPIdate(recurring.startDate),
      endDate: this.localization.convertDateToAPIdate(recurring.endDate)
    }
    return data;
  }

  async refreshList(){
    this.onSearch(null);
  }
  onCancel(e){
    this.imageList = [];
    this.cd.detectChanges();
    this.resetCreateForm();
   
  }

  async selectedTagEmit(event) {
    this.selectedTagData = event.map(x => { return { id: x.id, name: x.name } as UI.DiaryTag });
    this.actionButton.disabledproperty = !this.form.valid;
    this.actionButton = { ...this.actionButton }
    // this.allTagData = event.map(x=>{return {id:x.id,name:x.name} as UI.DiaryTag});

    // this.form.get('tag').setValue(this.selectedGuestInfo);

  }
  async searchSelectedTagEmit(event) {
  this.searchselectedTagData = event.map(x=>{return {id:x.id,name:x.name} as UI.DiaryTag});
    }
  
  getTypeClass(key){
    return key;
  }
  createEvent(e){
    this.imageList = [];
    this.showRightSection = true;
    this.showCreate = true;
    this.noResult = false;
    this.showEditAction = false;
    this.isEdit = false;
    // this.form.reset();
    this.resetCreateForm();
    this.selectedTagData = [];
    this.cd.detectChanges();
  }
  viewData(data,i){
    this.diaryEventList.forEach(x=>{
      x.selected=false;
    })
    data.selected=true;
    this.showRightSection = true;
    this.showEditAction = true;
    this.noResult = false;
    this.viewDiaryData = data;
    this.diaryAuditInfos = this.viewDiaryData.diaryAuditInfos;
    this.showCreate = false;
    this.index = i;
    this.cd.detectChanges();
  }
  onDelete(event,value){
    event.stopPropagation();
  }
  async openEdit(){
    this.showEditAction = false;
    this.showCreate = true;
    this.isEdit = true;
    this.form.controls['id'].patchValue(this.viewDiaryData.diaryInfo.id);
    this.form.controls['subject'].patchValue(this.viewDiaryData.diaryInfo.subject);
    this.form.controls['event'].setValue(this.viewDiaryData.diaryInfo.notes);
    this.form.controls['diaryDate'].setValue(this.viewDiaryData.diaryInfo.date);
    this.selectedTagData = this.viewDiaryData.diaryInfo.diaryTagMappings.map(x=>{return {id:0,name:x.tagName} as UI.DiaryTag});
    if(this.viewDiaryData.diaryInfo.diaryDocuments.length > 0){
      this.utilities.ToggleLoader(true);
      const docresponse =  await this.business.GetAllImages(this.viewDiaryData.diaryInfo.diaryDocuments);
      this.utilities.ToggleLoader(false);
      this.imageList =  this.business.DocumentUItoAPIMapper(docresponse,this.viewDiaryData.diaryInfo.diaryDocuments);
    }
    this.initializeButton();
    this.cd.detectChanges();
  }
  tagSearchTextEmit(pattern){
    this.tagSearchTextChanged.next(pattern);
  }
  tagsearchSearchTextEmit(pattern){
    this.tagsearchSearchTextChanged.next(pattern);
  }
  showFilter(value){
    this.isSlideOpen = value;
  }
  back(){
    this.onClose.emit(false);
  }

  CategoryFilter(e){
    this.headerFilter = e.filters.filter(x => x.isSelected);
  }
  imgAdd(arg) {
    this.imageList = arg;
    this.actionButton.disabledproperty = !this.form.valid;
    this.actionButton = { ...this.actionButton }
  }
  imgDelete(arg) {
    this.imageList = arg;
    this.actionButton.disabledproperty = !this.form.valid;
    this.actionButton = { ...this.actionButton }
  }
  async viewAllImages() {
    this.utilities.ToggleLoader(true);
  const docresponse =  await this.business.GetAllImages(this.viewDiaryData.diaryInfo.diaryDocuments);
  this.utilities.ToggleLoader(false);
  this.imageList =  this.business.DocumentUItoAPIMapper(docresponse,this.viewDiaryData.diaryInfo.diaryDocuments);
    this.dialog.open(ViewImageComponent, {
      data: {
        imageList: this.imageList
      }
    });
  }

  fileSizeExceeded(arg) {
    this.utilities.showAlert(this.captions.PhotoSizeExceed, AlertType.Info, ButtonType.Ok);
  }
  onSave(e){ 
  }
  startdateChange(eve){
    this.minEndDate = this.localization.getDate(this.form.controls['startDate'].value);
  }
  enddateChange(eve){
    this.maxStartDate = this.localization.getDate(this.form.controls['endDate'].value);
  }
    getDate(eve){
    this.selecteddiaryDate = eve;
    this.form.controls['startDate'].setValue(this.localization.getDate(this.selecteddiaryDate));
    this.form.controls['endDate'].setValue(this.localization.getDate(this.selecteddiaryDate));
    this.maxStartDate = this.localization.getDate(this.form.controls['endDate'].value);
    this.minEndDate = this.localization.getDate(this.form.controls['startDate'].value);
    this.resetCreateForm();
    this.showRightSection = false;
    this.onSearch(null);
  }
  async onSearch(eve){
    this.LoadCalanderCount(this.localization.getDate(this.form.controls['startDate'].value));
    const startDate = this.localization.convertDateObjToAPIdate(this.form.controls['startDate'].value);
    const endDate = this.localization.convertDateObjToAPIdate(this.form.controls['endDate'].value);
    const tags = this.searchselectedTagData.map(x=>x.name);
    this.diaryEventList = await  this.business.SearchDiary(startDate,endDate,tags);
    this.cd.detectChanges();
  }
  print(){
this.previewComponent(this.formReportOption());
  }
  formReportOption() {
    const formFilters = JSON.stringify({
      startDate: this.localization.convertDateObjToAPIdate(this.form.controls['startDate'].value),
      endDate: this.localization.convertDateObjToAPIdate(this.form.controls['endDate'].value),
      tags: this.searchselectedTagData.map(x=>x.name),
    });

    const reportOptions: ReportAPIOptions = {
      code: 'Diary',
      params: this.formReportParams(),                                                                                                                                                                                    
      URIParams: [],
      filters: formFilters,
      pageBreak: true
    };
    return reportOptions;
  }
  formReportParams(): ReportParams[] {
    return [
      { pPropertyName: this.localization.GetPropertyInfo('PropertyName') },
      { pReportName: 'Diary' },
      { pStartDate: this.localization.convertDateObjToAPIdate(this.form.controls['startDate'].value)},
      { pEndDate: this.localization.convertDateObjToAPIdate(this.form.controls['endDate'].value)},
      { pDate: this.localization.ConvertDateToISODateTime(new Date()) }
    ];

  }
  previewComponent(reportOptions) {
    const dialogRef = this.dialog.open(AgPreviewReportComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        reportOptions,
        downloadFileName: this.captions.lbl_diary,
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result) {
        // No Implementation
      }
    });
  }
  resetCreateForm(){
    this.form.controls['id'].setValue(0);
    this.form.controls['subject'].setValue('');
    this.form.controls['event'].setValue('');
    this.form.controls['diaryDate'].setValue(this.selecteddiaryDate);
    this.form.markAsUntouched();
    this.imageList = [];
    this.showRightSection = true;
    this.isEdit = false;
    this.showCreate = true;
    this.noResult = false;
    this.showEditAction = false;
    this.selectedTagData = [];
    this.cd.detectChanges();


    
  }

  CopyClick(): void {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;
    this.copy();
    setTimeout(() => {
      this.isProcessing = false;
    }, 3000);
  }

  async copy() {
    await this.getYearsForCopyEvent()
    const dialogRef = this.dialog.open(CopyDiaryComponent, {
      width: "450px",
      height: '315px',
      data: {
        copyFromYears:  this.pastEventYears
      },
      panelClass: 'small-popup',
      disableClose: true,
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.business.CopyEvents(res.copyFromYear, res.copyToYear).then(x => {
          if (x) {
            this.utilities.showAlert(this.captions.common.lbl_copiedSuccessfully, AlertType.Success, ButtonType.Ok);
          }
          else {
            this.utilities.showAlert(this.captions.common.lbl_copyFailed, AlertType.Error, ButtonType.Ok);
          }
        });
      }
    });
  }
  delete(obj)
  {
    var msg = obj.subject ? this.localization.replacePlaceholders(this.captions.warn_deleteWithName, ['name'], [obj.subject]) 
    : this.captions.warn_delete;
    this.utilities.showAlert(msg, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        this.business.DeleteDiaryEvent(obj.id).then(res => {
          if (res) {
            this.utilities.showAlert(this.captions.common.lbl_deletedSuccessfully, AlertType.Success, ButtonType.Ok);
            this.showRightSection = false;
            this.onSearch(null);
          }
          else {
            this.utilities.showAlert(this.captions.common.lbl_deleteFailed, AlertType.Error, ButtonType.Ok);
          }
        });
      }
    });
  }
  toggleChange(e)
  {
    if(e.checked)
    {
      this.form.addControl('recurring',  new FormControl(''));
    }else{
      this.form.removeControl('recurring');
    }
   
  }
  recurringSelected(event) {
    if(this.form.get('reccuringEvent').value)
    {
      this.form.setControl('recurring', event);
    }else{
      this.form.removeControl('recurring');
    }
  }
}


@Pipe({
  name: 'DisplayDateTime',
})
export class DisplayDateTime implements PipeTransform {
  constructor(private localization: Localization) { }
  transform(value: any, formdata: any) {
    if (typeof value == 'string') { value = this.localization.getDate(value); }
    let str = this.localization.localizeDisplayDate(value) + ' ';
    let time ='';
    if (this.localization.getTimeFormat() == 12) 
      time = this.localization.formatAMPM(value, true);
  else 
    time = this.localization.format24HrTime(value);
    return str + time;
  }
  }

