export enum menuTypes {
  initial = 'Initial',
  horizontal = 'Horizontal',
  combo = 'Combo',
  vertical = 'Vertical' 
}

export enum retailMenuTypes {
  primary,
  secondary,
  tertiary,
  lowerLevel
}

//These are the temporary constants until multicurrency is fully implemented across all screens.
export const multiCurrencyRoutePath  = [
  '/reservations/reservationslist/view',
  '/reservations/reservationslist/create',
  '/reservations/reservationslist/edit',
  '/reservations/reservationslist/folio',
  '/reservations/generalavailability',
  '/reservations/rateAvailability',
  '/frontdesk/checkout/beginCheckOut']

export enum jasperConfig {
  PROPJSCONFIG_JASPERREPORTSAPPLIANCEURL = "JasperReportsApplianceUrl"
}
