import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { SpaDepositPolicyService } from '../spa-add-deposit/spa-add-deposit.service';
import { DepositPolicySharedService } from 'src/app/common/shared/shared/deposit-policy/deposit-policy-shared';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { DepositPolicy, DepositPolicyBaseDetails, SubjectDataType } from 'src/app/common/shared/shared/deposit-policy/deposit-policy.model';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { SPAScheduleBreakPoint } from 'src/app/shared/globalsContant';

@Component({
  selector: 'app-deposity-policy-wrapper',
  templateUrl: './deposity-policy-wrapper.component.html',
  styleUrls: ['./deposity-policy-wrapper.component.scss'],
  providers: [SpaDepositPolicyService]
})
export class DeposityPolicyWrapperComponent implements OnInit {

  captions: any;
  tableOptions;
  tableContent : DepositPolicyBaseDetails[];
  
  chargeOptions: any[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isViewOnly: boolean = false;

  constructor(private localization: Localization, private spaDepositPolicyService : SpaDepositPolicyService, 
    private depositPolicySharedService : DepositPolicySharedService, private utils : SpaUtilities, private _userAccessBusiness: UserAccessBusiness) {
    this.captions = this.localization.captions;
   }

  async ngOnInit(): Promise<void> {
    this.getTableOptions();
    await this._userAccessBusiness.getUserAccess(SPAScheduleBreakPoint.DepositPolicy).then(
          x => this.isViewOnly = x.isViewOnly
        );

    this.depositPolicySharedService.data$.pipe(takeUntil(this.destroyed$)).subscribe(async payload => {
      if(payload!=null){
        switch(payload.type) {
          case SubjectDataType.GetPolicy:
            await this.getPolicyDetailsByPolicyId(payload.data);
            break;
          case SubjectDataType.CreatePolicy:
            await this.createDepositPolicy(payload.data,false);
            break;
          case SubjectDataType.UpdatePolicy:
            await this.createDepositPolicy(payload.data, true);
            break;
          case SubjectDataType.DeletePolicy:
            await this.deleteDepositPolicy(payload.data);
            break;
          case SubjectDataType.UpdateActive:
            await this.spaDepositPolicyService.updateDepositPolicyActive(payload.data);
            break;
        }
      }
    })
    
    
    this.chargeOptions = this.spaDepositPolicyService.getchargeOptions();
    this.tableContent = await this.getTableContent();
    this.assignTableBodyData(this.tableContent);
  }

getTableOptions() {
  let arrDataHeader = [
    { 'title': this.captions.tbl_hdr_policyCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_policyName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_description, 'jsonkey': 'description', 'searchable': false, 'sortable': true },
    { 'title': this.captions.tog_lbl_active, 'jsonkey': 'isActive', 'searchable': false, 'sortable': false, "type": "toggle" },

  ]
  this.tableOptions = [
    {
      TableHdrData: arrDataHeader,
      TablebodyData: [],
      pagination: false,
      sortable: true,
      editable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.lbl_searchByPolicyCodeName,
      EnableActions: true,
      SelectRows: false,
      Searchable: true,
      EditMoreOption: false,
      Sortable: 'code',
      TableId: '',
      disableDelete: false,
      SelectRow: true,
      SelectOnlyRow: true,
      dropdownOptions: '',
      waitlistIcon: false,
      TableDraggable: false,
      sticky: false,
      DoneCancel: false,
      IsViewOnly: true,
      disableEditButton: false,
      isCopyEnabled : true,
      ServiceId : 'viptype'
    }
  ];
}
async getTableContent(){
  return await this.spaDepositPolicyService.getAllDepositPolicies();
}

async getPolicyDetailsByPolicyId(id: number){
  var policyDetails = await this.spaDepositPolicyService.getDepositPolicyDetailsById(id);
  this.sendPolicyDetailsBack(policyDetails);
}

sendPolicyDetailsBack(policyDetails: any){
  this.depositPolicySharedService.sendData(SubjectDataType.SendPolicy, policyDetails);
}

async createDepositPolicy(policyData: DepositPolicy, isUpdate: boolean) {
  let errorMessage='';
  this.utils.ToggleLoader(true);
  var response = await this.spaDepositPolicyService.createOrUpdateDepositPolicy(policyData, isUpdate);
    this.utils.ToggleLoader(false);
    if (response.errorCode == 57)
      errorMessage = this.localization.replacePlaceholders(this.localization.getError(response.errorCode), ['policyName'], [response.errorDescription]);
    this.depositPolicySharedService.sendData(SubjectDataType.APIResponse, response.errorCode, errorMessage);
    if(response.successStatus){
      this.assignTableBodyData(response.result);
    }
}

async deleteDepositPolicy(id: number){
  this.utils.ToggleLoader(true);
  var response = await this.spaDepositPolicyService.deleteDepositPolicy(id);
  this.utils.ToggleLoader(false);
  this.depositPolicySharedService.sendData(SubjectDataType.DeleteAPIResponse, response.errorCode);
  if(response.successStatus){
    this.assignTableBodyData(response.result);
  }
}

assignTableBodyData(result: any){
  var tableData = [...this.tableOptions]
  tableData[0].TablebodyData = result;
  tableData[0].IsViewOnly = this.isViewOnly;
  this.tableOptions = tableData;
}

ngOnDestroy(){
  this.depositPolicySharedService.clearSendData();
  this.destroyed$.next();
  this.destroyed$.complete();
}

}

