import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { MatSelectChange } from '@angular/material/select';
import { GenderPreference, Host } from '../../../globalsContant';
import { BaseResponse, SystemConfig } from '../../../business/shared.modals';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'other-details',
    templateUrl: 'otherdetails.component.html',
    styleUrls: ['otherdetails.component.scss'],
})
export class OtherdetailsComponent implements OnInit {
    selected = 'none';
    FormGrp: UntypedFormGroup;
    cancelBool: boolean = false;
    customFieldHidden: any = [true, true, true, true, true];
    customFieldDesc: any = ['', '', '', '', ''];
    customFieldInfo: any = [];
    customFieldValues: any = [[], [], []];
    customFieldRequired: any = [false, false, false, false, false];
    isCopyFromClient: any = [false, false, false, false, false];
    captions: any = {};
    intakeForm: boolean = false;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    genderPreferenceRequired: boolean;
    floatLabel:string;
    isIntakeFormEnabled : boolean = false;
    status:  boolean=false;
    requireNoShow:  boolean=false;
    constructor(public _as: AppointmentpopupService, private http: HttpServiceCall,
        private Form: UntypedFormBuilder, public localization: SpaLocalization, private utils: SpaUtilities) {
        this._as.cancelBool = false;
        this.status=this._as.isEditAppointment?(_as.resultExistingAppointment?.appointmentDetail.status=="NOSHOW"?true:false):false;
        this.captions = localization.captions.bookAppointment;
        this.floatLabel = this.localization.setFloatLabel;

    }

    async GetSystemSetting() {
        this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "INTAKE_FORM");
        this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "REQUIRE_NO_SHOW_REASON");
    }

    async ngOnInit() {
        await this.GetSystemSetting();
        this.FormGrp = this.Form.group({
            vip: false,
            intakeForm: this.intakeForm,
            gender: 0,
            guestTypeId:0,
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            comments: '',
            policy: '',
            checkout: '',
            noShowComments: '',
            valueChange: false,
            linkcode: 0
        });
        this._as.otherDetailsFormGroup = this.FormGrp;
        if (this._as.isEditAppointment) {
            this._as.otherDetailsFormGroup.controls.vip.setValue(this._as.otherdetails.vip);
            this._as.otherDetailsFormGroup.controls.intakeForm.setValue(this._as.otherdetails.intakeForm);
        }

    
        this.fetchCustomFieldInfo();
        if (!(this._as.appointmentBreakPoints.AllowCustomField4)) {
            this.FormGrp.get('customField4').disable();
        }
        if (!(this._as.appointmentBreakPoints.AllowCustomField5)) {
            this.FormGrp.get('customField5').disable();
        }
        this._as.setGenderPreferenceRequired();
        this._as.requireGenderPreference$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            if ((this._as.otherdetails.gender === 0 || this._as.otherdetails.gender === GenderPreference.none) && res) {
                this.genderPreferenceRequired = true;
                this.FormGrp.controls.gender.setErrors({'required': true});
            } else {
                this.genderPreferenceRequired = false;
                this.FormGrp.controls.gender.setErrors(null);
            }
        });
    }

    fetchCustomFieldInfo() {
        this.http.CallApiWithCallback<any>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetCustomFieldsWithValues",
            method: HttpMethod.Get,
            showError: false,
            extraParams: []
        });
    }
    compareObjects(o1: any, o2: any): boolean {
        return o1 == o2;
      }

      createGuestTypeDropDown() {
        return this.Form.group({
            guestTypeId:0,
        });
      }


      
    
    updateGuestType(e: MatSelectChange) {
        if (this._as.allGuestTypes.length > 0) {
            let selectedGuestTypeCode=this._as.allGuestTypes.find(x=>x.id == e.value);
           // this._as.otherdetails.guestType = selectedGuestTypeCode ? selectedGuestTypeCode.id : 0;

         }
    }



    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if (callDesc == "GetCustomFieldsWithValues") {
            this.customFieldInfo = result.result ? result.result : [];
            if (this.customFieldInfo && this.customFieldInfo.length > 0) {
                for (var i = 0; i < this.customFieldInfo.length; i++) {
                    if (this.customFieldInfo[i] && (this.customFieldInfo[i].displayOn == 2 || this.customFieldInfo[i].displayOn == 3)) {
                        this.customFieldHidden[i] = false;
                        this.customFieldDesc[i] = this.customFieldInfo[i].fieldName ? this.customFieldInfo[i].fieldName : '';
                        this.customFieldRequired[i] = this.customFieldInfo[i].requiredOnAppointment ? this.customFieldInfo[i].requiredOnAppointment : false;
                        this.isCopyFromClient[i] = this.customFieldInfo[i].moveRecordFromClient ? this.customFieldInfo[i].moveRecordFromClient : false;
                        if (i < 3) {
                            this.customFieldValues[i] = this.customFieldInfo[i].customFieldValues && this.customFieldInfo[i].customFieldValues.length > 0 ? this.customFieldInfo[i].customFieldValues : [];
                        }
                    }
                }
                this._as.IsCopyFromClient = this.isCopyFromClient;
            }
        } else if (callDesc == "GetConfiguration") {
                 let res: SystemConfig = <any>result.result;
                 if(res.switch=="INTAKE_FORM")
                 {
                this.FormGrp.controls["intakeForm"].setValue(res.value == "true" ? true : false);
                this._as.otherdetails.intakeForm = res.value == "true" ? true : false;
                this.isIntakeFormEnabled = res.value == "true" ? true : false;
                 }
                 if(res.switch=="REQUIRE_NO_SHOW_REASON")
                 {
                    this.requireNoShow=res.value == "true" ? true : false;
                 }

        }
    }
    validateForm(data?: any, event?: any) {
        this._as.otherdetails.valueChange = true;
        if (data == 'vip') {
            this._as.otherdetails.vip = event[0];
        } else if (data == "intakeForm") {
            this._as.otherdetails.intakeForm = event[0];
        }
    }

}