import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup,Validators } from '@angular/forms';
import { AgDropdownConfig, ButtonValue } from "src/app/common/Models/ag-models";
import { Localization } from "src/app/common/localization/localization";
import { takeUntil,debounceTime } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, DiscountMapping, DiscountMappingsSections, DiscountMappingType } from '../shared/shared.modal';
import { ButtonTypes } from '../payment-manager/shared/shared-model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { OtherComponentsDiscountMappingBusiness } from './other-components-discount-mapping.business';
import { TierLevelDataService } from '../retail-code-setup/retail-tier-level/retail-tier-level.data.service';
import { uniqueFormGroupValidator } from '../shared/Validators/GuidValidator';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-other-components-discount-mapping',
  templateUrl: './other-components-discount-mapping.component.html',
  styleUrls: ['./other-components-discount-mapping.component.scss'],
  providers: [OtherComponentsDiscountMappingBusiness,TierLevelDataService],
  encapsulation: ViewEncapsulation.None
})
export class OtherComponentsDiscountMappingComponent implements OnInit {

  constructor(  private localization: Localization,private fb: UntypedFormBuilder,private utilities: CommonUtilities,
    private business: OtherComponentsDiscountMappingBusiness) {
    this.captions = this.localization.captions.retailsetup.DiscountMapping;
    this.floatLabel = this.localization.setFloatLabel;
  }
  captions: any;
  discountMappingForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  mappingTypes = [];
  playerTypes= [];
  playerTypeConfig: AgDropdownConfig;
  floatLabel: string;
  discountMappingType = DiscountMappingType;
  playerTypeOptions = [];
  playerTypeOptionsClone = [];
  vipTypeOptions = [];
  vipTypeOptionsClone = [];
  guestTypeOptions = [];
  guestTypeOptionsClone = [];
  tierLevelOptions = [];
  tierLevelOptionsClone =[];
  discountTypeOptions = [];
  discountTypeOptionsClone = [];
  discountTypeLinking = [];
  playerTypeId: number;
  inputSearchPlayer: string;
  inputSearch: string;

  ngOnInit(): void {
    this.initializeForm();
    this.getFormData();
    this.discountMappingForm.valueChanges.pipe(debounceTime(300),takeUntil(this.destroyed$)).subscribe(val => {
      this.updateValidators();
      this.updateActionButtonsState();
    });
  }

  initializeForm() {
    this.mappingTypes = this.business.getmappingTypes();
    this.discountMappingForm = this.fb.group({
      mappingFormArray: this.fb.array(
        [
          this.addGroup()
        ],
        { validators: uniqueFormGroupValidator() } // Validate duplicate groups
      ) as UntypedFormArray
    });
    this.actionButton = {
      type: "primary",
      label: this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "secondary",
      label: this.captions.btn_cancel,
      disabledproperty: true,
    };
  }

  async getFormData() {
    this.utilities.ToggleLoader(true);
    const [
      playerTypeOptions,
      vipTypeOptions,
      guestTypeOptions,
      tierLevelOptions,
      discountTypeOptions,
      discountTypeLinking
    ] = await Promise.all([
      this.business.getPlayerTypes(),
      this.business.getVIPTypes(),
      this.business.getGuestypes(),
      this.business.getTierLevel(),
      this.business.getDiscountTypes(),
      this.business.getDiscountTypeLinking()
    ]);


    this.playerTypeOptions = playerTypeOptions;
    this.vipTypeOptions = vipTypeOptions;
    this.guestTypeOptions = guestTypeOptions;
    this.tierLevelOptions = tierLevelOptions;
    this.discountTypeOptions = discountTypeOptions;
    this.discountTypeLinking = discountTypeLinking;
    if (this.discountTypeLinking && this.discountTypeLinking.length > 0)
      this.InitialpatchValue();
    this.utilities.ToggleLoader(false);
  }

  InitialpatchValue() {
    const discountTypeLinkingData = cloneDeep(this.discountTypeLinking);
    const masterLinkDatas = discountTypeLinkingData.filter(x => x.recursiveLinkId == 0);
    let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
    formArray.clear();
    masterLinkDatas.forEach(x => {
      let rowData = [];
      rowData.push(x);
      const recursiveData = discountTypeLinkingData.filter(link => link.recursiveLinkId == x.id);
      rowData.push(...recursiveData);
      let playerType = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.PlayerTypes)?.parentId ?? 0;
      let vipType = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.VipTypes)?.parentId ?? 0;
      let guestType = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.GuestTypes)?.parentId ?? 0;
      let tierLevel = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.TierLevels)?.parentId ?? 0;
      let discountType = rowData.find(x => x.masterChildTypeId == DiscountMappingsSections.DiscountTypes)?.childId ?? 0;
      const mappingTypeData = this.getMappingTypesBasedonData(playerType, vipType, guestType, tierLevel);  
      let rowGroup = this.fb.group({
        playerType: playerType,
        vipType: vipType,
        guestType: guestType,
        tierLevel: tierLevel,
        discountType: discountType,
        selectAll: mappingTypeData.filter(x => x.checked).length == 4,
        mappingList: [mappingTypeData],
        id:x.id,
      })
      
      formArray.push(rowGroup);
      this.playerTypeOptionsClone.push([...this.playerTypeOptions]);
      this.vipTypeOptionsClone.push([...this.vipTypeOptions]);
      this.guestTypeOptionsClone.push([...this.guestTypeOptions]);
      this.tierLevelOptionsClone.push([...this.tierLevelOptions]);
      this.discountTypeOptionsClone.push([...this.discountTypeOptions]);
     
    });
    formArray.setValidators(uniqueFormGroupValidator());
    formArray.updateValueAndValidity();
  }

  getMappingTypesBasedonData(playerType, vipType, guestType, tierLevel) {
    let mappingTypeData = cloneDeep(this.mappingTypes);
    if (playerType > 0 && mappingTypeData.find(x => x.id == this.discountMappingType.playerType))
      mappingTypeData.find(x => x.id == this.discountMappingType.playerType).checked = true;
    if (vipType > 0)
      mappingTypeData.find(x => x.id == this.discountMappingType.vipType).checked = true;
    if (guestType > 0)
      mappingTypeData.find(x => x.id == this.discountMappingType.guestType).checked = true;
    if (tierLevel > 0 && mappingTypeData.find(x => x.id == this.discountMappingType.tierLevel))
      mappingTypeData.find(x => x.id == this.discountMappingType.tierLevel).checked = true;
    return mappingTypeData;
  }

  addRow(){
   let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
   formArray.push(this.addGroup());
   this.playerTypeOptionsClone.push([...this.playerTypeOptions]);
   this.vipTypeOptionsClone.push([...this.vipTypeOptions]);
   this.guestTypeOptionsClone.push([...this.guestTypeOptions]);
   this.tierLevelOptionsClone.push([...this.tierLevelOptions]);
    this.discountTypeOptionsClone.push([...this.discountTypeOptions]);
   this.updateValidators();
  }

  addGroup() {
    return this.fb.group({
      playerType: 0,
      vipType:0,
      guestType:0,
      tierLevel: 0,
      discountType: [],
      selectAll:false,
      mappingList: [this.business.getmappingTypes()],
    })
  }

  deleteRow(index){
    let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray; 
    formArray.removeAt(index);
    this.playerTypeOptionsClone.splice(index, 1);
    this.vipTypeOptionsClone.splice(index,1);
    this.guestTypeOptionsClone.splice(index,1);
    this.tierLevelOptionsClone.splice(index,1);
    this.discountTypeOptionsClone.splice(index,1);
    this.discountMappingForm.markAsDirty();
    this.discountMappingForm.updateValueAndValidity();
  }

  resetRow(index){
    const mappingFormArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
    const rowControl = mappingFormArray.at(index) as UntypedFormGroup;

    // Reset specific fields without replacing FormGroup
    rowControl.patchValue({
      playerType: 0,
      vipType: 0,
      guestType: 0,
      tierLevel: 0,
      discountType: ''
    });
  }

  copyRow(index){
    let copyData = this.discountMappingForm.get('mappingFormArray')['controls'][index] as UntypedFormArray;
    //Resetting DiscountType as it should not be copied
    let newRow = cloneDeep(copyData.value);
    newRow.discountType = 0;
    let array = this.addGroup();
    this.playerTypeOptionsClone.push([...this.playerTypeOptions]);
    this.vipTypeOptionsClone.push([...this.vipTypeOptions]);
    this.guestTypeOptionsClone.push([...this.guestTypeOptions]);
    this.tierLevelOptionsClone.push([...this.tierLevelOptions]);
    this.discountTypeOptionsClone.push([...this.discountTypeOptions]);
    array.patchValue(newRow);
    this.discountMappingForm.get('mappingFormArray')['controls'].push(cloneDeep(array));
    this.discountMappingForm.get('mappingFormArray')['controls'] = [...this.discountMappingForm.get('mappingFormArray')['controls']]
    this.discountMappingForm.markAsDirty();
    this.discountMappingForm.updateValueAndValidity();
  }

  selectMappingType(eve,item,index){
    item.checked = eve.checked;
    let formArray = this.discountMappingForm.get('mappingFormArray')['controls'][index] as UntypedFormArray;
    const checkboxes = formArray.get('mappingList').value;
    let mappingList = formArray.get('mappingList').value;
    const updatedMappingList = mappingList.map(mapping =>
      mapping.id === item.id ? { ...mapping, checked: eve.checked } : mapping
    );

    formArray.get('mappingList').setValue(updatedMappingList, { emitEvent: false });
    this.discountMappingForm.markAsDirty();
    const allSelected = checkboxes.every((value) => value.checked);
    formArray.get('selectAll')?.setValue(allSelected, { emitEvent: false });
    if (!item.checked) {
      if (item.id === DiscountMappingType.guestType) {
        formArray.get('guestType')?.setValue(0, { emitEvent: false });
      }

      if (item.id === DiscountMappingType.tierLevel) {
        formArray.get('tierLevel')?.setValue(0, { emitEvent: false });
      }
      if (item.id === DiscountMappingType.playerType) {
        formArray.get('playerType')?.setValue(0, { emitEvent: false });
      }
      if (item.id === DiscountMappingType.vipType) {
        formArray.get('vipType')?.setValue(0, { emitEvent: false });
      }
    }
    this.updateValidators();
  }
  selectAll(e,index){
    let formArray = this.discountMappingForm.get('mappingFormArray')['controls'][index] as UntypedFormArray;
    const checkboxes = formArray.get('mappingList').value;
    checkboxes.forEach((control) => control.checked = e.checked);
    if (!e.checked) {
      ['guestType', 'tierLevel', 'playerType', 'vipType'].forEach(field => {
        formArray.get(field)?.setValue(0, { emitEvent: false });
      });
    }
    this.discountMappingForm.markAsDirty();
    this.updateValidators();
  }

  checkSelectedType(index,type){
    let formArray = this.discountMappingForm.get('mappingFormArray')['controls'][index];
    let isSelected = formArray.controls.mappingList.value?.find(x=> (x.id == type))?.checked;
    // this.updateValidators();
    return isSelected;
  }

  async onAction(e) {

    //Master section ids
    let masterPlayerTypeId = DiscountMappingsSections.PlayerTypes;
    let masterVipTypeId = DiscountMappingsSections.VipTypes;
    let masterGuestTypeId = DiscountMappingsSections.GuestTypes;
    let masterTierLevelId = DiscountMappingsSections.TierLevels;
    let masterDiscountTypeId = DiscountMappingsSections.DiscountTypes;

    let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
    let listofAPIData: DiscountMapping[][] = [];

    formArray.value.forEach(x => {
      let APIData: DiscountMapping[] = [];
      let selectedPlayertypeId: number = x.playerType ? x.playerType : 0;
      let selectedVipTypeId: number = x.vipType ? x.vipType : 0;
      let selectedGuestTypeId: number = x.guestType ? x.guestType : 0;
      let selectedTierLevelId: number = x.tierLevel ? x.tierLevel : 0;
      let selectedDiscountTypeId: number = x.discountType ? x.discountType : 0;
      APIData.push(
        {
          id: 0,
          parentId: selectedPlayertypeId,
          childId: selectedVipTypeId,
          masterParentTypeId: masterPlayerTypeId,
          masterChildTypeId: masterVipTypeId,
          recursiveLinkId: 0
        });

      APIData.push(
        {
          id: 0,
          parentId: selectedVipTypeId,
          childId: selectedGuestTypeId,
          masterParentTypeId: masterVipTypeId,
          masterChildTypeId: masterGuestTypeId,
          recursiveLinkId: 1
        });

      APIData.push(
        {
          id: 0,
          parentId: selectedGuestTypeId,
          childId: selectedTierLevelId,
          masterParentTypeId: masterGuestTypeId,
          masterChildTypeId: masterTierLevelId,
          recursiveLinkId: 1
        });

      APIData.push(
        {
          id: 0,
          parentId: selectedTierLevelId,
          childId: selectedDiscountTypeId,
          masterParentTypeId: masterTierLevelId,
          masterChildTypeId: masterDiscountTypeId,
          recursiveLinkId: 1
        }
      )
      listofAPIData.push(APIData)
      
    }
    );
    this.utilities.ToggleLoader(true);
    await this.business.CreateBulkDiscountTypeLinking(listofAPIData);
    this.discountTypeLinking = await this.business.getDiscountTypeLinking();
    if (this.discountTypeLinking && this.discountTypeLinking.length > 0) {
      this.InitialpatchValue();
    }
    this.utilities.ToggleLoader(false);
    this.discountMappingForm.markAsPristine();
      
  }


  onCancel(e) {
    this.utilities.showCommonAlert(this.captions.warn_datalostChanges, AlertType.Warning, ButtonTypes.YesNo,
      async (res) => {
        if (res === AlertAction.YES) {
          this.utilities.ToggleLoader(true);
          this.discountTypeLinking = await this.business.getDiscountTypeLinking();
          if (this.discountTypeLinking && this.discountTypeLinking.length > 0) {
            this.InitialpatchValue();
          }
          this.utilities.ToggleLoader(false);
          this.discountMappingForm.markAsPristine();
        }
      });
  }

  updateValidators() {
    const mappingFormArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;

    mappingFormArray.controls.forEach((group: UntypedFormGroup, i: number) => {
      const playerTypeControl = group.get('playerType');
      const vipTypeControl = group.get('vipType');
      const guestTypeControl = group.get('guestType');
      const tierLevelControl = group.get('tierLevel');
      const discountTypeControl = group.get('discountType');
      const mappingListControl = group.get('mappingList');

      // Update validators based on mapping type selections
      if (this.checkSelectedType(i, this.discountMappingType.playerType)) {
        playerTypeControl?.setValidators([Validators.required,Validators.min(1)]);
      } else {
        playerTypeControl?.clearValidators();
      }

      if (this.checkSelectedType(i, this.discountMappingType.vipType)) {
        vipTypeControl?.setValidators([Validators.required,Validators.min(1)]);
      } else {
        vipTypeControl?.clearValidators();
      }

      if (this.checkSelectedType(i, this.discountMappingType.guestType)) {
        guestTypeControl?.setValidators([Validators.required,Validators.min(1)]);
      } else {
        guestTypeControl?.clearValidators();
      }

      if (this.checkSelectedType(i, this.discountMappingType.tierLevel)) {
        tierLevelControl?.setValidators([Validators.required,Validators.min(1)]);
      } else {
        tierLevelControl?.clearValidators();
      }

      // Discount type is always required
      discountTypeControl?.setValidators([Validators.required,Validators.min(1)]);

      // Update validity for all controls
      playerTypeControl?.updateValueAndValidity({ emitEvent: false });
      vipTypeControl?.updateValueAndValidity({ emitEvent: false });
      guestTypeControl?.updateValueAndValidity({ emitEvent: false });
      tierLevelControl?.updateValueAndValidity({ emitEvent: false });
      discountTypeControl?.updateValueAndValidity({ emitEvent: false });

      // Explicitly update mapping list validity
      mappingListControl?.updateValueAndValidity({ emitEvent: false });
      group?.updateValueAndValidity();
    });

    // Trigger update for parent array
    mappingFormArray.updateValueAndValidity();
  }

  updateActionButtonsState() {
    const mappingFormArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;

    const hasInvalidRow = mappingFormArray.controls.some((row) => {
      const rowValue = row.value;
      return !rowValue.playerType && !rowValue.vipType && !rowValue.tierLevel && !rowValue.guestType;
    });

    // Check form validity and whether it's been modified
    const isValidAndDirty = this.discountMappingForm.valid && this.discountMappingForm.dirty && !hasInvalidRow;

    this.actionButton = { ...this.actionButton, disabledproperty: !isValidAndDirty };
    this.cancelButton = { ...this.cancelButton, disabledproperty: !isValidAndDirty };
  }

  filterOptions(event, from,index){
    this._filter(event.target.value, from,index);
  }

  private _filter(value, from,index){
    if(from == this.discountMappingType.playerType){
      if(value) {
          const filterValue = value.toLowerCase();
          return this.playerTypeOptionsClone[index] = this.playerTypeOptions.filter(x => x.viewValue.toLowerCase().includes(filterValue));
      } else {
          return this.playerTypeOptionsClone[index] = this.playerTypeOptions;
      }
    } else if(from == this.discountMappingType.vipType){
      if(value) {
        const filterValue = value.toLowerCase();
        return this.vipTypeOptionsClone[index] = this.vipTypeOptions.filter(x => x.viewValue.toLowerCase().includes(filterValue));
      } else {
          return this.vipTypeOptionsClone[index] = this.vipTypeOptions;
      }
    }
    else if(from == this.discountMappingType.guestType){
      if(value) {
        const filterValue = value.toLowerCase();
        return this.guestTypeOptionsClone[index] = this.guestTypeOptions.filter(x => x.viewValue.toLowerCase().includes(filterValue));
      } else {
          return this.guestTypeOptionsClone[index] = this.guestTypeOptions;
      }
    }
    else if(from == this.discountMappingType.tierLevel){
      if(value) {
        const filterValue = value.toLowerCase();
        return this.tierLevelOptionsClone[index] = this.tierLevelOptions.filter(x => x.viewValue.toLowerCase().includes(filterValue));
      } else {
          return this.tierLevelOptionsClone[index] = this.tierLevelOptions;
      }
    }
    else if(from == 'discountType'){
      if(value) {
        const filterValue = value.toLowerCase();
        return this.discountTypeOptionsClone[index] = this.discountTypeOptions.filter(x => x.viewValue.toLowerCase().includes(filterValue));
      } else {
          return this.discountTypeOptionsClone[index] = this.discountTypeOptions;
      }
    }
    
  }

  dropDownSearchClear(value, from,index){
    if(value == "icon-close"){
      this.inputSearch = "";
        this._filter("", from,index);
    }
  }
  resetSearch(from,index) {
    this.inputSearch = "";
    this._filter("", from,index);
  }
}
