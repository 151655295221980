import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PmsIntegrationSetupWrapperModalComponent } from '../pms-integration-setup-wrapper-modal/pms-integration-setup-wrapper-modal.component';
import { Router } from '@angular/router';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailLocalization } from '../common/localization/retail-localization';
import { CommonDataService } from 'src/app/common/dataservices/common.data.service';
import { Product } from '../shared/globalsContant';

@Component({
  selector: 'app-external-post-type-mapping',
  templateUrl: './external-post-type-mapping.component.html',
  styleUrls: ['./external-post-type-mapping.component.scss']
})
export class ExternalPostTypeMappingComponent implements OnInit, OnDestroy {
  selectedRowindex: any;
  public PostTypeMappingData = new EventEmitter<boolean>();
  productId: string;
  constructor(public dialog: MatDialog, private router: Router, private _utils: RetailUtilities,
    private localization: RetailLocalization, private _cds: CommonDataService) { 
      this.productId = this.localization.GetPropertyInfo("ProductId");

    }

  ngOnInit() {
    this.selectedRowindex = 0;
    let toggleVal = 'External';
    let formIndex;
    let postTypeMappingJson = this._cds.PostTypeMappingInput ?? {};
    const dialogRef = this.dialog.open(PmsIntegrationSetupWrapperModalComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      data: {
        formIndex,
        toggleVal,
        postTypeMappingJson
      },
      panelClass: 'small-popup',
      disableClose: true,
    });
    this._utils.ToggleLoader(true, this.localization.captions.lbl_processing);
    if(Number(this.productId) == Product.SNC){
      this.router.navigate(['/settings/pmssetup/pmspostingsetup/pmsIntegrationSetup']);
    } else {
      this.router.navigate(['/settings/interfaces/pmsIntegration/pmsIntegrationSetup']);

    }

    dialogRef.afterClosed().subscribe(res => {

      if (res) {
        this._cds.PostTypeMappingData.emit(res.data);
      }

    });
  }

  ngOnDestroy(): void {
    
  }

}
