import { Injectable, EventEmitter } from '@angular/core';
import { RetailTransactions, ButtonType, MiscellaneousSwitch, Modules } from '../globalsContant';
import { ShopItem, StaffTransactionDetail, CommissionTemplate, ClientCreditCardInfo, PayeeInfo, GuaranteeMethodDetail } from '../business/shared.modals';
import { PostedCommissions, ItemDiscount, ApplyDiscount, GiftCardShopDetails, LinkedRetailItemsData, ApplyTipAsGratuityData, CostPlusDiscountDetails } from '../../shop/shop.modals';
import { RetailUtilities, RedirectToModules } from "../utilities/retail-utilities";
import * as GlobalConst from '../../shared/globalsContant';
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { RetailItemType, OutletSubProperty, VATConfiguration, BaseResponse, ClientMultipack, UserBreakPoint, SystemConfiguration, RentalItemSlabRates, RentalItemRateType } from '../../retail.modals';
import { BreakPointAccess } from './breakpoint.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { rGuestBaseService } from './base.service';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AlertType, ScheduledTeeTimeUnPaidPlayer } from '../shared.modal';
import { DepositEventModel, RetailEventType, RetailEventParameters } from '../events/event.model';
import { RetailDataAwaiters } from '../events/awaiters/retail.data.awaiters';
import { GuestStayDetail, PaymentHistory, PaymentMethod } from './payment/payment-business.model';
import { GiftCardTransactionItem } from './payment/payment-model';
import { retailPublisher } from '../events/pubsub/retail.publishers';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from '../dialog-popup/dialogPopup-componenet';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { PropertySettingDataService } from '../../sytem-config/property-setting.data.service';
import { RetailFunctionalityBusiness } from '../business/retail-functionality.business';
import { ConfigKeys, FeatureName , PMS_SYSTEM_NAMES , RetailConstants, RetailFeatureFlagInformationService} from './retail.feature.flag.information.service';
import { PropertyFeature } from '../business/property-features.model';
import { RetailRoutes } from '../../retail-route';
import { PayAgentService } from './payagent.service';
import { NonPMAgentRequestHandler } from '../../payment/NonPMAgentRequestHandler';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { EngineDiscountModel, TenderInfoTransactionContract } from '../../retail-transaction-engine/transaction-engine-model';
import { TransactionService } from './transaction-service/transaction.dataservice';
import { TransactionTicketDiscount } from './transaction-service/transction-model';
import { TransactionEngineBusiness } from '../../retail-transaction-engine/transaction-engine-business';
import { Subject } from 'rxjs';
import { LockerAssignmentDetails } from '../../shop/shop.modals';
import { Guid } from 'guid-typescript';

@Injectable()
export class CommonVariablesService extends rGuestBaseService {
  CheckOutCallback: any;
  SelectedItemDiscount: ItemDiscount[] = [];
  SelectedOutletId: number = 0;
  SelectedTerminalId: number = 0;
  isPayButtonDisabledForSNCRF: boolean = false;
  DefaultOutletId: number = 0;
  hasAccessSettleCC: boolean;
  hasAccessSettleRC: boolean;
  hasAccessSettleGC: boolean;
  hasAccessSettleHC: boolean;
  hasAccessSettleCash: boolean;
  hasAccessSettlePostToFolio: boolean;
  hasAccessSettleOthers: boolean;
  hasAccessIssueV1GiftCard: boolean;
  hasAccessIssueExternalGiftCard: boolean;
  hasOverrideAccessForComponentLinking: boolean;
  hasAccessSettleNonIntegratedPaymentMethod:boolean;
  hasUserAccessToPriceOverride: boolean
  ItemEligibleForDiscount: any;
  ItemEligibleForCostOrCostPlus: any;
  hasMultiPackItem: boolean;
  AllOutlets: OutletSubProperty[] = [];
  SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[] = [];
  isFromUnpaidPlayer: boolean;
  isFromRefund: boolean;
  ReturnMethod: number = 0;
  isFromEditTeeTime: boolean;
  isFromGiftCard: boolean;
  RedirectModule: RedirectToModules;
  SeletedCourseId: number = 0;
  SelectedDate: string = "";
  isFromSncBeo: boolean;
  isFromSncBeoWithFolioPayment: boolean = false;
  golfBulkPlayerInfo: any = [];
  bookingId: number;
  paymentTokenReferenceId: number;
  arAccountNumber: string;
  arAccountName: string;
  isAutoTriggerNotificationEnabled: boolean = false;
  SelectedRetailItemDetails: any[] = [];
  ReceiptComment: string;
  isExcludeDiscOnServiceCharge: boolean = true;
  isExcludeDiscOnGratuity: boolean = true;
  functionalities: { [key: string]: boolean } = {};
  verifyExternalPMSIntergration = true;
  GuaranteeMethodData: GuaranteeMethodDetail = null;
  RefundTicketTransactionIDs: number[] = [];
  canSelectProducts: boolean = false;
  transactionUpdated = false;
  storeMiscSetting: Promise<SystemConfiguration[]>;
  selectedappointments: any[] = [];
  allowAutoCloseTransaction: boolean = false;
  defaultRefundToOriginal: boolean = false;
  discountReasonRequired: boolean = false;
  discountCommentsRequired: boolean = false;
  isFromCheckInCheckOutPopUP?: boolean;
  restrictNegativeRedeemption?:boolean = false;
  requiredReturnReason: boolean = true;
  golfPlayerInfo: any[];
  courseId: number = 0;
  isFromBulkTeeTime: boolean = false;
  addToTeeTime = new Subject<void>();
  releaseTempHold = new Subject<any>();
  detectRetailRentalTabChange = new Subject<void>();
  triggerFormOrderSummary = new Subject<void>();
  rentalItemGroupList: GroupRetailItems[] = [];
  removedRentalItemGroupList: GroupRetailItems[] = [];
  isRentalAtOutletLevel: boolean = false;
  isdisableUpdate : boolean;
  fromTeeTimeCancellation: boolean = false;
  displayImagesForRentals: boolean = false;	
  fromTeeTimeNoShow: boolean = false;
  isDayPass:boolean = false;
  isAutomaticMemberDiscFlow : boolean = false;
  rentalItemPurchaseDetails : RentalItemPurchase[] = [];
  lockerAssignmentDetail : LockerAssignmentDetails[] =[] ;
  AutoApplyTipAsGratuity: EventEmitter<ApplyTipAsGratuityData> = new EventEmitter<ApplyTipAsGratuityData>();
  RemoveTipAsGratuity: EventEmitter<PaymentHistory[]> = new EventEmitter<PaymentHistory[]>();
  tipAppliedAsGratuity = false;
	isRetailTicketUpdated: boolean = false;
	isDisplayItemNumberInShop: boolean = false;
  applyDiscountAutomatically: boolean = false;
  overrideDiscount:boolean = false;
  mandatoryRentalItemIds: number[] = [];
  public get ProductId() {
    return Number(this.utils.GetPropertyInfo('ProductId'));
  }
  get PaymentProcessor() {
    return this._featureFlagService.IsSkipPMAgent(null) ? this._nonPMAgentService : this._payAgentService;
  }
  get IsSkipPMAgent(){
    return this._featureFlagService.IsSkipPMAgent(null)
  }
  constructor(private utils: RetailUtilities, private http: HttpServiceCall, private BPoint: BreakPointAccess,
    private localization: RetailLocalization, private propertyInfo: RetailPropertyInformation,
    private httpClient: HttpClient, private propertyDataService: PropertySettingDataService,
    private commonUtils: QuickLoginUtilities
    , public dialog: MatDialog, private func: RetailFunctionalityBusiness
    ,private _featureFlagService: RetailFeatureFlagInformationService
    , private _payAgentService: PayAgentService
    , private _nonPMAgentService: NonPMAgentRequestHandler
    , private service : TransactionService
    , private transactionEngineBusiness: TransactionEngineBusiness) {
    super();
  }
  DDselection: string;
  sectionName: string;
  returnExchangeType: string;
  sectionFrom: string;
  AllShopItems: ShopItem[] = [];
  AppoinmentIdForCheckOut: number[] = [];
  isAppointmentCheckOut: boolean = false;
  isClassCheckOut: boolean = false;
  isPreSettlementGenerate: boolean = false;
  isPreSettlementForRetailItem: boolean = false;
  isFromContinueShopping: boolean = false;
  isFromContinueBooking: boolean = false;
  GoToRetailTransaction: boolean = false;
  cancelDepositAppointments: any[] = [];
  cancelDayPassAppointments: any[] = [];
  cancelRetailItems: any[] = [];
  cancellationNoShowCharge: any[] = [];
  CancellationNoShowWaiveOffId: number = 0; 
  clientId: any;
  depositArray: DepositEventModel[] = [];
  excludeTaxForDeposit: boolean;
  cancelFlag: boolean = false;
  statusChangeFlag: boolean = false;
  waiveOffFlag: boolean = false;
  depositFlag: boolean = false;
  commissionTemplate: CommissionTemplate[] = [];
  settleOpenTransaction: boolean = false;
  settleRWTTransaction: boolean = false;
  reOpenTransaction: boolean = false;
  correctTransaction: boolean = false;
  isDepositTransactionCorrection = false;
  isFromTeetimeGraphicalView = false;
  voidedTransactionId: number = 0;
  voidedTicketNumber: string = "";
  transactionId: number;
  ticketNumber: string;
  PackageGroupId: number = 0;
  syncOpenTrasactionList: boolean = true;
  exemptTaxForExchange: boolean;
  isReopenViewOnly: boolean;
  selectedProducts: SelectedProducts[] = [];
  transactionTicketDiscount: TransactionTicketDiscount[] = [];
  selectedResvProducts: SelectedProducts[] = [];
  packagedItems: PackagedItems[] = [];
  outlets: OutletSubProperty[] = [];
  retailTransaction = {
    dropdownState: RetailTransactions.opentransactions,
    tabState: 0,
    fromModifyCommission: false
  }
  returnTicket = {
    dropdownState: RetailTransactions.opentransactions,
    tabState: 0,
    fromReturnTicket: false
  }
  modifyCommission: any = {
    'dropdownState': RetailTransactions.opentransactions,
    'tabState': 0
  }

  isReturnItem: boolean = false;
  selectedRetailItem: SelectedRetailItem = null;
  IDTechCardSwipeTimeout: number;

  selectedTransactionRetailProducts: SelectedProducts[] = [];
  selectedReturnedRetailProducts: SelectedProducts[] = [];
  selectedExchangeRetailProducts: SelectedProducts[] = [];
  selectedRetainedRetailProducts: SelectedProducts[] = [];
  selectedMultiPackExchangedProducts: SelectedProducts[] = [];
  MultiPackProducts: SelectedProducts[] = [];
  MultipackproductIds: number[] = [];
  ClientMultiPacks: ClientMultipack[] = [];
  returnWithticketItems: SelectedProducts[] = [];
  retainedGiftCards: SelectedProducts[] = [];
  selectedclient: PayeeInfo = null;
  isReturnWithoutTicket: boolean = false;
  nonApplicableItemTypeForDiscount = [RetailItemType.Deposit, RetailItemType.CustomFee];
  returnItemTotal: number = 0;
  returnedItemTax: number = 0;
  retainedItemTotal: number = 0;
  exchangedItemTotal: number = 0;
  finalAmount: number = 0;
  rowdata: PostedCommissions;
  isFromDayEnd: boolean = false;
  isFromRetailDashBoard: boolean = false;
  isfromReturnPage = false;
  IsbeoItemReturned = false;
  IsbeoItemCorrected = false;
  IsbeoItemReopened = false;
  isFromBuy: boolean = false;
  isFromDeposit: boolean = false;
  Ticket: Ticket = null;
  ticketDiscount: EngineDiscountModel = null;
  OriginalTicket: Ticket = null;
  TempTicket: Ticket = null;
  retainedTicket: Ticket = null;
  TaxValue: number = 0;
  checkHandleGuid: any;
  hasUserAccessToExemptTax: boolean;
  unitOfMeasures: any;
  selectedPayeeId = 0;
  selectedGuestGuid: string = null;
  propertyVATConfiguration: VATConfiguration;
  memberCardNumber: string = "0";
  memberArNumber: string = "0";
  sourceCorpId: number = 0;
  memberDiscountType: string = "";
  membershipType: string = "";
  paymentTransacId: number = 0;
  giftCardListToUnload: GiftCardTransactionItem[] = [];
  UpdateGiftTabStatus = new EventEmitter<boolean>();
  PayButtonDisable = new EventEmitter<boolean>();
  isFromSpaWizard: boolean = false;
  selectedTransaction: any = null;
  isFromReservation: boolean = false;
  isFromAppointment: boolean = false;
  addRetailItemToSource: boolean = false;
  EditRetailItemToSource: boolean = false;
  appoinmentId: number = 0;
  LinkedRetailItemDetails: LinkedRetailItemsData = null;
  availableRounds: number = 0;
  isMemberNotActive: boolean = false;
  receiptClerkId: number;
  receiptPrintedBy: number;
  returnReason: string = "";
  selectedMemberInfo: PayeeInfo = null;
  hasAccessSettleAR: boolean;
  memberImageUrl: string;
  folioMemberConfig: any = null;
  CreateItemResponse: any;
  remainingAmount = 0;
  totalAmountWithoutTax = 0;
  totalTaxExemptRatio = 0;
  totalTenderReducesDiscountRatio = 0;
  totalTenderReducesTaxRatio = 0;
  taxExemptRatio = 0;
  isTaxRecalculated:boolean = false;
  isamountRecalcForDiscTax:boolean =false;
  isTaxExempted: boolean = false;
  remainingAmountAfterPayment = 0;
  currentCustomFee: CustomFeeConfig[];
  isCustomFeeEnabled = null;
  selectedTaxExemptPayment: PaymentMethod = {
		id: 0,
		paymentTypeId: 0,
		paymentMethodId: 0,
		postTypeId: 0,
	};
	customFeeItemDetails: any[]=[];
  groupedRetailItems: GroupRetailItems[];
  groupedRentalItems: GroupRetailItems[];
  removedRentalItems: SelectedProducts[] = [];
  isFromTherapistPortal : boolean = false;
  lockerAssignmentDetails: LockerAssignmentDetails = null;
  CustomFeeAddedToAppt = new EventEmitter<SelectedProducts[]>();
  printGiftReceipt : boolean = false;
  /**
   * Dictionary, key will be Player / Client ID and Value will be array of Transaction IDs
   */
  dependentTransactions = new Map<number, number[]>();

  /**
   * Reset method to destroy service objects on logout
   */
  public reset(): void {
    this.destroy();
    this.SelectedOutletId = 0;
    this.outlets = [];
    this.DefaultOutletId = 0;
  }

   destroy() {
    this.selectedProducts = [];
    this.transactionTicketDiscount = [];
    this.cancelDepositAppointments = [];
    this.cancelDayPassAppointments = [];
    this.cancelRetailItems = [];
    this.depositArray = [];
    this.SelectedItemDiscount = [];
    this.AppoinmentIdForCheckOut = [];
    this.isAppointmentCheckOut = false;
    this.isClassCheckOut = false;
    this.cancelFlag = false;
    this.depositFlag = false;
    this.statusChangeFlag = false;
    this.waiveOffFlag = false;
    this.selectedPayeeId = 0;
    this.selectedGuestGuid = null;
    this.TaxValue = 0;
    this.correctTransaction = false;
    this.isDepositTransactionCorrection = false;
    this.isFromTeetimeGraphicalView = false;
    this.voidedTransactionId = 0;
    this.voidedTicketNumber = "";
    this.settleOpenTransaction = false;
    this.settleRWTTransaction = false;
    this.isFromUnpaidPlayer = false;
    this.isFromEditTeeTime = false;
    this.selectedPayeeId = 0;
    this.memberCardNumber = "0";
    this.memberArNumber = "0";
    this.sourceCorpId = 0;
    this.isFromGiftCard = false;
    this.IsbeoItemCorrected = false;
    this.IsbeoItemReturned = false;
    this.reOpenTransaction = false;
    this.hasMultiPackItem = false;
    this.SelectedRetailItemDetails = [];
    this.commonUtils.resetQuickIdDetails();
    this.Ticket = null;
    this.selectedTransaction = null;
    this.isFromReservation = false;
    this.isFromAppointment = false;
    this.GuaranteeMethodData = null;
    this.availableRounds = 0;
    this.RefundTicketTransactionIDs = [];
    this.selectedResvProducts = [];
    this.canSelectProducts = false;
    this.isfromReturnPage = false;
    this.returnReason = "";
    this.selectedMemberInfo = null;
    this.transactionUpdated = false;
    this.memberImageUrl = "";
    this.appoinmentId = 0;
    this.addRetailItemToSource = false;
    this.discountCommentsRequired = false;
    this.discountReasonRequired = false;
    this.SelectedPlayers = [];
    this.selectedappointments = [];
    this.totalAmountWithoutTax = 0;
    this.totalTenderReducesDiscountRatio = 0;
    this.totalTenderReducesTaxRatio = 0;
    this.totalTaxExemptRatio = 0;
    this.taxExemptRatio = 0;
    this.isTaxRecalculated = false;
    this.isamountRecalcForDiscTax = false;
    this.remainingAmount = 0;
    this.CreateItemResponse = null;
    this.OriginalTicket = null;
    this.ticketDiscount = null;
    this.selectedTaxExemptPayment = {
      id: 0,
      paymentTypeId: 0,
      paymentMethodId: 0,
      postTypeId: 0,
    };
    this.remainingAmountAfterPayment = 0;
    this.requiredReturnReason = true;
    this.currentCustomFee = [];
    this.isCustomFeeEnabled = null;
    this.customFeeItemDetails = [];
    this.isRentalAtOutletLevel = false;
    this.dependentTransactions.clear();
    this.isAutomaticMemberDiscFlow = false;
    this.lockerAssignmentDetails = null;
    this.isRetailTicketUpdated = false;
    this.AutoApplyTipAsGratuity.complete();
    this.RemoveTipAsGratuity.complete();
    this.tipAppliedAsGratuity = false;
    this.printGiftReceipt = false;
    this.lockerAssignmentDetail = [],
    this.rentalItemPurchaseDetails = [],
    this.isDisplayItemNumberInShop = false;
    this.applyDiscountAutomatically = false;
    this.overrideDiscount = false;
  }


  public get selectedOutlet(): OutletSubProperty {
    return this.AllOutlets.find(x => x.subPropertyID == this.SelectedOutletId);
  }


  ClearServiceObj() {
    this.destroy();
    if (this.propertyInfo.UseRetailInterface && this.Ticket && this.Ticket.checkData.checkNumber.trim() != '') {
      this.InvokeDiscardCheck();
    }
    if (this.isFromDayEnd) {
      this.isFromDayEnd = false;
      this.utils.RedirectTo(RedirectToModules.Dayend);
    }
    if (this.isFromRetailDashBoard) {
      this.isFromRetailDashBoard = false;
      this.utils.RedirectTo(RedirectToModules.home);
    }
    else if (this.isAppointmentCheckOut || this.cancelFlag || this.depositFlag || this.statusChangeFlag) {
      this.utils.RedirectTo(RedirectToModules.appointment);
    }
    else if(this.waiveOffFlag) {
      this.utils.RedirectTo(RedirectToModules.Dayend);
    } 
    else if (this.isFromSncBeo) {
      this.isFromSncBeo = false;
      this.isFromSncBeoWithFolioPayment = false;
      let eventData: RetailEventParameters<any> = {
        data: '',
        eventType: RetailEventType.CancelOrderSummary
      };
      retailPublisher.publishEvent(eventData);
    }
    else if (this.isFromSpaWizard) {
      this.isFromSpaWizard = false;
      this.utils.RedirectTo(RedirectToModules.Spawizard);
    }
    else if(this.isClassCheckOut){
      this.utils.RedirectTo(RedirectToModules.Classes);
    }
    else {
      this.utils.RedirectTo(RedirectToModules.retail);
    }
  }

  async setCustomFeeItemDetails() {
		if (this.currentCustomFee && this.currentCustomFee.length > 0) {
			this.customFeeItemDetails = (await this.getRetailItemsDetailedInfoByIds(this.currentCustomFee.map(x => x.linkedItemId)));
		}
	}

  async InvokeDiscardCheck() {
    await this.CancelTicket(this.Ticket);
    this.Ticket = null;
  }

  async CancelTicket(ticket: Ticket) {
    let uriParams = {
      ticketNumber: ticket.checkData.checkNumber,
      checkHandleGuid: ticket.checkData.checkHandleGuid,
      outletId: this.SelectedOutletId,
      terminalId: this.SelectedTerminalId
    };
    this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "CancelTicket",
      method: HttpMethod.Post,
      uriParams: uriParams
    });
  }

  public async GetCustomFeeConfigurationSetting(): Promise<any> {
    try {
      let result = await this.http.CallApiAsync<any>({
        host: GlobalConst.Host.retailManagement,
        callDesc: "GetConfiguration",
        method: HttpMethod.Get,
        uriParams: { module: 3, Switch: 'REQUIRE_CUSTOM_FEE' }
      });
      return result.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async SetAllOutletsData() {
    let result = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.retailManagement,
      callDesc: "GetSubPropertyAccessByUser",
      method: HttpMethod.Get,
      uriParams: {
        userId: this.http.GetPropertyInfo("UserId")
      }
    });
    this.outlets = result.result ? result.result : [];
  }

  async GetRoomChargeConfigByOutlet(outletId: number) {
    if (!this.outlets || this.outlets.length == 0) {
      await this.SetAllOutletsData();
    }
    let currentOutlet = this.outlets.find(r => r.subPropertyID == outletId);
    const profitCenter = currentOutlet ? currentOutlet.profitCenter : '';
    const paymentMethodId = currentOutlet ? currentOutlet.roomChargePostingPayMethodId : 0;
    return { profitCenter: profitCenter, paymentMethodId: paymentMethodId }
  }

  async GetCurrentProfitCenter(outletId: number) {
    if (!this.outlets || this.outlets.length == 0) {
      await this.SetAllOutletsData();
    }
    let currentOutlet = this.outlets.find(r => r.subPropertyID == outletId);
    const profitCenter = currentOutlet ? currentOutlet.profitCenter : '';
    return profitCenter;
  }

  CalculateReturnWithTicketCommission(transactionId: number, transactionDate: any) {
    let uriParams = {
      transactionId: transactionId,
      transactionDate: transactionDate,
      productId: this.ProductId
    };
    this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "CloseReturnTransaction",
      method: HttpMethod.Put,
      uriParams: uriParams
    });
  }

  async GetLinkedTaxConfigurationById(Id: number) {
    let uriParams = {
      id: Id
    };
    const res = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.GetLinkedTaxDetailsById,
      method: HttpMethod.Get,
      uriParams: uriParams
    });
    return res.result;
  }

  async GetPropertySettings() {
    const response = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.authentication,
      callDesc: 'getAllPropertySettings',
      method: HttpMethod.Get,
      uriParams: {
        propertyId: this.http.GetPropertyInfo('PropertyId')
      }
    });
    return response.result;
  }
  ResetShopObject() {
    this.isReturnWithoutTicket = false;
    this.isFromEditTeeTime = false;
    this.memberCardNumber = "0";
    this.memberArNumber = "0";
    this.sourceCorpId = 0;
    if (this.settleOpenTransaction) {
      this.GoToRetailTransaction = true;
    }
    this.settleOpenTransaction = false;
    this.settleRWTTransaction = false;
    this.reOpenTransaction = false;
    this.SelectedPlayers = [];
    this.selectedappointments = [];
  }

  retailBreakPoints = [];

  checkForAccess() {
    const breakPoints = [
      GlobalConst.RetailBreakPoint.TaxExempt,
      GlobalConst.RetailBreakPoint.SettleToCreditCard,
      GlobalConst.RetailBreakPoint.SettleToRoomCharge,
      GlobalConst.RetailBreakPoint.SettleToCash,
      GlobalConst.RetailBreakPoint.SettleToPostToFolio,
      GlobalConst.RetailBreakPoint.SettleToOthers,
      GlobalConst.RetailBreakPoint.ISSUEV1GIFTCARD,
      GlobalConst.RetailBreakPoint.ISSUEEXTERNALGIFTCARD,
      GlobalConst.RetailBreakPoint.SETTLETOGROUPCHARGE,
      GlobalConst.RetailBreakPoint.OVERRIDE_COMPONENT_LINKING,
      GlobalConst.RetailBreakPoint.ALLOWARPAYMENTS,
      GlobalConst.RetailBreakPoint.SETTLETOGHOTELCOMP,
      GlobalConst.RetailBreakPoint.SettleToNonIntegratedCreditCards,
      GlobalConst.RetailBreakPoint.PriceOverride
    ];
    const breakPointResponse: BaseResponse<UserBreakPoint[]> = this.BPoint.GetBreakPoint(breakPoints);
    console.log({ breakPointResponse });
    breakPoints.forEach(bp => {
      const resp = breakPointResponse.result.find(b => b.breakPointNumber == bp);
      const isAllowed = (resp.allow || resp.view);
      switch (bp) {
        case GlobalConst.RetailBreakPoint.TaxExempt:
          this.hasUserAccessToExemptTax = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.SettleToCreditCard:
          this.hasAccessSettleCC = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.SettleToRoomCharge:
          this.hasAccessSettleRC = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.SETTLETOGROUPCHARGE:
          this.hasAccessSettleGC = isAllowed;
          break;        
        case GlobalConst.RetailBreakPoint.SETTLETOGHOTELCOMP:
          this.hasAccessSettleHC = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.SettleToCash:
          this.hasAccessSettleCash = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.SettleToPostToFolio:
          this.hasAccessSettlePostToFolio = isAllowed;
            break;  
        case GlobalConst.RetailBreakPoint.SettleToOthers:
          this.hasAccessSettleOthers = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.ISSUEV1GIFTCARD:
          this.hasAccessIssueV1GiftCard = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.ISSUEEXTERNALGIFTCARD:
          this.hasAccessIssueExternalGiftCard = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.OVERRIDE_COMPONENT_LINKING:
          this.hasOverrideAccessForComponentLinking = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.ALLOWARPAYMENTS:
          this.hasAccessSettleAR = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.SettleToNonIntegratedCreditCards:
          this.hasAccessSettleNonIntegratedPaymentMethod = isAllowed;
          break;
        case GlobalConst.RetailBreakPoint.PriceOverride:
          this.hasUserAccessToPriceOverride = isAllowed;
        default:
          break;
      }
    });
  }

  checkForIssuanceAccess() {
    const breakPoints = [
      GlobalConst.RetailBreakPoint.ISSUEV1GIFTCARD,
      GlobalConst.RetailBreakPoint.ISSUEEXTERNALGIFTCARD,
    ];
    const breakPointResponse: BaseResponse<UserBreakPoint[]> = this.BPoint.GetBreakPoint(breakPoints);
    console.log({ breakPointResponse });
    breakPoints.forEach(bp => {
      const resp = breakPointResponse.result.find(b => b.breakPointNumber == bp);
      const isAllowed = (resp.allow || resp.view);
      switch (bp) {
        case GlobalConst.RetailBreakPoint.ISSUEV1GIFTCARD:
          return this.hasAccessIssueV1GiftCard = isAllowed;
        case GlobalConst.RetailBreakPoint.ISSUEEXTERNALGIFTCARD:
          return this.hasAccessIssueExternalGiftCard = isAllowed;
        default:
          break;
      }
    });
  }

  CheckIssuanceAccess(isThirdParty: boolean, isCardActive: boolean) {
    if (!this.hasAccessIssueV1GiftCard && !isCardActive && !isThirdParty) {
      this.showBPMessage(GlobalConst.RetailBreakPoint.ISSUEV1GIFTCARD);
      return false;
    }
    else if (!this.hasAccessIssueExternalGiftCard && isThirdParty && !isCardActive) {
      this.showBPMessage(GlobalConst.RetailBreakPoint.ISSUEEXTERNALGIFTCARD);
      return false;
    }
    else {
      return true;
    }
  }

  GetBreakPointAccessMap(breakPoints: number[]): Map<number, boolean> {
    let bpMap: Map<number, boolean> = new Map<number, boolean>();
    if (breakPoints && breakPoints.length > 0) {
      const breakPointResponse: BaseResponse<UserBreakPoint[]> = this.BPoint.GetBreakPoint(breakPoints);
      breakPoints.forEach(bp => {
        const resp = breakPointResponse.result.find(b => b.breakPointNumber == bp);
        bpMap.set(bp, (resp.allow || resp.view));
      });
    }
    return bpMap;
  }

  showBPMessage(bpNumber: GlobalConst.RetailBreakPoint) {
    this.BPoint.showBreakPointPopup(this.localization.captions.breakpoint[bpNumber]);
  }


  GetNextLineNumber(): number {
    let lineNumber = 0;
    if (!this.selectedProducts || this.selectedProducts.length == 0) {
      lineNumber = 1;
    }
    else {
      lineNumber = Math.max(...this.selectedProducts.map(r => r.LineNumber)) + 1;
    }
    return lineNumber;
  }

  async setCustomFeeSwitch(){
		let res = await this.GetCustomFeeConfigurationSetting();
		this.isCustomFeeEnabled = res.value.toLowerCase() == "true"? true : false;
    return this.isCustomFeeEnabled;
	}

  // Returns the parent index of packaged items
  findParentPackageIndex(lineNumber: number, addedItemsList?: any[]): number {
    let parentIndex = -1;
    let discountedItem = addedItemsList.find(x => x.LineNumber == lineNumber && x.isPackagedItem);

    if (discountedItem) {
      var parentItem = addedItemsList.find(x => x.PackageGroupId == discountedItem.PackageGroupId && x.isGroupingKey && !x.isPackagedItem);
      if (parentItem) {
        for (const element of addedItemsList) {
          // increase the index only if normal item, parent element of a packaged item
          if (!element.isPackagedItem)
            parentIndex++;
          //Break if the packge item index found
          if (element.LineNumber == parentItem.LineNumber && element.ItemId == parentItem.ItemId)
            break;
        }
      }
    }
    return parentIndex;
  }

  findSelectedItemIndex(lineNumber: number, addedItemsList?: any[]): number {
    let listOfItems = addedItemsList.filter(x => !x.isPackagedItem);
    let selectedItem = listOfItems.find(x => x.LineNumber == lineNumber);
    return listOfItems.indexOf(selectedItem);
  }

  findSelectedItemIndexWithoutPackage(lineNumber: number, addedItemsList?: any[]): number {
    let listOfItems = addedItemsList.filter(x => !x.isGroupingKey || x.isPackagedItem);
    let selectedItem = listOfItems.find(x => x.LineNumber == lineNumber);
    return listOfItems.indexOf(selectedItem);
  }

  GetOrderSummaryItemIndex(item: SelectedProducts, addedItemsList: any[]): number {
    return this.findSelectedItemIndexWithoutPackage(item.LineNumber, addedItemsList);
  }

  private SetServiceChargeGratuityValue(serviceChargeGratuity: any[], newObject: any, type: string) {
    if (serviceChargeGratuity && serviceChargeGratuity.length > 0) {
      switch (type) {
        case 'SERVICECHARGE': {
          var sc = serviceChargeGratuity.find(r => r.serviceCharge != 0);
          if (sc) {
            let percentageId = this.utils.GetPercentageId(sc.serviceChargePercent);
            if (percentageId > 0) {
              newObject.PercentageId = percentageId;
              newObject.Percentage = sc.serviceChargePercent;
            }
            else if (sc.serviceChargePercent > 0) {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomPercent;
              newObject.Amount = sc.serviceChargePercent;
            }
            else {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomAmount;
              newObject.Amount = sc.serviceCharge;
            }
          }
          break;
        }
        case 'GRATUITY': {
          var sc = serviceChargeGratuity.find(r => r.gratuity != 0);
          if (sc) {
            let percentageId = this.utils.GetPercentageId(sc.gratuityPercent);
            if (percentageId > 0) {
              newObject.PercentageId = percentageId;
              newObject.Percentage = sc.gratuityPercent;
            }
            else if (sc.gratuityPercent > 0) {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomPercent;
              newObject.Amount = sc.gratuityPercent;
            }
            else {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomAmount;
              newObject.Amount = sc.gratuity;
            }
          }
          break;
        }
      }
    }
  }

  GetCustomStaffId(serviceChargeGratuity: any): string {
    let customId: string = '';

    if (serviceChargeGratuity.staffType == GlobalConst.THERAPIST) {
      customId = `T${serviceChargeGratuity.therapistId}`;
    }
    else if (serviceChargeGratuity.staffType == GlobalConst.USER) {
      customId = `U${serviceChargeGratuity.therapistId}`;
    }
    return customId;
  }

  SetCustomStaffIdForCommission(commission: any[]): any[] {
    if (!commission || commission.length == 0) {
      return commission;
    }
    commission.forEach(c => {
      if (c.staffType.toUpperCase() == GlobalConst.THERAPIST) {
        c.id = `T${c.staffId}`;
      }
      else if (c.staffType.toUpperCase() == GlobalConst.USER) {
        c.id = `U${c.staffId}`;
      }
    });
    return commission;
  }

  async getRetailItemsDetailedInfoByIds(retailItemIds: any[]) {
    const result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailManagement,
      callDesc: "GetRetailItemDetailedInfoList",
      method: HttpMethod.Put,
      body: retailItemIds
    });

    return result.result;
  }

  async getCustomFee() {
    if(this.isCustomFeeEnabled == null){
      await this.setCustomFeeSwitch();
    }
    if (this.isCustomFeeEnabled) {
      try{
        let res = await this.getCurrentCustomFeeConfig();
        this.currentCustomFee = res?.result?.length > 0 ? this.mapCustomFee(res.result) : [];
      }
      catch(e){
        this.currentCustomFee = [];
      }
    }
    else 
      this.currentCustomFee = [];
  }

  mapCustomFee(customFeeConfigs) {
    let res = [];
    customFeeConfigs.forEach(data => {
      res.push({
        id: data.id,
        isPercentage: data.isPercentage,
        name: data.name,
        value: data.value,
        linkedItemId: data.linkedItemId,
        code: data.code,
        isApplied: false
      } as CustomFeeConfig)
    })
    return res;
  }

  async getCurrentCustomFeeConfig()
  {
    const result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailManagement,
      callDesc: CommonApiRoutes.GetCurrentCustomFeeConfig,
      method: HttpMethod.Get,
      showError: true
    });
    return result;
  }

  async GetRetailItemDetails(selectedProducts: SelectedProducts[]) {
    if (selectedProducts && selectedProducts.length > 0) {
      const ItemIds = Array.from(new Set(selectedProducts.map(x => x.ItemId)));
      this.SelectedRetailItemDetails = await this.getRetailItemsDetailedInfoByIds(ItemIds);
      return _.cloneDeep(this.SelectedRetailItemDetails);
    }
  }

  async LoadSelectedProducts(transactionData: any, allItems: any[], action: string) {
    const retailItemId = transactionData.map(x => x.itemId);
    allItems = await this.getRetailItemsDetailedInfoByIds(retailItemId);
    this.transactionTicketDiscount = await this.service.getTransactionTicketDiscount(transactionData[0].transactionId);
    this.selectedProducts.push(...this.FormSelectedProduct(transactionData, allItems, action));
    if (transactionData && transactionData.length > 0) {
      let transactionDetailAggregate = await RetailDataAwaiters.GetPlayerNamesByTransactionId(transactionData[0].transactionId);
      if (
        this.selectedProducts.length > 0 && transactionDetailAggregate &&
        transactionDetailAggregate.playerTransactionDetails &&
        transactionDetailAggregate.playerTransactionDetails.length > 0
      ) {
        this.selectedProducts.forEach((p) => {
          let transaction = transactionDetailAggregate.playerTransactionDetails.find(
            (t) => t.transactionDetailId == p.id
          );
          if (transaction) {
            p.playerName = transaction.playerName;
            p.payeeId = transaction.playerId;
          }
        });
      }

    }


  }

  FormSelectedProduct(transactionData: any[], allItems: any[], action: string) {
    if (transactionData.length == 0 || allItems.length == 0) { return []; }
    let excludeDiscount = action && action.toLowerCase() == "correct" && this.propertyInfo.UseRetailInterface;
    const selectedProducts = [];
    for (let transaction of transactionData) {
      let item = allItems.filter(x => x.id == transaction.itemId);
      let customfeeitems = transactionData.filter(x => x.linkedCustomFeeTransactionDetailId == transaction.id && x.customFeeId > 0)?.map(x => x.customFeeId) ?? [];
      let unitOfMeasureDispValue: string = '';
      if (transaction.unitOfMeasureId && transaction.unitOfMeasureId > 0) {
        let unitOfMeasure = this.unitOfMeasures && this.unitOfMeasures.length > 0 ? this.unitOfMeasures.find(x => x.id == transaction.unitOfMeasureId) : [];
        unitOfMeasureDispValue = unitOfMeasure ? unitOfMeasure.name : '';
      }
      let serviceCharge = 0, gratuity = 0;
      if (this.settleOpenTransaction) {
        this.TaxValue += transaction.tax;
      }
      if (transaction.serviceChargeGratuity && transaction.serviceChargeGratuity.length > 0) {
        let gratuityObj = transaction.serviceChargeGratuity.find(x => x.totalGratuity != 0);
        let serviceChargeObj = transaction.serviceChargeGratuity.find(x => x.totalServiceCharge != 0);
        gratuity = gratuityObj ? gratuityObj.gratuity : 0;
        serviceCharge = serviceChargeObj ? serviceChargeObj.serviceCharge : 0;
      }
      let newGratuity: Gratuity[] = [];
      let newServiceCharge: ServiceCharge[] = [];
      if (transaction.serviceChargeGratuity && transaction.serviceChargeGratuity.length > 0) {
        let gratuityRows = [];
        if (transaction.clientMultiPackRedeemId > 0) {
          gratuityRows = transaction.serviceChargeGratuity.filter(y => y.totalGratuity < 0);
        }
        else {
          gratuityRows = transaction.serviceChargeGratuity.filter(y => y.totalGratuity > 0);
        }
        for (let grat of gratuityRows) {
          newGratuity.push({
            Id: this.GetCustomStaffId(grat),
            TransactionDetailId: grat.transactionDetailId,
            TherapistId: grat.therapistId,
            Percentage: grat.gratuityPercent,
            PercentageId: 0,
            PercOrAmount: grat.gratuityPercent > 0 ? 1 : 2, //Percentage - 1 , Amount - 2
            Amount: grat.gratuity,
            gratuity: grat.gratuity,
            StaffType: grat.staffType,
            GratuityTax: grat.gratuityTax,
            additionalGratuity: grat.additionalGratuity,
            customAmount: grat.additionalGratuity,
            customPercentage: grat.additionalGratuityPercent,
            customPercOrAmount: grat.additionalGratuityPercent > 0 ? 1 : 2
          });
        }
        let scRows = [];
        if (transaction.clientMultiPackRedeemId > 0) {
          scRows = transaction.serviceChargeGratuity.filter(y => y.totalServiceCharge < 0);
        }
        else {
          scRows = transaction.serviceChargeGratuity.filter(y => y.totalServiceCharge > 0);
        }

        for (let sc of scRows) {
          newServiceCharge.push({
            Id: this.GetCustomStaffId(sc),
            TransactionDetailId: sc.transactionDetailId,
            TherapistId: sc.therapistId,
            Percentage: sc.serviceChargePercent,
            PercentageId: 0,
            PercOrAmount: sc.serviceChargePercent > 0 ? 1 : 2, //Percentage - 1 , Amount - 2
            Amount: sc.serviceCharge,
            ServiceCharge: sc.serviceCharge,
            StaffType: sc.staffType,
            ServiceChargeTax: sc.serviceChargeTax,
            additionalServiceCharge: sc.additionalServiceCharge,
            customAmount: sc.additionalServiceCharge,
            customPercentage: sc.additionalServiceChargePercent,
            customPercOrAmount: sc.additionalServiceChargePercent > 0 ? 1 : 2
          });
        }

      }
      selectedProducts.push({
        Id: transaction.id,
        ItemDescription: (transaction.itemDescription || (transaction.clientMultiPackRedeemId > 0 ? this.localization.captions.shop.RedeemingMultipack + item[0].retailItemDetail.itemDescription : item[0].retailItemDetail.itemDescription)),
        ItemNumber:item[0]?.retailItemDetail?.itemNumber,
        ItemType: transaction.itemType,
        ProductName: (transaction.itemDescription || (transaction.clientMultiPackRedeemId > 0 ? this.localization.captions.shop.RedeemingMultipack + item[0].retailItemDetail.itemDescription : item[0].retailItemDetail.itemDescription)),
        ServiceId: transaction.serviceId,
        ProductPrice: transaction.unitPrice,
        SalesPrice: this.propertyInfo.UseRetailInterface ? transaction.totalAmount :
          Number(transaction.quantitySold) * Number(transaction.unitPrice),
        ExternalPOSItemId: transaction.externalPOSId,
        ItemId: transaction.itemId,
        Noofitems: transaction.quantitySold,
        Discount: excludeDiscount ? 0 : transaction.discount,
        weightedDiscount: excludeDiscount ? 0 : transaction.weightedDiscount,
        DiscountPercentage: excludeDiscount ? 0 : transaction.discountPercentage,
        DiscountTypeId: excludeDiscount ? 0 : transaction.discountTypeId,
        appliedDiscount: transaction.appliedDiscount,
        isDiscountPercentage: transaction.isDiscountPercentage,
        isEditDisabled: action == "settle",
        isModificationRestricted: (action == "settle" || transaction.isModificationRestricted),
        Commission: this.SetCustomStaffIdForCommission(transaction.commission),
        isCommissionable: item[0].retailItemDetail.isCommissionable,
        isCommissionRequired : item[0].retailItemDetail.isCommissionRequired,
        Gratuity: newGratuity,
        ServiceCharge: newServiceCharge,
        isGroupingKey: item[0].retailItemDetail.isGroupingKey,
        isPackagedItem: transaction.packageItemId > 0 ? true : false,
        PackageItemId: transaction.packageItemId,
        MultiPack: transaction.clientMultiPackRedeemId > 0 ? true : false,
        ClientMultiPackId: 0,
        PackageGroupId: 0,
        LineNumber: transaction.lineNumber,
        OriginalLineNumber: transaction.lineNumber,
        Tax: transaction.tax,
        BaseTax: transaction.baseTax,
        LinkedTax: transaction.linkedTax,
        Vat: transaction.vat,
        isOpenPricedItem: true, // Open Priced Item should always be true as the service may have different price than original price
        id: transaction.id,
        transactionDetailLinkId: transaction.transactionDetailLinkId ? transaction.transactionDetailLinkId : 0,
        isReturn: transaction.isReturn ? transaction.isReturn : false,
        category: item[0].retailItemDetail.category,
        isTaxExempt: transaction.isTaxExempt,
        retailItemType: item[0].retailItemDetail.itemType,
        scaledUnits: transaction.scaledUnit,
        unitOfMeasureId: transaction.unitOfMeasureId,
        uom: unitOfMeasureDispValue,
        isGiftCardItem: transaction.giftCardTransactionItem && transaction.giftCardTransactionItem.cardNumber ? true : false,
        GiftCardTransactionItem: transaction.giftCardTransactionItem,
        isRequestName: item[0].retailItemDetail.isRequestName,
        isAllowPriceOverride: item[0].retailItemDetail.isAllowPriceOverride,
        restrictReturn:item[0].retailItemDetail.restrictReturn,
        itemComments: transaction.itemComments,
        costPrice: transaction.costPrice,
        marginPercentage: transaction.marginPercentage,
        allowEarn: transaction.allowEarn,
        netUnitPrice: transaction.netUnitPrice,
        netUnitPriceWithoutDiscount: transaction.netUnitPrice,
        netPrice: transaction.netPrice,
        appliedCustomFee: customfeeitems,
        customFee: transaction.transactionCustomFee,
        linkedItemLineNumber: transaction.linkedCustomFeeTransactionDetailId > 0 ? transactionData.find(x=>x.id == transaction.linkedCustomFeeTransactionDetailId).lineNumber : 0,
        sourceType: transaction.sourceType ?? 0,
        sourceTypeId: transaction.sourceTypeId ?? 0,
        lockerAssignmentId : 0,
        rentalItemPurchaseId : 0,
        isCostDiscountApplied: transaction.discountTypeId == GlobalConst.DiscountType.Cost,
        isCostPlusDiscountApplied: transaction.discountTypeId == GlobalConst.DiscountType.CostPlus,
        costPlusDiscountDetails: transaction.discountTypeId == GlobalConst.DiscountType.CostPlus ? {
          value: transaction.costPlusDiscountValue,
          isPercentage: transaction.costPlusIsPercentage
        } : null
      });
    }
    this.updateRentalItemPurchaseIds (selectedProducts);
    this.updateLockerAssignmentDetailIds(selectedProducts);
    return selectedProducts;
  }

  private updateLockerAssignmentDetailIds(selectedProducts: any[]) {
    if (this.lockerAssignmentDetail.length > 0) {
      this.lockerAssignmentDetail.forEach(x => {
        const product = selectedProducts.find(i => i.id === x.transactionDetailId);
        if (product) {
          product.lockerAssignmentId = x.id > 0 ? x.id : 0;
        }
      });
    }
  } 

  private updateRentalItemPurchaseIds(selectedProducts: any[]) {
    if (this.rentalItemPurchaseDetails.length > 0) {
      this.rentalItemPurchaseDetails.forEach(x => {
        const product = selectedProducts.find(i => i.id === x.transactionDetailId);
        if (product) {
          product.rentalItemPurchaseId = x.id > 0 ? x.id : 0;
        }
      });
    }
  }

  checkAllPackagedItemRemoved(SelectedProduct: SelectedProducts, selectedProducts: SelectedProducts[]): boolean {
    return !selectedProducts.some(x => x.isPackagedItem && x.PackageGroupId == SelectedProduct.PackageGroupId && x.PackageItemId == SelectedProduct.PackageItemId);
  }


  /**
   * @method function
   * @function getGlobalTimeoutJSON()
   * @input params <null>
   * @output <obj>
   * @description Retrieves the timeout configured from JSON file placed inside Assets folder.
   */
  getGlobalTimeoutJSON() {
    let timeoutConfiguration = this.httpClient.get("./assets/configurations/global.timeout.json");
    timeoutConfiguration.subscribe((data: any) => {
      if (data) {
        this.IDTechCardSwipeTimeout = data.IDTechCardSwipeTimeoutInSeconds * 1000;
      }
    });
  }

  handleCustomFeeAddedItems()
  {
    this.CustomFeeAddedToAppt.subscribe((data: SelectedProducts[]) =>{

    })
  }

  GetServiceChargePercentage(serviceCharge: ServiceCharge): number {
    let percent = 0;
    if (serviceCharge) {
      if (serviceCharge.PercOrAmount == GlobalConst.ServiceChargeGratuityValueType.PreDefinedPercent) {
        percent = serviceCharge.Percentage;
      }
      else if (serviceCharge.PercOrAmount == GlobalConst.ServiceChargeGratuityValueType.CustomPercent) {
        percent = serviceCharge.Amount;
      }
    }
    return percent;
  }

  GetGratuityPercentage(gratuity: Gratuity) {
    let percent = 0;
    if (gratuity) {
      if (gratuity.PercOrAmount == GlobalConst.ServiceChargeGratuityValueType.PreDefinedPercent) {
        percent = gratuity.Percentage;
      }
      else if (gratuity.PercOrAmount == GlobalConst.ServiceChargeGratuityValueType.CustomPercent) {
        percent = gratuity.Amount;
      }
    }
    return percent;
  }

  TicketHasDiscount() {
    return this.Ticket.checkData.discount != 0;
  }

  HasDepoistItem(transactionDetails): boolean {
    let hasDepositItem = false;
    let itemsInTransaction = transactionDetails.filter(r => r.unitPrice >= 0).map(r => r.itemId);
    if (itemsInTransaction && itemsInTransaction.length > 0) {
      hasDepositItem = transactionDetails.some(r => r.itemType == GlobalConst.DepositRetailItemType);
    }
    return hasDepositItem;
  }
  async GetAllRoomsForGuest(guestId: string): Promise<GuestStayDetail[]> {
    let stayDetails: GuestStayDetail[] = [];
    let response: GuestStayDetail[] = await RetailDataAwaiters.getGuestStayDetails(guestId);
    if (response && response.length > 0) {
      stayDetails = response.filter(x => x.roomNo.trim() !== '');
    }
    return stayDetails;
  }

  async getOutletItems() {
    let result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailManagement,
      callDesc: "GetRetailItemsByOutletId",
      method: HttpMethod.Get,
      uriParams: { outletId: this.SelectedOutletId },
      showError: false
    });

    const tempAllShowItems = (result.result ? result.result : []);
    this.AllShopItems = result ? tempAllShowItems : [];
  }
  public async IsServiceChargeOnNet(): Promise<boolean> {
    if (!this.propertyInfo.IsVATEnabled) {
      return (this.isExcludeDiscOnServiceCharge && !this.propertyInfo.UseRetailInterface);
    }
    await this.GetVATConfiguration();
    return this.propertyVATConfiguration && this.propertyVATConfiguration.serviceChargeOnNet;
  }
  public async IsGratuityOnNet(): Promise<boolean> {
    if (!this.propertyInfo.IsVATEnabled) {
      return (this.isExcludeDiscOnGratuity && !this.propertyInfo.UseRetailInterface);
    }
    await this.GetVATConfiguration();
    return this.propertyVATConfiguration && this.propertyVATConfiguration.gratuityOnNet;
  }
  public async IsItemCommissionOnNet(): Promise<boolean> {
    if (!this.propertyInfo.IsVATEnabled) {
      return false;
    }
    await this.GetVATConfiguration();
    return this.propertyVATConfiguration && this.propertyVATConfiguration.itemCommissionOnNet;
  }
  public async IsRevenueCommissionOnNet(): Promise<boolean> {
    if (!this.propertyInfo.IsVATEnabled) {
      return false;
    }
    await this.GetVATConfiguration();
    return this.propertyVATConfiguration && this.propertyVATConfiguration.revenueCommissionOnNet;
  }

  public async GetVATConfiguration() {
    if (!this.propertyVATConfiguration) {
      this.propertyVATConfiguration = await this.GetPropertyVatConfiguration();
    }
  }
  public async GetPropertyVatConfiguration(): Promise<VATConfiguration> {
    let vatConfiguration: BaseResponse<VATConfiguration> = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.authentication,
      callDesc: "GetVatConfiguration",
      method: HttpMethod.Get,
      uriParams: { propertyId: this.utils.GetPropertyInfo("PropertyId") }
    });
    return vatConfiguration.result;
  }

  public async GetItemValueForCharges(grossPrice: number, netPrice: number, netPriceWithoutDiscount: number, chargeType: ServiceChargeGratuityEnum): Promise<number> {
    let price = grossPrice;
    let isGratuityOnNet = false;
    let isServiceChargeOnNet = false;
    if (this.propertyInfo.IsVATEnabled) {
      isGratuityOnNet = await this.IsGratuityOnNet();
      isServiceChargeOnNet = await this.IsServiceChargeOnNet();
    }
    if (this.propertyInfo.UseRetailInterface) {
      if (chargeType === ServiceChargeGratuityEnum.Gratuity) {
        price = await this.IsGratuityOnNet() ? netPrice : price;
      } else if (chargeType === ServiceChargeGratuityEnum.ServiceCharge) {
        price = await this.IsServiceChargeOnNet() ? netPrice : price;
      }
    } else {
      if (chargeType === ServiceChargeGratuityEnum.Gratuity) {
        price = this.isExcludeDiscOnGratuity ? this.propertyInfo.IsVATEnabled && isGratuityOnNet ?
          netPriceWithoutDiscount : netPrice : this.propertyInfo.IsVATEnabled && isGratuityOnNet ?
          netPrice : price;
      } else if (chargeType === ServiceChargeGratuityEnum.ServiceCharge) {
        price = this.isExcludeDiscOnServiceCharge ? this.propertyInfo.IsVATEnabled && isServiceChargeOnNet ?
          netPriceWithoutDiscount : netPrice
          : this.propertyInfo.IsVATEnabled && isServiceChargeOnNet ? netPrice : price;
      }
    }
    return price;
  }


  public async GetWebProxyHealthCheckStatusForPMSPostings(isRoomOrGroupCharge = false, isReversal = false, isPMSPostingViaPMSCommunicationReceiver = false) {
    
    if(isPMSPostingViaPMSCommunicationReceiver){
       return true;
    }
    
    //If Integrated with Versa then no need to validate WebProxy healthcheck, because no flow depends on it
    if (this.propertyInfo.IsVersaIntegration) return true;

    //If Integrated with Resort Finance Revenue Posting Return True.
    if (this.propertyInfo.isResortFinanceEnabled) return true;

    let res = await this.func.getRetailFunctionality();
    if (res) {
      this.functionalities = res;
      this.verifyExternalPMSIntergration = this.functionalities.ValidateExternalPMSRevenuePostingIntegration ? this.functionalities.ValidateExternalPMSRevenuePostingIntegration : false;
    }

    let pmsSystem = sessionStorage.getItem('pmsSystem');

    if(!pmsSystem){
        const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        pmsSystem = propConfig?.PMSSystem;
    }

    console.log("pmsSystem in the session has a value" + pmsSystem);
    if (!pmsSystem || pmsSystem == '') {
      const featureNames = [({ featureName: FeatureName.PMS_RevenuePosting, moduleId: Modules.RETAILMGT } as PropertyFeature)];
      const featureconfigurations = await this._featureFlagService.GetFeatureConfigurations(featureNames);
      if (featureconfigurations != null) {
        const config = featureconfigurations.find(f => f && f.configurationKey === ConfigKeys.PMSRevenuePosting.PMSSystem);
        if (config) {
          pmsSystem = config?.configurationValue;
          console.log("set pmsSystem in the session in Health check" + pmsSystem);
          sessionStorage.setItem("pmsSystem", pmsSystem);
        }
      }
    }

    //Validate WebProxy Healthcheck logic is required for the PMS System configured
    const PMSCommunicator_PMSSystems = [PMS_SYSTEM_NAMES.OPERA_HTNG, PMS_SYSTEM_NAMES.OPERA];
    if (pmsSystem && PMSCommunicator_PMSSystems.some(x => x.toLowerCase() == pmsSystem.trim().toLowerCase())) return true;

    if (this.verifyExternalPMSIntergration && (!isRoomOrGroupCharge || (isRoomOrGroupCharge && pmsSystem && (pmsSystem.toLowerCase() == PMS_SYSTEM_NAMES.V1.toLowerCase() || pmsSystem.toLowerCase() == PMS_SYSTEM_NAMES.VisualOne.toLowerCase() || 
      pmsSystem.toUpperCase() == GlobalConst.VERSA)))) {
      let hasRevenuePostingEnabled = this.propertyInfo.HasRevenuePostingEnabled;
      console.log("HasRevenuePostingEnabled: " + hasRevenuePostingEnabled);
      if (!hasRevenuePostingEnabled) {
        console.log("Retrieving PropertyFeatures");
        let propFeatureList: PropertyFeature[] = await this._featureFlagService.GetPropertyFeatures();
        console.log("GetPropertyFeatures response: " + JSON.stringify(propFeatureList));
        hasRevenuePostingEnabled = propFeatureList?.find(x => x.featureName.toLowerCase() == FeatureName.PMS_RevenuePosting.toLowerCase())?.isActive || false;
        console.log("Updating HasRevenuePostingEnabled as " + hasRevenuePostingEnabled);
      }
      if (hasRevenuePostingEnabled) {
        console.log("Invoking Web proxy health check API");
        let status: BaseResponse<boolean> = await this.http.CallApiAsync<any>({
          host: GlobalConst.Host.retailPOS,
          callDesc: "WebProxyHealthCheck",
          method: HttpMethod.Get,
          uriParams: {},
          showError: false
        });

        if (!status.result) {
          let message = this.localization.captions.shop.WebProxyStatus;
          if (isRoomOrGroupCharge) {
            this.dialog.closeAll();
            message = isReversal ? this.localization.captions.shop.RoomChargeReversalWebProxyStatus : this.localization.captions.shop.RoomChargeWebProxyStatus;
          }
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, message, ButtonType.Ok);
          console.log("Web proxy health check API returned failure!");
          return false;
        }
        console.log("Web proxy health check API returned success!");
        return true;
      } else {
        console.log("Web proxy health check child condition checks failed!");
        return false;
      }
    } else {
      console.log("Web proxy health check parent condition checks failed!");
      return false;
    }
  }

  public async allowMultipleMembersInTransaction() {
    const func: { [key: string]: boolean } = await this.func.getRetailFunctionality();
    return func && func.allowMultipleMembersInTransaction ? func.allowMultipleMembersInTransaction : false;
  }

  public GetSelectedOutletDetail(): OutletSubProperty {
    let outletDetail = this.AllOutlets.find(x => x.subPropertyID == this.SelectedOutletId);
    return outletDetail ? outletDetail : null;
  }

  openEnterCommentDialog(productdetails: SelectedProducts) {
    return this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '750px',
      data: {
        Type: 'ENTERCOMMENT',
        headername: this.localization.captions.shop.EnterComment,
        data: {
          itemDescription: productdetails.ItemDescription
        },
        quantity: productdetails.Noofitems,
        closebool: true,
        buttonType: ButtonType.SaveCancel
      },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
  }
  public async GetMiscConfig() {
    this.storeMiscSetting = this.propertyDataService.GetMiscConfigurationSetting()
    let miscSetting = await this.storeMiscSetting;    
    const GetSwitchValue = (switchName: string) => {
      const defaultValTypeMap = {
        "Boolean": "true",
        "Int32": "1"
      }
      const switchSetting = miscSetting.find(x => x.switch == switchName)
      return switchSetting.value?.toString()?.toLowerCase() == defaultValTypeMap[switchSetting.switchType]
    }

    if (miscSetting && miscSetting.length > 0) {
      this.isExcludeDiscOnServiceCharge = GetSwitchValue(MiscellaneousSwitch.EXCLUDE_DISCOUNT_ON_SERVICE_CHARGE);
      this.isExcludeDiscOnGratuity = GetSwitchValue(MiscellaneousSwitch.EXCLUDE_DISCOUNT_ON_GRATUITY);
      this.allowAutoCloseTransaction = GetSwitchValue(MiscellaneousSwitch.ENABLE_AUTO_CLOSE_TRANSACTION);
      this.defaultRefundToOriginal = GetSwitchValue(MiscellaneousSwitch.DEFAULT_REFUND_TO_ORIGINAL_SETTLEMENTS_ONLY);
      this.discountCommentsRequired = GetSwitchValue(MiscellaneousSwitch.DISCOUNT_COMMENTS_REQUIRED);
      this.discountReasonRequired = GetSwitchValue(MiscellaneousSwitch.DISCOUNT_REASON_REQUIRED);
      this.requiredReturnReason = GetSwitchValue(MiscellaneousSwitch.REQUIRE_RETURN_REASON);
      this.isCustomFeeEnabled = GetSwitchValue(MiscellaneousSwitch.REQUIRE_CUSTOM_FEE);
      this.isRentalAtOutletLevel = GetSwitchValue(MiscellaneousSwitch.RENTAL_ITEM_QUANTITY_AT_OUTLETLEVEL);
      this.displayImagesForRentals = GetSwitchValue(MiscellaneousSwitch.DISPLAY_IMAGES_IN_SHOP);
      this.isDisplayItemNumberInShop = GetSwitchValue(MiscellaneousSwitch.DISPLAY_ITEMNUMBER_IN_SHOP);
      this.applyDiscountAutomatically = GetSwitchValue(MiscellaneousSwitch.APPLY_DISCOUNTMAPPING_AUTOMATICALLY);
      this.overrideDiscount = GetSwitchValue(MiscellaneousSwitch.OVERRIDE_DISCOUNT_APPLIED)
    };

    return miscSetting;
  }

  public get enableResortFinance() : boolean {
    const func: { [key: string]: boolean } =  this.func.getFunctionality();
		return  sessionStorage.getItem(RetailConstants.EnableResortFinance) === 'true' && (func && func.IsResortFinanceEnabled ? func.IsResortFinanceEnabled : false);
	}


  GetCheckLineItemByIndex(index: number): LineItem {
    if (this.Ticket.lineItems && this.Ticket.lineItems.length > index) {
      return this.Ticket.lineItems[index];
    }
    return null;
  }
  async exemptTaxForComp(SaleAmount) {
    if (SaleAmount > this.totalAmountWithoutTax || SaleAmount > this.remainingAmount) {
      console.log('error - saleamount exceeds total')
    }
    let ratio = Number((SaleAmount / this.totalAmountWithoutTax).toFixed(2));
    this.taxExemptRatio = ratio ?? 0;
    this.OriginalTicket.compTaxExemptRatio = Number((this.totalTaxExemptRatio + ratio).toFixed(2)) > 1 ? 1 : Number((this.totalTaxExemptRatio + ratio).toFixed(2));
    if (this.OriginalTicket.compTaxExemptRatio == 0) {
      return false;
    }
    let result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.RecalculateTicketWithTaxExempt,
      method: HttpMethod.Post,
      body: this.OriginalTicket,
      uriParams: [],
      showError: true
    })
    if (result.successStatus) {
      this.Ticket = result.result;
    }
    return result.successStatus;
  }

  getNetTenderReducesSettledAmount(SettlementHistory: Array<PaymentHistory> = []): [number , number] {
    const tenderReducesDiscountSettledPayments = SettlementHistory.filter(x => x.tenderReducesDiscount && x.discountExemptRatio != 0 && !x.isReversed);
    let netTenderReducesDiscountSettledAmount = 0;
    if (tenderReducesDiscountSettledPayments?.length > 0) {
      netTenderReducesDiscountSettledAmount = _.sumBy(tenderReducesDiscountSettledPayments, x => { return x.amount });
    }

    const tenderReducestaxSettledPayments = SettlementHistory.filter(x => x.tenderReducesTax && x.taxExemptRatio != 0 && !x.isReversed);
    let netTenderReducesTaxSettledAmount = 0;
    if (tenderReducestaxSettledPayments?.length > 0) {
      netTenderReducesTaxSettledAmount = _.sumBy(tenderReducestaxSettledPayments, x => { return x.amount });
    }
    return [netTenderReducesDiscountSettledAmount, netTenderReducesTaxSettledAmount];
  } 

  async ReCalculateTicketBasedOnTender(selectedPayment: PaymentMethod, amount : number, netTenderReducesDiscountSettledAmount: number ,netTenderReducesTaxSettledAmount: number,
     isReturn: boolean = false , returnedTRDSettledAmount: number = 0, returnedAutoremoveTaxSettledAmount: number = 0){
     if(amount > 0  && (selectedPayment.tenderReducesDiscount || selectedPayment.isAutoRemoveTax)){
      const isPartialSettlementDone = netTenderReducesDiscountSettledAmount != 0 || netTenderReducesTaxSettledAmount != 0;
        if(!isPartialSettlementDone){ // //change of payment with no partial payments done
          this.Ticket = this.OriginalTicket;
        }
        const request : TenderInfoTransactionContract = {
          contract: !isPartialSettlementDone  ? this.OriginalTicket : this.TempTicket,
          tenderReducesDiscount: selectedPayment.tenderReducesDiscount,
          tenderReducesTax: this.isTaxExempted ? false  : selectedPayment.isAutoRemoveTax,
          paymentAmount: amount,
          netTenderReducesDiscountSettledAmount: netTenderReducesDiscountSettledAmount + returnedTRDSettledAmount,
          netTenderReducesTaxSettledAmount : netTenderReducesTaxSettledAmount + returnedAutoremoveTaxSettledAmount,
          returnedItemTotal : isReturn ? this.returnItemTotal : 0,
          returnedItemTotalWithOutTax : isReturn ? this.returnItemTotal - this.returnedItemTax : 0,
          IsTaxExempt : this.isTaxExempted,
          isReturn: isReturn
        }
        const result = await this.service.ReCalculateTicketBasedOnTender(request);
        this.transactionEngineBusiness.MapNetAmounts(result,this.selectedProducts)
        this.isamountRecalcForDiscTax = true;
        this.Ticket = result;
     }
  }

  async ReCalculateTicketBasedOnTenderForReversedpayment(selectedPayment: PaymentMethod, amount : number, OriginalTicket: Ticket){
     if(amount > 0  && (selectedPayment.tenderReducesDiscount || selectedPayment.isAutoRemoveTax)){
        const request : TenderInfoTransactionContract = {
          contract:  OriginalTicket,
          tenderReducesDiscount: selectedPayment.tenderReducesDiscount,
          tenderReducesTax: selectedPayment.isAutoRemoveTax,
          paymentAmount: amount,
          netTenderReducesDiscountSettledAmount: 0,
          netTenderReducesTaxSettledAmount : 0,
          isReversed : true
        }
        const result = await this.service.ReCalculateTicketBasedOnTender(request);
        this.transactionEngineBusiness.MapNetAmounts(result,this.selectedProducts)
        this.Ticket = result;
     }
  }

  async ReCalculateTicketBasedOnTenderForSettledTransaction(){
    if(this.totalTenderReducesDiscountRatio != 0){
      this.OriginalTicket = this.Ticket = await this.transactionEngineBusiness.CreateTicket(
				this.SelectedOutletId,
				this.selectedProducts,
				this.settleOpenTransaction,
				this.ticketDiscount,
        true
			);
    }
    const ticket =  _.cloneDeep(this.OriginalTicket);
		ticket.tenderReducesDiscountRatio = this.totalTenderReducesDiscountRatio ;
		ticket.compTaxExemptRatio = this.totalTenderReducesTaxRatio ;
     const request : TenderInfoTransactionContract = {
       contract:  ticket,
       tenderReducesDiscount: ticket.tenderReducesDiscountRatio != 0,
       tenderReducesTax: ticket.compTaxExemptRatio != 0,
       isReversed : true
     }
     const result = await this.service.ReCalculateTicketBasedOnTender(request);
     this.transactionEngineBusiness.MapNetAmounts(result,this.selectedProducts)
     this.Ticket = result;
    
 }
  
  ApplyMemberDiscountAutomatically(product: any, miscSettings: any, activeDiscountReasons: any, allCategoryList: any, discountTypes: any, memberDiscountType : any) {
    let nonEligibleForDiscount;
    let discountReasonId;
    const discountReasonSetting = miscSettings.find(x => x.switch == GlobalConst.MiscellaneousSwitch.DISCOUNT_REASON_REQUIRED);
    const discountReasonRequiredTypes = {
      All: "1",
      PercentageOnly: "2"
    }
    if (this._featureFlagService.MemberDiscountReason && discountReasonSetting.isActive
      && (discountReasonSetting.value == discountReasonRequiredTypes.All
        || discountReasonSetting.value == discountReasonRequiredTypes.PercentageOnly)
    ) {
      if (activeDiscountReasons && activeDiscountReasons.length > 0) {
        discountReasonId = activeDiscountReasons.find(x => x.description.trim().toLowerCase() == this._featureFlagService.MemberDiscountReason.trim().toLowerCase())?.id ?? 0
      }
    }
    const applicableDiscount = discountTypes.find(x => x.type == memberDiscountType);
    let discountDataByCategory = allCategoryList.find(x => x.retailCategoryId == product.category && x.eligibleForDiscount);
    if (discountDataByCategory) {
      let discountDetail = discountDataByCategory.discountTypeDetails.find(x => x.discountTypeId == applicableDiscount?.id);
      let isflatDiscount =  !discountDetail?.isPercentage && discountDetail?.discountValue > 0;
      product.DiscountPercentage = discountDetail?.isPercentage ? discountDetail?.discountValue ?? 0 : 0;
      product.DiscountTypeId = product.DiscountPercentage > 0 ? discountDetail?.discountTypeId ?? 0 : 0;
      product.discountReason = discountReasonId;
      product.discountComments = this._featureFlagService.MemberDiscountReason ?? "";
      product.Discount = isflatDiscount ? discountDetail?.discountValue : product.Discount;
      product.isAutoMemDiscApplied = product.DiscountTypeId > 0 || isflatDiscount ? true : false;
      product.isAutoMemDiscRemoved = !product.isAutoMemDiscApplied;
    }
    else {
      nonEligibleForDiscount = product;
      product.isAutoMemDiscApplied = true;
      product.isAutoMemDiscRemoved = !product.isAutoMemDiscApplied;
    }
    return nonEligibleForDiscount;
  }

}


export interface SelectedProducts {
  ItemId: number,
  ExternalPOSItemId: number,
  ItemDescription: string,
  ItemNumber?: string,
  ItemType?: number,
  ServiceId: number,
  SalesPrice: number,
  isEditDisabled?: boolean,
  isDeleteDisabled?: boolean,
  ProductName: string,
  ProductPrice: number,
  MemberPrice?: number,
  Noofitems: number,
  Discount: number,
  weightedDiscount?: number,
  isDiscountPercentage?:boolean;
  appliedDiscount?:number;
  discountMinimumAmountInCart?:number;
  canHaveDiscount?:boolean,
  DiscountTypeId: number,
  DiscountName?: string,
  DisplayPrice?: string,
  DiscountPercentage: number,
  category: number;
  Commission?: Commission[],
  Gratuity?: Gratuity[],
  ServiceCharge?: ServiceCharge[],
  isCommissionable?: boolean,
  isCommissionRequired?: boolean,
  isSettleTicket?: boolean,
  isGroupingKey: boolean,
  isPackagedItem: boolean,
  PackageItemId: number,
  MultiPack: boolean,
  ClientMultiPackId: number,
  PackageGroupId: number,
  LineNumber?: number;
  OriginalLineNumber?: number;
  Tax?: number,
  BaseTax?: number,
  LinkedTax?: number,
  taxExemptIds?: number[];             
  Vat?: number,
  isOpenPricedItem: boolean,
  transactionDetailLinkId?: number,
  id?: number,
  charges?: any,
  StaffTransactionDetail?: StaffTransactionDetail[]
  MaxTherapistForCommission?: number;
  CheckOutAppointmentId?: number;
  IsAppointmentNotCheckedOut?: boolean;
  addOnId?: number;
  scaledUnits?: number;
  unitOfMeasureId?: number;
  uom?: string;
  isReturn?: boolean;
  isTaxExempt?: boolean;
  retailItemType?: RetailItemType;
  playerName?: string;
  isModificationRestricted?: boolean;
  netUnitPrice?: number;
  netUnitPriceWithoutDiscount?: number;
  netPrice?: number;
  payeeId?: number;
  multipackExternalPOSItemId?: number;
  rainCheckItemId?: number;
  isGiftCardItem?: boolean;
  GiftCardTransactionItem?: GiftCardShopDetails;
  rainCheckIssued?: boolean;
  isIncludedInRound?: boolean;
  isRequestName?: boolean;
  isAllowPriceOverride?: boolean;
  itemComments?: string;
  costPrice: number;
  marginPercentage: number;
  isRequireComments?: boolean;
  isDepositOnly?: boolean;
  clientName?: string;
  clientId?: number;
  allowEarn: boolean;
  linkedRetailItemGuid?: string;
  returnType?: ReturnWithTicketType;
  isServiceChargeReturn?: boolean;
  isGratuityReturn?: boolean;
  isItemChecked?: boolean;
  returnLinkId?: number;
  discountComments?: string;
  discountReason?: number;
  isPartialReturn?: boolean;
  transactionDetailId?: number;
  originalQuantity?: number;
  roomIndex?: number;
  totalAmount?: number;
  rentType?: number;
  period?: number;
  perHourRentalRate?: number;
  perDayRentalRate?: number;
  GroupingParentId?: number;
  GroupingUniqueIdentifier?: string;
  GroupingItemDescription?: string;
  productDetails?: ResvRetailShopProductDetail[];
  multiPackTransactionDetailId?: number;
  isGratuityModifiedByCreateTicket?: boolean;
  isServiceChargeModifiedByCreateTicket?: boolean;
  isDiscountModifiedByCreateTicket?: boolean;
  linkedTax?: number;
  baseTax?: number;
  linkedItemLineNumber?: number;
  clientType?: number;
  clientLinkId?: string;
  customFee? : TransactionCustomFee;
  appliedCustomFee?: number[];
  sourceType?: GlobalConst.CustomFeeSourceType,
  sourceTypeId?: number
  playerId?: number
  isAutoMemDiscApplied?: boolean;
  isAutoMemDiscRemoved?: boolean;
  rentalItemPurchaseId?:number;
  lockerAssignmentId ?: number;
  rentalStartTime?:  Date;
  rentalEndTimeWithoutBuffer?:  Date;
  rentalEndTime?:  Date;
  bufferTime?: number;
  duration?: number;
  quantity?: number;
  comments?: string;   
  bufferMins?: number 
  isFromTeeTimeAddRetailItem?: boolean;
  multipackCustomFee?: number;
  multipackMemberCustomFee?: number;
  retailMultipackRedemption?: boolean;
  policyId?: number;
  useInventory?: boolean;
  isCostPlusDiscountApplied?: boolean;
  isCostDiscountApplied?: boolean;
  costPlusDiscountDetails?: CostPlusDiscountDetails;
  overridedProductPrice?: number | string;
  rentalSourceType?: RentalSourceType;
  tempRentalSourceId?: Guid;
  tempHoldId?: number;
  restrictRetrun?: boolean;
  itemPar?: number;
  inventoryQuantity?: number;
}

export interface GroupRetailItems{
  groupName: string;
  groupId: string;
  rentalSourceType: RentalSourceType;
  retailItems: SelectedProducts[];
  outletId?: number;
  isCheckedOut?: boolean;
  tempSourceId: Guid;
  tempHoldId: number;
}

export interface RentalItemPurchase {
    id: number;
    rentalItemId: number;
    sourceType: RentalSourceType;
    sourceTypeId: number;
    rentalStartTime: string;
    rentalEndTimeWithoutBuffer: string;
    rentalEndTime: string;
    buffer: number;
    duration: number;
    quantity: number;
    comments: string;
    isActive: boolean;
    rentalItemDetails: string;
    outletId: number;
    uid: string; //unique identifier
    amount: number;
    itemDescription: string;
    transactionId ?: number;
    transactionDetailId ?: number;
    retailTicketNumber ?: number;
    tempSourceTypeId: Guid;
    tempHoldId: number
}
export enum RentalSourceType {
  ScheduledTeeTimePlayer = 1,
  TempTeeTimePlayer = 2
}
export interface ShopRentalInfoRequest{
  startDateTime: string,
  endDateTime: string,
  itemIds: number[],
  outletId: number
}


export interface RentalItemPurchaseRequest{
  overrideUnavailability: boolean;
  mandatoryRentalItemIds: number[];
  rentalItemPurchases: RentalItemPurchase[];
  isValidationRequired: boolean;
}
export interface RentalItemPurchaseResponse{
  rentalItemPurchases: RentalItemPurchase[];
  rentalItemInfo: RentalItemUnavailability[];
}
export interface RentalItemUnavailability{
  rentalItemId: number;
  itemDescription: string;
  unavailableItemsCount: number;
  availableItemsCount: number;
  isMandatory: boolean;
}

export interface RentalInfoWithAvailability {
    retailItemId: number;
    rateType: RentalItemRateType;
    rate: number;
    bufferTime: number;
    totalQuantity: number;
    availableQuantity: number;
    outofOrderQuantity: number;
    rentalItemSlabRates: RentalItemSlabRates[];
}

export interface ResvRetailShopProductDetail {
  startDate: Date | string;
  endDate: Date | string;
  quantity: number;
  hours: number;
  price: number;
  total: number;
  rentType: boolean;
  rentTypeLabel: string;
  comments : string
}

export enum ReturnWithTicketType {
  Default = 0,
  RETURNSERVICECHARGEONLY,
  RETURNGRATUITYONLY,
  RETURNSERVICECHARGEANDGRATUITY,
  RETAINITEMANDSERVICECHARGE,
  RETAINITEMANDGRATUITY,
  RETAINITEMONLY,
  DUMMYRETURNFORSERVICECHARGEANDGRATUITY

}
export interface PackagedItems {
  PackageItemId: number,
  ItemId: number,
  ExternalPOSItemId: number,
  ItemDescription: string,
  ServiceId: number,
  SalesPrice: number,
  isEditDisabled?: boolean,
  isDeleteDisabled?: boolean,
  ProductName: string,
  ProductPrice: number,
  Noofitems: number,
  Discount: number,
  Commission?: Commission[],
  Gratuity?: Gratuity,
  ServiceCharge?: ServiceCharge,
  isCommissionable?: boolean,
  isSettleTicket?: boolean,
  isModificationRestricted?: boolean
}
export interface SelectedRetailItem {
  clerkId: number,
  comment: string,
  discount: string,
  gratuity: string,
  guestId: number,
  id: number,
  isTaxExempt: boolean,
  isVoided: boolean
  memberId: number,
  outletId: number,
  propertyId: number,
  serviceCharge: number,
  status: string,
  stayId: number,
  subPropertyId: number,
  ticketNumber: string,
  totalAmount: number,
  totalPrice: number,
  totalTax: number,
  transactionDate: string,
  transactionType: string,
  retailTransactionType: string
}
export interface SelectedClient {
  id: string,
  name: string,
  address: string,
  city: string,
  country: string,
  creditCardInfo: ClientCreditCardInfo,
  guestId: string
}

export interface Commission {
  id: any,
  StaffId: number,
  StaffType: string,
  Quantity?: number // for multi therapist commission for same item(not split commission)
  TemplateId?: number;
  SplitLevel?: number;
  SplitPercentage?: number;
}

export interface ServiceChargeGratuity {
  Id: number,
  Date: string,
  ItemId: number,
  TransactionDetailId: number,
  TherapistId: number,
  ServiceChargePercent: number,
  GratuityPercent: number,
  ServiceCharge: number,
  Gratuity: number,
  TotalServiceCharge: number,
  TotalGratuity: number,
  StaffType: string,
  GratuityTax?: number;
  ServiceChargeTax?: number;
  serviceChargeGratuityTaxDetails?: ServiceChargeGratuityTaxDetail[];
  additionalGratuity?: number;
  additionalGratuityPercent?: number;
  additionalServiceCharge?: number;
  additionalServiceChargePercent?: number;
  netGratuity?: number;
  netServiceCharge?: number;
}

export interface ServiceChargeGratuityTaxDetail
{
  taxId: number;
  taxValue?: number;
  isAmount?: boolean;
}

export interface Gratuity {
  Id: any,
  TransactionDetailId: number,
  TherapistId: any,
  Percentage: number,
  PercentageId: number,
  PercOrAmount: number; //Percentage - 1 , Amount - 2
  Amount: number;
  gratuity: number;
  StaffType?: any;
  GratuityTax?: number;
  externalTax? : ServiceChargeGratuityTaxDetail[]
  customPercentage?: number;
  customPercOrAmount?: number;
  customAmount?: number;
  additionalGratuity?: number;
  PaymentTransactionId?: number;
}

export interface ServiceCharge {
  Id: any,
  TransactionDetailId: number,
  TherapistId: any,
  Percentage: number,
  PercentageId: number,
  PercOrAmount: number
  Amount: number;
  ServiceCharge: number;
  StaffType?: any;
  ServiceChargeTax?: number;
  externalTax? : ServiceChargeGratuityTaxDetail[];
  customPercentage?: number;
  customPercOrAmount?: number;
  customAmount?: number;
  additionalServiceCharge?: number;
}

export interface ServiceCharges {
  id: number;
  isPercentage: boolean;
  netServiceCharge: number;
  serviceChargeValue: number;
  taxDetails: TaxDetails[],
  staffType?: any;
  therapistId: any,
  transactionDetailId: number,
  value: number;
  additionalValue: number,
  additionalServiceChargeValue: number,
  additionalServiceChargeIsPercentage: boolean;
}


export interface Gratuities {
  id: number;
  isPercentage: boolean;
  netGratuity: number;
  gratuityValue: number;
  taxDetails: TaxDetails[],
  staffType?: any;
  therapistId: any,
  transactionDetailId: number,
  value: number;
  additionalValue: number,
  additionalGratuityValue: number,
  additionalGratuityIsPercentage: boolean;  
}

export interface ResortFinanceFolioPostDetail  {
  sourceType: string;
  sourceTypeId: string;
  invoiceNumber: string;
  originTotal: number;
  postedAmount: number;
  originType: string;
  postIds: string;
  postChargeUserDetails: string;
  groupingId: string;
}
export interface PostChargeUserDetail {
  userId: number;
  userType: number;
}

export enum ResortFinanceOriginType {
   APPOINTMENT = "1",
   TEETIME ="2" ,
   RETAILITEM ="3"
}
export interface FolioInfo {
  sourceType: string;
  sourceTypeId: string;
  folioInvoiceNumber: string;
}
export enum TransactionStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  RETURN = "RETURN",
  VOID = "VOID"
}
export interface Ticket {
  lineItems: LineItem[],
  checkData: CheckData,
  compTaxExemptRatio: number;
  discounts?: EngineDiscountModel[];
  taxExemptIds: number[];
  calculationOrder: { [key: number]: string; };
  maximumDecimalPlaces: number;
  isVatEnabled: boolean;
  isExcludeDiscountOnGratuity: boolean;
  isExcludeDiscountOnServiceCharge: boolean;
  isExcludeDiscountOnTax: boolean;
  outletId: number;
  propertyVATConfiguration: PropertyVATConfiguration;
  excludeFlatTaxOnZeroCharge: boolean;
  tenderReducesDiscountRatio: number;
}

export interface PropertyVATConfiguration {
  id: number;
  propertyId: number;
  vATNumber: string;
  serviceChargeOnGross: boolean;
  serviceChargeOnNet: boolean;
  vATEnbledForServiceCharge: boolean;
  serviceChargeVATId: number;
  addOnVatDetails: AddOnVatDetails[];
  gratuityOnGross: boolean;
  gratuityOnNet: boolean;
  itemCommissionOnGross: boolean;
  itemCommissionOnNet: boolean;
  revenueCommissionOnGross: boolean;
  revenueCommissionOnNet: boolean;
}

export interface AddOnVatDetails  {
  propertyVATConfigurationId: number;
  vatId: number;
  type: number;
}

export interface LineItem {

  externalPOSItemId: number,
  itemId: number,
  quantity: number,
  unitPrice: number,
  memberUnitPrice: number,
  itemType: string,
  itemDescription: string,
  tax: number,
  linkedTax: number,
  baseTax: number,
  amount: number,
  discount: number,
  weightedDiscount?:number,
  canHaveDiscount?:boolean,
  isMultiPackRedeem: boolean,
  index: number,
  isVoided: boolean,
  isOpenPricedItem: boolean,
  taxDetails: TaxDetails[],
  isTaxExempt: boolean,
  discountModel: ApplyDiscount,
  discountModels?:ApplyDiscount[],
  netPrice: number,
  netUnitPrice: number,
  vat: number;
  gratuity?: number;
  serviceCharge?: number;
  gratuityTax?: number;
  serviceChargeTax?: number;
  serviceCharges?: ServiceCharges[];
  gratuities?: Gratuities[];
  netUnitPriceWithoutDiscount: number;
  OriginalTax: number
  multipackCustomFee?: number;
  multipackMemberCustomFee?: number;
}

export interface CheckData {

  checkNumber: string;
  checkHandleGuid: string;
  checkStatus: string;
  totalTax: number;
  totalAmount: number;
  gratuity: number;
  serviceCharge: number;
  discount: number;
  checkDiscount:number;
  subTotal: number;
  closeDate: Date;
  withheldValue: number;
  totalAmountWithOutTax: number;
  totalItemTax: number;
  totalServiceChargeTax: number;
  totalGratuityTax: number;
  totalLinkedTax:number;
  totalBaseTax: number;
}


export interface TaxDetails {
  id: number,
  value: number,
  isPercentage: boolean,
  isTaxRevenueToProperty: boolean,
  taxUniqueId: number,
  linkedTaxDetails: TaxDetails[],
  linkedTaxValue: number,
  baseTaxValue: number,
  allowRemovalForPaymentMethod: boolean,
  unitTaxValue : number;
  appliedTax: number;
  originalTaxAmount: number
}

export interface ApplyDiscountInput {
  index?: number;
  CategoryId: number;
  SelectedDiscount: ItemDiscount;
  isMultipleItems: boolean;
  itemName?: string;
  itemPrice?: number;
  showItemDetails?: boolean;
  costPrice?: number;
  isCostOrCostPlusEligible?: boolean;
}
export enum ServiceChargeGratuityEnum {
  Gratuity = 1,
  ServiceCharge
}

export interface CustomFeeConfig{
  id: number;
  name: string;
  value: number;
  isPercentage: boolean;
  linkedItemId: number;
  code: string;
  isApplied: boolean;
  isOverWritten: boolean;
}

export interface TransactionCustomFee{
  id: number;
  customFeeId: number;
  name: string;
  transactionId: number;
  transactionDetailId: number;
  value: number;
  isPercentage: boolean;
  isOverWritten: boolean;
  scheduledTeeTimePlayerCustomFeeId?:number;
  scheduledTeeTimePlayerFeeId?:number;
}
