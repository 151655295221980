import { Injectable } from '@angular/core';
import { ActionTypeEnum, aligment, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { EmailProcessingDataService } from 'src/app/common/dataservices/notification/email-processing-data.service';
import { AlertType, ButtonType, TableActions } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { emailInitial, EmailLogs, EmailLogsUI } from './email-logs.model';
import { GraphServiceParams, MailAttachment, MailBody, MailContact, MailMessage, ODataTypes } from 'src/app/common/Models/ms-graph-http.model';
import { MsGraphRoutes } from 'src/app/common/common-route';
import { environment } from 'src/environments/environment';
import { MsGraphApiCommunication } from 'src/app/common/communication/services/ms-graph.service';
import { CommonUtilities } from '../../utilities/common-utilities';
@Injectable()
export class emailLogsBusiness {
    captions: any;
    constructor(private localization: Localization,private utils: CommonUtilities, private emailProcessingDataService: EmailProcessingDataService,private emailClient: MsGraphApiCommunication) {
        this.captions = this.localization.captions;
    }

    getTableOptions(isViewOnly: boolean): TableOptions {
        return {
            defaultSortOrder: SorTypeEnum.desc,
            defaultsortingColoumnKey: 'emailDateTimeStamp',
            isHeaderCheckboxAllowed: true,
            isRowClick: true,
            uniqueKey: 'id',
            columnFreeze: {
                firstColumn: false,
                lastColumn: true
            },
            actions:
                [{

                    type: TableActions.reply,
                    disabled: isViewOnly === true
                },
                {

                    type: TableActions.replyAll,
                    disabled: isViewOnly === true,
                },
                {

                    type: TableActions.forward,
                    disabled: isViewOnly === true,
                },
                {
                    type: TableActions.delete,
                    disabled: isViewOnly === true
                }
                ]
        }
    }

    getTableHeaderOptions(): TableHeaderOptions[] {
        return [

            {
                key: 'checked',
                displayNameId: '',
                displayName: '',
                hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
                templateName: ActionTypeEnum.checkbox,
            },
            {
                key: 'emailSubject',
                displayNameId: 'lbl_subject',
                displayName: this.captions.lbl_subject,
                sorting: true,
                sortingKey: 'emailSubject',
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'fromAddress',
                displayNameId: 'lbl_fromAddress',
                displayName: this.captions.lbl_fromAddress,
                sorting: true,
                sortingKey: 'fromAddress'
            },
            {
                key: 'toAddress',
                displayNameId: 'lbl_toAddress',
                displayName: this.captions.lbl_toAddress,
                sorting: true,
                sortingKey: 'toAddress',
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'emailDateTime',
                displayNameId: 'lbl_emailDateTime',
                displayName: this.captions.lbl_emailDateTime,
                sorting: true,
                sortingKey: 'emailDateTimeStamp'
            },
            {
                key: ActionTypeEnum.action,
                displayName: this.captions.tbl_hdr_actions,
                templateName: ActionTypeEnum.action,
                alignment: aligment.center
            }
        ]
    }

    async getTableContent(emailRefNumber: string){
        let result : EmailLogs[] = await this.emailProcessingDataService.getEmailLogs(emailRefNumber);
        return result.map(x => this.mapTableContent(x));
    }

    mapTableContent(emailLog: EmailLogs) : EmailLogsUI{
        return {
            id: emailLog.id,
            emailReferenceDetailId: emailLog.emailReferenceDetailId,
            emailSubject: emailLog.subject,
            fromAddress: emailLog.fromEmailAddress,
            toAddress: emailLog.toEmailAddress && emailLog.toEmailAddress != null && emailLog.toEmailAddress != '' ? this.mapEmailAddress(emailLog.toEmailAddress) : [],
            ccAddress: emailLog.ccEmailAddress && emailLog.ccEmailAddress != null && emailLog.ccEmailAddress != '' ? this.mapEmailAddress(emailLog.ccEmailAddress) : [],
            checked: false,
            messageId: emailLog.messageId,
            conversionId: emailLog.conversionId,
            emailInitial: this.extractEmailInitial(emailLog.fromEmailAddress),
            emailDateTime: this.localization.localizeUTCDate(emailLog.emailDate, true),
            emailDateTimeStamp : new Date(emailLog.emailDate).valueOf(),
            hasAttachments: emailLog.hasAttachments,
            attachmentList: [],
            bodyContent: ''
        } as EmailLogsUI
    }

    async getEmailLogByMessageId(emailLog: EmailLogsUI){
        let messageWithBody = await this.emailProcessingDataService.getEmailLogByMessageId(emailLog.messageId);
        return messageWithBody && messageWithBody != null ? this.mapBodyAndAttachments(emailLog, messageWithBody) : emailLog;
    }

    mapBodyAndAttachments(emailLog: EmailLogsUI, messageWithBody: EmailLogs){
        emailLog.ccAddress = messageWithBody.ccEmailAddress && messageWithBody.ccEmailAddress != null && messageWithBody.ccEmailAddress != '' ? this.mapEmailAddress(messageWithBody.ccEmailAddress) : [];
        emailLog.bodyContent = messageWithBody.bodyContent;
        emailLog.attachmentList = messageWithBody.attachments;
        return emailLog;
    }

    mapEmailAddress(emailAddress: string) : string[]{
        let emails : string[] = emailAddress.split(',').filter(email => email !== '');
        return emails
    }

    extractEmailInitial(data): emailInitial{
        return {
            initial: data[0] + data[1]
        }
    }

    async refreshEmailLogs(){
        return await this.emailProcessingDataService.refreshEmailLogs();
    }

    async deleteEmailLogs(ids: number[]){
        return await this.emailProcessingDataService.deleteEmailLogs(ids);
    }

    async getEmailSetting() {
        return await this.emailProcessingDataService.getEmailSetting();
    }

    patchMailBody(data) {
        if (data) {
            let toAddress = data.toAddress && data.toAddress != null ? data.toAddress.join(", ") : "";
            let ccAddress = data.ccAddress && data.ccAddress != null ? data.ccAddress.join(", ") : "";
            let emailHeader = `<div><br><br><br><br>
        <p><strong>From:</strong> ${data.fromAddress}</p>
        <p><strong>Sent:</strong> ${data.emailDateTime}</p>
        <p><strong>To:</strong> ${toAddress}</p>
        <p><strong>CC:</strong> ${ccAddress}</p>
        <p><strong>Subject:</strong> ${data.emailSubject}</p>
    </div>`;
            if (!data.bodyContent || data.bodyContent == null) {
                data.bodyContent = "<p></p>";
            }
            //data.bodyContent = data.bodyContent.replace('</body>', `${emailHeader}</body>`);
            data.bodyContent = emailHeader + data.bodyContent;
            return data.bodyContent;
        }

    }

    prepareAndComposeMailMessage(subject: string, ccAddress: string[], toAddress: string[], mailContent: string, attachmentList: any[]) {
        try {
            const ccMailRecipients: MailContact[] = ccAddress && ccAddress.length > 0 ? this.mapEmailAddressForGraphAPI(ccAddress) : [];
            const toMailRecipients: MailContact[] = ccAddress && ccAddress.length > 0 ? this.mapEmailAddressForGraphAPI(toAddress) : [];
            
            const mailBody: MailBody = {
                contentType: "html",
                content: mailContent
            };
            const attachments: MailAttachment[] = [];
            attachmentList.forEach(attachment => {
                attachments.push(
                    {
                        '@odata.type': ODataTypes.FILEATTACHMENT,
                        name: attachment.name,
                        contentType: attachment.contentType,
                        contentBytes: attachment.contentBytes
                    }
                );
            });
            const mailMessage: MailMessage = {
                subject: subject,
                ccRecipients: ccMailRecipients,
                toRecipients: toMailRecipients,
                body: mailBody,
                attachments: attachments
            };
            const graphParams: GraphServiceParams = {
                forceLogin: true,
                route: MsGraphRoutes.MeMessages,
                body: mailMessage
            };
            this.emailClient.openWebMailWithMessage(graphParams).then(result => {
                const encodedItemId = encodeURIComponent(result.id);
                const composeUrl = `${environment['MsDeeplink']}${encodedItemId}?ItemID=${encodedItemId}&exvsurl=1`;
                this.utils.openUrlAsPopoutWindow(composeUrl);
            });
        }
        catch (error) {
            console.log(this.captions.mail_failedToComposeEmail);
            this.utils.showAlert(this.captions.mail_failedToComposeEmail, AlertType.Error, ButtonType.Ok);
        }

    }

    mapEmailAddressForGraphAPI(emailAddress: string[]){
        let mailRecipients :MailContact[] = [];
        emailAddress.forEach(mail => {
            let mailRecipient: MailContact = {
                emailAddress :{
                    address: mail
                }
            }
            mailRecipients.push(mailRecipient);
        });
        return mailRecipients;
    }

}
