import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { MatDialog } from '@angular/material/dialog';
import { ActionMode, AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { CommonUtilities } from '../utilities/common-utilities';
import { DepositPolicySharedService } from './deposit-policy-shared';
import { SubjectDataType } from './deposit-policy.model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';


@Component({
  selector: 'app-deposit-policy',
  templateUrl: './deposit-policy.component.html',
  styleUrls: ['./deposit-policy.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DepositPolicyComponent implements OnInit {

  captions : any;
  @Input() tableContent;
  originalcontent = [];
  tableOptions: any = [];
  @Input() chargeOptions;
  searchText = '';
  IsEditModeEnabledFlag: boolean = false;
  showInactivetog:boolean=false;
  createToggler = false;
  createModalData: createModal;
  crudActionInput;
  @Input() isViewOnly = true;
  onEditDisableFunction = true;
  isCopyPolicy = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  customEditCancelled : boolean = false;
  isSliderChanged : boolean = false;
  
  @Input("tableOptions")
  set tableValues(value: any){
    if(value != null && value.length > 0){
      this.tableOptions = value;
      this.loadTableData();
    }
  }

  constructor(private localization: Localization,public dialog: MatDialog,
    public utils: CommonUtilities, private depositPolicySharedService : DepositPolicySharedService) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
     this.utils.ToggleLoader(true);
        this.depositPolicySharedService.data$.pipe(takeUntil(this.destroyed$)).subscribe(payload => {
          if (payload) {
            if(payload.type == SubjectDataType.SendPolicy) {
            if (this.isCopyPolicy)
              this.loadCopyData(payload.data);
            else
              this.loadEditData(payload.data);
          }
            else if (payload.type == SubjectDataType.APIResponse || payload.type == SubjectDataType.DeleteAPIResponse) {
              if (payload.data == 0) {
                let alertMessage = payload.type == SubjectDataType.APIResponse ? this.captions.alertPopup.successfullysaved : this.captions.alertPopup.successfullyDeleted;
                this.utils.showAlert(alertMessage, AlertType.WellDone, ButtonType.Ok);
                this.backAction();
              }
            else if(payload.data == -1000) {            
              this.backAction();
            }
            else{
              let errorMessage = payload.errorMessage == '' ? this.localization.getError(payload.data) : payload.errorMessage;
              this.utils.showError(errorMessage);
            }
          }
        }
        })
        setTimeout(() => {
          this.utils.ToggleLoader(false);
        }, 100)    
  }

  loadTableData() {
    // this.onEditDisableFunction = true;
    this.originalcontent = this.tableOptions[0].TablebodyData;
    //console.log(this.tableOptions);
    this.showInActiveFilter();
  }

  createCancellation() {
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_save,
      form: undefined
    };
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_createDepositPolicy
    };
  }

  showInactive(eve) {
    this.showInactivetog = !this.showInactivetog;
    this.showInActiveFilter();
  }

  showInActiveFilter() {
    if (this.showInactivetog)
      this.tableContent = this.originalcontent;
    else
      this.tableContent = this.originalcontent.filter(x => x.isActive);

    var tableData = [...this.tableOptions]
    tableData[0].TablebodyData = this.tableContent;
    this.tableOptions = tableData;
  }

  inactiveSliderChange(e){
    this.isSliderChanged = true;
    let alertMsg = e && e.data.isActive ? this.captions.lbl_showMappingActiveAlertmsg : this.captions.lbl_showMappingInactiveAlertmsg;
    this.utils.showAlert(alertMsg, AlertType.Info, ButtonType.Ok);
  }

  CancelEvent(event) {
    this.customEditCancelled = true;
    this.IsEditModeEnabledFlag = false;
    this.isSliderChanged = false;
  }


  async DoneEvent(event) {
    this.depositPolicySharedService.sendData(SubjectDataType.UpdateActive, event.id);
    this.showInActiveFilter();
    this.IsEditModeEnabledFlag = false;
    this.isSliderChanged = false;
  }

  searchTextChange(eve) {
    this.searchText = eve;
  }

  handleClick(event){
    switch (event.from) {
      case ActionMode.create:
          var obj = this.depositPolicySharedService.mapPolicyToObject(event.formValues, false);
          this.depositPolicySharedService.sendData(SubjectDataType.CreatePolicy, obj);
          break;
      case ActionMode.copy:
          var obj = this.depositPolicySharedService.mapPolicyToObject(event.formValues, false);
          this.depositPolicySharedService.sendData(SubjectDataType.CreatePolicy, obj);          
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
      case ActionMode.update:
          var obj = this.depositPolicySharedService.mapPolicyToObject(event.formValues, true);
          this.depositPolicySharedService.sendData(SubjectDataType.UpdatePolicy, obj);
        break;

    }
  }

  back(e) {
    this.backAction();
  }

  backAction(form?) {
    this.customEditCancelled = true;
    this.IsEditModeEnabledFlag = false;
    this.createToggler = false;
  }

  edit(rowData){

    this.IsEditModeEnabledFlag = true;
    this.customEditCancelled = false;
    this.depositPolicySharedService.sendData(SubjectDataType.GetPolicy, rowData[0].id);
    this.isCopyPolicy = false;
  }

  loadEditData(PolicyData: any)
  {
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_editDepositPolicy
    };
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: PolicyData
    };
  }

  copyRow(rowData){
    this.IsEditModeEnabledFlag = true;
    this.depositPolicySharedService.sendData(SubjectDataType.GetPolicy, rowData.id);
    this.isCopyPolicy = true;
  }

  loadCopyData(policyData: any)
  {
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_createDepositPolicy
    };
    this.crudActionInput = {
      mode: ActionMode.copy,
      actionButton: this.captions.btn_update,
      form: policyData
    };
  }


  deleteRow(rowData){
    this.utils.showAlert(this.captions.lbl_showMappingDeleteAlertmsg, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res == AlertAction.YES) {
            this.depositPolicySharedService.sendData(SubjectDataType.DeletePolicy, rowData[0].id);
          }
        })
  }
  formReady(eve) {

  }

  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
