import { Injectable } from '@angular/core';
import { TableHeaderOptions,TableOptions } from '../../Models/ag-models';
import { Localization } from '../../localization/localization';
import { ActionTypeEnum } from '../../components/cdkvirtual/cdkvirtual.model';
import { ConfigValidationService } from '../../services/config/config-validation.service';
import { AggregatedResult, ApiCall, ApiCall2, ChildAggregatedResultConfig, ChildUrl, Comparewithchildren, ConfigKeyAndValue, ConfigKeyAndValuesSubset, ConfigValidate, ConfigValidationUIModel, Configuration, ConsolidatedConfigAPIresponses, DepedenciesFrom, DependeciesconfigKeyAndValue, DependencyFilter, GateWay, Globaldependency, InputValidationConfigJsonApiPMSModel, LegitiamteConfiguration, MasterConfiguration, MasterDatasFilterby, MasterconfigurationsSetup, NeededHearbeats, PlaceHolder, PossibleFilter, PossibleMainValue, Rule, Service, SplitResultForValidation, UrlConfig, ValidationConfig, ValidationJsonApiModel, childConfig, consolidatedConfigResult, masterConfigDetails, masterConfiguration } from './config-validation.model';
import { name } from 'msal/lib-commonjs/packageMetadata';
import { SorTypeEnum } from '../../shared/shared/enums/enums';
import { ConfigValidationStatus, ValidationErrorStatus } from '../../enums/shared-enums';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
@Injectable({
  providedIn: 'root'
})
export class ConfigValidationBusinessService {
  captions: any;
  constructor(private localization: Localization,private configValidationService: ConfigValidationService 
   , private utilities: CommonUtilities) {
    this.captions = this.localization.captions;
}

async masterConfigurationSetup():Promise<masterConfiguration[]>
{
  console.log('start - master configuration setup')
  var masterConfigResponse1 =  await this.configValidationService.getMasterConfigUrl();
  console.log('masterConfigResponse-1',masterConfigResponse1);

  let masterConfigDetailsApiResponse1: masterConfigDetails[]=masterConfigResponse1;
  console.log('masterConfigDetailsApiResponse-1',masterConfigDetailsApiResponse1);
  
  let masterconfigurationApiResponselist1:masterConfiguration[]=[];


  let masterconfigurationApiResponse1:masterConfiguration=
  {
     //config:'Group Charge',
     masterConfigDetails:masterConfigDetailsApiResponse1

  };
  masterconfigurationApiResponselist1.push(masterconfigurationApiResponse1);
  console.log('masterconfigurationApiResponselist-1',masterconfigurationApiResponselist1);
  console.log('end - master configuration setup-1')
  return masterconfigurationApiResponselist1;
}


   async childApiCallTest(id : number):Promise<any>
{
  let ConsolidatedConfigAPIresultlist2:ConsolidatedConfigAPIresponses[]=[]
    let ConsolidatedConfigAPIresult2:ConsolidatedConfigAPIresponses={
      configuration: 'Group Charge',
      childconfiguration: []
    }
  
var childConfigResponse1 = await this.configValidationService.getChildConfigUrl(id);
console.log('childApiCallTest-childConfigResponse1',childConfigResponse1)

let childConfigDetailsApiResponse: childConfig[]=childConfigResponse1;
console.log('childApiCallTest-childConfigDetailsApiResponse1',childConfigResponse1);
return childConfigDetailsApiResponse

}


async childConfiurationSetup(ValidateJsonResponse1: ValidationJsonApiModel[],masterconfigurationApiResponselist1:masterConfiguration[]):Promise<ConsolidatedConfigAPIresponses[]>
{
    console.log('start- child configuration setup')
    let ConsolidatedConfigAPIresultlist1:ConsolidatedConfigAPIresponses[]=[]
    // let ConsolidatedConfigAPIresult1:ConsolidatedConfigAPIresponses={
    //   configuration: '',
    //   childconfiguration: []
    // }
    console.log('ValidateJsonResponse1-length',ValidateJsonResponse1['configurations'].length)

  for (let i=0 ; i<ValidateJsonResponse1['configurations'].length;i++)
  {
    var feature=ValidateJsonResponse1['configurations'][i];
    console.log('for-loop',feature);
    //ConsolidatedConfigAPIresult1.configuration=feature.configKey;


    var ConsolidatedConfigAPIresult1:ConsolidatedConfigAPIresponses={
      configuration: feature.configKey,
      childconfiguration: []
    }
    
    

    const promises = [];

    var masterconfiglist  =masterconfigurationApiResponselist1.find(r=>r.masterConfigDetails);
    //masterconfigurationApiResponse1.masterConfigDetails.find(x=>x.featureName===feature.configKey);
    console.log('masterconfiglist',masterconfiglist)

    //console.log('isApiCallNeeded2-masterConfigDetails',isApiCallNeeded2.masterConfigDetails)
    console.log('isApiCallNeeded3-masterConfigDetails',masterconfiglist.masterConfigDetails.find(x=>x.featureName===feature.configKey))
    var isApiCallNeeded1:masterConfigDetails=masterconfiglist.masterConfigDetails.find(x=>x.featureName===feature.configKey);
    //console.log('isApiCallNeeded1',isApiCallNeeded1)
    ConsolidatedConfigAPIresult1.childconfiguration=  await this.childApiCallTest(isApiCallNeeded1.id)
    console.log('feature.configKey',feature.configKey)
    console.log('ConsolidatedConfigAPIresult',ConsolidatedConfigAPIresult1);

    ConsolidatedConfigAPIresultlist1.push(ConsolidatedConfigAPIresult1)
  }

      
    console.log('end- child configuration setup')
    //ConsolidatedConfigAPIresultlist1.push(ConsolidatedConfigAPIresult1)

    console.log('-----------------------------------------------------')
    console.log('ConsolidatedConfigAPIresultlist',ConsolidatedConfigAPIresultlist1)

    console.log('-----------------------------------------------------')

    return ConsolidatedConfigAPIresultlist1;
  
}




async getValidationJson(): Promise<ConfigValidationUIModel[]> {
  
        
  //this.utils.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
  //master json input from api
  var response =  await this.configValidationService.getValidationJson();



  console.log('response-configuration',response['configurations'])
  let ValidateJsonResponse: ValidationJsonApiModel[]=response;
  console.log('ValidateJsonResponse',ValidateJsonResponse);
  //finalapi result
 let ConsolidatedConfigAPIresultlist:ConsolidatedConfigAPIresponses[]=[]
 let ConsolidatedConfigAPIresult:ConsolidatedConfigAPIresponses={
   configuration: 'Group Charge',
   childconfiguration: []
 }

 //split test start
 console.log('splitstart')
 //ValidateJsonResponse.find(a=>a.configurations.find(b=>b.))


 var splitmasterconfigurationApiResponselist:masterConfiguration[]=await this.masterConfigurationSetup();
 console.log('splitmasterconfigurationApiResponselist',splitmasterconfigurationApiResponselist)
 console.log('master end')

 console.log('child start')
// var splitConsolidatedConfigAPIresultlist:ConsolidatedConfigAPIresponses[] = await this.childConfiurationSetup(ValidateJsonResponse,splitmasterconfigurationApiResponselist);
// console.log('splitConsolidatedConfigAPIresultlist',splitConsolidatedConfigAPIresultlist)

console.log('splitend')

//var ValidatedJsonRawResponse:AggregatedResult[]= await this.validationBothMasterjsonAndMasterConfig(ValidateJsonResponse,splitConsolidatedConfigAPIresultlist);


  //split test end

  //master configuration setup
  //api call
  // console.log('start - master configuration setup')
  // var masterConfigResponse =  await this.configValidationService.getMasterConfigUrl();
  // console.log('masterConfigResponse',masterConfigResponse);

  // let masterConfigDetailsApiResponse: masterConfigDetails[]=masterConfigResponse;
  // console.log('masterConfigDetailsApiResponse',masterConfigDetailsApiResponse);
  
  // let masterconfigurationApiResponselist:masterConfiguration[]=[];


  // let masterconfigurationApiResponse:masterConfiguration=
  // {
  //    config:'Group Charge',
  //    masterConfigDetails:masterConfigDetailsApiResponse

  // };
  // masterconfigurationApiResponselist.push(masterconfigurationApiResponse);
  // console.log('masterconfigurationApiResponselist',masterconfigurationApiResponselist);
  // console.log('end - master configuration setup')


  // //child configuration setup
  // //api call
  // console.log('start- child configuration setup')
  // ValidateJsonResponse['configurations'].forEach(async feature=>
  //   {
  //     //console.log('feaure apicall-configurationName',x.configurationName)

  //     //console.log('feaure apicall-configurationName',k.configurationName);
  //     var isApiCallNeeded:masterConfigDetails=masterconfigurationApiResponse.masterConfigDetails.find(x=>x.featureName===feature.configKey);
  //     console.log('isApiCallNeeded',isApiCallNeeded)
  //     //this.selectedFeature = this.features.find(feature => feature.featureName === featureName);

  //     if(isApiCallNeeded)
  //     {
  //         console.log('isApiCallNeeded',isApiCallNeeded.id)
  //         var childConfigResponse =  await this.configValidationService.getChildConfigUrl(isApiCallNeeded.id);
  //         console.log('childConfigResponse',childConfigResponse)

  //         let childConfigDetailsApiResponse: childConfig[]=childConfigResponse;
  //         console.log('childConfigDetailsApiResponse',childConfigResponse);

  //         ConsolidatedConfigAPIresult.childconfiguration=childConfigDetailsApiResponse;
  //     }
     
  //   }
  // ) 
  // console.log('end- child configuration setup')
  // ConsolidatedConfigAPIresultlist.push(ConsolidatedConfigAPIresult)

  // console.log('-----------------------------------------------------')
  // console.log('ConsolidatedConfigAPIresult',ConsolidatedConfigAPIresult)
  // console.log('ConsolidatedConfigAPIresultlist',ConsolidatedConfigAPIresultlist)

  // console.log('-----------------------------------------------------')
  
  //this.validationBothMasterjsonAndMasterConfig(ValidateJsonResponse,ConsolidatedConfigAPIresultlist);

  

  //this.utils.ToggleLoaderWithMessage(false);
  //let uiModelResponse: ConfigValidationUIModel[]= await this.mapJsontoUIModelNew(ValidatedJsonRawResponse)

  let uiModelResponse: ConfigValidationUIModel[]= await this.mapJsontoUIModel(ValidateJsonResponse)
  console.log('uiModelresponse',uiModelResponse)
  
  return uiModelResponse;
}

async mapJsontoUIModelNew(apiresponse: AggregatedResult[]): Promise<ConfigValidationUIModel[]> {
  let uiModels: ConfigValidationUIModel[] = [];
console.log('apiresponse-mapJsontoUIModelNew',apiresponse)

var i:number =0;
apiresponse.forEach(x=>
  {
    i=i+1;
   let uiModel: ConfigValidationUIModel={
     id: i,
     config: x.masterconfig,
     configkey: '',
     reason: '',
     status: ConfigValidationStatus.success,
     uiHeader: 0,
     expectedValue: 'Nil'
   };

   x.childConfigs.forEach(k=>
    {
      uiModel.configkey=k.configKey;
      uiModel.reason= k.reason;
      uiModel.status=k.isError?ConfigValidationStatus.Fail:ConfigValidationStatus.success

    })
    console.log('i',i,'uiModel',uiModel)
    
    uiModels.push(uiModel)
  
  }
)


  console.log('uiModels',uiModels)
  return uiModels;
}

 async validationBothMasterjsonAndMasterConfig(masterJsonapiresponse: ValidationJsonApiModel[],ConsolidatedConfigAPIresultlist :ConsolidatedConfigAPIresponses[]):Promise<AggregatedResult[]>
{
  console.log('length',ConsolidatedConfigAPIresultlist.find(a=>a.configuration='Group Charge').childconfiguration.length)
  if(ConsolidatedConfigAPIresultlist.find(a=>a.configuration='Group Charge').childconfiguration.length===0)
  {
      console.log('innum varla da')
  }
  console.log('validationBothMasterjsonAndMasterConfig-start')

  console.log('validationBothMasterjsonAndMasterConfig-masterJsonapiresponse',masterJsonapiresponse)
  console.log('validationBothMasterjsonAndMasterConfig-masterConfigsApiResponse',ConsolidatedConfigAPIresultlist)

  let consolidatedConfigresult:consolidatedConfigResult[]=[]
  let Aggregatedresultlist:AggregatedResult[]=[]

  masterJsonapiresponse['configurations'].forEach(x=>
    {
      var apiFeatureResult : ConsolidatedConfigAPIresponses =ConsolidatedConfigAPIresultlist.find(a=>a.configuration=x.configKey)
      console.log('validationBothMasterjsonAndMasterConfig-apiFeatureResult',apiFeatureResult,apiFeatureResult.childconfiguration)
      let aggregatedResult:AggregatedResult={
        masterconfig: x.configKey,
        childConfigs: []
      }
      console.log('validationBothMasterjsonAndMasterConfig-feaure',x.configKey)
      console.log('validationBothMasterjsonAndMasterConfig-AggregatedResult',aggregatedResult)
     x.configValidate.forEach(k=>
       {
          let validatedresult:ChildAggregatedResultConfig={
            configKey: k.configurationName,
            configvalue: '',
            reason: '',
            isError: false
          }
          console.log('validationBothMasterjsonAndMasterConfig-configurationName',k.configurationName)
          let isReadyForValidation=apiFeatureResult.childconfiguration.find(child=>child.configurationKey===k.configurationName)
          console.log('isReadyForValidation',isReadyForValidation,'apiFeatureResult.childconfiguration',apiFeatureResult.childconfiguration)
          if(isReadyForValidation)
          {
              console.log('---validation start---')
              console.log('db and input json matched',isReadyForValidation)

              console.log('---validation data-input k',k,'dbresult',isReadyForValidation)
              var dbDatatype=typeof(isReadyForValidation.configurationValue);
              console.log('input validation config-dataType',k.validationConfig[0].dataType)
              console.log('dbDatatype',dbDatatype);
              //datatype validation
              if(k.validationConfig[0].dataType===dbDatatype)
              {
                  validatedresult.configvalue=isReadyForValidation.configurationValue;
                  validatedresult.reason='Datatype Matched'

              }

              console.log('--validation end')
          }
          else{
            validatedresult.reason='Missing from db'
            validatedresult.isError=true
          }

          console.log('validationBothMasterjsonAndMasterConfig',k)
          console.log('validationBothMasterjsonAndMasterConfig-validationconfig',k.validationConfig[0])
          aggregatedResult.childConfigs.push(validatedresult);
        })
        console.log('aggregatedResult',aggregatedResult)
        Aggregatedresultlist.push(aggregatedResult)

     }) 


     console.log('Aggregatedresultlist',Aggregatedresultlist)
     console.log('validationBothMasterjsonAndMasterConfig-end')
     return Aggregatedresultlist;
}

async mapJsontoUIModel(apiresponse: ValidationJsonApiModel[]): Promise<ConfigValidationUIModel[]> {
  let uiModels: ConfigValidationUIModel[] = [];
console.log('apiresponse-mapJsontoUIModel',apiresponse)

// apiresponse['configurations'].forEach(x=>
//   {
//     console.log('x',x)
//     console.log('loop-configKey',x.configKey)
//     console.log('loop-configValidate',x.configValidate)
//     x.configValidate.forEach(k=>
//       {
//         console.log('loop-configobj',k)
//         console.log('loop-configname',k.configurationName)
        
//       }
//     )
//   }
// )

apiresponse['configurations'].forEach(x=>
  {
   let uiModel: ConfigValidationUIModel={
     id: 1,
     config: x.configKey,
     configkey: '',
     reason: 'reason1',
     status: ConfigValidationStatus.success,
     uiHeader: 0,
     expectedValue: 'Nil'
   };
  //  uiModel.id=1
  //  uiModel.config=x.configKey
  //  uiModel.reason='reason1'
  //  uiModel.status='status1'
   x.configValidate.forEach(k=>
      {
        uiModel.configkey=k.configurationName;
      }
    )
    uiModels.push(uiModel)
  }
)


  console.log('uiModels',uiModels)
  return uiModels;
}
  getHeaderOptions(): TableHeaderOptions[] {
    return [
        {
            key: 'id',
            displayName: 'Id',
            displayNameId: this.captions.lbl_Tenant,
            sorting: true,
            searchable: false
            

        },
        {
            key: 'config',
            displayName: 'Config Name',
            displayNameId: this.captions.lbl_Property,
            sorting: true,
            searchable: true
            
        },
        {
            key: 'configkey',
            displayName: 'Config Key',
            displayNameId: this.captions.lbl_Outlet,
            sorting: true,
            searchable: false
            
            
        },
        {
          key: 'status',
          displayName: 'Status',
          displayNameId: this.captions.PMOutletsModifiedBy,
          sorting: true,
          searchable: false,
          templateName:ActionTypeEnum.custom
      },
        
        {
            key: 'reason',
            displayName: 'Comments',
            displayNameId: this.captions.lbl_lastsync,
            sorting: true,
            searchable: false
        },
        
        {
          key: 'expectedValue',
            displayName: 'Expected Sample Value',
            displayNameId: this.captions.lbl_lastsync,
            sorting: false,
            searchable: false
           
      }
       
    ]
}

getTableOptions(isViewOnly: boolean): TableOptions {
  return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'id',
      showTotalRecords: true,
      enablePagination: false,
      columnSearch:true,
      isFilterRequired:true,
      columnFreeze: {
          firstColumn: false,
          lastColumn: true
        }

      // ,actions: [
      //     {
      //         type: TableActions.edit,
      //         disabled: false
      //     },
      //     {
      //         type: TableActions.sync,
      //         disabled: isViewOnly
      //     },
      //     {
      //         type: TableActions.delete,
      //         disabled: isViewOnly
      //     }
      // ]
  }
}

//////////////// demo-----------------------------------------------------------------------
//----------------------------------//////////////////////////////////////

//comment
async getValidatedUIResult(): Promise<any> {
  //this.utils.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
  //master json input from api

  console.log('----Start new way full generic-----------')
  
  //get input json
  var response =  await this.configValidationService.getValidationJson();
  console.log('generic-response-configuration',response['configurations'])
  let ValidateJsonResponse: ValidationJsonApiModel[]=response;
  console.log('generic-ValidateJsonResponse',ValidateJsonResponse);

  //configuration with Final results
  let finalValidateJsonResponse: ValidationJsonApiModel[]=response;


  for(let i=0 ; i<ValidateJsonResponse['configurations'].length;i++)
 {
  var inputFeature:Configuration=ValidateJsonResponse['configurations'][i];
    console.log('genric-for-loop',inputFeature);
    console.log('configkey',inputFeature.configKey)
    
    //initializie master configuration setup
    //var masterConfigurationApiResponselist:masterConfigDetails[]=await this.initialMasterConfigurationSetup(inputFeature.masterconfigurationsSetup);
    var masterConfigurationApiResponselist:any=await this.initialMasterConfigurationSetup(inputFeature.masterconfigurationsSetup);

    console.log('generic-masterConfigurationApiResponselist',masterConfigurationApiResponselist);

    console.log('generic-master end')
    //child
    console.log('generic-child start')
    console.log('chilsetup',inputFeature.configUrl[0].getCall[0].childUrl);

    var filterBy=inputFeature.configUrl[0].getCall[0].childUrl.comparewithmaster;
    console.log('filterby',filterBy)

    //get child configuration using master response
    //future work->>if(list) {}else{ }

   

    

   //need to change x.feature name as generic
    var isApiCallNeededGeneric:any=masterConfigurationApiResponselist.find(x=>x[filterBy]===inputFeature.configKey);
    console.log('generic isApiCallNeededGeneric',isApiCallNeededGeneric);
    
    var childConfigurationApiResponselist:any[]=await this.initialchildConfiurationSetup(inputFeature,isApiCallNeededGeneric);
    console.log('generic childConfigurationApiResponselist',isApiCallNeededGeneric);
console.log('next need to add the final result',childConfigurationApiResponselist)
    
finalValidateJsonResponse['configurations'][i]['Configvalidatedapiresult'] = childConfigurationApiResponselist;

    // console.log('generic-masterConfigurationApiResponselist',masterConfigurationApiResponselist);
    console.log('finalValidateJsonResponse',    finalValidateJsonResponse['configurations'][i])



     console.log('generic-child end')

     //if otuside loop not work come here  //validation both input and output

 }
 // going out from loop validation both input and output
 console.log('finalValidateJsonResponselist', finalValidateJsonResponse)
 var uiResult:ConfigValidationUIModel[]= []
 //await this.validationBothInputJsonAndApiResponse(finalValidateJsonResponse);
 console.log('----validation ready-----------')
console.log('uiResult',uiResult)
 console.log('-----------------------------------')

  console.log('----End new way full generic-----------')
  //this.utils.ToggleLoaderWithMessage(false);
  return uiResult;
}



async initialMasterConfigurationSetup(urlConfiguration:MasterconfigurationsSetup):Promise<any>
{
  //masterConfigDetails
  //new implementation start
  let masterconfigurationApiResponselist1:masterConfiguration[]=[];

  console.log('generic-initialMasterConfigurationSetup-start')
  var masterConfigurationResult =  await this.configValidationService.getMasterConfigurationAPICall(urlConfiguration);
  console.log('generic-masterConfigurationResult',masterConfigurationResult)

  console.log('generic-initialMasterConfigurationSetupend')

  return masterConfigurationResult;
  //new implemtntation end

}

async initialchildConfiurationSetup(inputFeature: Configuration,masterConfigDetails:any):Promise<any>
{
    console.log('start- child configuration setup-initialchildConfiurationSetup',inputFeature,masterConfigDetails)
    let ConsolidatedConfigAPIresultlist1:ConsolidatedConfigAPIresponses[]=[]
   

    // var feature=ValidateJsonResponse1['configurations'][i];
    // console.log('for-loop',feature);
    // //ConsolidatedConfigAPIresult1.configuration=feature.configKey;


    var ConsolidatedConfigAPIresult1:ConsolidatedConfigAPIresponses={
      configuration: '',
      childconfiguration: []
    }
    
  
    //console.log('isApiCallNeeded1',isApiCallNeeded1)
    var childconfigurationApiResult:any[]=  await this.initialChildApiCallTest(masterConfigDetails,inputFeature)
    console.log('feature.configKey','neeeeeddd')
    console.log('ConsolidatedConfigAPIresult',childconfigurationApiResult);

    //ConsolidatedConfigAPIresultlist1.push(ConsolidatedConfigAPIresult1)
  

      
    console.log('end- child configuration setup-initialchildConfiurationSetup')
    //ConsolidatedConfigAPIresultlist1.push(ConsolidatedConfigAPIresult1)

    console.log('-----------------------------------------------------')
    console.log('ConsolidatedConfigAPIresultlist',ConsolidatedConfigAPIresultlist1)

    console.log('-----------------------------------------------------')

    return childconfigurationApiResult;
  
}

async initialChildApiCallTest(masterConfigDetail :any,inputFeature: Configuration):Promise<any[]>
{
  let ConsolidatedConfigAPIresultlist2:ConsolidatedConfigAPIresponses[]=[]
    let ConsolidatedConfigAPIresult2:ConsolidatedConfigAPIresponses={
      configuration: 'Group Charge',
      childconfiguration: []
    }



  
var childConfigResponse1 = await this.configValidationService.getChildConfigurationApiCall(masterConfigDetail,inputFeature);
console.log('initialChildApiCallTest-childConfigResponse1',childConfigResponse1)

let childConfigDetailsApiResponse: any[]=childConfigResponse1;
console.log('initialChildApiCallTest-childConfigDetailsApiResponse1',childConfigResponse1);

return childConfigDetailsApiResponse;

}

urlCheck(url :string)
{
  this.configValidationService.validateUrl(url).subscribe(
    (isAccessible: boolean) => {
      //this.isUrlAccessible = isAccessible;
      console.log(' isAccessible', isAccessible)
    }
  );
}

// async validationBothInputJsonAndApiResponse(finalValidateJsonResponse: ValidationJsonApiModel[]):Promise<ConfigValidationUIModel[]>
// {

//   let finalUIResult:ConfigValidationUIModel[]=[];
//   var idcount:number=0;
//   for(let i=0 ; i<finalValidateJsonResponse['configurations'].length;i++)
//     {
//      var inputFeature:Configuration=finalValidateJsonResponse['configurations'][i];
//      var inputJson:ConfigValidate[]=inputFeature.configValidate;
//      var apiResult:any[]=inputFeature.Configvalidatedapiresult;
//      var inputKeyvalidationList:ValidationConfig[]=inputFeature.configValidate.find(a=>a.validationConfig).validationConfig;

//      console.log('inputFeature-validationBothInputJsonAndApiResponse',inputFeature);
//      console.log('genric-for-loop-validationBothInputJsonAndApiResponse-input',inputJson);
//      console.log('genric-for-loop-validationBothInputJsonAndApiResponse-inputKeyvalidationList',inputKeyvalidationList);
//      console.log('genric-for-loop-validationBothInputJsonAndApiResponse-apiresponse',apiResult);
     
//      for(let i=0;i<inputJson.length;i++)
//       {
//         var iserror:boolean=false;
//         idcount=idcount+1;
//         var inputconfigkey=inputJson[i].configurationName;
//         console.log('inputconfigkey',inputconfigkey);
//         console.log('comparewith',inputKeyvalidationList.find(c=>c.Comparefilterwith).Comparefilterwith);
//         var isRequired=inputKeyvalidationList[0].required;
//         console.log('isrquired',isRequired );

//         let uiModel: ConfigValidationUIModel={
//           id:idcount ,
//           config: inputFeature.configKey,
//           configkey: inputconfigkey,
//           reason: 'Looks Fine',
//           status: ConfigValidationStatus.success
//         }
//         var apifilterby=inputKeyvalidationList.find(c=>c.Comparefilterwith).Comparefilterwith;
//         var isKeypresent=apiResult.find(a=>a[apifilterby]===inputconfigkey);
//         console.log('iskeypresent',isKeypresent);

//         if(isKeypresent)
//         {
//           var apiresponsevalue=inputKeyvalidationList.find(c=>c.ValuekeyFrom).ValuekeyFrom;

//           console.log(isKeypresent[apiresponsevalue])
//           var value:any =isKeypresent[apiresponsevalue];
//           console.log(inputconfigkey,'-value got',value)
//           console.log('typeof(value)',typeof(value))
//           console.log('input datatype',inputKeyvalidationList.find(c=>c.dataType).dataType)


//           //datatype check
//           if(typeof(value)===inputKeyvalidationList.find(c=>c.dataType).dataType)
//           {
           
//             uiModel.status=ConfigValidationStatus.success

//           }
//           else{
//             uiModel.reason= 'Datatype Mismatched'
//             uiModel.status=ConfigValidationStatus.Fail
//           }
//         }
//         else
//         {
//          uiModel.reason= isRequired?'Required But Missing from DB': 'Not required But Missing From DB'
//          uiModel.status=isRequired?ConfigValidationStatus.success:ConfigValidationStatus.Fail;
         
//           console.log(inputconfigkey,'-keynot found')
//         }

//         finalUIResult.push(uiModel);

//       }

//     }



// console.log('finalUIResult',finalUIResult)

// //urlcheck
// //this.urlCheck('https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService');
// //url check

//   return finalUIResult;

// }

//comment
//////-------------------------------------------------/////////////////
//////////////////////////-------------------------------------------------------------


//*************************************START retailconfiguration,credit card and gift card***********************************************8 */
//-------------------------------------------------------------------------------------------------------------------------------------------

//////////////////////////////////////////////////////////////////////////////////////////////////////
// async getValidatedRetailConfigurationUIResult(): Promise<ConfigValidationUIModel[]> {
//   this.utils.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
//   //master json input from api

//   console.log('----Start new way full generic retail config,cc and gc-----------')
  
//   //get input json
//   var response =  await this.configValidationService.getValidationJson();
//   console.log('generic-response-configuration',response['configurations']);
//   let ValidateJsonResponse: InputValidationConfigJsonApiModel[]=response;
//   console.log('generic-ValidateJsonResponse',ValidateJsonResponse);

//   //configuration with Final results
 
//   let ConfigApiJsonResponseList: InputValidationConfigJsonApiModel={
//     product: ValidateJsonResponse['product'],
//     configurations: []
//   };


//   for(let i=0 ; i<ValidateJsonResponse['configurations'].length;i++)
//     {
//       let newinputFeature:Configuration={
//         configKey: '',
//         masterconfigurationsSetup: undefined,
//         configUrl: [],
//         configValidate: [],
//         heartbeat: '',
//         dependantServices: [],
//         Configvalidatedapiresult: [],
//         masterConfigKey: '',
//         masterConfigurations: [],
//         childConfigurations: [],
//         legitiamteConfiguration: [],
//         ConfigApiresult: [],
//         subMasterConfigKey: '',
//         isFilterMandatory: false,
//         isGroupByMandatory: false
//       }

//      var inputFeature:Configuration=ValidateJsonResponse['configurations'][i];
//        console.log('genric-for-loop',inputFeature);
//        console.log('configkey',inputFeature.masterConfigKey);
       
//       var masterConfigurationApiResponselist:any[]=await this.initialMasterCallsConfigurationSetup(inputFeature.masterConfigurations);
   
   
//        console.log('generic-master end');
//        console.log('generic-child strat////');

//        console.log(inputFeature.masterConfigKey,'-generic-masterConfigurationApiResponselist',masterConfigurationApiResponselist);
//        //read the child configuration

//        var childConfigurationApiResponseList:any[]=await this.initialchildCallsConfiurationSetup(inputFeature,masterConfigurationApiResponselist);
       
//        console.log('..generic child end////-childConfigurationApiResponseList',childConfigurationApiResponseList)
       
//       inputFeature.ConfigApiresult=childConfigurationApiResponseList;
//       //VALIDATION
//       console.log('validate push');
//       console.log('inputFeature',inputFeature,'childConfigurationApiResponseList',childConfigurationApiResponseList)
//       ConfigApiJsonResponseList.configurations.push(inputFeature)
//       //newinputFeature.configurations.push(inputFeature)
//       //finalValidateJsonResponseList.push(finalValidateJsonResponse)

//       console.log('validate list pushed')

    
//       }
//       console.log('finalValidateJsonResponseList',ConfigApiJsonResponseList)
//       var uiResponse:ConfigValidationUIModel[]=await this.validateBothInputJsonAndApiResponse(ConfigApiJsonResponseList);


//     //console.log('----End new way full generic retail config,cc and gc-----------');
   
//     //let uiResponsee:any[]=[{id:'1',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'},{id:'2',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'}]

//   return uiResponse;
// }

// async initialMasterCallsConfigurationSetup(urlConfiguration:MasterConfiguration[]):Promise<any[]>
// {
//   //masterConfigDetails
//   //new implementation start

//   var masterConfigurationResultList:any[]=[]

//   let masterApiCallList: ApiCall[]=urlConfiguration.find(a=>a.apiCalls).apiCalls;

//   console.log('generic-initialMasterCallsConfigurationSetup-start',masterApiCallList)
//   var mastercallinc:number=1
//   const masterApiAllResult={
//     mastercallid:mastercallinc
    
//   }

//   var masterApiAllResultList:any[]=[]

//  for (let i=0;i<masterApiCallList.length;i++)
//   {
//     console.log('master Loop call starts')
//     mastercallinc=mastercallinc+1;
//     let masterApiCall: ApiCall=masterApiCallList[i];

//     console.log('master loop obj-',masterApiCall);

//     var masterConfigurationResult =  await this.configValidationService.getMasterUrlConfigurationAPICall(masterApiCall);
//     console.log('generic-masterConfigurationResult',masterConfigurationResult);
    

//     if(Array.isArray(masterConfigurationResult))
//       {
//         console.log('it is  an array');
//         masterApiAllResult['response']=masterConfigurationResult
//         masterConfigurationResultList=masterConfigurationResult
//         masterApiAllResultList.push(masterApiAllResult);

//       }
//      else{
//         console.log('it is not an array');
//         masterConfigurationResultList.push(masterConfigurationResult)
//         masterApiAllResult['response']=masterConfigurationResultList;
//         masterApiAllResultList.push(masterApiAllResult);
//       }
//   }


 
//   console.log('generic-initialMasterCallsConfigurationSetup End')

//   return masterApiAllResultList;
//   //new implemtntation end

// }


// async initialchildCallsConfiurationSetup(inputFeature: Configuration,masterConfigDetails:any[]):Promise<any[]>
// {


//     console.log('start- child configuration setup-initialchildCallsConfiurationSetup',inputFeature,masterConfigDetails)
//     // let ConsolidatedConfigAPIresultlist1:ConsolidatedConfigAPIresponses[]=[]
//     let childApiCallList: ApiCall2[]=inputFeature.childConfigurations.find(a=>a.apiCalls).apiCalls;
//   console.log('childApiCallList',childApiCallList)
//   let consolidatedApiUrlResult:any[]=[]
//   var childcallinc:number=1


  
//   var childApiAllResultList:any[]=[]
//   for (let i=0;i<childApiCallList.length;i++)
//     {
//       const childApiAllResult={
//         childcallid:childcallinc
        
//       }
      
//       var childApicallConfiguration:ApiCall2=childApiCallList[i];
//       childApiAllResult['isGroupedResult']=childApicallConfiguration.groupBy?true:false;
//       console.log('initiate child call for this childApicallConfiguration',childApicallConfiguration)
//       var filteredResult:any;
//       if(masterConfigDetails.length>0)
//       {
//         if(childApicallConfiguration?.masterDatasFilterby?.length>0)
//         {
//           for(let j=0;j<childApicallConfiguration?.masterDatasFilterby?.length;j++)
//           {
//             var inputmasterFilter:MasterDatasFilterby=childApicallConfiguration?.masterDatasFilterby[i];
//             console.log('inputmasterFilter',inputmasterFilter)
            
//             var masterApiresponsebyMasterCallId:any= masterConfigDetails.find(a=>a?.mastercallid==inputmasterFilter.masterApiCallId)['response']
//             console.log('masterApifilter',masterApiresponsebyMasterCallId);
//             if(inputmasterFilter.masterDataFilterBy.startsWith('#'))
//             {
//               var comparingkey=this.splitmasterkey(inputmasterFilter.masterDataFilterBy);
//               console.log('need to split',inputmasterFilter.masterDataFilterBy,'comparingkey',comparingkey)
//               var masterConfigapiDetails:any[]=masterApiresponsebyMasterCallId
//               var result=masterConfigapiDetails.find(a=>a[comparingkey]==inputFeature.masterConfigKey);
//               console.log('filtered-result',result)
//               filteredResult=result;


//             }

//           }
//         }
//         else{
//           var filteredResultlist:any[]= masterConfigDetails.find(a=>a?.mastercallid==1)['response']
//           filteredResult=filteredResultlist[0];

//         }
//       }


//       childcallinc=childcallinc+1;
//       console.log('final filteredResult',filteredResult)
//       var apiresult=await this.initialChildApiCallWithFilteredMaster(filteredResult,childApicallConfiguration,inputFeature.masterConfigKey)
//       //consolidatedApiUrlResult.push(apiresult)
//       childApiAllResult['response']=apiresult;
//       childApiAllResultList.push(childApiAllResult);


//     }


   
//     console.log('End- child configuration setup-initialchildCallsConfiurationSetup',inputFeature,masterConfigDetails)
//     console.log('childApiAllResultList',childApiAllResultList)
    
//     return childApiAllResultList;
   
// }


// async initialChildApiCallWithFilteredMaster(masterConfigDetail :any,inputFeature: ApiCall2,masterConfigKey:string):Promise<any[]>
// {
//   console.log('initialChildApiCallWithFilteredMaster-start','masterConfigDetail',masterConfigDetail,'inputFeature',inputFeature)


// var childConfigResponse1 = await this.configValidationService.getChildUrlConfigurationApiCall(masterConfigDetail,inputFeature);
// console.log('initialChildApiCallWithFilteredMaster-childConfigResponse1',childConfigResponse1);

// let childConfigDetailsApiResponse: any[]=[];
// let finalChildConfiguredApiResult:any[]=[]

// let newfinalChildConfiguredApiResult:any[]=[];


// if(Array.isArray(childConfigResponse1))
// {
//   childConfigDetailsApiResponse=childConfigResponse1;
// }
// else{
//   childConfigDetailsApiResponse.push(childConfigResponse1);
// }

// console.log('childConfigDetailsApiResponse.length',childConfigDetailsApiResponse.length)


// var resultformed:boolean=false;

// if(inputFeature.groupBy)
// {
//   console.log('groupby logic start')
//   console.log('inputFeature.groupBy',inputFeature.groupBy)
//   console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse);

//   //get the hostids by input parameter 
//   var grpbyuniqueIds:any[]=[]
//   let resultByHostListNew:any[]=[]

//   for(let grpvalue=0;grpvalue<inputFeature.groupBy.length;grpvalue++)
//   {

//     //console.log('childConfigDetailsApiResponse[g]',childConfigDetailsApiResponse[g])

//     var groupBykey=inputFeature.groupBy[grpvalue].groupBykey;
//     var groupingconfigKeyName=inputFeature.groupBy[grpvalue].groupingconfigKeyName;
//     var groupingconfigValueName=inputFeature.groupBy[grpvalue].groupingconfigValueName;
//     var groupingconfigKeyResult=inputFeature.groupBy[grpvalue].groupingconfigKeyResult;
//     var groupingconfigValueResult=inputFeature.groupBy[grpvalue].groupingconfigValueResult;

//     console.log('groupBykey',groupBykey,'groupingconfigKeyName',groupingconfigKeyName,'groupingconfigValueName',groupingconfigValueName,'groupingconfigKeyResult',groupingconfigKeyResult,'groupingconfigValueResult',groupingconfigValueResult)
//     // console.log('childConfigDetailsApiResponse[g][groupingconfigKeyName]',childConfigDetailsApiResponse[g][groupingconfigKeyName])
//     // console.log('childConfigDetailsApiResponse[g][groupingconfigKeyName]',childConfigDetailsApiResponse[g][groupingconfigValueName])

//     var grpByUniqueList:any[]= childConfigDetailsApiResponse.filter(p=>p[groupingconfigKeyName]==groupingconfigKeyResult && p[groupingconfigValueName]==groupingconfigValueResult)
//     console.log('grpByUniqueList',grpByUniqueList)

//     var grpbyuniqueIdsNew:any[]= grpByUniqueList.map(a=>{return a.hostId})

//     console.log('grpbyuniqueIdsNew',grpbyuniqueIdsNew)
//     //let resultByHostListNew:any[]=[]


//     if(grpbyuniqueIdsNew.length)
//     {
//         const newResultByHost={
//           groupId:inputFeature.groupBy[grpvalue].groupById,
//             groupName:groupingconfigValueResult,
//             groupResponse:[]

//         }
//         for(let r=0;r<grpbyuniqueIdsNew.length;r++)
//         {
          
//           const resultByHost={ 
//             CommonName:grpbyuniqueIdsNew[r],
//             groupedResponse:[]
//           }
  
//           let groupedRes=childConfigDetailsApiResponse.filter(a=>a[groupBykey]==grpbyuniqueIdsNew[r]);
//           console.log('groupedRes',groupedRes);
//           resultByHost.groupedResponse=groupedRes;
//           newResultByHost.groupResponse.push(resultByHost)
  
//         }
//         resultByHostListNew.push(newResultByHost)

//     }

//       console.log('resultByHostListNew',resultByHostListNew)


//     // for(let f=0;f<resultByHostListNew.length;f++)
//     //   {
//     //     finalChildConfiguredApiResult.push(resultByHostListNew[f])
//     //   }

//     //   console.log('new groupby-grpby finalChildConfiguredApiResult',finalChildConfiguredApiResult)
//   }

//   for(let f=0;f<resultByHostListNew.length;f++)
//     {
//       finalChildConfiguredApiResult.push(resultByHostListNew[f])
//     }

//     console.log('new groupby-grpby finalChildConfiguredApiResult',finalChildConfiguredApiResult)

  

//       const newRes={
//         'childresponseFilterId':1,
//         'response':finalChildConfiguredApiResult
//       }

//       newfinalChildConfiguredApiResult.push(newRes)

//       resultformed=true;

    

//   console.log('groupby logic end')


// }

// if(inputFeature.configResultFrom &&inputFeature.groupBy===null)
//   {
//     resultformed=true;
//     var childconfigFilterBy=inputFeature.childApiDataFilterby;
//     if(inputFeature.childApiDataFilterby.startsWith('*') && inputFeature.possibleFilterIds.length>0)
//     {
//       console.log('inputFeature.possibleFilterIds',inputFeature.possibleFilterIds)
//       for(let posfilter=0;posfilter<inputFeature.possibleFilterIds.length;posfilter++)
//       {
//         console.log('inputFeature.possibleFilterIds[posfilter]',inputFeature.possibleFilterIds[posfilter])
//         var comparingkey=this.splitmasterkey(inputFeature.childApiDataFilterby);
//         console.log('*comparingkey',comparingkey)
//         var childresult:any=childConfigDetailsApiResponse.find(a=>a[comparingkey]===inputFeature.possibleFilterIds[posfilter].possibleKey)
        
//         console.log('*comparingkey',comparingkey,'*after filter childresult',childresult)
//         if(childresult)
//         {

//           var finalresult:any[]=childresult[inputFeature.configResultFrom]
//           console.log('after filter final childresult',childresult)
        
//           const newRes={
//             'childresponseFilterId':inputFeature.possibleFilterIds[posfilter].id,
//             'response':finalresult
//           }
//           console.log('newRes',newRes)
//           newfinalChildConfiguredApiResult.push(newRes);

//         }

//       }

//        console.log('**newfinalChildConfiguredApiResult',newfinalChildConfiguredApiResult)
//     }

//     if(inputFeature.childApiDataFilterby.startsWith('#')&&inputFeature.possibleFilterIds.length<=0)
//       {
//         var comparingkey=this.splitmasterkey(inputFeature.childApiDataFilterby);
//         console.log('#comparingkey',comparingkey)

//         var childresult:any=childConfigDetailsApiResponse.find(a=>a[comparingkey]===masterConfigKey)
//         console.log('comparingkey',comparingkey,'after filter childresult',childresult)
//         if(childresult)
//         {
//         var finalresult:any[]=childresult[inputFeature.configResultFrom]
//         console.log('after filter final childresult',childresult)
        
//         const newRes={
//           'childresponseFilterId':1,
//           'response':finalresult
//         }
//         console.log('newRes',newRes)
//         newfinalChildConfiguredApiResult.push(newRes);

//         finalChildConfiguredApiResult=finalresult
//         }

//         // var masterConfigapiDetails:any[]=masterApiresponsebyMasterCallId
//         // var result=masterConfigapiDetails.find(a=>a[comparingkey]==inputFeature.masterConfigKey);
//         // console.log('filtered-result',result)
//         // filteredResult=result;
//       }

//       if(childconfigFilterBy=="")
//         {

//           if(Array.isArray(childConfigDetailsApiResponse[0][inputFeature.configResultFrom]))
//           {

//               finalChildConfiguredApiResult=childConfigDetailsApiResponse[0][inputFeature.configResultFrom];
//           }
//           else
//           {
//               finalChildConfiguredApiResult.push(childConfigDetailsApiResponse[0][inputFeature.configResultFrom]);
//           }

//           const newRes={
//             'childresponseFilterId':1,
//             'response':finalChildConfiguredApiResult
//           }
//           console.log('newRes',newRes)
//           newfinalChildConfiguredApiResult.push(newRes)

//           //finalChildConfiguredApiResult=childConfigDetailsApiResponse[0][inputFeature.configResultFrom];
//           console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse[0][inputFeature.configResultFrom])

//         }
//   }
//   // else
//   // {
//   //   finalChildConfiguredApiResult=childConfigDetailsApiResponse;

//   // }
//   if(!resultformed )
//     {
//       const newRes={
//         'childresponseFilterId':1,
//         'response':childConfigDetailsApiResponse
//       }
//       console.log('newRes',newRes)
//       newfinalChildConfiguredApiResult.push(newRes);

//       finalChildConfiguredApiResult=childConfigDetailsApiResponse;
//     }

// console.log('newfinalChildConfiguredApiResult',newfinalChildConfiguredApiResult)
// console.log('initialChildApiCallWithFilteredMaster-finalChildConfiguredApiResult',finalChildConfiguredApiResult);
  
// return newfinalChildConfiguredApiResult;

// }


// async validateBothInputJsonAndApiResponse(finalValidateJsonResponse: InputValidationConfigJsonApiModel):Promise<ConfigValidationUIModel[]>
// {
//   console.log('ready for validation')
//   console.log('finalValidateJsonResponse',finalValidateJsonResponse)
//   let finalUIResult:ConfigValidationUIModel[]=[];
//   var uimodelnumber:number=1
//   for(let i=0 ; i<finalValidateJsonResponse['configurations'].length;i++)
//   {
    
//     var inputFeature:Configuration=finalValidateJsonResponse['configurations'][i];
//     var apiResult:any[]=inputFeature.ConfigApiresult;
//     console.log('apiResult',apiResult)
//     console.log('apiResult.find(a=>a.isGroupedResult).isGroupedResult',apiResult[0].isGroupedResult)
//       //need to monitor the logic
//     var isGroupedResultvalue=apiResult[0].isGroupedResult
//     //need to monitor the logic
//     console.log('isGroupedResult',isGroupedResultvalue)
   
//     console.log('validation starts for-index',i,inputFeature);
//     console.log('apiResult',apiResult);
//     var legitimateconfigsList:LegitiamteConfiguration[]=inputFeature.legitiamteConfiguration
//     console.log('legitimateconfigsList',legitimateconfigsList)
//     var Filterids=inputFeature.childConfigurations[0].apiCalls.find(a=>a.apicallId===1).possibleFilterIds
//     var plzdontProceed=false;
//     if(apiResult?.length==0  &&inputFeature.isFilterMandatory)
//     //if(inputFeature.isFilterMandatory==true)
//     {
//       console.log('Filterids',Filterids)
//       console.log('plz dont proceed')
//       plzdontProceed=true;
//       let nouiModel: ConfigValidationUIModel={
//         id: uimodelnumber,
//         config:inputFeature.masterConfigKey,
//         configkey: 'Nil',
//         reason: 'nothing configured atleast configure one of them',
//         status: 'Validation failed.!'
//       }
//       finalUIResult.push(nouiModel)
      
        
//     }

//     var morethanonegrp:boolean=false;
//     if(apiResult?.length==0  &&inputFeature.isGroupByMandatory)
//     {
//       console.log('isGroupByMandatory validation start')
//       var grpResponse:any[]=apiResult[0].response;
//       console.log('grpResponse',grpResponse)
//       var dbgrpReult:any[]=grpResponse[0].response;
//       console.log('dbgrpReult',dbgrpReult)

//       if(dbgrpReult?.length)
//       {
//         morethanonegrp=true;
//       }

//       console.log('morethanonegrp')
      
//       let nouiModel: ConfigValidationUIModel={
//         id: uimodelnumber,
//         config:inputFeature.masterConfigKey,
//         configkey: 'Nil',
//         reason: 'multiple host configured maintain one host',
//         status: 'Validation failed.!'
//       }
//       finalUIResult.push(nouiModel)
//       console.log('isGroupByMandatory validation end')



//     }
    


    
//     if(!plzdontProceed && !morethanonegrp)
//     {

//       if(isGroupedResultvalue)
//       {

//         var grpResponse:any[]=apiResult[0].response;
//         console.log('grpResponse',grpResponse)
//         var dbgrpReult:any[]=grpResponse[0].response;
//         console.log('dbgrpReult',dbgrpReult)
//         var grpId=dbgrpReult[0].groupId;
//         console.log('grpId',grpId)
//         var groupname=dbgrpReult[0].groupName
        
//         var legitimateconfigsListbyGrpId=legitimateconfigsList.filter(a=>a.childresponseFilterId==grpId)
//         console.log('legitimateconfigsListbyGrpId',legitimateconfigsListbyGrpId)
        
//         for(let p=0 ;p<legitimateconfigsListbyGrpId.length;p++)
//         {
//           var legitimateconfigs:LegitiamteConfiguration=legitimateconfigsListbyGrpId[p];
//           console.log('legitimateconfigsList[lc]',legitimateconfigs)
//           //
        

//           //
//           var validationConfigList:ValidationConfig[]=legitimateconfigs.validationConfig;
//           console.log('validationConfigList',validationConfigList);
//           //var finalvalidatevaluevalues:any[]=[]
//           var needtoValidateList:any[]=[]
//           var comparingkey:Comparewithchildren=legitimateconfigs.comparewithchildren[0];

//           var dbresultList:any[]= apiResult.find(a=>a.childcallid==comparingkey.childrenApiCallId)['response']

//           console.log('dbresultList',dbresultList,dbresultList.length)
//           console.log('dbresultList[ar]',dbresultList[0].response)
//           console.log('dbresultList[0].response[0].groupResponse',dbresultList[0].response[0].groupResponse)

//           for(let ar=0; ar<dbresultList[0].response[0].groupResponse.length;ar++)
//             {
//               var inputobjectkey=comparingkey.childrenDataFilterBy;
//               //var configsWithsamekey:any[]= dbresultList[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)
//               if(dbresultList[0].response.length>0)
//               {
//                 //var configsWithsamekey:any[]= dbresultList[0].response[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)
//                 var configsWithsamekey:any[]= dbresultList[0].response[0].groupResponse[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)

//                 console.log('configsWithsamekey',configsWithsamekey)
//                 const needtoValidate={
//                   groupedCommonname:dbresultList[0].response[0].groupResponse[ar].CommonName
                  
//                 }

//                 needtoValidate['groupedConfigs']=configsWithsamekey;
//                 needtoValidateList.push(needtoValidate)
//                 console.log('needtoValidate',needtoValidate);
//               }
              
              
              
//             }

//           console.log('needtoValidateList',needtoValidateList)

//           if(needtoValidateList.length)
//           {

//             for(let nv=0;nv<needtoValidateList.length;nv++)
//             {
//               var finalvalidatevaluevalues:any[]=[]
//               let uiModel: ConfigValidationUIModel={
//                 id: uimodelnumber,
//                 config: groupname + '  |  '+ inputFeature.masterConfigKey+'-'+needtoValidateList[nv].groupedCommonname,
//                 configkey: '',
//                 reason: 'Cool..! Looks Fine',
//                 status: 'validated successfully'
//               }

//               uiModel.configkey=legitimateconfigs.configurationName;

//               console.log('needtoValidateList[nv].groupedConfigs',needtoValidateList[nv].groupedConfigs)
//               var dbresult=needtoValidateList[nv].groupedConfigs
//               var dbvaluekeyinput=validationConfigList[0].ValuekeyFrom
//               var dbvalue=dbresult[dbvaluekeyinput].toString()

//               finalvalidatevaluevalues.push(dbvalue)

//               for(let y=0;y<validationConfigList.length;y++)
//                 {
//                   console.log('finalvalidatevaluevalues',finalvalidatevaluevalues)
//                   var expectedvalue:string[]=validationConfigList[y].expectedValue;
//                   //--console.log('a=>a==expectedvalue',finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                   console.log('a=>a==expectedvalue',finalvalidatevaluevalues.includes)
          
//                   var errorcount=0;
//                   if(finalvalidatevaluevalues.length>0)
//                   {
//                     var realEntryFromDb=finalvalidatevaluevalues[0];
//                     //var inputValue=expectedvalue.find(a=>a===realEntryFromDb)
          
          
//                     //--if(!finalvalidatevaluevalues.find(a=>a.toLowerCase()==expectedvalue.toLowerCase()))
//                       if(!expectedvalue.find(a=>a.toLowerCase()===realEntryFromDb.toLowerCase()))
//                       {
//                         errorcount=errorcount+1;
//                         uiModel.reason='not an expected value there!'
//                         uiModel.status='Validation Failed'
//                         console.log('not an expected value there!')
//                       }
//                     //case sensitive check 
//                     console.log('expected value pass')
                    
//                     if(errorcount==0)  
//                     {
//                       console.log('case sensitive start')
//                       //--if(!finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                         if(!expectedvalue.includes(realEntryFromDb))
//                         {
//                           errorcount=errorcount+1;
//                           uiModel.reason='case sensitive failed'
//                         uiModel.status='Validation Failed'
//                           console.log('case sensitive failed')
//                         }
//                       //var IsCorrectCaseSensitive:boolean= await this.CaseSensitiveCheck(finalvalidatevaluevalues,expectedvalue)
                      
//                     }
          
          
//                     if(errorcount==0)  
//                     {
//                       console.log('datatype check start')
//                       console.log('typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue))',typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue)),'validationConfigList[y].dataType',validationConfigList[y].dataType)
//                       if(typeof(realEntryFromDb)===validationConfigList[y].dataType==false)
//                       {
//                           errorcount=errorcount+1;
//                           uiModel.reason='datatype check failed.!'
//                           uiModel.status='Validation Failed'
//                           console.log('datatype check failed.!')
//                       }
                      
//                     }
          
//                     if(errorcount==0 && validationConfigList[y]?.urlConfig!=null)
//                     {
//                       console.log('url hit check start')
//                       const urlHitPaylod={
//                       url: validationConfigList[y]?.urlConfig.endpoint,
//                       method: "Get"}
                  
//                       // url: "https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService/V1/heartbeat",
          
//                       var urlhitResponse=await this.configValidationService.UrlUpCheck(urlHitPaylod);
//                       if(!urlhitResponse)
//                       {
//                             errorcount=errorcount+1;
//                             uiModel.reason='response not found for this URL';
//                             uiModel.status='Validation Failed';
//                             console.log('url check failed.!');
//                       }
                        
//                     }
          
//                   }
//                   else
//                   {
//                     uiModel.reason='not found from api'
//                     uiModel.status='Validation Failed'
//                     console.log('not found from api')
//                   }
//                   //expected value check
                
          
//                 }

//                 finalUIResult.push(uiModel)
//             uimodelnumber=uimodelnumber+1;
//             }
//           } 

          

//           console.log('legitimateconfigs',legitimateconfigs)
  

//         }

//       }
//       else
//       {

//         for(let lc=0 ;lc<legitimateconfigsList.length;lc++)
//           {
//             let uiModel: ConfigValidationUIModel={
//               id: uimodelnumber,
//               config:inputFeature.subMasterConfigKey!=null &&inputFeature.subMasterConfigKey!=""?inputFeature.masterConfigKey+'-'+inputFeature.subMasterConfigKey: inputFeature.masterConfigKey,
//               configkey: '',
//               reason: 'Cool..! Looks Fine',
//               status: 'validated successfully'
//             }

            

//             //var nothingConfigured=false;
            
            


//             var legitimateconfigs:LegitiamteConfiguration=legitimateconfigsList[lc];
//             //uimodel configkeyset
//             console.log('legitimateconfigsList[lc]',legitimateconfigs)
//             uiModel.configkey=legitimateconfigs.configurationName;
//             console.log('ui specifier start ')
//             var ResultMadeFor=''
//             var ispossiblefilteridsPresent=false
//             for(let p=0;p<inputFeature.childConfigurations.length;p++)
//             {
//               if(inputFeature.childConfigurations[0].apiCalls.find(a=>a.apicallId===legitimateconfigs.comparewithchildren[0].childrenApiCallId))
//               {
//                 var posFilterids=inputFeature.childConfigurations[0].apiCalls.find(a=>a.apicallId===legitimateconfigs.comparewithchildren[0].childrenApiCallId).possibleFilterIds
//                 console.log('posFilterids',posFilterids)
//                 if(posFilterids.length>0)
//                   {
//                     ispossiblefilteridsPresent=true;
//                     ResultMadeFor=posFilterids.find(pos=>pos.id==legitimateconfigs.childresponseFilterId).possibleKey
//                   console.log('ResultMadeFor',ResultMadeFor)
//                   uiModel.config=inputFeature.masterConfigKey+'-'+ResultMadeFor
//                   }
//               }
//             }
//             //console.log('filteridsList',inputFeature.childConfigurations.find(a=>a.apiCalls.find(r=>r.apicallId===legitimateconfigs.comparewithchildren[0].childrenApiCallId).possibleFilterIds))
//             console.log('ui specifier end ')
//             var validationConfigList:ValidationConfig[]=legitimateconfigs.validationConfig;
//             console.log('validationConfigList',validationConfigList);
//             var finalvalidatevaluevalues:any[]=[]
//             var noneedtoLoop=false
            
            
//             if(legitimateconfigs.comparewithchildren)
//             {
//                 for (let k=0;k<legitimateconfigs.comparewithchildren.length;k++)
//                 {
//                   var comparingkey:Comparewithchildren=legitimateconfigs.comparewithchildren[k];
//                   var inputobjectkey=comparingkey.childrenDataFilterBy;
//                   var dbresultList:any[]= apiResult.find(a=>a.childcallid==comparingkey.childrenApiCallId)['response']
//                   console.log('inputobjectkey',inputobjectkey,'dbresultList',dbresultList,'dbresultList[0]',dbresultList[0])
//                   // if(dbresultList.length==0 && ispossiblefilteridsPresent)
//                   // {
//                   //   nothingConfigured=true;
//                   //   uiModel.reason='nothing configured atleast configure on them'
                    
//                   // }
                  
//                   //get the result based on childresponsefilterid
//                   var matcheddbresult:any[]=dbresultList?.filter(r=>r.childresponseFilterId==legitimateconfigs.childresponseFilterId)
//                   console.log('matcheddbresult',matcheddbresult)
//                   if(matcheddbresult?.length<=0)
//                     {
//                       noneedtoLoop=true
//                     }
//                   if(dbresultList.length)
//                   {
//                       if(inputobjectkey=="")
//                         {
//                           var dbvaluekeyinput=validationConfigList[0].ValuekeyFrom
//                           //var dbvalue=dbresultList[0][dbvaluekeyinput].toString()
//                           //if(matcheddbresult[0]['response'].length>0)
//                           var dbvalue=  matcheddbresult[0]['response'][0][dbvaluekeyinput].toString()

//                         }
//                         else{
//                           //var dbresult=dbresultList.find(d=>d[inputobjectkey]===legitimateconfigs.configurationName);
//                         if(matcheddbresult.length>0)
//                           {
//                             if(matcheddbresult[0]['response'].length>0)
//                               {
//                           var dbresult=matcheddbresult[0]['response']?.find(d=>d[inputobjectkey]===legitimateconfigs.configurationName);
//                           var dbvaluekeyinput=validationConfigList[0].ValuekeyFrom
//                           var dbvalue=dbresult[dbvaluekeyinput].toString()
//                               }
//                           }
      
//                         }
                      
//                       console.log('dbresult',dbresult,'dbvaluekeyinput',dbvaluekeyinput,'dbvalue',dbvalue)
//                       if(dbvalue)
//                         {
//                       finalvalidatevaluevalues.push(dbvalue)
//                         }
//                       console.log('finally',legitimateconfigs.configurationName,dbresultList.find(d=>d[inputobjectkey]===legitimateconfigs.configurationName))
                        
      
//                   }
                  
                  
                
//                 }
//               }
      
      
//             console.log('legitimateconfigs',legitimateconfigs)


//             if(!noneedtoLoop)
//               {
//             for(let y=0;y<validationConfigList.length;y++)
//             {
//               console.log('finalvalidatevaluevalues',finalvalidatevaluevalues)
//               var expectedvalue:string[]=validationConfigList[y].expectedValue;
//               //--console.log('a=>a==expectedvalue',finalvalidatevaluevalues.find(a=>a==expectedvalue))
//               console.log('a=>a==expectedvalue',finalvalidatevaluevalues.includes)
      
//               var errorcount=0;
//               if(finalvalidatevaluevalues.length>0)
//               {
//                 var realEntryFromDb=finalvalidatevaluevalues[0];
//                 //var inputValue=expectedvalue.find(a=>a===realEntryFromDb)
      
      
//                 //--if(!finalvalidatevaluevalues.find(a=>a.toLowerCase()==expectedvalue.toLowerCase()))
//                   if(!expectedvalue.find(a=>a.toLowerCase()===realEntryFromDb.toLowerCase()))
//                   {
//                     errorcount=errorcount+1;
//                     uiModel.reason='not an expected value there!'
//                     uiModel.status='Validation Failed'
//                     console.log('not an expected value there!')
//                   }
//                 //case sensitive check 
//                 console.log('expected value pass')
                
//                 if(errorcount==0)  
//                 {
//                   console.log('case sensitive start')
//                   //--if(!finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                     if(!expectedvalue.includes(realEntryFromDb))
//                     {
//                       errorcount=errorcount+1;
//                       uiModel.reason='case sensitive failed'
//                     uiModel.status='Validation Failed'
//                       console.log('case sensitive failed')
//                     }
//                   //var IsCorrectCaseSensitive:boolean= await this.CaseSensitiveCheck(finalvalidatevaluevalues,expectedvalue)
                  
//                 }
      
      
//                 if(errorcount==0)  
//                 {
//                   console.log('datatype check start')
//                   console.log('typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue))',typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue)),'validationConfigList[y].dataType',validationConfigList[y].dataType)
//                   if(typeof(realEntryFromDb)===validationConfigList[y].dataType==false)
//                   {
//                       errorcount=errorcount+1;
//                       uiModel.reason='datatype check failed.!'
//                       uiModel.status='Validation Failed'
//                       console.log('datatype check failed.!')
//                   }
                  
//                 }
      
//                 if(errorcount==0 && validationConfigList[y]?.urlConfig!=null)
//                 {
//                   console.log('url hit check start')
//                   const urlHitPaylod={
//                   url: validationConfigList[y]?.urlConfig.endpoint,
//                   method: "Get"}
              
//       //            url: "https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService/V1/heartbeat",
      
//                   var urlhitResponse=await this.configValidationService.UrlUpCheck(urlHitPaylod);
//                   if(!urlhitResponse)
//                   {
//                         errorcount=errorcount+1;
//                         uiModel.reason='response not found for this URL';
//                         uiModel.status='Validation Failed';
//                         console.log('url check failed.!');
//                   }
                    
//                 }
      
//               }
//               else
//               {
//                 uiModel.reason='not found from api'
//                 uiModel.status='Validation Failed'
//                 console.log('not found from api')
//               }
//               //expected value check
            
      
//             }
//             finalUIResult.push(uiModel)
//             uimodelnumber=uimodelnumber+1;
//           }
      
//           }
//       }
//     }

  



//     console.log('validation end for-index',i,inputFeature);

   

//   }

  

//   console.log('validation end')




// console.log('finalUIResult',finalUIResult)

// //urlcheck
// //this.urlCheck('https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService');
// //url check

//   return finalUIResult;

// }

//olddddddddddddddddddddddddddddddddddddddddddddddd
// async getValidatedRetailConfigurationUIResult(): Promise<ConfigValidationUIModel[]> {
//   this.utils.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
//   //master json input from api

//   console.log('----Start new way full generic retail config,cc and gc-----------')
  
//   //get input json
//   var response =  await this.configValidationService.getValidationJson();
//   console.log('generic-response-configuration',response['configurations']);
//   let ValidateJsonResponse: InputValidationConfigJsonApiModel[]=response;
//   console.log('generic-ValidateJsonResponse',ValidateJsonResponse);

//   //configuration with Final results
 
//   let ConfigApiJsonResponseList: InputValidationConfigJsonApiModel={
//     product: ValidateJsonResponse['product'],
//     configurations: []
//   };


//   for(let i=0 ; i<ValidateJsonResponse['configurations'].length;i++)
//     {
//       let newinputFeature:Configuration={
//         configKey: '',
//         masterconfigurationsSetup: undefined,
//         configUrl: [],
//         configValidate: [],
//         heartbeat: '',
//         dependantServices: [],
//         Configvalidatedapiresult: [],
//         masterConfigKey: '',
//         masterConfigurations: [],
//         childConfigurations: [],
//         legitiamteConfiguration: [],
//         ConfigApiresult: [],
//         subMasterConfigKey: '',
//         isFilterMandatory: false,
//         isGroupByMandatory: false
//       }

//      var inputFeature:Configuration=ValidateJsonResponse['configurations'][i];
//        console.log('genric-for-loop',inputFeature);
//        console.log('configkey',inputFeature.masterConfigKey);
       
//       var masterConfigurationApiResponselist:any[]=await this.initialMasterCallsConfigurationSetup(inputFeature.masterConfigurations);
   
   
//        console.log('generic-master end');
//        console.log('generic-child strat////');

//        console.log(inputFeature.masterConfigKey,'-generic-masterConfigurationApiResponselist',masterConfigurationApiResponselist);
//        //read the child configuration

//        var childConfigurationApiResponseList:any[]=await this.initialchildCallsConfiurationSetup(inputFeature,masterConfigurationApiResponselist);
       
//        console.log('..generic child end////-childConfigurationApiResponseList',childConfigurationApiResponseList)
       
//       inputFeature.ConfigApiresult=childConfigurationApiResponseList;
//       //VALIDATION
//       console.log('validate push');
//       console.log('inputFeature',inputFeature,'childConfigurationApiResponseList',childConfigurationApiResponseList)
//       ConfigApiJsonResponseList.configurations.push(inputFeature)
//       //newinputFeature.configurations.push(inputFeature)
//       //finalValidateJsonResponseList.push(finalValidateJsonResponse)

//       console.log('validate list pushed')

    
//       }
//       console.log('finalValidateJsonResponseList',ConfigApiJsonResponseList)
//       var uiResponse:ConfigValidationUIModel[]=await this.validateBothInputJsonAndApiResponse(ConfigApiJsonResponseList);


//     //console.log('----End new way full generic retail config,cc and gc-----------');
   
//     //let uiResponsee:any[]=[{id:'1',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'},{id:'2',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'}]

//   return uiResponse;
// }

// async initialMasterCallsConfigurationSetup(urlConfiguration:MasterConfiguration[]):Promise<any[]>
// {
//   //masterConfigDetails
//   //new implementation start

//   var masterConfigurationResultList:any[]=[]

//   let masterApiCallList: ApiCall[]=urlConfiguration.find(a=>a.apiCalls).apiCalls;

//   console.log('generic-initialMasterCallsConfigurationSetup-start',masterApiCallList)
//   var mastercallinc:number=1
//   const masterApiAllResult={
//     mastercallid:mastercallinc
    
//   }

//   var masterApiAllResultList:any[]=[]

//  for (let i=0;i<masterApiCallList.length;i++)
//   {
//     console.log('master Loop call starts')
//     mastercallinc=mastercallinc+1;
//     let masterApiCall: ApiCall=masterApiCallList[i];

//     console.log('master loop obj-',masterApiCall);

//     var masterConfigurationResult =  await this.configValidationService.getMasterUrlConfigurationAPICall(masterApiCall);
//     console.log('generic-masterConfigurationResult',masterConfigurationResult);
    

//     if(Array.isArray(masterConfigurationResult))
//       {
//         console.log('it is  an array');
//         masterApiAllResult['response']=masterConfigurationResult
//         masterConfigurationResultList=masterConfigurationResult
//         masterApiAllResultList.push(masterApiAllResult);

//       }
//      else{
//         console.log('it is not an array');
//         masterConfigurationResultList.push(masterConfigurationResult)
//         masterApiAllResult['response']=masterConfigurationResultList;
//         masterApiAllResultList.push(masterApiAllResult);
//       }
//   }


 
//   console.log('generic-initialMasterCallsConfigurationSetup End')

//   return masterApiAllResultList;
//   //new implemtntation end

// }


// async initialchildCallsConfiurationSetup(inputFeature: Configuration,masterConfigDetails:any[]):Promise<any[]>
// {


//     console.log('start- child configuration setup-initialchildCallsConfiurationSetup',inputFeature,masterConfigDetails)
//     // let ConsolidatedConfigAPIresultlist1:ConsolidatedConfigAPIresponses[]=[]
//     let childApiCallList: ApiCall2[]=inputFeature.childConfigurations.find(a=>a.apiCalls).apiCalls;
//   console.log('childApiCallList',childApiCallList)
//   let consolidatedApiUrlResult:any[]=[]
//   var childcallinc:number=1


  
//   var childApiAllResultList:any[]=[]
//   for (let i=0;i<childApiCallList.length;i++)
//     {
//       const childApiAllResult={
//         childcallid:childcallinc
        
//       }
      
//       var childApicallConfiguration:ApiCall2=childApiCallList[i];
//       childApiAllResult['isGroupedResult']=childApicallConfiguration.groupBy?true:false;
//       console.log('initiate child call for this childApicallConfiguration',childApicallConfiguration)
//       var filteredResult:any;
//       if(masterConfigDetails.length>0)
//       {
//         if(childApicallConfiguration?.masterDatasFilterby?.length>0)
//         {
//           for(let j=0;j<childApicallConfiguration?.masterDatasFilterby?.length;j++)
//           {
//             var inputmasterFilter:MasterDatasFilterby=childApicallConfiguration?.masterDatasFilterby[i];
//             console.log('inputmasterFilter',inputmasterFilter)
            
//             var masterApiresponsebyMasterCallId:any= masterConfigDetails.find(a=>a?.mastercallid==inputmasterFilter.masterApiCallId)['response']
//             console.log('masterApifilter',masterApiresponsebyMasterCallId);
//             if(inputmasterFilter.masterDataFilterBy.startsWith('#'))
//             {
//               var comparingkey=this.splitmasterkey(inputmasterFilter.masterDataFilterBy);
//               console.log('need to split',inputmasterFilter.masterDataFilterBy,'comparingkey',comparingkey)
//               var masterConfigapiDetails:any[]=masterApiresponsebyMasterCallId
//               var result=masterConfigapiDetails.find(a=>a[comparingkey]==inputFeature.masterConfigKey);
//               console.log('filtered-result',result)
//               filteredResult=result;


//             }

//           }
//         }
//         else{
//           var filteredResultlist:any[]= masterConfigDetails.find(a=>a?.mastercallid==1)['response']
//           filteredResult=filteredResultlist[0];

//         }
//       }


//       childcallinc=childcallinc+1;
//       console.log('final filteredResult',filteredResult)
//       var apiresult=await this.initialChildApiCallWithFilteredMaster(filteredResult,childApicallConfiguration,inputFeature.masterConfigKey)
//       //consolidatedApiUrlResult.push(apiresult)
//       childApiAllResult['response']=apiresult;
//       childApiAllResultList.push(childApiAllResult);


//     }


   
//     console.log('End- child configuration setup-initialchildCallsConfiurationSetup',inputFeature,masterConfigDetails)
//     console.log('childApiAllResultList',childApiAllResultList)
    
//     return childApiAllResultList;
   
// }


// async initialChildApiCallWithFilteredMaster(masterConfigDetail :any,inputFeature: ApiCall2,masterConfigKey:string):Promise<any[]>
// {
//   console.log('initialChildApiCallWithFilteredMaster-start','masterConfigDetail',masterConfigDetail,'inputFeature',inputFeature)


// var childConfigResponse1 = await this.configValidationService.getChildUrlConfigurationApiCall(masterConfigDetail,inputFeature);
// console.log('initialChildApiCallWithFilteredMaster-childConfigResponse1',childConfigResponse1);

// let childConfigDetailsApiResponse: any[]=[];
// let finalChildConfiguredApiResult:any[]=[]

// let newfinalChildConfiguredApiResult:any[]=[];


// if(childConfigResponse1!=null && Array.isArray(childConfigResponse1))
// {
//   childConfigDetailsApiResponse=childConfigResponse1;
// }
// else{
//   if(childConfigResponse1!=null)
//   childConfigDetailsApiResponse.push(childConfigResponse1);
// }

// console.log('childConfigDetailsApiResponse.length',childConfigDetailsApiResponse.length,childConfigDetailsApiResponse)


// var resultformed:boolean=false;

// if(childConfigDetailsApiResponse?.length>0)
//   {

// if(inputFeature.groupBy)
// {
//   console.log('groupby logic start')
//   console.log('inputFeature.groupBy',inputFeature.groupBy)
//   console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse);

//   //get the hostids by input parameter 
//   var grpbyuniqueIds:any[]=[]
//   let resultByHostListNew:any[]=[]

//   for(let grpvalue=0;grpvalue<inputFeature.groupBy.length;grpvalue++)
//   {

//     //console.log('childConfigDetailsApiResponse[g]',childConfigDetailsApiResponse[g])

//     var groupBykey=inputFeature.groupBy[grpvalue].groupBykey;
//     var groupingconfigKeyName=inputFeature.groupBy[grpvalue].groupingconfigKeyName;
//     var groupingconfigValueName=inputFeature.groupBy[grpvalue].groupingconfigValueName;
//     var groupingconfigKeyResult=inputFeature.groupBy[grpvalue].groupingconfigKeyResult;
//     var groupingconfigValueResult=inputFeature.groupBy[grpvalue].groupingconfigValueResult;

//     console.log('groupBykey',groupBykey,'groupingconfigKeyName',groupingconfigKeyName,'groupingconfigValueName',groupingconfigValueName,'groupingconfigKeyResult',groupingconfigKeyResult,'groupingconfigValueResult',groupingconfigValueResult)
//     // console.log('childConfigDetailsApiResponse[g][groupingconfigKeyName]',childConfigDetailsApiResponse[g][groupingconfigKeyName])
//     // console.log('childConfigDetailsApiResponse[g][groupingconfigKeyName]',childConfigDetailsApiResponse[g][groupingconfigValueName])

//     var grpByUniqueList:any[]= childConfigDetailsApiResponse.filter(p=>p[groupingconfigKeyName]==groupingconfigKeyResult && p[groupingconfigValueName]==groupingconfigValueResult)
//     console.log('grpByUniqueList',grpByUniqueList)

//     var grpbyuniqueIdsNew:any[]= grpByUniqueList.map(a=>{return a.hostId})

//     console.log('grpbyuniqueIdsNew',grpbyuniqueIdsNew)
//     //let resultByHostListNew:any[]=[]


//     if(grpbyuniqueIdsNew.length)
//     {
//         const newResultByHost={
//           groupId:inputFeature.groupBy[grpvalue].groupById,
//             groupName:groupingconfigValueResult,
//             groupResponse:[]

//         }
//         for(let r=0;r<grpbyuniqueIdsNew.length;r++)
//         {
          
//           const resultByHost={ 
//             CommonName:grpbyuniqueIdsNew[r],
//             groupedResponse:[]
//           }
  
//           let groupedRes=childConfigDetailsApiResponse.filter(a=>a[groupBykey]==grpbyuniqueIdsNew[r]);
//           console.log('groupedRes',groupedRes);
//           resultByHost.groupedResponse=groupedRes;
//           newResultByHost.groupResponse.push(resultByHost)
  
//         }
//         resultByHostListNew.push(newResultByHost)

//     }

//       console.log('resultByHostListNew',resultByHostListNew)


//     // for(let f=0;f<resultByHostListNew.length;f++)
//     //   {
//     //     finalChildConfiguredApiResult.push(resultByHostListNew[f])
//     //   }

//     //   console.log('new groupby-grpby finalChildConfiguredApiResult',finalChildConfiguredApiResult)
//   }

//   for(let f=0;f<resultByHostListNew.length;f++)
//     {
//       finalChildConfiguredApiResult.push(resultByHostListNew[f])
//     }

//     console.log('new groupby-grpby finalChildConfiguredApiResult',finalChildConfiguredApiResult)

  

//       const newRes={
//         'childresponseFilterId':1,
//         'response':finalChildConfiguredApiResult
//       }

//       newfinalChildConfiguredApiResult.push(newRes)

//       resultformed=true;

    

//   console.log('groupby logic end')


// }

// if(inputFeature.configResultFrom &&inputFeature.groupBy===null)
//   {
//     resultformed=true;
//     var childconfigFilterBy=inputFeature.childApiDataFilterby;
//     if(inputFeature.childApiDataFilterby.startsWith('*') && inputFeature.possibleFilterIds.length>0)
//     {
//       console.log('inputFeature.possibleFilterIds',inputFeature.possibleFilterIds)
//       for(let posfilter=0;posfilter<inputFeature.possibleFilterIds.length;posfilter++)
//       {
//         console.log('inputFeature.possibleFilterIds[posfilter]',inputFeature.possibleFilterIds[posfilter])
//         var comparingkey=this.splitmasterkey(inputFeature.childApiDataFilterby);
//         console.log('*comparingkey',comparingkey)
//         var childresult:any=childConfigDetailsApiResponse.find(a=>a[comparingkey]===inputFeature.possibleFilterIds[posfilter].possibleKey)
        
//         console.log('*comparingkey',comparingkey,'*after filter childresult',childresult)
//         if(childresult)
//         {

//           var finalresult:any[]=childresult[inputFeature.configResultFrom]
//           console.log('after filter final childresult',childresult)
        
//           const newRes={
//             'childresponseFilterId':inputFeature.possibleFilterIds[posfilter].id,
//             'response':finalresult
//           }
//           console.log('newRes',newRes)
//           newfinalChildConfiguredApiResult.push(newRes);

//         }

//       }

//        console.log('**newfinalChildConfiguredApiResult',newfinalChildConfiguredApiResult)
//     }

//     if(inputFeature.childApiDataFilterby.startsWith('#')&&inputFeature.possibleFilterIds.length<=0)
//       {
//         var comparingkey=this.splitmasterkey(inputFeature.childApiDataFilterby);
//         console.log('#comparingkey',comparingkey)

//         var childresult:any=childConfigDetailsApiResponse.find(a=>a[comparingkey]===masterConfigKey)
//         console.log('comparingkey',comparingkey,'after filter childresult',childresult)
//         if(childresult)
//         {
//         var finalresult:any[]=childresult[inputFeature.configResultFrom]
//         console.log('after filter final childresult',childresult)
        
//         const newRes={
//           'childresponseFilterId':1,
//           'response':finalresult
//         }
//         console.log('newRes',newRes)
//         newfinalChildConfiguredApiResult.push(newRes);

//         finalChildConfiguredApiResult=finalresult
//         }

//         // var masterConfigapiDetails:any[]=masterApiresponsebyMasterCallId
//         // var result=masterConfigapiDetails.find(a=>a[comparingkey]==inputFeature.masterConfigKey);
//         // console.log('filtered-result',result)
//         // filteredResult=result;
//       }

//       if(childconfigFilterBy=="")
//         {

//           if(Array.isArray(childConfigDetailsApiResponse[0][inputFeature.configResultFrom]))
//           {

//               finalChildConfiguredApiResult=childConfigDetailsApiResponse[0][inputFeature.configResultFrom];
//           }
//           else
//           {
//               finalChildConfiguredApiResult.push(childConfigDetailsApiResponse[0][inputFeature.configResultFrom]);
//           }

//           const newRes={
//             'childresponseFilterId':1,
//             'response':finalChildConfiguredApiResult
//           }
//           console.log('newRes',newRes)
//           newfinalChildConfiguredApiResult.push(newRes)

//           //finalChildConfiguredApiResult=childConfigDetailsApiResponse[0][inputFeature.configResultFrom];
//           console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse[0][inputFeature.configResultFrom])

//         }
//   }
// }
//   // else
//   // {
//   //   finalChildConfiguredApiResult=childConfigDetailsApiResponse;

//   // }
//   if(!resultformed )
//     {
//       const newRes={
//         'childresponseFilterId':1,
//         'response':childConfigDetailsApiResponse
//       }
//       console.log('newRes',newRes)
//       newfinalChildConfiguredApiResult.push(newRes);

//       finalChildConfiguredApiResult=childConfigDetailsApiResponse;
//     }

// console.log('newfinalChildConfiguredApiResult',newfinalChildConfiguredApiResult)
// console.log('initialChildApiCallWithFilteredMaster-finalChildConfiguredApiResult',finalChildConfiguredApiResult);
  
// return newfinalChildConfiguredApiResult;

// }


// async validateBothInputJsonAndApiResponse(finalValidateJsonResponse: InputValidationConfigJsonApiModel):Promise<ConfigValidationUIModel[]>
// {
//   console.log('ready for validation')
//   console.log('finalValidateJsonResponse',finalValidateJsonResponse)
//   let finalUIResult:ConfigValidationUIModel[]=[];
//   var uimodelnumber:number=1
//   for(let i=0 ; i<finalValidateJsonResponse['configurations'].length;i++)
//   {
    
//     var inputFeature:Configuration=finalValidateJsonResponse['configurations'][i];
//     var apiResult:any[]=inputFeature.ConfigApiresult;
//     console.log('apiResult',apiResult)
//     console.log('apiResult.find(a=>a.isGroupedResult).isGroupedResult',apiResult[0].isGroupedResult)
//       //need to monitor the logic
//     var isGroupedResultvalue=apiResult[0].isGroupedResult
//     //need to monitor the logic
//     console.log('isGroupedResult',isGroupedResultvalue)
   
//     console.log('validation starts for-index',i,inputFeature);
//     console.log('apiResult',apiResult);
//     var legitimateconfigsList:LegitiamteConfiguration[]=inputFeature.legitiamteConfiguration
//     console.log('legitimateconfigsList',legitimateconfigsList)
//     var Filterids=inputFeature.childConfigurations[0].apiCalls.find(a=>a.apicallId===1).possibleFilterIds
//     var plzdontProceed=false;
//     if(apiResult[0]?.response?.length==0  &&inputFeature.isFilterMandatory)
//     //if(inputFeature.isFilterMandatory==true)
//     {
//       console.log('Filterids',Filterids)
//       console.log('plz dont proceed')
//       plzdontProceed=true;
//       let nouiModel: ConfigValidationUIModel={
//         id: uimodelnumber,
//         config:inputFeature.masterConfigKey,
//         configkey: 'Nil',
//         reason: 'nothing configured atleast configure one of them',
//         status: ConfigValidationStatus.Fail
//       }
//       uimodelnumber=uimodelnumber+1
//       finalUIResult.push(nouiModel)
      
        
//     }

//     var morethanonegrp:boolean=false;
//     var nothingconfigured=false;
//     if(inputFeature.isGroupByMandatory || apiResult[0]?.response[0]?.response?.length==0)
//     {
      
//       if(apiResult[0]?.response[0]?.response?.length==0)
//       {
//         nothingconfigured=true;
//       }
//       console.log('isGroupByMandatory validation start')
//       var grpResponse:any[]=apiResult[0].response;
//       console.log('grpResponse',grpResponse)
//       var dbgrpReult:any[]=grpResponse[0].response;
//       console.log('dbgrpReult',dbgrpReult)

//       if(dbgrpReult?.length>1 && !nothingconfigured)
//       {
//         morethanonegrp=true;
//       }

//       console.log('morethanonegrp')

//       if(nothingconfigured)
//         {
//           let nouiModel: ConfigValidationUIModel={
//             id: uimodelnumber,
//             config:inputFeature.masterConfigKey,
//             configkey: 'Nil',
//             reason: `no ${inputFeature.masterConfigKey} configured`,
//             status: ConfigValidationStatus.Fail
            
//           }
//           uimodelnumber=uimodelnumber+1
//          finalUIResult.push(nouiModel)

//         }

//         if(morethanonegrp)
//           {
//             let nouiModel: ConfigValidationUIModel={
//               id: uimodelnumber,
//               config:inputFeature.masterConfigKey,
//               configkey: 'Nil',
//               reason: nothingconfigured? `no ${inputFeature.masterConfigKey} configured`:`multiple ${inputFeature.masterConfigKey} configured maintain one host`,
//               status: ConfigValidationStatus.Fail
              
//             }
//             uimodelnumber=uimodelnumber+1
//          finalUIResult.push(nouiModel)

//           }
      
      
      
//       console.log('isGroupByMandatory validation end')



//     }
    


    
//     if(!plzdontProceed && !morethanonegrp && !nothingconfigured)
//     {

//       if(isGroupedResultvalue)
//       {

//         var grpResponse:any[]=apiResult[0].response;
//         console.log('grpResponse',grpResponse)
//         var dbgrpReult:any[]=grpResponse[0].response;
//         console.log('dbgrpReult',dbgrpReult)
//         var grpId=dbgrpReult[0].groupId;
//         console.log('grpId',grpId)
//         var groupname=dbgrpReult[0].groupName
        
//         var legitimateconfigsListbyGrpId=legitimateconfigsList.filter(a=>a.childresponseFilterId==grpId)
//         console.log('legitimateconfigsListbyGrpId',legitimateconfigsListbyGrpId)
        
//         for(let p=0 ;p<legitimateconfigsListbyGrpId.length;p++)
//         {
//           var legitimateconfigs:LegitiamteConfiguration=legitimateconfigsListbyGrpId[p];
//           console.log('legitimateconfigsList[lc]',legitimateconfigs)
//           //
        

//           //
//           var validationConfigList:ValidationConfig[]=legitimateconfigs.validationConfig;
//           console.log('validationConfigList',validationConfigList);
//           //var finalvalidatevaluevalues:any[]=[]
//           var needtoValidateList:any[]=[]
//           var comparingkey:Comparewithchildren=legitimateconfigs.comparewithchildren[0];

//           var dbresultList:any[]= apiResult.find(a=>a.childcallid==comparingkey.childrenApiCallId)['response']

//           console.log('dbresultList',dbresultList,dbresultList.length)
//           console.log('dbresultList[ar]',dbresultList[0].response)
//           console.log('dbresultList[0].response[0].groupResponse',dbresultList[0].response[0].groupResponse)

//           for(let ar=0; ar<dbresultList[0].response[0].groupResponse.length;ar++)
//             {
//               var inputobjectkey=comparingkey.childrenDataFilterBy;
//               //var configsWithsamekey:any[]= dbresultList[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)
//               if(dbresultList[0].response.length>0)
//               {
//                 //var configsWithsamekey:any[]= dbresultList[0].response[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)
//                 var configsWithsamekey:any[]= dbresultList[0].response[0].groupResponse[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)

//                 console.log('configsWithsamekey',configsWithsamekey)
//                 const needtoValidate={
//                   groupedCommonname:dbresultList[0].response[0].groupResponse[ar].CommonName
                  
//                 }

//                 needtoValidate['groupedConfigs']=configsWithsamekey;
//                 needtoValidateList.push(needtoValidate)
//                 console.log('needtoValidate',needtoValidate);
//               }
              
              
              
//             }

//           console.log('needtoValidateList',needtoValidateList)

//           if(needtoValidateList.length)
//           {

//             for(let nv=0;nv<needtoValidateList.length;nv++)
//             {
//               var finalvalidatevaluevalues:any[]=[]
//               let uiModel: ConfigValidationUIModel={
//                 id: uimodelnumber,
//                 config: groupname + '  |  '+ inputFeature.masterConfigKey+'-'+needtoValidateList[nv].groupedCommonname,
//                 configkey: '',
//                 reason: 'Cool..! Looks Fine',
//                 status: ConfigValidationStatus.success
//               }

//               uiModel.configkey=legitimateconfigs.configurationName;

//               console.log('needtoValidateList[nv].groupedConfigs',needtoValidateList[nv].groupedConfigs)
//               var dbresult=needtoValidateList[nv].groupedConfigs
//               var dbvaluekeyinput=validationConfigList[0].ValuekeyFrom
//               var dbvalue=dbresult[dbvaluekeyinput].toString()

//               finalvalidatevaluevalues.push(dbvalue)

//               for(let y=0;y<validationConfigList.length;y++)
//                 {
//                   console.log('finalvalidatevaluevalues',finalvalidatevaluevalues)
//                   var expectedvalue:string[]=validationConfigList[y].expectedValue;
//                   //--console.log('a=>a==expectedvalue',finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                   console.log('a=>a==expectedvalue',finalvalidatevaluevalues.includes)
          
//                   var errorcount=0;
//                   if(finalvalidatevaluevalues.length>0)
//                   {
//                     var realEntryFromDb=finalvalidatevaluevalues[0];
//                     //var inputValue=expectedvalue.find(a=>a===realEntryFromDb)
          
          
//                     //--if(!finalvalidatevaluevalues.find(a=>a.toLowerCase()==expectedvalue.toLowerCase()))
//                       if(!expectedvalue.find(a=>a.toLowerCase()===realEntryFromDb.toLowerCase()))
//                       {
//                         errorcount=errorcount+1;
//                         uiModel.reason='not an expected value there!'
//                         uiModel.status=ConfigValidationStatus.Fail
//                         console.log('not an expected value there!')
//                       }
//                     //case sensitive check 
//                     console.log('expected value pass')
                    
//                     if(errorcount==0)  
//                     {
//                       console.log('case sensitive start')
//                       //--if(!finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                         if(!expectedvalue.includes(realEntryFromDb))
//                         {
//                           errorcount=errorcount+1;
//                           uiModel.reason='case sensitive failed'
//                         uiModel.status=ConfigValidationStatus.Fail
//                           console.log('case sensitive failed')
//                         }
//                       //var IsCorrectCaseSensitive:boolean= await this.CaseSensitiveCheck(finalvalidatevaluevalues,expectedvalue)
                      
//                     }
          
          
//                     if(errorcount==0)  
//                     {
//                       console.log('datatype check start')
//                       console.log('typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue))',typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue)),'validationConfigList[y].dataType',validationConfigList[y].dataType)
//                       if(typeof(realEntryFromDb)===validationConfigList[y].dataType==false)
//                       {
//                           errorcount=errorcount+1;
//                           uiModel.reason='datatype check failed.!'
//                           uiModel.status=ConfigValidationStatus.Fail
//                           console.log('datatype check failed.!')
//                       }
                      
//                     }
          
//                     if(errorcount==0 && validationConfigList[y]?.urlConfig!=null)
//                     {
//                       console.log('url hit check start')
//                       const urlHitPaylod={
//                       url: validationConfigList[y]?.urlConfig.endpoint,
//                       method: "Get"}
                  
//                       // url: "https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService/V1/heartbeat",
          
//                       var urlhitResponse=await this.configValidationService.UrlUpCheck(urlHitPaylod);
//                       if(!urlhitResponse)
//                       {
//                             errorcount=errorcount+1;
//                             uiModel.reason='response not found for this URL';
//                             uiModel.status=ConfigValidationStatus.Fail;
//                             console.log('url check failed.!');
//                       }
                        
//                     }
          
//                   }
//                   else
//                   {
//                     uiModel.reason='not found from api'
//                     uiModel.status=ConfigValidationStatus.Fail
//                     console.log('not found from api')
//                   }
//                   //expected value check
                
          
//                 }

//                 finalUIResult.push(uiModel)
//             uimodelnumber=uimodelnumber+1;
//             }
//           } 

          

//           console.log('legitimateconfigs',legitimateconfigs)
  

//         }

//       }
//       else
//       {

//         for(let lc=0 ;lc<legitimateconfigsList.length;lc++)
//           {
//             var noneedtocheckValues : boolean=false;
//             let uiModel: ConfigValidationUIModel={
//               id: uimodelnumber,
//               config:inputFeature.subMasterConfigKey!=null &&inputFeature.subMasterConfigKey!=""?inputFeature.masterConfigKey+'-'+inputFeature.subMasterConfigKey: inputFeature.masterConfigKey,
//               configkey: '',
//               reason: 'Cool..! Looks Fine',
//               status: ConfigValidationStatus.success
//             }

            
//             var legitimateconfigs:LegitiamteConfiguration=legitimateconfigsList[lc];
//             //uimodel configkeyset
//             console.log('legitimateconfigsList[lc]',legitimateconfigs)
//             uiModel.configkey=legitimateconfigs.configurationName;
//             console.log('ui specifier start ')
//             var ResultMadeFor=''
//             var ispossiblefilteridsPresent=false
//             for(let p=0;p<inputFeature.childConfigurations.length;p++)
//             {
//               if(inputFeature.childConfigurations[0].apiCalls.find(a=>a.apicallId===legitimateconfigs.comparewithchildren[0].childrenApiCallId))
//               {
//                 var posFilterids=inputFeature.childConfigurations[0].apiCalls.find(a=>a.apicallId===legitimateconfigs.comparewithchildren[0].childrenApiCallId).possibleFilterIds
//                 console.log('posFilterids',posFilterids)
//                 if(posFilterids.length>0)
//                   {
//                     ispossiblefilteridsPresent=true;
//                     ResultMadeFor=posFilterids.find(pos=>pos.id==legitimateconfigs.childresponseFilterId).possibleKey
//                   console.log('ResultMadeFor',ResultMadeFor)
//                   uiModel.config=inputFeature.masterConfigKey+'-'+ResultMadeFor
//                   }
//               }
//             }
//             //console.log('filteridsList',inputFeature.childConfigurations.find(a=>a.apiCalls.find(r=>r.apicallId===legitimateconfigs.comparewithchildren[0].childrenApiCallId).possibleFilterIds))
//             console.log('ui specifier end ')
//             var validationConfigList:ValidationConfig[]=legitimateconfigs.validationConfig;
//             console.log('validationConfigList',validationConfigList);
//             var finalvalidatevaluevalues:any[]=[]
//             var noneedtoLoop=false
            
            
//             if(legitimateconfigs.comparewithchildren)
//             {
//                 for (let k=0;k<legitimateconfigs.comparewithchildren.length;k++)
//                 {
//                   var comparingkey:Comparewithchildren=legitimateconfigs.comparewithchildren[k];
//                   var inputobjectkey=comparingkey.childrenDataFilterBy;
//                   var dbresultList:any[]= apiResult.find(a=>a.childcallid==comparingkey.childrenApiCallId)['response']
//                   console.log('inputobjectkey',inputobjectkey,'dbresultList',dbresultList,'dbresultList[0]',dbresultList[0])
//                   // if(dbresultList.length==0 && ispossiblefilteridsPresent)
//                   // {
//                   //   nothingConfigured=true;
//                   //   uiModel.reason='nothing configured atleast configure on them'
                    
//                   // }
                  
//                   //get the result based on childresponsefilterid
//                   var matcheddbresult:any[]=dbresultList?.filter(r=>r.childresponseFilterId==legitimateconfigs.childresponseFilterId)
//                   console.log('matcheddbresult',matcheddbresult)
//                   if(matcheddbresult?.length<=0)
//                     {
//                       noneedtoLoop=true
//                     }
//                   if(dbresultList.length)
//                   {
//                       if(inputobjectkey=="")
//                         {
//                           var dbvaluekeyinput=validationConfigList[0].ValuekeyFrom
//                           //check is key present
//                           console.log('check checkKeyAloneNotValues start')
//                           console.log('keycheck',matcheddbresult[0]['response'][0].hasOwnProperty(dbvaluekeyinput))

//                           if(matcheddbresult[0]['response'][0].hasOwnProperty(dbvaluekeyinput) && legitimateconfigs.CheckKeyAloneNotValues)
//                           {
//                             noneedtocheckValues=true;
//                             console.log('proceed')
//                             //need to proceed

//                           }

//                           console.log('check checkKeyAloneNotValues end')

//                           //var dbvalue=dbresultList[0][dbvaluekeyinput].toString()
//                           //if(matcheddbresult[0]['response'].length>0)
//                           var dbvalue=  matcheddbresult[0]['response'][0][dbvaluekeyinput]?.toString()

//                         }
//                         else{
//                           //var dbresult=dbresultList.find(d=>d[inputobjectkey]===legitimateconfigs.configurationName);
//                         if(matcheddbresult.length>0)
//                           {
//                             if(matcheddbresult[0]['response'].length>0)
//                               {
//                           var dbresult=matcheddbresult[0]['response']?.find(d=>d[inputobjectkey].toLowerCase()===legitimateconfigs.configurationName.toLowerCase());
//                           console.log('dbresult',dbresult)
//                           var dbvaluekeyinput=validationConfigList[0].ValuekeyFrom
                         
//                           if(dbresult)
//                             {
//                               console.log('dbresult[inputobjectkey]',dbresult[inputobjectkey], legitimateconfigs.CheckKeyAloneNotValues)
//                               if(dbresult[inputobjectkey]!=null && legitimateconfigs.CheckKeyAloneNotValues)
//                                 {
//                                   noneedtocheckValues=true;
//                                   console.log('proceed else')
    
//                                 }
//                           var dbvalue=dbresult[dbvaluekeyinput]?.toString()
//                             }
//                               }
//                           }
      
//                         }
                      
//                       console.log('dbresult',dbresult,'dbvaluekeyinput',dbvaluekeyinput,'dbvalue',dbvalue)
//                       if(dbvalue)
//                         {
//                       finalvalidatevaluevalues.push(dbvalue)
//                         }
//                       console.log('finally',legitimateconfigs.configurationName,dbresultList.find(d=>d[inputobjectkey]===legitimateconfigs.configurationName))
                        
      
//                   }
                  
                  
                
//                 }
//               }
      
      
//             console.log('legitimateconfigs',legitimateconfigs)


//             if(!noneedtoLoop)
//             {
//             if(noneedtocheckValues)  
//               {
//                 uiModel.reason='Cool..! Looks Fine'
//                       uiModel.status=ConfigValidationStatus.success
//                       console.log('noneedtocheckValues')

//               }
//             else{
//               for(let y=0;y<validationConfigList.length;y++)
//                 {
//                   console.log('finalvalidatevaluevalues',finalvalidatevaluevalues)
//                   var expectedvalue:string[]=validationConfigList[y].expectedValue;
//                   //--console.log('a=>a==expectedvalue',finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                   console.log('a=>a==expectedvalue',finalvalidatevaluevalues.includes)
          
//                   var errorcount=0;
//                   if(finalvalidatevaluevalues.length>0)
//                   {
//                     var realEntryFromDb=finalvalidatevaluevalues[0];
//                     //var inputValue=expectedvalue.find(a=>a===realEntryFromDb)
          
          
//                     //--if(!finalvalidatevaluevalues.find(a=>a.toLowerCase()==expectedvalue.toLowerCase()))
//                       if(!expectedvalue.find(a=>a.toLowerCase()===realEntryFromDb.toLowerCase()))
//                       {
//                         errorcount=errorcount+1;
//                         uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                         uiModel.status=ConfigValidationStatus.Fail
//                         console.log('not an expected value there!')
//                       }
//                     //case sensitive check 
//                     console.log('expected value pass')
                    
//                     if(errorcount==0)  
//                     {
//                       console.log('case sensitive start')
//                       //--if(!finalvalidatevaluevalues.find(a=>a==expectedvalue))
//                         if(!expectedvalue.includes(realEntryFromDb))
//                         {
//                           errorcount=errorcount+1;
//                           uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-case sensitive failed':'case sensitive failed'
//                         uiModel.status=ConfigValidationStatus.Fail
//                           console.log('case sensitive failed')
//                         }
//                       //var IsCorrectCaseSensitive:boolean= await this.CaseSensitiveCheck(finalvalidatevaluevalues,expectedvalue)
                      
//                     }
          
          
//                     if(errorcount==0)  
//                     {
//                       console.log('datatype check start')
//                       console.log('typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue))',typeof(finalvalidatevaluevalues.find(a=>a==expectedvalue)),'validationConfigList[y].dataType',validationConfigList[y].dataType)
//                       if(typeof(realEntryFromDb)===validationConfigList[y].dataType==false)
//                       {
//                           errorcount=errorcount+1;
//                           uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Datatype check failed.!':'Datatype check failed.!'
//                           uiModel.status=ConfigValidationStatus.Fail
//                           console.log('datatype check failed.!')
//                       }
                      
//                     }
          
//                     if(errorcount==0 && validationConfigList[y]?.urlConfig!=null)
//                     {
//                       console.log('url hit check start')
//                       const urlHitPaylod={
//                       url: validationConfigList[y]?.urlConfig.endpoint,
//                       method: "Get"}
                  
//           //            url: "https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService/V1/heartbeat",
          
//                       var urlhitResponse=await this.configValidationService.UrlUpCheck(urlHitPaylod);
//                       if(!urlhitResponse)
//                       {
//                             errorcount=errorcount+1;
//                             uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues?'Not Mandatory-response not found for this URL':'response not found for this URL';
//                             uiModel.status=ConfigValidationStatus.Fail;
//                             console.log('url check failed.!');
//                       }
                        
//                     }
          
//                   }
//                   else
//                   {
//                     uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues?'Not Mandatory-not found from api':'not found from api'
//                     uiModel.status=ConfigValidationStatus.Fail
//                     console.log('not found from api')
//                   }
//                   //expected value check
                
          
//                 }

//             }    
            
//             finalUIResult.push(uiModel)
//             uimodelnumber=uimodelnumber+1;
//           }
      
//           }
//       }
//     }

  



//     console.log('validation end for-index',i,inputFeature);

   

//   }

  

//   console.log('validation end')




// console.log('finalUIResult',finalUIResult)

// //urlcheck
// //this.urlCheck('https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService');
// //url check

//   return finalUIResult;

// }
////////////////////////////////////////////////////////////////////////////////////////////


//*************************************START payment manager ,retailconfiguration,credit card and gift card,payment manager***********************************************8 */
//-------------------------------------------------------------------------------------------------------------------------------------------
async getValidatedRetailConfigurationUIResultPMS(isRevalidate:boolean,revalidatedids:number[]): Promise<ConfigValidationUIModel[]> {
  this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
  //master json input from api

   
  console.log('----Start new way full generic payment manager,retail config,cc and gc-----------')
  
  //get input json
  var response =  await this.configValidationService.getValidationJsonPMS();
  
  console.log('generic-response-configuration',response['configurations']);
  let ValidateJsonResponse: InputValidationConfigJsonApiPMSModel[]=response;
  console.log('generic-ValidateJsonResponse',ValidateJsonResponse);

//consolidated Heart Beat check


  // //configuration with Final results
 
  let ConfigApiJsonResponseList: InputValidationConfigJsonApiPMSModel={
    product: ValidateJsonResponse['product'],
    configurations: [],
    rules: ValidateJsonResponse['rules'],
    neededHearbeats: ValidateJsonResponse['neededHearbeats'],
    globaldependencies: ValidateJsonResponse['globaldependencies']
  };



  console.log('ConfigApiJsonResponseList',ConfigApiJsonResponseList)
  console.log('neededHearbeats',ConfigApiJsonResponseList.neededHearbeats)

  var heartbeatResult:NeededHearbeats= await this.HeartBeatCheck(ConfigApiJsonResponseList.neededHearbeats)
  ConfigApiJsonResponseList.neededHearbeats=heartbeatResult;
  console.log('heartbeatReult',heartbeatResult)

  console.log('configurations',ValidateJsonResponse['configurations'])
  var inputConfigs:Configuration[]=ValidateJsonResponse['configurations']
  var filteredConfigs:Configuration[]=[]
  if(isRevalidate && revalidatedids.length>0)
  {
    // revalidatedids=[1,2]
   
    for(let i=0 ; i<inputConfigs.length;i++)
    {
      if(revalidatedids.includes(inputConfigs[i].uiHeaderId))
      {
        filteredConfigs.push(inputConfigs[i])
      }

    }
    ValidateJsonResponse['configurations']=filteredConfigs
    
  
    console.log('filteredConfigs',filteredConfigs)
  }
  else
  {
    ValidateJsonResponse['configurations']
  }

  //global dependency-------------------------------------------------------------------------------------------------
  console.log('global dependency start',ValidateJsonResponse['globaldependencies'])
  if(ValidateJsonResponse['globaldependencies']?.length>0)
  {
    let newGlobalDependencyList:Globaldependency[]=[]

    for(let d=0;d<ValidateJsonResponse['globaldependencies']?.length;d++)
      {
          console.log('ValidateJsonResponse[globaldependencies][0]',ValidateJsonResponse['globaldependencies'][d])
          let newGlobalDependency:Globaldependency=ValidateJsonResponse['globaldependencies'][d];
        //apicalls
          if(newGlobalDependency.viaApiCall)
          {
             var depResult= await this.DependencyApiCallConsolidate(newGlobalDependency)
             newGlobalDependency.globaldependenciesResult=depResult
             newGlobalDependencyList.push(newGlobalDependency)
          }
      
      }

      ConfigApiJsonResponseList.globaldependencies=newGlobalDependencyList
  }
  
  console.log('global dependecy End')

  //global dependency
  
  //uncomment
  for(let i=0 ; i<ValidateJsonResponse['configurations'].length;i++)
    {
      let newinputFeature:Configuration={
        configKey: '',
        masterconfigurationsSetup: undefined,
        configUrl: [],
        configValidate: [],
        heartbeat: '',
        dependantServices: [],
        Configvalidatedapiresult: [],
        masterConfigKey: '',
        masterConfigurations: [],
        childConfigurations: [],
        legitiamteConfiguration: [],
        ConfigApiresult: [],
        subMasterConfigKey: '',
        isFilterMandatory: false,
        isGroupByMandatory: false,
        uiShowTemplate: '',
        possibleFilters: [],
        splitResultForValidation: [],
        uiHeaderId: 0,
        groupBy: undefined
      }

     var inputFeature:Configuration=ValidateJsonResponse['configurations'][i];
       console.log('genric-for-loop',inputFeature);
       console.log('configkey',inputFeature.masterConfigKey);

       var ismasterHeartBeatNotReachable:boolean=false;
       this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
      var masterConfigurationApiResponselist:any=await this.initialMasterCallsConfigurationSetupPMS(inputFeature.masterConfigurations,heartbeatResult);
   
      
      
       console.log('generic-master end');
       console.log('generic-child strat////');

       console.log(inputFeature.masterConfigKey,'-generic-masterConfigurationApiResponselist',masterConfigurationApiResponselist);
       //read the child configuration
       this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
      var childConfigurationApiResponseList:any[]=await this.initialchildCallsConfiurationSetupPMS(inputFeature,masterConfigurationApiResponselist,heartbeatResult);
     
      //  console.log('..generic child end////-childConfigurationApiResponseList',childConfigurationApiResponseList)
      inputFeature.ConfigApiresult=childConfigurationApiResponseList;
      //VALIDATION
      console.log('validate push');
      console.log('inputFeature',inputFeature,'childConfigurationApiResponseList',childConfigurationApiResponseList)
      ConfigApiJsonResponseList.configurations.push(inputFeature)
      
      
      console.log('validate list pushed')

    
      }
      
     console.log('finalValidateJsonResponseList',ConfigApiJsonResponseList)
     this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
    var uiResponse:ConfigValidationUIModel[]=await this.validateBothInputJsonAndApiResponsePMS(ConfigApiJsonResponseList);
    this.utilities.ToggleLoaderWithMessage(false,'Validations in progress please wait');
//uncomment
    console.log('----End new way full generic retail config,cc and gc-----------');
   
    let uiResponsee:any[]=[{id:'1',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'},{id:'2',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'}]

  return uiResponse;
}

async DependencyApiCallConsolidate(dependencyInput : Globaldependency) : Promise<any>
{
  //now we implemnted only for pmssystem dependencies
   console.log('DependencyApiCallConsolidate start ')
   var depapicallResultList:any[]=[]
   var alldependencyResult:any[]=[]
   for(let depapi=0;depapi<dependencyInput.dependencyApiCalls?.length;depapi++)
   {
    let depapicallResult:any={depapiId:dependencyInput.dependencyApiCalls[depapi].apicallId}
    var dependencyapiresponse= await this.configValidationService.getDependencyApicall(dependencyInput.dependencyApiCalls[depapi]);
    depapicallResult['response']=dependencyapiresponse;
    depapicallResultList.push(depapicallResult)
   }
   console.log('depapicallResultList',depapicallResultList)
   var inputdependeciesconfigKeyAndValues:DependeciesconfigKeyAndValue[]=dependencyInput.dependeciesconfigKeyAndValues;

    for(let idkv=0;idkv<inputdependeciesconfigKeyAndValues?.length;idkv++)
    {
      let inputdependeciesconfigKeyAndValue:DependeciesconfigKeyAndValue=inputdependeciesconfigKeyAndValues[idkv];
      //take the result
      let matcheddepsplitfrom:DepedenciesFrom=dependencyInput.depedenciesFrom.find(a=>a.dId==inputdependeciesconfigKeyAndValues[idkv].depedenciesFromId);
      var depvalueKeyFromResult:any[]=[]
      if(inputdependeciesconfigKeyAndValue.depedenciesFromId==0)
      {
        console.log('zeroo split')
        depvalueKeyFromResult.push(depapicallResultList[0]['result'])
      }
      // if(matcheddepsplitfrom?.dvalueKeyFromJSON)
      // {

      var dependencyResult:any={}
      dependencyResult['id']=dependencyInput.id;
      dependencyResult['configUniqId']=inputdependeciesconfigKeyAndValue.configUniqId
        depvalueKeyFromResult.push(depapicallResultList[0]['response'][matcheddepsplitfrom?.dvalueKeyFrom]);
        console.log('depvalueKeyFromResult',depvalueKeyFromResult);
        var depresult=depvalueKeyFromResult[0][inputdependeciesconfigKeyAndValue.configurationName]
        var uniquedepid=0
        if(depresult)
          {
            dependencyResult['configUniqValue']=uniquedepid
            uniquedepid=inputdependeciesconfigKeyAndValue.depResults[0].expectedValue.find(a=>a.ExpValue.toString().toLowerCase()===depresult.toString().toLowerCase())?.ExpId
  
          }
          if(uniquedepid)
          {
            dependencyResult['configUniqValue']=uniquedepid
          }
        console.log(uniquedepid)
        alldependencyResult.push(dependencyResult)
      //}


    }
  
   return alldependencyResult;
   

   console.log('DependencyApiCallConsolidate end ')
}

async HeartBeatCheck(inputNeededHearbeats:NeededHearbeats):Promise<NeededHearbeats>
{
   var NeededHearbeatsResponse:NeededHearbeats={
     gateWay: [],
     service: []
   }
   
   var oldgateWayList:GateWay[]=inputNeededHearbeats.gateWay;
   var oldserviceList:Service[]=inputNeededHearbeats.service;
   var newgateWayList:GateWay[]=[]
   var newserviceList:Service[]=[]

   for(let service=0;service<oldserviceList.length;service++)
    {
        console.log('oldserviceList[gate]',oldserviceList[service])
        var HBapiresult:boolean= await this.configValidationService.ServiceHeartBeatCalls(oldserviceList[service]);
       console.log('HBapiresult',HBapiresult)
       oldserviceList[service].result=HBapiresult;
       newserviceList.push(oldserviceList[service])
    }

   for(let gate=0;gate<oldgateWayList.length;gate++)
   {
       console.log('oldgateWayList[gate]',oldgateWayList[gate])
       var HBsapiresult:boolean= await this.configValidationService.GateWayHeartBeatCalls(oldgateWayList[gate]);
      console.log('HBapiresult',HBsapiresult)
      oldgateWayList[gate].result=HBsapiresult;
      newgateWayList.push(oldgateWayList[gate])
   }

   



   NeededHearbeatsResponse.gateWay=newgateWayList;
   NeededHearbeatsResponse.service=newserviceList;





   return NeededHearbeatsResponse;
}


async initialMasterCallsConfigurationSetupPMS(urlConfiguration:MasterConfiguration[],heartbeatResult:NeededHearbeats):Promise<any>
{
  //masterConfigDetails
  //new implementation start

  var masterConfigurationResultList:any[]=[]

  let masterApiCallList: ApiCall[]=urlConfiguration.find(a=>a.apiCalls).apiCalls;

  console.log('generic-initialMasterCallsConfigurationSetupPMS-start',masterApiCallList)
  var mastercallinc:number=1
  const masterApiAllResult={
    mastercallid:mastercallinc
    
  }

  var finalMasterObject={
    heartbeatUnreachable:false,
    isgateway:false,
    masterApiAllResultList:[]
  }

  var masterApiAllResultList:any[]=[]

 for (let i=0;i<masterApiCallList.length;i++)
  {
    console.log('master Loop call starts')
    mastercallinc=mastercallinc+1;
    let masterApiCall: ApiCall=masterApiCallList[i];
    // var inputmastergateway:number=masterApiCall.hearbeatGatewayId
    // var inputmasterService:number=masterApiCall.hearbeatServiceId
    // console.log('inputmastergateway',inputmastergateway,'inputmasterService',inputmasterService)
    var apiHitNotAllowed:boolean=false
    console.log('master loop obj-',masterApiCall);
    if(masterApiCall)
    {
      var gatewayresponse:boolean=heartbeatResult.gateWay.find(a=>a.gatewayId==masterApiCall.hearbeatGatewayId).result
      var serviceresponse:boolean=masterApiCall.hearbeatServiceId>0?heartbeatResult.service.find(a=>a.serviceId==masterApiCall.hearbeatServiceId).result:false
  
        if(!gatewayresponse)
        {
          finalMasterObject['heartbeatUnreachable']=true
          finalMasterObject['isgateway']=true
          apiHitNotAllowed=true
        }
        else
        {
          if(!serviceresponse)
          {
            finalMasterObject['heartbeatUnreachable']=true
            apiHitNotAllowed=true
            if(!finalMasterObject['isgateway'])
            {
              finalMasterObject['isgateway']=false
            }
          
          }
        }
    }
    if(!apiHitNotAllowed)
    {
      this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
      var masterConfigurationResult =  await this.configValidationService.getMasterUrlConfigurationAPICall(masterApiCall);
      console.log('generic-masterConfigurationResult',masterConfigurationResult);
      

      if(Array.isArray(masterConfigurationResult))
        {
          console.log('it is  an array');
          masterApiAllResult['response']=masterConfigurationResult
          //masterConfigurationResultList=masterConfigurationResult
          masterApiAllResultList.push(masterApiAllResult);

        }
        else{
          console.log('it is not an array');
          masterConfigurationResultList.push(masterConfigurationResult)
          masterApiAllResult['response']=masterConfigurationResultList;
          masterApiAllResultList.push(masterApiAllResult);
        }
    }
  }


 
  console.log('generic-initialMasterCallsConfigurationSetupPMS End',masterApiAllResultList)
  finalMasterObject['masterApiAllResultList']=masterApiAllResultList

  return finalMasterObject;
  //new implemtntation end

}


async initialchildCallsConfiurationSetupPMS(inputFeature: Configuration,masterConfigurationApiResponse :any,heartbeatResult:NeededHearbeats):Promise<any[]>
{
  

    console.log('start- child configuration setup-initialchildCallsConfiurationSetupPMS',inputFeature,masterConfigDetails)
    // let ConsolidatedConfigAPIresultlist1:ConsolidatedConfigAPIresponses[]=[]
    let childApiCallList: ApiCall2[]=inputFeature.childConfigurations.find(a=>a.apiCalls).apiCalls;
  console.log('childApiCallList',childApiCallList)
  let consolidatedApiUrlResult:any[]=[]
  var childcallinc:number=1

  var conheartbeatUnreachable:boolean=masterConfigurationApiResponse['heartbeatUnreachable']
      var masterConfigDetails:any[]=masterConfigurationApiResponse['masterApiAllResultList']
      var isgatewayfail=masterConfigurationApiResponse['isgateway']

      console.log('conmasterApiAllResultList',masterConfigDetails)
      console.log('conheartbeatUnreachable',conheartbeatUnreachable)
      console.log('isgatewayfail',isgatewayfail)

  

  
  var childApiAllResultList:any[]=[]
  for (let i=0;i<childApiCallList.length;i++)
    {

      var childApiHitNotAllowed:boolean=false
      const childApiAllResult={
        childcallid:childcallinc,
        childonlyfailed:false
      }
      var skipcall:boolean=false
      var childApicallConfiguration:ApiCall2=childApiCallList[i];
      if(childApicallConfiguration.callDecider!=null)
      {
        if(childApicallConfiguration.callDecider.FromLocal)
        {
          //in future it based on method
          var result=this.localization.GetPropertyConfig(childApicallConfiguration.callDecider.FromLocal.Keys);
          if(result!=null)
          {
            var inputexpectedValues:string[]=childApicallConfiguration.callDecider.FromLocal.expectedResult
            var doskip:boolean=inputexpectedValues.some(item => item.toLowerCase() === result.toLowerCase());
          }
          else{
            doskip=true;
          }
          

          if(!doskip)
          {
            skipcall=true
          }
        }

      }

      if(!skipcall)
      {

        var gatewayresponse:boolean=heartbeatResult.gateWay.find(a=>a.gatewayId==childApicallConfiguration.hearbeatGatewayId).result
      var serviceresponse:boolean=childApicallConfiguration.hearbeatServiceId>0?heartbeatResult.service.find(a=>a.serviceId==childApicallConfiguration.hearbeatServiceId).result:false
      if(conheartbeatUnreachable)
      {
        childApiHitNotAllowed=true;
        childApiAllResult['ismastergatewayfail']=isgatewayfail
        
      }
      else{
        if(!gatewayresponse)
          {
            childApiAllResult['heartbeatUnreachable']=true
            childApiAllResult['isgateway']=true
            childApiHitNotAllowed=true
            childApiAllResult['ismastergatewayfail']=isgatewayfail
          }
          else
          {
            if(!serviceresponse)
            {
              childApiAllResult['heartbeatUnreachable']=true
              childApiAllResult['ismastergatewayfail']=isgatewayfail

              childApiHitNotAllowed=true
              if(!childApiAllResult['isgateway'])
              {
                childApiAllResult['isgateway']=false
              }
            
            }
          }

      }

      childApiAllResult['isGroupedResult']=inputFeature?.isGroupByMandatory?true:false;
      console.log('initiate child call for this childApicallConfiguration',childApicallConfiguration)
      //var filteredResult:any;
      var filteredResultPMS :any[]=[];
      if(masterConfigDetails.length>0)
      {
        if(childApicallConfiguration?.masterDatasFilterby?.length>0)
        {
          for(let j=0;j<childApicallConfiguration?.masterDatasFilterby?.length;j++)
          {
            var inputmasterFilter:MasterDatasFilterby=childApicallConfiguration?.masterDatasFilterby[i];
            console.log('inputmasterFilter',inputmasterFilter)
            
            var masterApiresponsebyMasterCallId:any= masterConfigDetails.find(a=>a?.mastercallid==inputmasterFilter.masterApiCallId)['response']
            console.log('masterApifilter',masterApiresponsebyMasterCallId);
            if(inputmasterFilter.masterDataFilterBy.startsWith('#'))
            {
              var comparingkey=this.splitmasterkey(inputmasterFilter.masterDataFilterBy);
              console.log('need to split',inputmasterFilter.masterDataFilterBy,'comparingkey',comparingkey)
              var masterConfigapiDetails:any[]=masterApiresponsebyMasterCallId
              var result=masterConfigapiDetails.find(a=>a[comparingkey]==inputFeature.masterConfigKey);
              console.log('filtered-result',result)
              //filteredResult=result;
              filteredResultPMS.push(result)

            }

            

          }
        }
        else{
          var filteredResultlist:any[]= masterConfigDetails.find(a=>a?.mastercallid==1)['response']
          //filteredResult=filteredResultlist[0];
          filteredResultPMS=filteredResultlist

        }
      }


      childcallinc=childcallinc+1;
      childApiAllResult['isLoopedResult']=childApicallConfiguration.loopall;
      //console.log('final filteredResult',filteredResult)
      console.log('final filteredResultpms',filteredResultPMS)


      // if(!childApiHitNotAllowed)
      // {
      this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
        var apiresult=await this.initialChildApiCallWithFilteredMasterPMS(filteredResultPMS,childApicallConfiguration,inputFeature)
        //consolidatedApiUrlResult.push(apiresult)
        childApiAllResult['response']=apiresult;
        childApiAllResultList.push(childApiAllResult);
      // }
      // else{
      //   childApiAllResult['response']=[];
      //   childApiAllResultList.push(childApiAllResult);
      // }
      


      }
      

    }


   
    console.log('End- child configuration setup-initialchildCallsConfiurationSetup',inputFeature,masterConfigDetails)
    console.log('childApiAllResultList',childApiAllResultList)
    
    return childApiAllResultList;
   
}

async initialChildApiCallWithFilteredMasterPMS(masterConfigDetails :any[],childApicallConfiguration:ApiCall2, inputFeature: Configuration):Promise<any[]>
{
  console.log('initialChildApiCallWithFilteredMaster-start','masterConfigDetail',masterConfigDetails,'inputFeature',inputFeature,'childApicallConfiguration',childApicallConfiguration)
  var childApiRawList: any[]=[]
  var childConfigResponse1:any[]=[]

  if(childApicallConfiguration.loopall)
  {
    console.log('initialChildApiCallWithFilteredMasterPMS loop start')
    for(let mslist=0;mslist<masterConfigDetails?.length;mslist++)
      {
        this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
        var childConfigResponse = await this.configValidationService.getChildUrlConfigurationApiCallPMS(masterConfigDetails[mslist],childApicallConfiguration);
        console.log('initialChildApiCallWithFilteredMaster-childConfigResponse1',childConfigResponse1);
        childApiRawList.push(childConfigResponse);
        

      }

      var childConfigResponse1:any[]=childApiRawList;
 
  }
  else{
    console.log('initialChildApiCallWithFilteredMasterPMS loop avoided')
    this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
    //getChildUrlConfigurationApiCall
    var childConfigResponse1:any[] = await this.configValidationService.getChildUrlConfigurationApiCallPMS(masterConfigDetails[0],childApicallConfiguration);
    console.log('initialChildApiCallWithFilteredMaster-childConfigResponse1',childConfigResponse1);

  }
console.log('childConfigResponse1',childConfigResponse1)
let childConfigDetailsApiResponse: any[]=[];
let finalChildConfiguredApiResult:any[]=[]

let newfinalChildConfiguredApiResult:any[]=[];


if(childConfigResponse1!=null && Array.isArray(childConfigResponse1))
{
  childConfigDetailsApiResponse=childConfigResponse1;
}
else{
  if(childConfigResponse1!=null)
  childConfigDetailsApiResponse.push(childConfigResponse1);
}

console.log('childConfigDetailsApiResponse.length',childConfigDetailsApiResponse.length,childConfigDetailsApiResponse)


var resultformed:boolean=false;
var finalchildResultAfterFilterationList:any[]=[]


//new all configuration
if(childConfigDetailsApiResponse?.length>0)
  {
    var filterkey=''
    if(childApicallConfiguration.childApiDataFilterby.startsWith('*'))
    {
      filterkey=this.splitmasterkey(childApicallConfiguration.childApiDataFilterby);
      console.log('filterkey',filterkey)
    }

    if(childApicallConfiguration.loopall)
    {

      
      for(let massApiList=0;massApiList<childConfigDetailsApiResponse.length;massApiList++)
      {
        var childResultAfterFilterationList:any[]=[]
        
        
        var innermassAPIList:any[]=childConfigDetailsApiResponse[massApiList];


        if(inputFeature.possibleFilters.length>0)
          {
            var childResultAfterFilteration:any={
              response:[]
            }
           
            for(let childResult=0;childResult<innermassAPIList.length;childResult++)
            {
              var possibleMainValueGot=0
              childResultAfterFilteration['head']=innermassAPIList[childResult]['outletId']

              console.log('filter starts for', innermassAPIList[childResult],innermassAPIList[childResult][filterkey])
              console.log('inputFeature.possibleFilters',inputFeature.possibleFilters)

              if(filterkey!='')
                {
                  var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==innermassAPIList[childResult][filterkey])

                }
                else
                {
                  var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==0)

                }
              
              if(childApicallConfiguration.isConfigResultFromJSON)
              {
                 var stringState=innermassAPIList[childResult][childApicallConfiguration.configResultFrom]
                 console.log('stringState',stringState)
                 var jsonState:any=JSON.parse(stringState);
                 //var tenderInformation: TenderInformation = JSON.parse(stringState);
                //var configuration: TenderConfiguration = JSON.parse(tenderInformation.C
                 //console.log('jsonState',jsonState,'tenderInformation',tenderInformation)
              }
              else
              {
                 console.log('else part-1')
              }
              console.log('filtermatched',filtermatched)
              if(filtermatched)
              {
                
                if(filtermatched?.possibleMainValues.length>0)
                {

                  if(childApicallConfiguration.isConfigResultFromJSON)
                  {
                   
                    var filteringredients=filtermatched?.possibleMainvaluesfilterFrom;
                    console.log('filteringredients',filteringredients)
                    console.log('jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom]',jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom])
                    //var possibleMainvaluesMasterValuKey:any=jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom]
                    console.log('5849933030',JSON.parse(jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom]))
                    var possibleMainvaluesMasterValuKey:any=JSON.parse(jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom])
                    if(possibleMainvaluesMasterValuKey.hasOwnProperty(filtermatched?.possibleMainvaluesfilterFrom.comparingPMVKey))
                      {
                        console.log('possibleMainvaluesMasterValuKey',possibleMainvaluesMasterValuKey[filtermatched?.possibleMainvaluesfilterFrom.comparingPMVKey],possibleMainvaluesMasterValuKey)
                    
                        var matchedPossibleMainValue=filtermatched.possibleMainValues.find(a=>a.comparingWithPMValue.toString()===possibleMainvaluesMasterValuKey[filtermatched?.possibleMainvaluesfilterFrom.comparingPMVKey].toString())
                        console.log('matchedPossibleMainValue',matchedPossibleMainValue)
                        if(matchedPossibleMainValue)
                        possibleMainValueGot=matchedPossibleMainValue?.possibleMainvaluesId
                      }
                   
                  }
                  else
                  {
                    console.log('else part-2')

                  }
                  
                }
               
                
                childResultAfterFilteration['response'].push({
                  possibleFilterId:filtermatched.id,
                  possibleMainvaluesId:possibleMainValueGot,
                  result:jsonState
                })
                
               
              }
              else{
                //childResultAfterFilteration['head']=innermassAPIList[childResult]['outletId']

              }

            }

            childResultAfterFilterationList.push(childResultAfterFilteration)

          }


          finalchildResultAfterFilterationList.push(childResultAfterFilterationList)

          


      }


      console.log('finalchildResultAfterFilterationList',finalchildResultAfterFilterationList)

     

    }
    else{
      console.log('loop all ila');

      var childResultAfterFilterationElse:any={
        response:[]
      }

      var resultFormed:boolean=false

      if(childApicallConfiguration.apiIter)
      {
        console.log('paymaent method filterkey',filterkey)
          console.log('paymaent method start->childConfigDetailsApiResponse',childConfigDetailsApiResponse)
          if(childConfigDetailsApiResponse?.length>0)
          {
            for(let itr=0;itr<childConfigDetailsApiResponse?.length;itr++)
            {
              childResultAfterFilterationElse['head']=inputFeature.masterConfigKey

              console.log('childConfigDetailsApiResponse[itr]',childConfigDetailsApiResponse[itr],childConfigDetailsApiResponse[itr][filterkey])
              if(filterkey!='')
                {
                  console.log('inputFeature.possibleFilters',inputFeature.possibleFilters)
                  var filtermatcheditr:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id===childConfigDetailsApiResponse[itr][filterkey])
                  console.log('filtermatched',filtermatcheditr)
                  if(filtermatcheditr)
                  {
                    childResultAfterFilterationElse['response'].push({
                      possibleFilterId:filtermatcheditr.id,
                      possibleMainvaluesId:0,
                      result:childConfigDetailsApiResponse[itr]
                       })
                   
                  }
                 
                }
             

            }

            finalchildResultAfterFilterationList.push(childResultAfterFilterationElse)
            resultFormed=true


          }
      }

      

      if(inputFeature.isGroupByMandatory)
        {
          console.log('consolidate group by start ')
          console.log('Groupby-childConfigDetailsApiResponse',childConfigDetailsApiResponse)
          resultFormed=true

            console.log('groupby logic start')
          console.log('inputFeature.groupBy',inputFeature.groupBy)
          console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse);

          //get the hostids by input parameter 
          var grpbyuniqueIds:any[]=[]
          let resultByHostListNew:any[]=[]

          var childGroupByResult:any={
            response:[]
          }
          childGroupByResult['head']=inputFeature.masterConfigKey

          // childGroupByResult['response'].push({
          //   possibleFilterId:0,
          //   possibleMainvaluesId:0,
          //   group:0,
          //   groupName:'',
          //   result:[]
          // })
          //finalchildResultAfterFilterationList.push(childnoResult)
          let resultByHostListNewConsolidate:any[]=[]
          // var findnongrp=childConfigDetailsApiResponse.filter(p=>p[groupingconfigKeyName]==groupingconfigKeyResult);
          //         var grplist =inputFeature.groupBy.grpDatas.map(item => item.groupingconfigValueResult);

          //         // Extract the names from the objects
          //         const objectNames = findnongrp?.map(obj => obj[groupingconfigValueName]);
          //         //["STAY", "STAY", "bala"]
                  

                  // Find the mismatch count
                  let mismatchCount:number = 0;

          for(let grpvalue=0;grpvalue<inputFeature.groupBy.grpDatas.length;grpvalue++)
          {
            
                //console.log('childConfigDetailsApiResponse[g]',childConfigDetailsApiResponse[g])
            
                var groupBykey=inputFeature.groupBy.grpDatas[grpvalue].groupBykey;
                var groupingconfigKeyName=inputFeature.groupBy.grpDatas[grpvalue].groupingconfigKeyName;
                var groupingconfigValueName=inputFeature.groupBy.grpDatas[grpvalue].groupingconfigValueName;
                var groupingconfigKeyResult=inputFeature.groupBy.grpDatas[grpvalue].groupingconfigKeyResult;
                var groupingconfigValueResult=inputFeature.groupBy.grpDatas[grpvalue].groupingconfigValueResult;
            
                console.log('groupBykey',groupBykey,'groupingconfigKeyName',groupingconfigKeyName,'groupingconfigValueName',groupingconfigValueName,'groupingconfigKeyResult',groupingconfigKeyResult,'groupingconfigValueResult',groupingconfigValueResult)
                
                if(inputFeature.groupBy.isGroupByProgramatically)
                {
                  var prggrpByUniqueList:any[]=[]
                  //need to optimize
                  for(let splitbyprog=0;splitbyprog<childConfigDetailsApiResponse?.length;splitbyprog++)
                  {
                    let input=childConfigDetailsApiResponse[splitbyprog][groupingconfigKeyName];
                    //childConfigDetailsApiResponse[0][groupingconfigKeyName]
                    const parts = input.split('.')
                    if(!prggrpByUniqueList.includes(parts[1]))
                    {
                      prggrpByUniqueList.push(parts[1])
                    }
                    
                  }
                  let prggrpresultByHostListNew:any[]=[]
                  if(prggrpByUniqueList.length)
                  {
                    const prggrpnewResultByHost={
                      groupId:inputFeature.groupBy.grpDatas[grpvalue].groupById,
                        groupName:inputFeature.masterConfigKey,
                        groupResponse:[]

                    }

                    for(let r=0;r<prggrpByUniqueList.length;r++)
                      {
                        
                        const prggrpresultByHost={ 
                          CommonName:prggrpByUniqueList[r],
                          groupedResponse:[]
                        }
                        let offerlist:any[]=[]
                        for(let xt=0;xt<childConfigDetailsApiResponse?.length;xt++)
                        {
                          
                          let input=childConfigDetailsApiResponse[xt][groupingconfigKeyName];
                    //childConfigDetailsApiResponse[0][groupingconfigKeyName]
                           const parts = input.split('.')
                           if(parts[1]==prggrpByUniqueList[r])
                           {
                            offerlist.push(childConfigDetailsApiResponse[xt])
                           }
                        }
                        
                
                        //let groupedRes=childConfigDetailsApiResponse.filter(a=>a[groupBykey]==grpbyuniqueIdsNew[r]);
                        //console.log('groupedRes',groupedRes);
                        prggrpresultByHost.groupedResponse=offerlist;
                        prggrpnewResultByHost.groupResponse.push(prggrpresultByHost)
                
                      }
                      prggrpresultByHostListNew.push(prggrpnewResultByHost)
                  }


                  //write split and get unique values
                  
                   console.log('isGroupByProgramatically-childConfigDetailsApiResponse,prggrpByUniqueList',childConfigDetailsApiResponse,prggrpByUniqueList)

                   if(prggrpresultByHostListNew.length>0)
                    {
                      resultByHostListNewConsolidate.push(prggrpresultByHostListNew)
    
                    }
                }
                else{
                  var findnongrp=childConfigDetailsApiResponse.filter(p=>p[groupingconfigKeyName]==groupingconfigKeyResult);
                  var grplist =inputFeature.groupBy.grpDatas.map(item => item.groupingconfigValueResult);

                  // Extract the names from the objects
                  const objectNames = findnongrp?.map(obj => obj[groupingconfigValueName]);
                  //["STAY", "STAY", "bala"]
                  

                  // Find the mismatch count
                  mismatchCount = objectNames.filter(name => !grplist.includes(name))?.length;
                  var grpByUniqueList:any[]= childConfigDetailsApiResponse.filter(p=>p[groupingconfigKeyName]==groupingconfigKeyResult && p[groupingconfigValueName]==groupingconfigValueResult)
                console.log('grpByUniqueList',grpByUniqueList)

                var grpbyuniqueIdsNew:any[]= grpByUniqueList.map(a=>{return a.hostId})

                  console.log('grpbyuniqueIdsNew',grpbyuniqueIdsNew)
                  let resultByHostListNew:any[]=[]

                  if(grpbyuniqueIdsNew.length)
                  {
                      const newResultByHost={
                        groupId:inputFeature.groupBy.grpDatas[grpvalue].groupById,
                          groupName:groupingconfigValueResult,
                          groupResponse:[]

                      }
                      for(let r=0;r<grpbyuniqueIdsNew.length;r++)
                      {
                        
                        const resultByHost={ 
                          CommonName:grpbyuniqueIdsNew[r],
                          groupedResponse:[]
                        }
                
                        let groupedRes=childConfigDetailsApiResponse.filter(a=>a[groupBykey]==grpbyuniqueIdsNew[r]);
                        console.log('groupedRes',groupedRes);
                        resultByHost.groupedResponse=groupedRes;
                        newResultByHost.groupResponse.push(resultByHost)
                
                      }
                      resultByHostListNew.push(newResultByHost)

                  }

               console.log('resultByHostListNew',resultByHostListNew)
               if(resultByHostListNew.length>0)
                {
                  resultByHostListNewConsolidate.push(resultByHostListNew)

                }


                }
                

          }

          console.log('resultByHostListNewConsolidate',resultByHostListNewConsolidate)
          if(mismatchCount<1)
          {
            if(resultByHostListNewConsolidate?.length>0)
              {
                for(let ghc=0;ghc<resultByHostListNewConsolidate?.length;ghc++)
                  {
                    console.log('resultByHostListNewConsolidate[ghc]',resultByHostListNewConsolidate[ghc])
                    var groupResponselevel1=resultByHostListNewConsolidate[ghc][0]['groupResponse']
                    console.log('groupResponselevel1',groupResponselevel1)
                    childGroupByResult['response'].push({
                      possibleFilterId:0,
                      possibleMainvaluesId:0,
                      group:resultByHostListNewConsolidate[ghc][0]['groupId'],
                      groupName:resultByHostListNewConsolidate[ghc][0]['groupName'],
                      result:resultByHostListNewConsolidate[ghc][0]['groupResponse']
                    })
                  }
                
              }
              else
              {
                var childGroupByResult:any={
                  response:[]
                }
                childGroupByResult['head']=inputFeature.masterConfigKey
      
                childGroupByResult['response'].push({
                  possibleFilterId:0,
                  possibleMainvaluesId:0,
                  group:0,
                  groupName:'',
                  result:[]
                })
                //finalchildResultAfterFilterationList.push(childGroupByResult)
    
              }

          }
          else{

            var childGroupByResult:any={
              response:[]
            }
            childGroupByResult['head']=inputFeature.masterConfigKey
  
            childGroupByResult['response'].push({
              possibleFilterId:0,
              possibleMainvaluesId:0,
              group:-1,
              groupName:'kindly correct the grp value',
              result:[]
            })

          }
          
          
          finalchildResultAfterFilterationList.push(childGroupByResult)



          // if(inputFeature.groupBy)
// {
//   console.log('groupby logic start')
//   console.log('inputFeature.groupBy',inputFeature.groupBy)
//   console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse);

//   //get the hostids by input parameter 
//   var grpbyuniqueIds:any[]=[]
//   let resultByHostListNew:any[]=[]

//   for(let grpvalue=0;grpvalue<inputFeature.groupBy.length;grpvalue++)
//   {

//     //console.log('childConfigDetailsApiResponse[g]',childConfigDetailsApiResponse[g])

//     var groupBykey=inputFeature.groupBy[grpvalue].groupBykey;
//     var groupingconfigKeyName=inputFeature.groupBy[grpvalue].groupingconfigKeyName;
//     var groupingconfigValueName=inputFeature.groupBy[grpvalue].groupingconfigValueName;
//     var groupingconfigKeyResult=inputFeature.groupBy[grpvalue].groupingconfigKeyResult;
//     var groupingconfigValueResult=inputFeature.groupBy[grpvalue].groupingconfigValueResult;

//     console.log('groupBykey',groupBykey,'groupingconfigKeyName',groupingconfigKeyName,'groupingconfigValueName',groupingconfigValueName,'groupingconfigKeyResult',groupingconfigKeyResult,'groupingconfigValueResult',groupingconfigValueResult)
//     // console.log('childConfigDetailsApiResponse[g][groupingconfigKeyName]',childConfigDetailsApiResponse[g][groupingconfigKeyName])
//     // console.log('childConfigDetailsApiResponse[g][groupingconfigKeyName]',childConfigDetailsApiResponse[g][groupingconfigValueName])

//     var grpByUniqueList:any[]= childConfigDetailsApiResponse.filter(p=>p[groupingconfigKeyName]==groupingconfigKeyResult && p[groupingconfigValueName]==groupingconfigValueResult)
//     console.log('grpByUniqueList',grpByUniqueList)

//     var grpbyuniqueIdsNew:any[]= grpByUniqueList.map(a=>{return a.hostId})

//     console.log('grpbyuniqueIdsNew',grpbyuniqueIdsNew)
//     //let resultByHostListNew:any[]=[]


//     if(grpbyuniqueIdsNew.length)
//     {
//         const newResultByHost={
//           groupId:inputFeature.groupBy[grpvalue].groupById,
//             groupName:groupingconfigValueResult,
//             groupResponse:[]

//         }
//         for(let r=0;r<grpbyuniqueIdsNew.length;r++)
//         {
          
//           const resultByHost={ 
//             CommonName:grpbyuniqueIdsNew[r],
//             groupedResponse:[]
//           }
  
//           let groupedRes=childConfigDetailsApiResponse.filter(a=>a[groupBykey]==grpbyuniqueIdsNew[r]);
//           console.log('groupedRes',groupedRes);
//           resultByHost.groupedResponse=groupedRes;
//           newResultByHost.groupResponse.push(resultByHost)
  
//         }
//         resultByHostListNew.push(newResultByHost)

//     }

//       console.log('resultByHostListNew',resultByHostListNew)


//     // for(let f=0;f<resultByHostListNew.length;f++)
//     //   {
//     //     finalChildConfiguredApiResult.push(resultByHostListNew[f])
//     //   }

//     //   console.log('new groupby-grpby finalChildConfiguredApiResult',finalChildConfiguredApiResult)
//   }

//   for(let f=0;f<resultByHostListNew.length;f++)
//     {
//       finalChildConfiguredApiResult.push(resultByHostListNew[f])
//     }

//     console.log('new groupby-grpby finalChildConfiguredApiResult',finalChildConfiguredApiResult)

  

//       const newRes={
//         'childresponseFilterId':1,
//         'response':finalChildConfiguredApiResult
//       }

//       newfinalChildConfiguredApiResult.push(newRes)

//       resultformed=true;

    

//   console.log('groupby logic end')


// }

          

          console.log('consolidate group by end ')
        }

       
      if(!inputFeature.isFilterMandatory && !resultFormed)
      {
        childResultAfterFilterationElse['head']=inputFeature.masterConfigKey

        console.log('is not filter side')

        if(filterkey=='')
        {
          childResultAfterFilterationElse['response'].push({
            possibleFilterId:0,
            possibleMainvaluesId:0,
            result:childConfigDetailsApiResponse
          })
        }
        finalchildResultAfterFilterationList.push(childResultAfterFilterationElse)

      }
      else
      {
        if(!resultFormed)
        {
            //var childResultAfterFilterationListElse:any[]=[]
          childResultAfterFilterationElse['head']=inputFeature.masterConfigKey


          for(let n=0;n<childConfigDetailsApiResponse?.length;n++)
          {
            console.log('filterkey',filterkey)



            if(filterkey=='')
              {
                var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==0)

                var compareKeyIfFilterKeyMissed=filtermatched.possibleMainvaluesfilterFrom.comparingPMVKey
                var possiblemainValueRes:PossibleMainValue=filtermatched.possibleMainValues.find(a=>a.comparingWithPMValue.toLowerCase()==childConfigDetailsApiResponse[n][compareKeyIfFilterKeyMissed].toLowerCase())
                console.log('possiblemainValueRes',possiblemainValueRes)
                if(possiblemainValueRes)
                  {
                childResultAfterFilterationElse['response'].push({
                  possibleFilterId:0,
                  possibleMainvaluesId:possiblemainValueRes.possibleMainvaluesId,
                  result:childConfigDetailsApiResponse[n]
                })
              }

              }
              else
              {
                console.log('dvebeveeeb4b3brbb5nnnrrbrbbrb')
                //var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==0)

              }

            console.log('childConfigDetailsApiResponse[n]',childConfigDetailsApiResponse[n])



          }

          finalchildResultAfterFilterationList.push(childResultAfterFilterationElse)
         }

      }
            
      console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse)

    }

  }
  else
  {
    var childnoResult:any={
      response:[]
    }
    childnoResult['head']=inputFeature.masterConfigKey

    childnoResult['response'].push({
      possibleFilterId:0,
      possibleMainvaluesId:0,
      result:[]
    })
    finalchildResultAfterFilterationList.push(childnoResult)

  }
console.log('finalchildResultAfterFilterationList-finalle',finalchildResultAfterFilterationList)

console.log('newfinalChildConfiguredApiResult',newfinalChildConfiguredApiResult)
console.log('initialChildApiCallWithFilteredMaster-finalChildConfiguredApiResult',finalChildConfiguredApiResult);
  
return finalchildResultAfterFilterationList;
//return childConfigDetailsApiResponse;
}


// async initialChildApiCallWithFilteredMasterPMS(masterConfigDetails :any[],childApicallConfiguration:ApiCall2, inputFeature: Configuration):Promise<any[]>
// {
//   console.log('initialChildApiCallWithFilteredMaster-start','masterConfigDetail',masterConfigDetails,'inputFeature',inputFeature,'childApicallConfiguration',childApicallConfiguration)
//   var childApiRawList: any[]=[]
//   var childConfigResponse1:any[]=[]

//   if(childApicallConfiguration.loopall)
//   {
//     console.log('initialChildApiCallWithFilteredMasterPMS loop start')
//     for(let mslist=0;mslist<masterConfigDetails?.length;mslist++)
//       {
//         var childConfigResponse = await this.configValidationService.getChildUrlConfigurationApiCallPMS(masterConfigDetails[mslist],childApicallConfiguration);
//         console.log('initialChildApiCallWithFilteredMaster-childConfigResponse1',childConfigResponse1);
//         childApiRawList.push(childConfigResponse);
        

//       }

//       var childConfigResponse1:any[]=childApiRawList;
 
//   }
//   else{
//     console.log('initialChildApiCallWithFilteredMasterPMS loop avoided')

//     var childConfigResponse1:any[] = await this.configValidationService.getChildUrlConfigurationApiCall(masterConfigDetails[0],childApicallConfiguration);
//     console.log('initialChildApiCallWithFilteredMaster-childConfigResponse1',childConfigResponse1);

//   }
// console.log('childConfigResponse1',childConfigResponse1)
// let childConfigDetailsApiResponse: any[]=[];
// let finalChildConfiguredApiResult:any[]=[]

// let newfinalChildConfiguredApiResult:any[]=[];


// if(childConfigResponse1!=null && Array.isArray(childConfigResponse1))
// {
//   childConfigDetailsApiResponse=childConfigResponse1;
// }
// else{
//   if(childConfigResponse1!=null)
//   childConfigDetailsApiResponse.push(childConfigResponse1);
// }

// console.log('childConfigDetailsApiResponse.length',childConfigDetailsApiResponse.length,childConfigDetailsApiResponse)


// var resultformed:boolean=false;
// var finalchildResultAfterFilterationList:any[]=[]


// //new all configuration
// if(childConfigDetailsApiResponse?.length>0)
//   {
//     var filterkey=''
//     if(childApicallConfiguration.childApiDataFilterby.startsWith('*'))
//     {
//       filterkey=this.splitmasterkey(childApicallConfiguration.childApiDataFilterby);
//       console.log('filterkey',filterkey)
//     }

//     if(childApicallConfiguration.loopall)
//     {

      
//       for(let massApiList=0;massApiList<childConfigDetailsApiResponse.length;massApiList++)
//       {
//         var childResultAfterFilterationList:any[]=[]
        
        
//         var innermassAPIList:any[]=childConfigDetailsApiResponse[massApiList];


//         if(inputFeature.possibleFilters.length>0)
//           {
//             var childResultAfterFilteration:any={
//               response:[]
//             }
           
//             for(let childResult=0;childResult<innermassAPIList.length;childResult++)
//             {
//               var possibleMainValueGot=0
//               childResultAfterFilteration['head']=innermassAPIList[childResult]['outletId']

//               console.log('filter starts for', innermassAPIList[childResult],innermassAPIList[childResult][filterkey])
//               console.log('inputFeature.possibleFilters',inputFeature.possibleFilters)

//               if(filterkey!='')
//                 {
//                   var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==innermassAPIList[childResult][filterkey])

//                 }
//                 else
//                 {
//                   var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==0)

//                 }
              
//               if(childApicallConfiguration.isConfigResultFromJSON)
//               {
//                  var stringState=innermassAPIList[childResult][childApicallConfiguration.configResultFrom]
//                  console.log('stringState',stringState)
//                  var jsonState:any=JSON.parse(stringState);
//                  //var tenderInformation: TenderInformation = JSON.parse(stringState);
//                 //var configuration: TenderConfiguration = JSON.parse(tenderInformation.C
//                  //console.log('jsonState',jsonState,'tenderInformation',tenderInformation)
//               }
//               else
//               {
//                  console.log('else part-1')
//               }
//               console.log('filtermatched',filtermatched)
//               if(filtermatched)
//               {
                
//                 if(filtermatched?.possibleMainValues.length>0)
//                 {

//                   if(childApicallConfiguration.isConfigResultFromJSON)
//                   {
                   
//                     var filteringredients=filtermatched?.possibleMainvaluesfilterFrom;
//                     console.log('filteringredients',filteringredients)
//                     console.log('jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom]',jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom])
//                     //var possibleMainvaluesMasterValuKey:any=jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom]
//                     console.log('5849933030',JSON.parse(jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom]))
//                     var possibleMainvaluesMasterValuKey:any=JSON.parse(jsonState[filtermatched?.possibleMainvaluesfilterFrom.mastervalueKeyFrom])
//                     console.log('possibleMainvaluesMasterValuKey',possibleMainvaluesMasterValuKey[filtermatched?.possibleMainvaluesfilterFrom.comparingPMVKey],possibleMainvaluesMasterValuKey)
//                     var matchedPossibleMainValue=filtermatched.possibleMainValues.find(a=>a.comparingWithPMValue.toString()===possibleMainvaluesMasterValuKey[filtermatched?.possibleMainvaluesfilterFrom.comparingPMVKey].toString())
//                     console.log('matchedPossibleMainValue',matchedPossibleMainValue)
//                     if(matchedPossibleMainValue)
//                     possibleMainValueGot=matchedPossibleMainValue?.possibleMainvaluesId
//                   }
//                   else
//                   {
//                     console.log('else part-2')

//                   }
                  
//                 }
               
                
//                 childResultAfterFilteration['response'].push({
//                   possibleFilterId:filtermatched.id,
//                   possibleMainvaluesId:possibleMainValueGot,
//                   result:jsonState
//                 })
                
               
//               }
//               else{
//                 //childResultAfterFilteration['head']=innermassAPIList[childResult]['outletId']

//               }

//             }

//             childResultAfterFilterationList.push(childResultAfterFilteration)

//           }


//           finalchildResultAfterFilterationList.push(childResultAfterFilterationList)

          


//       }


//       console.log('finalchildResultAfterFilterationList',finalchildResultAfterFilterationList)

     

//     }
//     else{
//       console.log('loop all ila');

//       var childResultAfterFilterationElse:any={
//         response:[]
//       }

//       if(!inputFeature.isFilterMandatory)
//       {
//         childResultAfterFilterationElse['head']=inputFeature.masterConfigKey

//         console.log('is not filter side')

//         if(filterkey=='')
//         {
//           childResultAfterFilterationElse['response'].push({
//             possibleFilterId:0,
//             possibleMainvaluesId:0,
//             result:childConfigDetailsApiResponse
//           })
//         }
//         finalchildResultAfterFilterationList.push(childResultAfterFilterationElse)

//       }
//       else
//       {
//             //var childResultAfterFilterationListElse:any[]=[]
//       childResultAfterFilterationElse['head']=inputFeature.masterConfigKey


//       for(let n=0;n<childConfigDetailsApiResponse?.length;n++)
//         {
//           console.log('filterkey',filterkey)



//           if(filterkey=='')
//             {
//               var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==0)

//               var compareKeyIfFilterKeyMissed=filtermatched.possibleMainvaluesfilterFrom.comparingPMVKey
//               var possiblemainValueRes:PossibleMainValue=filtermatched.possibleMainValues.find(a=>a.comparingWithPMValue.toLowerCase()==childConfigDetailsApiResponse[n][compareKeyIfFilterKeyMissed].toLowerCase())
//               console.log('possiblemainValueRes',possiblemainValueRes)

//               childResultAfterFilterationElse['response'].push({
//                 possibleFilterId:0,
//                 possibleMainvaluesId:possiblemainValueRes.possibleMainvaluesId,
//                 result:childConfigDetailsApiResponse[n]
//               })

//             }
//             else
//             {
//               console.log('dvebeveeeb4b3brbb5nnnrrbrbbrb')
//               //var filtermatched:PossibleFilter=inputFeature.possibleFilters.find(a=>a.id==0)

//             }

//           console.log('childConfigDetailsApiResponse[n]',childConfigDetailsApiResponse[n])



//         }

//         finalchildResultAfterFilterationList.push(childResultAfterFilterationElse)
//       }

      

      




//       console.log('childConfigDetailsApiResponse',childConfigDetailsApiResponse)

//       //configResultFrom



//     }

   

   


//   }
//   else
//   {
//     var childnoResult:any={
//       response:[]
//     }
//     childnoResult['head']=inputFeature.masterConfigKey

//     childnoResult['response'].push({
//       possibleFilterId:0,
//       possibleMainvaluesId:0,
//       result:[]
//     })
//     finalchildResultAfterFilterationList.push(childnoResult)

//   }
// console.log('finalchildResultAfterFilterationList',finalchildResultAfterFilterationList)

// console.log('newfinalChildConfiguredApiResult',newfinalChildConfiguredApiResult)
// console.log('initialChildApiCallWithFilteredMaster-finalChildConfiguredApiResult',finalChildConfiguredApiResult);
  
// return finalchildResultAfterFilterationList;
// //return childConfigDetailsApiResponse;
// }

// async validateBothInputJsonAndApiResponsePMS(finalValidateJsonResponse: InputValidationConfigJsonApiPMSModel):Promise<ConfigValidationUIModel[]>
// {
//   let finalUIResult:ConfigValidationUIModel[]=[];

//   console.log('START &77777&&&&& validateBothInputJsonAndApiResponsePMS')
//   var uimodelnumber=1

//   for(let br=0;br<finalValidateJsonResponse.configurations.length;br++)
//   {

//     var inputFeature:Configuration=finalValidateJsonResponse['configurations'][br];
//     var apiResult:any[]=inputFeature.ConfigApiresult;
    
//     console.log(apiResult[0]['isLoopedResult'])
//     var apiDataFromDB=apiResult[0]
//     var ValidatemasterResponse:any[]= apiDataFromDB['response']

//     if(apiDataFromDB['isLoopedResult'])
//     {
//       console.log('looped-apiresult(masterResponse)',ValidatemasterResponse)

//       for(let mr=0;mr<ValidatemasterResponse.length;mr++)
//       {
//         console.log('start masterResponse',mr,ValidatemasterResponse[mr])
//         var childHead=ValidatemasterResponse[mr][0]['head']
//         var ValidatechildResponse=ValidatemasterResponse[mr][0]['response']

//         console.log('ValidatechildResponse',ValidatechildResponse)

//         for(let vcr=0;vcr<ValidatechildResponse.length;vcr++)
//         {
//           console.log('ready ValidatechildResponse[vcr]',ValidatechildResponse[vcr])
//           var possibleFilterIdFromDb=ValidatechildResponse[vcr]['possibleFilterId']
//           var possibleMainvaluesIdFromDb=ValidatechildResponse[vcr]['possibleMainvaluesId']
//           console.log('possibleFilterIdFromDb',possibleFilterIdFromDb,'possibleMainvaluesIdFromDb',possibleMainvaluesIdFromDb)
          
//           var legitimateConfigurationList:LegitiamteConfiguration[]=inputFeature.legitiamteConfiguration.filter(a=>a.FilterBaseIds.possibleFilterId==possibleFilterIdFromDb && a.FilterBaseIds.possibleMainvaluesId== possibleMainvaluesIdFromDb)
//           console.log('legitimateConfigurationList',legitimateConfigurationList)
//           if(legitimateConfigurationList.length>0)
//           {
//             var configKeyAndValuesList:ConfigKeyAndValue[]=legitimateConfigurationList[0].configKeyAndValues;
//           console.log('configKeyAndValuesList',configKeyAndValuesList)

//           for(let vkl=0;vkl<configKeyAndValuesList.length;vkl++)
//           {

//             var specialKey=configKeyAndValuesList[vkl].comparewithchildren[0].childrenDataFilterBy
//             console.log('splitResultForValidationList',inputFeature.splitResultForValidation)
//             console.log('configKeyAndValuesList[vkl]',configKeyAndValuesList[vkl])
//             var matchedsplitResultForValidation:SplitResultForValidation=inputFeature.splitResultForValidation.find(s=>s.splitResultForValidationId.toString()==configKeyAndValuesList[vkl].splitResultForValidationId.toString())
//             console.log('matchedsplitResultForValidation',matchedsplitResultForValidation)
//             console.log('***************************')
//             console.log('ValidatechildResponse[vcr]',ValidatechildResponse[vcr])
//             console.log('***************************')
//             var mastervalueKeyFromResult:any[]=[]
//             if(matchedsplitResultForValidation.ismastervalueKeyFromJSON)
//             {

             
//               mastervalueKeyFromResult.push(JSON.parse(ValidatechildResponse[vcr]['result'][matchedsplitResultForValidation.mastervalueKeyFrom]));
//               console.log('mastervalueKeyFromResult',mastervalueKeyFromResult);

//             }

//             var childValueKeyFromResult:any[]=[]

//             if(matchedsplitResultForValidation.childValueKeyFrom!=''&&matchedsplitResultForValidation.childValueKeyFrom!=null)
//             {
//                 if(mastervalueKeyFromResult.length>0)
//                 {
//                   console.log('mastervalueKeyFromResult[0]',mastervalueKeyFromResult[0])
//                   console.log('mastervalueKeyFromResult[0]',mastervalueKeyFromResult[0]['configuration'])

//                   childValueKeyFromResult=[...mastervalueKeyFromResult[0][matchedsplitResultForValidation.childValueKeyFrom]]
//                   console.log('childValueKeyFromResult',childValueKeyFromResult)
//                 }
//             }
            
//             var ConfigKeyAndValuesSubsetList:ConfigKeyAndValuesSubset[]=configKeyAndValuesList[vkl].configKeyAndValuesSubset
//             var finalDBResult:any[]=childValueKeyFromResult.length>0?childValueKeyFromResult:mastervalueKeyFromResult;
//             console.log('$$$$$$$$$$$$$$')
//             console.log('finalDBResult',finalDBResult)

//             for(let kvs=0; kvs<ConfigKeyAndValuesSubsetList.length;kvs++)
//             {

//               let uiModel: ConfigValidationUIModel={
//                 id: uimodelnumber,
//                 config:childHead,
//                 configkey: ConfigKeyAndValuesSubsetList[kvs].configurationName,
//                 reason: 'Cool..! Looks Fine',
//                 status: ConfigValidationStatus.success
//               }

             
//              console.log('specialKey',specialKey)
//              console.log('ConfigKeyAndValuesSubsetList[kvs]',ConfigKeyAndValuesSubsetList[kvs])
//              console.log('ConfigKeyAndValuesSubsetList[kvs].configurationName',ConfigKeyAndValuesSubsetList[kvs].configurationName)

//              var validResult=finalDBResult.find(f=>f[specialKey]===ConfigKeyAndValuesSubsetList[kvs].configurationName)
//              console.log('validResult',validResult)

//              if(validResult)
//               {
//                 var expectedValues:any[]=ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].expectedValue
//                 var ValuesFromDB:any[]=validResult[ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue]
//                 console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
//                 var errorCount:number=0;
//                 if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB))
//                  {
//                    errorCount=errorCount+1;
//                    //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                    uiModel.reason='Not an expected value there!'
//                    uiModel.status=ConfigValidationStatus.Fail
//                    console.log('not an expected value there!')
//                  }

//               }
//               else
//               {
//                 errorCount=errorCount+1;
//                    //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                    uiModel.reason='expected key is not there.!'
//                    uiModel.status=ConfigValidationStatus.Fail
//                    console.log('expected key is not there')

//               }
            

//               finalUIResult.push(uiModel)
//               uimodelnumber=uimodelnumber+1;


//             }


//           }

//           }
          
//         }
//         console.log('end masterResponse',mr,ValidatemasterResponse[mr])
//       }



//     }
//     else
//     {

//       console.log('nonlooped-apiresult(masterResponse)-NFA',ValidatemasterResponse)

//       if(!inputFeature.isFilterMandatory)
//       {
//         console.log('no possible filter area -nfa')
//         var childHeadNFA=ValidatemasterResponse[0]['head']
//       var ValidatechildResponseNfa:any[]=ValidatemasterResponse[0]['response']
//       console.log('childHeadNFA',childHeadNFA,'ValidatechildResponseNfa',ValidatechildResponseNfa)
//       for(let vcnfa=0;vcnfa<ValidatechildResponseNfa?.length;vcnfa++)
//         {
//       var matchedlegitiamteConfigurationdNFA:LegitiamteConfiguration[]= inputFeature.legitiamteConfiguration.filter(l=>l.FilterBaseIds.possibleMainvaluesId==ValidatechildResponseNfa[vcnfa]['possibleMainvaluesId']&& l.FilterBaseIds.possibleFilterId==0) 
//         console.log('matchedlegitiamteConfigurationdNFA',matchedlegitiamteConfigurationdNFA)
//         for(let c=0;c<matchedlegitiamteConfigurationdNFA[0].configKeyAndValues.length;c++)
//         {

//           var specialKey=matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].comparewithchildren[0].childrenDataFilterBy

//           // var matchedSplitResultValidationNlp:SplitResultForValidation=inputFeature.splitResultForValidation.find(sp=>sp.splitResultForValidationId.toString()==matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].splitResultForValidationId.toString())   
//           // console.log('matchedSplitResultValidationNlp',matchedSplitResultValidationNlp)

//           var mastervalueKeyFromResultNFa:any[]=[]
//           var tempresponseNfa=ValidatechildResponseNfa[c]['result']

//           //var tempresponse=ValidatechildResponseNoLp[vcnlp]['result'][matchedSplitResultValidationNlp.mastervalueKeyFrom]
//           if(Array.isArray(tempresponseNfa))
//                   {
//                     mastervalueKeyFromResultNFa=tempresponseNfa
            
//                   }
//                   else{
//                     console.log('it is not an array');
//                     mastervalueKeyFromResultNFa.push(tempresponseNfa)
                    
//                   }
          
//           console.log('mastervalueKeyFromResultNlp',mastervalueKeyFromResultNFa);
//           console.log('specialKey',specialKey)
//           var subsetListNfa:ConfigKeyAndValuesSubset[]=matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].configKeyAndValuesSubset
//           console.log('subsetListNfa',subsetListNfa)

//           for(let kvsNfa=0; kvsNfa<subsetListNfa.length;kvsNfa++)
//             {
//               let uiModel: ConfigValidationUIModel={
//                 id: uimodelnumber,
//                 config:childHeadNFA,
//                 configkey: subsetListNfa[kvsNfa].configurationName,
//                 reason: 'Cool..! Looks Fine',
//                 status: ConfigValidationStatus.success
//               }

//               var validResult=mastervalueKeyFromResultNFa.find(f=>f[specialKey]===subsetListNfa[kvsNfa].configurationName)
//               console.log('validResult',validResult)

//               if(validResult)
//                 {
//                   var expectedValues:any[]=subsetListNfa[kvsNfa].validationConfig[0].expectedValue
//                   var ValuesFromDB:any[]=validResult[subsetListNfa[kvsNfa].validationConfig[0].ValuekeyFrom.Mainvalue]
//                   console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
//                   var errorCount:number=0;
//                   if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB))
//                     {
//                       errorCount=errorCount+1;
//                       //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                       uiModel.reason='Not an expected value there!'
//                       uiModel.status=ConfigValidationStatus.Fail
//                       console.log('not an expected value there!')
//                     }
  
//                 }
//                 else
//                 {
//                   errorCount=errorCount+1;
//                       //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                       uiModel.reason='expected key is not there.!'
//                       uiModel.status=ConfigValidationStatus.Fail
//                       console.log('expected key is not there')
  
//                 }
              
  
//                 finalUIResult.push(uiModel)
//                 uimodelnumber=uimodelnumber+1;
//             }

//         }

        



//       }

//       }
//       else{
//         var childHeadNoLp=ValidatemasterResponse[0]['head']
//       var ValidatechildResponseNoLp:any[]=ValidatemasterResponse[0]['response']
//       var possibleFilterNlp: PossibleFilter[]=inputFeature.possibleFilters.filter(p=>p.id==0)
//       console.log('ValidatechildResponseNoLp',ValidatechildResponseNoLp)
//       console.log('possibleFilterNlp',possibleFilterNlp)

//       for(let vcnlp=0;vcnlp<ValidatechildResponseNoLp?.length;vcnlp++)
//       {
        
//         var matchedlegitiamteConfiguration:LegitiamteConfiguration[]= inputFeature.legitiamteConfiguration.filter(l=>l.FilterBaseIds.possibleMainvaluesId==ValidatechildResponseNoLp[vcnlp]['possibleMainvaluesId']&& l.FilterBaseIds.possibleFilterId==0) 
//         console.log('legitiamteConfiguration',matchedlegitiamteConfiguration)
//         if(matchedlegitiamteConfiguration.length>0)
//         {
//         for(let ckv=0;ckv<matchedlegitiamteConfiguration[0].configKeyAndValues.length;ckv++)
//         {
//           var specialKey=matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].comparewithchildren[0].childrenDataFilterBy

//           var matchedSplitResultValidationNlp:SplitResultForValidation=inputFeature.splitResultForValidation.find(sp=>sp.splitResultForValidationId.toString()==matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].splitResultForValidationId.toString())   
//           console.log('matchedSplitResultValidationNlp',matchedSplitResultValidationNlp)

//           var mastervalueKeyFromResultNlp:any[]=[]
          
//           var tempresponse=ValidatechildResponseNoLp[vcnlp]['result'][matchedSplitResultValidationNlp.mastervalueKeyFrom]
//           if(Array.isArray(tempresponse))
//                   {
//                     mastervalueKeyFromResultNlp=tempresponse
            
//                   }
//                   else{
//                     console.log('it is not an array');
//                     mastervalueKeyFromResultNlp.push(tempresponse)
                    
//                   }
          
//           console.log('mastervalueKeyFromResultNlp',mastervalueKeyFromResultNlp);
//           console.log('specialKey',specialKey)
//           var subsetList:ConfigKeyAndValuesSubset[]=matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].configKeyAndValuesSubset

//           var ConfigKeyAndValuesSubsetList:ConfigKeyAndValuesSubset[]=configKeyAndValuesList[ckv].configKeyAndValuesSubset


//           for(let kvs=0; kvs<subsetList.length;kvs++)
//             {

//               let uiModel: ConfigValidationUIModel={
//                 id: uimodelnumber,
//                 config:childHeadNoLp,
//                 configkey: subsetList[kvs].configurationName,
//                 reason: 'Cool..! Looks Fine',
//                 status: ConfigValidationStatus.success
//               }

//               var validResult=mastervalueKeyFromResultNlp.find(f=>f[specialKey]===subsetList[kvs].configurationName)
//               console.log('validResult',validResult)

//               if(validResult)
//                 {
//                   var expectedValues:any[]=subsetList[kvs].validationConfig[0].expectedValue
//                   var ValuesFromDB:any[]=validResult[subsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue]
//                   console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
//                   var errorCount:number=0;
//                   if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB))
//                     {
//                       errorCount=errorCount+1;
//                       //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                       uiModel.reason='Not an expected value there!'
//                       uiModel.status=ConfigValidationStatus.Fail
//                       console.log('not an expected value there!')
//                     }
  
//                 }
//                 else
//                 {
//                   errorCount=errorCount+1;
//                       //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
//                       uiModel.reason='expected key is not there.!'
//                       uiModel.status=ConfigValidationStatus.Fail
//                       console.log('expected key is not there')
  
//                 }
              
  
//                 finalUIResult.push(uiModel)
//                 uimodelnumber=uimodelnumber+1;


//             }
          

          

//         }
//       }

//       }

//       }
      

        

      
//     }
    
    
    
//   }
  
//   console.log('THE eND &77777&&&&& validateBothInputJsonAndApiResponsePMS')


//   console.log('validateBothInputJsonAndApiResponsePMS-finalUIResult',finalUIResult)
//   return finalUIResult;
// }


//spa test




async validateBothInputJsonAndApiResponsePMS(finalValidateJsonResponse: InputValidationConfigJsonApiPMSModel):Promise<ConfigValidationUIModel[]>
{
  let finalUIResult:ConfigValidationUIModel[]=[];

  console.log('START &77777&&&&& validateBothInputJsonAndApiResponsePMS')
  var uimodelnumber=1

  for(let br=0;br<finalValidateJsonResponse.configurations.length;br++)
  {
    this.utilities.ToggleLoaderWithMessage(true,'Validations in progress please wait');
    var inputFeature:Configuration=finalValidateJsonResponse['configurations'][br];
    var inputHB:NeededHearbeats=finalValidateJsonResponse.neededHearbeats;
    var apiResult:any[]=inputFeature.ConfigApiresult;
    var mainRuleList:Rule[]=finalValidateJsonResponse.rules
    var globaldepinput:Globaldependency[]=finalValidateJsonResponse.globaldependencies
    console.log(apiResult[0]['isLoopedResult'])
    var apiDataFromDB=apiResult[0]
    var ValidatemasterResponse:any[]= apiDataFromDB['response']

    console.log('inputHB',inputHB)

    //looped result()
    if(apiDataFromDB['isLoopedResult'])
    {
    
      if(ValidatemasterResponse[0]['response']!=undefined)
      {
        let uiModel: ConfigValidationUIModel={
          id: uimodelnumber,
          config: ValidatemasterResponse[0]['head'],
          configkey: 'NIL',
          reason: 'no result found',
          status: ConfigValidationStatus.Fail,
          uiHeader: inputFeature.uiHeaderId,
          expectedValue: 'Nil'
        }

        finalUIResult.push(uiModel)
        uimodelnumber=uimodelnumber+1;
      }
      else{
        
      
      //console.log('ValidatemasterResponse[0][response]',ValidatemasterResponse[0]['response'])
      console.log('looped-apiresult(masterResponse)',ValidatemasterResponse)
      
      for(let mr=0;mr<ValidatemasterResponse.length;mr++)
      {
        console.log('start masterResponse',mr,ValidatemasterResponse[mr])
        var childHead=ValidatemasterResponse[mr][0]['head']
        var ValidatechildResponse=ValidatemasterResponse[mr][0]['response']

        console.log('ValidatechildResponse',ValidatechildResponse)

        for(let vcr=0;vcr<ValidatechildResponse.length;vcr++)
        {
          console.log('ready ValidatechildResponse[vcr]',ValidatechildResponse[vcr])
          var possibleFilterIdFromDb=ValidatechildResponse[vcr]['possibleFilterId']
          var possibleMainvaluesIdFromDb=ValidatechildResponse[vcr]['possibleMainvaluesId']
          console.log('possibleFilterIdFromDb',possibleFilterIdFromDb,'possibleMainvaluesIdFromDb',possibleMainvaluesIdFromDb)
          var legitimateConfigurationList:LegitiamteConfiguration[]=[]
          // if(possibleMainvaluesIdFromDb>0)
          // {
          //  legitimateConfigurationList=inputFeature.legitiamteConfiguration.filter(a=>a.FilterBaseIds.possibleFilterId==possibleFilterIdFromDb && a.FilterBaseIds.possibleMainvaluesId== possibleMainvaluesIdFromDb)

          // }
          //else{
             legitimateConfigurationList=inputFeature.legitiamteConfiguration.filter(a=>a.FilterBaseIds.possibleFilterId==possibleFilterIdFromDb)

          //}
          console.log('legitimateConfigurationList',legitimateConfigurationList)
          if(legitimateConfigurationList.length>0)
          {
            for(let loopleg=0;loopleg<legitimateConfigurationList.length;loopleg++)
            {

              var configKeyAndValuesList:ConfigKeyAndValue[]=legitimateConfigurationList[loopleg].configKeyAndValues;
          console.log('configKeyAndValuesList',configKeyAndValuesList)

          var subname:any=inputFeature.possibleFilters.find(a=>a.id==legitimateConfigurationList[loopleg].FilterBaseIds.possibleFilterId).possibleKey

          for(let vkl=0;vkl<configKeyAndValuesList.length;vkl++)
          {

            var specialKey=configKeyAndValuesList[vkl].comparewithchildren[0].childrenDataFilterBy
            console.log('splitResultForValidationList',inputFeature.splitResultForValidation)
            console.log('configKeyAndValuesList[vkl]',configKeyAndValuesList[vkl])
            var matchedsplitResultForValidation:SplitResultForValidation=inputFeature.splitResultForValidation.find(s=>s.splitResultForValidationId.toString()==configKeyAndValuesList[vkl].splitResultForValidationId.toString())
            console.log('matchedsplitResultForValidation',matchedsplitResultForValidation)
            console.log('***************************')
            console.log('ValidatechildResponse[vcr]',ValidatechildResponse[vcr])
            console.log('***************************')
            var mastervalueKeyFromResult:any[]=[]
            if(configKeyAndValuesList[vkl].splitResultForValidationId=='0')
            {
              console.log('zeroo split')
              mastervalueKeyFromResult.push(ValidatechildResponse[vcr]['result'])
            }
            if(matchedsplitResultForValidation?.ismastervalueKeyFromJSON)
            {

             
              mastervalueKeyFromResult.push(JSON.parse(ValidatechildResponse[vcr]['result'][matchedsplitResultForValidation.mastervalueKeyFrom]));
              console.log('mastervalueKeyFromResult',mastervalueKeyFromResult);

            }

            var childValueKeyFromResult:any[]=[]

            if(matchedsplitResultForValidation?.childValueKeyFrom!=''&&matchedsplitResultForValidation?.childValueKeyFrom!=null)
            {
                if(mastervalueKeyFromResult.length>0)
                {
                  console.log('mastervalueKeyFromResult[0]',mastervalueKeyFromResult[0])
                  console.log('mastervalueKeyFromResult[0]',mastervalueKeyFromResult[0]['configuration'])
                  if(mastervalueKeyFromResult[0][matchedsplitResultForValidation.childValueKeyFrom]!=null)
                  {
                    childValueKeyFromResult=[...mastervalueKeyFromResult[0][matchedsplitResultForValidation.childValueKeyFrom]]
                  console.log('childValueKeyFromResult',childValueKeyFromResult)
                  }
                }
            }
            
            var ConfigKeyAndValuesSubsetList:ConfigKeyAndValuesSubset[]=configKeyAndValuesList[vkl].configKeyAndValuesSubset
            var finalDBResult:any[]=childValueKeyFromResult.length>0?childValueKeyFromResult:mastervalueKeyFromResult;
            console.log('$$$$$$$$$$$$$$')
            console.log('finalDBResult',finalDBResult)
            //deptakecheck
            for(let kvs=0; kvs<ConfigKeyAndValuesSubsetList.length;kvs++)
            {
              var loopValidationProceed=true
                if(ConfigKeyAndValuesSubsetList[kvs].isbasedonDependencies)
                  {
                        var inputDependencyFilter:DependencyFilter=ConfigKeyAndValuesSubsetList[kvs].dependencyFilter;
                        console.log('globaldepinput',globaldepinput,globaldepinput.find(a=>a.id==inputDependencyFilter.id))
                        //globaldepinput.find(a=>a['depResults'])
                        var loopglobaldepinputobject: Globaldependency=globaldepinput.find(a=>a.id==inputDependencyFilter.id)
                        console.log('globaldepinputobject.globaldependenciesResult',loopglobaldepinputobject.globaldependenciesResult)
                        let loopmatcheddep:any=loopglobaldepinputobject.globaldependenciesResult.find(a=>a.configUniqId==inputDependencyFilter.configUniqId && a.configUniqValue===inputDependencyFilter.configuniqValue)
                      console.log('grpmatcheddep',loopmatcheddep)
                      if(loopmatcheddep==undefined)
                      {
                        loopValidationProceed=false
                      }
                  }

              if(loopValidationProceed)
              {

                var expectedValuefromJson:any[]=ConfigKeyAndValuesSubsetList[kvs]?.validationConfig[0]?.expectedValue
              let uiModel: ConfigValidationUIModel={
                id: uimodelnumber,
                config: childHead + '-' + subname,
                configkey: ConfigKeyAndValuesSubsetList[kvs].configurationName,
                reason: 'No Issues Found',
                status: ConfigValidationStatus.success,
                uiHeader: inputFeature.uiHeaderId,
                expectedValue: expectedValuefromJson
              }

             
             console.log('specialKey',specialKey)
             console.log('ConfigKeyAndValuesSubsetList[kvs]',ConfigKeyAndValuesSubsetList[kvs])
             console.log('ConfigKeyAndValuesSubsetList[kvs].configurationName',ConfigKeyAndValuesSubsetList[kvs].configurationName)
             if(specialKey=='')
             {
              var validResult=finalDBResult[0];
              console.log('validResult-splkey no',validResult)
             }
             if(specialKey!='')
             {
              var validResult=finalDBResult.find(f=>f[specialKey]===ConfigKeyAndValuesSubsetList[kvs].configurationName)
              console.log('validResult',validResult)
 
             }
             
             if(validResult)
              {

                var ruleList:number[]=ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].ruleIds.sort((a, b) => a - b)
                console.log('ruleList',ruleList)
  
                // console.log('subsetListNfa[kvsNfa].checkKeyAloneNotValues', ConfigKeyAndValuesSubsetList[kvs].CheckKeyAloneNotValues)
                
                for(let rule=0;rule<ruleList?.length;rule++)
                {
                  var expectedValues:any[]=ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].expectedValue
                var ValuesFromDB:any=validResult[ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue]
                console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                  
                  console.log('rule start for-',rule)
                  const inputRule ={
                    specialKey:specialKey,
                    mainvalue:ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue,
                    ValuesFromDB:ValuesFromDB?.toString(),
                    expectedValues:expectedValues,
                    inputUrlConfig:ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].urlConfig,
                    validResult:validResult,
                    datatype:ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].dataType
                  }

                  console.log('inputRule',inputRule)
                  // let outputModel={
                  //   isError:false,
                  //   errorText:''
                  // }
                  
                  var ruleResponse:any=await this.ValidationByRuleList(ruleList[rule],inputRule)
                  console.log('ruleResponse',ruleResponse)
                  if(ruleResponse['isError']==true)
                  {
                   
                    uiModel.reason=ruleResponse['errorText']
                    uiModel.status=ConfigValidationStatus.Fail
                    uiModel.expectedValue=expectedValues
                    console.log('rule side')
                    break;
                  }
                  console.log('rule end for-',rule)
  
                }

                //needed old
                // var expectedValues:any[]=ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].expectedValue
                // var ValuesFromDB:any=validResult[ConfigKeyAndValuesSubsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue]
                // console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                // var errorCount:number=0;
                
                // if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB?.toString()))
                //  {
                //    errorCount=errorCount+1;
                //    //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                //    uiModel.reason='Not an expected value there!'
                //    uiModel.status=ConfigValidationStatus.Fail
                //    console.log('not an expected value there!')
                //  }

                 //neeeded old

              }
              else
              {
                //errorCount=errorCount+1;
                   //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                   uiModel.reason='expected key is not there.!'
                   uiModel.status=ConfigValidationStatus.Fail
                   console.log('expected key is not there')

              }
            

              finalUIResult.push(uiModel)
              uimodelnumber=uimodelnumber+1;

              }
              


            }


          }

            }

            

          }
          
        }
        console.log('end masterResponse',mr,ValidatemasterResponse[mr])
      }
    }



    }
    else
    {

      console.log('nonlooped-apiresult(masterResponse)-NFA',ValidatemasterResponse)
//grouped result (pms integration,offer,multi property)
if(apiDataFromDB['isGroupedResult'])
  {
     console.log('validation start for group result')
     var childHeadGroup=ValidatemasterResponse[0]['head']
     var ValidatechildResopnseGrp:any[]=ValidatemasterResponse[0]['response']

     
     
     if(ValidatechildResopnseGrp?.length<=1)
    {
      if(ValidatechildResopnseGrp[0]['possibleFilterId']==0&&ValidatechildResopnseGrp[0]['possibleMainvaluesId']==0&&ValidatechildResopnseGrp[0]['result'].length<=0 && ValidatechildResopnseGrp[0]['group']!=-1)
      {
        let uiModel: ConfigValidationUIModel={
          id: uimodelnumber,
          config: ValidatemasterResponse[0]['head'],
          configkey: 'NIL',
          reason: 'no result found',
          status: ConfigValidationStatus.Fail,
          uiHeader: inputFeature.uiHeaderId,
          expectedValue: 'Nil'
        }
  
        finalUIResult.push(uiModel)
        uimodelnumber=uimodelnumber+1;
      }
      else{
        if(ValidatechildResopnseGrp?.length==0)
          {
            console.log('No host configured')
    
            let uiModel: ConfigValidationUIModel={
              id: uimodelnumber,
              config: 'PMS INTEGRATION' + '-' + childHeadGroup,
              configkey: 'NIL',
              reason: 'No host configured',
              status: ConfigValidationStatus.Fail,
              uiHeader: inputFeature.uiHeaderId,
              expectedValue: 'Nil'
            }
    
            finalUIResult.push(uiModel)
            uimodelnumber=uimodelnumber+1;
          }
          else
          {

            var uniqueID=ValidatechildResopnseGrp[0]['group']
            if(uniqueID==-1)
            {
              console.log('grp value altered')

              let uiModel: ConfigValidationUIModel={
                id: uimodelnumber,
                config: 'PMS INTEGRATION' + '-' + childHeadGroup,
                configkey: 'NIL',
                reason: 'kinldy check the grouping values',
                status: ConfigValidationStatus.Fail,
                uiHeader: inputFeature.uiHeaderId,
                expectedValue: 'Nil'
              }
        
              finalUIResult.push(uiModel)
              uimodelnumber=uimodelnumber+1;
            }
            else
            {
              var grpName=ValidatechildResopnseGrp[0]['groupName']
            console.log('uniqueID',uniqueID,'grpName',grpName)
            var finalGroupResult:any[]=ValidatechildResopnseGrp[0]['result']
            console.log('finalGroupResult',finalGroupResult)
            var matchedLegitimateGrpConfiguration:LegitiamteConfiguration[]=inputFeature.legitiamteConfiguration.filter(lg=>lg.FilterBaseIds.groupById.toString()===uniqueID.toString())
            console.log('matchedLegitimateGrpConfiguration',matchedLegitimateGrpConfiguration)
            var GroupconfigKeyAndValues:ConfigKeyAndValue[]=matchedLegitimateGrpConfiguration[0]['configKeyAndValues']
            console.log('GroupconfigKeyAndValues',GroupconfigKeyAndValues)
    
            for(let ckvgrp=0;ckvgrp<GroupconfigKeyAndValues.length;ckvgrp++)
            {
              var GroupconfigKeyAndValuesSubSet:ConfigKeyAndValuesSubset[]=GroupconfigKeyAndValues[ckvgrp].configKeyAndValuesSubset
              console.log('GroupconfigKeyAndValuesSubSet',GroupconfigKeyAndValuesSubSet)
              //deptakecheck
              for(let grpsub=0;grpsub<GroupconfigKeyAndValuesSubSet.length;grpsub++)
              {
                var needtoValidateList:any[]=[]
                var grpValidationProceed=true
                if(GroupconfigKeyAndValuesSubSet[grpsub].isbasedonDependencies)
                  {
                        var inputDependencyFilter:DependencyFilter=GroupconfigKeyAndValuesSubSet[grpsub].dependencyFilter;
                        console.log('globaldepinput',globaldepinput,globaldepinput.find(a=>a.id==inputDependencyFilter.id))
                        //globaldepinput.find(a=>a['depResults'])
                        var grpglobaldepinputobject: Globaldependency=globaldepinput.find(a=>a.id==inputDependencyFilter.id)
                        console.log('globaldepinputobject.globaldependenciesResult',grpglobaldepinputobject.globaldependenciesResult)
                        let grpmatcheddep:any=grpglobaldepinputobject.globaldependenciesResult.find(a=>a.configUniqId==inputDependencyFilter.configUniqId && a.configUniqValue===inputDependencyFilter.configuniqValue)
                      console.log('grpmatcheddep',grpmatcheddep)
                      if(grpmatcheddep==undefined)
                      {
                        grpValidationProceed=false
                      }
                  }

                 if(grpValidationProceed)
                {
                  for(let ar=0; ar<finalGroupResult.length;ar++)
                    {
                      var inputobjectkey=GroupconfigKeyAndValues[ckvgrp].comparewithchildren[0].childrenDataFilterBy;
                       if(GroupconfigKeyAndValues[ckvgrp].comparewithchildren[0].isSplitKey)
                       {
                        let splitByInput=GroupconfigKeyAndValues[ckvgrp].comparewithchildren[0].isSplitKey.splitBy;
                        finalGroupResult[ar].groupedResponse.map(x => {
                          const splitParts = x[inputobjectkey]?.split(splitByInput); // Safe access with optional chaining
                          if (splitParts && splitParts.length > GroupconfigKeyAndValues[ckvgrp].comparewithchildren[0].isSplitKey.splitPartIndex) { // Ensure splitParts exists and has enough parts
                              x[inputobjectkey] = splitParts[GroupconfigKeyAndValues[ckvgrp].comparewithchildren[0].isSplitKey.splitPartIndex]; // Assign the third part to x[inputobjectkey]
                          }
                      });
                       }
      
                      //var inputobjectkey=GroupconfigKeyAndValues[ckvgrp].comparewithchildren[0].childrenDataFilterBy;
                      //var configsWithsamekey:any[]= dbresultList[ar].groupedResponse.find(a=>a[inputobjectkey]==legitimateconfigs.configurationName)
                      if(finalGroupResult.length>0)
                      {
                        var configsWithsamekey:any[]= finalGroupResult[ar].groupedResponse.find(a=>a[inputobjectkey]==GroupconfigKeyAndValuesSubSet[grpsub].configurationName)
        
                        console.log('configsWithsamekey',configsWithsamekey)
                        const needtoValidate={
                          groupedCommonname:finalGroupResult[ar].CommonName
                          
                        }
        
                        needtoValidate['groupedConfigs']=configsWithsamekey;
                        needtoValidateList.push(needtoValidate)
                        console.log('needtoValidate',needtoValidate);
                      }
      
                      
                    }
      
                    console.log('needtoValidateList',needtoValidateList)
      
                    if(needtoValidateList.length>0)
                    {
                      for(let nv=0;nv<needtoValidateList.length;nv++)
                      {
                        var finalgroupedCommonName=needtoValidateList[nv]['groupedCommonname']              
                        var finalgroupResponse=needtoValidateList[nv]['groupedConfigs'] 
                                     
                        let uiModel: ConfigValidationUIModel={
                          id: uimodelnumber,
                          //config: groupname + '  |  '+ inputFeature.masterConfigKey+'-'+needtoValidateList[nv].groupedCommonname,
                          config: inputFeature.masterConfigKey + '-' + grpName + '-' + finalgroupedCommonName,
                          configkey: GroupconfigKeyAndValuesSubSet[grpsub].configurationName,
                          reason: 'No Issues Found',
                          status: ConfigValidationStatus.success,
                          uiHeader: inputFeature.uiHeaderId,
                          expectedValue:GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].expectedValue
                        }
      
                                     
                        console.log('finalgroupedCommonName',finalgroupedCommonName,'finalgroupResponse',finalgroupResponse)
                        
                        if(finalgroupResponse)
                          {
                            var ruleList:number[]=GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].ruleIds.sort((a, b) => a - b)
                            console.log('ruleList',ruleList)
              
                            
                            for(let rule=0;rule<ruleList?.length;rule++)
                            {
                              var expectedValues:any[]=GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].expectedValue
                            var ValuesFromDB:any=finalgroupResponse[GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].ValuekeyFrom.Mainvalue]
                            console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                              
                              console.log('rule start for-',rule)
                              const inputRule ={
                                specialKey:specialKey,
                                mainvalue:GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].ValuekeyFrom.Mainvalue,
                                ValuesFromDB:ValuesFromDB,
                                expectedValues:expectedValues,
                                inputUrlConfig:GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].urlConfig,
                                validResult:finalgroupResponse,
                                datatype:GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].dataType
                              }
          
                              console.log('inputRule',inputRule)
                              // let outputModel={
                              //   isError:false,
                              //   errorText:''
                              // }
                              
                              var ruleResponse:any= await this.ValidationByRuleList(ruleList[rule],inputRule)
                              console.log('ruleResponse',ruleResponse)
                              if(ruleResponse['isError']==true)
                              {
                                //errorCount=errorCount+1;
                                //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                                uiModel.reason=ruleResponse['errorText']
                                uiModel.status=ConfigValidationStatus.Fail
                                console.log('rule side')
                                break;
                              }
                              console.log('rule end for-',rule)
              
                            }
      
      
      
                            // var expectedValues:any[]=GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].expectedValue
                            // var ValuesFromDB:any=finalgroupResponse[GroupconfigKeyAndValuesSubSet[grpsub].validationConfig[0].ValuekeyFrom.Mainvalue]
                            // console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                            // var errorCount:number=0;
                            // if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB.toString()))
                            //   {
                            //     errorCount=errorCount+1;
                            //     //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                            //     uiModel.reason='Not an expected value there!'
                            //     uiModel.status=ConfigValidationStatus.Fail
                            //     console.log('not an expected value there!')
                            //   }
            
                          }
                          else
                          {
                            //errorCount=errorCount+1;
                                //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                                uiModel.reason='expected key is not there.!'
                                uiModel.status=ConfigValidationStatus.Fail
                                console.log('expected key is not there')
            
                          }
                        
            
                          finalUIResult.push(uiModel)
                          uimodelnumber=uimodelnumber+1;
      
                      }
      
                      
                    }
                } 
                // let uiModel: ConfigValidationUIModel={
                //   id: uimodelnumber,
                //   config:'PMS INTEGRATION'+'-'+childHeadGroup+'-'+grpName+'-'+uniqueID,
                //   configkey: GroupconfigKeyAndValuesSubSet[grpsub].configurationName,
                //   reason: 'Cool..! Looks Fine',
                //   status: ConfigValidationStatus.success
                // }
    
                
                  
                  
    
    
              }

            }
            
            }
    
          }
      }
      

    }
    else
    {

      console.log('More than one host configured')

      let uiModel: ConfigValidationUIModel={
        id: uimodelnumber,
        config: 'PMS INTEGRATION' + '-' + childHeadGroup,
        configkey: 'NIL',
        reason: 'More than one host configured',
        status: ConfigValidationStatus.Fail,
        uiHeader: inputFeature.uiHeaderId,
        expectedValue: 'Nil'
      }

      finalUIResult.push(uiModel)
      uimodelnumber=uimodelnumber+1;

    }
     console.log('childHeadGroup',childHeadGroup,'ValidatechildResopnseGrp',ValidatechildResopnseGrp)
     console.log('validation end for group result')
     
  } 
  else
  {

    if(!inputFeature.isFilterMandatory)
      {
        console.log('no possible filter area -nfa')
        var childHeadNFA=ValidatemasterResponse[0]['head']
      var ValidatechildResponseNfa:any[]=ValidatemasterResponse[0]['response']
      console.log('childHeadNFA',childHeadNFA,'ValidatechildResponseNfa',ValidatechildResponseNfa)
      if(ValidatechildResponseNfa[0]['possibleFilterId']==0&&ValidatechildResponseNfa[0]['possibleMainvaluesId']==0&&ValidatechildResponseNfa[0]['result'].length<=0)
        {
          let uiModel: ConfigValidationUIModel={
            id: uimodelnumber,
            config: ValidatemasterResponse[0]['head'],
            configkey: 'NIL',
            reason: 'no result found',
            status: ConfigValidationStatus.Fail,
            uiHeader: inputFeature.uiHeaderId,
            expectedValue: 'Nil'
          }

          finalUIResult.push(uiModel)
          uimodelnumber=uimodelnumber+1;
        }
        else{
          for(let vcnfa=0;vcnfa<ValidatechildResponseNfa?.length;vcnfa++)
            {
          var matchedlegitiamteConfigurationdNFA:LegitiamteConfiguration[]= inputFeature.legitiamteConfiguration.filter(l=>l.FilterBaseIds.possibleMainvaluesId==ValidatechildResponseNfa[vcnfa]['possibleMainvaluesId']&& l.FilterBaseIds.possibleFilterId==0) 
            console.log('matchedlegitiamteConfigurationdNFA',matchedlegitiamteConfigurationdNFA)
            console.log('matchedlegitiamteConfiguration[0].configKeyAndValues',matchedlegitiamteConfigurationdNFA[0].configKeyAndValues)
            for(let c=0;c<matchedlegitiamteConfigurationdNFA[0].configKeyAndValues.length;c++)
            {
    
              var specialKey=matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].comparewithchildren[0].childrenDataFilterBy
              
              var mastervalueKeyFromResultNFa:any[]=[]
              if(inputFeature.splitResultForValidation.length>0)
                {
                  var matchedSplitResultValidationNfa:SplitResultForValidation=inputFeature.splitResultForValidation.find(sp=>sp.splitResultForValidationId.toString()==matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].splitResultForValidationId.toString())   
                  if(matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].splitResultForValidationId.toString()=='0')
                  {
                    var tempresponseNfa=ValidatechildResponseNfa[0]['result'][0]
    
                  }
                  else
                  {
                    if(matchedSplitResultValidationNfa.mastervalueKeyFrom!="")
                      {
                    console.log('matchedSplitResultValidationNfa',matchedSplitResultValidationNfa)
                    var tempresponseNfa=ValidatechildResponseNfa[0]['result'][0][matchedSplitResultValidationNfa.mastervalueKeyFrom]
                    //this code test for tenant setup configuration
                    if (Object.keys(tempresponseNfa).length === 0) {
                      tempresponseNfa=null
                      console.log("configValue is an empty object");
                       }

                      }
    
                  }
                    
                    
        
                }
                else
                {
                  // var mastervalueKeyFromResultNFa:any[]=[]
                  var tempresponseNfa=ValidatechildResponseNfa[0]['result']
                }
             
             
    console.log('tempresponseNfa',tempresponseNfa)
              //var tempresponse=ValidatechildResponseNoLp[vcnlp]['result'][matchedSplitResultValidationNlp.mastervalueKeyFrom]
              if(Array.isArray(tempresponseNfa))
              {
                mastervalueKeyFromResultNFa=tempresponseNfa
        
              }
              else{
                console.log('it is not an array');
                mastervalueKeyFromResultNFa.push(tempresponseNfa)
                
              }
              
              console.log('mastervalueKeyFromResultNFa',mastervalueKeyFromResultNFa);
              console.log('specialKey',specialKey)
              var subsetListNfa:ConfigKeyAndValuesSubset[]=matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].configKeyAndValuesSubset
              console.log('subsetListNfa',subsetListNfa)
              //deptakecheck
              for(let kvsNfa=0; kvsNfa<subsetListNfa.length;kvsNfa++)
                {
                  var validationproceed=true
                  
                  if(subsetListNfa[kvsNfa].isbasedonDependencies)
                  {
                        var inputDependencyFilter:DependencyFilter=subsetListNfa[kvsNfa].dependencyFilter;
                        console.log('globaldepinput',globaldepinput,globaldepinput.find(a=>a.id==inputDependencyFilter.id))
                        //globaldepinput.find(a=>a['depResults'])
                        var globaldepinputobject: Globaldependency=globaldepinput.find(a=>a.id==inputDependencyFilter.id)
                        console.log('globaldepinputobject.globaldependenciesResult',globaldepinputobject.globaldependenciesResult)
                        let matcheddep:any=globaldepinputobject.globaldependenciesResult.find(a=>a.configUniqId==inputDependencyFilter.configUniqId && a.configUniqValue===inputDependencyFilter.configuniqValue)
                      console.log('matcheddep',matcheddep)
                      if(matcheddep==undefined)
                      {
                        validationproceed=false
                      }
                  }
                  
                  if(validationproceed)
                  {

                  
                  var expectedValuesfrominput:any[]=subsetListNfa[kvsNfa].validationConfig[0].expectedValue
                  let uiModel: ConfigValidationUIModel={
                    id: uimodelnumber,
                    config: childHeadNFA,
                    configkey: subsetListNfa[kvsNfa].configurationName,
                    reason: 'No Issues Found',
                    status: ConfigValidationStatus.success,
                    uiHeader: inputFeature.uiHeaderId,
                    expectedValue: expectedValuesfrominput
                  }
    
                 
    
                  if(specialKey!="")
                    {
                      console.log('subsetListNfa[kvsNfa].configurationName',subsetListNfa[kvsNfa].configurationName)
                  var validResult=mastervalueKeyFromResultNFa.find(f=>f[specialKey]===subsetListNfa[kvsNfa].configurationName)
                    }
                    if(specialKey=='')
                      {
                        var matchedSplitResultValidationwithoutsplkey:SplitResultForValidation=inputFeature.splitResultForValidation.find(sp=>sp.splitResultForValidationId.toString()==matchedlegitiamteConfigurationdNFA[0].configKeyAndValues[c].splitResultForValidationId.toString())   
                        console.log('matchedSplitResultValidationwithoutsplkey',matchedSplitResultValidationwithoutsplkey)
    
                        var mastervalueKeyFromResultNlp:any[]=[]
              
                        var validResult=mastervalueKeyFromResultNFa[0]
    
    
                      }
                    if(specialKey==subsetListNfa[kvsNfa].configurationName)
                      {
                        var validResult=mastervalueKeyFromResultNFa[0]
                      }
                    // else{
                    //   var validResult=mastervalueKeyFromResultNFa.find(f=>f[]===subsetListNfa[kvsNfa].configurationName)
    
                    // }
                  console.log('validResult',validResult)
                  
    
                  if(validResult)
                    {
    
    
                      var ruleList:number[]=subsetListNfa[kvsNfa].validationConfig[0].ruleIds.sort((a, b) => a - b)
                      console.log('ruleList',ruleList)
        
                      console.log('subsetListNfa[kvsNfa].checkKeyAloneNotValues', subsetListNfa[kvsNfa].CheckKeyAloneNotValues)
                      
                      for(let rule=0;rule<ruleList?.length;rule++)
                      {
                        var expectedValues:any[]=subsetListNfa[kvsNfa].validationConfig[0].expectedValue
                        var ValuesFromDB:any=validResult[subsetListNfa[kvsNfa].validationConfig[0].ValuekeyFrom.Mainvalue]
                        console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                        
                        console.log('rule start for-',rule)
                        const inputRule ={
                          specialKey:specialKey,
                          mainvalue:subsetListNfa[kvsNfa].validationConfig[0].ValuekeyFrom.Mainvalue,
                          ValuesFromDB:ValuesFromDB,
                          expectedValues:expectedValues,
                          inputUrlConfig:subsetListNfa[kvsNfa].validationConfig[0].urlConfig,
                          validResult:validResult,
                          datatype:subsetListNfa[kvsNfa].validationConfig[0].dataType
                        }
    
                        console.log('inputRule',inputRule)
                        // let outputModel={
                        //   isError:false,
                        //   errorText:''
                        // }
                        
                        var ruleResponse:any=await this.ValidationByRuleList(ruleList[rule],inputRule)
                        console.log('ruleResponse',ruleResponse)
                        if(ruleResponse['isError']==true)
                        {
                          //errorCount=errorCount+1;
                          //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                          uiModel.reason=ruleResponse['errorText']
                          uiModel.status=ConfigValidationStatus.Fail
                          console.log('rule side')
                          break;
                        }
                        console.log('rule end for-',rule)
        
                      }
    
                      console.log('validate result readuakkvf')
                      //needed(old)
                      // if(subsetListNfa[kvsNfa].CheckKeyAloneNotValues)
                      // {
                      //   var errorCount:number=0;
                      //   if(specialKey=='')
                      //     {
                      //       if(!validResult.hasOwnProperty(subsetListNfa[kvsNfa].validationConfig[0].ValuekeyFrom.Mainvalue))
                      //         {
                      //           errorCount=errorCount+1;
                      //           //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                      //           uiModel.reason='please add this key'
                      //           uiModel.status=ConfigValidationStatus.Fail
                      //           console.log('not an expected value there!')
                      //         }
                      //     }
                        
                      // }
                      // else
                      // {
                      // var expectedValues:any[]=subsetListNfa[kvsNfa].validationConfig[0].expectedValue
                      // var ValuesFromDB:any=validResult[subsetListNfa[kvsNfa].validationConfig[0].ValuekeyFrom.Mainvalue]
                      // console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                      // var errorCount:number=0;
                      // if(ValuesFromDB==undefined)
                      //   {
                      //     errorCount=errorCount+1;
                      //     //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                      //     uiModel.reason='check both key and values'
                      //     uiModel.status=ConfigValidationStatus.Fail
                      //     console.log('not an expected value there!')
                      //   }
                      //   else
                      //   {
                      //     if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB.toString()))
                      //       {
                      //         errorCount=errorCount+1;
                      //         //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                      //         uiModel.reason='Not an expected value there!'
                      //         uiModel.status=ConfigValidationStatus.Fail
                      //         console.log('not an expected value there!')
                      //       }
    
                      //     if(errorCount==0 && subsetListNfa[kvsNfa].validationConfig[0].urlConfig!=null)
                      //     {
      
                      //       if(!this.urlValidationByHit(subsetListNfa[kvsNfa].validationConfig[0].urlConfig))
                      //       {
                      //         errorCount=errorCount+1;
                      //           uiModel.reason='url hit fails -response not found';
                      //           uiModel.status=ConfigValidationStatus.Fail
                      //           console.log('url check failed.!');
                      //       }
      
                      //     }
    
                      //   }
                      
                      // }
                      //needed(old )
                    }
                    else
                    {
                      //errorCount=errorCount+1;
                          //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                          uiModel.reason='expected key is not there.!'
                          uiModel.status=ConfigValidationStatus.Fail
                          console.log('expected key is not there')
      
                    }
                  
      
                    finalUIResult.push(uiModel)
                    uimodelnumber=uimodelnumber+1;
                  }
                }
    
            }
    
            
    
    
    
          }
    

        }


      
      }
      else{
      



        var childHeadNoLp=ValidatemasterResponse[0]['head']
      var ValidatechildResponseNoLp:any[]=ValidatemasterResponse[0]['response']
      var possibleFilterNlp: PossibleFilter[]=inputFeature.possibleFilters.filter(p=>p.id==0)
      console.log('ValidatechildResponseNoLp',ValidatechildResponseNoLp)
      console.log('possibleFilterNlp',possibleFilterNlp)

      if(possibleFilterNlp.length>0)
      {
        if(ValidatechildResponseNoLp.length<=0)
        {
          let uiModel: ConfigValidationUIModel={
            id: uimodelnumber,
            config: ValidatemasterResponse[0]['head'],
            configkey: 'NIL',
            reason: 'no matched configuration',
            status: ConfigValidationStatus.Fail,
            uiHeader: inputFeature.uiHeaderId,
            expectedValue: 'Nil'
          }
  
          finalUIResult.push(uiModel)
          uimodelnumber=uimodelnumber+1;
        }
        else{

        
        if(ValidatechildResponseNoLp[0]['result']?.length<=0&&ValidatechildResponseNoLp[0]['possibleMainvaluesId']==0&&ValidatechildResponseNoLp[0]['possibleMainvaluesId']===0)
        {
          let uiModel: ConfigValidationUIModel={
            id: uimodelnumber,
            config: ValidatemasterResponse[0]['head'],
            configkey: 'NIL',
            reason: 'no result found',
            status: ConfigValidationStatus.Fail,
            uiHeader: inputFeature.uiHeaderId,
            expectedValue: 'Nil'
          }
  
          finalUIResult.push(uiModel)
          uimodelnumber=uimodelnumber+1;
        }

        for(let vcnlp=0;vcnlp<ValidatechildResponseNoLp?.length;vcnlp++)
          {
            
            var matchedlegitiamteConfiguration:LegitiamteConfiguration[]= inputFeature.legitiamteConfiguration.filter(l=>l.FilterBaseIds.possibleMainvaluesId==ValidatechildResponseNoLp[vcnlp]['possibleMainvaluesId']&& l.FilterBaseIds.possibleFilterId==0) 
            console.log('legitiamteConfiguration',matchedlegitiamteConfiguration)
            if(matchedlegitiamteConfiguration.length>0)
            {
            for(let ckv=0;ckv<matchedlegitiamteConfiguration[0].configKeyAndValues.length;ckv++)
            {
              var specialKey=matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].comparewithchildren[0].childrenDataFilterBy
    
              var matchedSplitResultValidationNlp:SplitResultForValidation=inputFeature.splitResultForValidation.find(sp=>sp.splitResultForValidationId.toString()==matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].splitResultForValidationId.toString())   
              console.log('matchedSplitResultValidationNlp',matchedSplitResultValidationNlp)
              var subhead:any=possibleFilterNlp[0].possibleMainValues.find(a=>a.possibleMainvaluesId==matchedlegitiamteConfiguration[0].FilterBaseIds.possibleMainvaluesId).comparingWithPMValue
              
              var mastervalueKeyFromResultNlp:any[]=[]
              
              var tempresponse=ValidatechildResponseNoLp[vcnlp]['result'][matchedSplitResultValidationNlp.mastervalueKeyFrom]
              if(Array.isArray(tempresponse))
                      {
                        mastervalueKeyFromResultNlp=tempresponse
                
                      }
                      else{
                        console.log('it is not an array');
                        mastervalueKeyFromResultNlp.push(tempresponse)
                        
                      }
              
              console.log('mastervalueKeyFromResultNlp',mastervalueKeyFromResultNlp);
              console.log('specialKey',specialKey)
              var subsetList:ConfigKeyAndValuesSubset[]=matchedlegitiamteConfiguration[0].configKeyAndValues[ckv].configKeyAndValuesSubset
              //deptakecheck
              //var ConfigKeyAndValuesSubsetList:ConfigKeyAndValuesSubset[]=configKeyAndValuesList[ckv].configKeyAndValuesSubset
              
    
              for(let kvs=0; kvs<subsetList.length;kvs++)
                {

                  var filtervalidationproceed=true
                  
                  if(subsetList[kvs].isbasedonDependencies)
                  {
                        var inputDependencyFilter:DependencyFilter=subsetList[kvs].dependencyFilter;
                        console.log('globaldepinput',globaldepinput,globaldepinput.find(a=>a.id==inputDependencyFilter.id))
                        //globaldepinput.find(a=>a['depResults'])
                        var filterglobaldepinputobject: Globaldependency=globaldepinput.find(a=>a.id==inputDependencyFilter.id)
                        console.log('filterglobaldepinputobject.globaldependenciesResult',filterglobaldepinputobject.globaldependenciesResult)
                        let filmatcheddep:any=filterglobaldepinputobject.globaldependenciesResult.find(a=>a.configUniqId==inputDependencyFilter.configUniqId && a.configUniqValue===inputDependencyFilter.configuniqValue)
                      console.log('matcheddep',filmatcheddep)
                      if(filmatcheddep==undefined)
                      {
                        filtervalidationproceed=false
                      }
                  }


                  if(filtervalidationproceed)
                  {
                    let uiModel: ConfigValidationUIModel={
                      id: uimodelnumber,
                      config: childHeadNoLp + '-' + subhead,
                      configkey: subsetList[kvs].configurationName,
                      reason: 'No Issues Found',
                      status: ConfigValidationStatus.success,
                      uiHeader: inputFeature.uiHeaderId,
                      expectedValue: subsetList[kvs].validationConfig[0].expectedValue
                    }
      
                    var validResult=mastervalueKeyFromResultNlp.find(f=>f[specialKey]===subsetList[kvs].configurationName)
                    console.log('validResult',validResult)
      
                    if(validResult)
                      {
  
                        var ruleList:number[]=subsetList[kvs].validationConfig[0].ruleIds.sort((a, b) => a - b)
                        console.log('ruleList',ruleList)
          
                        console.log('subsetList[kvs].checkKeyAloneNotValues', subsetList[kvs].CheckKeyAloneNotValues)
                        
                        for(let rule=0;rule<ruleList?.length;rule++)
                        {
                          var expectedValues:any[]=subsetList[kvs].validationConfig[0].expectedValue
                          var ValuesFromDB:any=validResult[subsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue]
                          console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                          
                          console.log('rule start for-',rule)
                          const inputRule ={
                            specialKey:specialKey,
                            mainvalue:subsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue,
                            ValuesFromDB:ValuesFromDB,
                            expectedValues:expectedValues,
                            inputUrlConfig:subsetList[kvs].validationConfig[0].urlConfig,
                            validResult:validResult,
                            datatype:subsetList[kvs].validationConfig[0].dataType
                          }
      
                          console.log('inputRule',inputRule)
                          // let outputModel={
                          //   isError:false,
                          //   errorText:''
                          // }
                          
                          var ruleResponse:any= await this.ValidationByRuleList(ruleList[rule],inputRule)
                          console.log('ruleResponse',ruleResponse)
                          if(ruleResponse['isError']==true)
                          {
                            //errorCount=errorCount+1;
                            //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                            uiModel.reason=ruleResponse['errorText']
                            uiModel.status=ConfigValidationStatus.Fail
                            console.log('rule side')
                            break;
                          }
                          console.log('rule end for-',rule)
          
                        }
  
  
  
                        //needed old
                        // var expectedValues:any[]=subsetList[kvs].validationConfig[0].expectedValue
                        // var ValuesFromDB:any=validResult[subsetList[kvs].validationConfig[0].ValuekeyFrom.Mainvalue]
                        // console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                        // var errorCount:number=0;
                        // if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB))
                        //   {
                        //     errorCount=errorCount+1;
                        //     //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                        //     uiModel.reason='Not an expected value there!'
                        //     uiModel.status=ConfigValidationStatus.Fail
                        //     console.log('not an expected value there!')
                        //   }
                        //needed old
                      }
                      else
                      {
                        //errorCount=errorCount+1;
                            //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                            uiModel.reason='expected key is not there.!'
                            uiModel.status=ConfigValidationStatus.Fail
                            console.log('expected key is not there')
        
                      }
                    
        
                      finalUIResult.push(uiModel)
                      uimodelnumber=uimodelnumber+1;

                  }
    
                  
    
    
                }
              
    
              
    
            }
          }
    
        }
        }
      }
      else{
        console.log('payment method mattim i think')
        for(let pmod=0;pmod<ValidatechildResponseNoLp?.length;pmod++)
        {

          var pmodpossibleFilterIdFromDb=ValidatechildResponseNoLp[pmod]['possibleFilterId']


          var legitimateConfigurationList:LegitiamteConfiguration[]=inputFeature.legitiamteConfiguration.filter(a=>a.FilterBaseIds.possibleFilterId==pmodpossibleFilterIdFromDb && a.FilterBaseIds.possibleMainvaluesId==0)

          if(legitimateConfigurationList.length>0)
          {
            console.log('legitimateConfigurationList',legitimateConfigurationList[0])
            var pmodsubname:any=inputFeature.possibleFilters.find(a=>a.id==legitimateConfigurationList[0].FilterBaseIds.possibleFilterId).possibleKey

            var pmodconfigKeyAndValueList:ConfigKeyAndValue[]=legitimateConfigurationList[0].configKeyAndValues
            for(let pmodck=0;pmodck< pmodconfigKeyAndValueList.length;pmodck++)
            {
              var specialKey=pmodconfigKeyAndValueList[pmodck].comparewithchildren[0].childrenDataFilterBy

              var pmodconfigKeyAndValuesSubSet:ConfigKeyAndValuesSubset[]=pmodconfigKeyAndValueList[pmodck].configKeyAndValuesSubset
              //depcheck
              var splitfrominput:string=pmodconfigKeyAndValueList[pmodck].splitResultForValidationId
              var pmodMatchedSplitresultValidation=inputFeature.splitResultForValidation.filter(a=>a.splitResultForValidationId.toString()==splitfrominput)
              var pmodmastervalueKeyFromResult:any[]=[]

              if(pmodMatchedSplitresultValidation[0]?.ismastervalueKeyFromJSON)
                {
                  console.log(ValidatechildResponseNoLp[pmod]['result'][pmodMatchedSplitresultValidation[0].mastervalueKeyFrom])
                  if(ValidatechildResponseNoLp[pmod]['result'][pmodMatchedSplitresultValidation[0].mastervalueKeyFrom]!=null&&ValidatechildResponseNoLp[pmod]['result'][pmodMatchedSplitresultValidation[0].mastervalueKeyFrom]!="")
                  {
                    pmodmastervalueKeyFromResult=[...JSON.parse(ValidatechildResponseNoLp[pmod]['result'][pmodMatchedSplitresultValidation[0].mastervalueKeyFrom])]

                  }
                  //pmodmastervalueKeyFromResult=[...JSON.parse(ValidatechildResponseNoLp[pmod]['result'][pmodMatchedSplitresultValidation[0].mastervalueKeyFrom])]

                  //pmodmastervalueKeyFromResult.push(JSON.parse(ValidatechildResponseNoLp[pmod]['result'][pmodMatchedSplitresultValidation[0].mastervalueKeyFrom]));
                  console.log('mastervalueKeyFromResult',pmodmastervalueKeyFromResult);
    
                }
                var pmodfinalDBResult:any[]=pmodmastervalueKeyFromResult;
                console.log('pmodfinalDBResult',pmodfinalDBResult)
              for(let pmodsub=0;pmodsub<pmodconfigKeyAndValuesSubSet.length;pmodsub++)
              {
                console.log('specialKey',specialKey,pmodconfigKeyAndValuesSubSet[pmodsub].configurationName)
                let uiModel: ConfigValidationUIModel={
                  id: uimodelnumber,
                  config: childHeadNoLp + '-' + pmodsubname,
                  configkey: pmodconfigKeyAndValuesSubSet[pmodsub].configurationName,
                  reason: 'No Issues Found',
                  status: ConfigValidationStatus.success,
                  uiHeader: inputFeature.uiHeaderId,
                  expectedValue: pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].expectedValue
                }

                if(specialKey=='')
                  {
                   var validResult=finalDBResult[0];
                   console.log('validResult-splkey no',validResult)
                  }
                  if(specialKey!='')
                  {
                   var pmodvalidResult=pmodfinalDBResult.find(f=>f[specialKey]===pmodconfigKeyAndValuesSubSet[pmodsub].configurationName)
                   console.log('pmodvalidResult',pmodvalidResult)
      
                  }
                  
                  
                  if(pmodvalidResult)
                    {

                      var ruleList:number[]=pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].ruleIds.sort((a, b) => a - b)
                      console.log('ruleList',ruleList)
        
                      console.log('subsetListNfa[kvsNfa].checkKeyAloneNotValues', pmodconfigKeyAndValuesSubSet[pmodsub].CheckKeyAloneNotValues)
                      
                      for(let rule=0;rule<ruleList?.length;rule++)
                      {
                        var expectedValues:any[]=pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].expectedValue
                      var ValuesFromDB:any=pmodvalidResult[pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].ValuekeyFrom.Mainvalue]
                      console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                        
                        console.log('rule start for-',rule)
                        const inputRule ={
                          specialKey:specialKey,
                          mainvalue:pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].ValuekeyFrom.Mainvalue,
                          ValuesFromDB:ValuesFromDB,
                          expectedValues:expectedValues,
                          inputUrlConfig:pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].urlConfig,
                          validResult:pmodvalidResult,
                          datatype:pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].dataType
                        }
    
                        console.log('inputRule',inputRule)
                        // let outputModel={
                        //   isError:false,
                        //   errorText:''
                        // }
                        
                        var ruleResponse:any=await this.ValidationByRuleList(ruleList[rule],inputRule)
                        console.log('ruleResponse',ruleResponse)
                        if(ruleResponse['isError']==true)
                        {
                          //errorCount=errorCount+1;
                          //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                          uiModel.reason=ruleResponse['errorText']
                          uiModel.status=ConfigValidationStatus.Fail
                          uiModel.expectedValue=expectedValues
                          console.log('rule side')
                          break;
                        }
                        console.log('rule end for-',rule)
        
                      }


                      //needed old 
                      // var expectedValues:any[]=pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].expectedValue
                      // var ValuesFromDB:any=pmodvalidResult[pmodconfigKeyAndValuesSubSet[pmodsub].validationConfig[0].ValuekeyFrom.Mainvalue]
                      // console.log('expectedValues',expectedValues,'ValuesFromDB',ValuesFromDB)
                      // var errorCount:number=0;
                      
                      // if(!this.compareBothInputAndOutput(expectedValues,ValuesFromDB?.toString()))
                      //  {
                      //    errorCount=errorCount+1;
                      //    //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                      //    uiModel.reason='Not an expected value there!'
                      //    uiModel.status=ConfigValidationStatus.Fail
                      //    console.log('not an expected value there!')
                      //  }
                       //needed old
      
                    }
                    else
                    {
                      //errorCount=errorCount+1;
                         //uiModel.reason=legitimateconfigs.isNotnecessaryKeyValues? 'Not Mandatory-Not an expected value there!':'Not an expected value there!'
                         uiModel.reason='expected key is not there.!'
                         uiModel.status=ConfigValidationStatus.Fail
                         console.log('expected key is not there')
      
                    }
                  
      
                    finalUIResult.push(uiModel)
                    uimodelnumber=uimodelnumber+1;
              }


            }
          
          }

        }

        console.log('&&&&*&$^$&%*^*^*%$$^$&***$')

      }

      

      }

  }

    }
    //uncomment
    
  }
  
  console.log('THE eND &77777&&&&& validateBothInputJsonAndApiResponsePMS')


  console.log('validateBothInputJsonAndApiResponsePMS-finalUIResult',finalUIResult)
  return finalUIResult;
}

atleastAnyValues(valuefromDB:any):boolean
{
  var isAnyValuesPresent:boolean=false;
  console.log('atleast any value parameter',valuefromDB)
  if(valuefromDB!='')
  {
    isAnyValuesPresent=true;
  }

  return isAnyValuesPresent;
}

compareBothInputAndOutput(expectedValues:any[],valuefromDB :any)
{
  var isMatched=false;
  if(expectedValues.find(a=>a.toLowerCase()===valuefromDB?.toLowerCase()))
    {
      isMatched=true;
    }
    return isMatched;
}

urlHelper(expectedValues:any[],valuefromDB :any):boolean{

  for(let exurl=0; exurl<expectedValues?.length;exurl++)
  {
    if(expectedValues[exurl]==valuefromDB)
    {
      return true;
    }
  }
}


   async urlValidationByHit(config:UrlConfig,expectedValues:any[],valuefromDB :any)
{
 
    console.log('url hit check start')
    //form the url
    var endpointsplit:any[]=config.endpoint.split('/');
    console.log('endpointsplit',endpointsplit)
    // const urlHitPaylod={
    // url: config.endpoint,
    // method: config.method}
    var isurlSuccess=false;
    let inputurl:string='';
    let getvaluefromexpvalue:boolean=this.urlHelper(expectedValues,valuefromDB)
    if(endpointsplit?.length>=0)
    {
      for(let es=0;es<endpointsplit?.length;es++)
      {
        let placeholderInput:PlaceHolder[]=config.placeHolder.filter(a=>a.placeHolderId==es);
        if(placeholderInput[0].placeholdervalue.valueFromexpectedValue)
        {
          if(getvaluefromexpvalue)
          {
            //url.concat(valuefromDB?.toString())
            inputurl +=valuefromDB?.toString()
          }

        }
        else{
          if(inputurl!='')
          {
            //url.concat('/')
            inputurl +='/'
          }
          inputurl +=placeholderInput[0].placeholdervalue?.value?.toString()
          //inputurl.concat(placeholderInput[0].placeholdervalue?.value?.toString())
        }
        
      }
    }
    // url: "https://authorize-to-v11.hospitalityrevolution.com/WebCommunicationService/V1/heartbeat",
    const urlHitPaylod={
      url: inputurl,
      method: config.method}
    var urlhitResponse=await this.configValidationService.UrlUpCheck(urlHitPaylod);
    console.log('urlhitResponse',urlhitResponse)
    if(urlhitResponse.result)
    {
      isurlSuccess=true;
        
    }
    console.log('isurlSuccess',isurlSuccess)
    return isurlSuccess;
  

}

CheckKeyAloneNotValues(specialKey:string,validResultObject:any,properyName:any):boolean
{
  var isKeyPresent:boolean=false
  console.log('inpur param for CheckKeyAloneNotValues')
  console.log('specialKey',specialKey,'validResultObject',validResultObject,'properyName',properyName)

  if(specialKey=='')
    {
      if(validResultObject.hasOwnProperty(properyName))
        {
          isKeyPresent=true;
          // errorCount=errorCount+1;
          // uiModel.reason='please add this key'
          // uiModel.status=ConfigValidationStatus.Fail
          // console.log('not an expected value there!')
        }
    }
    else
    {
      if(validResultObject.hasOwnProperty(specialKey))
        {
          isKeyPresent=true;
        }

    }
  
   
  return isKeyPresent;
}

CaseSensitiveCheck(input:any[],dbresult:any):boolean
{
  // var a:string='apple';
  // var b:string='ApplE';
  // if(a==b)
  // { console.log('nooooooooooo')}
  var iscorrectcase=false;
  if(input.find(a=>a==dbresult))
  {
    iscorrectcase=true;
  }

  return iscorrectcase;
}

whiteSpacehelper(expectedValues:any[],valuefromDB :any):any{
  var valuesmatched=false
  console.log('trimmed one',valuefromDB?.trim().toLowerCase())
  for(let exv=0; exv<expectedValues?.length;exv++)
  {
  const normalizeString = (str: string): string => str.replace(/\s+/g, ' ').trim();

  const normalizedStr1 = normalizeString(expectedValues[exv]);
  const normalizedStr2 = valuefromDB?.replace(/\s+/g, '');

  if(normalizedStr1.toLowerCase() === normalizedStr2?.toLowerCase())
  {
       
       return exv;
    }
  }
  return -1;
  
}

checkWhitespace(expectedValues:any[],valuefromDB :any,returnedvalue:any):boolean
{
  var isThereWhitespace:boolean=false;
  
    var valuwithspace:string=valuefromDB.replace(/\s+/g, '');
    
    if(valuefromDB?.length!=valuwithspace?.toString().trim().length)
    {
      isThereWhitespace=true;
    }
  
  
return isThereWhitespace;
}

isValidJSON(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;  // Valid JSON
  } catch (e) {
    return false; // Invalid JSON
  }
}

DatatypeCheck(valuefromDB :any,inputDataType:any):boolean
{
  var isDataTypematched:boolean=false
  if(inputDataType.toLowerCase()=='json')
  {
    isDataTypematched=this.isValidJSON(valuefromDB)
    return isDataTypematched
  }
  else
  {
    var dbDatatype=typeof(valuefromDB);
    console.log('input validation config-dataType',inputDataType)
    console.log('dbDatatype',dbDatatype);
    //datatype validation
    if(inputDataType===dbDatatype)
    {
      isDataTypematched=true;
    }
    return isDataTypematched
  }
}

DecryptCheck(expectedValues:any[],valuefromDB :any):boolean
{
  let uTempData : string = 'UUVkNUpFUmxYaVJBUjNra1JHVmVKQT09IFJVRXdPVEExUlVJM05VSTNSRGxDT1E9PSBmQT09IE1UVkJOalF5T1RORU5qTTRNVGxDT1E9PSBKRjVsUkNSNVIwQWtYbVZFSkhsSFFBPT0=';
    let primarykey = atob(atob(uTempData)
    .split(' ')[3]);
    let secondarykey = atob(atob(uTempData)
    .split(' ')[1]);
    var isDecryptmatched:boolean=false
    if(valuefromDB!=undefined || valuefromDB!=null)
    {
      let decryptedResult=this.utilities.DecryptString(valuefromDB,primarykey,secondarykey)
    
      console.log('decryptedResult',decryptedResult)
      if(decryptedResult!="")
      {
        if(expectedValues.find(a=>a.toLowerCase()===decryptedResult?.toLowerCase()))
        {
          isDecryptmatched=true
        }
      }
    }
        return isDecryptmatched
}

base64Decryptcheck(expectedValues:any[],valuefromDB :any)
{
  var isbase64Decryptmatched:boolean=false 
  if(valuefromDB!=undefined || valuefromDB!=null)
    {
      let decodedresult=atob(valuefromDB)
    
      console.log('base64Decryptcheck',decodedresult)
      if(decodedresult!="")
      {
        if(expectedValues.find(a=>a.toLowerCase()===decodedresult?.toLowerCase()))
        {
          isbase64Decryptmatched=true
        }
      }
    }
    return isbase64Decryptmatched
}






//*************************************end payment manager,retailconfiguration,credit card and gift card***********************************************8 */
//-------------------------------------------------------------------------------------------------------------------------------------------




splitmasterkey(input:string):any
{
   var beforeSplit:string=input;
   console.log('beforeSplit',beforeSplit)

   var allstringlist : string[]=[]
   allstringlist=input.split('-')
   console.log('allstringlist',allstringlist)
   var afterSplit:string=allstringlist[1];
   console.log('afterSplit',afterSplit)
   return afterSplit;
}

 async ValidationByRuleList(ruleId:number,validationmodel:any):Promise<any>{

  let outputModel={
    isError:false,
    errorText:''
  }

  var isError=false;

  switch (ruleId) {
    case 1:
        var CheckKeyAloneNotValues:boolean=this.CheckKeyAloneNotValues(validationmodel['specialKey'],validationmodel['validResult'],validationmodel['mainvalue'])
        if(!CheckKeyAloneNotValues)
        {
          outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.CheckKeyAloneNotValues
        }
        console.log("checkkeyonly",CheckKeyAloneNotValues);
        
        return outputModel;
    case 2:
      var compareBothInputAndOutput:boolean= this.compareBothInputAndOutput(validationmodel['expectedValues'],validationmodel['ValuesFromDB']?.toString())
      if(!compareBothInputAndOutput)
        {
          outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.MandatoryKeyValue
        }
      console.log("mandatory key and values",compareBothInputAndOutput);
      return outputModel;
    case 3:
      var atleastAnyValues:boolean=this.atleastAnyValues(validationmodel['ValuesFromDB'])
      if(!atleastAnyValues)
        {
          outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.AleastAnyValues
        }
        console.log("atleastAnyValues",atleastAnyValues);
        return outputModel;
    case 4:
      var CaseSensitiveCheck:boolean=this.CaseSensitiveCheck(validationmodel['expectedValues'],validationmodel['ValuesFromDB'])
      if(!CaseSensitiveCheck)
        {
          outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.caseSensitive
        }
      console.log("casesensitive",CaseSensitiveCheck);
      return outputModel;
    case 5:
      var returnedvalue=this.whiteSpacehelper(validationmodel['expectedValues'],validationmodel['ValuesFromDB']);
      if(returnedvalue>=0)
      {
        var checkWhitespace:boolean=this.checkWhitespace(validationmodel['expectedValues'],validationmodel['ValuesFromDB'],returnedvalue)
        if(checkWhitespace)
          {
            outputModel.isError=true;
            outputModel.errorText=ValidationErrorStatus.checkWhiteSpace
          }
        console.log("check whitespace",checkWhitespace);
      }
      else{
        outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.MandatoryKeyValue
      }
      
      return outputModel;
    case 6:
      var DatatypeCheck:boolean=this.DatatypeCheck(validationmodel['ValuesFromDB'] ,validationmodel['datatype'])
      if(!DatatypeCheck)
        {
          outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.dataType
        }
      console.log("DatatypeCheck",DatatypeCheck);
      return outputModel;
      case 7:
        if(validationmodel['inputUrlConfig']?.endpoint==null && validationmodel['inputUrlConfig']?.method==null)
        {
          outputModel.isError=true;
          outputModel.errorText=ValidationErrorStatus.urlnoConfigure
        }
        else{
          let urlparam:UrlConfig={
            host: '',
            endpoint: validationmodel['inputUrlConfig'].endpoint,
            parameter: undefined,
            method: validationmodel['inputUrlConfig'].method,
            comparewithmaster: undefined,
            placeHolder: validationmodel['inputUrlConfig'].placeHolder
          }

          var urlvalidation:boolean=  await this.urlValidationByHit(urlparam,validationmodel['expectedValues'],validationmodel['ValuesFromDB'])
          if(!urlvalidation)
            {
              outputModel.isError=true;
              outputModel.errorText=ValidationErrorStatus.urlValidation
            }
          console.log("urlValidationByHit",urlvalidation);
        }
     
      return outputModel;
      case 8:
      var encryptionCheck:boolean=this.DecryptCheck(validationmodel['expectedValues'],validationmodel['ValuesFromDB'])
      console.log("DatatypeCheck",encryptionCheck);
      if(!encryptionCheck)
      {
        outputModel.isError=true;
        outputModel.errorText=ValidationErrorStatus.encrypt
      }
      return outputModel;
      case 9:
      var base64Decrypt:boolean=this.base64Decryptcheck(validationmodel['expectedValues'],validationmodel['ValuesFromDB'])
      console.log("base64Decryptcheck",base64Decrypt);
      if(!base64Decrypt)
      {
        outputModel.isError=true;
        outputModel.errorText=ValidationErrorStatus.encrypt
      }
      return outputModel;
    default:
        console.log("no rules meet");
        return outputModel;

        
}

}




//-------------------------------------------------------------------------------------------------------------------------------------------
//*************************************retailconfiguration,credit card and gift card END***********************************************8 */



}
