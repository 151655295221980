import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailService } from '../../retail.service';
import { PayAgentService } from '../service/payagent.service';
import { HandleResponse, PaymentMethods, HandleRequest, PaymentErrorCodes } from '../business/shared.modals';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { GiftCardType, ButtonType, RetailBreakPoint } from '../globalsContant';
import { GiftCardBusiness, SearchScreen } from '../../sytem-config/gift-cards/gift-cards.business';
import { GiftCardPMRequest, GiftCardTypeNames, GiftcardDetails, GiftcardSearchFilters, HttpResponseStatus, ThirdPartyGiftCard } from '../service/payment/payment-model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CommonVariablesService } from '../service/common-variables.service';
import { AlertType } from '../shared.modal';

import { UserMachineConfigurationService } from '../../common/services/user-machine-configuration.service';
import { UserBreakPoint, UserSessionConfiguration } from '../../retail.modals';
import { RetailUtilities } from '../utilities/retail-utilities';
import { GiftCardPaymentRecord } from '../service/payment/payment-business.model';
import { RetailFeatureFlagInformationService } from '../service/retail.feature.flag.information.service';
import { MatDialog } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../../shop/shop-dialog-popup/shop-dialog-popup.component';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { NonPMAgentRequestHandler } from '../../payment/NonPMAgentRequestHandler';
import { RetailAdvancedSearchComponent } from '../../retail-advanced-search/retail-advanced-search.component';
import { BreakPointAccess } from '../service/breakpoint.service';

@Component({
  selector: 'app-gift-cards-search',
  templateUrl: './gift-cards-search.component.html',
  styleUrls: ['./gift-cards-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GiftCardsSearchComponent implements OnInit {
  
  @Input() screenType: number;  
  @Input() isThirdPartyGiftcard: boolean = false;
  @Input() isTopUp: boolean = false;
  @Input() inputCardNumber: string = '';
  @Input() outletId: number = 0;
  @Input() isRefund: boolean = false;
  allowSearchBPValue: boolean = false;
  @Output() giftCardSearchResult: EventEmitter<HandleResponse> = new EventEmitter();

  @ViewChild('giftcardSearch') giftcardSearch: ElementRef;

  captions: any;
  selectedDevice: string;
  fieldPlaceholder: string;
  GiftCardNumber: string;
  giftCardsFormGroup: UntypedFormGroup;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  userSessionConfiguration: UserSessionConfiguration;
  userSessionConfigLoaded: boolean = false;
  PrevSearchSeqChar: string = "";  
  isV1GiftcardEnabled: boolean = false;
  isExternalGiftcardEnabled: boolean = false;
  floatLabel: string;
  floatLabelNever: string;

  get currentOutletId() {
    return this.outletId > 0 ? this.outletId : this.defaultOutletId;
  }

  get issueScreen(): boolean { return (this.screenType == SearchScreen.IssuePopup) }

  get issuanceFlow(): boolean { return (this.screenType == SearchScreen.IssuePopup || this.isRefund) }

  get isOrderSummary(): boolean { return this.screenType == SearchScreen.OrderSummary }

  get defaultOutletId() {
    return (this.userSessionConfiguration && this.userSessionConfiguration.defaultOutletId > 0 ? this.userSessionConfiguration.defaultOutletId : 0);
  }

  get paymentMethod() {
    return (this.isThirdPartyGiftcard || this.giftCardsFormGroup.controls['thirdparty'].value ?
      this.giftcardbusiness.ThirdPartyTenderByVendor : PaymentMethods.V1GiftCard);
  }

  get CurrentThirdPartyValue() {
    return (!this.issuanceFlow && !this.isOrderSummary) ? this.giftCardsFormGroup.controls['thirdparty'].value : this.isThirdPartyGiftcard;
  }  

  get AllowNameSearch() { return this.giftcardbusiness._giftCardConfig ? this.giftcardbusiness._giftCardConfig?.allowSearchByGuestName : false }

  constructor(public localization: RetailLocalization,
    public _ams: RetailService,
    private _payAgentService: PayAgentService,
    private propertyInformation: RetailPropertyInformation,
    public giftcardbusiness: GiftCardBusiness,
    private fb: UntypedFormBuilder,
    private _shopService: CommonVariablesService,
    private userMachineConfigurationService: UserMachineConfigurationService,
    private utils: RetailUtilities,
    public dialog: MatDialog,
    private _featureInfo: RetailFeatureFlagInformationService
    , private _nonPMAgentHandler: NonPMAgentRequestHandler
    , public BPoint: BreakPointAccess
  ) {
    this.captions = this.localization.captions.shop.GiftCard;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.StartCustomLoaderSubscription();
    this.fieldPlaceholder = this.issuanceFlow ? this.captions.GiftCardNumber : this.captions.SearchBygiftcardnumber;
    this.InitFormGrp();
    if (this.isTopUp) {
      this.GiftCardNumber = this.inputCardNumber
    }
    this._shopService.UpdateGiftTabStatus.subscribe(x => this.InitFormGrp());
    this.GetUserDefaultDeviceConfiguration();
    this.GetIssueGiftCardBreakPoints();
    this.validateUserAccess();
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  validateUserAccess() {
    const userBreakPoints : UserBreakPoint[] =  this.BPoint.GetBreakPoint([RetailBreakPoint.ALLOW_GIFTCARD_SEARCH_BY_GUESTNAME]).result;
    this.allowSearchBPValue = userBreakPoints.find(x => x.breakPointNumber == RetailBreakPoint.ALLOW_GIFTCARD_SEARCH_BY_GUESTNAME)?.allow ?? false;
  }

  ngOnChanges() {
    //Added to Reset the search if user switch btw Giftcard methods in ordersummary
    if (this.inputCardNumber == "reset") { this.GiftCardNumber = ""; }
  }

  InitFormGrp() {
    this.giftCardsFormGroup = this.fb.group({
      thirdparty: [{ value: (this.giftcardbusiness.GiftcardType === GiftCardType.ExternalGiftCard), disabled: (this.giftcardbusiness.GiftcardType == GiftCardType.ExternalGiftCard || this.giftcardbusiness.GiftcardType == GiftCardType.V1GiftCard) }]
    });
  }

  GetIssueGiftCardBreakPoints()
  {
    this._shopService.checkForAccess();
    this.isExternalGiftcardEnabled = this._shopService.hasAccessIssueExternalGiftCard;
    this.isV1GiftcardEnabled = this._shopService.hasAccessIssueV1GiftCard;
  }

  StartCustomLoaderSubscription() {
    this._ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe((loader) => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        } else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
  }

  async GetUserDefaultDeviceConfiguration() {
    this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing);
    this.userSessionConfiguration = await this.userMachineConfigurationService.getUserSessionConfiguration(Number(this.localization.GetPropertyInfo('UserId')));
    this.userSessionConfigLoaded = true;
    this.utils.ToggleLoaderWithMessage(false);
  }

  async HandleGiftCardData(event: HandleResponse) {
    if (event.status.toLowerCase() == HttpResponseStatus.Success) {
      this.GiftCardNumber = event.paymentHandle[0] && event.paymentHandle[0].inquiryInfo && event.paymentHandle[0].inquiryInfo.id;
    } else if (event.cardNumber) {
      this.GiftCardNumber = event.cardNumber;
    }
    //Preventive check to avoid Credit cards being swiped in Giftcard search
    if (this.GiftCardNumber && this._featureInfo.ValidateGiftCardNumber && !this.utils.ValidateNumber(this.GiftCardNumber)) {
      this.giftcardSearch.nativeElement.value = "";
      this.GiftCardNumber = "";
      return this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(GlobalConst.UIErrorMsgCodes.InvalidCardNumber));
    }
    event.isThirdParty = this.CurrentThirdPartyValue;
    //Override the card status for eGiftify or Other gift card if Issueance is not supported by Vendor
    event.isCardActive = this.CheckIfLoadNeedsToBeInvokedForInactiveCard(event.errorMessage) ? true : event.isCardActive;
    if (this.issuanceFlow && this.CheckCartHasSameItem()) { return; }
    let checkAccess = this._shopService.CheckIssuanceAccess(event.isThirdParty, event.isCardActive);
    if(checkAccess)
    this.giftCardSearchResult.emit(event);
  }

  CheckIfLoadNeedsToBeInvokedForInactiveCard(errorMessage: string) {
    return (this.issuanceFlow && this.CurrentThirdPartyValue && !this._featureInfo.GiftcardIssuanceSupportedByVendor
      && this._featureInfo.InitiateLoadForCardActivation && (errorMessage?.trim()?.toLowerCase() == this._featureInfo.ErrorMessageForInactiveGiftCards?.trim()?.toLowerCase()));
  }  

  giftcardValChange(event) {
    let cardNumber = this.GiftCardNumber.trim();
    const BlockFurthertxtseqIndicator = "^";
    const notNumberRegex = /[^0-9]/;
    const validNumber = this.utils.ValidateNumber(cardNumber);
    const blockFurtherInput = (this.PrevSearchSeqChar != "" && this.PrevSearchSeqChar == BlockFurthertxtseqIndicator);
    this.PrevSearchSeqChar = !blockFurtherInput ? event.data : this.PrevSearchSeqChar;
    if (cardNumber && validNumber && !blockFurtherInput) {
      this.GiftCardNumber = cardNumber;
    } else {
      if (blockFurtherInput) {
        cardNumber = cardNumber.substring(0, cardNumber.length - 1);
      }
      this.PrevSearchSeqChar = !cardNumber ? "" : this.PrevSearchSeqChar;
      this.GiftCardNumber = cardNumber.replace(notNumberRegex, '');
      this.giftcardSearch.nativeElement.value = cardNumber.replace(notNumberRegex, '');
    }
    this.giftCardSearchResult.emit(null);
  }

  onPaste(event: ClipboardEvent) {
    let pasteContent = event.clipboardData.getData('text');
    if (!this.utils.ValidateNumber(pasteContent)) {
      this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.common.CopyPasteNotAllowed);
      event.preventDefault();
    }
  }

  CheckCartHasSameItem() {
    if (this.GiftCardNumber && this._shopService.selectedProducts.length > 0 &&
      (this._shopService.selectedProducts.some(x => x.isGiftCardItem && x.GiftCardTransactionItem && x.GiftCardTransactionItem.cardNumber == this.GiftCardNumber))) {
      this.utils.showAlert(this.captions.ItemAlreadyAddedToCart, AlertType.Warning, ButtonType.Ok);
      return true;
    } else {
      return false;
    }
  }

  async CheckIfCardIsAlreadySold(): Promise<boolean> {
    let cardAlreadySold = false;
    if (this.issuanceFlow && this.GiftCardNumber && this.paymentMethod) {
      let result: GiftCardPaymentRecord[] = await this.giftcardbusiness.getGiftCardPaymentRecords(this.GiftCardNumber);
      if (this.CurrentThirdPartyValue) {
        result = result.filter(x => ThirdPartyGiftCard.includes(x.type));
      } else {
        result = result.filter(x => x.type == GiftCardTypeNames.V1GiftCard);
      }
      if (result && result.length > 0 && ((this.CurrentThirdPartyValue && result.some(x => x.isIssued && !x.isVoided))
        || (!this.CurrentThirdPartyValue && !this.v1GC_ValidateIssuance(result)))) {
        cardAlreadySold = true;
        this.utils.showAlert(this.captions.CardAlreadySold, AlertType.Warning, ButtonType.Ok);
      }
    }
    return cardAlreadySold;
  }

  v1GC_ValidateIssuance(giftcardpaymentrecord: GiftCardPaymentRecord[]) {
    let allowIssue = false;
    if (giftcardpaymentrecord && giftcardpaymentrecord.every(x => !x.isIssued)) {
      allowIssue = true;
    } else {
      const issuedRecordList = giftcardpaymentrecord.filter(g => g.isIssued);
      const reversedIssueTrans = [];
      issuedRecordList.forEach(issuedRecord => {
        if (issuedRecord && !issuedRecord.isVoided && giftcardpaymentrecord.length > 1) {
          const reversalRecord = giftcardpaymentrecord.find(x => x.id > issuedRecord.id
            && Math.abs(issuedRecord.amount) == Math.abs(x.amount)
            && x.isVoided && !x.isIssued && !x.isLoaded && !x.isRedeemed);
          reversedIssueTrans.push(reversalRecord ? true : false);
        }
      });
      allowIssue = reversedIssueTrans.every(r => r);
    }
    return allowIssue;
  }

  async PerformSearch() {
    if (this.isTopUp || !this.GiftCardNumber) {
      return;
    }
    if (this.GiftCardNumber && this.issuanceFlow && this.CheckCartHasSameItem()) { return; }

    if (this._featureInfo.AllowPinValidation && this.CurrentThirdPartyValue && this.giftcardbusiness.IsAgilysysGiftcard) {
      this.ShowGiftCardPINPopup();
    } else {
      this.SearchWithGiftCardNumber();
    }
  }

  async PerformAdvancedSearch() {
    //Validate User access
    if (!this.allowSearchBPValue) {
      this.BPoint.showBreakPointPopup(this.localization.captions.breakpoint[RetailBreakPoint.ALLOW_GIFTCARD_SEARCH_BY_GUESTNAME]);
      return;
    }
    const dialogRef = this.dialog.open(RetailAdvancedSearchComponent, {
      width: '1250px',
      height: '650px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        isEdit: false,
        headername: this.localization.captions.shop.GiftCard.GiftcardAdvancedSearchPopup.lbl_advanceSearch,
        closebool: true,
        isExternal: this.CurrentThirdPartyValue
      },
      panelClass: 'shop-payment'
    });

    dialogRef.afterClosed().subscribe((selectedGiftcard) => {
      if (selectedGiftcard) {
        this.GiftCardNumber = selectedGiftcard.giftCardNumber;
        this.SearchWithGiftCardNumber('',selectedGiftcard);
      }
    });
  }

  ShowGiftCardPINPopup() {
    const dialogRef = this.dialog.open(ShopDialogPopUp, {
      width: '400px',
      height: '210px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        isEdit: false,
        headername: this.localization.captions.shop.GiftCard.PinValidation,
        closebool: true,
        templatename: 'GCPin'
      },
      panelClass: 'shop-payment'
    });

    dialogRef.afterClosed().subscribe(pinNumber => {
      if (pinNumber) {
        this.SearchWithGiftCardNumber(pinNumber);
      }
    });
  }  

  async SearchWithGiftCardNumber(pinNumber?: string, giftcardDetails = null) {
    if (this.GiftCardNumber) {
      this._ams.loaderEnable.next(this.captions.SearchLoaderMsg);
      let RequestBody: HandleRequest = {
        tenderId: String(this.paymentMethod),
        inquiryInfo: {
          id: this.GiftCardNumber,
          cardData: null
        }
      };

      let Handles: any;
      if (this._featureInfo.SkipPMAgent) {
        let request: GiftCardPMRequest = {
          handle: JSON.stringify({
            id: this.GiftCardNumber,
            inquiryData: {
              id: this.GiftCardNumber,
              cardData: null
            }
          }),
          Amount: {
            RequestAmount: "0.00"
          },
          InquirerInfo: {
            TenderId: String(this.paymentMethod),
            TerminalId: "0",
            OrderNumber: String(this.utils.GetRandomNumber(7)),
            Profitcenter: "0",
            ZoneOffset: this._featureInfo.ZoneOffset
          }
        }
        Handles = this._nonPMAgentHandler.GetBalance(request, this.currentOutletId);
      } else {
        Handles = this._payAgentService.GetHandles(RequestBody, this.currentOutletId);
      }
      
      Handles.then(response => {
        console.log({ response });
        const GiftcardNotInSystemStatus = "invalidcardnumber";        
        
        if (response.status.toLowerCase() == HttpResponseStatus.Success) {          
          response.isThirdParty = this.CurrentThirdPartyValue;
          response.tenderId = this.paymentMethod;
          response.isCardActive = true; //Considering card as Active only if the status is "SUCCESS"
          response.cardNumber = this.GiftCardNumber;
          response.email = giftcardDetails?.emailId;
          response.phoneNumber = giftcardDetails?.phoneNumber;
          this.giftCardSearchResult.emit(response);
        }
        else if (response.status.toLowerCase() == HttpResponseStatus.Failure
          && response.errorMessage.toLowerCase() == this.giftcardbusiness.pmAgentCardNotFoundMsg.toLowerCase()) {
          this._payAgentService.PaymentErrorPrompt(PaymentErrorCodes.CardNumberNotValid);
        }
        else if ((this.issuanceFlow && this.CurrentThirdPartyValue && !this.giftcardbusiness.IsAgilysysGiftcard && (response.errorMessage.toLowerCase() == this.giftcardbusiness.cardStatusInactiveMsg.toLowerCase()))
          || (this.CurrentThirdPartyValue && this.giftcardbusiness.IsAgilysysGiftcard && this.issuanceFlow && this.giftcardbusiness.AgysGiftcard_CardNotRegistered.toLowerCase() == response.errorMessage.toLowerCase())
        ) {//For Givex Giftcard and For Agilysys Engage Giftcard
          response.isThirdParty = this.CurrentThirdPartyValue;
          response.tenderId = this.paymentMethod;
          response.isCardActive = false;
          let checkAccess = this._shopService.CheckIssuanceAccess(response.isThirdParty, response.isCardActive);
          if(checkAccess)
          this.giftCardSearchResult.emit(response);
        }
        else if (this.CheckIfLoadNeedsToBeInvokedForInactiveCard(response.errorMessage)) {
          response.isThirdParty = this.CurrentThirdPartyValue;
          response.tenderId = this.paymentMethod;
          response.isCardActive = true; //Considering card as Active, Since eGiftify or Other GC System will return failure for fresh cards and we need to load the card
          response.cardNumber = this.GiftCardNumber;
          response.email = giftcardDetails?.emailId;
          response.phoneNumber = giftcardDetails?.phoneNumber;
          this.giftCardSearchResult.emit(response);
        }
        else if (!this.CurrentThirdPartyValue && this.issuanceFlow) { // For V1 Giftcard
          let checkAccess = this._shopService.CheckIssuanceAccess(false, false);
          if(checkAccess)
          this.giftCardSearchResult.emit({
            paymentHandle: null,
            isThirdParty: false,
            status: GiftcardNotInSystemStatus,
            errorMessage: "",
            cardNumber: this.GiftCardNumber,
            isCardActive: false
          } as HandleResponse);
        }
        else {
          const errorMsg = !this.CurrentThirdPartyValue ? this.giftcardbusiness.GetV1GCErrorMsg(response.errorMessage) : response.errorMessage;
          this.utils.ShowError(this.localization.captions.common.Error, errorMsg);
        }
        setTimeout(() => {
          this._ams.loaderEnable.next('');
        }, 1000);
      }).catch((err) => {
        //this._payAgentService.PaymentErrorPrompt(err);
        setTimeout(() => {
          this._ams.loaderEnable.next('');
        }, 1000);
      });
    }
  }

}
