import { takeUntil } from 'rxjs/operators';
import { EventEmitter, Injectable, OnDestroy, Output, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpServiceCall, HttpMethod } from './http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { SpaUtilities } from '../utilities/spa-utilities';
import { RecentAppointment, clientRecentAppsResponse, clientInfoDisplay, Address, ClientLabel, ServicePriceType, ServicePriceTypeWithCode, ClientDetail } from '../business/new-booking.model';
import { Host, ButtonType, DefaultGUID, SPAScheduleBreakPoint, SPAManagementBreakPoint, ImgRefType, GenderPreference, ErrorConstants, SERVICECHARGE, GRATUITY, PatronInfoSearchResultType, ClientType, Gender } from '../globalsContant';
import { BaseResponse, SystemConfig, ClientSOAPNote, ClientCreditCardInfo, BaseResp, LineItem, CheckData, TransactionAdapter, PriceModel, ServiceDetail, GenderOverrideMessage, ClientScreenProperties, multiClientInfo, TempHoldApp, ClientInfo, UserBreakPoint, ContactLog, GuestContact, RetailItems, AppointmentBillingDetail} from '../business/shared.modals';
import { SPAConfig } from '../../core/config/SPA-config';
import { UntypedFormGroup, UntypedFormBuilder, NgForm } from '@angular/forms';
import { SpaAppointmentComponent } from '../../appointment/spa-appointment/spa-appointment.component';
import { SpaLocalization } from '../../core/localization/spa-localization';
import * as _ from 'lodash'
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { BreakPointAccess } from './breakpoint.service';
import { FormatText } from '../pipes/formatText-pipe.pipe';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { CardDetail, GuaranteeMethod, GuaranteeMethodDetail, StoreTerminal } from 'src/app/retail/shared/business/shared.modals';
import { ImageProcessorService } from './image-processor-service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { SystemConfiguration } from '../business/view-settings.modals';
import { AppModuleService } from 'src/app/app.service';
import { PlayerInformationService } from 'src/app/common/shared/shared/service/player.information.service';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { EventNotification, EventNotificationGroup } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { PMSAction } from 'src/app/common/external-request/pms-request.model';
import { PMSSessionData } from 'src/app/core/core.model';
import { GuaranteeMethodAPIModel, GuaranteeMethodBusiness, GuaranteeMethodConfig, GuaranteeMethodJSONModel } from '../appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { cloneDeep } from 'lodash';
import { ClientInfo as newClientInfo } from '../business/new-booking.model';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { GuestSearch } from '../appointment-popup/create-appointment/client-information/GuestSearch-model';
import { RetailItem } from 'src/app/retail/retail.modals';

@Directive()
@Injectable({ providedIn: 'root' })
export class AppointmentpopupService implements OnDestroy {

    @Output() change = new EventEmitter();
    activeSelectedClient: number;
    currentClient: any;

    clientWidowActionType: string;
    clientEditData: ClientInfo;
    clientNameInfo:any = {};
    localisation: any = {};
    popupTitle: any;
    isClientBlocked: boolean;
    isLocationBlock: boolean = false;
    clientBlockInfos: any = [];
    overrideClientBlock: boolean = false;
    selectedClientType: number;
    showNodata: boolean = false;
    showLoading: boolean = false;
    discountType: string = '';
    isCheckedOutAppointment:boolean =false;

    totalData: any;
    isOverbook: any;
    appointmentTabName: any;
    clientTabName: any;
    personalTabName: any;
    additionalInformationTabName: any;
    medicalTabName: any;
    historyTabName: any;
    multipleRecords: boolean = false;
    recordsArray: any = [];
    otherdetails = { linkcode: 0, vip: false, intakeForm: false, NoMove: false, gender: 0,  customField1: 0, customField2: 0, customField3: 0, customField4: '', customField5: '', comments: '', policy: '', checkout: '', noShowComments: '', valueChange: false, client_scheduling: false};
    clinetInfo: any = { clientcomments: '', selectedtherapistId: 1 };
    labelRecords: ClientLabel[] = [];
    REQUIREGENEDERPREFRENCE: boolean = false;
    requireGenderPreference$ = new BehaviorSubject<boolean>(false);
    cancelBool: boolean;
    depositArray: any = [];
    packageDeepdata = [];
    moreviewTag: boolean = false;
    clientsearchArray: any = [];
    therapistArray: any = [];
    selectedtherapistId: any;
    appoinmentdetailArray: any = [];
    appoinmentDetailFormArray: UntypedFormGroup;
    multiClientInfo: multiClientInfo[] = [];
    selectedServicePriceTypes: ServicePriceType[] = [];
    clientInfoPriceType: ServicePriceTypeWithCode[] = [];
    clientId: any; //Check ClientId
    therapistidArray: any = [];   //Get Therapist Array Id
    selectedIndex: any;
    ClientSelectedTab: number;
    selectedClientIndex: any;
    therapistNotSelected: boolean = false;
    clientScreenProperties: ClientScreenProperties;
    attachClient: boolean;
    therapistAssociationError: boolean;
    isTherapistView: boolean;
    checkVar: string;
    isEditAppointment: boolean = false;
    isSpaWizard: boolean = false;
    isWizardGroupContactView = false;
    isRadioDisable = false;
    clientImageUrl: any;
    ImgTempHolder: any;
    VIP: any;
    saveText: any;
    GenderPreferences: any;
    EnforceGender: any;
    NoPreference: any;
    ServiceComments: any;
    CheckOutComments: any;
    noShowComments: any;
    checkwhetherfromClient: boolean = false;//this value to used to set the value of the save and update button under variuos scenarios
    public isfromAppoinment: boolean = false;
    private _clientImageObj = new BehaviorSubject<number>(0);
    clientImageObj: any = this._clientImageObj.asObservable();
    imageArray: any = [];
    allPriceTypes: any = [];
    allGuestTypes: any = [];
    allDiscountMapping: any = [];
    clientInfopriceTypeModel: number;
    apptguestTypeId: number;
    selectedClientPriceValue: number;
    aptPriceTypeGlobalService: any;
    isAptPriceReadOnly: boolean = false;
    isMultiPackAppointment: boolean = false;
    recreateTempAppointments = false;
    moveToSearchScreen = true;
    isLinkedAppointments = false;
    isLinkedAppointmentsLoaded = false;
    linkedAppointments: any = [];
    linkedAppointmentUpdated: any = [];
    linkedAppointmentId: any = null;
    isGuaranteeMethodRequired = false;
    guaranteeMethodConfig: GuaranteeMethodConfig = null;
    isGuaranteeMethodValid = false;
    isGuaranteeMethodDirty = false;
    isLinked
    guaranteeMethodData: GuaranteeMethodJSONModel;

    private _clientInfoObj1 = new BehaviorSubject<any>(0);
    clientInfoObj1: any = this._clientInfoObj1.asObservable();
    clientMultipacks: any;
    isCancel: boolean; //Fix For No changes in client-info
    isTestbool: boolean = false;
    serviceChanged: boolean = false;
    fromClientModule: boolean = false;
    addFromClientModule: boolean = false;
    private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
    clientAddedFromClientInformation: boolean = false;
    clientComments: string;
    clientTherapist: any;
    commentPlaceHolder: string;
    serviceCharge: number;
    gratuity: number;
    PMSClientItineraryData: BehaviorSubject<any> = new BehaviorSubject(null);
    //Added to load data
    orderSummaryPatronId: any;
    depositRecordsAvailable = new EventEmitter();
    clientBlockOverrideAccess: boolean = false;
    hotelReservationType: number = 1;
    isLoadedfromPMS: boolean = false;
    currentDate: Date;
    relatedClient: any = [];
    pmsActivityId: string;
    externalPMSConfNo: string;
    memberImageUrl: string;

    //Added for Appointment addon
    addOnsResult: any = [];
    makeAddOnsCall: boolean = false;
    addOns: any = [];
    addOnsSaveBtnEnable: boolean = false;
    hasAccessForAddOns: boolean = true;
    multiClientTherapistChange: boolean = false;
    defaulthotelresvvalue: number = 1;
    isInternalPMSIntegrated: boolean;
    isExternalIntergrated: boolean = false;
    isExternalClient: boolean = false;
    isEditClient: boolean = false;
    isExternalClientLinked: boolean = false;
    clientVipType: string = '';
    clientVipTypeId:number;
    clientTypeId:number;
    allVipTypes: any;
    dayPassClientIds:any;

    clientInfoChange(info: any) {
        this._clientInfoObj1.next(info);
    }




    medicalhistoryArray: any = [];

    activateClientSelected: any;

    addDepositarray: any = [{ "clientId": "", "linkClientId": [] }];
    activeBorderId: number = 0;
    priceTypes: any = [];
    appComponent: SpaAppointmentComponent;
    preferencesTabName: string;
    soapNotesTabName: string;
    clientPreferences: any = {};
    clientSoapNotes: ClientSOAPNote[] = [];
    clientCreditCardInfo: ClientCreditCardInfo[] = [];
    clientPreferedGender: any;
    clientPreferedService: any[] = [];
    clientPreferedTherapist: any[] = [];
    itineraryTabName: any;
    captions: any = this.localization.captions.bookAppointment;
    allCaptions: any = this.localization.captions;
    serviceArray: any;
    intialClinetArray: any = [];
    intialClinetArrayTracker: any = [];
    allServices: any = [];
    allTherapists: any = [];

    recordobjId: any = [];
    depositStartDate: any;
    depositEndDate: any;
    linkId: any;
    objidArray: any;
    clientIds: any;
    showProcess: boolean = false;
    isFromAppointmentSearch: boolean = false;
    clientHasPic: any;
    isClientImgRemoved: boolean;
    disablePreference: boolean = false;
    disableClientBlock: boolean = false;

    addwaitlist: boolean = false;
    IsAppointmentDisabled: boolean;
    //Express package stepper
    isClientCompleted: boolean = false;
    isPackageCompleted: boolean = false;
    isGuaranteeCompleted: boolean = false;
    isExpressServiceCompleted: boolean = false;
    isStandardServiceCompleted: boolean = false;
    isOtherInfoComplted: boolean = false;

    CheckForUpdate: boolean = false;
    appointmentBooking: boolean = false;
    editselectedClient: boolean = false;
    copySelectedClient: boolean = false;
    currentUserSessionId: number = 0;
    CreditBook = 'Credit Book';
    waitListFromDate: any;
    waitListToDate: any;
    guestId: any;
    clientTypes: any;
    hotelReservationTypes: any;
    isExternalSearch = false;
    private _convertToEdit = new BehaviorSubject<number>(0);
    convertToEdit: any = this._convertToEdit.asObservable();

    notificationConfiguration: SystemConfiguration[] = [];
    ExtendedSearchConfiguration: any = [];

    public _appointmentConfigurations$: ReplaySubject<any> = new ReplaySubject();

    isFromWaitlist: boolean = false;
    isFromLostDeniedBusiness: boolean = false;
    clientContactLogs: ContactLog[] = [];
    guestContact: GuestContact[] = [];
    isContactRelationUpdateRequired: boolean = false;
    dayPackageConfig: any=[];
    dayPackageUtilizedConfig : any=[];


    constructor(public http: HttpClient, private httpService: HttpServiceCall, private spaConfig: SPAConfig, private utils: SpaUtilities,
        public localization: SpaLocalization, public fb: UntypedFormBuilder, private breakPoint: BreakPointAccess, private formatPhn: FormatText, private httpserviceCall: HttpServiceCall,
        private PropertyInfo: SpaPropertyInformation, private imgService: ImageProcessorService, private _playerService: PlayerInformationService,
        private _retailFeature: RetailFeatureFlagInformationService, private _ams: AppModuleService, private notificationconfigService: NotificationConfigurationService, private retailPropConfig: RetailPropertyInformation) {
        this.popupTitle = this.captions.NewAppointment;
        this.appointmentTabName = this.captions.AppointmentDetails;
        this.clientTabName = this.captions.ClientInformation;
        this.personalTabName = this.captions.PersonalInformation;
        this.additionalInformationTabName = this.captions.AdditionalInformation;
        this.medicalTabName = this.captions.MedicalHistory;
        this.editClientFromPackage = false;
        this.clientScreenProperties = {
            helperText: '',
            minGuest: 0,
            maxGuest: 1 //default allowable clients can be search : 5

        }
        this.GetAllServices();
        this.GetAllTherapists();
        this.historyTabName = this.captions.History;
        this.preferencesTabName = this.captions.Preferences;
        this.soapNotesTabName = this.captions.SoapNotes;
        this.itineraryTabName = this.captions.Itinerary;

        if (sessionStorage.getItem('propertyInfo')) {
            this.hasAccessForClientPreference = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.EditClientPreferences], false);
            this.hasAccessForMedicalHistory = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.AccessClientMedicalHistory], false);
            this.hasAccessForSoapNotes = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.AddSOAPNotes], false);
            this.hasAccessForEditSoapNotes = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.EditSOAPNotes], false);
            const clientBlockInfoBreakPoint = this.clientWidowActionType == 'EDIT' ? SPAManagementBreakPoint.EditClientBlockInfo : SPAManagementBreakPoint.AddClientBlockInfo;
            this.hasAccessForClientBlockInfo = this.breakPoint.CheckForAccess([clientBlockInfoBreakPoint], false);
        }
        this.clientTypes = [{ id: 0, name: this.localization.captions.existingClients, checked: true }, { id: 3, name: this.localization.captions.hotelReservation, checked: false }, { id: 2, name: this.localization.captions.members, checked: false }];
        this.currentUserSessionId = this.utils.GetCurrentUserSessionId();
        this._ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe((loader) => {
            const loadingContainer = document.getElementById('custom-cover-spin');
            const loadingContainerMessage = document.getElementById('custom-cover-message');
            if (loadingContainer && loadingContainerMessage) {
                if (loader) {
                    loadingContainer.style.display = 'block';
                    loadingContainerMessage.innerText = loader;
                } else {
                    loadingContainer.style.display = 'none';
                    loadingContainerMessage.innerText = '';
                }
            }
        });
        this.clientBlockOverrideAccess = this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.overrideClientBlock], false);
    }

    ngOnDestroy(): void {
        this.$destroyed.next(true);
        this.$destroyed.complete();
        this.isAptPriceReadOnly = false;
        this.commentPlaceHolder = '';
        this.clientAddedFromClientInformation = false;
        this.isMultiPackAppointment = false;
        this.isFromWaitlist = false;
        this.isFromLostDeniedBusiness = false;
        this.clientScreenProperties.minGuest = 0;
        this.clientScreenProperties.maxGuest = 1;
        this.clientScreenProperties.selectedStaff = null;
        this.clientScreenProperties.helperText = '';
    }

    public async GetAllServices() {
        this.allServices = await this.InvokeServiceCallAsync('GetAllSpaService', GlobalConst.Host.spaManagement, HttpMethod.Get);
    }

    public async GetAllTherapists() {
        this.allTherapists = await this.InvokeServiceCallAsync('GetAllTherapist', GlobalConst.Host.spaManagement, HttpMethod.Get);
    }


    therapistBreak = { therapistId: 0, therapistName: '', startDate: '', startTime: '', breakDuration: 0, breakType: '', comments: '', paidOptions: '' };
    editClientFromPackage: boolean = false;
    add_client: boolean = false;
    exsisting_client: boolean = false;
    getServiceDetails: string = "assets/service_details.json";
    getPersonalDetails: string = "assets/personal_details.json";
    value_holder: any = [];
    personal_details_holder: any = [];
    resultNewAppointment: any = {};
    existingAppointmentsRef: any;
    resultExistingAppointment: any;
    resultExistingAppActivity: any;
    resultPkgEntitlementData: any;
    stayGuestData: any;
    associatedStayActivity: any;
    resultExistingClients: any;
    resultNewClient: any = {};
    showDetails: boolean;
    phoneLineLength: number = 5;
    addressLineLength: number = 3;
    SelectedClientId: any;
    RecentAppsUIArr: RecentAppointment[];
    RecentAppsResponse: clientRecentAppsResponse[]
    //Client Screens FormGroup - Begins
    personalDetailsFormGroup: UntypedFormGroup;
    additionalDetailsFormGroup: UntypedFormGroup;
    otherDetailsFormGroup: UntypedFormGroup;
    guaranteeFormGroup: UntypedFormGroup;
    medicalHistoryFormGrp: UntypedFormGroup;
    soapNotesFormGrp: UntypedFormGroup;
    contactLogsFormGrp: UntypedFormGroup;
    guestContactFormGrp: UntypedFormGroup;
    //Client Screens FormGroup - End
    clientInformationFormInfo: multiClientInfo[];
    personalDetailAddress: Address;
    personalIsValid: boolean;
    additionalIsValid: boolean;
    IsEmailAvailable: boolean;
    IsPhoneAvailable: boolean;
    IsCopyFromClient: any = [];
    IsRequiredOnAppointment: any = [];
    preferenceFormGroup: UntypedFormGroup;
    clientBlockIformationFormGroup: UntypedFormGroup;
    IsClientBlockInfoDirty: boolean = false;
    clientInformationForm: NgForm;
    IsAddClientFromSearch: boolean = false;
    IsAddClientFromSPA: boolean = false;
    appoinementConversion: boolean = false;
    hasAccessForMedicalHistory: boolean = true;
    hasAccessForSoapNotes: boolean = true;
    hasAccessForEditSoapNotes: boolean = true;
    hasAccessForClientPreference: boolean;
    hasAccessForClientBlockInfo: boolean;
    ClientBlockInformation: any;

    appointmentBreakPoints: any;
    TempHoldArr: TempHoldApp[] = [];
    //default temp hold values
    tempHoldValues: TempHoldApp = {
        service: 0,
        location: 0,
        startTime: '',
        endTime: '',
        serviceGroupId: 0,
        comments: "",
        status: "TEMP",
        breakDownTime: 0,
        duration: 0,
        setUpTime: 0,
        price: 0,
        doNotMove: false,
        requestStaff: false,
        isBookingLocked: false,
        isVip: false,
        genderPreference: 0,
        checkOutComments: "",
        noShowComments: "",
        noShow: false,
        packageId: 0,
        clientId: 0,
        therapist: [],
        appointmentId: 0,
        cancelId: "",
        appointmentDate: '',
        TempHoldId: '',
        TempHoldLinkId: 0,
        originalClientId: 0,
        multiGroupId: 0,
        ServiceCharge: 0,
        Gratuity: 0,
        Tax: 0,
        TotalAmount: 0,
        guestTypeId: 0,
        discountType: ""
    };
    clientGuestMap: any = [];
    ClientActivities: any = [];
    isPreviousDayAppt: boolean;
    clientScreenBreakPoints: any = [];
    isClientViewOnly: boolean;
    isPreferenceViewOnly: boolean;
    isClientBlockViewOnly: boolean;
    isSoapNotesViewOnly: boolean;
    IsOrderSummary: boolean = false;
    clientImageChange(img: any) {
        this._clientImageObj.next(img)
    }
    convertToEditClient(obj: any) {
        this._convertToEdit.next(obj);
    }

    sendData() {

        this.clientInfoChange(this.totalData);
    }
    getServiceDetailsJson() {
        return this.http.get(this.getServiceDetails).pipe(takeUntil(this.$destroyed)).subscribe(data => this.value_holder = data);
    }
    getPersonalDetailsJson() {
        return this.http.get(this.getPersonalDetails).pipe(takeUntil(this.$destroyed)).subscribe(data => this.personal_details_holder = data);
    }

    public async getMemberInfo(cardNo: string, scheduleDateTime: string, corpId: number): Promise<any> {
        let response = await this.httpService.CallApiAsync<any>({
            host: Host.retailPOS,
            callDesc: "GetMemberInfoByCardNo",
            uriParams: { cardNo: cardNo, scheduleDateTime: scheduleDateTime, corpId: corpId},
            method: HttpMethod.Get,
            showError: false
        });

        if (response.result != null) {
            return response.result;
        }
        return null;
    }

    public async getDiscountTypePrice(discountTypeMappings: any): Promise<any> {

        let response = await this.httpService.CallApiAsync<any>({
            host: Host.retailManagement,
            callDesc: "GetDiscountTypePrice",
            method: HttpMethod.Put,
            body: discountTypeMappings
        }); 

        if (response.result != null) {
            return response.result;
        }
        return null;
    }


    async CreateClientForGuest(clientObj: ClientInfo, memberInfo: any = null): Promise<ClientInfo> {
        let clientDetails: any;

        if (clientObj.clientDetail.id == 0 && this.selectedClientType != 2) {
            clientDetails = await this.httpserviceCall.CallApiAsync({
                host: Host.spaManagement,
                callDesc: "createClientByGuestId",
                method: HttpMethod.Put,
                body: clientObj,
                uriParams: { guid: clientObj.clientDetail.guestId },
            });
        } else {
            let clientData = await this.MapMemberToClientInfo(clientObj, memberInfo);
            clientDetails = await this.httpserviceCall.CallApiAsync({
                host: Host.spaManagement,
                callDesc: "createNewClient",
                method: HttpMethod.Post,
                body: clientData,
                uriParams: {}

            });
        }
        if (memberInfo) {
            clientDetails.result.clientDetail.membershipExpiryDate = memberInfo.membershipExpiryDate;
            clientDetails.result.clientDetail.membershipRenewalDate = memberInfo.membershipRenewalDate;
            clientDetails.result.clientDetail.membershipStatus = memberInfo.membershipStatus;
            let creditBookBalance = memberInfo.balanceList.find(t => t.BucketName.toLowerCase() == this.CreditBook.toLowerCase());
            clientDetails.result.clientDetail.creditBookBalance = creditBookBalance != null ? creditBookBalance.AccrualCash : 0;
        }
        return <ClientInfo>clientDetails.result;
    }

    async MapMemberToClientInfo(clientobj, memberInfo) {
        if (clientobj) {
            let clientinfo: ClientDetail = {
                id: 0,
                GuestId: '00000000-0000-0000-0000-000000000000',
                Title: '',
                FirstName: this.utils.capitalizeFirstLetter(clientobj.clientDetail.firstName),
                LastName: this.utils.capitalizeFirstLetter(clientobj.clientDetail.lastName),
                Pronounce: this.utils.capitalizeFirstLetter(clientobj.clientDetail.pronounced),
                Gender: this.GetGender(memberInfo.gender),
                DateOfBirth: '',
                Comments: '',
                BypassClientScheduling: false,
                CustomField1: 0,
                CustomField2: 0,
                CustomField3: 0,
                CustomField4: '',
                CustomField5: '',
                GenderPreference: '',
                LastChangeId: '00000000-0000-0000-0000-000000000000',
                InterfaceGuestId: '',
                PriceTypeId: 0,
                loyaltyDetail: [],
                tempId: null,
                clientType: GlobalConst.ClientType.Member,
                clientLinkId: clientobj.clientDetail.clientLinkId,
                arAccountNumber: memberInfo != null ? memberInfo.arAccountNumber : null,
                isContactRelationUpdateRequired: this.isContactRelationUpdateRequired,
                discountType: memberInfo != null ? memberInfo.discountType : null,
                corpId : clientobj.clientDetail.corpId,
                vip : '',
                taxNumber:'',
                guestTypeId: 0
            };

            let clientInfoObj: newClientInfo = {
                id: clientinfo.id,
                clientDetail: clientinfo,
                emails: clientobj.emails,
                addresses: clientobj.addresses,
                phoneNumbers: clientobj.phoneNumbers,
                clientMedicalCondition: [],// Need to do
                clientPreferences: [],
                clientSOAPNotes: [],
                clientCreditCardInfo: [],
                clientBlock: null,
                clientContactLogs: [],
                guestContact: [],
            }

            return clientInfoObj;
        }
    }

    GetGender(gender)
    {
        switch(gender)
        {
            case 1:
             return Gender.Male;
             case 2:
             return Gender.Female;
             default:
              return "";
        }
    }


    validateMultiClient(): boolean {
        for (let multiClientItem of this.multiClientInfo) {
            const element = multiClientItem;
            if (element.TherapistId == 0) {
                return false;
            }
        }
        return true;
    }
    ValidateAllTherapistsAssociated(): boolean {
        if (this.clientScreenProperties.selectedStaff && this.clientScreenProperties.selectedStaff.length > 0) {
            for (let clientScreen of this.clientScreenProperties.selectedStaff) {
                if (this.multiClientInfo.filter(o => o.TherapistId == clientScreen.id).length == 0)
                    return false;
            }
        }
        return true;
    }

    async getDefaultHotelReservationTypes(pmsSystemValue : string) {
        let clientSearchFilter = this.fetchGuestSearchJson();
        let clientSearchDetail = clientSearchFilter?.find(p => p.integratedPms == pmsSystemValue);
        if (this.isExternalIntergrated && pmsSystemValue && clientSearchDetail) {
            this.hotelReservationTypes = this.MapSearchData(clientSearchDetail, ClientType.HotelReservation);
        }
        else if (this.defaulthotelresvvalue && this.isInternalPMSIntegrated) {
            this.hotelReservationTypes = [
                { id: 1, name: this.localization.captions.confirmationNumber, checked: false },
                { id: 2, name: this.localization.captions.roomNumber, checked: false },
                { id: 0, name: this.localization.captions.guestName, checked: false },
                { id: 4, name: this.localization.captions.contactDetail, checked: false }
            ];
        }
        else{
            this.hotelReservationTypes = [
                { id: 1, name: this.localization.captions.confirmationNumber, checked: false },
                { id: 2, name: this.localization.captions.roomNumber, checked: false },
                { id: 0, name: this.localization.captions.guestName, checked: false },
                { id: 3, name: this.localization.captions.linkCode, checked: false },
                { id: 4, name: this.localization.captions.contactDetail, checked: false }
            ];
            }
            let defaultValueIndex = this.hotelReservationTypes?.findIndex(t=>t.id == this.defaulthotelresvvalue);
            defaultValueIndex = defaultValueIndex == -1 ? 0 : defaultValueIndex;
            this.hotelReservationTypes[defaultValueIndex].checked = true;
            this.hotelReservationType = this.hotelReservationTypes[defaultValueIndex].id;
    }


    async GetDefaultClientCategory(): Promise<any> {
        const result: BaseResponse<any> = await this.httpService.CallApiAsync({
            host: Host.spaManagement,
            callDesc: "GetSettingsByModule",
            method: HttpMethod.Put,
            body: { Switch: ['DEFAULT_CLIENT_CATEGORY', 'DEFAULT_HOTEL_RESERVATION', 'DEFAULT_EXISTING_CLIENT_CATEGORY'] },
            uriParams: { module: 'Appointment' }
        });
        return result;
    }

    getRecentAppointments(clientId: number) {
        this.httpService.CallApiWithCallback<clientRecentAppsResponse[]>({
            host: Host.schedule,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "getClientRecentAppointmentInfo",
            uriParams: { clientID: clientId },
            method: HttpMethod.Get,
            showError: false,
            extraParams: ['dataBelongTo']
        });
    }

    getClientInformation(clientId: number, isClientAdd?: boolean) {
        this.httpService.CallApiWithCallback<clientRecentAppsResponse[]>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "getClientInfo",
            uriParams: { id: clientId },
            method: HttpMethod.Get,
            showError: false,
            extraParams: ['dataBelongTo', isClientAdd]
        });
    }

    getClientBlockInformation(clientId: number) {
        this.httpService.CallApiWithCallback<clientRecentAppsResponse[]>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetClientBlockById",
            uriParams: { id: clientId },
            method: HttpMethod.Get,
            showError: false,
            extraParams: null
        });
    }
    async getClientBlockInformationSync(clientId: number) {
        let response = await this.httpService.CallApiAsync<any>({
            host: Host.spaManagement,
            callDesc: "GetClientBlockById",
            uriParams: { id: clientId },
            method: HttpMethod.Get,
            showError: false
        });
        return response ? response.result : null;
    }

    GetAllPriceTypesforService(serviceId: number) {
        if (serviceId > 0) {
            this.httpService.CallApiWithCallback<any>({
                host: Host.spaManagement,
                success: this.successCallback.bind(this),
                error: this.utils.errorCallback.bind(this),
                callDesc: "GetServicePriceTypes",
                uriParams: { serviceid: serviceId },
                method: HttpMethod.Get,
                showError: true,
                extraParams: [serviceId]
            });
        }
    }

    async getAllGuestTypes(includeInactive) {

        let response = await this.httpService.CallApiAsync<any>({
            host: Host.spaManagement,
            callDesc:"GetAllGuestType",
            uriParams: { "isIncludeInactive": includeInactive },
            method: HttpMethod.Get,
            showError: false
        });
        return response ? response.result : null;
    }

    activeClientBorder(i, clinetObj) {
        this.activeBorderId = i
        this.clientId = clinetObj.id;
    }

    ClientAddedEvent(clientId) {

        if (clientId && clientId > 0) {
            this.clientId = clientId;
            if (!this.IsAddClientFromSearch) {
                this.multipleRecords = this.multiClientInfo.length > 1;
                this.attachClient = false;
            }

            this.getClientInformation(clientId, true);
            this.getClientBlockInformation(clientId);
            this.isClientCompleted = true;
        }
    }


    async loadLinkedAppointments() {
        let linkedAppointments = []
        if (this.linkedAppointmentId && this.linkedAppointmentId != "") {
            const response = await this.httpService.CallApiAsync<any>({
                host: Host.schedule,
                callDesc: "LinkedAppointments",
                uriParams: {
                    isLinked: true,
                },
                method: HttpMethod.Post,
                showError: true,
                body: {
                    linkedAppointmentsID: this.linkedAppointmentId ? this.linkedAppointmentId : ""
                }
            });
            linkedAppointments = response.result ? <any>response.result : [];
        }
        return linkedAppointments;
    }

    resetEditedClientValues(clientId: number) {
        if (this.recordsArray && this.recordsArray.length == 1) {

            this.recordsArray = [];
            this.labelRecords = [];
        }
    }

    GetAllDepositAppointments(id: any, clientIds: any, fromDate: any, toDate: any, linkId: any, selectedOutletId: number): any {
        this.httpService.CallApiWithCallback<number>({
            host: Host.schedule,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetAllDepositAppointments",
            method: HttpMethod.Get,
            uriParams: { id: id, clientIds: clientIds, fromDate: this.utils.formatDate(fromDate), toDate: this.utils.formatDate(toDate), linkId: linkId, outletId: selectedOutletId},
            showError: true,
            extraParams: []
        });
    }

    GetMultiPacksForClients(clientIds: any) {
        this.httpService.CallApiWithCallback<any>({
            host: Host.retailPOS,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetMultiPacks",
            body: clientIds,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }


    GetClientIdsForMultiAppointment(appointmentId) {
        this.httpService.CallApiWithCallback<any>({
            host: Host.schedule,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetMultiAppointment",
            uriParams: {
                id: appointmentId
            },
            method: HttpMethod.Get,
            showError: true,
            extraParams: [appointmentId]
        });
    }

    setGenderPreference() {
        this.otherdetails.gender = GenderPreference.none;

        if (this.appoinmentdetailArray && this.appoinmentdetailArray.controls && this.appoinmentdetailArray.controls['StaffDropDownFormArr'] && this.appoinmentdetailArray.controls['StaffDropDownFormArr'].value && this.appoinmentdetailArray.controls['StaffDropDownFormArr'].value.length > 0) {
            let selectedTherapist = this.therapistArray.find((x) => { return x.id == this.appoinmentdetailArray.controls['StaffDropDownFormArr'].value[0].therapist })
            if (this.recordsArray[0] && selectedTherapist) {
                if (selectedTherapist.gender == this.recordsArray[0].clientDetail.genderPreference) {
                    this.otherdetails.gender = GenderPreference.enforceGender;
                } else if (this.recordsArray[0].clientDetail.genderPreference == 'Male') {
                    this.otherdetails.gender = GenderPreference.preferMale;
                } else if (this.recordsArray[0].clientDetail.genderPreference == 'Female') {
                    this.otherdetails.gender = GenderPreference.preferFemale;
                } else if (this.recordsArray[0].clientDetail.genderPreference == 'NoPreference') {
                    this.otherdetails.gender = GenderPreference.noPreference;
                } else if (this.recordsArray[0].clientDetail.genderPreference == 'Other') {
                    this.otherdetails.gender = GenderPreference.other;
                }
                else {
                    this.otherdetails.gender = GenderPreference.none;
                }
                let genderPref = '';
                if (selectedTherapist.gender == 'Male') {
                    genderPref = 'Female';
                }
                else if (selectedTherapist.gender == 'Female') {
                    genderPref = 'Male';
                }
                else if (this.recordsArray[0].clientDetail.genderPreference == 'NoPreference') {
                    genderPref = 'NoPreference';
                }
                this.clientPreferedGender = genderPref;
            }
        }
    }

    formGenderOverridePreferenceMessage(genderOverrideClientDetails: GenderOverrideMessage[]): string {
        let customMessageTemplate: string = this.localization.captions.bookAppointment.GenderOverrideMessagecustom + "<br>";
        let customMessage: string = "";
        genderOverrideClientDetails.forEach(x => {
            let clientName: string = x.clientName;
            let clientPreferredGender: string = x.clientPreferredGender;
            let therapistGender: string = x.therapistGender;
            customMessage += customMessageTemplate.interpolate({ clientName, clientPreferredGender, therapistGender });

        });

        return customMessage + this.localization.captions.bookAppointment.GenderOverrideMessagestatic;
    }

    getGenderPreference(gender: number, currentTherapist: any): string {
        
        let genderPreference = "";
        switch (gender) {
            case GlobalConst.GenderPreference.enforceGender:
                if (currentTherapist) {
                    genderPreference = currentTherapist.gender;
                }
                break;
            case GlobalConst.GenderPreference.preferMale:
                genderPreference = GlobalConst.Gender.Male;
                break;
            case GlobalConst.GenderPreference.preferFemale:
                genderPreference = GlobalConst.Gender.Female;
                break;
            case GlobalConst.GenderPreference.noPreference:
                genderPreference = GlobalConst.Gender.NoPreference;
            case GlobalConst.GenderPreference.other:
                genderPreference = GlobalConst.Gender.Other;
            default:
                break;
        }
        return genderPreference;

    }

    GetImagesByReference(referenceId: any[], referenceType: string, isthumbnailonly: boolean) {
        this.GetAllImagesByReference(referenceId, referenceType, false, this.successCallback.bind(this), this.utils.errorCallback.bind(this), []);
    }

    /**
     * @function GetAllImageByReference()
     * @Inputs referenceids, referencetype, successCallback, errorCallback, extraParams
     * @description get all image by Referencetype and ids
     */
    GetAllImagesByReference(referenceids: any, referencetype: string, isthumbnailonly: boolean, successCallback, errorCallback, extraParams) {
        return this.httpService.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "v2GetAllImagesByReferenceId",
            uriParams: { isThumbnailOnly: isthumbnailonly },
            method: HttpMethod.Put,
            body: referenceids,
            showError: false,
            extraParams: extraParams
        });
    }

    getEditAppointmentBreakPoints() {
        let appBpoint: UserBreakPoint[] = [];

        appBpoint = this.breakPoint.GetBreakPoint([SPAScheduleBreakPoint.EditAppointmentComments,
        SPAScheduleBreakPoint.EditAppointmentDuration,
        SPAScheduleBreakPoint.EditAppointmentServicePrice,
        SPAScheduleBreakPoint.EditBreakdownTime,
        SPAScheduleBreakPoint.EditSetupTime,
        SPAScheduleBreakPoint.EditCustomField1,
        SPAScheduleBreakPoint.EditCustomField2,
        SPAScheduleBreakPoint.EditCustomField3,
        SPAScheduleBreakPoint.EditCustomField4,
        SPAScheduleBreakPoint.EditCustomField5,
        SPAScheduleBreakPoint.RemoveDoNotMoveFlag
        ]).result;


        this.appointmentBreakPoints = {
            AllowAppointmentComments: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointmentComments, appBpoint),
            AllowAppointmentDuration: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointmentDuration, appBpoint),
            AllowAppointmentServicePrice: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointmentServicePrice, appBpoint),
            AllowBreakdownTime: this.IsActionAllowed(SPAScheduleBreakPoint.EditBreakdownTime, appBpoint),
            AllowSetupTime: this.IsActionAllowed(SPAScheduleBreakPoint.EditSetupTime, appBpoint),
            AllowCustomField1: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField1, appBpoint),
            AllowCustomField2: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField2, appBpoint),
            AllowCustomField3: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField3, appBpoint),
            AllowCustomField4: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField4, appBpoint),
            AllowCustomField5: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField5, appBpoint),
            AllowRemoveDoNotMoveFlag: this.IsActionAllowed(SPAScheduleBreakPoint.RemoveDoNotMoveFlag, appBpoint)
        }

    }

    private IsActionAllowed(breakPoint: number, appointmentBreakPoints: UserBreakPoint[]) {
        let _breakPoint: UserBreakPoint[] = appointmentBreakPoints.filter(bp => bp.breakPointNumber == breakPoint);
        return _breakPoint.length != 0 ? _breakPoint[0].allow : false;
    }

    checkSelected() {
        this.showProcess = false;
        this.showProcess = this.intialClinetArray.some(a =>
            a.services.some(element => {
                if (
                    element.isChecked && (
                        this.localization.currencyToSQLFormat(element.deposit) > 0
                        || this.localization.currencyToSQLFormat(element.gratuity) > 0
                        || this.localization.currencyToSQLFormat(element.serviceCharge) > 0)
                ) {
                    let service=a.services.find(o=>o.appointmentId==element.appointmentId && o.isConvenienceFee);
                    if (service) {
                        service.isChecked = true;
                    }
                    return true;
                }
                else
                {
                    let service=a.services.find(o=>o.appointmentId==element.appointmentId && o.isConvenienceFee);
                    if (service) {
                        service.isChecked = false;
                    }
                }
            })
        )

        this.intialClinetArrayTracker = [...this.intialClinetArray];
        return false;
    }

    async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
        if (callDesc == "getClientRecentAppointmentInfo") {
            let resp: any = result.result;
            this.RecentAppsUIArr = [];
            this.RecentAppsResponse = resp;
            for (let item of resp) {
                const element = item as clientRecentAppsResponse;
                this.RecentAppsUIArr.push(this.createRecentAppsObj(element.serviceName, element.therapistNames, element.date, element.status));
            }
        }
        if (callDesc == "getClientInfo") {

            let resp = <any>result.result;
            if (extraParams[1]) {
                if (this.recordsArray.map(x => x.clientDetail.id).indexOf(resp.clientDetail.id) > -1) {
                    let index = this.recordsArray.map(x => x.clientDetail.id).indexOf(resp.clientDetail.id);
                    if (this.recordsArray && this.recordsArray.length > 0)
                        this.recordsArray.splice(index, 1, resp);
                    if (this.labelRecords && this.labelRecords.length > 0) {
                        var clientData: ClientLabel = {
                            Id: resp.clientDetail.id,
                            FirstName: resp.clientDetail.firstName,
                            LastName: resp.clientDetail.lastName,
                            clientType: resp.clientDetail.clientType
                        };
                        this.labelRecords.splice(index, 1, clientData);
                    }
                }
                else {
                    this.recordsArray.push(resp);
                    var clientLable: ClientLabel = {
                        Id: resp.clientDetail.id,
                        FirstName: resp.clientDetail.firstName,
                        LastName: resp.clientDetail.lastName,
                        clientType: resp.clientDetail.clientType
                    };
                    if (this.labelRecords && this.labelRecords.length > 0) {
                        let isClientAvailable = this.labelRecords.find(x => x.Id === resp.clientDetail.id);
                        if (isClientAvailable) {
                            let getClientIndex = this.labelRecords.findIndex(x => x.Id === resp.clientDetail.id);
                            this.labelRecords[getClientIndex]['clientType'] = resp.clientDetail.clientType;
                        } else {
                            this.labelRecords.push(clientLable);
                        }
                    } else {
                        this.labelRecords.push(clientLable);
                    }
                }
            }
            else {
                this.personal_details_holder = this.createClientInfoDisplayObj(resp);
                this.recordsArray.push(this.createClientInfoDisplayObj(resp));
            }
            if (!this.recordsArray || this.recordsArray.length == 0) {
                this.clientMultipacks = null;
            }
            if (this.recordsArray.length > 0) {
                this.recordsArray.forEach(x => this.getFormattedPhone(x));
                if (!this.isEditAppointment) {
                    this.clientPreferedGender = resp.clientDetail ? resp.clientDetail.genderPreference : 'Male';
                }
            }
            let matchingClientMap = this.clientGuestMap.filter(x => x.ClientId == resp.clientDetail.id)
            if (!matchingClientMap || matchingClientMap.length == 0) {
                this.clientGuestMap.push({ "ClientId": resp.clientDetail.id, "GuestId": resp.clientDetail.guestId, "PMSActivityId": resp.clientDetail.pmsActivityId, "ExternalPMSConfNo": resp.clientDetail.confirmationNumber });
            }
            this.clientCreditCardInfo = resp.clientCreditCardInfo;
            this.CopyCustomFieldFromClient(resp);
            this.getRecentAppointments(resp.clientDetail.id);
            if (resp.clientDetail.clientType != ClientType.Member) {
                this.memberImageUrl = null;
                this.imgService.GetImagesByReference(resp.clientDetail.guestId, GlobalConst.ImgRefType.client, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [], true);
            }
            else {
                this.memberImageUrl = resp.clientDetail.photoUrl;
            }
            this.activateClientSelected = this.recordsArray[0];
            this.currentClient = this.recordsArray[0];
            this.GetItinerary(resp.clientDetail.id);

            let info = <any>result.result
            if (info && !info.clientDetail.bypassClientScheduling && !this.addwaitlist) {
                this.GetConflictingActivities(info.clientDetail.id);
            }
            else if (info) {
                let index = this.ClientActivities.map(x => x.ClientId).indexOf(info.clientDetail.id);
                if (index > -1) {
                    this.ClientActivities.splice(index);
                }
            }

            this.clientVipTypeId=this.GetVipTypeId(resp?.clientDetail?.vip);

            this.utils.ToggleLoader(false);
        }

        if (callDesc == "GetItenerary" || callDesc == "GetItineraryForAppointment") {
            let clientItenerary = <any>result.result;
            this.resultNewAppointment.timelineData = cloneDeep(clientItenerary);
            if (callDesc == "GetItineraryForAppointment") {
                this.resultNewAppointment.clientFullItinerary = cloneDeep(clientItenerary);
                this.subscribeStayDetail(clientItenerary, extraParams[0]);
            }
        }

        if (callDesc == "GetConflictingActivities") {
            if (result.result) {
                let conflict: any = result.result;
                let currentActivityId = extraParams[1];
                if (currentActivityId) {
                    let currentIndex = conflict.map(x => x.id).indexOf(currentActivityId);
                    if (currentIndex != -1) {
                        conflict.splice(currentIndex);
                    }
                }
                let index = this.ClientActivities.map(x => x.ClientId).indexOf(extraParams[0])
                if (conflict.length > 0 && index == -1) {
                    this.ClientActivities.push({ ClientId: extraParams[0], Activities: conflict });
                    var clientName = '';
                    if(this.currentClient != null && this.currentClient.clientDetail.id == extraParams[0])
                    {
                        clientName = this.currentClient.clientDetail.firstName + ' ' + this.currentClient.clientDetail.lastName;
                    }
                    this.utils.ShowErrorMessage(this.captions.ClientUnavailable, this.captions.ClientNotAvailableforSelectedTime + "<br\><br\>" + clientName + " | " + this.utils.FormatAppointmentDateTime(conflict[0].start, conflict[0].end), ButtonType.OkCancel);
                }
                else if (index != -1) {
                    this.ClientActivities.splice(index);
                }
            }
        }

        if (callDesc == "GetAllDepositAppointments") {
            let depositAppointments = <any>result.result ? <any>result.result : [];
            var groupByName: any = [];
            let _thisref = this;

            let corporatesApplicable: any = {};
            let memberConfiguration = JSON.parse(sessionStorage.getItem('memberConfiguration'));
            if (memberConfiguration && memberConfiguration.corpIdNameMap && memberConfiguration.corpIdNameMap != "")
                corporatesApplicable = JSON.parse(memberConfiguration.corpIdNameMap);
            depositAppointments.forEach(function (a) {
                groupByName[a.clientId] = groupByName[a.clientId] || [];
                groupByName[a.clientId].name = a.clientName;
                groupByName[a.clientId].clientType = a.clientType;
                groupByName[a.clientId].isSelected = false;
                groupByName[a.clientId].clientId = a.clientId;
                groupByName[a.clientId].clientLinkId = a.clientLinkId;
                groupByName[a.clientId].corpId = a.memberCorpId;
                groupByName[a.clientId].corpName = (corporatesApplicable[a.memberCorpId]) ? corporatesApplicable[a.memberCorpId] : '';
                groupByName[a.clientId].services = groupByName[a.clientId].services || [];
                groupByName[a.clientId].services.push({
                    id: a.id, appointmentId: a.appointmentId, appointmentStartTime: _thisref.utils.FormatAppointmentDateTime(a.appointmentStartTime, a.appointmentEndTime), appointmentEndTime: a.appointmentEndTime, serviceName: a.serviceName
                    , deposit: _thisref.localization.localizeCurrency(a.deposit, false), price: _thisref.localization.localizeCurrency(a.price, false), tax: _thisref.localization.localizeCurrency(a.tax, false), gratuity: _thisref.localization.localizeCurrency(a.gratuity, false)
                    , serviceCharge: _thisref.localization.localizeCurrency(a.serviceCharge, false),discountPrice: _thisref.localization.localizeCurrency(a.discountPrice, false), isChecked: false, packageGroupId: a.packageGroupId, isRetailItem: false, retailItemId: a.retailItemId ?? 0, isConvenienceFee: false
                });
                if (a.retailItems?.length > 0) {
                    a.retailItems.forEach(item => {
                        groupByName[a.clientId].services.push({
                            id: item.id, appointmentId: item.appoinmentId ?? 0, retailItemId: item.retailItemId, serviceName: item.name + ' Qty:' + item.quantity, deposit: _thisref.localization.localizeCurrency(item.deposit, false), price: _thisref.localization.localizeCurrency(item.price, false), 
                            gratuity: _thisref.localization.localizeCurrency(item.gratuity, false), serviceCharge: _thisref.localization.localizeCurrency(a.serviceCharge, false),discountPrice: _thisref.localization.localizeCurrency(a.discountPrice, false), isChecked: false, packageGroupId: a.packageGroupId, isRetailItem: true, isConvenienceFee: false
                        });
                    });
                }
                if(a.convenienceFeeDetail?.price>0)
                {
                    groupByName[a.clientId].services.push({
                        id: a.id, appointmentId: a.appointmentId,retailItemId: a.convenienceFeeDetail.retailItemId, appointmentStartTime: null, appointmentEndTime: null, serviceName: a.convenienceFeeDetail.name
                        , deposit: _thisref.localization.localizeCurrency(a.convenienceFeeDetail.price,false), price: _thisref.localization.localizeCurrency(a.convenienceFeeDetail.price,false), tax: 0, gratuity: 0
                        , serviceCharge: 0,discountPrice:0, isChecked: false, packageGroupId: a.packageGroupId, isConvenienceFee: true
                    });
                }
            });
            //let firstelement= groupByName.find(a=>a.clientId=this.objidArray[0]);
            //this.objidArray = this.objidArray.filter(t => t > 0);
            this.objidArray = depositAppointments.length > 0 ? depositAppointments.map(t => t.clientId) : this.objidArray.filter(t => t > 0);
            this.objidArray=[...new Set(this.objidArray)];
            this.objidArray.forEach(clientId => {
                if (groupByName.length > 0 && groupByName[clientId])
                    groupByName[clientId].services.sort((a, b) => a.retailItemId - b.retailItemId);
            });
            this.intialClinetArray = Object.values(groupByName);
            if (this.intialClinetArray.length > 1 && this.intialClinetArray[0].clientId != this.objidArray[0]) {
                let firstelement = this.intialClinetArray.find(a => a.clientId == this.objidArray[0]);
                this.intialClinetArray = this.intialClinetArray.filter(a => a !== firstelement);
                this.intialClinetArray.unshift(firstelement)
            }
            this.depositRecordsAvailable.emit(depositAppointments);
            this.checkSelected()
        }
        else if (callDesc == "GetAllClients") {
            let res = <any>result.result;
            res.forEach(element => {
                this.recordobjId.push({ "id": element.id, "name": element.firstName + " " + element.lastName })
            });
        }
        if (callDesc == "GetMultiPacks") {
            let resp: any = result.result;
            this.clientMultipacks = resp;
        }
        if (callDesc == "GetMultiAppointment") {
            let response = <any>result.result
            if (response && response.length > 0) {
                response.map(x => x.appointmentDetail.clientId);
                const guestIds = response.map(x => x.appointmentDetail.guestId);
                this.GetMultiPacksForClients(response.map(x => x.appointmentDetail.clientId));
                this.GetAllImagesByReference(guestIds, ImgRefType.client, false, this.successCallback.bind(this), this.utils.errorCallback.bind(this), []);
                let currentAppointment = response.filter(x => x.id == extraParams[0])[0];
                this.resultPkgEntitlementData = undefined;
                if (currentAppointment && currentAppointment.appointmentDetail && currentAppointment.appointmentDetail.activityId && currentAppointment.appointmentDetail.activityId != DefaultGUID) {
                    let activity = await this.InvokeServiceCallAsync('GetActivity', Host.schedule, HttpMethod.Get, { activityId: currentAppointment.appointmentDetail.activityId });
                    if (activity.result) {
                        this.resultExistingAppActivity = activity.result;
                        if (this.resultExistingAppActivity.linkedPMSActivityId && this.resultExistingAppActivity.linkedPMSActivityId != DefaultGUID) {
                            let res = await this.InvokeServiceCallAsync('GetPackageEntitlementData', Host.schedule, HttpMethod.Get, { activityId: this.resultExistingAppActivity.linkedPMSActivityId });
                            if (res && res.result) {
                                this.resultPkgEntitlementData = res.result;
                            }
                        }
                    }
                }
            }
        }
        if (callDesc === "v2GetImagesByReferenceId") {
            let response = <any>result.result
            if (response && response.length > 0) {
                response.forEach((img) => {
                    if (!this.imageArray.find(x => x['imageReferenceId'] === img.imageReferenceId)) {
                        this.clientImageChange(response);
                    }
                })
            }

        }
        if (callDesc == "GetConfiguration") {
            let res: SystemConfig = <any>result.result;
            this.REQUIREGENEDERPREFRENCE = res.value == "true" ? true : false;
            this.requireGenderPreference$.next(this.REQUIREGENEDERPREFRENCE);
        }

        if (callDesc == "GetServicePriceTypes") {
            this.selectedServicePriceTypes = result.result ? <any>result.result : [];
        }

        if (callDesc === "GetClientBlockById") {
            const response = result.result ? <any>result.result : null;
            this.ClientBlockInformation = response;
            if (!this.clientBlockInfos)
                this.clientBlockInfos = [];
            if (response) {
                this.clientBlockInfos = this.clientBlockInfos.filter(y => y.id != response.id);
                this.clientBlockInfos.push(response);
            }
        }

        if (callDesc === "LinkedAppointments") {
            this.linkedAppointments = result.result ? result.result : [];
        }
    }
    subscribeStayDetail(clientItinerary, date) {
        let stayDetail = clientItinerary.find(x =>
            !x.busy && (!this.pmsActivityId || this.pmsActivityId.toLowerCase() == x.id) &&
            this.localization.isFallBetweenDates(x.start, x.end, date)
        );
        this.PMSClientItineraryData.next(stayDetail);
    }

    subscribeWaitListDetail(clientItinerary, fromDate, toDate) {
        let stayDetail = clientItinerary.find(x =>
            !x.busy &&
            this.localization.isFallBetweenMultipleDates(x.start, x.end, fromDate, toDate)
        );
        this.PMSClientItineraryData.next(stayDetail);
    }



    getFormattedPhone = (ClientInfoParam) => {
        let phoneNumbers = [];
        if (ClientInfoParam.phoneNumbers && ClientInfoParam.phoneNumbers.length > 0) {
            let primaryPhone = ClientInfoParam.phoneNumbers.find(x => x.isPrimary);
            if (primaryPhone) {
                //ADDING FOR EXTENSION FIELD
                let formattedPhoneNo = this.utils.getFormattedPhNo(primaryPhone);
                phoneNumbers.push(formattedPhoneNo);
            }
            else {
                phoneNumbers = ClientInfoParam.phoneNumbers.map(x => {
                    //ADDING FOR EXTENSION FIELD
                    return this.utils.getFormattedPhNo(x);
                })
            }
        }
        ClientInfoParam['formattedPhones'] = phoneNumbers
    }

    CopyCustomFieldFromClient(clientObj) {

        if (this.addwaitlist) {
            return;
        }

        if (this.IsCopyFromClient[0]) {
            this.otherdetails.customField1 = clientObj.clientDetail.customField1 && clientObj.clientDetail.customField1 != 0 ? clientObj.clientDetail.customField1 : this.otherdetails.customField1;
        }
        if (this.otherdetails.customField1 == 0) {
            this.otherdetails.customField1 = null;
        }
        if (this.IsCopyFromClient[1]) {
            this.otherdetails.customField2 = clientObj.clientDetail.customField2 && clientObj.clientDetail.customField2 != 0 ? clientObj.clientDetail.customField2 : this.otherdetails.customField2;
        }
        if (this.otherdetails.customField2 == 0) {
            this.otherdetails.customField2 = null;
        }
        if (this.IsCopyFromClient[2]) {
            this.otherdetails.customField3 = clientObj.clientDetail.customField3 && clientObj.clientDetail.customField3 != 0 ? clientObj.clientDetail.customField3 : this.otherdetails.customField3;
        }
        if (this.otherdetails.customField3 == 0) {
            this.otherdetails.customField3 = null;
        }
        if (this.IsCopyFromClient[3]) {
            this.otherdetails.customField4 = clientObj.clientDetail.customField4 ? clientObj.clientDetail.customField4 : this.otherdetails.customField4;
        }
        if (this.IsCopyFromClient[4]) {
            this.otherdetails.customField5 = clientObj.clientDetail.customField5 ? clientObj.clientDetail.customField5 : this.otherdetails.customField5;
        }
        let otherInfo = this.otherdetails;
        this.otherDetailsFormGroup.controls.customField1.setValue(otherInfo.customField1);
        this.otherDetailsFormGroup.controls.customField2.setValue(otherInfo.customField2);
        this.otherDetailsFormGroup.controls.customField3.setValue(otherInfo.customField3);
        this.otherDetailsFormGroup.controls.customField4.setValue(otherInfo.customField4);
        this.otherDetailsFormGroup.controls.customField5.setValue(otherInfo.customField5);
        this.otherDetailsFormGroup.controls.linkcode.setValue(otherInfo.linkcode);
        this.otherDetailsFormGroup.controls.gender.setValue(otherInfo.gender);
        //this.otherDetailsFormGroup.controls.guestTypeId.setValue(otherInfo.guestType);
    }

    createClientInfoDisplayObj(client: any): clientInfoDisplay {
        let name = client.clientDetail.firstName + ' ' + client.clientDetail.lastName;
        let age = calcAge(client.clientDetail.dateOfBirth);
        let gender = client.clientDetail.gender;
        let phone: string[] = [];
        let _addr = client.addresses;
        let email: string[] = [];

        client.emails.forEach(emailObj => {
            email.push(emailObj.emailId);
        });

        client.phoneNumbers.forEach(ph => {
            phone.push(ph.number);
        });


        let _line1 = '';
        let _line2 = '';
        let _line3 = '';
        let _state = '';
        let _city = '';
        let _country = '';
        let _zip = '';
        let addrDesc = '';

        if (_addr.length != 0) {

            let _lineAddr1 = stringFormat(_addr[0].line1 ? _addr[0].line1 : '', ', ');
            let _lineAddr2 = stringFormat(_addr[0].line2, ', ');
            let _lineAddr3 = stringFormat(_addr[0].line3, ', ');
            let _stateAddr = stringFormat(_addr[0].state, ', ');
            let _cityAddr = stringFormat(_addr[0].city, ', ');
            let _countryAddr = stringFormat(_addr[0].country, ', ');
            let _zipAddr = stringFormat(_addr[0].line1, '.');
            addrDesc = _lineAddr1 + _lineAddr2 + _lineAddr3 + _stateAddr + _cityAddr + _countryAddr + _zipAddr;

        }
        addrDesc = _line1 + _line2 + _line3 + _state + _city + _country + _zip;


        return {
            name: name,
            age: age,
            gender: gender,
            phone: phone,
            email: email,
            address: addrDesc
        }
    }
    month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    createRecentAppsObj(ServiceName: string, TherapistNames: string[], onDate: Date, Status: string): RecentAppointment {
        var dt = this.utils.getDate(onDate)
        var dtStr = this.localization.LocalizeShortDateTime(dt);
        let desc: string = ServiceName + " " + this.captions.by + " " + TherapistNames.toString() + " " + this.captions.on + " " + dtStr;
        let UiStatus: string;

        if (Status == "RESV") {
            UiStatus = this.captions.Scheduled;
        } else if (Status == "CKIN") {
            UiStatus = this.captions.CheckedIn;
        }
        else if (Status == "CKOUT") {
            UiStatus = this.captions.CheckedOut;
        }
        else if (Status == "NOSHOW") {
            UiStatus = this.captions.NoShow;
        }
        else if (Status == "CANC") {
            UiStatus = this.captions.Cancelled;
        }
        return {
            appointmentDetails: desc,
            action: UiStatus
        } as RecentAppointment
    }
    GetItinerary(clientId: any) {
        if (this.addwaitlist) {
            return;
        }
        const tempStartTime = (this.resultExistingAppointment && this.resultExistingAppointment.appointmentDetail ? this.resultExistingAppointment.appointmentDetail.startTime : null);
        let startTime: any = this.resultNewAppointment.dateField ? this.resultNewAppointment.dateField : tempStartTime;

        if (!startTime) {
            return;
        }

        let dt: Date =
            typeof this.resultNewAppointment.dateField == "string"
                ? this.utils.getDate(this.resultNewAppointment.dateField)
                : this.resultNewAppointment.dateField;
        if (dt && dt.getTime() && this.resultNewAppointment.time) {
            startTime = this.utils.convertDateFormat(this.utils.getDate(
                dt.getFullYear() +
                "-" +
                this.utils.getFullMonth(dt) +
                "-" +
                this.utils.getFullDate(dt) +
                "T" +
                this.localization.DeLocalizeTime(this.resultNewAppointment.time)
            )
            );
        }

        let guestId = this.clientGuestMap.filter(x => x.ClientId == clientId).map(y => y.GuestId)[0];
        if (startTime && guestId != DefaultGUID) {
            this.httpService.CallApiWithCallback({
                host: Host.schedule,
                success: this.successCallback.bind(this),
                error: this.utils.errorCallback.bind(this),
                callDesc: "GetItineraryForAppointment",
                method: HttpMethod.Get,
                uriParams: { clientId: guestId, dateTime: startTime },
                showError: false,
                extraParams: [startTime]
            });
        }
    }

    setGenderPreferenceRequired() {
        this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "APPOINMENT_REQUIREGENEDERPREFRENCE");
    }


    setGenderPreferenceOnLoad() {
        this.clientPreferedGender = "";
        this.otherdetails.gender = this.otherdetails.gender != 0 ? this.otherdetails.gender : GenderPreference.none;
        let selectedstaffId = this.clientScreenProperties.selectedStaff && this.clientScreenProperties.selectedStaff.length > 0 ? this.clientScreenProperties.selectedStaff[0].id : 0;
        let selectedTherapist = this.therapistArray.find((x) => { return x.id == selectedstaffId })
        if (selectedTherapist && selectedTherapist != "") {
            const tempGender = selectedTherapist.gender == "Female" ? "Male" : "";
            this.clientPreferedGender = selectedTherapist.gender == "Male" ? "Female" : tempGender;
            if (this.recordsArray[0] && this.otherdetails.gender == 0) {
                if (selectedTherapist.gender == this.recordsArray[0].clientDetail.genderPreference) {
                    this.otherdetails.gender = GenderPreference.enforceGender;
                } else if (this.recordsArray[0].clientDetail.genderPreference == 'Male') {
                    this.otherdetails.gender = GenderPreference.preferMale;
                }
                else if (this.recordsArray[0].clientDetail.genderPreference == 'Female') {
                    this.otherdetails.gender = GenderPreference.preferFemale;
                }
                else if (this.recordsArray[0].clientDetail.genderPreference == 'NoPreference') {
                    this.otherdetails.gender = GenderPreference.noPreference;
                }
                else {
                    this.otherdetails.gender = GenderPreference.none;
                }
            }
        }
    }

    disableRadioButton(isFromPackage = false, packageMaxPeople = 1) {
        let maxGuest = this.clientScreenProperties['maxGuest'];
        let helperText = this.clientScreenProperties['helperText'];
        let clientadded = this.labelRecords ? this.labelRecords.length : 0;
        if(isFromPackage){
            this.isRadioDisable = packageMaxPeople != -1 ? clientadded >= maxGuest : false
        }
        else {
            this.isRadioDisable = clientadded >= maxGuest;
        }    
    }

    GetConflictingActivities(clientId: any) {
        const tempTime = (this.resultExistingAppointment && this.resultExistingAppointment.appointmentDetail ? this.resultExistingAppointment.appointmentDetail.startTime : null);
        let startTime: any = this.resultNewAppointment && this.resultNewAppointment.dateField ? this.resultNewAppointment.dateField : tempTime;

        if (!startTime) {
            return;
        }

        let dt: Date =
            typeof this.resultNewAppointment.dateField == "string"
                ? this.utils.getDate(this.resultNewAppointment.dateField)
                : this.resultNewAppointment.dateField;
        if (dt && dt.getTime() && this.resultNewAppointment.time) {
            startTime = this.utils.convertDateFormat(this.utils.getDate(
                dt.getFullYear() +
                "-" +
                this.utils.getFullMonth(dt) +
                "-" +
                this.utils.getFullDate(dt) +
                "T" +
                this.localization.DeLocalizeTime(this.resultNewAppointment.time)
            )
            );

            let endTime = this.utils.convertDateFormat(this.utils.AddMins(startTime, this.resultNewAppointment.duration ? this.resultNewAppointment.duration : 0));
            let guestId = this.clientGuestMap.filter(x => x.ClientId == clientId).map(y => y.GuestId)[0];
            let currentActivityId = this.existingAppointmentsRef && this.existingAppointmentsRef.length > 0 && this.existingAppointmentsRef.filter(x => x.appointmentDetail.guestId == guestId).length > 0 ?
                this.existingAppointmentsRef.filter(x => x.appointmentDetail.guestId == guestId)[0].appointmentDetail.activityId : null;
            if (startTime && guestId != DefaultGUID) {
                this.httpService.CallApiWithCallback({
                    host: Host.schedule,
                    success: this.successCallback.bind(this),
                    error: this.utils.errorCallback.bind(this),
                    callDesc: "GetConflictingActivities",
                    method: HttpMethod.Get,
                    uriParams: { clientId: guestId, startTime: startTime, endTime: endTime },
                    showError: false,
                    extraParams: [clientId, currentActivityId]
                });
            }
        }
    }

    async IsAppointmentLockedbyOtherSession(appointmentId: number, lockIfAvailable: boolean = false): Promise<boolean> {
        let isLocked: boolean = false;
        if (!appointmentId) {
            return isLocked;
        }
        let uriParams = { id: appointmentId, sessionId: this.currentUserSessionId, lockAppointment: lockIfAvailable };
        let apiResponse = await this.InvokeServiceCallAsync("GetAppointmentLock", Host.schedule, HttpMethod.Get, uriParams);
        if (apiResponse && apiResponse.result != 0 && this.currentUserSessionId != apiResponse.result) { // Appointment has been locked by other session
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(100000), ButtonType.Ok);
            isLocked = true;
        }
        return isLocked;
    }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this.httpService.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (err) {
            if (err && err.error) {
                let errMsg = this.localization.getError(err.error.errorCode);
                if (err.error.errorCode == 310002) {
                    await this.utils.ShowErrorMessage(this.localization.captions.common.Warning, errMsg, ButtonType.Ok)
                        .afterClosed().toPromise();
                    return;
                }
                else if (err.error.errorCode == 310001) {
                    await this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg, ButtonType.Ok)
                        .afterClosed().toPromise();
                    return;
                }
                else {
                    console.error(err);
                    throw err;
                }
            }


        }
    }

    checkForMinGuestNeeded(): boolean {
        let errorExist = false;
        if (this.multiClientInfo && this.clientScreenProperties &&
            this.multiClientInfo.length < this.clientScreenProperties.minGuest) {
            errorExist = true;
        }
        return errorExist;
    }

    checkForMaxGuestExceeded(): boolean {
        let errorExist = false;
        if (this.multiClientInfo && this.clientScreenProperties &&
            this.multiClientInfo.length > this.clientScreenProperties.maxGuest) {
            errorExist = true;
        }
        return errorExist;
    }

    setPriceTypeCodeUI() {
        if (!this.isMultiPackAppointment) { // PriceType Not applicable for Multipack appointments
            this.clientInfoPriceType = this.selectedServicePriceTypes.map(spt => {
                return {
                    price: spt.price,
                    serviceId: spt.serviceId,
                    priceTypeId: spt.priceTypeId,
                    code: this.allPriceTypes.find(apt => apt.id == spt.priceTypeId) ? this.allPriceTypes.find(apt => apt.id == spt.priceTypeId).code : ''
                }
            });
        }
    }




    setPriceTypeCodeForSelectedClient(clientId: number, serviceId: number) {
        this.clientInfoPriceType = [];//RESET ALL THE AVAILABLE PRICETYPE,TO CONSTRUCT NEW PRICETYPE
        if (!this.clientAddedFromClientInformation) { //If new client added , while edit appointment. that time need to display available servicepriceTypes for the services
            //will assign client associatedPriceType
            this.clientInfoPriceType = this.multiClientInfo.filter(x => x.id == clientId).map(spt => {
                return {
                    price: spt.price,
                    serviceId: serviceId,
                    priceTypeId: spt.priceTypeId,
                    code: this.allPriceTypes.find(apt => apt.id == spt.priceTypeId) ? this.allPriceTypes.find(apt => apt.id == spt.priceTypeId).code : ''
                }
            });
        }
        //For loading remaining available servicePriceTypes
        if (this.multiClientInfo.length > 0 && this.selectedServicePriceTypes.length > 0) {
            for (let selectedServiceItem of this.selectedServicePriceTypes) {
                let isPriceTypeAlreadyAvailable: number = this.clientInfoPriceType.filter(x => x.priceTypeId == selectedServiceItem.priceTypeId).length;
                if (isPriceTypeAlreadyAvailable == 0) {
                    let ServicePriceTypes: ServicePriceTypeWithCode = {
                        price: selectedServiceItem.price,
                        serviceId: serviceId,
                        priceTypeId: selectedServiceItem.priceTypeId,
                        code: this.allPriceTypes.find(apt => apt.id == selectedServiceItem.priceTypeId) ? this.allPriceTypes.find(apt => apt.id == selectedServiceItem.priceTypeId).code : ''
                    };
                    this.clientInfoPriceType.push(ServicePriceTypes);
                }
            }
        }
        this.setPriceTypeUIforMutliClient(clientId);
    }


    public setPriceTypeUIforMutliClient(clientId: number) {
        this.setPriceTypeUIControlValues(this.multiClientInfo, clientId);
        this.isPriceTypeApplicable();
    }

    private setPriceTypeUIControlValues(multiClientInfoObj: any[], clientId: number): void {
        let activeClient = multiClientInfoObj.find(client => client.id == clientId);
        let priceValue = this.isEditAppointment && activeClient ? this.resultExistingAppointment.appointmentDetail.price : this.resultNewAppointment.price;
        //setting dropdown selection
        this.clientInfopriceTypeModel = (activeClient && activeClient.priceTypeId > 0) ? activeClient.priceTypeId : 0;
        //setting price value input
        this.selectedClientPriceValue = activeClient ? activeClient.price : priceValue;
    }

    public updatePriceTypeForAppointment(clientId) {
        if (this.multiClientInfo.length > 0 && this.selectedServicePriceTypes.length > 0) {
            let clientObj = this.multiClientInfo.find(x => x.id == clientId);
            if (clientObj) {
                this.multiClientInfo.forEach(client => {
                    if (client.id == clientId) {
                        let selectedpriceTypeCode = this.selectedServicePriceTypes.find(y => y.priceTypeId == clientObj.priceTypeId);
                        client.priceTypeId = selectedpriceTypeCode ? selectedpriceTypeCode.priceTypeId : 0
                    }
                });
            }
        }
    }

    public updatePriceTypeIDForAppointment() {
        if (this.multiClientInfo.length > 0) {
            this.multiClientInfo.forEach(client => {
                if (this.selectedServicePriceTypes.length == 0) {
                    client.priceTypeId = 0;// If service has no priceTypes we no need to consider for client priceType
                }
            });
        }
    }



    public updateMultiClientInfoPriceDetails() {
        if (this.isPriceTypeApplicable()) {
            this.multiClientInfo.forEach(x => {
                let selectedpriceTypeCode = this.selectedServicePriceTypes.find(y => y.priceTypeId == x.priceTypeId);
                if (selectedpriceTypeCode && x.priceTypeId != 0) {
                    x.price = selectedpriceTypeCode.price;
                } else {
                    x.price = this.resultNewAppointment.price;
                }
            });
        }
        else {
            //For normal Appointment
            this.multiClientInfo.forEach(x => {
                x.price = this.resultNewAppointment.price;
            });

            if (this.multiClientInfo.length == 0) {
                this.selectedClientPriceValue = this.resultNewAppointment.price
            }
        }
    }

    public updateClientInfoPriceDetails(clientDetails: any) {
        if (this.isPriceTypeApplicable()) {
            this.multiClientInfo.forEach(x => {
                if (x.id == clientDetails.id) {
                    let selectedpriceTypeCode = this.selectedServicePriceTypes.find(y => y.priceTypeId == x.priceTypeId);
                    if (selectedpriceTypeCode && x.priceTypeId != 0) {
                        x.price = selectedpriceTypeCode.price;
                    } else {
                        x.price = this.resultNewAppointment.price;
                    }
                }
            });
        }
        else {
            //For normal Appointment
            this.multiClientInfo.forEach(x => {
                x.price = this.resultNewAppointment.price;
            });

            if (this.multiClientInfo.length == 0) {
                this.selectedClientPriceValue = this.resultNewAppointment.price
            }
        }
    }

    async clientServicePriceTypeCalc(clientDetails: any, hasOriginalServiceAndDate = false) {

        if (this.isPriceTypeApplicable()) {
            this.isAptPriceReadOnly = true;
            this.updateMultiClientInfoPriceDetails();
            if (!hasOriginalServiceAndDate) {
                await this.getServicePriceTypePriceBasedOnYield(clientDetails);
            } else {
                this.UpdateOriginalPriceTypePrice();
            }
        }
        else {
            this.multiClientInfo.forEach(x => {
                x.price = this.resultNewAppointment.price;
            });
        }


        this.setPriceTypeUIforMutliClient(this.multiClientInfo[0].id);
    }

    public async getServicePriceTypePriceBasedOnYield(clientDetails: any) {
        if (clientDetails && this.multiClientInfo.length > 0) {
            let apiClientBody = this.multiClientInfo.filter(x => x.priceTypeId > 0);
            let date: Date = this.resultNewAppointment.dateField;
            let formattedDate = this.localization.convertDateObjToAPIdate(date);

            let result = await this.httpService.CallApiAsync<any>({
                host: Host.schedule,
                callDesc: "GetServicePriceBasedOnPriceTypeYield",
                method: HttpMethod.Put,
                body: apiClientBody,
                uriParams: { serviceId: this.resultNewAppointment.service, appointmentDate: formattedDate }
            });

            let selectedClientPriceType: any = result.result;

            this.multiClientInfo.forEach(client => {
                let clientUpdatedPrice = selectedClientPriceType.find(y => y.id == client.id && y.priceTypeId == client.priceTypeId);
                if (clientUpdatedPrice) {
                    client.price = clientUpdatedPrice.price
                }
            });
        }
    }

    serviceChargecalc(isUpdate: boolean, SelectedService: number, service: ServiceDetail, price: number, GlobalService: any, percentage: number): number {
        let ServiceCharge = (isUpdate && this.existingAppointmentsRef && this.existingAppointmentsRef.length > 0
            && this.existingAppointmentsRef[0].appointmentDetail && this.existingAppointmentsRef[0].appointmentDetail.serviceCharge > 0) ? this.existingAppointmentsRef[0].appointmentDetail.serviceCharge : 0;

        if (this.serviceChanged || !isUpdate || this.resultNewAppointment.price != this.existingAppointmentsRef[0].appointmentDetail.price) {
            ServiceCharge = this.getGratuityAndServiceChargeDetails(SelectedService, "ServiceCharge", service, price, GlobalService);
        } else if (percentage > 0) {
            ServiceCharge = (price * percentage / 100);
        }
        return ServiceCharge ? Number(ServiceCharge.customToFixed()) : 0;
    }



    GratuityCalculation(isUpdate: boolean, SelectedService: number, service: ServiceDetail, price: number, GlobalService: any, percentage: number): number {
        let Gratuity = (isUpdate && this.existingAppointmentsRef && this.existingAppointmentsRef.length > 0
            && this.existingAppointmentsRef[0].appointmentDetail && this.existingAppointmentsRef[0].appointmentDetail.gratuity > 0) ? this.existingAppointmentsRef[0].appointmentDetail.gratuity : 0;

        if (this.serviceChanged || !isUpdate || this.resultNewAppointment.price != this.existingAppointmentsRef[0].appointmentDetail.price) {
            Gratuity = this.getGratuityAndServiceChargeDetails(SelectedService, "Gratuity", service, price, GlobalService);
        } else if (percentage > 0) {
            Gratuity = (price * percentage / 100);
        }
        return Gratuity ? Number(Gratuity.customToFixed()) : 0;
    }

    getGratuityAndServiceChargeDetails(SelectedService: number, category: string, service: ServiceDetail, price: number, GlobalService: any): number {
        if (SelectedService == 0)
            SelectedService = service.id;
        if (SelectedService !== service.id)
            this.serviceChanged = true;
        if (!price || !service.id) return;

        if (category == "ServiceCharge") {
            return this.getServiceChargeAmount(service, price);
        }
        if (category == "Gratuity") {
            return this.getGratuityAmount(service, price);
        }
    }

    GetServiceGratuityPercent(isUpdate: boolean, serviceId: number, percentType: string, GlobalService: any): number {
        let percentage = 0;
        if (GlobalService && GlobalService.length > 0) {
            let service = GlobalService.find(x => { return x.id == serviceId });
            if (service) {
                switch (percentType) {
                    case SERVICECHARGE:
                        if (!isUpdate || this.resultNewAppointment.price != this.existingAppointmentsRef[0].appointmentDetail.price) {
                            percentage = service.serviceChargePercent;
                        }
                        else if ((this.existingAppointmentsRef && this.existingAppointmentsRef.length > 0
                            && this.existingAppointmentsRef[0].appointmentDetail)) {
                            percentage = this.existingAppointmentsRef[0].appointmentDetail.serviceChargePercent > 0 ? this.existingAppointmentsRef[0].appointmentDetail.serviceChargePercent : 0;
                            percentage = this.existingAppointmentsRef[0].appointmentDetail.serviceId != serviceId ? service.serviceChargePercent : percentage;
                        }
                        break;
                    case GRATUITY:
                        if (!isUpdate || this.resultNewAppointment.price != this.existingAppointmentsRef[0].appointmentDetail.price) {
                            percentage = service.gratuityPercent;
                        }
                        else if ((this.existingAppointmentsRef && this.existingAppointmentsRef.length > 0
                            && this.existingAppointmentsRef[0].appointmentDetail)) {
                            percentage = this.existingAppointmentsRef[0].appointmentDetail.gratuityPercent > 0 ? this.existingAppointmentsRef[0].appointmentDetail.gratuityPercent : 0;
                            percentage = this.existingAppointmentsRef[0].appointmentDetail.serviceId != serviceId ? service.gratuityPercent : percentage;
                        }
                        break;
                }
            }
        }
        return percentage;
    }

    GetServiceChargeGratuityValue(service: ServiceDetail, price: number, type: string): number {
        let value: number = 0;
        if (service) {
            switch (type) {
                case SERVICECHARGE:
                    const tempService = (service.serviceChargePercent > 0 ? this.utils.RoundOff2DecimalPlaces(price * service.serviceChargePercent / 100) : service.serviceChargeAmount);
                    value = service.isAutoServiceCharge ? tempService : 0;
                    break;
                case GRATUITY:
                    const tempGratuity = (service.gratuityPercent > 0 ? this.utils.RoundOff2DecimalPlaces(price * service.gratuityPercent / 100) : service.gratuityAmount);
                    value = service.isAutoGratuity ? tempGratuity : 0;
                    break;
            }
        }
        return value;
    }

    getServiceChargeAmount(service: ServiceDetail, price: number): number {
        let serviceCharge: number = 0;
        if (service) {
            const tempServiceCharge = (service.serviceChargePercent > 0 ? this.utils.RoundOff2DecimalPlaces(price * service.serviceChargePercent / 100) : service.serviceChargeAmount);
            serviceCharge = service.isAutoServiceCharge ? tempServiceCharge : 0;
        }
        return serviceCharge;
    }

    getGratuityAmount(service: ServiceDetail, price: number): number {
        let gratuity: number = 0;
        if (service) {
            const tempGPercent = (service.gratuityPercent > 0 ? this.utils.RoundOff2DecimalPlaces(price * service.gratuityPercent / 100) : service.gratuityAmount);
            gratuity = service.isAutoGratuity ? tempGPercent : 0;
        }
        return gratuity;
    }

    public isPriceTypeApplicable(): boolean {
        let isPriceTypeApplicable: boolean = false;
        if (this.selectedServicePriceTypes.length > 0 && this.multiClientInfo && this.multiClientInfo.length > 0) {
            this.multiClientInfo.forEach(element => {
                let result = this.selectedServicePriceTypes.filter(x => x.priceTypeId == element.priceTypeId);
                if (result && result.length > 0) {
                    isPriceTypeApplicable = true;
                    this.isAptPriceReadOnly = isPriceTypeApplicable;
                }
            });
        }
        this.isAptPriceReadOnly = isPriceTypeApplicable;
        if (this.appoinmentdetailArray && this.appoinmentdetailArray.controls) {
            if (this.isAptPriceReadOnly) {
                this.appoinmentdetailArray.controls['Price']?.disable()
            }
            else {
                this.appoinmentdetailArray.controls['Price']?.enable();
            }
        }
        return isPriceTypeApplicable;
    }

    public UpdateOriginalPriceTypePrice() {
        if (this.existingAppointmentsRef && this.existingAppointmentsRef.length > 0) {
            this.multiClientInfo.forEach(client => {
                let originalAppointment = this.existingAppointmentsRef.find(y => y.appointmentDetail.clientId == client.id);
                if (originalAppointment && originalAppointment.appointmentDetail.priceTypeId > 0) {
                    client.price = originalAppointment.appointmentDetail.price;
                    client.priceTypeId = originalAppointment.appointmentDetail.priceTypeId;
                }
            });
        }
    }
    public ValidatePriceTypeAvailable(clientId: number): boolean {
        let isPriceTypeAvailable: boolean = true;
        let clientObj = this.multiClientInfo.find(x => x.id == clientId);
        if (clientObj && clientObj.priceTypeId > 0) {
            let selectedPriceTypes = this.selectedServicePriceTypes.find(x => x.priceTypeId == clientObj.priceTypeId);
            //If multiclient priceType not available in Selected ServicePriceType
            if (!selectedPriceTypes) {
                isPriceTypeAvailable = false;
            }
        }
        return isPriceTypeAvailable;
    }

    public activeBorder(i, clientObj) {
        if (clientObj) {
            this.activeBorderId = i
            this.activeSelectedClient = clientObj.clientDetail.id;
            this.currentClient = clientObj;
            this.clientTypeId=this.currentClient.clientDetail.clientType;
            let dt: Date =
                typeof this.resultNewAppointment.dateField == "string"
                    ? this.utils.getDate(this.resultNewAppointment.dateField)
                    : this.resultNewAppointment.dateField;
            if (dt && dt.getTime() && this.resultNewAppointment.time) {
                let startTime = this.utils.convertDateFormat(this.utils.getDate(
                    dt.getFullYear() +
                    "-" +
                    this.utils.getFullMonth(dt) +
                    "-" +
                    this.utils.getFullDate(dt) +
                    "T" +
                    this.localization.DeLocalizeTime(this.resultNewAppointment.time)
                )
                );
                let guestId = this.clientGuestMap.filter(x => x.ClientId == this.activeSelectedClient).map(y => y.GuestId)[0];
                if (startTime && !this.IsAppointmentDisabled && guestId != DefaultGUID) {
                    this.httpserviceCall.CallApiWithCallback({
                        host: Host.schedule,
                        success: this.successCallback.bind(this),
                        error: this.utils.errorCallback.bind(this),
                        callDesc: "GetItineraryForAppointment",
                        method: HttpMethod.Get,
                        uriParams: { clientId: guestId, dateTime: startTime },
                        showError: true,
                        extraParams: [startTime]
                    });
                }

            }
            if (this.isEditAppointment && !this.ValidatePriceTypeAvailable(this.activeSelectedClient) && !this.serviceChanged) {
                this.setPriceTypeCodeForSelectedClient(this.activeSelectedClient, this.resultExistingAppointment.appointmentDetail.serviceId);
            }
            else {
                this.setPriceTypeCodeUI();
                this.setPriceTypeUIforMutliClient(this.activeSelectedClient);
            }

            if (this.isEditAppointment) {
                switch (clientObj.clientDetail.clientType) {
                    case GlobalConst.ClientType.Guest:
                        this.clientTypes[0].checked = true;
                        this.clientTypes[1].checked = false;
                        this.clientTypes[2].checked = false;
                        this.selectedClientType = ClientType.Guest;
                        break;
                    case GlobalConst.ClientType.Member:
                        this.clientTypes[0].checked = false;
                        this.clientTypes[1].checked = false;
                        this.clientTypes[2].checked = true;
                        this.selectedClientType = ClientType.Member;
                        break;
                    case GlobalConst.ClientType.HotelReservation:
                        this.clientTypes[0].checked = false;
                        this.clientTypes[1].checked = true;
                        this.clientTypes[2].checked = false;
                        this.selectedClientType = ClientType.HotelReservation;
                        break;
                    default:
                        break;
                }
                this.disableRadioButton();
            }

            this.clientId = clientObj.clientDetail.id;
            this.guestId = clientObj.clientDetail.guestId;
            let clientFormatedName: string = clientObj.clientDetail.firstName + " " + clientObj.clientDetail.lastName;
            this.commentPlaceHolder = this.localization.replacePlaceholders(this.captions.Commentsaddedfor, ['clientName'], [clientFormatedName]);
            this.clientVipType = this.GetVipTypeName(clientObj.clientDetail.vip);
            this.clientVipTypeId=this.GetVipTypeId(clientObj.clientDetail.vip);
            this.refreshClient(clientObj.clientDetail.id);
        }
    }

    GetVipTypeName(vipCode){
        let vip = this.allVipTypes.find(x => x.code == vipCode)
        if(vip){
            return vip.name;
        }
        return '';
    }

    GetVipTypeId(vipCode){
        let  vipType=this.allVipTypes.find(x => x.code == vipCode);
        if(vipType){
            return vipType.id;
        }
        return 0;
    }

    public refreshClient(clientId) {
        for (let multiClientInfoItem of this.multiClientInfo) {
            const currentClientObj = multiClientInfoItem;
            if (currentClientObj.id == clientId) {

                if (this.isEditAppointment && !this.ValidatePriceTypeAvailable(clientId) && !this.serviceChanged) {
                    this.setPriceTypeCodeForSelectedClient(clientId, this.resultExistingAppointment.appointmentDetail.serviceId);
                }
                else {
                    this.setPriceTypeCodeUI();
                }

                this.clientComments = currentClientObj.comments;
                this.clientTherapist = currentClientObj.TherapistId;
                this.clientInfopriceTypeModel = currentClientObj.priceTypeId;
                this.selectedClientPriceValue = currentClientObj.price;
            }
        }
    }

    public IsTempRemovedFromBackEnd(errorResp: BaseResp): boolean {
        return errorResp && errorResp.result &&
            errorResp.result.length &&
            errorResp.result.some(r => r == ErrorConstants.TempNotFound);
    }

    async getPriceForServiceChargeAndGratuity(price: number, service: ServiceDetail): Promise<PriceModel> {
        var priceModel: PriceModel = {
            priceForGratuity: price,
            priceForServiceCharge: price
        }

        if (this.PropertyInfo.IsVATEnabled) {
            if ((service.isAutoGratuity && service.gratuityPercent > 0) || (service.isAutoServiceCharge && service.serviceChargePercent > 0)) {
                let uriPropertyParams = { propertyId: parseInt(this.localization.GetPropertyInfo("PropertyId")) }
                const vat = await this.InvokeServiceCallAsync('GetVatConfiguration', GlobalConst.Host.authentication, HttpMethod.Get, uriPropertyParams);
                let vatConfigurationData = vat.result;
                if (vatConfigurationData.serviceChargeOnNet || vatConfigurationData.gratuityOnNet) {
                    let uriRetailParams = { itemId: service.retailItemId }
                    const retail = await this.InvokeServiceCallAsync('GetShopItem', GlobalConst.Host.retailManagement, HttpMethod.Get, uriRetailParams);
                    var lineItem: LineItem = {
                        externalPOSItemId: retail.result.retailItem.retailItemDetail.externalPOSId,
                        unitPrice: price,
                        quantity: 1
                    };
                    var lineItemArray: LineItem[] = [];
                    lineItemArray.push(lineItem);
                    var checkData: CheckData = {
                        serviceCharge: 0,
                        gratuity: 0
                    }
                    var retailTransaction: TransactionAdapter = {
                        lineItems: lineItemArray,
                        checkData: checkData,
                        checkJSON: ''
                    }
                    let outletTerminals: BaseResponse<StoreTerminal[]> = await this.InvokeServiceCallAsync('GetStoreTerminal', Host.retailManagement, HttpMethod.Get, { outletId: retail.result.retailItem.outletItem[0].outletId });
                    let uriOutletparams = { outletId: retail.result.retailItem.outletItem[0].outletId, terminalId: outletTerminals.result[0].terminalId }
                    const ticket = await this.InvokeServiceCallAsync('GetTicketDetail', GlobalConst.Host.retailPOS, HttpMethod.Put, uriOutletparams, retailTransaction);
                    if (ticket.result) {
                        if (vatConfigurationData.serviceChargeOnNet) {
                            priceModel.priceForServiceCharge = ticket.result.lineItems[0].netPrice;
                        }
                        if (vatConfigurationData.gratuityOnNet) {
                            priceModel.priceForGratuity = ticket.result.lineItems[0].netPrice;
                        }
                    }
                }
            }
        }
        return priceModel;
    }

    public async GetEmailConfigurationSetting() {
        this.notificationConfiguration = [];
        const apiResponse = await this.InvokeServiceCallAsync(
            "GetSettingByModule",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { module: "EmailConfiguration" }
        );
        this.notificationConfiguration = apiResponse.result;
    }


    async GetAllVipTypes() {
        this.allVipTypes = [];
        const apiResponse = await this.InvokeServiceCallAsync(
            'GetAllVipType',
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { isIncludeInactive : false}
        );
        this.allVipTypes = apiResponse.result;
      }
      async GetAllDayPackageById(date:any) {
        let formattedDate = this.localization.convertDateObjToAPIdate(date);
        this.dayPackageConfig = [];
        const apiResponse = await this.InvokeServiceCallAsync(
            'GetDayPackageByDate',
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { startDate : formattedDate}
        );
        this.dayPackageConfig = apiResponse.result;
      }
      async GetAllDayPackageUtilizedById(date:any) {
        let formattedDate = this.localization.convertDateObjToAPIdate(date);
        this.dayPackageUtilizedConfig = [];
        const apiResponse = await this.InvokeServiceCallAsync(
            'GetDayPackageUtilizedByDate',
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { startDate : formattedDate}
        );
        this.dayPackageUtilizedConfig = apiResponse.result;
      }

    async GetExtendedProfileSearchSetting(): Promise<any> {
        const result: BaseResponse<SystemConfiguration> = await this.httpService.CallApiAsync({
            host: Host.spaManagement,
            callDesc: "GetSettingsByModule",
            method: HttpMethod.Put,
            body: { Switch: ['EXTENTED_PROFILE_SEARCH'] },
            uriParams: { module: 'Appointment' }
        });
        this.ExtendedSearchConfiguration = result.result;
    }

    public async CheckEmailSMSConfigured(isUpdate: boolean): Promise<string> {
        let isEmailNotAvailable = false;
        let isPhoneNotAvailable = false;
        let isTriggerPopup = false;
        let clientsNameForEmail = '';
        let clientsNameForPhoneNo = '';
        let emailConfirmationMessage = '';

        let EmailSMSTriggerPopupMessage: EventNotificationGroup[] = await this.notificationconfigService.GetEventNotificationGroupByEventId(isUpdate ? EventNotification.AppointmentUpdate : EventNotification.AppointmentBooking);

        let isPopUpRequired = false;
        if (EmailSMSTriggerPopupMessage.length > 0) {
            isPopUpRequired = true;
        }

        let clientsWithoutPhoneNo: any = this.recordsArray.filter(x => x.phoneNumbers.length === 0);
        const clientsWithoutEmail: any = this.recordsArray.filter(x => x.emails.length === 0);

        const clientsWithoutPhoneNoBooleanExp: boolean = clientsWithoutPhoneNo != null && clientsWithoutPhoneNo.length > 0;
        const clientsWithoutEmailBooleanExp: boolean = clientsWithoutEmail != null && clientsWithoutEmail.length > 0;

        if (isUpdate) {
            isEmailNotAvailable = (isPopUpRequired && clientsWithoutEmailBooleanExp);
            isPhoneNotAvailable = (isPopUpRequired && clientsWithoutPhoneNoBooleanExp);
        } else {
            isEmailNotAvailable = (isPopUpRequired && clientsWithoutEmailBooleanExp);
            isPhoneNotAvailable = (isPopUpRequired && clientsWithoutPhoneNoBooleanExp);
        }

        if (isEmailNotAvailable) {
            clientsNameForEmail = clientsWithoutEmail.map(x => x.clientDetail.firstName.concat(' ', x.clientDetail.lastName)).toString();
            isTriggerPopup = true;
        }

        if (isPhoneNotAvailable) {
            clientsWithoutPhoneNo = clientsNameForEmail ? clientsWithoutPhoneNo.filter(x => !clientsWithoutEmail.map(y => y.clientDetail.id).includes(x.clientDetail.id)) : clientsWithoutPhoneNo;
            clientsNameForPhoneNo = clientsWithoutPhoneNo ? clientsWithoutPhoneNo.map(x => x.clientDetail.firstName.concat(' ', x.clientDetail.lastName)).toString() : '';
            isTriggerPopup = true;
        }

        if (isTriggerPopup) {
            emailConfirmationMessage = this.captions.emailConfirmation;
            const clientNameFalse = clientsNameForEmail ? clientsNameForEmail : clientsNameForPhoneNo;
            const clients = clientsNameForEmail && clientsNameForPhoneNo ? clientsNameForEmail.concat(',', clientsNameForPhoneNo) : clientNameFalse;
            emailConfirmationMessage = emailConfirmationMessage.interpolate({ Clients: clients });
        }

        return emailConfirmationMessage;
    }

    async searchClientByPatron(patronId: string, callBack: (result: any, extraParams?) => void) {
        let client = await this.InvokeServiceCallAsync('GetClientByPatronId', GlobalConst.Host.spaManagement, HttpMethod.Get, { patronId: patronId });
        if (client.result) {
            this.utils.ShowErrorMessage(this.allCaptions.common.Information, this.captions.EnteredPatronIDIsAlreadyAvailable, ButtonType.YesNo, this.patronAlreadyExistCallBack.bind(this), [client.result, callBack, patronId])
        }
        else {
            this._ams.loaderEnable.next(this.allCaptions.common.LoadingPlayerInformation);
            let playerInfo = await this._playerService.GetPlayerInformation(patronId);
            this._ams.loaderEnable.next('');
            if (playerInfo && playerInfo.personalDetails) {
                if (this._retailFeature.UpdateGuestInfoAsPerCMS || this.popupTitle == this.captions.NewClient) {
                    callBack(PatronInfoSearchResultType.UPDATECMSDATAONEXISTING, [playerInfo.personalDetails])
                }
                else {
                    this.personalDetailsFormGroup.controls.rank.setValue(playerInfo.personalDetails.playerRank);
                    callBack(PatronInfoSearchResultType.PATRONFOUND);
                }
            }
            else {
                this.utils.ShowErrorPopup([14110]);
                callBack(PatronInfoSearchResultType.PATRONNOTFOUND);
            }
        }
    }

    async patronAlreadyExistCallBack(result: any, extraParams?: any) {
        if (result === 'YES') {
            this.popupTitle = this.captions.EditClient;
            this.clientWidowActionType = "EDIT";
            this.saveText = this.captions.update;
            this.clientEditData = _.cloneDeep(extraParams[0]);
            this.clientId = extraParams[0].id;
            this.guestId = extraParams[0].clientDetail.guestId;
            this.imgService.GetImagesByReference(this.guestId, GlobalConst.ImgRefType.client, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [], true);
            extraParams[1](PatronInfoSearchResultType.EDITEXISTINGPATRON);
            this.convertToEditClient(extraParams[0]);

        } else {
            extraParams[1](PatronInfoSearchResultType.PATRONNOTFOUND);
        }
    }

    async UpdateCMSDetailOnExistingGuest(patronId, guestData, callBack?) {
        this._ams.loaderEnable.next(this.allCaptions.common.LoadingPlayerInformation);
        let playerInfo = await this._playerService.GetPlayerInformation(patronId);
        let cmsHasChange: boolean = false;
        this._ams.loaderEnable.next('');
        if (playerInfo && playerInfo.personalDetails) {
            cmsHasChange = this.isCMSDataChanged(guestData, playerInfo.personalDetails);
            if (this._retailFeature.UpdateGuestInfoAsPerCMS) {
                let playerDetail = playerInfo.personalDetails;
                guestData.clientDetail.firstName = playerDetail.firstName;
                guestData.clientDetail.lastName = playerDetail.lastName;
                guestData.clientDetail.pronounce = playerDetail.pronounced;
                guestData.clientDetail.loyaltyDetail[0].rank = playerDetail.playerRank;
                guestData.clientDetail.dateOfBirth = playerDetail.dateOfBirth;
                if (playerDetail.gender && playerDetail.gender != 'U') {
                    guestData.clientDetail.gender = playerDetail.gender == 'M' || playerDetail.gender == 'Male' ? 'Male' : 'Female';
                }
                if (playerDetail.address) {
                    guestData.addresses = [];
                    guestData.addresses.push({
                        line1: playerDetail.address.addressLine1,
                        city: playerDetail.address.city,
                        state: playerDetail.address.state,
                        zip: playerDetail.address.postalCode,
                        country: playerDetail.address.country
                    })
                }
                if (playerDetail.phone && playerDetail.phone.length > 0) {
                    guestData.phoneNumbers = [];
                    playerDetail.phone.forEach(element => {
                        guestData.phoneNumbers.push({
                            contactTypeId: element.phoneTypeId,
                            number: (element.extension ? element.extension + ':' : '') + (element.countryCode ? element.countryCode + "|" : '|') + element.phoneNumber,
                            extension: element.extension,
                            isPrimary: element.isPrimary
                        })
                    });
                }
                if (playerDetail.email && playerDetail.email.length > 0) {
                    guestData.emails = [];
                    playerDetail.email.forEach(element => {
                        guestData.emails.push({
                            contactTypeId: element.emailTypeId,
                            emailId: element.emailAddress
                        })
                    });
                }
            }
            else {
                guestData.clientDetail.loyaltyDetail[0].rank = playerInfo.personalDetails.playerRank;
            }
        }
        else {
            guestData.clientDetail.loyaltyDetail = [];
            this.utils.ShowErrorPopup([14110]);
        }
        return cmsHasChange;
    }

    isCMSDataChanged(existingData, cmsData): boolean {
        if (this._retailFeature.UpdateGuestInfoAsPerCMS) {
            if (existingData.clientDetail.firstName !== cmsData.firstName ||
                existingData.clientDetail.lastName !== cmsData.lastName ||
                existingData.clientDetail.pronounce !== cmsData.pronounced ||
                existingData.clientDetail.loyaltyDetail[0].rank !== cmsData.playerRank ||
                //this.utils.getDate(existingData.clientDetail.dateOfBirth) != this.utils.getDate(cmsData.dateOfBirth)
                this.utils.GetFormattedDate(existingData.clientDetail.dateOfBirth) != this.utils.GetFormattedDate(cmsData.dateOfBirth)
            ) {
                return true;
            }

            if (cmsData.gender && cmsData.gender !== 'U' && cmsData.gender !== String(existingData.clientDetail.gender).charAt(0)) {
                return true;
            }

            if (cmsData.address && (cmsData.address.addressLine1 != existingData.addresses[0].line1 ||
                cmsData.address.city != existingData.addresses[0].city ||
                cmsData.address.state != existingData.addresses[0].state ||
                cmsData.address.postalCode != existingData.addresses[0].zip ||
                cmsData.address.country != existingData.addresses[0].country)) {
                return true;
            }

            let guestPhone = _.orderBy(_.cloneDeep(existingData.phoneNumbers), 'number', 'asc');
            let formatedCMSPhone = cmsData.phone.map(x => {
                return {
                    phoneNumber: x.phoneNumber,
                    phoneTypeId: x.phoneTypeId,
                    isPrimary: x.isPrimary,
                    formattedPhone: (x.extension ? x.extension + ':' : '') + (x.countryCode ? x.countryCode + "|" : '|') + x.phoneNumber,
                }
            })
            formatedCMSPhone = _.orderBy(formatedCMSPhone, 'formattedPhone', 'asc')
            if (guestPhone && guestPhone.length == formatedCMSPhone.length) {
                for (let index = 0; index < guestPhone.length; index++) {
                    if (guestPhone[index].number != formatedCMSPhone[index].formattedPhone ||
                        guestPhone[index].contactTypeId != formatedCMSPhone[index].phoneTypeId ||
                        guestPhone[index].isPrimary != formatedCMSPhone[index].isPrimary) {
                        return true;
                    }
                }
            } else {
                return true;
            }

            let guestEmail = _.orderBy(_.cloneDeep(existingData.emails), 'emails', 'asc');
            let cmsEmail = _.orderBy(cmsData.email, 'emailAddress', 'asc');
            if (cmsEmail && cmsEmail.length == guestEmail.length) {
                for (let index = 0; index < cmsEmail.length; index++) {
                    if (cmsEmail[index].emailAddress != guestEmail[index].emailId ||
                        cmsEmail[index].emailTypeId != guestEmail[index].contactTypeId) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }
        else {
            return existingData.clientDetail.loyaltyDetail[0].rank == cmsData.playerRank ? false : true;
        }
        return false;
    }

    async getPMSGuestInfoForAppointment(guestIds: string): Promise<ClientInfo> {
        debugger;
        let clientInfo: ClientInfo;
        let response = await this.InvokeServiceCallAsync('GetClientsByGuestIds', Host.spaManagement, HttpMethod.Put, '', [guestIds]);
        if (response && response.result) {
            let clientData: ClientInfo[] = response.result;
            if (clientData && clientData.length > 0) {
                clientInfo = clientData[0];
                if (clientInfo.clientDetail.id == 0) {
                    clientInfo.clientDetail.clientType = 3;
                    clientInfo = await this.CreateClientForGuest(clientInfo);
                }
            }
        }
        return clientInfo;
    }
    setPMSGuestInfoForAppointment(clientInfo: ClientInfo) {
        if (!clientInfo) {
            return;
        }
        this.fromClientModule = true;
        this.labelRecords = [];

        let clientLabel: ClientLabel = {
            Id: clientInfo.clientDetail.id,
            FirstName: clientInfo.clientDetail.firstName.trim(),
            LastName: clientInfo.clientDetail.lastName.trim(),
            clientType: clientInfo.clientDetail.clientType
        };

        this.labelRecords.push(clientLabel);
        this.recordsArray = [clientInfo];
        this.selectedClientType = 3;
        this.isLoadedfromPMS = true;
        this.pmsActivityId = clientInfo.clientDetail.pmsActivityId;
    }

    getPMSDataForAction(action: PMSAction): PMSSessionData {
        let pmsData = this.utils.getPMSSessionData();
        return pmsData && pmsData.action == action ? pmsData : null;
    }

    async GetOccupiedLocationDetails(date: any, currentTempHoldIds: any[]) {
        try {
            if (this.resultExistingAppointment && this.resultExistingAppointment.appointmentDetail && this.resultExistingAppointment.appointmentDetail.id > 0) {
                currentTempHoldIds.push(this.resultExistingAppointment.appointmentDetail.id)
            }
            let formattedDate = this.localization.convertDateObjToAPIdate(date);
            let result = await this.httpService.CallApiAsync<any>({
                host: Host.schedule,
                callDesc: "getOccupiedLocation",
                method: HttpMethod.Put,
                uriParams: { filterDate: formattedDate },
                body: currentTempHoldIds.length > 0 ? currentTempHoldIds : [],
            });

            return result && result.result;
        } catch (e) {
            this.httpService.exceptionHandle(e);
        }
    }

    public fetchGuestSearchJson(): any {
        const guestSearchUrl = './assets/GuestSearch.json';
        return this.localization.jsonReader.getJSON(guestSearchUrl);
    }
    MapSearchData(clientSearchDetail: GuestSearch, clientCategory: ClientType, defaultExistingClientCategory : number = undefined) {
        var selectedCategory = clientSearchDetail.searchComponent.filter(a => a.clientCategory == clientCategory)[0];
        if (selectedCategory) {
            let searchfilters: any[] = [];
            selectedCategory.searchFilter.forEach(e => {
                if (e.id == defaultExistingClientCategory) {
                    e.checked = true;
                }
                else {
                    e.checked = false;
                }
                searchfilters.push({ id: e.id, name: this.localization.captions[e.placeHolder], checked: e.checked });
            });
            let result = searchfilters.filter(s => s.checked);
            if (result.length == 0) {
                searchfilters[0].checked = true;
            }
            return searchfilters;
        }
    }

    public getMemberConfiguration(): any {
        let memberConfiguration = this.retailPropConfig.getMemberConfig;
        if(memberConfiguration && memberConfiguration.corpIdNameMap && memberConfiguration.corpIdNameMap != "")
            return JSON.parse(memberConfiguration.corpIdNameMap);
        return {};
    }

    public formAppointmentBillingDetail(guaranteeMethodData: GuaranteeMethodJSONModel, appointmentId: number, _guaranteeBusiness: GuaranteeMethodBusiness, clientCardToken: number) {
        let appointmentBillingDetail = null;
        if (guaranteeMethodData?.guaranteePaymentMethod?.GuaranteeMethod != null) {
            if (!guaranteeMethodData.isCopiedFromClient) {
                appointmentBillingDetail = this.formAppointmentBillingDetailModel(guaranteeMethodData.id, appointmentId, guaranteeMethodData, false, _guaranteeBusiness);
            }
            else {
                appointmentBillingDetail = this.generateAppointmentBillingDetailsFromClientCard(clientCardToken, guaranteeMethodData.id, appointmentId, _guaranteeBusiness);
            }
        }
        else {
            appointmentBillingDetail = this.generateAppointmentBillingDetailsFromClientCard(clientCardToken, 0, appointmentId, _guaranteeBusiness);
        }
        return appointmentBillingDetail;
    }

    private generateAppointmentBillingDetailsFromClientCard(clientCardToken, guaranteeMethodId, appointmentId, _guaranteeBusiness) {
        let guaranteeMethodData = this.formGuaranteeMethodDataFromClientCard(clientCardToken);

        if (guaranteeMethodData == null)
            return null;

        return this.formAppointmentBillingDetailModel(guaranteeMethodId, appointmentId, guaranteeMethodData, true, _guaranteeBusiness);
    }

    private formAppointmentBillingDetailModel(guaranteeMethodId, appointmentId, guaranteeMethodData, isCopiedFromClient, _guaranteeBusiness: GuaranteeMethodBusiness): AppointmentBillingDetail {
        return {
            id: guaranteeMethodId,
            appointmentId: appointmentId,
            billingDetail: _guaranteeBusiness.GetAPIStringJSON(guaranteeMethodData),
            isCopiedFromClient: isCopiedFromClient
        };
    }
    // Used to update client card on file as guarantee method - DepositPolicy
    private formGuaranteeMethodDataFromClientCard(tokenTransId: number): GuaranteeMethodJSONModel {

        if (tokenTransId == 0)
            return null;

        return {
            id: 0,
            isFormDirty: true,
            isFormValid: true,
            isCopiedFromClient: true,
            guaranteePaymentMethod: this.formGuaranteeMethodDetail(tokenTransId),
            updateCardDetailsToClientProfile: false
        };
    }

    private formGuaranteeMethodDetail(tokenTransId: number): GuaranteeMethodDetail {
        return {
            GuaranteeMethod: GuaranteeMethod.Creditcard,
            ARAccountDetail: null,
            RoomDetail: null,
            CardDetail: this.formCardDetail(tokenTransId)
        };
    }

    private formCardDetail(tokenTransId: number): CardDetail {
        return {
            PaymentReferenceId: tokenTransId
        };
    }
}

function calcAge(dateString) {
    if (dateString != null) {
        var birthday = +this.utils.getDate(dateString);
        return ~~((Date.now() - birthday) / (31557600000));
    } else {
        return 0;
    }
}

function stringFormat(input: string, appendBy?: string) {
    if (appendBy) {
        const tempInput = (input == "" ? '' : input + appendBy);
        return input ? tempInput : ''
    }
    else {
        const tempInputFalse = (input == "" ? '' : input);
        return input ? tempInputFalse : ''
    }

}

