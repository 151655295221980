import { Component,HostListener, OnInit } from '@angular/core';
import { SpaLocalization } from '../core/localization/spa-localization';
import { AppModuleService } from '../app.service';
import { BaseResponse } from '../shared/business/shared.modals';
import { ButtonType, Host, Product } from '../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../shared/service/http-call.service';
import { SpaPropertyInformation } from '../core/services/spa-property-information.service';
import { SystemConfiguration } from '../shared/business/view-settings.modals';
import { RouteLoaderService } from '../core/services/route-loader.service';
import { menuTypes } from '../shared/shared.modal';
import { ManageSessionService } from '../login/manage-session.service';
import { Router } from '@angular/router';
import { SignalrService } from 'src/app/common/communication/signalR/signalr.service';
import { NotificationModel, SignalRMessage, SignalRNotificationType, SystemDateChangeMessage } from '../common/communication/signalR/signalR.model';
import { SnackBarType } from '../common/shared/shared/enums/enums';
  import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { AlertType } from '../ag-common/enums';
import { PropertySettingDataService } from '../common/dataservices/authentication/propertysetting.data.service';
import { RetailFeatureFlagInformationService } from '../retail/shared/service/retail.feature.flag.information.service';
import { CommonUtilities } from '../common/shared/shared/utilities/common-utilities';
import { AlertNewPopupComponent } from 'src/app/common/components/alert-new-popup/alert-new-popup.component';
import { StaffReaderBoardBusiness } from 'src/app/common/staff-reader-board/staff-reader-board.business';
import { StaffReaderBoardDataService } from 'src/app/common/dataservices/staff-reader-board-data.service';
import { UserRoleDataService } from 'src/app/common/dataservices/user-roles-data.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { VCartMessageHandler } from 'src/app/common/components/menu/vcart/vcart.MessageHandler';
import { CartUtilities } from '../common/components/menu/vcart/cart.utilities';
import { IcartDataService } from '../common/dataservices/icart.data.service';
import moment from 'moment';
import { HttpCacheService } from '../common/services/cache/http-cache.service';
import { NotificationFailureType } from '../shared/menu/menu.model';
import { CartWindowMessageType } from '../common/components/menu/vcart/vcart.modal';
import { PropertyService } from '../common/services/property.service';
import { Localization } from '../common/localization/localization';
import { IdleTimeoutService } from '../shared/service/session-expired.service';
import * as FullStory from '@fullstory/browser';
import { FULL_STORY_ORG_ID } from 'src/app/app-constants';
import { JasperServerCommonDataService } from '../common/dataservices/jasperServerCommon.data.service';
import { KeyboardMenuNavigationService } from '../common/services/keyboard-menu-navigation.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [StaffReaderBoardBusiness, StaffReaderBoardDataService, UserRoleDataService]
})
export class LayoutComponent implements OnInit {

  captions: any;
  menuList: any;
  menuType = menuTypes;
  propertyName: any;
  propertyDateTime: any;
  logOutClicked = false;
  getThemeColor: string = '';
  requestUri:string;
  entityName:string;
  private _autoLogOff: any = false;
  private _logOffAfter: number = 1;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private routeDataService: RouteLoaderService,
    private sessionManageService: ManageSessionService,
     public localisation: SpaLocalization, public _ams: AppModuleService,private router: Router, private propertyInfo: SpaPropertyInformation, private http: HttpServiceCall,
     private _routeDataService: RouteLoaderService, private signalR: SignalrService,
     private utils: SpaUtilities, public localization: SpaLocalization,
     private PropertySettingService: PropertySettingDataService,
     private retailFeatureInformationService: RetailFeatureFlagInformationService,
     private sessionService: ManageSessionService, public dialog: MatDialog,
     private staffReaderBoardBusiness: StaffReaderBoardBusiness,
     private snackBar: MatSnackBar,
     private cartUtils: CartUtilities,
    private vcartMessageHandler: VCartMessageHandler, private cartDataService: IcartDataService,
    private httpCacheService: HttpCacheService,
    private propertyService: PropertyService,
    private commonLocalization: Localization,public _ids: IdleTimeoutService,
    private jasperServerCommonDataService:JasperServerCommonDataService,
    private keyBoardService: KeyboardMenuNavigationService) {
    this._routeDataService.loadSettings().then(result => {
      if (result) {
        const value = this.routeDataService.GetChildMenu('/');
        this.menuList = {
          menuType: menuTypes.primary,
          menu: value
        };
      }
    });
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      sessionStorage.setItem('isChangesMade', 'false');
    });
    this.notificationTrigger();
  }

  ngOnInit() {
    window.onbeforeunload = (ev) => {
      if(sessionStorage.getItem('isChangesMade') == 'true'){
        let dialogText = this.localization.captions.common.RefreshMessage;
        ev.returnValue = dialogText;
        return dialogText;
      } else {
        return null;
      }
    };
    this.captions = this.localisation.captions;
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    this.cartDataService.CartItemEdited.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      if (x?.cartDetail?.cartPayload?.uiObject) {
        if (x.cartDetail.productId == Product.SPA) {            
            this.router.navigate(['/appointment/newappointment']);
        }
      }
    });
    this.jasperServerCommonDataService.setauthTokenProvider()
    if (this.cartUtils.isEmbed()) {
      this.cartUtils.setEmbedScreenUIClasses();
    }
    let enableUICache = propConfig?.UICacheEnabled;     
    this.GetAppointmentConfiguration();
    this.propertyName = this.localisation.GetPropertyInfo('PropertyName');
    this.sessionService.changeTitle();
    this.triggerNotification();
    this.time();
    this.setAutoLogoff();
    this.toggleStyle();
    if(!FullStory){
      this.setFullStory();
    }
    
    this.staffReaderBoardBusiness.GetRecentNotesCount(true).then(x=> {
      if(x >0)
        this.openNotificationAlert()
    });
    this.StartSignalrConnection();   
    if (enableUICache && enableUICache.toLowerCase() == "true"){
     sessionStorage.setItem("setUICache", "true");
      this.setUICache();
    }
    setTimeout(() => {
      this.checkPropertyDate();
    }, 5000);
    
    
  }
  async setUICache() {
    await this.propertyService.readUICacheJsonData().then((result) => {
      this.commonLocalization.uiCacheData = result;
    });   
  }

  @HostListener('window:message', ['$event'])
  onMessage({ origin, data }) {
    if (data) {
      try {
        var messageData = JSON.parse(data);
        if (messageData.channel == 'VCART') {
          this.vcartMessageHandler.processWindowMessage(messageData);
        }
        else {
          // other window messages can be handled here}      
        }

      }
      catch (e) {

      }
    }
  }

  private StartSignalrConnection() {
    this.signalR.startConnection();
    this.signalR.startedConnection.then(res => {
      this.addPropertyListener();
      this.addCacheListener();
      this.addTenantUserListener();
    });
  }

  private addPropertyListener() {
    this.signalR.addPropertyListener(this, this.signalRPropertyListener)
      .catch((err) => console.log('Failure error ' + err));

    this.signalR.hubConnection.onreconnected((reconnect)=>{
      const list=this.signalR.GetSignalREvents();
      list.forEach(e=>{this.signalR.subscribeToEvent(e);});
      });
  }

  private addCacheListener(){
    this.signalR.addCacheListener(this, this.signalRCacheListener)
    .catch((err) => console.log('Failure error ' + err));

    this.signalR.hubConnection.onreconnected((reconnect)=>{
      const list=this.signalR.GetSignalREvents();
      list.forEach(e=>{this.signalR.subscribeToEvent(e);});
      });

  }

  setFullStory(){
    let propertyConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    let userInfo = JSON.parse(sessionStorage.getItem('userInformation'));
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[FULL_STORY_ORG_ID] != undefined) {
      FullStory.init({ orgId: propertyConfig.configValue[FULL_STORY_ORG_ID] });
      FullStory.identify('SPA-' + userInfo.userName, {
        "displayName": 'SPA-' + userInfo.userName,
        "productId": Product.SPA.toString(),
        "productName": "SPA",
        "tenantId": userInfo.tenantId?.toString() ?? "",
        "tenantCode": userInfo.tenantCode?.toString() ?? "",
        "propertyId": propertyConfig.propertyId?.toString() ?? "",
        "propertyName": this.propertyInfo.GetPropertyInfoByKey('PropertyName')
      });
    }
  }
  
  async signalRPropertyListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if(message && message.content && message.content.notificationType==SignalRNotificationType.ToasterNotification)
    {
      const content =JSON.parse(message.content.notificationObjectString);
      if (message.name == "Change System Date") {
        await this.UpdatePropertyDateCache(content);
      }
      else
      {
        this.utils.showToastMessage(content.message, SnackBarType.Success);
      }
    }
    else if(message && message.content && message.content.notificationType==SignalRNotificationType.NotificationIcon){
    
      if(message.content.title == "Note Sent")
      {
        this.utils.showToastMessage(this.captions.lbl_received_new_note, SnackBarType.Success);
      }
      else
      {
      //push to storage
       this.pushToNotificationStorage(message.content);
      }
    }

  }
  @HostListener("document:keydown", ["$event"]) onKeydownControlHandler(
    event: KeyboardEvent
  ) {
    // Call the shortcut decider to check for the combination
    this.keyBoardService.shortCutKeyMaker(event);
  }
  async signalRCacheListener(message: SignalRMessage<NotificationModel>):Promise<void>{
    if (message.name == "Clear Cache") {
      if (message.content.notificationObjectString != null) {
        this.entityName = message.content.notificationObjectString;
          if (this.entityName != null) {
            this.httpCacheService.cacheDelete(this.entityName);
          }
        return null;
      }
    }
  }

  pushToNotificationStorage(notification:NotificationModel){
    this.SetNotificationDetails(notification);
  }
  
  public SetNotificationDetails(notification: NotificationModel)
  {
    let PropertyNotification =this.GetNotificationDetails();
    if(PropertyNotification){
      PropertyNotification.push(notification);
    }
    else
    {
      PropertyNotification=[notification];
    }
    sessionStorage.setItem('PropertyNotification', JSON.stringify(PropertyNotification));
  }

  public GetNotificationDetails() : NotificationModel[]{
    const PropertyNotification =JSON.parse(sessionStorage.getItem('PropertyNotification'));
    return PropertyNotification;
  }

  async UpdatePropertyDateCache(message: SystemDateChangeMessage): Promise<void> {    
      let newSystemDate = await this.GetPropertyDate();
      let localizedDate = this.localization.localizeDisplayDate(newSystemDate); 
      if (newSystemDate != undefined && newSystemDate != null) {
        this.utils.showAlert(message.message + " to " + localizedDate,AlertType.Success,ButtonType.Ok,(res) => {
            if (res) {
              this.logoutHandler(true);
            }
          }
        );
      }   
  }

  async GetPropertyDate() {
    const propertityConfig = await this.PropertySettingService.getAllPropertySetting(Number(this.getPropertyId()));
    return this.localization.getDate(propertityConfig.propertyDate);
  }
  private getPropertyId() {
    return this.localization.GetsessionStorageValue('propertyInfo', 'PropertyId');
  }

 async checkPropertyDate(){
    const propertityConfig = await this.PropertySettingService.getAllPropertySetting(Number(this.getPropertyId()));
    const newDate=this.localization.getDate(propertityConfig.propertyDate);
    const localizeNewDate= new Date(this.localisation.getformattedDateMMDDYYYY(newDate));
    const localizeCurrentDate = new Date(this.localisation.getformattedDateMMDDYYYY(this.propertyInfo.CurrentDate));
    if(localizeNewDate>localizeCurrentDate)
    {
      this.utils.showAlert(this.captions.lbl_systemDateChange+this.localization.localizeDisplayDate(localizeNewDate),AlertType.Success,ButtonType.Ok,(res) => {
        if (res) {
          this.logoutHandler(true);
        }
      });
      }    
  }

  logoutHandler(arg) {
    this.logOutClicked = true;
    this.retailFeatureInformationService.reset();
    this.sessionService.logout();
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.removeAttribute("id");
  }

  triggerNotification(){
    if (!this.propertyInfo.UseRetailInterface) {
      this.sessionManageService.startTimerForNotification(1);
    }
    this.sessionManageService.startTimerForAppointmentRelease();
  }

  async GetAppointmentConfiguration() {
    let apiResponse: BaseResponse<SystemConfiguration[]> = await this.InvokeServiceCallAsync('GetSettingByModule', Host.spaManagement, HttpMethod.Get, { module: 'Appointment' });
    if (apiResponse && apiResponse.result) {
      this.getThemeColor = apiResponse.result.find(x => x.switch == 'THEME_COLOR').value.toString();
      let _appJSON: any = {};
      apiResponse.result.forEach(sc => {
        _appJSON[sc.switch] = sc.value
      });
      this.propertyInfo.SetAppointmentConfigurations(_appJSON);
    }
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  time() {
    if (!this.logOutClicked) {
      if(this.localisation.GetPropertyInfo('TimeZone')){
        this.propertyDateTime = this.localisation.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(this.propertyInfo.CurrentDate) ;
      }      
    }
  }

  setAutoLogoff() {
    this._autoLogOff = this.utils.GetPropertyInfo('AutoLogOff');
    const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
    if (this._autoLogOff == "true") {
      this._ids.resetOnTrigger = true;
      this._logOffAfter = +this.utils.GetPropertyInfo('LogOffAfter');
      this._ids.startTimer(this._logOffAfter, tokenDuration);
    } else {
      this._ids.resetOnTrigger = false;
    }
  }

  ngAfterViewInit() {
    if (!this.logOutClicked) {
      setInterval(() => this.time(), 500);
    }
    try{
    this.cartUtils.pushMessageToParentWindow(CartWindowMessageType.IFrameLoaded);
    }
    catch(e){
      console.log(e);
    }
  }

  toggleStyle() {
    this.localisation.isNewStyle = true;
    this.setView();
  }

  setView() {
    const bodyTag = document.getElementsByTagName('body')[0];
    if (this.localisation.isNewStyle) {
      bodyTag.classList.add('new-mat-view');
      this.localisation.setFloatLabel = 'always';
      this.localisation.setFloatLabelNever = 'never';
    } else {
      bodyTag.classList.remove('new-mat-view');
      this.localisation.setFloatLabel = 'never';
    }
  }
  
  openNotificationAlert(){
   
    const dialogRef = this.dialog.open(AlertNewPopupComponent, {
      width: '50%',
      height: '50%',
      maxWidth: '50%',
      data:{
        from:'login'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      
    });
  }

  notificationTrigger(){
    this.localization.notification$.pipe(takeUntil(this.destroyed$)).subscribe(x =>{
        if(x){
          const dialogRef = this.dialog.open(AlertNewPopupComponent, {
            minWidth: '30%',
            height: '40%',
            maxWidth: '40%',
            hasBackdrop : true,
            data:{
              from:'notify'
            },
          panelClass:'alert-notify-class',
        });
        dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
          
        });
        } 
        })
  }
  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  private addTenantUserListener(){
    this.signalR.addUserListener(this, this.signalRTenantUserListener)
      .catch((err) => console.log('Failure error ' + err));
 
      this.signalR.hubConnection.onreconnected((reconnect)=>{
        const list=this.signalR.GetSignalREvents();
        list.forEach(e=>{this.signalR.subscribeToEvent(e);});
        });
  }
 
  async signalRTenantUserListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if(message && message.content && message.content.notificationType==SignalRNotificationType.NotificationIcon) {
      const content =JSON.parse(message.content.notificationObjectString);
      if(message.name == "PlatformSyncFailed"){
        this.utils.showToastMessage(content.message,SnackBarType.Success);
      }
      if(message.name == "PlatfromGuestSyncSuccess"){
        this.utils.showToastMessage(content.message,SnackBarType.Success);
      }
      this.pushToNotificationStorage(message.content);
      this.sessionManageService.transactionCount.next([{ id : NotificationFailureType.cgpsLog, count : 0, message: content.message }]);
    }
  }
}