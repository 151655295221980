import { Injectable } from "@angular/core";
import { ActionTypeEnum, aligment } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { TaxTypeDataService } from 'src/app/retail/shared/service/taxtype.data.service';
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { Category } from 'src/app/retail/retail-code-setup/retail-taxes/tax-type/revenue-types.model';
import { API as TaxTypeAPI, TaxBased, TaxMaintenance, UI as TaxTypeUI, TaxTypeOptions, TaxMaintenanceByCriteria, AgeCategoryOptions } from 'src/app/retail/retail-code-setup/retail-taxes/tax-type/tax-type.model';
import { TaxExemptCategoryDataService } from "../../../tax-exempt-category/tax-exempt-category.data.service";



@Injectable()
export class CreateNewTaxTypeBusiness {

  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private _localization: RetailLocalization, private localization: RetailLocalization, private _taxTypeDataService: TaxTypeDataService
   ,public propertyInfo: RetailPropertyInformation,private utils: RetailUtilities,private taxExemptCategoryDataService: TaxExemptCategoryDataService) {
    this.captions = this._localization.captions;
  }

  async getPostTypes(): Promise<DropdownOptions[]> {
    let postTypes = await this._taxTypeDataService.getAllPostTypes(false);
    postTypes = postTypes.filter(x => x.postTypeCatergoryId == Category.nonRevenueDebits);
    return postTypes.map(x => ({ id: x.id, value: x.postTypeNumber, viewValue: x.postTypeName, checked: false }));
  }

  public async getTaxType(includeInactive: boolean): Promise<TaxTypeUI.TaxType[]> {
    const apiModels: TaxTypeAPI.Tax[] = await this._taxTypeDataService.getAllTaxTypes(includeInactive);
    return apiModels.map(x => this.uiMapper(x));
  }

  public async create(taxType): Promise<TaxTypeUI.TaxType[]> {
    const apiModels: TaxTypeAPI.Tax[] = await this._taxTypeDataService.createTaxType(this.APIMapperCreate(taxType));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async update(taxType): Promise<TaxTypeUI.TaxType[]> {
    const apiModels: TaxTypeAPI.Tax[] = await this._taxTypeDataService.updateTaxTypes(this.APIMapperCreate(taxType));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async getTaxTypeById(id: number): Promise<TaxTypeAPI.Tax> {
    try {
      return await this._taxTypeDataService.getTaxById(id);
    } catch (e) {
      console.error(e);
      throw e;
    }

  }

  public async delete(id: number): Promise<void> {
    try {
      return await this._taxTypeDataService.deleteTaxType(id);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async GetActiveTaxExemptCategories() {
    var data = await this.taxExemptCategoryDataService.getAllTaxExemptCategories(false);
    return data?.map(c => {
      return {
        id: c.id,
        viewValue: c.name,
        value: c
      }
    });
  }

      
  async getAllSpecializedTaxTypes(includeInactive: boolean): Promise<TaxTypeUI.TaxType[]> {
    const specializedTaxes = await this._taxTypeDataService.getAllSpecializedTaxTypes(includeInactive);
    return specializedTaxes.map(x => this.uiMapper(x));
  }

  async ValidateIfSpecializedTaxAssociated(taxId : number): Promise<boolean> {
    return await this._taxTypeDataService.ValidateIfSpecializedTaxAssociated(taxId);
  }

  private uiMapper(TaxTypeAPIModel: TaxTypeAPI.Tax): TaxTypeUI.TaxType {
    return {
      id: TaxTypeAPIModel.id,
      taxType: TaxTypeAPIModel.taxType,
      postTypeNumber: TaxTypeAPIModel.postTypeId,
      postTypeId: TaxTypeAPIModel.postTypeId,
      listOrder: TaxTypeAPIModel.listOrder,
      isActive: { value: TaxTypeAPIModel.isActive, isDisabled: false },
      exemptDays: TaxTypeAPIModel.exemptDays,
      effectiveDate: TaxTypeAPIModel.effectiveDate,
      isTaxable: TaxTypeAPIModel.isTaxable,
      isPostWithRoom: TaxTypeAPIModel.isPostWithRoom,
      isSpecializedUse: TaxTypeAPIModel.isSpecializedUse,
      isShowOnSplits: TaxTypeAPIModel.isShowOnSplits,
      isGSTTax: TaxTypeAPIModel.isGSTTax,
      isOccupancyTax: TaxTypeAPIModel.isOccupancyTax,
      isOCCTaxNoOfRooms: TaxTypeAPIModel.isOCCTaxNoOfRooms,
      isPostTaxToFirstFolio: TaxTypeAPIModel.isPostTaxToFirstFolio,
      pSTCodeId: TaxTypeAPIModel.pstCodeId,
      taxExemptCategory: TaxTypeAPIModel.taxExemptCategoryId,
      extractCode: TaxTypeAPIModel.extractCode,      
      taxMaintenances: [],
      excludeFlatTaxOnZeroCharge: TaxTypeAPIModel.excludeFlatTaxOnZeroCharge,
      isInclusive : TaxTypeAPIModel.isInclusive,
      maxTaxAmount : TaxTypeAPIModel.maxTaxAmount,
      minTaxAmount : TaxTypeAPIModel.minTaxAmount,
      minThresholdAmount : TaxTypeAPIModel.minThresholdAmount,
      specializedTaxId : TaxTypeAPIModel.specializedTaxId
    } as TaxTypeUI.TaxType;
  }

  public getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'postTypeNumber',
        displayNameId: 'tbl_hdr_postTypeNumber',
        displayName: this.captions.tbl_hdr_postTypeNumber,
        searchable: true,
        sorting: true,
        sortingKey: 'postTypeNumber'
      },
      {
        key: 'taxType',
        displayNameId: 'tbl_hdr_taxType',
        displayName: this.captions.tbl_hdr_taxType,
        searchable: true,
        sorting: true,
        sortingKey: 'taxType'
      },
      {
        key: 'listOrder',
        displayNameId: 'tbl_hdr_listOrder',
        displayName: this.captions.tbl_hdr_listOrder,
        sorting: true,
        sortingKey: 'listOrder',
        alignment: aligment.right
      },
      {
        key: 'isActive',
        displayNameId: 'tbl_hdr_active',
        displayName: this.captions.tbl_hdr_active,
        sortingKey: 'isActive',
        templateName: ActionTypeEnum.toggle
      },
      {
        key: ActionTypeEnum.action,
        displayNameId: 'tbl_hdr_actions',
        displayName: this.captions.tbl_hdr_actions,
        searchable: false,
        sorting: false,
        templateName: ActionTypeEnum.action
      },
    ];
  }

  private APIMapperCreate(taxTypeInput): TaxTypeAPI.Tax {
    return {
      id: taxTypeInput.taxDetails.id,
      taxType: taxTypeInput.taxDetails.taxType,
      postTypeId: taxTypeInput.versaDetails.postTypeId,
      exemptDays: 0,
      //effectiveDate: this.localization.convertDateObjToAPIdate(taxTypeInput.formValues.effectiveDays),
      isTaxable: taxTypeInput.taxDetails.chargeTax,
      isPostWithRoom: false,
      isSpecializedUse: taxTypeInput.taxDetails.isSpecialisedUse,
      isShowOnSplits: false,
      isGSTTax: false,
      isOccupancyTax: false,
      isOCCTaxNoOfRooms: false,
      isPostTaxToFirstFolio: false,
      pstCodeId: 0,
      taxExemptCategoryId: taxTypeInput.taxDetails.taxExemptCategories ? taxTypeInput.taxDetails.taxExemptCategories : 0,
      extractCode: '',
      listOrder: taxTypeInput.taxDetails.listOrder ? taxTypeInput.taxDetails.listOrder : 0,
      isActive: taxTypeInput.taxDetails.active,
      outletId: this.propertyInfo.GetDefaultOutlet(),
      taxMaintenances: this.APITaxMaintenanceMapper(taxTypeInput.rangeMaintenance, taxTypeInput.taxMaintenanceByCriteria),
      excludeFlatTaxOnZeroCharge: taxTypeInput.taxDetails.excludeFlatTaxOnZeroRate,
      isInclusive : taxTypeInput.taxDetails.taxInclusive,
      maxTaxAmount : taxTypeInput.taxDetails.maximumTaxAmt,
      minTaxAmount : taxTypeInput.taxDetails.minimumTaxAmt,
      minThresholdAmount : taxTypeInput.taxDetails.thresholdAmt,
      specializedTaxId : taxTypeInput.taxDetails.specializedTaxTypeId
    } as TaxTypeAPI.Tax;
  }

  private APITaxMaintenanceMapper(taxMaintenace,taxMaintenanceByCriteria): TaxMaintenance[] {
    let range: TaxMaintenance[] = [];
    if (taxMaintenace && taxMaintenace.length > 0) {
      taxMaintenace.forEach(x => {
        range.push({
          id: 0,
          taxId: 0,
          fromDate: this.localization.convertDateObjToAPIdate(x.effectiveFrom),
          toDate: this.localization.convertDateObjToAPIdate(x.effectiveUntil),
          isTaxAmount: x.taxBasedOn == TaxBased.TaxPercent ? false : true,
          taxAmount: x.taxBasedOn == TaxBased.TaxPercent ? this.delocalizePercentage(x.value) : this.localization.currencyToSQLFormat(x.value),
          isNewTaxAmount: false,
          newTaxAmount: 0,
          newPostTypeId: 0,
          taxReference: '',
          taxDept: '',
          taxPType: '',
          taxRound: 0,
          gaffile: 0,
          isActive : x.active.value,
          taxMaintenanceByCriteria : Array.isArray(taxMaintenanceByCriteria)
          ? this.APITaxMaintenanceByCriteriaMapper(
              taxMaintenanceByCriteria.filter(y => y.taxMaintenanceId === x.id),
            )
          : []
        });
      });
    }
    return range;
  }

  private APITaxMaintenanceByCriteriaMapper(taxMaintenanceByCriteria): TaxMaintenanceByCriteria[] {
    let range: TaxMaintenanceByCriteria[] = [];
    if (taxMaintenanceByCriteria && taxMaintenanceByCriteria.length > 0) {
      taxMaintenanceByCriteria.forEach(x => {
        range.push({
          id : 0,
          taxMaintenanceId : x.taxMaintenanceId,
          isActive : true,
          taxAmount : x.taxBasedOn == TaxBased.TaxAmount ?  this.localization.currencyToSQLFormat(x.taxAmount) : this.delocalizePercentage(x.taxAmount),
          isTaxAmount :  x.taxBasedOn == TaxBased.TaxAmount ? true : false,
          listOrderLinkId : 0,
          referenceType : x.referenceType,
          referenceValue : x.referenceValue
        });
      });
    }
    return range;
  }

  getTaxExemptcategoriesOptions() {
    return [
      {
        id: 1,
        value: '1',
        viewValue: this.captions.lbl_state
      },
      {
        id: 2,
        value: '2',
        viewValue: this.captions.lbl_country
      },
      {
        id: 3,
        value: '3',
        viewValue: this.captions.lbl_occ
      },
      {
        id: 4,
        value: '4',
        viewValue: this.captions.lbl_other
      }
    ];
  }

  public getTaxBasedOptions() {
    let options = [
      {
        id: TaxBased.TaxPercent,
        value: this.captions.lbl_taxPercent
      },
      {
        id: TaxBased.TaxAmount,
        value: this.captions.lbl_flatAmount
      }
    ];
    return options
  }

  public getNewTaxBasedOptions() {
    let options = [
      {
        id: TaxBased.TaxPercent,
        value: this.captions.lbl_newTaxPercent
      },
      {
        id: TaxBased.TaxAmount,
        value: this.captions.lbl_flatAmount
      }
    ];
    
    if(this.propertyInfo?.IsVATEnabled){
      options.splice(1,1);
    }

    return options
  }

  public getTableOptions(isViewOnly): TableOptions {
    return {
      actions: [
        {
          type: TableActions.copy,
          disabled: false
        }, {
          type: TableActions.delete,
          disabled: false
        }
      ],
      ignoreSort: true,
      defaultsortingColoumnKey: '',
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: true,
        lastColumn: true
      },
      isDragDisabled: isViewOnly,
      showDeleteMsg: false
    };
  }

  delocalizePercentage(percentage: string): number {
    percentage = percentage ? percentage.toString() : '';
    if (percentage.trim() === '') {
      return 0;
    }
    const v = percentage.replace(this.localization.decimalSeparator, '.');
    return parseFloat(v);
  }

    getTaxDetailsHeaderOptions(): TableHeaderOptions[] {
        const header: any = [
            {
                key: 'effectiveFrom',
                displayName: this.captions.lbl_effective_from,
                templateName: ActionTypeEnum.custom,
            },
            {
                key: 'effectiveUntil',
                displayName: this.captions.lbl_effective_until,
                templateName: ActionTypeEnum.custom,
            },
            {
                key: 'taxBasedOn',
                displayName: this.captions.lbl_tax_based_on,
                templateName: ActionTypeEnum.custom,
            },
            {
                key: 'value',
                displayName: this.captions.lbl_value,
                templateName: ActionTypeEnum.custom,
            },
            {
                key: 'active',
                displayNameId: 'tbl_hdr_active',
                displayName: this.captions.tbl_hdr_active,
                templateName: ActionTypeEnum.toggle
            },

            {
                key: ActionTypeEnum.action,
                displayName: this.captions.tbl_hdr_actions,
                displayNameId: 'tbl_hdr_actions',
                templateName: ActionTypeEnum.action
            }
        ]
        return header;
    }

    getTaxDetailsTableOptions(): TableOptions {
        return {
            defaultSortOrder: SorTypeEnum.asc,
            defaultsortingColoumnKey: 'value',
            actions: [

                {
                    type: TableActions.delete,
                    disabled: false
                }
            ],
        };
    }
    getAgeCategoryOptions() {
      return [{
        id: AgeCategoryOptions.Youth, 
        value: AgeCategoryOptions[1]
      },{
        id: AgeCategoryOptions.Child,
        value: AgeCategoryOptions[2]
      },{
        id: AgeCategoryOptions.Kid,
        value: AgeCategoryOptions[3]
      },{
        id: AgeCategoryOptions.Infant,
        value: AgeCategoryOptions[4]
      },{
        id: AgeCategoryOptions.Adult,
        value: AgeCategoryOptions[5]
      },{
        id: AgeCategoryOptions.Senior,
        value: AgeCategoryOptions[6]
      }]
    }

    async getNewHeaderOptions(isPerson, disabled = false): Promise<TableHeaderOptions[]> {
        const header: any = [

            {
                key: 'age',
                displayName: this.captions.lbl_age_category,
                sorting: true,
                templateName: ActionTypeEnum.dropdown,
                isDisabled: disabled,
                inputs: {
                    selectOptions: await this.getAgeCategoryOptions()
                }
            },
            {
                key: 'effectiveFrom',
                displayName: this.captions.lbl_effective_from,
                templateName: ActionTypeEnum.custom,
                isDisabled: disabled
            },
            {
                key: 'effectiveUntil',
                displayName: this.captions.lbl_effective_until,
                templateName: ActionTypeEnum.custom,
                isDisabled: disabled
            },
            {
                key: 'taxBasedOn',
                displayName: this.captions.lbl_tax_based_on,
                templateName: ActionTypeEnum.custom,
                isDisabled: disabled
            },
            {
                key: 'value',
                displayName: this.captions.lbl_value,
                templateName: ActionTypeEnum.custom,
                isDisabled: disabled
            },

            {
                key: ActionTypeEnum.action,
                displayName: this.captions.tbl_hdr_actions,
                displayNameId: 'tbl_hdr_actions',
                templateName: ActionTypeEnum.action
            }

        ]
        if (isPerson) {
            header.splice(0, 1, {
                key: 'person',
                displayName: this.captions.lbl_person_count,
                templateName: ActionTypeEnum.numberInput,
                isDisabled: disabled,
                sorting: true
            })
        }
        return header;
    }

    getNewTableOptions(isPerson): TableOptions {
        return {
            defaultSortOrder: SorTypeEnum.asc,
            defaultsortingColoumnKey: !isPerson ? 'age' : 'person',
            actions: [
                {
                    type: TableActions.copy,
                    disabled: false
                },
                {
                    type: TableActions.delete,
                    disabled: false
                }
            ],

        };
    }
}
