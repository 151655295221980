import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { SaveButtonObj } from 'src/app/common/Models/ag-models';
import { CommonUtilities } from '../../utilities/common-utilities';
import { ActionMode, AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { CkeditorPopupComponent } from '../../ckeditor-popup/ckeditor-popup.component';
import * as DecoupledEditor from 'src/app/common/ckeditor5-build-decoupled-document-master/build/ckeditor';
import { EmailSenderType, HtmlStringConversion } from './custom-email-ckeditor.model';
import { emailCkEditorBusiness } from './custom-email-ckeditor.business';
import { EmailActionMode } from '../view-email-logs/view-email-logs.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-custom-email-ckeditor',
  templateUrl: './custom-email-ckeditor.component.html',
  styleUrls: ['./custom-email-ckeditor.component.scss'],
  providers:[emailCkEditorBusiness],
  encapsulation: ViewEncapsulation.None
})
export class CustomEmailCkeditorComponent implements OnInit {

   captions: any;
   buttonObj: SaveButtonObj;
   ckEditorForm: UntypedFormGroup;
   public Editor = DecoupledEditor;
   maxCharacters: number = 2000;
   wordPlugin: any;
   exceededCount = 0;
   maxLimitMsg: string;
   public Config = { toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize', 'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList', 'bulletedList', 'outdent', 'indent', 'link', 'imageUpload', 'undo', 'redo'] };
   $destroyed: ReplaySubject<any> = new ReplaySubject(1);
   modeldata: any;
   title: string;
   emailActionList: any = [];
  setFloatLabelNever: string;
  type: any;
  file: any;
  excelImport = import('xlsx');
  attachmentList: any = [];
  attachmentExpand: boolean = true;
  emailConfigurationList: any = [];
  isShowBcc: boolean = false;
  senderType = EmailSenderType;
  emailReferenceNumber: string = '';
  emailDetails: any;
  style: string = '';
   constructor(@Inject(MAT_DIALOG_DATA) data: any,
     private dialogRef: MatDialogRef<CustomEmailCkeditorComponent>,
     private localization: Localization, private fb: UntypedFormBuilder, private utils: CommonUtilities, public dialog: MatDialog,private business: emailCkEditorBusiness,private http: HttpClient) {
     this.captions = this.localization.captions;
     this.title = this.captions.lbl_composeEmail;
     this.setFloatLabelNever = this.localization.setFloatLabelNever;
     this.modeldata = data;
     this.type = ['jpg','jpeg','gif','png','pdf','xlsx','xls','docx','pptx','ppt'].toString();
     this.emailReferenceNumber = data?.emailReferenceNumber;
     this.emailDetails = data;
     this.initializeForm();
     this.readCssFile();
   }

   initializeForm(){
    this.ckEditorForm = this.fb.group({
      emailThreadValue: '',
      subject: this.emailReferenceNumber && this.emailReferenceNumber != '' ? '[ref:' + this.emailReferenceNumber + ']' :  '',
      senderContent:'',
      attachmentList:''
    });
   }


  readCssFile(){
    // this.http.get('assets/cke-content-styles.css', { responseType: 'text' }).subscribe(
    //   (data) => {
    //     this.style = `<style>${data}</style>`;
    //     console.log(this.style)
    //   },
    //   (error) => {
    //     console.error('Error loading style:', error);
    //   }
    // );
  }
 
  ngOnInit() {

    this.buttonObj = {
      customSaveText: this.captions.lbl_send,
      disabled: false,
      isEdit: false
    };
    this.initializeInputs();
    if (this.emailDetails?.isPatchEnabled) {
      this.patchEmailLogData(this.emailDetails)
    }
  }

   initializeInputs(){
    this.emailActionList = this.business.getEmailActionList();
    this.emailConfigurationList = [
      {
        defaultExpand: true,
        sectionLabel: this.captions.lbl_to,
        emailList: [],
        sectionId: EmailSenderType.to,
        isVisible: true,
      },
      {
        defaultExpand: true,
        sectionLabel: this.captions.lbl_cc,
        emailList: [],
        sectionId: EmailSenderType.cc,
        isVisible: true,
      },
      {
        defaultExpand: true,
        sectionLabel: this.captions.lbl_bcc,
        emailList: [],
        sectionId: EmailSenderType.bcc,
        isVisible: false
      }
    ]

    this.setEmailConfigruationValue();
   }

   setEmailConfigruationValue(){
    this.ckEditorForm.controls.senderContent.setValue(this.emailConfigurationList);
   }
 
   ngAfterViewInit() {}

   public onReady(editor) {
     this.wordPlugin = editor.plugins.has('WordCount') ? editor.plugins.get('WordCount') : null;
     editor.ui.getEditableElement().parentElement.insertBefore(
       editor.ui.view.toolbar.element,
       editor.ui.getEditableElement()
     );
   }
   ngOnDestroy(): void {
     this.$destroyed.next(true);
     this.$destroyed.complete();
   }
   close(e) {
     if (this.ckEditorForm.dirty && this.ckEditorForm.valid) {
       this.utils.showAlert(this.captions.lbl_leave_page, AlertType.Confirmation, ButtonType.YesNo, res => {
         if (res === AlertAction.YES) {
           this.dialogRef.close(e);
           this.ckEditorForm.reset();
         }
       });
     }
     else {
       this.dialogRef.close(e);
       this.ckEditorForm.reset();
     }
   }
 
   closeHandler(e) {
     this.close(e);
   }
 
   saveHandler(eve) {
    let formValue = this.ckEditorForm.value;
    if(formValue && formValue.senderContent && formValue.senderContent.filter(x => x.emailList.length > 0).length == 0){
      this.utils.showAlert(this.captions.warn_EmptyMail, AlertType.Warning, ButtonType.Ok);
      return;
    }
    const example = this.formatStringToHtml(this.ckEditorForm.value.emailThreadValue)
  //   const convertOutlookHtmlFormat = `<html><head>${this.style}</head><body><div class='ck-content'>
  //       ${example}       
  // </div></body></html>`
  this.ckEditorForm.controls.emailThreadValue.setValue(example)
    this.business.sendMail(this.ckEditorForm.value);
    this.dialogRef.close({
      action: ActionMode.update,
      value: this.utf8ToBase64(this.formatStringToHtml(this.ckEditorForm.value.emailThreadValue)),
    });
   }
 
   showHtmlContent() {
     const cleanData = this.utils.sanitizeHTMLTemplate( this.ckEditorForm.get('emailThreadValue')?.value);
     this.ckEditorForm.get('emailThreadValue')?.setValue(cleanData);
     const dialogRef = this.dialog.open(CkeditorPopupComponent, {
       height: '90%',
       width: '90%',
       data: { headername: this.captions.lbl_composed, datarecord: this.ckEditorForm.get('emailThreadValue')?.value, Type: "HTML" },
       panelClass: 'ag_dialog--lg',
       disableClose: true,
       hasBackdrop: true
     });
   }


   public formatHtmlToString(value: string) {
    const formattedValue = this.replaceHtmlWithTags(value);
    return formattedValue;
  }

   public replaceHtmlWithTags(value: string) {
          let formattedValue = value.replace(HtmlStringConversion.pageBreakBeforeHtmlRegExp, HtmlStringConversion.pageBreakBeforeTag);
          formattedValue = formattedValue.replace(HtmlStringConversion.pageNumberHtmlRegExp, HtmlStringConversion.pageNumberTag);
          return formattedValue;
    }

    public replaceTagsWithHtml(value: string) {
          let formattedValue = value.replace(HtmlStringConversion.pageBreakBeforeTagRegExp, HtmlStringConversion.pageBreakBeforeHtml);
          formattedValue = formattedValue.replace(HtmlStringConversion.pageNumberTagRegExp, HtmlStringConversion.pageNumberHtml);
          return formattedValue;
    }

    public formatStringToHtml(value: string) {
      let colorFormat=this.FormatHSLAColorToColorName(value);
      let formattedValue = this.replaceTagsWithHtml(colorFormat);
      formattedValue = this.localization.curlyQuotesfix(formattedValue);
      return formattedValue;
    }

    public FormatHSLAColorToColorName(value: string) {
      let formattedColor=value;
      formattedColor= formattedColor.replace('hsl(270, 75%, 60%)','Purple');
      formattedColor= formattedColor.replace('hsl(240, 75%, 60%)', 'Blue');
      formattedColor= formattedColor.replace('hsl(210, 75%, 60%)', 'Light blue');
      formattedColor= formattedColor.replace('hsl(180, 75%, 60%)', 'Turquoise');
      formattedColor= formattedColor.replace('hsl(150, 75%, 60%)', 'Aquamarine');
      formattedColor= formattedColor.replace('hsl(120, 75%, 60%)' ,'Green');
      formattedColor= formattedColor.replace('hsl(90, 75%, 60%)', 'Light green');
      formattedColor= formattedColor.replace('hsl(60, 75%, 60%)', 'Yellow');
      formattedColor= formattedColor.replace('hsl(30, 75%, 60%)', 'Orange');
      formattedColor= formattedColor.replace('hsl(0, 75%, 60%)' ,'Red');
      formattedColor= formattedColor.replace('hsl(0, 0%, 100%)' ,'White');
      formattedColor= formattedColor.replace('hsl(0, 0%, 90%)', 'Light grey');
      formattedColor= formattedColor.replace('hsl(0, 0%, 60%)', 'Grey');
      formattedColor= formattedColor.replace('hsl(0, 0%, 30%)', 'Dim grey');
      formattedColor= formattedColor.replace('hsl(0, 0%, 0%)', 'Black');
      return formattedColor;
    }

    public base64ToUtf8(value: string): string {
      let result = '';
      try {
        result = decodeURIComponent(escape(atob(value || '')));
      }
      catch {
        result = atob(value || '');
      }
  
      return result
    }

    public utf8ToBase64(value: string): string {
      let result = '';
      try {
        result = btoa(unescape(encodeURIComponent(value || '')));
      }
      catch {
        result = btoa(value || '');
      }
      return result;
    }

    updatedMailListEmitter(event,data){
      data = event;
      data = [...data]
      this.setEmailConfigruationValue();

    }

    emitCollapseAction(event,data){
      data.defaultExpand = event;
      data.emailList= [...data.emailList]
    }

   
    expandCollapseEmail(data){
     data.defaultExpand = !data.defaultExpand
    }


    onFileSelected(event){
      for (const list of event) {
        this.processFileData(list);
      }
    }

    processFileData(fileData): boolean {  
      const listName = fileData.name.split(".");
      const extsn = listName[listName.length - 1];
      if (!this.type.toUpperCase().includes(extsn.toUpperCase())) {
        return false;
      }
        
      this.readFile(fileData,extsn)
      return true;
    }
   
  
    private readFile(file,extsn) {
      const fileName = file.name;
      let reader = new FileReader();
      let that = this;
      reader.onloadend = (e) => {
        let data = (<any>e.target).result;
        this.attachmentList.push({
          fileName: fileName,
          content: data,
          extension: file.type
        }) 
      this.ckEditorForm.controls.attachmentList.setValue(this.attachmentList)
    }

    reader.onerror = function (ex) {
    };
    reader.readAsDataURL(file);;
    }

    attachmentCollapse(){
      this.attachmentExpand = !this.attachmentExpand
    }

    deleteAttachments(event){
      let index = this.attachmentList.findIndex(x => x === event);
      this.attachmentList.splice(index, 1);
      this.attachmentList = [...this.attachmentList]
    }

    clearAllAction(){
      this.attachmentList = [];
    }

    showBccClick(senderType){
      let index = this.emailConfigurationList.findIndex(x => x.sectionId === senderType);
      this.emailConfigurationList[index].isVisible = !this.emailConfigurationList[index].isVisible
    }


    discardMail(){
       this.utils.showAlert(this.captions.warn_datalost, AlertType.Confirmation, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) 
        {
          this.dialogRef.close();

        } 
        else {

        }
        });
    }

    downloadFile(data){
      const base64Data = data.content; // Example base64 data
      const byteCharacters = atob(base64Data.split(',')[1]); // Decode base64 to binary
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray]);

      // Create a temporary object URL for the Blob
      const url = window.URL.createObjectURL(blob);
    
      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = data.fileName;  // Set the filename for the download
    
      // Trigger the download
      anchor.click();
    
      // Clean up: revoke the object URL after download
      window.URL.revokeObjectURL(url);
    }

    patchEmailLogData(data){
      if(data && data.EmailData && data.EmailData != null){
        this.patchBodyContent(data);
        this.bindConfigurationListValue(data)
        switch(data.actionMode){
          case EmailActionMode.reply:{
            this.title = this.captions.lbl_replyMail;
          }
          break;
          case EmailActionMode.replyAll:{
            this.title = this.captions.lbl_replyMail;
  
          }
          break;
          case EmailActionMode.forward:{
            this.title = this.captions.lbl_forwardMail;
          }
          break;
  
        }
      }
    }


    bindConfigurationListValue(data){
      if(data.actionMode == EmailActionMode.replyAll)
         data.EmailData.toAddress.push(data.EmailData.fromAddress)

      this.emailConfigurationList = [
        {
          defaultExpand: true,
          sectionLabel: this.captions.lbl_to,
          emailList: data.actionMode == EmailActionMode.reply ?  this.business.mapMailAddress([data.EmailData.fromAddress]) :
           data.actionMode == EmailActionMode.replyAll ? this.business.mapMailAddress(data.EmailData.toAddress) : '',
          sectionId: EmailSenderType.to,
          isVisible: true,
        },
        {
          defaultExpand: true,
          sectionLabel: this.captions.lbl_cc,
          emailList: data.actionMode == EmailActionMode.replyAll ? this.business.mapMailAddress(data.EmailData.ccAddress) : '',
          sectionId: EmailSenderType.cc,
          isVisible: true,
        },
        {
          defaultExpand: true,
          sectionLabel: this.captions.lbl_bcc,
          emailList: [],//this.business.getMailArrayList(),
          sectionId: EmailSenderType.bcc,
          isVisible: false
        }
      ]
      this.setEmailConfigruationValue();
    }

    patchBodyContent(data){
      let bodyContent = this.business.patchMailBody(data.EmailData);
      this.attachmentList = data.EmailData.attachmentList;
      this.ckEditorForm.controls.subject.setValue(data.EmailData.emailSubject)
      this.ckEditorForm.controls.emailThreadValue.setValue(bodyContent)
    }


}
