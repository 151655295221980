import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertAction } from 'src/app/common/Models/common.models';
import { Localization } from 'src/app/common/localization/localization';
import { AgInputFieldConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ButtonType } from '../../../shared/globalsContant';
import { AlertType, GridOperationType } from 'src/app/retail/shared/shared.modal';
import { TierLevelBusiness } from '../retail-tier-level.business';
import { TierLevelDataService } from '../retail-tier-level.data.service';

@Component({
  selector: 'app-create-tier-level',
  templateUrl: './create-tier-level.component.html',
  styleUrls: ['./create-tier-level.component.scss'],
  providers: [TierLevelBusiness, TierLevelDataService],
  encapsulation: ViewEncapsulation.None
})
export class CreateTierLevelComponent implements OnInit {
  tabledata: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private localization: Localization,
    private business: TierLevelBusiness,
    private fb: UntypedFormBuilder,
    private _utilities: RetailUtilities,
    private dialogRef: MatDialogRef<CreateTierLevelComponent>) {
    this.header = this.dialogData.header;
    this.tabledata = this.dialogData.tableData;
    this.captions = this.localization.captions;
    this.dataInput = dialogData;
    this.business.discountTypes = dialogData.discountTypes;
    this.discountTypeInput = dialogData.discountTypes.map(x => {
      return {
        id: x.id,
        value: x.name,
        viewValue: x.name
      }
    });
  }

  header: string;
  captions: any;
  tierLevelForm: UntypedFormGroup;
  @Output() nextEmit = new EventEmitter();
  cancelButton: ButtonValue;
  saveButton: ButtonValue;
  tierCodeConfig: AgInputFieldConfig;
  tierNameConfig: AgInputFieldConfig
  descriptionConfig: AgInputFieldConfig
  discountTypeInput: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  dataInput: any;

  ngOnInit(): void {
    this.formGenerator();
    this.tierLevelForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.saveButton.disabledproperty = !(this.tierLevelForm.dirty && this.tierLevelForm.valid)
    });

  }

  formGenerator() {
    this.tierLevelForm = this.fb.group({
      id: 0,
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: '',
      discountTypeId: 0
    })
    this.saveButton = {
      type: "primary",
      label: this.dialogData.mode == GridOperationType.Edit ? this.captions.btn_update : this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.tierCodeConfig = {
      className: 'ag_form-control--2',
      form: this.tierLevelForm,
      formControlName: 'code',
      readonly: this.dataInput.mode === GridOperationType.Edit,
      placeHolder: this.captions.tierCode,
      maxlength: 255,
      showRequired: true,
      errorMessage: this.captions.missing_tierCode,
      inputType: 'noprespace,nospecailchar,notallowspace',
      automationId: 'Txt_CreateTier_codeText'
    };
    this.tierNameConfig = {
      className: 'ag_form-control--2',
      form: this.tierLevelForm,
      formControlName: 'name',
      placeHolder: this.captions.tierName,
      maxlength: 255,
      showRequired: true,
      errorMessage: this.captions.missing_tierName,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_CreateTier_tierName'
    };
    this.descriptionConfig = {
      className: 'ag_form-control--2',
      form: this.tierLevelForm,
      formControlName: 'description',
      placeHolder: this.captions.description,
      maxlength: 255,
      showRequired: false,
      inputType: 'noprespace',
      automationId: 'Txt_CreateTier_description'
    };
    this.patchValues();
  }

  patchValues() {
    if (this.dataInput.mode === GridOperationType.Edit) {
      this.tierLevelForm.patchValue({
        id: this.dataInput.data.id,
        code: this.dataInput.data.code,
        name: this.dataInput.data.name,
        description: this.dataInput.data.description,
        discountTypeId: this.dataInput.data.discountTypeId,
      });
    }
    this.tierLevelForm.markAsUntouched();
    this.tierLevelForm.markAsPristine();
  }

  async save(e) {
    if (this.dataInput.mode === GridOperationType.Edit) {
      let res = await this.business.updateTierLevel(this.tierLevelForm.value)
      this.dialogRef.close(res);
    }
    else {
      let code = this.tierLevelForm.value.code.toLowerCase();
      let isExistingCode = this.tabledata?.filter(x => x.code.toLowerCase() == code)?.length > 0
      if (isExistingCode) {
        this._utilities.showAlert(this.captions.DuplicateCodeWarn, AlertType.Error, ButtonType.Ok, (res) => {
          return
        }); 
        return
      }

      let res = await this.business.createTierLevel(this.tierLevelForm.value)
      this.dialogRef.close(res);
    }
  }

  cancel(eve) {
    if (this.tierLevelForm.dirty) {
      this._utilities.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          this.dialogRef.close(this.tabledata);
        }
      })
    }
    else {
      this.dialogRef.close(this.tabledata);
    }
  }

}
