import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { Host, Product } from "../../shared/globalsContant";
import { BaseResponse} from "../../retail.modals";
import { FeaturesConfigurations, ModuleGetFeatureRoute, ModuleHosts, ModuleSetFeatureRoute, PropertyFeature } from '../../shared/business/property-features.model';
import { GiftCardConfiguration } from '../../shared/service/payment/payment-model';
import { RetailService } from '../../retail.service';
import { MiscellaneousSwitch } from "../../shared/globalsContant";
@Injectable({ providedIn: 'root' })
export class PropertyFeaturesConfigurationService {    

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private retailService: RetailService) { }

    public async getPropertyFeatures(): Promise<PropertyFeature[]> {
        try {
         
            return await this.InvokeCreditCardConfigurationCalls<PropertyFeature[]>(
                Host.authentication, "GetPropertyFeatures",
                HttpMethod.Get,
                undefined,
                {
                    propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
                    productId: Product.RETAIL
                });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async getFeatureConfiguration(featureId: number, moduleId: number): Promise<FeaturesConfigurations[]> {
        try {
            return await this.InvokeCreditCardConfigurationCalls<FeaturesConfigurations[]>(ModuleHosts[moduleId], ModuleGetFeatureRoute[moduleId], HttpMethod.Get, undefined, { PropertyFeatureId: featureId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetFeatureConfigurations(featureNames: string[]):  Promise<FeaturesConfigurations[]> {
        let propertyId = Number(this.utils.GetPropertyInfo('PropertyId'));
        let productId = Number(this.utils.GetPropertyInfo('ProductId'));
        if (propertyId && productId) {
            let response: any = await this.http.CallApiAsync<any>({
                host: Host.retailPOS,
                callDesc: "GetFeatureConfiguration",
                method: HttpMethod.Put,
                uriParams: { propertyId: propertyId, productId: productId },
                body: featureNames
            });
            return response.result;
        }
    }

    public async GetFeatureConfigurationsById(featureIds: number[]):  Promise<FeaturesConfigurations[]> {
        let propertyId = Number(this.utils.GetPropertyInfo('PropertyId'));
        let productId = Number(this.utils.GetPropertyInfo('ProductId'));
        if (propertyId && productId) {
            let response: any = await this.http.CallApiAsync<any>({
                host: Host.retailPOS,
                callDesc: "GetFeatureConfigurationById",
                method: HttpMethod.Put,
                uriParams: { propertyId: propertyId, productId: productId },
                body: featureIds
            });
            return response.result;
        }
    }
    public async SetQuickIdConfigSettingForSpa(module:string) {
        let response: any = await this.http.CallApiAsync<any>({
            host: Host.spaManagement,
            callDesc: "GetAllSettingByModule",
            method: HttpMethod.Get,
            uriParams: {  module:module },
          
        });        
        sessionStorage.setItem("QuickIdConfig",JSON.stringify(response.result[0]));
        
      }

      public async SetQuickIdConfigSettingForGolf(module:string,screen:string) {
        let response: any = await this.http.CallApiAsync<any>({
            host: Host.golfManagement,
            callDesc: "GetGolfSettings",
            method: HttpMethod.Get,
            uriParams: {  module: module, screen: screen },
          
        });   
        let result:any = response.result;     
          if (result && result.configValue && result.configValue.retailTransactions) {
              result.configValue["QUICKID_RETAILTRANSACTIONS"] = result.configValue.retailTransactions;
          }
          if (result && result.configValue && result.configValue.priceOverride) {
              result.configValue["QUICKID_PRICEOVERRIDE"] = result.configValue.priceOverride;
          }
                sessionStorage.setItem('QuickIdConfig',JSON.stringify(result.configValue));               
      }
      

      public async SetQuickIdConfigSettingForRetail(module:string) {
        let response: any = await this.http.CallApiAsync<any>({
            host: Host.retailManagement,
            callDesc: "GetAllSettingByModule",
            method: HttpMethod.Get,
            uriParams: {  module:module },
          
        });        
        sessionStorage.setItem("QuickIdConfig",JSON.stringify(response.result[0]));
        
      } 
      
    public async GetMiscConfig() {
        const miscSettings = await this.GetMiscConfigurationSetting();
        let miscValue = miscSettings.result.find(x => x.switch == "TRANSACTION_BY_MACHINENAME").value == "true" ? 'true' : 'false';
        let isCashDrawerEnabled = miscSettings.result.find(x => x.switch == MiscellaneousSwitch.ENABLE_CASH_DRAWER_FUNCTIONALITY).value == "true" ? true : false;
        let cashDrawerURL = miscSettings.result.find(x => x.switch == MiscellaneousSwitch.CASH_DRAWER_URI)?.value;
        let discountMappingEnabled = miscSettings.result.find(x => x.switch == MiscellaneousSwitch.APPLY_DISCOUNTMAPPING_AUTOMATICALLY)?.value == "true" ? true : false;;
        sessionStorage.setItem('enableMachineTransaction', miscValue.toString());
        if (isCashDrawerEnabled) {
            sessionStorage.setItem('CashDrawerURL', cashDrawerURL?.toString());
        }
        if (discountMappingEnabled) {
            sessionStorage.setItem('DiscountMappingEnabled', discountMappingEnabled.toString());
        }

    }      

    public async GetMiscConfigurationSetting() {
        try {
            return await this.http.CallApiAsync<any>({
                host: Host.retailManagement,
                callDesc: "GetMiscConfiguration",
                method: HttpMethod.Get
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async saveFeatureConfiguration(body: FeaturesConfigurations[], moduleId: number): Promise<boolean> {
        try {
            return await this.InvokeCreditCardConfigurationCalls<boolean>(ModuleHosts[moduleId], ModuleSetFeatureRoute[moduleId], HttpMethod.Post, body);
        } catch (e) {
            this.http.exceptionHandle(e);
       }
    }

    public async updatePropertyFeatures(isActivate: boolean, propertyFeatureId: number) {
        try {
            return await this.InvokeCreditCardConfigurationCalls<boolean>(Host.authentication, "UpdatePropertyFeature", HttpMethod.Put, null, { id: propertyFeatureId, value: isActivate });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetGiftCardConfiguration(): Promise<GiftCardConfiguration> {
        let result = await this.retailService.InvokeServiceCallAsync("GetGCConfiguration", Host.payment, HttpMethod.Get);
        return result.result;
    }

    private async InvokeCreditCardConfigurationCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: FeaturesConfigurations[] | PropertyFeature, uRIParams?: any): Promise<T> {

        try {
            let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
                callDesc: callDesc,
                host: host,
                method: callType,
                body: body,
                uriParams: uRIParams
            });

            return response.result;
        }
        catch (ex) {
            return null;
        }
    }
}