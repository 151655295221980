import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';

@Component({
  selector: 'app-rentals-confirm-booking-popup',
  templateUrl: './rentals-confirm-booking-popup.component.html',
  styleUrls: ['./rentals-confirm-booking-popup.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class RentalsConfirmBookingPopupComponent implements OnInit {
  captions: any;
  continueButton: ButtonValue;
  cancelButton: ButtonValue;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent= [];
  inputTableContent = [];
  
  constructor(private dialogRef: MatDialogRef<RentalsConfirmBookingPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,public localization: RetailLocalization) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    console.log(this.data);
    this.initializeTable();
    this.continueButton = {
      type: 'primary',
      label: this.captions.alertPopup.continue,
      disabledproperty: false
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
  }

  initializeTable() {
    this.headerOptions = this.getHeaderOptions();
    this.tableOptions = this.getTableOptions();
    this.tableContent = this.getTableContent();
  }

  getHeaderOptions() {
    let headerOptions = [
      {
        key: 'itemName',
        displayName: this.captions.lbl_itemName,
      },
      {
        key: 'slot',
        displayName: this.captions.lbl_slot,
      },
      {
        key: 'unavailable',
        displayName: this.captions.lbl_unavailable,
      },
      {
        key: 'added',
        displayName: this.captions.lbl_added,
      }
    ]
    if (this.data.isRentalsMandatory) {
      headerOptions.push(
        {
          key: 'mandatory',
          displayName: this.captions.mandatory
        })
    }
    return headerOptions;
  }

  getTableOptions() {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'itemName',
      uniqueKey: 'itemName',
    }
  }

  getTableContent(){
    let tableContent = [];
    this.data.data.forEach(x=>{
      tableContent.push({
        itemName: x.itemDescription,
        slot: this.localization.ConvertDateToTime(x.slot), //"9.00 AM",
        unavailable: x.unavailableItemsCount,
        added: x.availableItemsCount,
        mandatory: x.isMandatory
      })
    })
    return tableContent;
  }

  close(){
    this.dialogRef.close();
  }
  continue(e){
    this.dialogRef.close('continue');
  }
  onCancel(e){
    this.dialogRef.close('cancel');
  }

}
