import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import * as MoreInterface from './more-model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReturnButtonValue, TableSearchHeader } from '../../Models/ag-models';
import { Localization } from "src/app/common/localization/localization";
import { Product } from 'src/app/common/enums/shared-enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AgSwiperConfig } from '../../Models/common.models';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreComponent implements OnInit {

  @Input() moreOptions: MoreInterface.More;
  @Input() buttonObj: MoreInterface.SaveButtonObj;
  @Input() searchHeaderOption: TableSearchHeader;
  @Input() automationId:string = '';
  searchByChar: MoreInterface.SearchByChar;
  charArr: string[] = [];
  firstLetter: string[] = [];
  disableFirstLetter: boolean[] = [];
  position: any = ''; // dynamic data
  targetPosition: any = ''; // dynamic data
  all = true;
  changeCharBg = 0;
  left: number;
  top: number;
  filteredDataArr: MoreInterface.MoreCheckbox[] = [];
  newFilteredDataArr: MoreInterface.MoreCheckbox[] = [];
  originalFilteredDataArr: MoreInterface.MoreCheckbox[] = [];
  lastlyClicked: string;
  searchForm: UntypedFormGroup;
  hasValue: string;


  isDisabled = true;
  saveButtonText: string;
  cancelButtonText: string;
  isAll: MoreInterface.MoreCheckbox;
  public swiperConfig: AgSwiperConfig = {
    slidesPerView: 23,
    spaceBetween: 5,
    keyboard: {
      enabled: true,
    },
    mousewheel: true,
    navigation: {
      nextEl: 'icon-right-arrow',
      prevEl: 'icon-left-arrow',
    }
  };

  @Output() moreCheckboxClicked: EventEmitter<any> = new EventEmitter();

  @Output() moreApplyValueChange = new EventEmitter();
  @Output() moreCancelValueChange = new EventEmitter();

  @Output() moreSearchHandler: EventEmitter<any> = new EventEmitter();
  @Output() moreCharClicked: EventEmitter<any> = new EventEmitter();
  @Output() closeMoreWindow: EventEmitter<any> = new EventEmitter();

  @ViewChild('moreSection') moreSection: ElementRef;
  floatLabel: string;
  floatLabelNever: string;
  currentProductId: number;

  constructor(private form: UntypedFormBuilder,private localization: Localization,private utils: CommonUtilities) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
   }

  ngOnInit() {
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
    this.searchForm = this.form.group({
      searchText: this.form.control(''),
      showInactive: false
    });
    this.buttonObj = this.moreOptions.buttonObj;
    this.searchHeaderOption = this.moreOptions.searchHeaderOption;
    this.searchByChar = this.moreOptions.searchByChar;
    this.all = this.moreOptions.searchByChar.all;
    this.charArr = this.moreOptions.searchByChar.charArr;
    this.originalFilteredDataArr = this.moreOptions.filteredDataArr.slice(0, this.moreOptions.filteredDataArr.length);
    this.newFilteredDataArr = this.moreOptions.filteredDataArr.slice(0, this.moreOptions.filteredDataArr.length);
    this.filteredDataArr = this.moreOptions.filteredDataArr;
    this.position = this.moreOptions.position[0];
    this.targetPosition = this.moreOptions.position[1];

    this.isDisabled = this.buttonObj.disabled ? this.buttonObj.disabled : true;
    this.saveButtonText = this.buttonObj.customSaveText;
    this.cancelButtonText = this.buttonObj.customCancelText;


    this.setPosition();

    this.originalFilteredDataArr.forEach(y => {
      if (y.id == '0' || y.id == 0) {
        return;
      }
      this.firstLetter.push(y.moreCustomCBxlabelText.charAt(0).toLowerCase());
    });

    this.charArr.forEach((x, index) => {
      const convertCharCase = x.toLowerCase();
      if (this.all && index == 0) {
        this.disableFirstLetter.push(true);
        return;
      }
      this.disableFirstLetter.push(this.checkChar(this.firstLetter, convertCharCase));
    });
    this.isAll = this.newFilteredDataArr.find(x => (x.id == "0" || x.id == 0));
  }
 


  setPosition() {
    const HeaderFooterLength = 65 * 2;
    const checkboxHeight = 30;
    const getRowLength = ((this.filteredDataArr.length / 5) >> 0);
    const getRowReminder = this.filteredDataArr.length % 5;
    let rowHeight;

    if (getRowLength == 0) {
      rowHeight = (1 * checkboxHeight);
      rowHeight = rowHeight + HeaderFooterLength + 40;  // 40 padding 
    } else if (getRowLength < 5) {
      if (getRowReminder == 0) {
        rowHeight = (getRowLength * checkboxHeight);
      } else if (getRowReminder >= 1) {
        rowHeight = ((getRowLength + 1) * checkboxHeight);
      }
      rowHeight = rowHeight + HeaderFooterLength + 40;  // 40 padding 
    } else {
      rowHeight = 175 + HeaderFooterLength;  // max-height of container is 175 
    }

  
    const targetLeft = this.targetPosition.left;        
    
    const targetTop = this.targetPosition.top - 5;
    const screenHeight = this.position.view.innerHeight;
    const screenWidth = this.position.view.innerWidth;
    const checkBottomDiff = screenHeight - targetTop;
    const checkLeftDiff = screenWidth - targetLeft;

    //top position
    if (checkBottomDiff < rowHeight) {
      if (checkBottomDiff < 0) {
        this.top = screenHeight - rowHeight;
      } else {
        this.top = targetTop - rowHeight;
      }
    } else {
      if (targetTop - 70 < 70) {
        this.top = targetTop;
      } else {
        if (targetTop - rowHeight > 0) {
          this.top = targetTop - rowHeight;
        } else {
          this.top = targetTop;
        }
      }
    }

    //left position 
    if (checkLeftDiff < 900) {
      if (this.currentProductId == Product.PMS) {
        this.left = screenWidth - 1080;
      } else {
        this.left = screenWidth - 900;
      }
    } else {
      this.left = targetLeft;
    }
  }
  checkChar(arr, charSent) {
    return arr.some(x => charSent === x);
  }

  // search

  searchValueChange() {
    this.changeCharBg = this.all ? 0 : -1;
    this.hasValue = this.searchForm.controls.searchText.value;
    const searchText = this.hasValue ? this.hasValue.trim().toLowerCase() : '';
    this.callFilter(searchText, 'fullText', '');
    this.moreSearchHandler.emit();
  }
  clearText() {
    this.searchForm.controls.searchText.reset();
    this.searchValueChange();
  }

  // char clicked

  charClicked(char, index) {
    this.changeCharBg = index;
    const searchChar = char.toLowerCase();
    this.callFilter(searchChar, 'char', index);
    this.moreCharClicked.emit();
  }

  // close clicked

  close() {
    this.closeMoreWindow.emit();
  }
  closeWindow() {
    this.closeMoreWindow.emit();
  }

  // checked change

  filteredCheckboxClicked(i, filteredData, e) {
    this.lastlyClicked = filteredData.id;
    if (filteredData.id == '0' || filteredData.id == 0) {
      this.newFilteredDataArr.forEach(x => {
        x.moreCustomCBxChecked = e.target.checked;
      });
    } else {
      this.newFilteredDataArr.find(x => x.id == filteredData.id).moreCustomCBxChecked = e.target.checked;
      if (this.isAll) {
        if (e.target.checked) {
          let findZeroIndex = 0;
          let getCount = 0;
          this.newFilteredDataArr.forEach((x, index) => {
            if (x.id == '0' || x.id == 0) {
              findZeroIndex = index;
              return;
            }
            if (x.moreCustomCBxChecked) {
              getCount++;
            }
          });
          if (getCount == (this.newFilteredDataArr.length - 1)) {
            this.newFilteredDataArr[findZeroIndex].moreCustomCBxChecked = true;
          }
        } else {
          this.newFilteredDataArr.find(x => (x.id == "0" || x.id == 0)).moreCustomCBxChecked = !this.newFilteredDataArr.some(x => !x.moreCustomCBxChecked);
        }
      }
    }
    this.isDisabled = false;
    this.moreCheckboxClicked.emit();
  }

  getApplyEmitvalue(e: ReturnButtonValue) {
    const emitArr = [];
    this.newFilteredDataArr.forEach((data, index) => {
      if (data.moreCustomCBxChecked) {
        if (data.id === this.lastlyClicked) {
          emitArr.splice(0, 0, data);
          return;
        }
        emitArr.push(data);
      }
    });
    this.moreApplyValueChange.emit(emitArr);
  }

  getCancelEmitvalue(e: ReturnButtonValue) {
    this.moreCancelValueChange.emit(e);
  }

  callFilter(searchText, type, charIndex) {
    const newArr = [];
    this.originalFilteredDataArr.forEach((x, index) => {
      const checkText = x.moreCustomCBxlabelText.toLowerCase().indexOf(searchText);
      if (type == 'fullText' && checkText != -1) {
        newArr.push(index);
      }
      if (type == 'char' && charIndex == 0 && this.all) {
        newArr.push(index);
        return;
      }
      if (type == 'char' && checkText != -1 && checkText == 0) {
        newArr.push(index);
      }
    });
    const newSearchArr = [];
    for (let dataLoop = 0; dataLoop <= (newArr.length - 1); dataLoop++) {
      const index = newArr[dataLoop];
      newSearchArr.push(this.originalFilteredDataArr[index]);
    }
    this.filteredDataArr = newSearchArr;
  }
}

