import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppointmentpopupService } from '../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import { SpaUtilities } from '../../utilities/spa-utilities';
import { BaseResponse, ClientDetail, KeyValuePair, NoDataModel, SystemConfig, Therapist } from '../../business/shared.modals';
import { Host, DepositRetailItemType } from '../../globalsContant';
import { appointmentService } from '../../service/appointment.service';
import { UntypedFormControl } from '@angular/forms';
import { AppointmentActionsDialogComponent } from '../../appointment-actions/appointment-actions-dialog/appointment-actions-dialog.component';
import { SlideInformationService } from '../../slide-information/slide-information.service';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { Router } from '@angular/router';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { RetailSharedVariableService } from '../../../retail/shared/retail.shared.variable.service';
import { BreakPointAccess } from '../../service/breakpoint.service';
import { DepositEventModel } from '../../../retail/shared/events/event.model';
import { RedirectToModules } from '../../../retail/shared/utilities/retail-utilities';
import { Gratuity, ServiceCharge } from 'src/app/retail/shared/shared.modal';
import _ from 'lodash';
import { GuaranteeMethodAPIModel, GuaranteeMethodBusiness } from '../../appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { Subscription } from 'rxjs';
import { CustomFeeSourceType } from 'src/app/retail/shared/globalsContant';

@Component({
  selector: 'add-deposit',
  templateUrl: './add-deposit.component.html',
  styleUrls: ['./add-deposit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [GuaranteeMethodBusiness]
})

export class AdddepositComponent implements OnInit,OnDestroy {
  Active: boolean = false;
  date = new UntypedFormControl(this.PropertyInfo.CurrentDate);
  excludeTax = new UntypedFormControl(false);
  serializedDate = new UntypedFormControl(this.PropertyInfo.CurrentDate.toISOString());
  selected = 'option2';
  depositArray: any = [];
  checkboxValue = "";
  selectedId: number;
  dateFrom: any = this.PropertyInfo.CurrentDate;
  dateTo: any = this.PropertyInfo.CurrentDate;
  ClientName: any;
  recordObj: any;
  recordobjId: any = [];
  clientSearch: any=[];
  intialClinetArray: any = [];
  linkId: any;
  clinetidArray: any = []
  isChecked: boolean = false;
  appointmentId: any;
  linkCode: any = [];
  captions: any = this.localization.captions.bookAppointment;
  common: any = this.localization.captions.common;
  symbol: any = "(" + this.localization.currencySymbol + ")";
  mainIndex: any;
  appointmentInfo: any[] = [];
  depositAppointments = [];
  therapistsDetail: Therapist[];
  deposititems : any;
  isCorporatesConfigured : boolean = false;
  corporatesApplicable : any = {};
  restrictExcessDepositPrice : boolean=false;
  showGrid: boolean = false;
  sub:Subscription = new Subscription();
  noDataOptions: NoDataModel;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AppointmentActionsDialogComponent>, public appointmentservice: AppointmentpopupService,
    public SlideService: SlideInformationService, private http: HttpServiceCall, public util: SpaUtilities, public localization: SpaLocalization,
    public _as: appointmentService, public retailSharedService: RetailSharedVariableService, private router: Router, public PropertyInfo: SpaPropertyInformation,
    private BPoint: BreakPointAccess,
    private utils: SpaUtilities,
    private guaranteeBusiness: GuaranteeMethodBusiness, private retailPropertyInfo: RetailPropertyInformation
  ) {
    this.retailSharedService.excludeTaxForDeposit = this.excludeTax.value;
    this.sub.add(
    this.appointmentservice.depositRecordsAvailable.subscribe(async x => {
      let depositRecords = _.cloneDeep(x);
      this.appointmentservice.intialClinetArrayTracker.map(itenerary => {
        depositRecords.map(clientInfo => {
          if (itenerary.clientId === clientInfo.clientId) {
            itenerary.services.map(service => {
              if (clientInfo.appointmentId === service.appointmentId) {
                clientInfo.deposit = service.deposit;
                clientInfo.gratuity = service.gratuity;
                clientInfo.serviceCharge = service.serviceCharge;
              }
            })
          }
        });
      });
      this.depositAppointments = depositRecords;
      await this.GetAllClientAppointments();
    }));
  }

  ngOnInit() {
    this.appointmentId = this.SlideService.fieldData.appointmentId;
        
    const memberConfiguration = this.retailPropertyInfo.getMemberConfig;
    if(memberConfiguration && memberConfiguration.applicableCorporates && memberConfiguration.applicableCorporates != "" && memberConfiguration.corpIdNameMap && memberConfiguration.corpIdNameMap != ""){
      this.isCorporatesConfigured = true;
      this.corporatesApplicable = JSON.parse(memberConfiguration.corpIdNameMap); 
    }
    this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "RESTRICT_EXCESS_DEPOSIT_PRICE");
    this.GetLinkCodes();
    this.noDataOptions = { inputText: this.localization.captions.common.NoDataFound, noDataId: GlobalConst.noRecordsType.noRecords, noDataFontSize: 10 };
  }

  ngOnDestroy(){
    this.appointmentservice.labelRecords=[];
    this.appointmentservice.objidArray=[];
    if(this.sub)
      this.sub.unsubscribe();
  }


  GetAllClientAppointments() {
    let objids = this.appointmentservice.objidArray;
    this.utils.ToggleLoader(true);
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "AppointmentbyClientsandStatus",
      uriParams: { clientIds: objids, status: 'CANC,RESV,NOSHOW,CKIN,CKOUT', date: null },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  errorCallback(){
    this.showGrid = true;
    this.utils.ToggleLoader(false);
  }

  checkedData(deposit: any): any {
    deposit.isSelected = deposit.services.every((x) => x.isChecked);
    this.appointmentservice.checkSelected();
  }

  checkOverallData(deposit) {
    deposit.services.map((x) => x.isChecked = deposit.isSelected ? !this.checkCheckBoxApplicable(x) : deposit.isSelected);
    this.appointmentservice.checkSelected();
  }

  priceChange(depositDetail){
    if(this.restrictExcessDepositPrice){
      if((this.localization.currencyToSQLFormat(depositDetail.deposit) + this.localization.currencyToSQLFormat(depositDetail?.depositPaid)) > this.localization.currencyToSQLFormat(depositDetail.totalprice)){
        this.util.showAlert(this.localization.getError(110062), GlobalConst.AlertType.Warning, GlobalConst.ButtonType.Ok);
      }
    }else{
      this.appointmentservice.checkSelected();
    }
  }

  changeToggle(e) {
    this.excludeTax.setValue(e[0]);
  }

  async GetShopItemsAsync() {
    let result = await this.http.CallApiAsync<any>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemByItemType",
      method: HttpMethod.Get,
      uriParams: {
        type: DepositRetailItemType
      }
    });

    return result.result;
  }

  CheckItemsAreFromSelectedOutlet(shopitems): boolean {
    const selectedOutletItems = shopitems.filter(si => si.outletItem.some(o => o.outletId == this.retailSharedService.SelectedOutletId));
    let depositItemInSelectedOutletItem = selectedOutletItems.filter(x => x.retailItemDetail.itemType == 6);
    return depositItemInSelectedOutletItem.length > 0;
  }

  /**
   * check if user provided deposit exceeds actual price
   */
  checkIfDepositExceedsPrice()
  {
    return this.appointmentservice.intialClinetArray.some(deposit=>{
      return deposit.services.some(dep=> {
        return dep.isChecked && (this.localization.currencyToSQLFormat(dep.deposit) + this.localization.currencyToSQLFormat(dep?.depositPaid)) > this.localization.currencyToSQLFormat(dep.totalprice)
      })
    })
  }

  async updateService() {
    if (this.retailSharedService.SelectedOutletId === 0 || (this.PropertyInfo.UseRetailInterface && this.retailSharedService.SelectedTerminalId === 0)) {
      this.retailSharedService.selectedProducts = [];
      this.dialogRef.close();
      this.util.RedirectTo(1);
      let errorText = this.retailSharedService.SelectedOutletId === 0 ? this.localization.captions.shop.PleaseSelectDefaultOutlet : this.localization.captions.shop.selectTerminal;
      this.util.ShowErrorMessage(this.localization.captions.common.Information, errorText);
      return;
    }
    this.deposititems = await this.GetShopItemsAsync();
    if (!this.CheckItemsAreFromSelectedOutlet(this.deposititems)) {
      const errorMsg = this.localization.getError(10722);
      this.retailSharedService.selectedProducts = [];
      this.dialogRef.close();
      this.util.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
      return;
    }
    if (this.isCorporatesConfigured && this.checkForDifferentCorpMembers()) {
      const errorMsg = this.localization.getError(110061);
      this.retailSharedService.selectedProducts = [];
      this.util.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
      return;
    }

    if(this.restrictExcessDepositPrice ? this.checkIfDepositExceedsPrice() : false)
    {
      this.util.showAlert(this.localization.getError(110062), GlobalConst.AlertType.Warning, GlobalConst.ButtonType.Ok);
      return;
    }
    
    this.appointmentservice.intialClinetArray.forEach(a =>
      a.services.forEach(element => {
        if (
          element.isChecked && (
            this.localization.currencyToSQLFormat(element.deposit) > 0
            || this.localization.currencyToSQLFormat(element.gratuity) > 0
            || this.localization.currencyToSQLFormat(element.serviceCharge) > 0)
        ) {
          const elementWithClientName = { ...element, clientName: a.name, clientId: a.clientId ? a.clientId : 0 , clientType:a.clientType,clientLinkId:a.clientLinkId};
          this.depositArray.push(elementWithClientName);
        }
      })
    );
    let depositAll: DepositEventModel[] = [];
    for (let i = 0; i < this.depositArray.length; i++) {
      depositAll.push({
        id: 0,
        typeId: this.depositArray[i].appointmentId,
        amount: this.localization.currencyToSQLFormat(this.depositArray[i].deposit),
        gratuity: 0, //this.localization.currencyToSQLFormat(this.depositArray[i].gratuity),
        serviceCharge: 0, //this.localization.currencyToSQLFormat(this.depositArray[i].serviceCharge),
        clientName: this.depositArray[i] && this.depositArray[i].clientName ? this.depositArray[i].clientName : '',
        clientId: this.depositArray[i] && this.depositArray[i].clientId ? this.depositArray[i].clientId : 0,
        clienttype: this.depositArray[i] && this.depositArray[i].clientType ? this.depositArray[i].clientType : 0,
        clientLinkId: this.depositArray[i] && this.depositArray[i].clientLinkId ? this.depositArray[i].clientLinkId : 0,
        retailItemId: this.depositArray[i].retailItemId,
        packageGroupId: this.depositArray[i].packageGroupId,
        typeMappingId: this.depositArray[i].retailItemId > 0 ? this.depositArray[i].id : 0,
        isConvenienceFee: this.depositArray[i].isConvenienceFee
      });
    }
    this.retailSharedService.payeeId = this.appointmentservice.intialClinetArray.length > 0 ? this.appointmentservice.intialClinetArray[0].clientId : 0;
    this.retailSharedService.sourceCorpId = this.depositAppointments.filter(x => x.clientType == 2)?.map(x => x.memberCorpId)[0] ?? 0;
    this.retailSharedService.depositArray = [];
    this.retailSharedService.depositArray = depositAll;    
    this.retailSharedService.selectedappointments = depositAll;
    this.retailSharedService.isFromDeposit = true;
    this.retailSharedService.excludeTaxForDeposit = this.excludeTax.value;
    const currentApptDetail = this.appointmentInfo.find(x => x.id == this.appointmentId);
    if (currentApptDetail?.appointmentBillingDetail?.billingDetail) {
      const billingDetails: GuaranteeMethodAPIModel = this.guaranteeBusiness.ParseToAPIModel(currentApptDetail.appointmentBillingDetail.billingDetail)
      this.retailSharedService.GuaranteeMethodData = billingDetails && billingDetails.guaranteePaymentMethod;
    }
    this.adddeposit();
  }

  GetAllClients() {
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.util.errorCallback.bind(this),
      callDesc: "GetAllClients",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  receiveMessage($event) {
    if (this.validateLocationMaxGuest()) {
      this.makeSearchCall($event.searchValue)
    }
  }

  validateLocationMaxGuest() {
    if (this.appointmentservice.recordsArray && this.appointmentservice.clientScreenProperties.maxGuestLocation && this.appointmentservice.clientScreenProperties.maxGuestLocation > 0
      && this.appointmentservice.recordsArray.length >= this.appointmentservice.clientScreenProperties.maxGuestLocation) {
      let message = this.captions.LocationMaxGuestClientConflict.interpolate({ location: this.appointmentservice.clientScreenProperties.maxGuestLocation });
      this.util.showAlert(message, GlobalConst.AlertType.Warning, GlobalConst.ButtonType.Ok);
      return false;
    }
    return true;

  }

  adddeposit(){
    let shopItemData = this.deposititems;
      let linenumber = 1;
      if (shopItemData != null && this.retailSharedService.depositArray && this.retailSharedService.depositArray.length > 0) {
        this.retailSharedService.selectedProducts = [];
        this.retailSharedService.depositArray.forEach(deposit => {
          this.retailSharedService.selectedProducts.push({
            ItemId: shopItemData[0].retailItemDetail.id,
            ExternalPOSItemId: shopItemData[0].retailItemDetail.externalPOSId,
            ItemType: shopItemData[0].retailItemDetail.itemType,
            ItemDescription: shopItemData[0].retailItemDetail.itemDescription,
            ProductName: shopItemData[0].retailItemDetail.itemDescription,
            LineNumber: linenumber++,
            ServiceId: 0,
            ProductPrice: deposit.amount,
            SalesPrice: deposit.amount,
            Noofitems: 1,
            Discount: 0,
            DiscountPercentage: 0,
            DiscountTypeId: 0,
            category: shopItemData[0].retailItemDetail.category,
            isEditDisabled: true,
            isModificationRestricted: true,
            isGroupingKey: shopItemData[0].retailItemDetail.isGroupingKey,
            isPackagedItem: shopItemData[0].retailItemDetail.isPackagedItem,
            PackageItemId: 0,
            MultiPack: shopItemData[0].retailItemDetail.isMultiPack,
            ClientMultiPackId: 0,
            PackageGroupId: 0,
            isOpenPricedItem: true,
            ServiceCharge: this.GetServiceChargeDetails(deposit.serviceCharge, deposit.typeId),
            Gratuity: this.GetGratuityDetails(deposit.gratuity, deposit.typeId),
            costPrice: shopItemData[0].retailItemDetail.costPrice,
            marginPercentage: shopItemData[0].retailItemDetail.marginPercentage,
            isRequireComments: shopItemData[0].retailItemDetail.isRequireComments,
            isRequestName: shopItemData[0].retailItemDetail.isRequestName,
            itemComments: '',
            isDepositOnly: true,
            clientName: deposit && deposit.clientName ? deposit.clientName : '',
            clientId: deposit && deposit.clientId ? deposit.clientId : 0,
            payeeId: deposit && deposit.clientId ? deposit.clientId : 0,
            allowEarn: shopItemData[0].retailItemDetail.allowEarn,
            discountComments: '',
            discountReason: 0,
            sourceType:(deposit.isConvenienceFee && deposit.retailItemId > 0)? CustomFeeSourceType.ConvenienceFee: (deposit.retailItemId > 0) ? CustomFeeSourceType.RetailItem :CustomFeeSourceType.Appointment,
            sourceTypeId: (deposit.retailItemId > 0 && deposit.isConvenienceFee)? this.appointmentId:(deposit.retailItemId > 0) ? deposit.typeMappingId : deposit.typeId
          });
        });
        this.dialogRef.close();
        this.retailSharedService.isFromDeposit = true;
        if (this.SlideService.fieldData &&
          (this.SlideService.fieldData.appointmentFrom === GlobalConst.AppointmentFrom.SpaWizard
            || this.SlideService.fieldData.isFromSpaWizard)) {
          this.retailSharedService.RedirectModule = RedirectToModules.Spawizard;
          this.retailSharedService.groupAppoinmentId = this.SlideService.fieldData.groupAppointmentId;
          this.retailSharedService.appoinmentId = this.SlideService.fieldData.appointmentId;
          this.retailSharedService.isFromSpaWizard = true;
        }
        else {
          this.retailSharedService.RedirectModule = RedirectToModules.appointment;
        }
        this.router.navigate(['/shop/viewshop/order']);
      }
  }

  makeSearchCall(name: string) {
    this.http.CallApiWithCallback<any[]>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.util.errorCallback.bind(this),
      callDesc: "DepositClientSearch",
      method: HttpMethod.Get,
      uriParams: { searchKey: encodeURIComponent(name), fromDate: this.util.formatDate(this.appointmentservice.depositStartDate), toDate: this.util.formatDate(this.appointmentservice.depositEndDate), linkId: this.appointmentservice.linkId },
      showError: false,
      extraParams: ['dataBelongTo']
    });
  }

  GetLinkCodes() {
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.util.errorCallback.bind(this),
      callDesc: "GetLinkCodes",
      method: HttpMethod.Get,
      uriParams: { propertyDate: this.util.convertDateFormat(this.PropertyInfo.CurrentDate), showInActive: false },
      showError: true,
      extraParams: []
    });
  }
  Cancel() {
    this.appointmentservice.intialClinetArray = [];
    this.depositArray = [];
    this.appointmentservice.objidArray = [];
    this.dialogRef.close();
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string): void {
    if (callDesc == "GetAllClients") {
      let res = <any>result.result;
      res.forEach(element => {
        this.recordobjId.push({ "id": element.id, "name": element.firstName + " " + element.lastName })
        
      });
    }
    else if (callDesc == "DepositClientSearch") {
      let searchApiResponse = <any>result.result;
      if (searchApiResponse) { 
        this.recordobjId=[];
       var client = this.checkForClientAlreadyAdded(searchApiResponse); 
       client.forEach(element => {
        this.recordobjId.push({ "id": element.id, "firstName":element.clientDetail.firstName,"lastName":element.clientDetail.lastName,"clientType":element.clientDetail.clientType,"guestId":element.clientDetail.guestId,
        "addresses":element.addresses[0],"emails":element.emails[0],"phoneNumbers":element.phoneNumbers[0], "corpId":element.clientDetail?.corpId, "corpName":(this.corporatesApplicable[element.clientDetail?.corpId] != null) ? this.corporatesApplicable[element.clientDetail?.corpId] : ''
      })      
        });
      }
      else {
        this.appointmentservice.clientsearchArray = [];
      }
    }
    else if (callDesc == "GetLinkCodes") {
      this.linkCode = <any>result.result;
    }
    else if (callDesc == "UpdateAppointmentDeposit") {
      this.appointmentservice.intialClinetArray = [];
      this.depositArray = [];
      this.appointmentservice.objidArray = [];
      this.dialogRef.close();
    }
    else if (callDesc == "AppointmentbyClientsandStatus") {
      try{
      this.appointmentInfo = <any>result.result;
      const allappointments = result.result;
      let AlltherapistIds = [];
      this.appointmentInfo.forEach(a => a.appointmentTherapists.map(t => AlltherapistIds.push(t.therapistId)));
      const uniqIds = Array.from(new Set(AlltherapistIds));
      this.LoadTherapistDetails(uniqIds);
      this.AutoPopulateSCGT();
      }finally{
        this.showGrid = true;
        this.utils.ToggleLoader(false);
      }
    }
    else if (callDesc == "GetConfiguration") {
      let res: SystemConfig = <any>result.result;
      if (res.switch == "RESTRICT_EXCESS_DEPOSIT_PRICE") {
        this.restrictExcessDepositPrice =res.value == "true" ? true : false;
      }
    }
    

    this.recordobjId = [...this.recordobjId]
  }

  taxExemptChange() {
    var breakpoint = this.BPoint.GetBreakPoint([GlobalConst.RetailBreakPoint.TaxExempt]).result;
    if (!breakpoint[0].allow) {
      this.BPoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.RetailBreakPoint.TaxExempt]);
      this.excludeTax.setValue(!this.excludeTax.value);
    } else {
      this.excludeTax.setValue(this.excludeTax.value);
    }
  }

  private AutoPopulateSCGT() {
    if (this.appointmentInfo && this.appointmentInfo.length > 0) {
      setTimeout(() => {
        this.appointmentservice.intialClinetArray.map(deposit => {
          if (deposit && deposit.services) {
            //Prefill the diff value
            deposit.services.map(x => {
              if (!x.isRetailItem) {
                const depositApp = this.depositAppointments.find(d => d.appointmentId == x.appointmentId);
                const appointmentObj = this.appointmentInfo.find(a => a.appointmentDetail.id == x.appointmentId);
                const depositobj = appointmentObj.deposit.filter(a => a.refundTransactionId == 0 && !a.isVoided);
                let prevServiceCharge = 0, prevGratuity = 0, prevDeposit = 0;

                if (depositobj) {
                  depositobj.forEach(element => {
                    prevDeposit = prevDeposit + element.amount;
                    prevServiceCharge = prevServiceCharge + element.serviceCharge;
                    prevGratuity = prevGratuity + element.gratuity
                  });
                }

                if (appointmentObj && appointmentObj.appointmentDetail && depositApp) {
                  const noExistingDeposits = (prevGratuity == 0 && prevServiceCharge == 0 && prevDeposit == 0);
                  this.updateDepositFields(x, noExistingDeposits, prevDeposit, appointmentObj.appointmentDetail.price, appointmentObj.appointmentDetail.gratuity, appointmentObj.appointmentDetail.serviceCharge, depositApp.tax, depositApp.customFee);
                }
              }
              else {
                const depositRetailItem = this.depositAppointments.map(d => d.retailItems.find(r => r.id == x.id)).find(r => r != undefined);
                if (depositRetailItem) {
                  const noExistingDeposits = this.localization.currencyToSQLFormat(depositRetailItem.deposit) == 0;
                  this.updateDepositFields(x, noExistingDeposits, depositRetailItem.deposit, depositRetailItem.price, 0, 0, depositRetailItem.tax, 0);
                }
              }
              if(this.depositAppointments.find(d => d.appointmentId == x.appointmentId)?.convenienceFeeDetail.price>0)
              {
                const depositAppointment = this.depositAppointments.find(d => d.appointmentId == x.appointmentId).convenienceFeeDetail.price;
                this.updateDepositFields(x, true, depositAppointment, depositAppointment, 0, 0, 0, 0);
              }
            });
          }
        });
      }, 50);
    }
  }

  updateDepositFields(item, noExistingDeposits, deposit, price, prefillGT, prefillSC, prefillTax, prefillCustomFee) {

    const prefillDeposit = noExistingDeposits ? price : deposit;
    const totalprice = price + prefillGT + prefillCustomFee + prefillSC + prefillTax;

    item.gratuity = this.localization.localizeCurrency(prefillGT, false);
    item.serviceCharge = this.localization.localizeCurrency(prefillSC, false);
    item.tax = this.localization.localizeCurrency(prefillTax, false);
    item.customFee = this.localization.localizeCurrency(prefillCustomFee, false);
    item.showCustomFee = prefillCustomFee > 0;
    item.depositPaid = noExistingDeposits ? this.localization.localizeCurrency(0, false) : this.localization.localizeCurrency(prefillDeposit, false)
    const depositAmount = noExistingDeposits ? prefillDeposit + prefillGT + prefillCustomFee + prefillSC + prefillTax
      : Number(price + prefillGT + prefillCustomFee + prefillSC + prefillTax) - Number(prefillDeposit);
    item.deposit = Number(depositAmount) > 0 ? this.localization.localizeCurrency(depositAmount, false) : this.localization.localizeCurrency(0, false);
    item.remainingDue = this.localization.localizeCurrency(totalprice - (noExistingDeposits ? 0 : prefillDeposit), false);
    item.totalprice = this.localization.localizeCurrency(totalprice);
  }

  GetAppointmentTherapists(appointmentId: number) {
    let therapistIds = [];
    const clientAppointment = this.appointmentInfo.find(x => x.id == appointmentId);
    if (clientAppointment && clientAppointment.appointmentTherapists) {
      therapistIds = clientAppointment.appointmentTherapists.map(t => t.therapistId);
    }
    return therapistIds;
  }

  GetGratuityDetails(grauityamount: number, appointmentId: number): Gratuity[] {
    let gratuity: Gratuity[] = [];
    let allowedTherapists = this.GetGratuityAllowedTherapists(this.GetAppointmentTherapists(appointmentId));
    if (grauityamount > 0) {
      allowedTherapists = allowedTherapists ? allowedTherapists : [];
      if (allowedTherapists.length > 0) {
        let amountSplitup = this.util.GetSplitup(grauityamount, allowedTherapists.length);
        for (let i = 0; i < allowedTherapists.length; i++) {
          gratuity.push(
            {
              Id: `T${allowedTherapists[i]}`,
              TransactionDetailId: 0,
              TherapistId: allowedTherapists[i],
              Amount: (i == allowedTherapists.length - 1) ? amountSplitup.upScaleValue : amountSplitup.equalSplit,
              PercOrAmount: 2,
              Percentage: 0,
              PercentageId: 0,
              gratuity: (i == allowedTherapists.length - 1) ? amountSplitup.upScaleValue : amountSplitup.equalSplit,
              StaffType: GlobalConst.THERAPIST
            }
          );
        }
      } else { // Add without staff
        gratuity.push(
          {
            Id: '',
            TransactionDetailId: 0,
            TherapistId: 0,
            Amount: grauityamount,
            PercOrAmount: 2,
            Percentage: 0,
            PercentageId: 0,
            gratuity: grauityamount,
          }
        );
      }
    }
    return gratuity;
  }

  GetServiceChargeDetails(servicechargeamount: number, appointmentId: number): ServiceCharge[] {
    let serviceCharge: ServiceCharge[] = [];
    let allowedTherapists = this.GetServiceChargeAllowedTherapists(this.GetAppointmentTherapists(appointmentId));
    if (servicechargeamount > 0) {
      allowedTherapists = allowedTherapists ? allowedTherapists : [];
      if (allowedTherapists.length > 0) {
        let amountSplitup = this.util.GetSplitup(servicechargeamount, allowedTherapists.length);
        for (let i = 0; i < allowedTherapists.length; i++) {
          serviceCharge.push(
            {
              Id: `T${allowedTherapists[i]}`,
              TransactionDetailId: 0,
              TherapistId: allowedTherapists[i],
              Amount: (i == allowedTherapists.length - 1) ? amountSplitup.upScaleValue : amountSplitup.equalSplit,
              PercOrAmount: 2,
              Percentage: 0,
              PercentageId: 0,
              ServiceCharge: (i == allowedTherapists.length - 1) ? amountSplitup.upScaleValue : amountSplitup.equalSplit,
              StaffType: GlobalConst.THERAPIST
            }
          );
        }
      } else { // Add without staff
        serviceCharge.push(
          {
            Id: '',
            TransactionDetailId: 0,
            TherapistId: 0,
            Amount: servicechargeamount,
            PercOrAmount: 2,
            Percentage: 0,
            PercentageId: 0,
            ServiceCharge: servicechargeamount,
          }
        );
      }
    }
    return serviceCharge;
  }

  GetServiceChargeAllowedTherapists(therapistIds): any {
    if (therapistIds != null && therapistIds.length > 0) {
      return this.therapistsDetail.filter(
        o => therapistIds.indexOf(o.id) > -1 && o.allowServiceCharge
      ).map(x => { return x.id });
    }
    return null;
  }

  GetGratuityAllowedTherapists(therapistIds): any {
    if (therapistIds != null && therapistIds.length > 0) {
      return this.therapistsDetail.filter(
        o => therapistIds.indexOf(o.id) > -1 && o.allowGratuity
      ).map(x => { return x.id });
    }
    return null;
  }

  async LoadTherapistDetails(therapistIds) {
    if (therapistIds.length > 0) {
      let keyValue: KeyValuePair = { key: "id", value: therapistIds };
      let result = await this.http.CallApiAsync({
        callDesc: "getTherapists",
        method: HttpMethod.Get,
        host: Host.spaManagement,
        queryString: keyValue
      });
      let therapistDetail = <Therapist[]>result.result;
      this.therapistsDetail = therapistDetail ? therapistDetail : [];
    }
  }

  checkForClientAlreadyAdded(client: any): any {
    //this collection contains already selected client items in client screen.
    let clientSearchResArr: any = client;
    let indexOfExisting: number;
    this.appointmentservice.labelRecords.forEach(alreadyAddedCli => {
      indexOfExisting = clientSearchResArr.findIndex(c => c.id == alreadyAddedCli.Id);
      if (indexOfExisting != -1) {
        clientSearchResArr.splice(indexOfExisting, 1);  //remove from the search result collection if the item matches.
      }
    }
    )
    return clientSearchResArr;
  }

  checkForDifferentCorpMembers(): boolean {
    let selectedClients = this.appointmentservice.intialClinetArray.filter(a => a.services.some(s => s.isChecked));
    let corpIds = new Set(selectedClients.filter(i => i.clientType == 2).map(c => c.corpId));
    return corpIds.size > 1;
  }

  /**
   * check if check box can be enabled 
   * @param deposit 
   * @returns true/false
   */
  checkCheckBoxApplicable(deposit:any){
      if(deposit)
        return this.restrictExcessDepositPrice && 
          this.localization.currencyToSQLFormat(deposit.remainingDue) <= 0

      return false
  }
}
