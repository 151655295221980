
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetPropertyBusiness } from './set-property.business';
import { DialogCloseOption } from 'src/app/shared/enums/shared-enums';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JWT_TOKEN, USERS_SESSSIONS_INFO } from 'src/app/app-constants';
import { PropertyService } from 'src/app/common/services/property.service';
import { SpaLocalization } from '../../localization/spa-localization';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { UserProperty } from 'src/app/common/Models/common.models';
import { AuthenticationService } from '../../services/authentication.service';
import { Host } from 'src/app/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { BaseResponse } from 'src/app/shared/business/shared.modals';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.html',
  styleUrls: ['./set-property.component.scss']
})

export class ProgressBarComponent {

  DialogCloseOptionEnum = DialogCloseOption;
  captions: any;

  constructor(
    public dialogRef: MatDialogRef<ProgressBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localization: SpaLocalization) {
    this.captions = this.localization.captions;
  }

  closeDialog(res) {
    this.dialogRef.close(res);
  }
}

@Component({
  selector: 'app-set-property',
  templateUrl: './set-property.component.html',
  styleUrls: ['./set-property.component.scss'],
  providers: [SetPropertyBusiness, PropertyInformationDataService]
})

export class SetPropertyComponent implements OnInit {

  token: string;
  propertyId: number;
  userDetails: any;
  selectedPropertyDetails: any;
  propertyName: string;
  routeParam: string;
  state$: any;
  captions: any;
  constructor(
    private router: Router,
    private setPropertyBusiness: SetPropertyBusiness,
    private propertyService: PropertyService,
    private sessionService: ManageSessionService,
    private authentication: AuthenticationService,
    public localization: SpaLocalization,
    private dmConfigDataService: DMConfigDataService,
    public http: HttpServiceCall,
    private utils: SpaUtilities,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    const currentNavigation = this.router.getCurrentNavigation();
    const tempState = this.getTempoaryState();
    if (currentNavigation || tempState) {
      this.state$ = currentNavigation.extras.state || tempState;
      this.setTempoaryState(this.state$);
      this.token = this.state$.token;
      this.propertyId = Number(this.state$.propertyId);
      this.propertyName = this.state$.propertyName;
      this.routeParam = this.state$.routeParam || '/home';
    } else {
      this.route.queryParams.subscribe(params => {
        this.token = params.token;
        this.propertyId = Number(params.propertyId);
        this.propertyName = params.propertyName;
        this.routeParam = params.routeParam || '/home';
      });
    }
    this.routeParam = this.routeParam.replace("/Spa", "");
  }

  async ngOnInit() {
    this.captions = this.localization.captions;
    this.dialog.open(ProgressBarComponent, {
      width: '40%',
      height: '40%',
      disableClose: true,
      data: {
        propertyName: this.propertyName
      }
    });
    // sessionStorage.setItem(USER_SESSION, null);
    if (this.token) {
      this.userDetails = await this.setPropertyBusiness.GetLoginDetailsByToken(this.token);
    } else {
      this.userDetails = this.GetUserSessionsInfo();
    }
    sessionStorage.setItem(JWT_TOKEN, this.userDetails.token);
    localStorage.setItem(JWT_TOKEN, this.userDetails.token);
    const propertyDetails: UserProperty = this.userDetails.userProperties.find(
      item => item.propertyId === this.propertyId
    );
    this.selectedPropertyDetails = {
      id: propertyDetails.propertyCode,
      name: propertyDetails.propertyName
    };
    this.propertyService.isGoogleApiLoaded = false;
    this.propertyService.SetUserInfo(this.userDetails);
    let usersessionId = '';
    if (propertyDetails.sessionId !== null) {
      usersessionId = propertyDetails.sessionId;
    } else {
      const utcDate: Date = this.localization.getUTCDateTimeNow();
      const token = sessionStorage.getItem('_jwt');
      let sessionData = {
        id: 0,
        userId: Number(propertyDetails["userId"]),
        startTime: this.localization.ConvertDateToISODateTime(utcDate),
        propertyId: Number(propertyDetails['propertyId']),
        productId: Number(propertyDetails['productId']),
        timeZone: this.utils.GetClientTimeZone(),
        token: token
      };

      let response = (await this.CreateSession(sessionData, false)).result;
      usersessionId = response;
      console.log(response);
    } 
   
    this.propertyService.UpdateUserSessionInfo(this.selectedPropertyDetails, this.userDetails, usersessionId);
    // this.propertyService.setUserSession(String(usersessionId));
    sessionStorage.removeItem('MultiPropertySession');
    sessionStorage.removeItem('DataMagineConfig');
    await this.dmConfigDataService.SetDataMagineConfig(); 
    this.propertyService.changeTitle();
    this.setPropertyBusiness.GetUserTherapist(this.userDetails.userLoginInfo.userId, this.successCallback.bind(this), this.errorCallback.bind(this));
    setTimeout(() => {
      this.dialog.closeAll();
      this.router.navigate([this.routeParam],
        {
          state: { applyFilter: true }
        }
      );
    }, 5000);
  }

  async CreateSession(sessionData, isFromPMS: boolean) {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        callDesc: "CreateSession",
        body: sessionData,
        method: HttpMethod.Post,
        showError: false,
        uriParams: ''
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  private setTempoaryState(value) {
    localStorage.setItem('tempState', JSON.stringify(value));
    sessionStorage.setItem('tempState', JSON.stringify(value));
  }

  private getTempoaryState() {
    const tempState = sessionStorage.getItem('tempState');
    if (tempState) {
      return JSON.parse(tempState);
    }
    return null;
  }

  private removeTempState() {
    localStorage.removeItem('tempState');
    sessionStorage.removeItem('tempState');
  }

  public GetUserSessionsInfo(): any {
    let userSessions: any;
    const sessionDetails = this.getUserSessionsInfoItem(USERS_SESSSIONS_INFO);
    if (sessionDetails) {
      userSessions = JSON.parse(sessionDetails);
    }
    return userSessions;
  }

  private getUserSessionsInfoItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }
  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) {
      if(callDesc == 'GetUserTherapist'){
        let response = <any>result.result;
          if(response){
            sessionStorage.setItem('UserTherapist', JSON.stringify(response));
            this.routeParam = '/appointment';
          }else{
            sessionStorage.removeItem('UserTherapist');
          }
      }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string): void {

  }

}
