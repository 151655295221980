import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AlertAction, AlertType, RateType, ScheduledTeeTimeUnPaidPlayer, SettingModule, SettingScreen, TeeTimeConfig, TeeTimeTempHoldResult } from '../../../shared/shared.modal';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { Router } from '@angular/router';
import { CommonVariablesService } from '../../../shared/service/common-variables.service';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ButtonType } from '../../../shared/globalsContant';
import { RetailPropertyInformation } from '../../../common/services/retail-property-information.service';
import { RetailDataAwaiters } from '../../../shared/events/awaiters/retail.data.awaiters';
import * as _ from 'lodash'
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { DefaultOutletType } from 'src/app/common/shared/shared/enums/enums';
import { GuaranteeTypes } from 'src/app/retail/shared/business/shared.modals';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { MatDialog } from '@angular/material/dialog';
import { SelectOutletPopupComponent } from 'src/app/common/shared/shared/select-outlet-popup/select-outlet-popup.component';
import { ButtonAction } from 'src/app/common/shared/shared/globalsContant';
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import { DiscountTypeResponse, PlayerPaymentstatus, playerTypes } from '../../../retail.modals';
import * as GlobalConst from '../../../shared/globalsContant';
import { PlayerCategory } from 'src/app/common/enums/shared-enums';
import { UserAccessBreakPoints } from 'src/app/retail/shared/constants/useraccess.constants';
import { HeaderOptionInteface, templatenameenum } from 'src/app/retail/shared/tablevirtualscroll/tablevirtualscroll.model';

@Component({
  selector: 'app-unpaid-players',
  templateUrl: './unpaid-players.component.html',
  styleUrls: ['./unpaid-players.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnpaidPlayersComponent implements OnInit {

  unpaidPlayersForm: UntypedFormGroup;
  captions: any;
  courseSelect: any = [];
  actionButton: { type: string; label: any; disabledproperty: boolean };
  dateInput: {
    className: string;
    form: any;
    formControlName: string;
    errorMessage: string;
    minDate?: Date;
    placeHolder: string;
    value: string;
    maxDate?: Date;
    automationId?:string;
  };
  headerOption: HeaderOptionInteface[];
  bodyContentdata: ScheduledTeeTimeUnPaidPlayer[] = [];
  tempHoldPlayer: TeeTimeTempHoldResult[];
  isPlayerPayReleaseBP: boolean = false;
  fromUnpaidPlayer : boolean = true;
  tableOptions: { selectType: selecttypeenum; };
  selectedDate: Date;
  selectedCourse: number;
  SelectedPlayers: any[] = [];
  userId: any;
  userAccessCourses: number[];
  defaultCourse: { id: number; type: string; };
  teeTimeDefaults: TeeTimeConfig;
  isToContinueWithOldCartItems: boolean = false;
  rateTypes: RateType[] = [];
  floatLabel: string;
  popUpComponentDetails: { componentName: any; popUpDetails: { isStepper: boolean; eventName: string; bindData: string; }; };
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  outlets: any;


  constructor(
    private localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , private router: Router
    , private _retailService: CommonVariablesService
    , private _utilities: RetailUtilities
    , private _PropertyInformation: RetailPropertyInformation
    , private _retailSharedService: RetailSharedVariableService
    , private _memberService: MemberBusinessService
    , private dialog: MatDialog
    , private _subPropertyDataService: SubPropertyDataService
    , private cdr: ChangeDetectorRef
  ) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.userId = Number(this.localization.GetsessionStorageValue('_userInfo', 'userId'));
    this.captions = this.localization.captions.shop.unpaidPlayers;
    this.formGenerator();
    this.GetCourseData();
    this.loadGridWithOutlet();
    this.getPlayerProfileBreakPointAccess();
    RetailDataAwaiters.GetAllRateTypes().then(resp => {
      this.rateTypes = resp;
    });
    this.dateInput = {
      className: 'golf-form-control--lg',
      form: this.unpaidPlayersForm,
      formControlName: 'date',
      errorMessage: this.localization.captions.lessonBooking.dateError,
      placeHolder: this.localization.captions.common.Date,
      value: this._PropertyInformation.CurrentDTTM.toString(),
      automationId:"UnpaidPlayers"
    };
    RetailDataAwaiters.GetSettings(SettingModule.SystemSetup, SettingScreen.TeeTime).then(res => {
      this.teeTimeDefaults = res;
      this.reBindDate();
    });
    this._retailService.isFromRefund = false;
    this._retailService.RefundTicketTransactionIDs = [];
  }

  async getPlayerProfileBreakPointAccess() {
    RetailDataAwaiters.ValidateBreakPoint(UserAccessBreakPoints.PLAYERPAYRELEASE, false).then(x => {
      this.isPlayerPayReleaseBP = x.isAllow;
    });
  }

  GetDefaultCourse(x) { this.defaultCourse = x; }

  formGenerator() {
    this.unpaidPlayersForm = this.fb.group({
      date: '',
      courseSelect: ''
    });
    this.actionButton = {
      type: 'primary',
      label: this.captions.pay,
      disabledproperty: true,
    };
  }

  async GetCourseData() {
    this._utilities.ToggleLoaderWithMessage(true,this.localization.captions.lbl_processing)
    let defaultCourse = await RetailDataAwaiters.GetDefaultCourses();
    let userCourse = await RetailDataAwaiters.GetUserCourse(this.userId);
    let allCourse = await RetailDataAwaiters.GetAllCourses();
    let results = await Promise.all([defaultCourse, userCourse, allCourse]);
    if (results) {
      this.defaultCourse = results[0];
      this.userAccessCourses = results[1].map(x => x.courseId);
      this.loadCoursesData(results[2]);
    }
  }

  async loadCoursesData(courseResult) {
    this.courseSelect = courseResult;
    if (this.courseSelect) {
      this.unpaidPlayersForm.patchValue({
        date: this._PropertyInformation.CurrentDTTM,
        courseSelect: this.defaultCourse ? this.defaultCourse.id : ''
      });
      if (this.unpaidPlayersForm.get("date").valid) {
        this.loadGrid();
      }
    }
  }

  GetHeaderOption(showAction : boolean)
  {
    let headerOptions : HeaderOptionInteface[] = [
      {
        key: 'playerName',
        description: this.captions.playerName,
        alignment: 'textLeft',
        sorting: true,
        searchable: false
      }, 
      {
        key: 'scheduledDateTime',
        description: this.captions.date,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }, 
      {
        key: 'course',
        description: this.captions.course,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }, 
      {
        key: 'rateType',
        description: this.captions.rateType,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }, 
      {
        key: 'packageCode',
        description: this.captions.packageCode,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      },
      {
        key: 'walk',
        description: this.captions.walk,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }, {
        key: 'trail',
        description: this.captions.trail,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }, 
      {
        key: 'holes',
        description: this.captions.holes,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      }
    ];
    const memberConfig = this._PropertyInformation.getMemberConfig;
    if(memberConfig && memberConfig.applicableCorporates!= null && memberConfig.applicableCorporates){
      headerOptions.splice(5,0,
      {
        key: 'corpName',
        description: this.captions.corpName,
        alignment: 'textLeft',
        sorting: false,
        searchable: false
      });
    }
    if (showAction) {
      headerOptions.push({
        key: 'action',
        description: this.localization.captions.teetime.action,
        alignment: 'textCenter',
        sorting: false,
        searchable: false,
        templatecontainer: {disabledFlag: true, templateName: templatenameenum.childTemplate}
      });
    }

    return headerOptions;
  }

  async loadGrid() {
    this._retailService.SelectedPlayers = [];
    this._retailService.selectedProducts = [];
    var courseId = this.unpaidPlayersForm.get("courseSelect").value;
    var date = this.unpaidPlayersForm.get("date").value;

    if (courseId == '' || date == '')
      return;

    this.tableOptions = {
      selectType: selecttypeenum.checkbox,
    };

    this.bodyContentdata = [];
    this.bodyContentdata.length = 0;
    const param = {
      courseId: courseId,
      date: date
    }
    let results = await RetailDataAwaiters.GetUnpaidPlayersByPlayerStatus(courseId, this.localization.convertDateTimeToAPIDateTime(date), PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund);
    if (results) {
      results.forEach(element => { element.scheduledTeeTimePlayerId = element.id; element.id = element.playerId; element.isHold = false; element.isReleaseAllow = false; });
      await this.getHoldPlayers(results.map(x=> x.playerId));
      const loginUserId = this.localization.GetUserInfo('userId');
      if (this.tempHoldPlayer.length > 0) {
        results.forEach(a => {
          let playerLength = this.tempHoldPlayer.filter(c => c.playerId == a.playerId).length > 0 ? true : false;
          const blockByUserName = playerLength == true ? this.tempHoldPlayer.find(c => c.playerId == a.playerId).userName : "";
          const blockByUserId = playerLength == true ? this.tempHoldPlayer.find(c => c.playerId == a.playerId).createdBy : 0;
          a.blockByUser = blockByUserName;
          a.isHold = blockByUserName != "" && blockByUserName != undefined;
          a.isReleaseAllow = blockByUserId && blockByUserId.toString() == loginUserId ? true : this.isPlayerPayReleaseBP;
          a.checked = a.isHold == true ? false : a.checked;
        });
      }

      let showAction = results.filter(x => x.isHold)?.length > 0 ? true : false;
      this.headerOption = this.GetHeaderOption(showAction);

      let productId = Number(this._utilities.GetPropertyInfo('ProductId'));
      if (productId == GlobalConst.Product.GOLF && this.teeTimeDefaults && this.teeTimeDefaults != null && this.teeTimeDefaults.requireCheckInCheckOut) {
        results = results.filter(x => (x.playerStatus & PlayerPaymentstatus.checkIn) != 0 || (((x.playerStatus & PlayerPaymentstatus.unPaid) != 0 || (x.playerStatus & PlayerPaymentstatus.refund)!=0) && (x.playerStatus & PlayerPaymentstatus.checkOut) != 0));
      }
      this.bodyContentdata = results;
      this.actionButton.disabledproperty = true;
    }
    this._utilities.ToggleLoaderWithMessage(false)
  }

  async getHoldPlayers(playerIds: number[]) {
    this.tempHoldPlayer = await RetailDataAwaiters.GetTempHoldPlayers(playerIds);
  }

  async releasePlayersInHold(playerIds: number[]) {
    await RetailDataAwaiters.ReleasePlayersInHold(playerIds);
    await this.resetRetailItemInShop(playerIds);
  }
  async resetRetailItemInShop(playerIds: number[]) {
    this._retailSharedService.SelectedPlayers = this._retailSharedService.SelectedPlayers.filter(x => !(playerIds.some(z => x.playerId == z)))
    this._retailSharedService.selectedProducts = this._retailSharedService.selectedProducts.filter(x => !(playerIds.some(z => x.payeeId == z)))
  }

  activityAction(e, data) {
    let playerIds = [];
    playerIds.push(data.playerId);
    this.releasePlayersInHold(playerIds);
    this.bodyContentdata.find(c => c.playerId == data.playerId).isHold = false;
    this.bodyContentdata.find(c => c.playerId == data.playerId).blockByUser = "";
    this.bodyContentdata = [...this.bodyContentdata];
    this.cdr.detectChanges();
    let showAction = this.bodyContentdata.filter(x => x.isHold)?.length > 0 ? true : false;
    this.headerOption = this.GetHeaderOption(showAction);
  }

  openDefaultOutletPopup() {
    this.popUpComponentDetails = {
      componentName: SelectOutletPopupComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: ''
      }
    };
    const selectDefaultDialogRef = this.dialog.open(SelectOutletPopupComponent, {
      width: '35%',
      height: '45%',
      data: {
        title: this.localization.captions.selectOutletPopupTitle,
        update: this.localization.captions.btn_save,
        cancel: this.localization.captions.cancel,
        componentDetails: this.popUpComponentDetails,
        info: '',
        actionType: ButtonAction.pay,
        teeTimeType: '',
        isSaveClose: true,
        outlets: this.outlets
      },
      disableClose: true
    });
    selectDefaultDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (result.from === AlertAction.YES) {
        this._retailSharedService.SelectedOutletId = result.outlet;
      }
      this.loadGrid();
    });
  }

  GetUnpaidPlayers(unpaidPlayers: ScheduledTeeTimeUnPaidPlayer[]) {
    this.bodyContentdata = unpaidPlayers;
    this.actionButton.disabledproperty = true;
  }

  dateChanged(event) {

    if (!this.actionButton.disabledproperty) {
      RetailDataAwaiters.ShowConfirmation(this.captions.confirmationMessage, this.onAlertClose.bind(this));
      return;
    }

    this.selectedDate = this.unpaidPlayersForm.get("date").value;
    if (this.unpaidPlayersForm.get("date").valid) {
      this.loadGridWithOutlet();
    }
  }

  onAlertClose(btnname) {
    if (btnname == AlertAction.CANCEL) {
      this.unpaidPlayersForm.get('date').setValue(this.selectedDate);
      this.unpaidPlayersForm.get('courseSelect').setValue(this.selectedCourse);
    }
    else {     //if OK
      this.selectedDate = this.unpaidPlayersForm.get("date").value
      this.selectedCourse = this.unpaidPlayersForm.get("courseSelect").value;
      this.loadGridWithOutlet();
    }
  }

  OnCourseChange(event) {
    this._utilities.ToggleLoaderWithMessage(true,this.localization.captions.lbl_processing)

    if (!this.actionButton.disabledproperty) {
      RetailDataAwaiters.ShowConfirmation(this.captions.confirmationMessage, this.onAlertClose.bind(this));
      return;
    }
    if (!this.userAccessCourses.find(x => x == event.value)) {
      this.unpaidPlayersForm.get('courseSelect').setValue('');
      return this._utilities.showAlert(this.captions.courseUserAccess, AlertType.Warning, ButtonType.Ok);
    }
    this.selectedCourse = this.unpaidPlayersForm.get("courseSelect").value;
    if (this.unpaidPlayersForm.get("date").valid) {

      this.loadGridWithOutlet();
    }
    //this._utilities.ToggleLoaderWithMessage(false)
  }

  async loadGridWithOutlet() {
    if (!this._retailSharedService.SelectedOutletId) {
      this.outlets = await this._subPropertyDataService.getOutlets();
      this.openDefaultOutletPopup();
    }
    else {
      this.loadGrid();
    }
  }

  async AddPlayersInHold(selectedPlayerIds) : Promise<boolean> {
    let result = false;
    await RetailDataAwaiters.TempHoldPlayers(selectedPlayerIds).then(r => { result = true }).catch(async e => {
      let errorMsg = "";
      if(!e.result && e.errorCode)
      {
        errorMsg = this.localization.getError(e.errorCode);
        if(e.errorCode == 41001)
        {          
          errorMsg = e.errorDescription ? this.localization.replacePlaceholders(errorMsg, ['slots'], [e.errorDescription]) : "";
        }        
      }
      var res = this._utilities.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
      await res.afterClosed().toPromise();
      result = false;
    });
    return result;
  }

  async pay(event) {
    this.actionButton.disabledproperty = true;
    this._utilities.ToggleLoader(true);
    const checkedItems = this.SelectedPlayers;
    let customFeeLinkedItemIds =[]; 
    if (checkedItems && checkedItems.length && this.rateTypes && this.rateTypes.length) {
      let corpIds = _.uniq(checkedItems.map(o => o.corpId));
      corpIds = corpIds.filter(o => o != null && o != '');
      if(corpIds && corpIds.length > 1){
        this._utilities.showAlert(this.captions.memberdifferentcorporates,AlertType.Warning);
        return false;
      }
      checkedItems.forEach
      (o => {
        if(o.scheduledTeeTimePlayerCustomFees?.length > 0)
        {
          let linkedItemIds = [...new Set(o.scheduledTeeTimePlayerCustomFees.map(item => item.linkedItemId))];
          customFeeLinkedItemIds.push(...linkedItemIds);
        }
      });
      if(customFeeLinkedItemIds?.length > 0)
        customFeeLinkedItemIds = [...new Set(customFeeLinkedItemIds)];
      let totalMemberGuaranteedPlayerAssociated = 0;
      checkedItems.forEach(x => {
        const isValidMember = this.isValidMember(x.rateTypeId, x.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      if (totalMemberGuaranteedPlayerAssociated > 1 && !(this._retailService.allowMultipleMembersInTransaction() && this._memberService.AllowMultiMemberInTransaction)) {
        this._utilities.showAlert(this.captions.RestrictMultiMemberInATransaction, AlertType.Info, ButtonType.Ok);
        return false;
      }
    }
    // Setting Default outlet for unpaid players
    let defaultConfig = await RetailDataAwaiters.getDefaultsSetting();
    const userDefault = this._utilities.getUserDefaults();
    this._retailSharedService.DeletePreviousPlayer = false;
    if (this._PropertyInformation.UseRetailInterface && userDefault
      && !this._retailSharedService.isTeeTimeAsPaid && !this._retailSharedService.isPromptedForTerminalSelect) {
      // As per QA req, considering user selection if both outlet and terminal not set in user defaults
      this._retailSharedService.SelectedTerminalId = !userDefault.defaultOutletId && !userDefault.defaultTerminalId ? this._retailSharedService.SelectedTerminalId : userDefault.defaultTerminalId;
    }
     //check machine default outlet exist
     let defaultMachineConfig = JSON.parse(sessionStorage.getItem('machineNameDefaults'));
     let default_machine_OutletId = defaultMachineConfig && Object.keys(defaultMachineConfig).length > 0  ? defaultMachineConfig?.defaultOutletId : 0;
     if(default_machine_OutletId){
      await this.settingDefaultOutlet(Number(default_machine_OutletId));
      this._retailSharedService.isTeeTimeAsPaid = false;
    }
    else{      
      if (defaultConfig.defaultOutletType == DefaultOutletType.course) {
        let course = await RetailDataAwaiters.GetCourse(this.unpaidPlayersForm.get("courseSelect").value);
        await this.settingDefaultOutlet(course.defaultOutletId);
        await RetailDataAwaiters.ValidateSelectedTerminalExistsInSelectedOutlet();
        this._retailSharedService.isTeeTimeAsPaid = true;
      } else {
        let outlet = RetailDataAwaiters.GetDefaultOutlet();
        if (outlet && outlet.id) {
          await this.settingDefaultOutlet(Number(outlet.id));
          this._retailSharedService.isTeeTimeAsPaid = false;
        }
      }
    }
    this._retailService.SelectedOutletId = this._retailSharedService.SelectedOutletId;
    this._retailService.SelectedTerminalId = this._retailSharedService.SelectedTerminalId;
    if (!this.isToContinueWithOldCartItems) {
      if (!await RetailDataAwaiters.CheckForUserOutletAccessAndDefaultOutlet()) {
        return;
      }
      let retailProducts: any[] =[];
      try{
        this._utilities.ToggleLoader(true);
        let items = await RetailDataAwaiters.getPlayerRetailItems(this.SelectedPlayers.map(x=>x.scheduledTeeTimePlayerId));
        this._utilities.ToggleLoader(false);
        
        items?.map(y=>y.retailItems).forEach(x=>{
          let y =JSON.parse(x);
          y.forEach(z=>{
            z.id = z.ItemId;
            z.sourceTypeId = this.SelectedPlayers.find(x=> x.scheduledTeeTimePlayerId ==  z.playerId).playerId;
            retailProducts.push(z);
          });
        });
      }
      catch(ex){
        this._utilities.ToggleLoader(false);
      }
      let discountTypeResponse : DiscountTypeResponse[] =  await RetailDataAwaiters.GetDiscountTypeResponse(this.SelectedPlayers, retailProducts);
      if(discountTypeResponse?.length > 0)
      {
        this.SelectedPlayers = RetailDataAwaiters.reCalculateCustomFee(this.SelectedPlayers,discountTypeResponse);
      }
      let selectedProducts = await RetailDataAwaiters.GetSelectedProducts(this.SelectedPlayers,customFeeLinkedItemIds ?? []);
      selectedProducts.push(...retailProducts);
      const golfItems = RetailDataAwaiters.GetRetailItemsFromPlayers(this.SelectedPlayers);
      retailProducts?.forEach(x=>{
        golfItems.push(x);
      });
      if (RetailDataAwaiters.CheckPlayersHaveAnyPendingSettlements(this.SelectedPlayers)) { return; }
      if (!(await RetailDataAwaiters.CheckItemsAreFromSelectedOutlet(golfItems))) {
        this.SelectedPlayers = [];
        return;
      }
      selectedProducts?.forEach(selectedProduct=>
      {
        let selectedPlayer = this.SelectedPlayers?.find(x=> x.playerId == selectedProduct.sourceTypeId);
        if(selectedPlayer && selectedPlayer.playerCategoryId != playerTypes.member)
        {
          let response = discountTypeResponse?.find(x=> x.sourcetypeId == selectedProduct.sourceTypeId && x.itemId == selectedProduct.ItemId);
          if(response)
          {
            selectedProduct.DiscountTypeId = response.discountTypeId;
            if(response.isPercentage)
            {
              selectedProduct.DiscountPercentage = response.discountValue;
            }
            else
            {
              selectedProduct.Discount = response.discountValue;
            }
          }
        }
      });
      if (this.SelectedPlayers.length > 0 && selectedProducts.length > 0) {
        this.AddPlayersInHold(this.SelectedPlayers.map(x=> x.playerId)).then(resp => {
          if(resp == true)
          {
            this._retailService.isFromUnpaidPlayer = true;
            this._retailService.SelectedPlayers = this.SelectedPlayers;
            let firstPlayer = _.orderBy(this._retailService.SelectedPlayers, "playerSlotPosition", "asc");
            this._retailService.selectedPayeeId = firstPlayer[0].playerId;
            this._retailService.sourceCorpId = Number(this.SelectedPlayers.filter(x => x.playerCategoryId === PlayerCategory.Member)?.map(x => x.corpId)[0] ?? 0);
            this._retailService.selectedProducts = selectedProducts;
            this._retailService.SelectedDate = this.unpaidPlayersForm.get("date").value;
            this._retailService.SeletedCourseId = this.unpaidPlayersForm.get("courseSelect").value;
            this._retailService.memberCardNumber = "0";
            this._retailSharedService.isPromptedForTerminalSelect = false;
            this.router.navigate(['/shop/viewshop']);
          }
        });
      }
    }
    else {
      this.isToContinueWithOldCartItems = false;
      this._retailService.selectedProducts = this._retailSharedService.selectedProducts;
      this._retailService.isFromEditTeeTime = this._retailSharedService.isFromEditTeeTime;
      this._retailService.selectedPayeeId = this._retailSharedService.payeeId;
      this._retailService.sourceCorpId = Number( this.SelectedPlayers.filter(x => x.playerCategoryId === PlayerCategory.Member)?.map(x => x.corpId)[0] ?? 0);
      this._retailService.SelectedPlayers = this._retailSharedService.SelectedPlayers;
      this._retailSharedService.isPromptedForTerminalSelect = false;
      this.router.navigate(['/shop/viewshop']);
    }
    this._utilities.ToggleLoader(false);
  }

  isValidMember(rateTypeId, playerCategoryId): boolean {
    return (rateTypeId > 0 && (this.rateTypes.find(y => y.id == rateTypeId) || ({} as any)).guaranteeType == GuaranteeTypes.Member)
      || (playerCategoryId === 3);
  }
  emitteddatavalue(event) {
    if (event.checkedData.length > 0) {
      this.SelectedPlayers = event.checkedData;
      this.actionButton.disabledproperty = false;
    }
    else
      this.actionButton.disabledproperty = true;
  }

  reBindDate() {
    if (this.teeTimeDefaults.setPaidOnDayOfPlay) {
      this.dateInput.maxDate = this._PropertyInformation.CurrentDate;
      this.dateInput = { ...this.dateInput };
    }
  }

  async settingDefaultOutlet(defaultOutletId: number) {
    if (this._retailSharedService.selectedProducts.length > 0) {
      if (this._retailSharedService.SelectedOutletId != defaultOutletId) {
        let res = this._utilities.showAlert(this.localization.captions.teetime.differentOutletSelection, AlertType.Warning, ButtonType.YesNo, (result) => {
          if (result === AlertAction.YES) {
            this._retailSharedService.SelectedOutletId = defaultOutletId;
            this._retailSharedService.selectedProducts = [];
            this._retailSharedService.DeletePreviousPlayer = true;
            this._retailService.isFromEditTeeTime = false;
          } else {
            this.isToContinueWithOldCartItems = true;
            this._retailSharedService.DeletePreviousPlayer = false;
          }
        });
        await res.afterClosed().toPromise();
      }
    } else {
      this._retailSharedService.SelectedOutletId = defaultOutletId;
    }
  }
  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
}

export enum optionstypesenum {
  edit = 'edit',
  delete = 'delete',
  dragdrop = 'dragdrop',
  claim = 'claim',
  moreoption = 'moreoption'
}
export enum selecttypeenum {
  checkbox = 'checkbox',
  radiobutton = 'radiobutton',
  none = ''
}
