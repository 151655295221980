import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertMessagePopupComponent } from 'src/app/retail/shared/alert-message-popup/alert-message-popup.component';
import { debounceTime } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-retail-card-swipe-popup',
  templateUrl: './card-swipe-popup.component.html',
  styleUrls: ['./card-swipe-popup.component.scss']
})
export class CardSwipePopupComponent implements OnInit {

  noButton: boolean = false;
  IDTHiddenField:string;
  dataform:UntypedFormGroup;
  @ViewChild('cardfield') cardfield: ElementRef;
  inputSubscription$: any;
  floatLabel: string;
  captions: any;
  cardEntryModeGif: string;
  spinnerClass: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<AlertMessagePopupComponent>, private localization : Localization) {

    this.noButton = data.noButton ? data.noButton : false;
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions;
   }

  ngOnInit() {
    this.dataform= new UntypedFormGroup({
      carddata:new UntypedFormControl('')
      })
    this.inputSubscription$ = this.dataform.valueChanges.pipe(debounceTime(1000))
      .subscribe(val => {
        console.log(val);
        this.dialog.close(val.carddata);
      });
      this.cardEntryModeGif = this.data && this.data.isManualEntry ? './assets/images/CardKeyIn.gif' : './assets/images/Cardpayment.gif';
      this.spinnerClass = this.data && this.data.isManualEntry ? 'spinner-image-key-in' : 'spinner-image';
      if(this.data?.isRFID){
        this.cardEntryModeGif =  './assets/images/RFID_Scan.gif';
      }
  }

  ngAfterViewInit() {
    if (this.data.isHiddenFieldRequired &&  this.cardfield) {
      this.cardfield.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.inputSubscription$.unsubscribe();
  }

  DialogClose = (result) => {
    this.dialog.close(result);
  }
}
