import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionMode } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { RetailTaxGroupsBusiness } from '../retail-tax-groups.business';
import { ButtonValue, TaxGroups } from 'src/app/retail/retail.modals';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { AgToggleConfig, DropdownOptions } from 'src/app/common/Models/ag-models';
import { TaxType } from 'src/app/common/shared/shared/setupConstants';

@Component({
  selector: 'app-create-tax-groups',
  templateUrl: './create-tax-groups.component.html',
  styleUrls: ['./create-tax-groups.component.scss']
})

export class CreateTaxGroupsComponent implements OnInit {
  @Input() crudInput;
  captions;
  form: FormGroup;
  saveButton: ButtonValue;
  cancelButton: ButtonValue
  compoundTaxinput: AgToggleConfig;
  taxesoptions: DropdownOptions[];
  orderList: { id: number; value: string; compoundTax: boolean; }[];
  compoundTaxToggleValue: boolean = false;
  maxTaxGroupNameLength = TaxType.TAXTYPE_MAXLENGTH;

  @Output() handleClickEvent = new EventEmitter();
  
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private fb: FormBuilder, private localization: Localization,
    private business: RetailTaxGroupsBusiness, private utilities: RetailUtilities,
    private dialogRef: MatDialogRef<CreateTaxGroupsComponent>
  ) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {

    if (!this.crudInput) return;

    this.form = this.fb.group({
      taxgroupName: '',
      listOrder: '',
      compoundTax: '',
      taxes: '',
      orderofPriority: ""
    })
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.enableButton()
    });

    this.compoundTaxinput = {
      group: this.form,
      formControlName: 'compoundTax',
      label: this.captions.lbl_compoundTax
    }

    this.saveButton = {
      type: 'primary',
      label: this.crudInput.mode == ActionMode.create ? this.captions.btn_save : this.captions.btn_update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
    this.getTaxOptions();
    this.updateToggleState();
  }

   ngAfterViewInit() {
    this.utilities.ToggleLoader(true);
    if (this.crudInput?.form) {
      this.patchForm(this.crudInput.form)
    }
    this.getNextListOrder().finally(() => {
      this.utilities.ToggleLoader(false);
    });
  }

  enableButton() {
    this.saveButton.disabledproperty = !(this.form.valid && this.form.dirty && this.orderList?.length > 0);
    this.saveButton = { ...this.saveButton };
  }

  patchForm(form) {
    this.form.patchValue(form);
  }
  async onAction() {
    const taxGroup: TaxGroups = {
      id: this.crudInput.form ? this.crudInput.form.id : 0,
      name: this.form.value.taxgroupName,
      listOrder: this.crudInput.mode == ActionMode.create ? this.form.value.listOrder : this.crudInput.form.listOrder,
      isActive: this.crudInput.mode == ActionMode.create ? true : this.crudInput.form.isActive,
      taxGroupConfiguration: this.orderList?.map((item, index) => ({
        id: 0,
        taxGroupId: this.crudInput.mode == ActionMode.create ? 0 : this.crudInput.form.id,
        taxId: item.id,
        calculationOrder: index + 1,
        isCompoundTax: item.compoundTax,
      })) || [],
    };
    if (ActionMode.create == this.crudInput.mode) {
      await this.business.createTaxGroup(taxGroup).catch(ex => {
        if (!ex.valid) {
          this.utilities.showError(ex.message);
        }
      });
      
    }
    else if (ActionMode.update == this.crudInput.mode) {
      await this.business.UpdateTaxGroup(taxGroup).catch(ex => {
        if (!ex.valid) {
          this.utilities.showError(ex.message);
        }
      }
      );
    }

    this.handleClickEvent.emit({
      from: this.crudInput.mode == ActionMode.create ? ActionMode.create : ActionMode.update,
      form: this.form,
    });
    this.dialogRef?.close();
  }

  onCancel() {
    this.handleClickEvent.emit({
      from: ActionMode.cancel,
      form: this.form,
    });
  }

  toggleChange(e) {
    this.orderList.forEach(item => {
      item.compoundTax = e.checked;
    });
  }

  comoundTaxToggleChange(e) {
    this.updateToggleState();
    this.form.markAsDirty();
    this.enableButton();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.form.markAsDirty();
    moveItemInArray(this.orderList, event.previousIndex, event.currentIndex);
    this.setorderListinform();
    this.enableButton();
  }

  setorderListinform() {
    this.form.get('orderofPriority').setValue(this.orderList);
  }

  moveUp(i) {
    this.moveItem(this.orderList, i, 'up');
  }

  moveDown(i) {
    this.moveItem(this.orderList, i, 'down');
  }

  moveItem(arr: any[], fromIndex: number, direction: 'up' | 'down'): void {
    this.form.markAsDirty();
    const toIndex = direction === 'up' ? fromIndex - 1 : fromIndex + 1;

    if (toIndex >= 0 && toIndex < arr.length) {
      [arr[fromIndex], arr[toIndex]] = [arr[toIndex], arr[fromIndex]];
    }
    this.setorderListinform();
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async getTaxOptions() {
    this.taxesoptions = await this.business.getAllTaxes();
     await this.setOrderListValue();
  }

  onTaxesChange(e) {
    this.orderList = this.taxesoptions.filter((x) => e.value.includes(x.id)).map((x) => {
      const taxDetail = this.crudInput.form?.taxesObject.find(t => t.taxId == x.id);
      return {
        id: Number(x.id),
        value: x.value,
        compoundTax: taxDetail ? taxDetail.isCompoundTax : false
      };
    });
    this.enableButton();
    this.updateToggleState();
  }
  updateToggleState() {
    this.compoundTaxToggleValue = this.orderList?.length > 0 && this.orderList.every(item => item.compoundTax);
    this.form.controls["compoundTax"].setValue(this.compoundTaxToggleValue);
  }

  async setOrderListValue() {
    if (this.crudInput.form?.taxes) {
      this.orderList = this.crudInput.form?.taxesObject.map(tax => {
        const taxOption = this.taxesoptions?.find(x => x.id == tax.taxId);
        return {
          id: tax.taxId,
          value: taxOption.value,
          compoundTax: tax.isCompoundTax,
        };
      });
    } else {
      this.orderList = [];
    }
    await this.setorderListinform();
    this.updateToggleState();
    this.enableButton();
  }
  async getNextListOrder() {
    let nextListOrder = await this.business.GetNextListOrder();
    const value = this.crudInput.mode == ActionMode.create ? nextListOrder : this.crudInput.form.listOrder
    this.form.controls["listOrder"].setValue(value);
  }
}
