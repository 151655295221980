import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../localization/spa-localization';
import { DefaultSettings, Host } from "../../shared/globalsContant";
import { BaseResponse } from '../../shared/business/shared.modals';
import { NextId } from "../../retail/retail.modals";
import { UserSessionConfiguration } from "../core.model";
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { CommonApiRoutes, PropertyApiRoutes } from 'src/app/common/common-route';
import { DefaultUserConfigurationTenant, DefaultUserConfigurationTenantModel } from 'src/app/common/shared/shared.modal';
import { GenderSetup } from 'src/app/common/shared/core.model';

@Injectable()
export class UserMachineConfigurationService {
  userSessionConfigKey: string = 'userSessionConfigInfo';
  constructor(
    private http: HttpServiceCall,
    private utils: SpaUtilities,
    private localization: SpaLocalization,
    private _authenticationCommunication: TenantManagementCommunication
  ) { }

  public async getUserSessionConfiguration(userId: number): Promise<UserSessionConfiguration> {
    let result = await this.invokeUserSessionConfigurationCalls<UserSessionConfiguration>(
      "GetUserSessionConfiguration",
      HttpMethod.Get,
      undefined,
      { userId });
    if (result === null) {
      result = this.initializeUserSessionConfiguration(userId);
    }
    return result;
  }


  public async getDefaultSettings(): Promise<DefaultSettings[]> {
    let response: BaseResponse<DefaultSettings[]> = await this.http.CallApiAsync<DefaultSettings[]>({
      callDesc: 'GetSettingByModule',
      host: Host.spaManagement,
      method: HttpMethod.Get,
      uriParams: { module: 2 }
    });

    if (!response.successStatus) {
      this.showError(response.errorCode);
    }
    return response.result;
  }

  public async getGenders(tenantid :number ): Promise<GenderSetup[]>{
    const response = await this.http.CallApiAsync<any>({
      host: Host.authentication,
      callDesc: CommonApiRoutes.GetGenders,
      method: HttpMethod.Get,
      uriParams: { 
        tenantId : tenantid,
        includeInActive: false 
      }
    });
    
    return response.result;
  }
  initializeUserSessionConfiguration(userId: number): UserSessionConfiguration {
    return {
      id: 0,
      userId: userId,
      defaultOutletId: 0,
      defaultTerminalId: 0,
      defaultPaymentId: 0,
      defaultCourseId: 0,
      defaultPaymentDevice: '',
      defaultDeviceName: '',
      isIdtechSred: false,
      smallStickersPrinter: '',
      hangingTicketsPrinter: '',
      propertyId: 0
    }
  }

  public async createUserSessionConfiguration(body: UserSessionConfiguration): Promise<NextId> {
    let result = await this.http.CallApiAsync<NextId>({
      callDesc: "CreateUserSessionConfiguration",
      host: Host.retailManagement,
      method: HttpMethod.Post,
      body: body
    });
    return result.result;
  }

  public async updateUserSessionConfiguration(body: UserSessionConfiguration): Promise<UserSessionConfiguration> {
    try {
      return await this.invokeUserSessionConfigurationCalls<UserSessionConfiguration>("UpdateUserSessionConfiguration",
        HttpMethod.Put,
        body,
        { UserId: body.userId });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  private async invokeUserSessionConfigurationCalls<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
    let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
      callDesc: callDesc,
      host: Host.retailManagement,
      method: callType,
      body: body,
      uriParams: uRIParams
    });

    if (!response.successStatus) {
      this.showError(response.errorCode);
    }
    return response.result;
  }

  private showError(errorCode: number) {
    let errMsg = this.localization.getError(errorCode);
    this.utils.ShowErrorMessage("Error", errMsg);
  }

  public getLoggedinUserSessionConfiguration(): UserSessionConfiguration {
    if (sessionStorage.getItem(this.userSessionConfigKey) != null) {
      return {
        id: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'Id')),
        userId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'UserId')),
        defaultOutletId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultOutletId')),
        defaultCourseId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultCourseId')),
        defaultPaymentDevice: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultPaymentDevice'),
        defaultDeviceName: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultDeviceName'),
        isIdtechSred: Boolean(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'IsIdtechSred')),
        smallStickersPrinter: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'SmallStickersPrinter'),
        hangingTicketsPrinter: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'HangingTicketsPrinter'),
        propertyId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'PropertyId')),
        defaultPaymentId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultPaymentId'))
      }
    }
    return null;
  }

  // Test methods
  public log(message: string): void {
    const timeString: string = new Date().toLocaleTimeString();
    console.log(`${message} (${timeString})`)
  }

  error(message: string): void {
    console.error(`ERROR: ${message}`);
  }

  public async GetPaymentMethods(): Promise<any[]> {
    let result = await this.http.CallApiAsync<any[]>({
      callDesc: "PaymentMethod",
      host: Host.payment,
      method: HttpMethod.Get
    });
    return result.result;
  }

  public UpdateTenantDefaultUserConfiguration(defaultUserConfiguration: DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>) {
    return this._authenticationCommunication.putPromise({
      route: PropertyApiRoutes.UpdateDefaultTenantUserConfiguration,
      body: defaultUserConfiguration
    })
  }

  public GetTenantDefaultUserConfiguration(configName: string, propertyId: number, productId: number, userId: string): Promise<DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>> {
    return this._authenticationCommunication.getPromise({
      route: PropertyApiRoutes.GetDefaultTenantUserConfiguration,
      uriParams: { "configurationName": configName, "propertyId": propertyId, "productId": productId, 'userId': userId }
    })
  }
}
