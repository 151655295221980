import { Guid } from "guid-typescript";

export enum TeeTimePriceType {
    FullPrice,
    CustomPrice
}
export enum ChargeType {
    Percentage,
    Amount
}
export enum ScheduleType {
    AtTimeOfBooking=1,
    PriorToArrival
}
export enum ApplyWithinType{
    Days = 0,
    Hours
}

export interface DataPayload{
    type: SubjectDataType;
    data: any;
    errorMessage?: string;
}

export interface MappingDataPayload{
    type: MappingSubjectDataType;
    data: any;
    errorMessage?: string;
}

export enum SubjectDataType{
    GetPolicy,
    SendPolicy,
    CreatePolicy,
    UpdatePolicy,
    DeletePolicy,
    UpdateActive,
    APIResponse,
    DeleteAPIResponse
}

export enum MappingSubjectDataType{
    SearchMappingRequest,
    SearchMappingResponse,
    GetPolicyMapping,
    SendPolicyMapping,
    CreatePolicyMapping,
    UpdatePolicyMapping,
    DeletePolicyMapping,
    APIResponse,
    DeleteAPIResponse   
}

export enum GuestCategory{
    ExistingClient,
    Member = 2,
    HotelReservation
}

export interface GuestType {
    Id : number
    Code : string
    Name : string
    Description : string
    ListOrder : number
    IsActive : boolean
}

export interface DepositPolicy{
    id: number;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
    depositPolicyDetails: DepositPolicyDetails[];
}

export interface DepositPolicyDetails{
    id: number;
    poicyId: number;    
    includeCharges: string;    
    scheduleType: number;
    numberOfDays: number;
    isFullPrice: boolean;
    isPercentage: boolean;
    charge: number;
}

export interface DepositPolicyBaseDetails{
    id: number;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
}

export interface DepositPolicyMapping{
    id: number,
    policyId: number,
    startDate: string,
    endDate: string,    
    guestType: string,
    guestCategory: number,
    mappingGroupId: string,
    policyMapping: string
}

export interface DepositPolicyMappingBaseDetails{
    policyId: number,
    name: string,
    code: string,
    guestType: string,
    guestCategory: number[],
    startDate: string,
    endDate: string,
    policyMapping: string,
    isActive: boolean,
    mappingGroupId: Guid
}

export interface DepositPolicyMappingTableData{
    id: number,
    name: string,
    code: string,
    applyTo: string,
    dateRange: string,
    mappingGroupId: Guid,
    isInActive: boolean,
    dateSort?: number
}

export interface ServiceGroupsWithServices{
    id: number,
    name: string,
    selected: boolean,
    services: ServiceBaseDetails[]
}

export interface ServiceBaseDetails{
    id: number,
    groupId: number,
    code: string,
    name: string,
    selected: boolean
}


export interface MainGroup {
    id: number;
    name: string;
    selected: boolean;
    subGroup: SubGroup[];
    isSubGroupSelected?: boolean;
}
export interface SubGroup {
    id: number;
    name: string;
    selected: boolean;
}