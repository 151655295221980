export enum UserAccessBreakPoints {
  ORIGINCODES = 50005,
  ROOMLOCATION = 50440,
  OTACODE = 50125,
  KEYENCODERSTATION = 50105,
  CANCELLATIONCODE = 20135,
  CANCELLATIONPOLICY = 20085,
  BILLINGCODES = 50035,
  CONTACTTYPE = 50045,
  CURRENCYCODE = 50095,
  CLASSES = 50025,
  COMTROLPOSTTYPES = 50100,
  VideoCheckout = 85140,
  ComtrolGeneral = 85120,
  KeyLockInterface = 85135,
  PREFERENCEDETAIL = 50140,
  ROOMFEATURE = 50435,
  ORIGIN = 50005,
  NEWSPAPERTYPE = 50235,
  SEGMENT1 = 50010,
  SEGMENT2 = 50015,
  CREDITCARDTERMINALS = 50480,
  COMPLAINTCODE = 50220,
  TARIFFCODE = 50280,
  INDUSTRYCODE = 50130,
  MARKETSEGMENT = 50020,
  REGION = 50030,
  DEPOSITMETHODS = 50500,
  CHECKLISTCODE = 50150,
  CHECKLISTDETAIL = 50155,
  ARRIVALTYPE = 50040,
  GUESTTYPE = 50000,
  BUILDING = 50425,
  DENYANDLOSTCODE = 50250,
  GUESTSPECIALREQUIREMENT = 50210,
  MARKETCHANNELTYPE = 50170,
  POSTINGREVENUEBUCKET = 50180,
  PREFERENCECATEGORY = 50135,
  PSTCODE = 50115,
  SPECIALGUARANTEECODE = 50215,
  VIPTYPE = 50265,
  KEYLOCKACCESS = 50335,
  PAYMENTMETHOD = 50465,
  TARIFFGROUP = 50285,
  POTENTIALCODE = 50275,
  COMPETITORPROFILE = 50276,
  ROOMTYPE = 50430,
  OTAFEEMAINTENANCE = 50260,
  USERSETUP = 2300,
  USERROLESETUP = 2305,
  CLASSACCESS = 2310,
  ROOMTYPEGROUP = 50455,
  DAILYRESTRICTION = 50310,
  ROOMDEFINITION = 50450,
  FISCALPERIOD = 50475,
  PREFERENCEEXCLUSION = 50145,
  ROOMUPGRADEMAINTAINANCE = 50375,
  PERPERSONINCREMENT = 50300,
  TAXTYPE = 50470,
  PROPERTYINFORMATION = 85005,
  GUESTMESSAGE = 55045,
  BATCHPRINTLUGGAGETAG = 60060,
  ROOMRACK = 60055,
  MAXRACK = 60050,
  USERDEFINEDFIELDS = 50510,
  POSTTYPE = 50460,
  DEPARTMENT = 50520,
  CATEGORY = 50525,
  ROOMRATE = 50290,
  SYSTEMPASSCODE = 50530,
  DEPOSITPOLICY = 50515,
  SETSTATUSANDCONDITION = 60010,
  SETCONDITION = 60005,
  SETOUTOFORDER = 60015,
  ROOMCHANGE = 60020,
  STAYDEFAULTS = 85060,
  ROOMANDHOUSEKEEPINGDEFAULTS = 85065,
  CardTemplate = 50535,
  SEARCHRESERVATION = 55020,
  CREATERESERVATION = 55025,
  CONFIGURE_FIELDS = 55120,
  CHECKOUTRESERVATION = 55125,
  CreateGuest = 55100,
  UpdateGuest = 55105,
  SearachGuest = 55095,
  ASSIGNROOM = 55030,
  COPYSTAY = 55060,
  TRANSFERSTAY = 55070,
  ASSIGNTELEPHONEEXTENSION = 55170,
  TASK = 55035,
  AUTOASSIGN = 55040,
  CONTACTRELATIONSHIPMAINTENANCE = 50277,
  GUESTCONTACT = 55110,
  FOLIO = 90005,
  FOLIOPAYMENTMETHOD = 90010,
  FOLIOROUTING = 90015,
  ADDCHARGES = 90020,
  CREDITCARDINTERFACE = 60065,
  TRANSFERPOSTING = 90025,
  CHANGEFOLIO = 90030,
  CORRECTADJUSTMENTPOSTING = 90035,
  PRIORITYROOMASSIGNMENT = 55075,
  GENERALAVAILABILITY = 55015,
  ROOMCHARGEALLOCATION = 60025,
  HOUSESTATUS = 55010,
  COMBINEGUESTRECORDS = 65085,
  DEDUPEGUESTPROFILES = 12120,
  OTAINTERFACEMONITOR = 65010,
  TRANSFERAMOUNT = 90040,
  TRANSFERBALANCE = 90045,
  RATEADJUSTMENTS = 90050,
  OTAGENERALCONFIG = 85010,
  OTAHOTELCODES = 85015,
  OTAROOMTYPES = 85020,
  OTARATEPLAN = 85025,
  OTARATEPLANBYROOMTYPE = 85030,
  OTAFLOORLIMITS = 85035,
  OTACODEROUTING = 85040,
  OTAPERPERSONSURCHARGE = 85045,
  OTAGROUPROUTING = 85050,
  OTACONNECTIONSETUP = 85080,
  OTAARIRESYNC = 85055,
  ADDITIONALNAMES = 55085,
  RESERVATIONCANCELLATIONPOLICY = 50540,
  SETRECURRNGCHARGE = 55135,
  DASHBOARD = 55055,
  DASHBOARDADR = 55145,
  DASHBOARDTOTALROOMREVENUE = 55155,
  DASHBOARDREVPAR = 55150,
  MANAGERADJUSTMENT = 90055,
  RATEAVAILABILITYCALCULATOR = 55005,
  GUESTCOMPLAINTS = 55115,
  TELEPHONEEXTENSION = 50385,
  LINKRESERVATIONS = 55140,
  WAKEUPCALL = 55090,
  GUESTLETTERS = 50320,
  STAYLETTERS = 50325,
  Segment = 100040,
  OriginGuestType = 100045,
  DETAILEDLOGVIEWER = 55185,

  SHARESTAY = 55065,
  ATTACHCOMPTEMPTORESERVATION = 55160,
  PETPOLICY = 85075,
  PETLOGIC = 55175,
  BATCHCHECKIN = 60045,
  NOSHOWREPORT = 100035,
  DENYLOST = 100030,
  Role = 75020,
  Shift = 75025,
  Workarea = 75030,
  Staff = 75035,
  Schedule = 75040,
  Location = 75045,
  TaskCategory = 75050,
  Workgroup = 75055,
  Task = 75060,
  TAGTEMPLATE = 65095,
  DAILYBUDGET = 6000,
  MONTHLYBUDGET = 6002,
  MANAGERBUDGET = 6001,
  SALESCALLTYPES = 6003,
  SALESCALLNEXTSTEPS = 6004,
  BOOKINGLETTER = 170495,
  ACCOUNTLETTER = 6007,
  SALESCALLLETTER = 6009,
  INQUIRYDENIALLETTER = 6008,
  CATERINGMANAGER = 6010,
  CONFERENCEMANAGER = 6011,
  SALESMANAGER = 6012,
  DISTRIBUTIONLISTCODES = 6005,
  MODIFYRESERVATION = 55050,
  CONFIGUREPROFORMAINVOICE = 6013,
  //booking
  BOOKINGSEARCH = 170280,
  CREATEBOOKING = 170285,
  EDITBOOKING = 170290,
  MOVEBOOKING = 170295,
  CLOSEBOOKING = 170300,
  COPYBOOKING = 170305,
  TRANSFERBOOKING = 170310,
  GROUPRESUME = 170315,
  DELETEBOOKING = 170320,
  INQUIRYDENYBOOKING = 170325,
  ADDROOMBLOCK = 170330,
  EDITROOM = 170335,
  DELETEROOM = 170340,
  DELETEROOMTYPE = 170345,
  DELETEDAY = 170350,
  BOOKINGTASK = 170355,
  BOOKINGCOMMENT = 170360,
  BOOKINGCONTACT = 170365,
  DELETEFUNCTION = 170370,
  PARENTROOMBLOCK = 170375,
  RATES = 170380,
  COMMENTTEMPLATE = 170410,
  SLEEPINGROOMTEMPLATE = 170415,
  BOOKINGTEMPLATE = 170420,
  SALESMANAGEREQUIVALENCYGROUP = 170445,
  SALESMANAGEREQUIVALENCIES = 170450,
  SETGROUPRESERVATIONSDEFAULTS = 170440,
  ACCOUNT = 170250,
  NEGOTIATEDCOMPANYRATE = 170255,
  ACCOUNTSEARCH = 170260,
  CONTACT = 170265,
  CLOSERANGEOFBOOKING = 170395,
  CANCELBOOKING = 170435,
  DENIALCODE = 170455,
  RESERVEDBYCODES = 170430,
  ACCOUNTCOMBINE = 170385,
  ACCOUNTNUMBERCONVERSION = 170405,
  CUSTOMHOUSEKEEPING = 75065,
  CUSTOMHOUSEKEEPINGSERVICE = 75070,
  STANDARDRESERVATIONGROUPUPLOAD = 170075,
  GROUPCHECKOUT = 170030,
  GROUPMASTERFOLIOROUTING = 170045,
  CHECKINGROUPMASTERFOLIO = 170050,
  GROUPRESERVATIONSDEFAULTS = 170060,
  SINGLEROOMPICKUPS = 170065,
  BATCHROOMPICKUPS = 170070,
  PRINTBOOKINGSHEET = 170085,
  BOOKINGACCOUNTLOG = 170105,
  MASTERFOLIOHISTORY = 170115,
  OUTOFBLOCKRESTRICTIONS = 170145,
  GROUPMEMBERLIST = 170150,
  LOGGEDCHANGESOUTSIDEBLOCK = 170155,
  OVERSELL = 170160,
  EDITSALESCALL = 170270,
  GENERATEBOOKINGLETTER = 170495,
  RELEASEUNRELEASEREMAININGROOMS = 170460,
  SETBLACKOUTDATES = 170465,
  PRINTSOFTPICKUPREPORT = 170470,
  GENERATEACCOUNTLETTER = 170480,
  GENERATEINQUIRYLETTER = 170485,
  GENERATESALESCALLLETTER = 170490,
  VISUALGRC = 170475,
  MASTERFOLIODETAILS = 100715,

  //Guest Services
  VEHICLEMAINTENANCE = 160005,
  VEHICLEASSIGNMENT = 160020,
  CONCIERGECATEGORY = 160040,
  AIRLINECODES = 160035,
  AIRPORTDESTINATION = 160050,
  AIRPORTBUSTRANSFERSYSTEM = 160000,
  CLASSSERVICECODE = 160030,
  TRAVELAGENCYCOMMISSION = 170520,
  CONCIERGESUBCATEGORY = 160095,
  ROOMCHART = 55190,
  VEHICLETYPE = 160060,
  RATECODE = 160075,
  DRIVERCODE = 160055,
  DRIVERASSIGNMENT = 160015,
  PROVIDERCODE = 160070,
  PICKUPDROPOFFCODE = 160065,
  VEHICLETIMETABLE = 160045,
  TERMINALCODE = 160085,
  VEHICLECODES = 160090,
  SPECIALARRANGEMENTCODE = 160080,
  HolidayHotDates = 170525,
  GENERATESALESCALLSLETTER = 170530,
  CONCIERGE = 160025,

  // NIGHT AUDIT
  AUTONIGHTAUDIT = 80015,


  //OVERVIEW
  NIGHTAUDITOVERVIEW = 80010,
  TASKTEMPLATEMAPPING = 80020,
  EMERGENCYREPORT = 80025,
  ROOMMOVE = 80010,

  TRANSPORTATIONRATE = 160010,
  TRANSPORTATIONINFORMATION = 160100,

  //COMP ACCOUNTING
  POSTTYPECOMPORDER = 150045,
  RANKCODEMANAGEMENT = 150010,
  OFFERCODEMANAGEMENT = 150005,
  COMPTEMPLATESETUP = 150030,
  LOSTANDFOUNDSTATUS = 65115,
  MEALPERIOD = 65120,
  PACKAGEDISCOUNT = 50430,

  //Comtrol
  DEVICECLASSSETUP = 85085,
  DEVICECLASSANDVENDORMAPPING = 85090,
  HOUSEKEEPINGCODEMAPPING = 85110,
  ACCESSLEVELSETUP = 85095,
  ACCESSAREASETUP = 85100,
  ACCESSLEVELANDAREAMAPPING = 85105,
  GENERALCONFIGURATION = 85120,
  KEYLOCKINTERFACE = 85135,
  DIALCODESETUP = 85110,
  VIDEOCHECKOUT = 85140,
  COMPACCOUNTINGSETUP = 150040,
  DIALINCODESETUP = 85145,

  //Maintenance Setup
  MaintenanceEquipment = 70045,
  MaintenanceCategories = 70040,
  //Reservation Package Availability
  CHECKAVAILABILITYPACKAGE = 55195,
  LOSTANDFOUND = 75075,
  CONTACTSYNC = 170500,
  TASKSYNC = 55205,
  GUESTSYNC = 55210,
  MANITENANCEVENDORSETUP = 70020,
  Asset = 70005,
  Part = 70010,
  PREVENTATIVEMAINTENANCEDEFAULTS = 70025,
  CLERKREPORTGROUPING = 12345,
  PREVENTATIVEMAINTENANCE = 70030,
  RoomSoldGraph = 170505,
  PLAYERWORTH = 150050,

  //MOD Assignment
  MODAssignment = 65610,

  
  PRINTERDEFAULTCONFIGURATION = 15065,

  //DataMagine
  DATAMAGINE_CONFIGURATION = 85160,
  DATAMAGINE_SETTINGS = 85165,
  DOCUMENTCODES = 85175,
  DOCUMENTTYPES = 85170,

  ADMINTEGRATIONSETUP = 12085,
  DATAMAGINE_GENERATE_EFORM = 20785,
  ProjectJobCode = 190260,
  MonthEndProcessing = 190295,
  FinancialReportAutoPrint=190355,

  //Authorize Integration
  AUTHORIZE_CONFIGURATION = 12110,
  PLAYERPOINTS = 150055,
  PLAYERCOMPS = 150060,

}
