import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { SpaDepositPolicyService } from '../spa-add-deposit/spa-add-deposit.service';
import { ReplaySubject } from 'rxjs';
import { DepositPolicySharedService } from 'src/app/common/shared/shared/deposit-policy/deposit-policy-shared';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { takeUntil } from 'rxjs/operators';
import { MappingSubjectDataType } from 'src/app/common/shared/shared/deposit-policy/deposit-policy.model';
import { Guid } from 'guid-typescript';
import { SPAScheduleBreakPoint } from 'src/app/shared/globalsContant';

@Component({
  selector: 'app-deposity-policy-mapping-wrapper',
  templateUrl: './deposity-policy-mapping-wrapper.component.html',
  styleUrls: ['./deposity-policy-mapping-wrapper.component.scss'],
  providers: [SpaDepositPolicyService]
})
export class DeposityPolicyMappingWrapperComponent implements OnInit {
  
  captions: any;
  guestCategoryOptions = [];
  guestTypeOptions = [];
  serviceGroupArray = [];
  productId  = GlobalConst.Product.SPA;
  searchPlaceholder: any;
  tableOptions;
  tableContent = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isViewOnly: boolean = false;
  

  constructor(private localization: Localization, private spaDepositPolicyService : SpaDepositPolicyService,
    private depositPolicySharedService : DepositPolicySharedService, private utils: SpaUtilities, 
    private _userAccessBusiness: UserAccessBusiness
  ) {
    this.captions = this.localization.captions;
   }

  async ngOnInit(): Promise<void> {
    this.getTableOptions();
     await this._userAccessBusiness.getUserAccess(SPAScheduleBreakPoint.DepositPolicyMapping).then(
        x => this.isViewOnly = x.isViewOnly
      );

    this.depositPolicySharedService.mappingData$.pipe(takeUntil(this.destroyed$)).subscribe(async payload => {
          if(payload != null){
            switch (payload.type) {
              case MappingSubjectDataType.GetPolicyMapping:
                await this.getDepositPolicyMapping(payload.data);
                break;
              case MappingSubjectDataType.CreatePolicyMapping:
                await this.createDepositPolicyMapping(payload.data);
                break;
              case MappingSubjectDataType.UpdatePolicyMapping:
                await this.createDepositPolicyMapping(payload.data, true);
                break;
              case MappingSubjectDataType.DeletePolicyMapping:
                await this.deleteDepositPolicyMapping(payload.data);
            }
          }
        })

    this.searchPlaceholder = this.captions.lbl_searchByServiceGroup;
    this.guestCategoryOptions = this.spaDepositPolicyService.getGuestCategories();
    this.guestTypeOptions = await this.getAllGuestTypes();
    this.serviceGroupArray = await this.getServiceGroupsWithService();
    this.tableContent = await this.getTableContent();
    this.assignTableBodyData(this.tableContent);
    this.getAllDepositPolicy();  
    this.getAllGuestTypes(); 
   
  }
  getTableOptions() {
    let arrDataHeader = [
      { 'title': this.captions.tbl_hdr_policyCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
      { 'title': this.captions.tbl_hdr_policyName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
      { 'title': this.captions.lbl_applyTo, 'jsonkey': 'applyTo', 'searchable': false, 'sortable': true },
      { 'title': this.captions.tbl_hdr_dateRange, 'jsonkey': 'dateRange', 'searchable': false, 'sortable': true,'sortcolumn':'dateSort','sortcolumndatatype': 'number'},
      { 'title': this.captions.tog_lbl_active, 'jsonkey': 'isInActive', 'searchable': false, 'sortable': false, "type": "toggle" }
    ]
    this.tableOptions = [
      {
        TableHdrData: arrDataHeader,
        TablebodyData: this.tableContent,
        pagination: false,
        sortable: true,
        editable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.lbl_searchByPolicyCodeName,
        EnableActions: true,
        SelectRows: false,
        Searchable: true,
        EditMoreOption: false,
        Sortable: 'code',
        TableId: '',
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: '',
        waitlistIcon: false,
        TableDraggable: false,
        sticky: false,
        DoneCancel: false,
        IsViewOnly: true,
        disableEditButton: false,
        isCopyEnabled : false,
        ServiceId : 'viptype'
      }
    ];
  }

    async getTableContent(){
      var mappingData = await this.spaDepositPolicyService.getAllDepositPolicyMappings(true)
      return this.spaDepositPolicyService.mapMappingData(mappingData);
    }

    async getServiceGroupsWithService(){
      return await this.spaDepositPolicyService.getAllServicesGroupsWithServices(false);
      }

     async getAllDepositPolicy(){
        var response = await this.spaDepositPolicyService.getAllDepositPolicies(false);
        var policiesData = this.spaDepositPolicyService.policyMapper(response);
        this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.SearchMappingResponse, policiesData);
      }

    async getAllGuestTypes() {
      var response = await this.spaDepositPolicyService.getAllGuestTypes(false);
      return this.spaDepositPolicyService.policyMapper(response);
    }

    async getDepositPolicyMapping(groupId: Guid){
        var result = await this.spaDepositPolicyService.getDepositPolicyMapping(groupId);
        this.guestCategoryOptions = this.spaDepositPolicyService.updateGuestCategories(this.guestCategoryOptions, result.guestCategory);
        this.spaDepositPolicyService.mapServiceAndServiceGroup(this.serviceGroupArray, result.policyMapping);
        this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.SendPolicyMapping, result);
      }

      async createDepositPolicyMapping(mappingData: any[], isUpdate: boolean = false) {
        let errorMessage = '';
        this.utils.ToggleLoader(true);
        let mappingDataobj = await this.spaDepositPolicyService.formObjectForPolicyMapping(mappingData);
        var response = await this.spaDepositPolicyService.createOrUpdateDepositPolicyMapping(mappingDataobj, isUpdate);
        this.utils.ToggleLoader(false);
        if (response.errorCode == 59)
          errorMessage = this.localization.replacePlaceholders(this.localization.getError(response.errorCode), ['policyName'], [response.errorDescription]);
        this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.APIResponse, response.errorCode, errorMessage);
        var result = this.spaDepositPolicyService.mapMappingData(response.result);
        this.assignTableBodyData(result);
      }

       async deleteDepositPolicyMapping(groupId: Guid){
          this.utils.ToggleLoader(true);
          var response = await this.spaDepositPolicyService.deleteDepositPolicyMapping(groupId);
          this.utils.ToggleLoader(false);
          this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.DeleteAPIResponse, response.errorCode);
          var result = this.spaDepositPolicyService.mapMappingData(response.result);
          this.assignTableBodyData(result);
        }

      assignTableBodyData(data: any){
        var tableData = [...this.tableOptions]
        tableData[0].TablebodyData = data;
        tableData[0].IsViewOnly = this.isViewOnly;
        this.tableOptions = tableData;
      }

      ngOnDestroy(){
        this.depositPolicySharedService.clearSendMappingData();
        this.destroyed$.next();
        this.destroyed$.complete();
      }

}

