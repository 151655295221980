
import { Injectable } from "@angular/core";
import { RetailEventParameters, VoidEventModel, TicketChangeEventModel, DepositEventModel, CancelDepositEventModel, CancellationNoShowChargeEventModel, DayPassEventModel, CancelDayPassEventModel, IClassCheckOutEventModel, UpdateCancellationNoShowChargeEventModel, UpdateAppointmentFeeEventModel } from "../../../retail/shared/events/event.model";
import { RetailEventHandler } from "../retailevent-handler";
import { DepositAPIModel, AppointmentCancel, DayPassModel, ClassDepositAPIModel, ClassClientCancel } from "../../business/shared.modals";
import { LinkedRetailItemsData } from "src/app/retail/shop/shop.modals";
import { AppointmentRetailItem } from "src/app/appointment/slide-information/slide-information.component";
import { CustomFeeSourceType } from "src/app/retail/shared/globalsContant";

@Injectable({
    providedIn: "root"
})
export class SubscriptionHandler {
    constructor(private _handleRetailEvents: RetailEventHandler) {

    }

    public async handleVoid(eventParams: RetailEventParameters<VoidEventModel>) {
        const data: VoidEventModel = eventParams.data;
        if (data.isDeposit) {
            if (data.isDeposit) {
                if (data.sourceType === CustomFeeSourceType.Classes) {
                    this._handleRetailEvents.RevertClassDeposit(data.transactionId);
                } else {
                    this._handleRetailEvents.RevertDeposit(data.transactionId);
                }
            }
        }
        else {
            if (data.sourceType === CustomFeeSourceType.Classes) {
                this._handleRetailEvents.UndoClassClientAppointment(data.transactionId);
            } else {
                this._handleRetailEvents.UndoAppointment(data.transactionId);
            }
        }
    }

    public async  handleReOpen(eventParams: RetailEventParameters<TicketChangeEventModel>) {
        let data = eventParams.data;
        this._handleRetailEvents.UpdateAppointmentTransaction(data.oldTransactionId, data.transactionId, data.transactionDetail,data.returnData);
    }

    public async handleCancelDeposit(eventParams: RetailEventParameters<CancelDepositEventModel[]>) {
        let deposit: CancelDepositEventModel[] = eventParams.data;
        if (!deposit || deposit.length == 0) {
            return;
        }
        if(deposit.every(data=> data.customFeeSourceType === CustomFeeSourceType.Classes)){
            let cancelClassAppointment: ClassClientCancel[] = [];
            deposit.forEach(r => {
                cancelClassAppointment.push({
                    classClientId: r.id,
                    cancelComments: r.cancelComments ?? '',
                    cancelReasonId: r.cancelReason,
                    depositRefundAmount: deposit.reduce((a, b) => a + b.depositRefundAmount, 0),
                })
            });
            this._handleRetailEvents.CancelClientDeposit(deposit[0].transactionId, cancelClassAppointment.length >0 ? cancelClassAppointment[0]: null);
        }else{
            let cancelAppointment: AppointmentCancel[] = [];
            deposit.forEach(r => {
                cancelAppointment.push({
                    id: r.id,
                    cancelComments: r.cancelComments,
                    cancelFee: r.cancelFee,
                    cancelReason: r.cancelReason,
                    depositRefundAmount: r.depositRefundAmount,
                    canPerformCancel: r.canPerformCancel
                })
            });
            this._handleRetailEvents.CancelAppointmentDeposit(deposit[0].transactionId, cancelAppointment);
        }
    }

    public async handleCancelDayPass(eventParams: RetailEventParameters<CancelDayPassEventModel[]>) {
        let deposit: CancelDayPassEventModel[] = eventParams.data;
        if (!deposit || deposit.length == 0) {
            return;
        }
        let cancelAppointment: AppointmentCancel[] = [];
        deposit.forEach(r => {
            cancelAppointment.push({
                id: r.id,
                cancelComments: r.cancelComments,
                cancelFee: r.cancelFee,
                cancelReason: r.cancelReason,
                depositRefundAmount: r.depositRefundAmount,
                canPerformCancel: r.canPerformCancel
            })
        });
        this._handleRetailEvents.UpdateDayPassIdInAppointment( cancelAppointment);
    }

    public async handleCancellationNoShowCharge(eventParams: RetailEventParameters<CancellationNoShowChargeEventModel[]>) {
        let chargeData: CancellationNoShowChargeEventModel[] = eventParams.data;
        this._handleRetailEvents.updateAppointmentWithcharge(chargeData);
    }

    public async handleCancellationNoShowChargeWaiveOff(eventParams: RetailEventParameters<number>){
        let appointmentId: number = eventParams.data;
        this._handleRetailEvents.updateCancellationNoShowFeeWaiveOff(appointmentId);
    }

    public async handleAppointmentRetailItem(eventParams: RetailEventParameters<LinkedRetailItemsData>){
      let appointmentRetailItem: AppointmentRetailItem = {
        appointmentId : eventParams.data.sourceTypeId,
        id : 0,
        linkedRetailItemId: eventParams.data.id,
        retailItems: eventParams.data.linkedRetailItemDetails

      }
      this._handleRetailEvents.CreateUpdateDeleteAppointmentRetailItem(appointmentRetailItem);
    }
    
    public async handleDayPass(eventParams: RetailEventParameters<DayPassEventModel>){
        let dayPassModel: DayPassModel = {
            transactionId:eventParams.data.transactionId,
            transactionDetailIds:eventParams.data.transactionDetailsIds,
            id:eventParams.data.sourceTypeId,
            paidAmount:eventParams.data.amount,
            status:eventParams.data.status
        }
        this._handleRetailEvents.UpdateTransactionForDayPass(dayPassModel);
      }

    public async handleDeposit(eventParams: RetailEventParameters<DepositEventModel[]>) {
        let deposit: DepositEventModel[] = eventParams.data;
        if (!deposit || deposit.length == 0) {
            return;
        }
        
        const isClassDeposit = deposit.every(d => (d.sourceType ?? 0) === CustomFeeSourceType.Classes);
        if(isClassDeposit){
            let classDepositModel: ClassDepositAPIModel[] = [];
            deposit.forEach(r => {
                classDepositModel.push(
                    {
                        id: r.id,
                        classClientId: r.sourceTypeId,
                        amountPaid: r.amount,
                        serviceCharge: r.serviceCharge,
                        gratuity: r.gratuity,
                        isVoided: false,
                        depositTransactionId: r.depositTransactionId,
                        depositTransactionDetailId: r.depositTransactionDetailId,
                        refundAmount: 0,
                        refundTransactionId: 0,
                        transactionStatus: r.status
                    }
                )
            });
            this._handleRetailEvents.AddClassClientDeposit(classDepositModel);
        }else{
            let depositModel: DepositAPIModel[] = [];
            deposit.forEach(r => {
                depositModel.push(
                    {
                        id: r.id,
                        appointmentId: r.typeId,
                        amount: r.amount,
                        serviceCharge: r.serviceCharge,
                        gratuity: r.gratuity,
                        isVoided: false,
                        depositTransactionId: r.depositTransactionId,
                        depositTransactionDetailId: r.depositTransactionDetailId,
                        refundAmount: 0,
                        refundTransactionId: 0,
                        status: r.status,
                        retailItemId: r.retailItemId,
                        packageGroupId: r.packageGroupId,
                        typeMappingId: r.typeMappingId
                    }
                )
            });
            this._handleRetailEvents.AddDeposit(depositModel);
        }
    }

    public async handleClassCheckOut(eventParams: RetailEventParameters<IClassCheckOutEventModel[]>){
        this._handleRetailEvents.handleClassCheckOut(eventParams.data);
    }

    public async handleUpdateCancellationNoShowCharge(eventParams: RetailEventParameters<UpdateCancellationNoShowChargeEventModel>){
        this._handleRetailEvents.updateCancellationNoShowCharge(eventParams.data);
    }

    public async handleCorrect(eventParams: RetailEventParameters<TicketChangeEventModel>) {
        let data = eventParams.data;
        this._handleRetailEvents.UpdateAppointmentAfterCorrection(data.oldTransactionId, data.transactionId, data.transactionDetail, data.ticketNumber);
    }

    public async handleUpdateAppointmentFee(eventParams: RetailEventParameters<UpdateAppointmentFeeEventModel>)
    {
        let data=eventParams.data;
        this._handleRetailEvents.UpdateAppointmentFee(data.appointmentIds,data.status);
    }
}
