import { Injectable } from '@angular/core';
import { BaseResponse, ClientInfo, LocationAvailability, TherapistAvailability, TherapistMaxServiceDetail, LocationViewModel, TherapistViewModel, GroupAppointmentSearchModel, UserBreakPoint, Service, ServiceAvailableDays, ServiceEquipmentMaintenance, GenderOverrideMessage, AppointmentFilterOutput, ServiceViewModel, EquipmentQuantityDetails } from 'src/app/shared/business/shared.modals';
import * as _ from 'lodash';
import * as GlobalConst from '../../shared/globalsContant';
import {
  AppointmentAvailable,
  AutoBookSet,
  CustomFieldType,
  Days,
  DistributionSetup,
  FilterData,
  GroupActivity,
  GroupAppointment,
  GroupAppointmentsRequestModel,
  GroupDetail,
  GroupList,
  KeyValuePair,
  LocationAPI,
  NegotiatedPrice,
  Options,
  ServiceGroupAPI,
  SetClientDetail,
  TherapistDetailAPI,
  WizardAutoBookTempAppointmentRequest,
  WizardAutoBookTempAppointmentResponse,
  WizardGroupAppointmentRequest,
  WizardGroupAppointmentResponse,
  WizardTempAppointmentRequest,
  SetDetails,
  WizardTempAppointmentResponse, CustomField as CF,
  PMSGroupDetail
} from './spa-wizard.modal';
import { CustomField, LinkCode, PackageInfo } from 'src/app/shared/business/view-settings.modals';
import { CustomFieldKey } from 'src/app/common/shared/shared/globalsContant';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Host, ReportBreakPoint, SPAManagementBreakPoint, SPAScheduleBreakPoint } from 'src/app/shared/globalsContant';
import { HttpMethod, HttpServiceCall, KeyValuePair as HttpKeyValuePair } from 'src/app/shared/service/http-call.service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { Subject } from 'rxjs';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { GuaranteeMethodJSONModel } from 'src/app/shared/appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ClientLabel } from 'src/app/shared/business/new-booking.model';

@Injectable()
export class SpaWizardService {
  EnableServicePanels = false;
  EnableOverAllSave = false;
  selectedClients: ClientInfo[] = [];
  clientsArr: any=[];
  addedSets = [];
  selectedSet: AutoBookSet;
  selectedClientsClone = [];
  clientSectionFilters = {};
  autoBook = false;
  packageGroupList: GroupList[];
  serviceGroupList: GroupList[];
  autoBookWizardArray: AppointmentAvailable[] = [];
  wizardArray: AppointmentAvailable[] = [];
  activeServices: AppointmentAvailable[] = [];
  spaWizardForm: UntypedFormGroup;
  spaWizardFilterData: any;
  therapistArray: any[] = [];
  locationArray: any[] = [];
  serviceArray: any[] = [];
  allServiceArray: any[] = [];
  serviceGroup: ServiceGroupAPI[] = [];
  serviceDetailArray: Service[] = [];
  customFields: any[] = [];
  medicalConditions: any[] = [];
  packages: any[] = [];
  selectedServiceGroupList: any = [];
  selectedPackageGroupList: any = [];
  packageGroup:any =[];
  packageInfoList: PackageInfo[] = [];
  slotInterval: any;
  appointmentBreakPoints: any = {}
  groupAppointments: GroupAppointment[] = [];
  groupDetail: GroupDetail;
  tempAppointmentsIdentifier = ''; // Guid
  setClientDetails: SetClientDetail[];
  OriginalSpaWizardForm: UntypedFormGroup;
  clientUpdateNotifier$ = new Subject<string>();
  setChangeNotifier$ = new Subject<string>();
  appointmentUpdateNotifier$ = new Subject<string>();
  isAppointmentDateChanged$ = new Subject<boolean>();
  customFieldsResult: CustomField[] = [];
  linkCodeResult: LinkCode[] = [];
  wizardCaptions;
  isSpaWizardCleared: boolean = false;
  guaranteeMethodJSONModel: GuaranteeMethodJSONModel = null;
  clientBlockInfos = [];
  clientBlockOverrideAccess = false;
  isOtherDetailsUpdated = false;
  genderOverrideMessage: GenderOverrideMessage[] = [];
  UpdatedAppointments: any = [];
  BatchScheduleTempAppointments = 10;
  FailedAppointments = [];
  serviceGroupsLoaded: any;
  sendAutomaticNotification:boolean =true;
  showExternalSearch = false;
  externalSearch: boolean;
  isExternalSearch = false;
  MoreOptions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isInternalPMSIntegrated: boolean;
  isExternalIntergrated: boolean = false;
  selectedPackage: any;
  retailInfo:any[];
  noOfCompServiceDetails:any;
  noOfCompRetailDetails:any;
  PackageAppointmentServices: any[];
  PackageRetailServices: any[];
  PackageStandardService:any[];
  clientSearchTypes:any;
  isAppointmentCleared:boolean = false;
  defaultClientType:number = 0;
  totalServiceCountFixed:number =0;
  totalRetailItemsCountFixed:number=0;
  totalServiceCountVariable:number=0;
  totalRetailItemsCountVariable:number=0;
  ServiceCountFixed:number=0;
  RetailItemsCountFixed:number=0;
  ServiceCountVariable:number=0;
  RetailItemsCountVariable:number=0;
  wizardArrayBeforeToggle: AppointmentAvailable[] = [];
  SelectedRetailItems=[];
  allGuestTypes=[];
  pmsGroupDetail:PMSGroupDetail = {};
  private stateSubject = new BehaviorSubject<{ clientSearchTypes: any[]; isAppointmentCleared: boolean }>({
    clientSearchTypes: [],
    isAppointmentCleared: false,
  });
  state$ = this.stateSubject.asObservable();

  clientLabelData: ClientLabel[] = [];
  private dateSubject = new BehaviorSubject<any | null>(null);
  spaWizardDate$ = this.dateSubject.asObservable();
  dayPackageConfig: any=[];
  dayPackageUtilizedConfig : any=[];
  clientVipType: any;
  discountClientType: any;
  serviceRetailItemId: any;
  isPackageApt: boolean;
  
  constructor(private utils: SpaUtilities
    , private http: HttpServiceCall
    , private Form: UntypedFormBuilder
    , private PropertyInfo: SpaPropertyInformation
    , private breakPoint: BreakPointAccess
    , private localization: SpaLocalization) {
    this.wizardCaptions = this.localization.captions.bookAppointment.spawizard;
    this.GetInitializeFormValues();
    this.GetCustomFields();
    this.GetPackageGroup();
    this.getAllMedicalHistory();
    this.getAllServices();
    this.getAllLocations();
    this.getAllTherapists();
    this.clientBlockOverrideAccess = this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.overrideClientBlock], false);
  }

  async GetConfigSettingData() {

    const response = await this.http.CallApiAsync<any>(
      {
        host: Host.spaManagement,
        callDesc: "GetSettingsByModule",
        method: HttpMethod.Put,
        body: { Switch: ['DEFAULT_CLIENT_CATEGORY', 'DEFAULT_HOTEL_RESERVATION', 'DEFAULT_EXISTING_CLIENT_CATEGORY', 'ENABLE_LINKED_APPOINTMENTS'] },
        uriParams: { module: 'Appointment' }
      }
    );
    return response;
  }
        async GetDayPackageByDate(date:any) {
          let formattedDate = this.localization.convertDateObjToAPIdate(date);
          this.dayPackageConfig = [];
          const apiResponse =  await this.http.CallApiAsync<any>(
            {
              host: Host.spaManagement,
              callDesc: "GetDayPackageByDate",
              method: HttpMethod.Get,
              uriParams:  { startDate : formattedDate}
            }
          );
          this.dayPackageConfig = apiResponse.result;
        }
        async GetAllDayPackageUtilizedById(date:any) {
          let formattedDate = this.localization.convertDateObjToAPIdate(date);
          this.dayPackageUtilizedConfig = [];
          const apiResponse = 
          await this.http.CallApiAsync<any>(
            {
              host: Host.spaManagement,
              callDesc: "GetDayPackageUtilizedByDate",
              method: HttpMethod.Get,
              uriParams:  { startDate : formattedDate}
            }
          );
          this.dayPackageUtilizedConfig = apiResponse.result;
        }

  GetInitializeFormValues() {
    this.spaWizardForm = this.Form.group({
      clientForm: this.Form.group({
        multipleBooking: true,
        autoBook: false,
        hotelBookingList: false,
        member: false,
        addSet: '',
        searchText: '',
        noOfClients: '',
        bookingId: '',
        bookingName: '',
        bookingClients: '',
        linkAppointments: false,
        multiBookingOption: 'SearchClient',
        ExistingClientOptions:this.localization.captions.bookAppointment.spawizard.FirstName,
        hotelBookingOptions: this.localization.captions.bookAppointment.spawizard.ConfirmationNumber
      }),
      newApptForm: this.Form.group({
        appointmentDate: this.PropertyInfo.CurrentDate,
        startDate: this.PropertyInfo.CurrentDate,
        endDate: this.PropertyInfo.CurrentDate,
        startTime: '09:00',
        endTime: '18:00',
        remainingAppointmentsOn: '',
        spaHours: true,
        ignoreSetupBreakdownTime: false,
        quickRoom: false
      }),
      autoForm: this.Form.group({
        overLap: false,
        medicalCondition: false,
        maxPerDay: '0',
        maxPerSlot: '0',
        negotiatedPrice: this.NegotiatedPrice()[0].id,
        negotiatedValue: '',
        distributionSetupForm: this.Form.group({
          service: this.ServiceDistribution()[1].id,
          staff: this.StaffDistribution()[0].id,
          location: this.LocationDistribution()[0].id,
        })
      }),
      otherDetails: this.GetOtherDetailsFormGroup(),
      serviceOptionsForm: this.Form.group({
        serviceType: 'services',
        locationId: 0,
        therapistId: 0,
        locationIds: [],
        therapistIds: [],
        noOfPackages:1

      })
    });
    this.OriginalSpaWizardForm = _.cloneDeep(this.spaWizardForm);
  }

  GetAppointmentFilterData(): FilterData {
    return this.spaWizardForm.getRawValue();
  }

  resetOtherDetailsForm() {
    this.spaWizardForm.controls.otherDetails = this.GetOtherDetailsFormGroup();
  }

  GetOtherDetailsFormGroup() {
    return this.Form.group({
      LinkCode: 0,
      vip: false,
      DoNotMove: false,
      ReqStaff: false,
      IsBookingLocked: false,
      genderPreference: 0,
      AppointmentComments: '',
      CheckOutComments: '',
      customField1: '',
      customField2: '',
      customField3: '',
      customField4: '',
      customField5: '',
      guestTypeId: 0
    })
  }

  /**
   * @function getBookingInfo()
   * @param bookingId
   * @description Fetch appointment booking info from API by booking ID
   */
  getBookingInfo(bookingId) {
    return {
      multipleBooking: false,
      autoBook: false,
      otherDetails: {},
      selectedSet: { id: 1, name: 'Set 1' },
      selectedClients: [{ id: 1, name: 'John Smith', checked: false }],
      appointmentDate: this.utils.getDate('2018-09-07'),
      remainingOn: this.utils.getDate('2018-09-13'),
      startTime: '00:00',
      endTime: '23:00',
      spaHours: false,
      setUpBreakDown: true,
      quickRoom: true,
      bookingName: '',
      bookingClients: '',
      overlap: false,
      medicalCondition: false,
      maxPerDay: 5,
      maxPerSlot: 5,
      multiBookingOption: 'SearchClient',
      ExistingClientOption:this.localization.captions.bookAppointment.spawizard.FirstName,
      hotelReservationOption: this.localization.captions.bookAppointment.spawizard.ConfirmationNumber
    };
  }
  public async getActivitiesByBookData(bookData: string): Promise<GroupActivity[]> {
    const response = await this.http.CallApiAsync<GroupActivity[]>(
      {
        callDesc: 'GetActivityByBookData',
        method: HttpMethod.Put,
        host: GlobalConst.Host.schedule,
        body: bookData
      }
    );
    return response.result;
  }

  public async getDefaultHotelReservation()
  {
    this.GetConfigSettingData().then(x => {

      if (x?.result) {
        let hotelReservation = x.result.find(y => y.switch == 'DEFAULT_HOTEL_RESERVATION' && y.isActive);
        if (hotelReservation) {

          switch (hotelReservation.value) {
            case '0':
              this.spaWizardForm.controls.clientForm['controls'].hotelBookingOptions.setValue(this.localization.captions.bookAppointment.spawizard.GuestName);
              break;
            case '1':
              this.spaWizardForm.controls.clientForm['controls'].hotelBookingOptions.setValue(this.localization.captions.bookAppointment.spawizard.ConfirmationNumber);
              break;
            case '2':
              this.spaWizardForm.controls.clientForm['controls'].hotelBookingOptions.setValue(this.localization.captions.bookAppointment.spawizard.RoomNumber);
              break;
            case '3':
              this.spaWizardForm.controls.clientForm['controls'].hotelBookingOptions.setValue(
                (!this.isExternalIntergrated && !this.isInternalPMSIntegrated)?
                this.localization.captions.bookAppointment.spawizard.LinkCode:
                this.localization.captions.bookAppointment.spawizard.ConfirmationNumber
              );
              break;
            case '4':
              this.spaWizardForm.controls.clientForm['controls'].hotelBookingOptions.setValue(
                (!this.isExternalIntergrated)?
                this.localization.captions.bookAppointment.spawizard.ContactDetail:
                this.localization.captions.bookAppointment.spawizard.ConfirmationNumber
              );
              break;
            default:
              this.spaWizardForm.controls.clientForm['controls'].hotelBookingOptions.setValue(this.localization.captions.bookAppointment.spawizard.ConfirmationNumber);
              break;

          }
        }
    }

    });
  }

  
  public async getSpaClientByGuids(GuestIDs: string[]): Promise<ClientInfo[]> {

    const response = await this.http.CallApiAsync<ClientInfo[]>(
      {
        callDesc: 'GetSpaClientsByGuid',
        method: HttpMethod.Put,
        host: GlobalConst.Host.spaManagement,
        body: GuestIDs
      }
    );
    return response.result;
  }

  public async getAllServiceGroups(): Promise<ServiceGroupAPI[]> {
    const response = await this.http.CallApiAsync<ServiceGroupAPI[]>({
      callDesc: 'GetAllServiceGrpByUserId',
      method: HttpMethod.Get,
      host: GlobalConst.Host.spaManagement
    });
    this.serviceGroup = response.result;

    if (this.serviceGroup.length == 0)
      this.serviceGroupsLoaded = true;

    return response.result;

  }


  public async getAllTherapists(): Promise<TherapistDetailAPI[]> {
    const response = await this.http.CallApiAsync<TherapistDetailAPI[]>({
      callDesc: 'getActiveTherapist',
      method: HttpMethod.Get,
      host: GlobalConst.Host.spaManagement
    });
    this.therapistArray = response.result;
    return response.result;
  }


  public async getClientBlockInfos(ids: any[]) {
    const response = await this.http.CallApiAsync<any[]>({
      callDesc: 'GetClientblockdetails',
      method: HttpMethod.Put,
      body: ids,
      showError: true,
      host: GlobalConst.Host.spaManagement
    });
    this.updateClientBlockInfos(response.result);
  }
  updateClientBlockInfos(result: any) {
    if (!this.clientBlockInfos) {
      this.clientBlockInfos = [];
    }
    if (result && result.length > 0) {
      for (let clientBlockInfo of result) {
        this.clientBlockInfos = this.clientBlockInfos.filter(x => x.clientId !== clientBlockInfo.clientId);
        this.clientBlockInfos.push(clientBlockInfo);
      }
    }

  }


  public async getAllLocations(): Promise<LocationAPI[]> {
    const response = await this.http.CallApiAsync<LocationAPI[]>({
      callDesc: 'GetAllLocByUserId',
      method: HttpMethod.Get,
      host: GlobalConst.Host.spaManagement
    });
    this.locationArray = response.result;
    return response.result;
  }

  public async getAvailableLocations(serviceId, startTime, endTime, therapistId): Promise<LocationViewModel[]> {
    const response = await this.http.CallApiAsync<LocationViewModel[]>({
      callDesc: 'GetAvailableLocations',
      method: HttpMethod.Get,
      host: GlobalConst.Host.schedule,
      uriParams: { serviceId, startTime, endTime, therapistId }
    });
    return response.result;
  }

  public async getAvailableTherapists(serviceId, startTime, endTime): Promise<TherapistViewModel[]> {
    const response = await this.http.CallApiAsync<TherapistViewModel[]>({
      callDesc: 'GetAvailableTherapists',
      method: HttpMethod.Get,
      host: GlobalConst.Host.schedule,
      uriParams: { serviceId, startTime, endTime }
    });
    return response.result;
  }


  public async getAllServices(): Promise<ServiceViewModel[]> {
    const response = this.http.CallApiAsync<ServiceViewModel[]>({
      callDesc: 'GetAllSpaServiceByUserId',
      method: HttpMethod.Get,
      host: GlobalConst.Host.spaManagement
    });

    const allServices = await this.http.CallApiAsync<ServiceViewModel[]>({
      callDesc: 'GetAllSpaService',
      method: HttpMethod.Get,
      host: GlobalConst.Host.spaManagement
    });

    this.serviceArray = (await response).result;
    this.allServiceArray = (await allServices).result;

    return _.cloneDeep(this.serviceArray);
  }

  public async setServiceFilter(bookingDate, allServices: ServiceViewModel[]): Promise<AppointmentFilterOutput> {
    let [filterResponse, services, allMaintenance] = await Promise.all([
      this.GetAllAppointmentFilters(bookingDate),
      this.LoadServiceDetails(),
      this.GetAllEquipmentMaintenance()
    ]);
    let wizardArrayList = [];
    if (filterResponse && filterResponse.serviceViewModels && filterResponse.serviceViewModels.length > 0) {
      for (let availableService of filterResponse.serviceViewModels) {
        let service = allServices.find(r => r.id == availableService.id);
        if (service) {
          if (this.isServiceEquipmentUnderMaintenance(services, allMaintenance, service.id)) {
            continue;
          }
          service.price = availableService.price; // setting yield price
          wizardArrayList.push(this.MapServiceViewModelToUI(service, filterResponse));// = filterResponse.serviceViewModels.map(a => this.MapServiceViewModelToUI(a, filterResponse));
        }
      }
    }
    this.wizardArray = wizardArrayList;
    this.activeServices = _.cloneDeep(this.wizardArray);
    return filterResponse;
  }

  isServiceEquipmentUnderMaintenance(service: Service[], maintenance: ServiceEquipmentMaintenance[], serviceId) {
    let isUnderMaintenance = false;
    let formData = this.GetAppointmentFilterData();
    let startTime = this.utils.getDate(this.utils.getAPIDateTimeFromDateAndTimeControls(formData.newApptForm.appointmentDate, formData.newApptForm.startTime));
    let endTime = this.utils.getDate(this.utils.getAPIDateTimeFromDateAndTimeControls(formData.newApptForm.appointmentDate, formData.newApptForm.endTime));
    let specificService = service.find(s => s.serviceDetail.id == serviceId);
    if (specificService && specificService.serviceEquipments && specificService.serviceEquipments.length > 0
      && maintenance && maintenance.length > 0) {
      let equipmentIds = specificService.serviceEquipments.map(e => e.equipmentId);
      let currentServiceEquipment = maintenance.filter(m => equipmentIds.includes(m.equipmentID));
      if (currentServiceEquipment && currentServiceEquipment.length > 0) {
        currentServiceEquipment.forEach(ce => {
          ce.startTime = this.utils.getDate(ce.startTime);
          ce.endTime = this.utils.getDate(ce.endTime);
        });
        isUnderMaintenance = currentServiceEquipment.some(r => r.startTime.getTime() <= startTime.getTime() && r.endTime.getTime() >= endTime.getTime());
      }
    }
    return isUnderMaintenance;
  }

  private MapServiceViewModelToUI(input: ServiceViewModel, filterInputs?: AppointmentFilterOutput): AppointmentAvailable {
    return {
      id: input.id,
      description: input.serviceGroupDescription,
      serviceDetails: input,
      overlap: 'yes',
      ignorebreakdown: 'yes',
      medictioncondtion: 'yes',
      packageid: 0,
      locationId: filterInputs && filterInputs.locationViewModels.filter(x => x.serviceId === input.id).map(a => a.locationId),
      therapistId: filterInputs && filterInputs.therapistViewModels.filter(x => x.serviceId === input.id).map(a => a.therapistId)
    };
  }

  /**
   * @description Retrieves the Filter based on the selectedDate
   * @param selectedDate
   */
  public async GetAllAppointmentFilters(selectedDate) {
    const uriParams = {
      isTherapistOverbook: false,
      isLocationOverbook: false,
      id: 0,
      timeAvailable: false,
      date: selectedDate,
      tempIds: ''
    };
    const response = await this.http.CallApiAsync<AppointmentFilterOutput>({
      host: GlobalConst.Host.schedule,
      callDesc: 'getAppointmentFilters',
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }


  public async GetRecentAppointmentData(searchString: string, startDate: Date, endDate: Date,selectedSearchType:any) {
    const response = await this.http.CallApiAsync<GroupAppointmentSearchModel[]>({
      host: GlobalConst.Host.schedule,
      callDesc: 'searchGroupAppointment',
      body: { search: searchString, startDate: startDate,endDate: endDate,apptSearchType: selectedSearchType },
      method: HttpMethod.Post
    });
    return response.result;
   }
 
  public async GetPackageGroup(): Promise<BaseResponse<any[]>> {
    var date=this.spaWizardForm.value.newApptForm.appointmentDate;
    let day: string = this.localization.getDayForDate(date);
    const response: BaseResponse<any[]> = await this.http.CallApiAsync({
      host: Host.spaManagement,
      callDesc: 'GetAllPackagesForSpaWizard',
      uriParams:{ dateTime: this.utils.formatDate(date), day: this.getDayIndexForAPIcall(day), maxPeople: 1 },
      method: HttpMethod.Get,
      showError: true
    });
    if (response && response.result && response.successStatus) {
      this.packages = response.result;
      return response;
    }
    return null;
  }
  getDayIndexForAPIcall(day: string): number {
    let index: number = 0;
    if (this.localization.captions.calendar.Mon == day)
      index = 0;
    if (this.localization.captions.calendar.Tue == day)
      index = 1;
    if (this.localization.captions.calendar.Wed == day)
      index = 2;
    if (this.localization.captions.calendar.Thu == day)
      index = 3;
    if (this.localization.captions.calendar.Fri == day)
      index = 4;
    if (this.localization.captions.calendar.Sat == day)
      index = 5;
    if (this.localization.captions.calendar.Sun == day)
      index = 6;

    return index;
  }

  async GetPackageById(packageId: number,Date: any): Promise<any> {
    var date=this.spaWizardForm.value.newApptForm.appointmentDate;
    let day: string = this.localization.getDayForDate(date);
    if (packageId && packageId !== 0) {
      const uriParam = { id: packageId ,dateTime: this.utils.formatDate(date), day: this.getDayIndexForAPIcall(day)};
      const apiResponse: BaseResponse<any> = await this.http.CallApiAsync({
        host: Host.spaManagement,
        callDesc: 'GetPackageComponentsForSpaWizard',
        method: HttpMethod.Get,
        uriParams: uriParam
      });
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
        return apiResponse.result;
      }
    }
    return null;
  }

  public async GetTherapistAvailability(bookingDate: string, serviceId: number, isPackageBook: boolean = false, appointmentId: number = 0, tempholdIds: number[] = []): Promise<TherapistAvailability> {
    const params = {
      serviceId: serviceId,
      bookingDate: bookingDate,
      isPackageBook: isPackageBook,
      appointmentid: appointmentId,
      tempIds: tempholdIds && tempholdIds.length > 0 ? JSON.stringify(tempholdIds) : null
    };
    const response: BaseResponse<TherapistAvailability> = await this.http.CallApiAsync({
      callDesc: 'GetTherapistAvailability',
      host: Host.schedule,
      method: HttpMethod.Get,
      uriParams: params
    });
    return response.result;
  }

  public async GetLocationAvailability(bookingDate: string, serviceId: number, excludeAppointmentIds: number[]): Promise<LocationAvailability[]> {
    const params = { serviceId: serviceId, bookingDate: bookingDate };
    const response: BaseResponse<LocationAvailability[]> = await this.http.CallApiAsync({
      callDesc: 'GetLocationAvailability',
      host: Host.schedule,
      method: HttpMethod.Put,
      uriParams: params,
      body: excludeAppointmentIds
    });
    return response.result;
  }

  public async GetTherapistServiceCount(bookingDate: string, serviceId: number): Promise<TherapistMaxServiceDetail[]> {
    const params = { serviceId: serviceId, bookingDate: bookingDate };
    const response: BaseResponse<TherapistMaxServiceDetail[]> = await this.http.CallApiAsync({
      callDesc: 'GetTherapistServiceCount',
      host: Host.schedule,
      method: HttpMethod.Get,
      uriParams: params
    });
    return response.result;
  }

  public async getAppointmentByDate(filterDate: any) {
    let fromDate = this.utils.formatDate(filterDate);
    let toDate = this.utils.formatDate(filterDate);
    const response = await this.http.CallApiAsync<any[]>({
      callDesc: 'GetAppointments',
      method: HttpMethod.Get,
      host: Host.schedule,
      body: undefined,
      uriParams: { FromDate: fromDate, ToDate: toDate },
      showError: true
    });
    return response.result;
  }

  async getEquipmentQuantity() {
    const response: BaseResponse<EquipmentQuantityDetails> = await this.http.CallApiAsync<EquipmentQuantityDetails>(
      {
        callDesc: 'EquipmentQtyDetails',
        host: Host.spaManagement,
        method: HttpMethod.Get
      }
    );
    return response.result;
  }
  public async GetServiceEquipmentMaintenance(serviceId: number): Promise<ServiceEquipmentMaintenance[]> {
    const params = { serviceId: serviceId };
    const response: BaseResponse<ServiceEquipmentMaintenance[]> = await this.http.CallApiAsync({
      callDesc: 'GetServiceEquipmentMaintenance',
      host: Host.spaManagement,
      method: HttpMethod.Get,
      uriParams: params
    });
    return response.result;
  }

  public async GetAllEquipmentMaintenance(): Promise<ServiceEquipmentMaintenance[]> {
    const response: BaseResponse<ServiceEquipmentMaintenance[]> = await this.http.CallApiAsync({
      callDesc: 'GetAllEquipmentMaintenance',
      host: Host.spaManagement,
      method: HttpMethod.Get
    });
    return response.result;
  }

  public async GetLinkCode(): Promise<LinkCode[]> {
 
    let formData = this.GetAppointmentFilterData();
    const result: BaseResponse<LinkCode[]> = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: 'GetLinkCodes',
      method: HttpMethod.Get,
      uriParams: { propertyDate: this.utils.convertDateFormat(this.utils.getDate(formData.newApptForm.appointmentDate)), showInActive: false },
    });
    if (result && result.result && result.successStatus) {
      this.linkCodeResult = result.result;
      return result.result;
    }
    return null;
  }

  public async GetCustomFields(): Promise<CustomField[]> {

    const result: BaseResponse<CustomField[]> = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: 'GetCustomFieldsWithValues',
      method: HttpMethod.Get
    });
    if (result && result.result && result.successStatus) {
      this.customFields = this.buildCustomFields(result.result);
      this.customFieldsResult = result.result;
      return result.result;
    }
    return null;
  }

  public async createBulkClient(clients): Promise<ClientInfo[]> {

    const result: BaseResponse<ClientInfo[]> = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: 'CreateBulkClient',
      method: HttpMethod.Post,
      body: clients,
      showError: false
    });

    if (result && result.result && result.successStatus) {
      return result.result;
    }

    return null;

  }


  public async SheduleGroupAppointment(requestBody: WizardGroupAppointmentRequest): Promise<WizardGroupAppointmentResponse> {
    if (!this.tempAppointmentsIdentifier) {
      this.tempAppointmentsIdentifier = this.utils.generateGUID();
    }


    const groupDetailResponse: BaseResponse<any> = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'SheduleGroupDetail',
      method: HttpMethod.Put,
      body: requestBody.groupDetail,
      uriParams: { guid: this.tempAppointmentsIdentifier },
      showError: false
    });

    requestBody.groupDetail.id = groupDetailResponse.result;

    let apiRequestArray: Promise<WizardGroupAppointmentResponse>[] = [];

   
    if(this.spaWizardForm.controls.serviceOptionsForm.value.serviceType == 'services')
    {
    let i = 0;
    while (i * this.BatchScheduleTempAppointments < requestBody.appointments.length) {
      let range = i * this.BatchScheduleTempAppointments;
      const appList = [];
      for (let j = range; j < range + this.BatchScheduleTempAppointments && j < requestBody.appointments.length; j++) {
        appList.push(requestBody.appointments[j]);
      }
      apiRequestArray.push(this.SaveAppointments(requestBody, appList));
      i++;
     }
     }
     else
     {
      apiRequestArray.push(this.SaveAppointments(requestBody, requestBody.appointments));
     }

    let response = await Promise.all(apiRequestArray);

    let result = { ...response[0] };

    response.forEach(element => {
      if (element) {
        element.appointments.forEach(appointment => {
          if (!result.appointments.find(x => x.appointmentDetail.id == appointment.appointmentDetail.id)) {
            result.appointments.push(appointment);
          }
        });
      }
    });

    return result;
  }


  public async SaveAppointments(requestBody, appointments): Promise<WizardGroupAppointmentResponse> {

    let requestObj = { ...requestBody };
    requestObj.appointments = appointments;
    if(this.retailInfo && this.retailInfo?.length>0)
    {
      requestObj.packageAppointmentRetailItems=this.retailInfo;
    }

    const result: BaseResponse<WizardGroupAppointmentResponse> = await this.http.CallApiAsync<WizardGroupAppointmentResponse>({
      host: GlobalConst.Host.schedule,
      callDesc: 'SheduleGroupAppointment',
      method: HttpMethod.Put,
      body: requestObj,
      uriParams: { guid: this.tempAppointmentsIdentifier }
    });

    if (result && result.result && result.successStatus) {
      return result.result;
    }
    else if(result?.errorCode > 0){
      this.utils.showError(this.localization.getError(result.errorCode));
    }
    return null;
  }


  public async UpdateAppointmentGroupId(appointmentIds: number[], groupId: number): Promise<any[]> {
    const result: BaseResponse<any[]> = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.schedule,
      callDesc: 'UpdateGroupId',
      method: HttpMethod.Put,
      body: appointmentIds,
      uriParams: { groupId: groupId }
    });

    if (result && result.result && result.successStatus) {
      return result.result;
    }

    return null;

  }

  public async GetServiceDetailByIds(id: number[]) {
    const response: BaseResponse<ServiceViewModel[]> = await this.http.CallApiAsync<ServiceViewModel[]>(
      {
        callDesc: 'GetServiceDetailsByIds',
        host: Host.spaManagement,
        method: HttpMethod.Put,
        body: id
      }
    );
    return response.result;
  }

  public async CreateGroupTempAppointment(body: WizardTempAppointmentRequest): Promise<WizardTempAppointmentResponse[]> {
    if (!this.tempAppointmentsIdentifier) {
      this.tempAppointmentsIdentifier = this.utils.generateGUID();
    }
    const response = await this.http.CallApiAsync<WizardTempAppointmentResponse[]>(
      {
        callDesc: 'CreateGroupTempAppointment',
        method: HttpMethod.Post,
        host: GlobalConst.Host.schedule,
        body: body,
        uriParams: { guid: this.tempAppointmentsIdentifier }
      }
    );
    return response.result;
  }

  public async CreateAutoGroupTempAppointment(body: WizardAutoBookTempAppointmentRequest): Promise<WizardAutoBookTempAppointmentResponse> {
    if (!this.tempAppointmentsIdentifier) {
      this.tempAppointmentsIdentifier = this.utils.generateGUID();
    }
    const response = await this.http.CallApiAsync<WizardAutoBookTempAppointmentResponse>(
      {
        callDesc: 'CreateAutoBookGroupTempAppointment',
        method: HttpMethod.Post,
        host: GlobalConst.Host.schedule,
        body: body
      }
    );
    return response.result;
  }

  async getAllMedicalHistory() {
    const response: BaseResponse<any[]> = await this.http.CallApiAsync<any[]>({
      host: Host.spaManagement,
      callDesc: 'getAllMedicalConditions',
      method: HttpMethod.Get,
      showError: true,
    });
    if (response && response.result && response.successStatus) {
      this.medicalConditions = response.result;
      return response;
    }
    return null;
  }

  buildCustomFields(response: any) {
    const dropDownFields = [CustomFieldKey.CustomField1, CustomFieldKey.CustomField2, CustomFieldKey.CustomField3];
    return response.map((cf) => {
      const keyValuePairs: KeyValuePair[] = [];
      if (dropDownFields.includes(cf.columnName)) {
        cf.customFieldValues.map(cfv => {
          keyValuePairs.push({
            id: cfv.id,
            key: cfv.code,
            value: cfv.description,
            isSelected: false
          } as KeyValuePair);
        });
      }
      return {
        fieldKey: cf.columnName,
        fieldName: cf.fieldName,
        fieldType: dropDownFields.includes(cf.columnName) ? CustomFieldType.Dropdown : CustomFieldType.TextField,
        displayOn: cf.displayOn,
        fieldValues: keyValuePairs,
        isRequired: cf.requiredOnAppointment
      } as CF;
    }) as CF[];
  }

  ClearServiceValues() {
    this.selectedClients = [];
    this.groupAppointments = [];
    this.SelectedRetailItems = [];
    this.groupDetail = null;
    this.tempAppointmentsIdentifier = '';
    this.wizardArray = [];
    this.autoBookWizardArray = [];
    this.appointmentBreakPoints = null;
    this.customFieldsResult = [];
    this.spaWizardForm = null;
    this.guaranteeMethodJSONModel = null;
  }

  async GetGroupAppointmentDetails(reqBody: GroupAppointmentsRequestModel): Promise<WizardGroupAppointmentResponse> {
    const result: BaseResponse<WizardGroupAppointmentResponse> = await this.http.CallApiAsync<WizardGroupAppointmentResponse>({
      host: Host.schedule,
      callDesc: 'GetFullGroupAppointment',
      method: HttpMethod.Put,
      body: reqBody,
      showError: true
    });
    return result.result;
  }

  async DeleteTempAppointments(ids: any) {
    const response = await this.http.CallApiAsync<boolean>({
      host: Host.schedule,
      callDesc: 'DeleteAppointments',
      method: HttpMethod.Delete,
      body: ids,
      showError: false,
    });

    if (response.result) {
      console.log("Appointments deleted properly");
    }

  }

  async GetClients(clientId: number[]): Promise<ClientInfo[]> {
    const response = await this.http.CallApiAsync<ClientInfo[]>(
      {
        callDesc: 'GetClients',
        method: HttpMethod.Get,
        host: GlobalConst.Host.spaManagement,
        queryString: { key: 'id', value: clientId }
      }
    );
    return response.result;
  }

  public async SingleUndoCheckIn(id: number) {
    const response: BaseResponse<ServiceViewModel[]> = await this.http.CallApiAsync<ServiceViewModel[]>(
      {
        callDesc: 'UndoCheckInAppointment',
        host: Host.schedule,
        method: HttpMethod.Put,
        uriParams: { id: id }
      }
    );
    return response.result;
  }

  public async UndoCheckIn(id: number[]) {
    const response: BaseResponse<ServiceViewModel[]> = await this.http.CallApiAsync<ServiceViewModel[]>(
      {
        callDesc: 'UndoCheckinMulti',
        host: Host.schedule,
        method: HttpMethod.Put,
        body: id
      }
    );
    return response.result;
  }

  public async getSettingData(): Promise<any[]> {
    const response = await this.http.CallApiAsync<any[]>(
      {
        callDesc: 'GetAllSetting',
        method: HttpMethod.Get,
        host: GlobalConst.Host.spaManagement
      }
    );
    let settingInfo = <any>response.result;
    let _appJSON: any = {};
    settingInfo.map(sc => {
      if (sc.switchType == "Boolean") {
        sc.value = this.convertStringToBoolean(sc.value as string);
      }
      _appJSON[sc.switch] = sc.value
    });
    this.PropertyInfo.SetAppointmentConfigurations(_appJSON);
    return response.result;
  }
  public async getSettingDataWithValues(): Promise<any[]> {
    const response = await this.http.CallApiAsync<any[]>(
      {
        callDesc: 'GetAllSetting',
        method: HttpMethod.Get,
        host: GlobalConst.Host.spaManagement
      }
    );
    let settingInfo = <any>response.result;
    let _appJSON: any = {};
    settingInfo.map(sc => {
      if (sc.switchType == "Boolean") {
        sc.value = this.convertStringToBoolean(sc.value as string);
      }
      _appJSON[sc.switch] = sc.value
    });
    this.PropertyInfo.SetAppointmentConfigurations(_appJSON);
    return _appJSON;
  }


  public async fetchCustomFieldInfo(): Promise<any[]> {

    const response = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetCustomFieldsWithValues",
      method: HttpMethod.Get,
      showError: false
    });

    return response.result;
  }


  public async LoadServiceDetails(): Promise<Service[]> {
    let keyValue = this.serviceArray.map(x => x.id);
    let result = await this.http.CallApiAsync({
      callDesc: "GetServices",
      method: HttpMethod.Put,
      host: Host.spaManagement,
      body: keyValue
    });
    let serviceDetail = <Service[]>result.result;
    this.serviceDetailArray = serviceDetail ? serviceDetail.filter(service => (service.serviceDetail.minimumStaff < 2 && service.serviceDetail.minimumGuest < 2)) : [];
    return serviceDetail;
  }

  convertStringToBoolean(value: string): boolean {
    return value == "true" ? true : false;
  }

  SetUpBreakPoints() {
    let appBpoint: UserBreakPoint[] = [];

    appBpoint = this.breakPoint.GetBreakPoint([
      SPAScheduleBreakPoint.BookAppointment,
      SPAScheduleBreakPoint.EditAppointmentComments,
      SPAScheduleBreakPoint.EditAppointmentDuration,
      SPAScheduleBreakPoint.EditAppointmentServicePrice,
      SPAScheduleBreakPoint.EditBreakdownTime,
      SPAScheduleBreakPoint.EditSetupTime,
      SPAScheduleBreakPoint.EditCustomField1,
      SPAScheduleBreakPoint.EditCustomField2,
      SPAScheduleBreakPoint.EditCustomField3,
      SPAScheduleBreakPoint.EditCustomField4,
      SPAScheduleBreakPoint.EditCustomField5,
      SPAScheduleBreakPoint.CancelAppointment,
      SPAScheduleBreakPoint.CheckInAppointment,
      SPAScheduleBreakPoint.UndoCheckIn,
      SPAScheduleBreakPoint.CheckOutAppointment,
      SPAScheduleBreakPoint.AddDeposit,
      SPAScheduleBreakPoint.EditAppointment,
      SPAScheduleBreakPoint.AppointmentSearch,
      SPAManagementBreakPoint.AddNewClientProfile,
      SPAManagementBreakPoint.EditClientProfile,
      SPAManagementBreakPoint.AddPriceType,
      ReportBreakPoint.GuestAppointments
    ]).result;


    this.appointmentBreakPoints = {
      [SPAScheduleBreakPoint.BookAppointment]: this.IsActionAllowed(SPAScheduleBreakPoint.BookAppointment, appBpoint),
      [SPAScheduleBreakPoint.EditAppointmentComments]: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointmentComments, appBpoint),
      [SPAScheduleBreakPoint.EditAppointmentDuration]: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointmentDuration, appBpoint),
      [SPAScheduleBreakPoint.EditAppointmentServicePrice]: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointmentServicePrice, appBpoint),
      [SPAScheduleBreakPoint.EditBreakdownTime]: this.IsActionAllowed(SPAScheduleBreakPoint.EditBreakdownTime, appBpoint),
      [SPAScheduleBreakPoint.EditSetupTime]: this.IsActionAllowed(SPAScheduleBreakPoint.EditSetupTime, appBpoint),
      [SPAScheduleBreakPoint.EditCustomField1]: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField1, appBpoint),
      [SPAScheduleBreakPoint.EditCustomField2]: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField2, appBpoint),
      [SPAScheduleBreakPoint.EditCustomField3]: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField3, appBpoint),
      [SPAScheduleBreakPoint.EditCustomField4]: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField4, appBpoint),
      [SPAScheduleBreakPoint.EditCustomField5]: this.IsActionAllowed(SPAScheduleBreakPoint.EditCustomField5, appBpoint),
      [SPAScheduleBreakPoint.CancelAppointment]: this.IsActionAllowed(SPAScheduleBreakPoint.CancelAppointment, appBpoint),
      [SPAScheduleBreakPoint.CheckInAppointment]: this.IsActionAllowed(SPAScheduleBreakPoint.CheckInAppointment, appBpoint),
      [SPAScheduleBreakPoint.UndoCheckIn]: this.IsActionAllowed(SPAScheduleBreakPoint.UndoCheckIn, appBpoint),
      [SPAScheduleBreakPoint.CheckOutAppointment]: this.IsActionAllowed(SPAScheduleBreakPoint.CheckOutAppointment, appBpoint),
      [SPAScheduleBreakPoint.AddDeposit]: this.IsActionAllowed(SPAScheduleBreakPoint.AddDeposit, appBpoint),
      [SPAScheduleBreakPoint.EditAppointment]: this.IsActionAllowed(SPAScheduleBreakPoint.EditAppointment, appBpoint),
      [SPAScheduleBreakPoint.AppointmentSearch]: this.IsActionAllowed(SPAScheduleBreakPoint.AppointmentSearch, appBpoint),
      [SPAManagementBreakPoint.AddNewClientProfile]: this.IsActionAllowed(SPAManagementBreakPoint.AddNewClientProfile, appBpoint),
      [SPAManagementBreakPoint.EditClientProfile]: this.IsActionAllowed(SPAManagementBreakPoint.EditClientProfile, appBpoint),
      [SPAManagementBreakPoint.AddPriceType]: this.IsActionAllowed(SPAManagementBreakPoint.AddPriceType, appBpoint),
      [ReportBreakPoint.GuestAppointments]: this.IsActionAllowed(ReportBreakPoint.GuestAppointments, appBpoint)

    }

  }

  private IsActionAllowed(breakPoint: number, appointmentBreakPoints: UserBreakPoint[]) {
    let _breakPoint: UserBreakPoint[] = appointmentBreakPoints.filter(bp => bp.breakPointNumber == breakPoint);
    return _breakPoint.length != 0 ? _breakPoint[0].allow : false;
  }

  public CheckForAccess(breakPoint: number, showMessage: boolean = true) {
    let returnFlag = this.appointmentBreakPoints ? this.appointmentBreakPoints[breakPoint] : true;
    if (!returnFlag && showMessage) {
      this.breakPoint.showBreakPointPopup(this.breakPoint.localization.captions.breakpoint[breakPoint]);
    }
    return returnFlag;
  }

  GetTempClientLastInt(name: string): number {
    let returnCount: number = 0;
      // returnCount1: number = 0;
    if (this.selectedClients && this.selectedClients.length) {
      let selectedClientsLength=this.selectedClients.length;
      this.selectedClients.filter(x => {
        if (name ? x.clientDetail.firstName.toUpperCase() == name.toUpperCase() : true) {
          returnCount=selectedClientsLength;

          // returnCount1 = Number.parseInt(x.clientDetail.lastName);
          // if (!Number.isNaN(returnCount1)) {
          //   returnCount = returnCount1;
          // }
        }
      })
    }
    return returnCount;
  }

  public GetServiceOptions() {
    return [
      { 'key': 'services', 'value': this.wizardCaptions.Services },
      { 'key': 'package', 'value': this.wizardCaptions.Package }
    ]
  }

  public NegotiatedPrice(): Options[] {
    return [
      {
        id: NegotiatedPrice.Flat,
        value: NegotiatedPrice.Flat,
        viewValue: this.wizardCaptions.Flat
      },
      {
        id: NegotiatedPrice.Percent,
        value: NegotiatedPrice.Percent,
        viewValue: this.wizardCaptions.Percent
      }
    ]
  }

  public ServiceDistribution(): Options[] {
    return [
      {
        id: DistributionSetup.Day,
        value: DistributionSetup.Day,
        viewValue: this.wizardCaptions.Day
      },
      {
        id: DistributionSetup.Range,
        value: DistributionSetup.Range,
        viewValue: this.wizardCaptions.Range
      }
    ]
  }

  public StaffDistribution(): Options[] {
    return [
      {
        id: DistributionSetup.None,
        value: DistributionSetup.None,
        viewValue: this.wizardCaptions.None
      },
      {
        id: DistributionSetup.Day,
        value: DistributionSetup.Day,
        viewValue: this.wizardCaptions.Day
      },
      {
        id: DistributionSetup.Range,
        value: DistributionSetup.Range,
        viewValue: this.wizardCaptions.Range
      }
    ]
  }

  public LocationDistribution(): Options[] {
    return [
      {
        id: DistributionSetup.None,
        value: DistributionSetup.None,
        viewValue: this.wizardCaptions.None
      },
      {
        id: DistributionSetup.Day,
        value: DistributionSetup.Day,
        viewValue: this.wizardCaptions.Day
      },
      {
        id: DistributionSetup.Range,
        value: DistributionSetup.Range,
        viewValue: this.wizardCaptions.Range
      }
    ]
  }

  addPackageInfo(packageInfo: PackageInfo) {
    if (!this.packageInfoList.some(x => x.id == packageInfo.id)) {
      this.packageInfoList.push(packageInfo);
    }
  }

  public async setAutoBookServiceFilter() {
    let wizardArrayList = [];
    const allServicesDetails = _.cloneDeep(this.serviceDetailArray);
    const startDate: Date = this.spaWizardForm.controls.newApptForm['controls']['startDate'].value;
    const endDate: Date = this.spaWizardForm.controls.newApptForm['controls']['endDate'].value;
    wizardArrayList = allServicesDetails.filter(x => {
      if (!x.serviceDetail.isInActive) {
        const serviceStart = new Date(x.serviceDetail.effectiveFromDate);
        const serviceEnd = new Date(x.serviceDetail.effectiveToDate);
        const start = _.cloneDeep(startDate);
        const end = _.cloneDeep(endDate);
        if ((start >= serviceStart && start <= serviceEnd) || (end >= serviceStart && end <= serviceEnd)) {
          let result = false;
          if (start >= serviceStart) {
            for (let i = 0; i < 7; i++) {
              result = this.isServiceAvailable(start.getDay(), x.serviceAvailableDays);
              start.setDate(start.getDate() + (i == 0 ? (i + 1) : 1));
              if (result || start > end || start > serviceEnd) {
                break;
              }
            }
          } else if (serviceStart >= start) {
            for (let i = 0; i < 7; i++) {
              result = this.isServiceAvailable(serviceStart.getDay(), x.serviceAvailableDays);
              serviceStart.setDate(serviceStart.getDate() + (i == 0 ? (i + 1) : 1));
              if (result || serviceStart > end || serviceStart > serviceEnd) {
                break;
              }
            }
          }
          return result;
        } else {
          return false;
        }
      }
      else {
        return false;
      }
    });
    //equipement service filter
    let startTime = this.utils.getDate(this.utils.getAPIDateTimeFromDateAndTimeControls(this.spaWizardForm.controls.newApptForm['controls']['startDate'].value, this.spaWizardForm.controls.newApptForm['controls']['startTime'].value));
    let endTime = this.utils.getDate(this.utils.getAPIDateTimeFromDateAndTimeControls(this.spaWizardForm.controls.newApptForm['controls']['endDate'].value, this.spaWizardForm.controls.newApptForm['controls']['endTime'].value));
    let equipMainenance = await this.GetAllEquipmentMaintenance();
    if (equipMainenance && equipMainenance.length > 0) {
      equipMainenance = equipMainenance.filter(x => this.utils.getDate(x.startTime) <= startTime && this.utils.getDate(x.endTime) >= endTime);
    }
    if (equipMainenance.length > 0) {
      let equipmentIds = equipMainenance.map(x => x.equipmentID);
      equipmentIds.forEach(element => {
        wizardArrayList = wizardArrayList.filter(x => !x.serviceEquipments ||
          (x.serviceEquipments && x.serviceEquipments.length == 0) ||
          (x.serviceEquipments && x.serviceEquipments.length > 0 && !x.serviceEquipments.map(y => y.equipmentId).includes(element)));
      });
    }

    if (this.serviceGroup.length === 0) {
      await this.getAllServiceGroups();
    }
    let filterResponse = wizardArrayList.map(x => {
      return this.MapAutoBookServiceViewModelToUI(x);
    })
    this.autoBookWizardArray = _.cloneDeep(filterResponse);
    this.wizardArray = _.cloneDeep(filterResponse);
    return filterResponse;
  }

  public UpdateSetClientDetail() {
    this.setClientDetails = [];
    if (this.groupAppointments.length > 0) {
      console.log(this.groupAppointments);
      let setDetails: any[] = [];
      // setDetails = _.chain(this.groupAppointments)
      //   .groupBy('appointment.appointmentDetail.setName');
        // .map((value, key) => (
        //   {
        //     setName: key,
        //     setGuid: value[0].appointment.appointmentDetail.setGuid,
        //     appointmentInfo: value
        //   })
        // ).value();
      let groupedSetNameObj = this.groupAppointments.reduce((preValue, curValue)=>{
        preValue[curValue.appointment.appointmentDetail.setName] = preValue[curValue.appointment.appointmentDetail.setName] || [];
        preValue[curValue.appointment.appointmentDetail.setName]['setName'] = curValue.appointment.appointmentDetail.setName;
        preValue[curValue.appointment.appointmentDetail.setName]['setGuid'] = curValue.appointment.appointmentDetail.setGuid;
        if(!preValue[curValue.appointment.appointmentDetail.setName].hasOwnProperty('appointmentInfo'))
            preValue[curValue.appointment.appointmentDetail.setName]['appointmentInfo'] = [];

        preValue[curValue.appointment.appointmentDetail.setName]['appointmentInfo'].push(curValue);
        return preValue;
      }, Object.create(null));

      setDetails = Object.keys(groupedSetNameObj).map(key=>groupedSetNameObj[key]);

      setDetails.forEach((set) => {
        this.setClientDetails.push({
          setName: set.setName,
          setGuid: set.setGuid,
          clientAppointmentDetail: set.appointmentInfo.map(appointment => {
            return {
              clientId: appointment.appointment.appointmentDetail.clientId,
              clientTempId: appointment.tempClientId || null,
              serviceId: appointment.appointment.appointmentDetail.serviceId
            }
          })
        })
      });
      console.log(setDetails);
    }
  }

  private MapAutoBookServiceViewModelToUI(input: Service): AppointmentAvailable {
    const serviceGroup = this.serviceGroup.find(x => x.id === input.serviceDetail.serviceGroupId);
    const serviceGroupDesc = serviceGroup.description || '';
    return {
      id: input.serviceDetail.id,
      description: serviceGroupDesc,
      serviceDetails: {
        id: input.serviceDetail.id,
        code: input.serviceDetail.code,
        description: input.serviceDetail.description,
        serviceGroupDescription: serviceGroupDesc,
        serviceGroupId: serviceGroup ? serviceGroup.id : 0,
        isActive: input.serviceDetail.isInActive,
        effectiveFromDate: input.serviceDetail.effectiveFromDate,
        effectiveToDate: input.serviceDetail.effectiveToDate,
        cancellationPolicy: input.serviceDetail.cancellationPolicy,
        price: input.serviceDetail.price,
        duration: input.serviceDetail.time,
        setupTime: input.serviceDetail.setupTime,
        breakDownTime: input.serviceDetail.breakDownTime,
        serviceComment: input.serviceDetail.comments,
        servicePolicy: input.serviceDetail.policy,
        minimumGuest: input.serviceDetail.minimumGuest,
        maximumGuest: input.serviceDetail.maximumGuest,
        minimumStaff: input.serviceDetail.minimumStaff,
        maximumStaff: input.serviceDetail.maximumStaff,
        isAutoGratuity: input.serviceDetail.isAutoGratuity,
        gratuityPercent: input.serviceDetail.gratuityPercent,
        gratuityAmount: input.serviceDetail.gratuityAmount,
        isAutoServiceCharge: input.serviceDetail.isAutoServiceCharge,
        serviceChargePercent: input.serviceDetail.serviceChargePercent,
        serviceChargeAmount: input.serviceDetail.serviceChargeAmount,
        retailItemId: input.serviceDetail.retailItemId,
        colorCode: input.serviceDetail.colorCode,
        isOffsite: input.serviceDetail.isOffsite,
        servicePriceTypes: input.serviceDetail.servicePriceTypes,
        listOrder: input.serviceDetail.listOrder
      },
      overlap: 'yes',
      ignorebreakdown: 'yes',
      medictioncondtion: 'yes',
      packageid: 0,
      locationId: input.serviceLocations && input.serviceLocations.map(a => a.locationId),
      therapistId: input.serviceTherapists && input.serviceTherapists.map(a => a.therapistId)
    };
  }

  private isServiceAvailable(day: Days, serviceAvailableDays: ServiceAvailableDays): boolean {
    switch (day) {
      case Days.Sunday:
        return serviceAvailableDays.isAvailableOnSunday;
      case Days.Monday:
        return serviceAvailableDays.isAvailableOnMonday;
      case Days.Tuesday:
        return serviceAvailableDays.isAvailableOnTuesday;
      case Days.Wednesday:
        return serviceAvailableDays.isAvailableOnWednesday;
      case Days.Thursday:
        return serviceAvailableDays.isAvailableOnThursday;
      case Days.Friday:
        return serviceAvailableDays.isAvailableOnFriday;
      case Days.Saturday:
        return serviceAvailableDays.isAvailableOnSaturday;
      default:
        return false;
    }
  }

  setState(clientSearchTypes: any[], isAppointmentCleared: boolean) {
    this.stateSubject.next({ clientSearchTypes, isAppointmentCleared });
  }

  sendData(dateForm: any){
    this.dateSubject.next(dateForm);
  }

  clearDateSubject(){
    this.dateSubject.next(null);
  }
  async getRetailItemsDetailedInfoByIds(retailItemIds: any[]): Promise<any[]> {
    const result = await this.http.CallApiAsync<any>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemDetailedInfoList",
      method: HttpMethod.Put,
      body: retailItemIds
    });

    return result.result;
  }
  async setPackageRetailItem(groupAppointmentDetails: WizardGroupAppointmentResponse)
  {
    var retailItems=[];
    if(groupAppointmentDetails?.packageAppointmentRetailItems &&groupAppointmentDetails.packageAppointmentRetailItems.length)
    {
      const clientIdMap = groupAppointmentDetails.appointments.reduce((acc, appointment) => {
        acc[appointment.appointmentDetail.packageGroupId] = appointment.appointmentDetail.clientId;
        return acc;
    }, {} as { [key: string]: number });
    const distinctRetailItemIds = Array.from(new Set(groupAppointmentDetails.packageAppointmentRetailItems.map(item => item.retailItemId)));
    if(distinctRetailItemIds && distinctRetailItemIds.length)
    {
      retailItems=await this.getRetailItemsDetailedInfoByIds(distinctRetailItemIds);
    }
    groupAppointmentDetails.packageAppointmentRetailItems.forEach(item => {
      const clientId = clientIdMap[item.packageGroupId];
      var retailItem=retailItems.filter(x=>x.id==item.retailItemId); 
      if (clientId !== undefined) {
          item.client = clientId;
      }
      if(retailItem &&retailItem.length)
      {
        item.retailItemName=retailItem[0]?.retailItemDetail?.itemDescription
      }
    });
      this.SelectedRetailItems.push(...groupAppointmentDetails.packageAppointmentRetailItems);
    
    }
  }
   
}
