import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { CreateTierLevelComponent } from './create-tier-level/create-tier-level.component';
import { TierLevelBusiness } from './retail-tier-level.business';
import { TierLevelDataService } from './retail-tier-level.data.service';
import { DiscountMappingBusiness } from '../../discount-mapping/discount-mapping.business';

@Component({
  selector: 'app-retail-tier-level',
  templateUrl: './retail-tier-level.component.html',
  styleUrls: ['./retail-tier-level.component.scss'],
  providers: [TierLevelBusiness, TierLevelDataService, DiscountMappingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class RetailTierLevelComponent implements OnInit {

  tableBodyData: any[] = [];
  filteredtableBodyData: any[] = [];
  tableoptions: any[];
  captions: any;
  IsViewOnly: boolean;
  searchHeaderOption: TableSearchHeader;
  userOperationType: myGlobals.OperationType;
  searchText = '';
  discountTypes: any[] = undefined;

  constructor(private Form: UntypedFormBuilder, public localization: RetailLocalization, private utils: RetailUtilities,
    private business: TierLevelBusiness, private discountbusiness: DiscountMappingBusiness, private dialog: MatDialog,
    private retailService: RetailSetupService) {
    this.captions = this.localization.captions
  }

  ngOnInit() {
    this.userOperationType = myGlobals.OperationType.Create;
    this.setSearchHeaderOption(this.captions.btn_CreditTierLevel, this.captions.lbl_searchTierCode);
    this.getTableData();
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.TierLevel).view;
  }

  setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
    this.searchHeaderOption = {
      createBtnLabel,
      searchPlaceHolder,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: false,
      toggleDisabled: false,
      createBtnDisabled: this.IsViewOnly,
      hasHeaderAction: false,
      hideActionBtn: false,
      automationId: 'retailTierLevel'
    };
  }

  private BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.tierCode, "jsonkey": "code", "sortable": true },
        { "title": this.captions.tierName, "jsonkey": "name", "sortable": true },
        { "title": this.captions.description, "jsonkey": "description", "sortable": false, "searchable": false }],
        TablebodyData: this.filteredtableBodyData,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: '',
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.tierLevel,
        Sortable: 'code',
        TableId: myGlobals.GridType.tierLevel,
        disableDelete: false,
        pageTitle: 'tierLevel',
        ServiceId: 'tierLevel',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: false,
        automationId: 'retailTierLevel'
      }
    ];
  }

  async populateDiscountTypes() {
    if (!this.discountTypes) {
      let discountTypes = await this.discountbusiness.getDiscountTypes();
      this.discountTypes = discountTypes;
      this.business.discountTypes = discountTypes;
    }
  }

  async getTableData() {
    await this.populateDiscountTypes();
    let allTierLevel = await this.business.getAllTierLevel();
    this.tableBodyData = allTierLevel;
    this.filteredtableBodyData = allTierLevel;
    this.BindToGrid();
  }

  searchChange(value) {
    if (value) {
      this.searchText = value;
      const filterValue = value.toLowerCase();
      this.filteredtableBodyData = this.tableBodyData.filter(t => (t.code.toLowerCase().includes(filterValue)));
    } else {
      this.searchText = '';
      this.filteredtableBodyData = this.tableBodyData;
    }
    this.BindToGrid();
  }
  
  async createEvent(e) {
    this.openDialog();
  }

  async openDialog() {
    await this.populateDiscountTypes();
    this.dialog.open(CreateTierLevelComponent, {
      width: '60%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.hdr_CreateTierLevel,
        tableData: this.tableBodyData,
        discountTypes: this.discountTypes,
        mode: myGlobals.GridOperationType.Create,
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.tableBodyData = result;
        this.searchChange(this.searchText);
      }
    });
  }

  async EditRecords(discountData) {
    await this.populateDiscountTypes();
    this.dialog.open(CreateTierLevelComponent, {
      width: '60%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.hdr_EditTierLevel,
        data: discountData[0],
        tableData: this.tableBodyData,
        discountTypes: this.discountTypes,
        mode: myGlobals.GridOperationType.Edit,
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.tableBodyData = result;
        this.searchChange(this.searchText);
      }
    });
  }

  async DeleteRecords(event: any) {
    this.utils.ToggleLoader(true, this.captions.lbl_processing);
    if (this.business.DeleteValidation(event[0].id)) {
      this.tableBodyData = await this.business.deleteTierLevel(event[0].id);
      this.searchChange(this.searchText);
    }
    this.utils.ToggleLoader(false, this.captions.lbl_processing);
  }

}
