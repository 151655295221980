import { ButtonValue, AgInputFieldConfig, AgDateConfig, AgDropdownConfig } from 'src/app/common/Models/ag-models';
import { Input, Output, Component, OnInit, Inject, ViewEncapsulation, EventEmitter } from '@angular/core';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { ActionMode, AlertType } from 'src/app/common/shared/shared/enums/enums';
import { of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction, PolicyType } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ApplyWithinType, ChargeType, ScheduleType, TeeTimePriceType } from '../deposit-policy.model';

@Component({
  selector: 'app-create-deposit-policy',
  templateUrl: './create-deposit-policy.component.html',
  styleUrls: ['./create-deposit-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateDepositPolicyComponent implements OnInit {

  depositPolicyForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  policyCodeConfig: AgInputFieldConfig;
  policyNameConfig: AgInputFieldConfig;
  policyDescriptionConfig: AgInputFieldConfig;
  codeMaxLength: number = 10;
  PolicyTypeEnum = PolicyType;
  TeeTimePriceEnum = TeeTimePriceType;
  ChargeTypeEnum = ChargeType;
  floatLabel: string;
  addChargeButton: ButtonValue;
  ApplyWithinEnum = ApplyWithinType;
  scheduleTypeOptions : {id:number,value:string,viewValue:string}[] = [];
  ScheduleTypeEnum = ScheduleType;
  @Input() inputs;
  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();
  applyWithinHoursDaysMaxLength: number = 9;
  depositDropDownInput: AgDropdownConfig;
  charges: FormArray;
  @Input() chargeOptions : any[] = [];
  @Input() isViewOnly = true;
  isValidateSuccess:boolean=false;
  isScheduleTypeSelected: boolean=false;

  constructor(private localization: Localization,
    private fb: UntypedFormBuilder, private utils: CommonUtilities,) { }

    ngOnInit(): void {
      this.captions = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel;
      this.scheduleTypeOptions = this.getScheduleOptions();
      this.formGenerator();
      this.depositPolicyForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
        this.checkIfScheduleTypeSelected();
        this.updateActionButtonState();
      });
      if (this.isViewOnly) {
        this.disableControls();
      }
    }

    private disableControls() {
      this.utils.disableControls(this.depositPolicyForm, ["policyType"]);
        this.policyCodeConfig.disabled = true;
        this.policyCodeConfig = {...this.policyCodeConfig};
        this.policyNameConfig.disabled = true;
        this.policyNameConfig = {...this.policyNameConfig};
        this.policyDescriptionConfig.disabled = true;
        this.policyDescriptionConfig = {...this.policyDescriptionConfig};
        this.actionButton.disabledproperty = true;
        this.actionButton = {...this.actionButton };
        this.addChargeButton.disabledproperty = true;
        this.addChargeButton = {...this.addChargeButton };
    }

    isPercentValueExceed() : boolean {
      let array = this.depositPolicyForm.controls.charges as UntypedFormArray;
      array.controls.forEach(x=>{
        const charge = x.get('charge');
        if(charge.hasError('max'))
          return false;
      })
      return true;
    }

    updateActionButtonState() {
      this.actionButton.disabledproperty = this.isViewOnly || !(this.depositPolicyForm.dirty && this.depositPolicyForm.valid && this.isScheduleTypeSelected && this.isPercentValueExceed());
    }
  
    formGenerator(){
      this.depositPolicyForm = this.fb.group({
        id: 0,
        isActive: true,
        policyCode: '',
        policyName: '',
        policyDescription : '',
        startDate: '',
        endDate: '',
        includeCharges:'',
        teetimePrice: this.TeeTimePriceEnum.FullPrice,
        percentOrAmount: this.ChargeTypeEnum.Percentage,
        percent:'',
        amount:'',
        charges: this.fb.array([
          this.fb.group({
            scheduleType: 0,
            applyWithinHoursDays: [0, Validators.required],
            priceType: this.TeeTimePriceEnum.FullPrice,
            chargeType: this.ChargeTypeEnum.Percentage,
            charge: 0,
          })
        ])
      })
      this.policyCodeConfig = {
        form: this.depositPolicyForm,
        formControlName: 'policyCode',
        placeHolder: this.captions.tbl_hdr_policyCode,
        maxlength: this.codeMaxLength,
        showRequired: true,
        errorMessage: this.captions.missing_policyCode,
        inputType: 'capitalise,notallowspace,nospecailchar',
        hideplaceholder: true,
        automationId : "Txt_CreateDepositPolicy_policyCode",
        disabled: this.inputs.mode == ActionMode.update
      };
      this.policyNameConfig = {
        form: this.depositPolicyForm,
        formControlName: 'policyName',
        placeHolder: this.captions.tbl_hdr_policyName,
        showRequired: true,
        errorMessage: this.captions.missing_policyName,
        maxlength: 80,
        inputType: 'noprespace',
        automationId : "Txt_CreateDepositPolicy_policyName",
        hideplaceholder: true
      };
      this.policyDescriptionConfig = {
        form: this.depositPolicyForm,
        formControlName: 'policyDescription',
        placeHolder: this.captions.policyDescription,
        maxlength: 200,
        showRequired: false,
        className: 'w-100'
      };
      this.actionButton = {
        type: "primary",
        label: this.inputs.mode === ActionMode.update ? this.captions.btn_update : this.captions.btn_save,
        disabledproperty: true,
      };
      this.cancelButton = {
        type: "tertiary",
        label: this.captions.btn_cancel,
      };
      this.addChargeButton = {
        type: "secondary",
        label: this.captions.btn_addCharge,
      }

      this.depositDropDownInput = {
        form: this.depositPolicyForm,
        formControlName: 'includeCharges',
        selectOptions: of(this.chargeOptions),
        isMultiSelect: true,
        placeHolder: this.captions.lbl_includeCharges,
        automationId : "Dd_CreateDepositPolicy_includeCharges",
        isAll: true,
        isAllSelected: false,
        dropdownWithSearch: false
      }

      if (this.inputs != null) {
        if (this.inputs.mode == ActionMode.update) {
          this.patchFormValues(this.inputs.form);
        }
        if (this.inputs.mode == ActionMode.copy) {
          this.inputs.form.code = '';
          this.patchFormValues(this.inputs.form);
        }
      }
    }

     patchFormValues(data: any){                 
        var Ids = data.depositPolicyDetails[0].includeCharges ? data.depositPolicyDetails[0].includeCharges.split(',').map(Number):[];
        var includedCharges = Ids.length > 0 ? JSON.stringify(this.chargeOptions.filter((x)=>Ids.includes(x.id))) : null;

        this.depositPolicyForm.patchValue({
          id: data.id, 
          policyCode: data.code,
          policyName: data.name,
          policyDescription : data.description, 
          includeCharges: includedCharges != null ? JSON.parse(includedCharges): '',                  
          isActive: data.isActive,         
        });
    
        this.charges = this.depositPolicyForm.get('charges') as FormArray;
        this.charges.clear();
        data.depositPolicyDetails.forEach(charge => {         
          this.charges.push(this.fb.group({

            id: charge.id,                                     
            scheduleType: charge.scheduleType,
            applyWithinHoursDays: charge.numberOfDays,
            priceType: charge.isFullPrice ? TeeTimePriceType.FullPrice : TeeTimePriceType.CustomPrice,
            chargeType: charge.isPercentage ? ChargeType.Percentage : ChargeType.Amount,
            charge: this.localization.localizeCurrency(charge.charge, false, 2)           
          }));          
        });
    
      }
  
    addCharge(e){
      const controls=this.fb.group({
          scheduleType: 0,
          applyWithinHoursDays: [0, Validators.required],
          priceType: this.TeeTimePriceEnum.FullPrice,
          chargeType: this.ChargeTypeEnum.Percentage,
          charge: 0,
        })
      
      if(this.isAtTimeOfBookingPresent()) {
        var scheduleTypeControl = controls.get('scheduleType');
        scheduleTypeControl?.setValue(2);
        const applyWithinControl = controls.get('applyWithinHoursDays');
        applyWithinControl?.setValue(1);
      }        
      
      let array = this.depositPolicyForm.controls.charges as UntypedFormArray;
      array.push(controls);
      this.depositPolicyForm.markAsDirty();
      this.depositPolicyForm.updateValueAndValidity();
    }

    ScheduleTypeDisable(i){
      const chargesGroup = this.getChargesFormGroupForIndex(i);
      if (chargesGroup) {
        const scheduleControl = chargesGroup.get('scheduleType');
        if(this.isAtTimeOfBookingPresent() && scheduleControl?.value!=1){
          scheduleControl?.setValue(2);
          return true;
        }
        else
          return false;
      }
    }

    isAtTimeOfBookingPresent() : boolean {
      var isAtTimeOfBookingSelected = false;
      const chargesArray = this.depositPolicyForm.get('charges') as FormArray;  
      chargesArray.controls.forEach(x=>{
        if(x.value.scheduleType==1) {
          isAtTimeOfBookingSelected = true;            
        }              
      });
      return isAtTimeOfBookingSelected;
    }

    onScheduleTypeChange(event,i) {
      this.isScheduleTypeSelected = true;
      this.isAtTimeOfBookingPresent();
        const chargesGroup = this.getChargesFormGroupForIndex(i);
      if (chargesGroup) {
        const applyWithinControl = chargesGroup.get('applyWithinHoursDays');
        var applyWithinVal = event.value == 1 ? 0 : 1;        
        applyWithinControl?.setValue(applyWithinVal);
      }
    }

    checkIfScheduleTypeSelected() {
      let array = this.depositPolicyForm.controls.charges as UntypedFormArray;
      array.controls.forEach(x=>{
        if(x.value.scheduleType>0)
          this.isScheduleTypeSelected = true;
        else
        this.isScheduleTypeSelected = false;
      })
      
    }

    checkValue(){
  
    }
  
    onDeleteRow(index){
      let array = this.depositPolicyForm.controls.charges as UntypedFormArray;
      array.removeAt(index);
      this.isAtTimeOfBookingPresent();
      this.depositPolicyForm.markAsDirty();
      this.depositPolicyForm.updateValueAndValidity();
    }    

    getScheduleOptions() {
      return [
        {
          id : 1,
          value:this.captions.lbl_timeofBooking,
          viewValue:this.captions.lbl_timeofBooking
        },
        {
          id : 2,
          value:this.captions.lbl_priortoArrival,
          viewValue:this.captions.lbl_priortoArrival
        }
      ]
    }

    chargeTypeChange(index: number) {
      const chargesGroup = this.getChargesFormGroupForIndex(index);
        if (chargesGroup) {
          const chargeControl = chargesGroup.get('charge');
          chargeControl?.setValue(0);
        }   
    }

    getChargesFormGroupForIndex(index: number): FormGroup{
        const chargesArray = this.depositPolicyForm.get('charges') as FormArray;
        if(chargesArray) {
          const chargesGroup = chargesArray.at(index) as FormGroup;
          return chargesGroup;
        }
        return null;
      }
    
    priceTypeChange(e,i){
      if(e.value == TeeTimePriceType.FullPrice){
        const chargesGroup = this.getChargesFormGroupForIndex(i);
        chargesGroup.controls.chargeType.setValue(ChargeType.Percentage);
        const chargeControl = chargesGroup.get('charge');
          chargeControl?.setValue(0);
      }
    }
  
    onActiveChange(e){
      this.depositPolicyForm.updateValueAndValidity();
    }

    validateDepositPolicy() {
      let charges = this.depositPolicyForm.get('charges') as FormArray;
      let chargeValues = charges.value;
      let priorToArrivalCharge = charges.value.filter(x=>x.scheduleType==2);
      let applyWithinValues: Set<number> = new Set();
      if (chargeValues?.length > 0) {
        priorToArrivalCharge.forEach(c => {
          if(c.scheduleType==2) {
            applyWithinValues.add(c.applyWithinHoursDays);
          }
        })
        let isDuplicateAvailable = applyWithinValues.size < priorToArrivalCharge?.length;
        let isMinApplyWithin = applyWithinValues.has(0);
        if(isMinApplyWithin){
          this.utils.showError(this.captions.err_PriorToArrivalMinDays);
        } else if (isDuplicateAvailable) {
          this.utils.showError(this.captions.err_DuplicateApplyWithinDay);
        }
        this.isValidateSuccess = !isDuplicateAvailable && !isMinApplyWithin;
        return this.isValidateSuccess;
      } 
    }
  
    onAction(e){
      if(this.validateDepositPolicy()){
      const clickReturnValue = {
        from: this.inputs.mode,
        formValues: this.depositPolicyForm.getRawValue(),
        form: this.depositPolicyForm
      };
      this.handleClickEvent.emit(clickReturnValue);
    } 
    }
  
    onCancel(eve) {
      const clickReturnValue = {
        from: ActionMode.cancel,
        formValues: this.depositPolicyForm.getRawValue(),
        form: this.depositPolicyForm
      };
      if (this.depositPolicyForm.dirty) {
        this.utils.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res == AlertAction.YES) {
            this.handleClickEvent.emit(clickReturnValue);
          }
        })
      }
      else {
        this.handleClickEvent.emit(clickReturnValue);
      }
    }

    restrictApplyWithinLength(event: KeyboardEvent) {
      const input = event.target as HTMLInputElement;
      if (input.value.length >= this.applyWithinHoursDaysMaxLength && event.key != 'Backspace' && event.key != 'Delete')
        event.preventDefault();
    }

}


