import { Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { AgToggleConfig, TableHeaderOptions, TableOptions, AgDateRangeConfig,AgTimeConfig } from 'src/app/common/Models/ag-models';
import { Days } from 'src/app/appointment/spa-wizard/spa-wizard.modal';
import { cloneDeep } from 'lodash';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CompareKey } from 'src/app/common/constants';

@Component({
  selector: 'app-entry-period',
  templateUrl: './entry-period.component.html',
  styleUrls: ['./entry-period.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntryPeriodComponent implements OnInit {

  activeToggle: AgToggleConfig;
  dateRangeInputs: AgDateRangeConfig;
  startTimeInput: AgTimeConfig;
  endTimeInput: AgTimeConfig;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  entryPeriodForm: UntypedFormGroup;
  captions : any;
  isViewOnly: boolean;
  headerOptions : any=[];
  floatLabel : string;
  minStartDate :any;
  days = [Days.Sunday, Days.Monday, Days.Tuesday, Days.Wednesday, Days.Thursday, Days.Friday, Days.Saturday];
  enabledDays: any = [];
  isEdit:boolean=false;
  @Output() emitForm = new EventEmitter();
  @Input() editEntryPeriodData;
  

  constructor(private fb: FormBuilder,private localization: Localization,
    public PropertyInfo: SpaPropertyInformation,private utils : CommonUtilities
  ) { 
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }
  

  ngOnInit(): void {
    this.initialize();
    this.minStartDate = this.PropertyInfo.CurrentDate;
    if(this.editEntryPeriodData) {
      this.entryPeriodForm.patchValue(this.editEntryPeriodData);
      this.isEdit=true;
      this.enableDisableDays();
    }
     this.entryPeriodForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.emitForm.emit(this.entryPeriodForm);
     });
  }

  initialize() {
    this.entryPeriodForm = this.fb.group({
      id:0,
      name : '',
      code : '',
      startDate: '',
      endDate: '',
      startTime:'',
      endTime:'', 
      activeToggle : true,
      allDays: '',
      Sun: '',
      Mon: '',
      Tue: '',
      Wed: '',
      Thu: '',
      Fri: '',
      Sat: '',
    });
    this.dateRangeInputs = {
      form: this.entryPeriodForm,
      startDateControlName: 'startDate',
      endDateControlName: 'endDate',
      placeHolder: "Date",
      isDateRequired: true,
      className: 'ag_w--100',
      errorMessage: this.captions.lbl_dateRange,
      minDate:this.localization.getDate(this.PropertyInfo.CurrentDate)
    }
    this.startTimeInput = {
      form: this.entryPeriodForm,
      formControlName: 'startTime',
      placeHolder: this.captions.lbl_startTime,
      placeHolderId: 'lbl_time',
      className: 'ag_w--100',
      isTimeRequired: true,
      automationId:'txt_entryPeriod_startTime'
    }
    this.endTimeInput = {
      form: this.entryPeriodForm,
      formControlName: 'endTime',
      placeHolder: this.captions.lbl_endTime,
      placeHolderId: 'lbl_time',
      className: 'ag_w--100',
      isTimeRequired: true,
      automationId:'txt_entryPeriod_endTime'
    }
    this.activeToggle = {
      group: this.entryPeriodForm,
      formControlName: 'activeToggle',
      label: 'Active',
      horizontal: false,
      automationId: "'Tog_entryPeriod_activeToggle'"
    }
   this.headerOptions = [
      {
        displayName : this.captions.setting.allDays
      },
      {
        displayName : this.captions.setting.lbl_sun
      },
      {
        displayName : this.captions.setting.lbl_mon
      },
      {
        displayName : this.captions.setting.lbl_tue
      },
      {
        displayName : this.captions.setting.lbl_wed
      },
      {
        displayName : this.captions.setting.lbl_thurs
      },
      {
        displayName : this.captions.setting.lbl_fri
      },
      {
        displayName : this.captions.setting.lbl_sat
      },
      {
        displayName : this.captions.tbl_hdr_action
      }
   ];
   this.disableYeildingDays();
  }
  refresh(){
    this.entryPeriodForm.controls.allDays.setValue("");
    this.entryPeriodForm.controls.Sun.setValue("");
    this.entryPeriodForm.controls.Mon.setValue("");
    this.entryPeriodForm.controls.Tue.setValue("");
    this.entryPeriodForm.controls.Wed.setValue("");
    this.entryPeriodForm.controls.Thu.setValue("");
    this.entryPeriodForm.controls.Fri.setValue("");
    this.entryPeriodForm.controls.Sat.setValue("");

  }
  setAddDays(e){
    let allDays = e.target.value;
    this.entryPeriodForm.controls.Sun.disabled ? '0' : this.entryPeriodForm.controls.Sun.setValue(allDays);
    this.entryPeriodForm.controls.Mon.disabled ? '0' :this.entryPeriodForm.controls.Mon.setValue(allDays);
    this.entryPeriodForm.controls.Tue.disabled ? '0' :this.entryPeriodForm.controls.Tue.setValue(allDays);
    this.entryPeriodForm.controls.Wed.disabled ? '0' :this.entryPeriodForm.controls.Wed.setValue(allDays);
    this.entryPeriodForm.controls.Thu.disabled ? '0' :this.entryPeriodForm.controls.Thu.setValue(allDays);
    this.entryPeriodForm.controls.Fri.disabled ? '0' :this.entryPeriodForm.controls.Fri.setValue(allDays);
    this.entryPeriodForm.controls.Sat.disabled ? '0' :this.entryPeriodForm.controls.Sat.setValue(allDays);
  }
  onInput() {
    
    this.entryPeriodForm.markAsDirty();
    this.entryPeriodForm.updateValueAndValidity();
  }
  startDateChange(eve) {
    // this.enableDisableDays();
  }
  endDateChange(eve) {
    this.enableDisableDays();
  }
  enableDisableDays() {
    const startDate = cloneDeep(this.entryPeriodForm.controls.startDate.value);
    const endDate = cloneDeep(this.entryPeriodForm.controls.endDate.value);
    // const start = new Date(this.entryPeriodForm.controls.startDate.value);
    // const end = new Date(this.entryPeriodForm.controls.endDate.value);
    this.enabledDays=[];
    // this.disableYeildingDays();
    if (startDate && endDate) {

      // const fromDay = new Date(start).getDay();

      // const endDay = new Date(end).getDay();

      const diffTime = endDate - startDate;
    let daysDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let isSingleDay =  this.utils.ValidateDates(startDate,endDate) == CompareKey.equal ? true : false
    daysDiff = isSingleDay ? 1 : daysDiff + 1;

      // const diffTime = this.localization.getDateDifference(start, end);

      // const daysDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (daysDiff >= 7) {

        this.entryPeriodForm.controls.Sun.enable();
        this.entryPeriodForm.controls.Mon.enable();
        this.entryPeriodForm.controls.Tue.enable();
        this.entryPeriodForm.controls.Wed.enable();
        this.entryPeriodForm.controls.Thu.enable();
        this.entryPeriodForm.controls.Fri.enable();
        this.entryPeriodForm.controls.Sat.enable();
        this.entryPeriodForm.controls.allDays.enable();
      } else if(daysDiff < 7) {
        for (let i = 0; i < daysDiff; i++) {
          this.enableYeildingDay(startDate.getDay());
          this.enabledDays.push(startDate.getDay());
          startDate.setDate(startDate.getDate() + (i == 0 ? (i + 1) : 1));
        }
        let unavailableDays = this.days.filter(day => !this.enabledDays.includes(day));
        for( let i = 0; i<= unavailableDays.length;i++) {
          this.disableEntryPeriodDays(unavailableDays[i])
        }
      } else {
        this.disableYeildingDays();
      }
    }
  }

  enableYeildingDay(day) {
    switch (day) {
      case Days.Sunday:
        this.entryPeriodForm.controls.Sun.enable();
        break;
      case Days.Monday:
        this.entryPeriodForm.controls.Mon.enable();
        break;
      case Days.Tuesday:
        this.entryPeriodForm.controls.Tue.enable();
        break;
      case Days.Wednesday:
        this.entryPeriodForm.controls.Wed.enable();
        break;
      case Days.Thursday:
        this.entryPeriodForm.controls.Thu.enable();
        break;
      case Days.Friday:
        this.entryPeriodForm.controls.Fri.enable();
        break;
      case Days.Saturday:
        this.entryPeriodForm.controls.Sat.enable();
        break;
    }
    this.entryPeriodForm.controls.allDays.enable();
  }

  disableYeildingDays() {
    this.entryPeriodForm.controls.allDays.disable();
    this.entryPeriodForm.controls.Sun.disable();
    this.entryPeriodForm.controls.Mon.disable();
    this.entryPeriodForm.controls.Tue.disable();
    this.entryPeriodForm.controls.Wed.disable();
    this.entryPeriodForm.controls.Thu.disable();
    this.entryPeriodForm.controls.Fri.disable();
    this.entryPeriodForm.controls.Sat.disable();
  }

  disableEntryPeriodDays(day) {
    
    switch (day) {
      case Days.Sunday:
        this.entryPeriodForm.controls.Sun.disable();
        this.entryPeriodForm.controls.Sun.setValue('');
        break;
      case Days.Monday:
        this.entryPeriodForm.controls.Mon.disable();
        this.entryPeriodForm.controls.Mon.setValue('');
        break;
      case Days.Tuesday:
        this.entryPeriodForm.controls.Tue.disable();
        this.entryPeriodForm.controls.Tue.setValue('');
        break;
      case Days.Wednesday:
        this.entryPeriodForm.controls.Wed.disable();
        this.entryPeriodForm.controls.Wed.setValue('');
        break;
      case Days.Thursday:
        this.entryPeriodForm.controls.Thu.disable();
        this.entryPeriodForm.controls.Thu.setValue('');
        break;
      case Days.Friday:
        this.entryPeriodForm.controls.Fri.disable();
        this.entryPeriodForm.controls.Fri.setValue('');
        break;
      case Days.Saturday:
        this.entryPeriodForm.controls.Sat.disable();
        this.entryPeriodForm.controls.Sat.setValue('');
        break;
    }
  }
  pickerOpenChange(eve) {

  }
  startTimeChange(eve) {
    this.endTimeInput.minTime = this.entryPeriodForm.controls.startTime.value;
    this.endTimeInput.minTime = this.localization.getTime(this.localization.AddMinutes(this.localization.TimeToDate(this.entryPeriodForm.controls.startTime.value), 1), this.localization.getTimeFormat());
    this.endTimeInput = {...this.endTimeInput};
  }
  EndtimeChange(eve) {

  }
  toggleChange(event) {
    let checked = event.checked;
    this.entryPeriodForm.controls.activeToggle.setValue(event.checked);
  }

}
