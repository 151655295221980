import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "./http-call.service";
import { GridViewType } from "../calendar-grid/calendar-grid-models/calendar-grid.models";
import { Host, ActionType, GridTimeInterval, SPAScheduleBreakPoint } from "../globalsContant";
import { BaseResponse, appointment, appointmentDetail, ManagementData } from "../business/shared.modals";
import { SpaUtilities } from "../utilities/spa-utilities";
import * as moment from "moment";
import { AppointmentpopupService } from "./appointmentpopup.service";
import { appointmentService } from "./appointment.service";
import { AppointmentPopupComponent } from "../appointment-popup/appointment-popup.component";
import { MatDialog } from "@angular/material/dialog";
import {  TherapistDetails,  ServiceLocations } from "../business/view-settings.modals";
import { SpaPropertyInformation } from "../../core/services/spa-property-information.service";
import { AppointmentUtilities } from "../utilities/appointment-utilities";
import { AppointmentActionsDialogComponent } from "../appointment-actions/appointment-actions-dialog/appointment-actions-dialog.component";
import { SlideInformationService } from "../slide-information/slide-information.service";
import { SpaLocalization } from "../../core/localization/spa-localization";
import { BreakPointAccess } from "./breakpoint.service";
import { take } from "rxjs/operators";


export interface AppointmentEditParams {
    view: GridViewType;
    gridDate: Date;
    propertyDate: Date;
    headerObject: any; // Therpist or location
    locationId: number;
    therapistId: number;
    callBack: any;
}

export interface ActionIcon {
    id: number;
    icon: string;
    iconText: string;
}

export interface ActionIconData {
    legendstatusMsg: string;
    actionData: ActionIcon[]
}


@Injectable()
export class appointmentEditService {

    captions: any;

    constructor(public dialog: MatDialog, public http: HttpServiceCall, public utils: SpaUtilities,
        public appointmentpopservice: AppointmentpopupService, public apptService: appointmentService, private PropertyInfo: SpaPropertyInformation,
        public appUtils: AppointmentUtilities, public SlideService: SlideInformationService, private localization: SpaLocalization, private breakPoint: BreakPointAccess) {
        this.captions = this.localization.captions.bookAppointment;
    }

    public async EditAppointment(id: number, editParams: Partial<AppointmentEditParams>): Promise<void> {
        await this.getDataForEditAppointment(id, editParams);
    }

    private async getDataForEditAppointment(id: number, editParams: Partial<AppointmentEditParams>) {
        let result: BaseResponse<appointment[]> = await this.http.CallApiAsync<any>({
            callDesc: "GetAppointmentsWithTempCreated",
            uriParams: { id: id },
            host: Host.schedule,
            method: HttpMethod.Get
        });

        let listofAllAppointments: any = result.result;
        let listofAppointments = listofAllAppointments.filter(res => res.appointmentDetail.status !== "TEMP");

        let appointmentData: any = listofAppointments.filter(x => x.appointmentDetail.id == id)[0];

        let therapistId: number = appointmentData.appointmentTherapists[0].therapistId;

        let therapistDetailsResult: BaseResponse<TherapistDetails> = await this.http.CallApiAsync<any>({
            callDesc: "GetTherapistDetailById",
            uriParams: { id: therapistId },
            host: Host.spaManagement,
            method: HttpMethod.Get
        });
        let therapistDetail = therapistDetailsResult.result;

        let locationDetailsResult: BaseResponse<ServiceLocations[]> = await this.http.CallApiAsync<any>({
            callDesc: "GetAllLoc",
            host: Host.spaManagement,
            method: HttpMethod.Get
        });
        let alllocationDetails = locationDetailsResult.result;
        let locationDetaills = alllocationDetails.filter(x => x.id == appointmentData.appointmentDetail.locationId)[0];

        let defaultData: any = {
            TherapistId: therapistDetail.id,
            lastName: therapistDetail.lastName,
            firstName: therapistDetail.firstName,
            gender: therapistDetail.gender,
            LocationId: appointmentData.appointmentDetail.locationId,
            description: locationDetaills.description,
            time: this.localization.LocalizeTime(appointmentData.appointmentDetail.startTime)
        }

        var obj: any = { id: id, action: "Edit", defaultValue: defaultData };



        let listTempAppointments = listofAllAppointments.filter(res => res.appointmentDetail.status === "TEMP");
        this.appointmentpopservice.TempHoldArr = this.appUtils.formTempHoldObjects(listTempAppointments);
        this.appointmentpopservice.resultExistingAppointment = this.appUtils.formAppointmentObject(listofAppointments);
        this.appointmentpopservice.resultExistingClients = this.formClientIdsObject(listofAppointments);
        this.appointmentpopservice.existingAppointmentsRef = listofAllAppointments.filter(res => res.appointmentDetail.status !== "TEMP");

        let listOfTherapistToBeAdded: number[] = [];


        this.appointmentpopservice.resultExistingAppointment.appointmentTherapists.filter(res => {
            if (!listOfTherapistToBeAdded.includes(res.therapistId)) {
                listOfTherapistToBeAdded.push(res.therapistId);
            }
        });
        if (listofAppointments.length > 1) {
            listofAppointments.forEach(res => {
                let appointmentTherapist = this.appointmentpopservice.resultExistingAppointment.appointmentTherapists.filter(k => k.appointmentId == res.id);
                let appointmentDetails = listofAppointments.filter(elem => elem.appointmentDetail.id === appointmentTherapist[0].appointmentId);
                this.appointmentpopservice.multiClientInfo.push({ "id": appointmentDetails[0].appointmentDetail.clientId, "comments": appointmentDetails[0].appointmentDetail.clientComments, "TherapistId": appointmentTherapist[0].therapistId, "priceTypeId": appointmentDetails[0].appointmentDetail.priceTypeId, "price": appointmentDetails[0].appointmentDetail.price , "discountType" : appointmentDetails[0].appointmentDetail.discountType?appointmentDetails[0].appointmentDetail.discountType:"","guestTypeId":appointmentDetails[0].appointmentDetail.guestTypeId??0 });
            })
        }
        else {
            this.appointmentpopservice.multiClientInfo.push({ "id": listofAppointments[0].appointmentDetail.clientId, "comments": listofAppointments[0].appointmentDetail.clientComments, "TherapistId": 0, "priceTypeId": listofAppointments[0].appointmentDetail.priceTypeId, "price": listofAppointments[0].appointmentDetail.price, "discountType" : listofAppointments[0].appointmentDetail.discountType?listofAppointments[0].appointmentDetail.discountType:'',"guestTypeId":listofAppointments[0]?.appointmentDetail?.guestTypeId??0});
        }

        this.LoadOtherDetails(this.appointmentpopservice.resultExistingAppointment.appointmentDetail);


        this.appointmentpopservice.popupTitle = this.captions.EDITAPPOINTMENT;
        this.appointmentpopservice.isEditAppointment = true;
        this.appointmentpopservice.isPreviousDayAppt = this.utils.GetDateWithoutTime(appointmentData.appointmentDetail.startTime) < this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate);
        this.appointmentpopservice.GetClientIdsForMultiAppointment(appointmentData.appointmentDetail.id);

        let data = obj.defaultValue;
        data.closebool = true;
        data.LocationId = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.locationId;
        data.ServiceGroupId = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.serviceGroupId;
        data.ServiceId = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.serviceId;
        data.LocationName = locationDetaills.description;
        let dialogRef = this.dialog.open(AppointmentPopupComponent, {
            width: '95%',
            height: '85%',
            disableClose: true,
            hasBackdrop: true,
            data: data,
            panelClass: 'small-popup'
        });

           dialogRef.afterClosed().subscribe(res => {

            this.appointmentpopservice.multiClientInfo = [];
            let extraPrams = [];
            // Refresh Search Appointment Grid
            if (this.appointmentpopservice.isFromAppointmentSearch) {
                this.appointmentpopservice.isFromAppointmentSearch = false;
                this.apptService.refreshSearchAppointmentGrid = !this.apptService.refreshSearchAppointmentGrid;
            }

            if (editParams && editParams.callBack) {
                editParams.callBack(extraPrams);
            }

        });
        this.appointmentpopservice.showDetails = false;

    }

    /**
     *
     * @param appointment This Method returns action icon details for appointment status
     * @param params
     */
    public getActionIcons(appointmentDetails: appointment, params: AppointmentEditParams) {
        let result: ActionIconData = {
            legendstatusMsg: "",
            actionData: []
        };
        let Appointmentstatus = appointmentDetails.appointmentDetail.status;
        if (Appointmentstatus == "CKIN") {
            result.legendstatusMsg = this.captions.CheckedIn;
            result.actionData = [{ icon: 'icon-recurring', iconText: this.captions.Recurring, id: 1 },
            { icon: 'icon-edit1', iconText: this.captions.Edit, id: 2 },
            { icon: 'icon-changeservice', iconText: this.captions.ChangeService, id: 13 },
            { icon: 'icon-copy', iconText: this.captions.Copy, id: 4 },
            { icon: 'icon-move', iconText: this.captions.Move, id: 5 },
            { icon: 'icon-swap', iconText: this.captions.Swap, id: 6 },
            { icon: 'icon-print', iconText: this.captions.Print, id: 7 },
            { icon: 'icon-recap', iconText: this.captions.RecapAppointment, id: 8 },
            { icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 },
            { icon: 'icon-serviceaddon', iconText: this.captions.ServiceAddOn, id: 10 },
            { icon: 'icon-link', iconText: this.captions.LinkMultipack, id: 11 },
            { icon: 'icon-undo', iconText: this.captions.UndoCheckIn, id: 16 },
            { icon: 'icon-lunch', iconText: this.captions.BookLunch, id: 12 }
            ];
        }
        else if (Appointmentstatus == "CKOUT") {
            result.legendstatusMsg = this.captions.CheckedOut;
            result.actionData = [{ icon: 'icon-recurring', iconText: this.captions.Recurring, id: 1 },
            { icon: 'icon-edit1', iconText: this.captions.Edit, id: 2 },
            { icon: 'icon-lunch', iconText: this.captions.BookLunch, id: 12 },
            { icon: 'icon-copy', iconText: this.captions.Copy, id: 4 },
            { icon: 'icon-recap', iconText: this.captions.RecapAppointment, id: 8 },
            { icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 },
            { icon: 'icon-print', iconText: this.captions.Print, id: 7 }
            ];

        }
        else if (Appointmentstatus == "CLOSED") {
            result.legendstatusMsg = this.captions.Closed;
            if (params.view == GridViewType.TherapistView) {
                result.actionData = [
                    { icon: 'icon-mug', iconText: this.captions.TherapistBreak, id: 14 }];
            }
            else {
                result.actionData = [{ icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 }];
            }
        }
        else if (Appointmentstatus == "BREAK" && params.locationId == 0) {
            result.legendstatusMsg = this.captions.Break;
            result.actionData = [{ icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 }, { icon: 'icon-delete', iconText: this.captions.Delete, id: 20 }];
        }
        else if (Appointmentstatus == "NOSHOW") {
            let currentBusinessDate = this.utils.GetDateWithoutTime(params.propertyDate)
            let currentGridDate = this.utils.GetDateWithoutTime(params.gridDate)
            result.legendstatusMsg = this.captions.NoShow;
            result.actionData = [{ icon: 'icon-recurring', iconText: this.captions.Recurring, id: 1 },
            { icon: 'icon-edit1', iconText: this.captions.Edit, id: 2 },
            { icon: 'icon-copy', iconText: this.captions.Copy, id: 4 },
            { icon: 'icon-move', iconText: this.captions.Move, id: 5 },
            { icon: 'icon-swap', iconText: this.captions.Swap, id: 6 },
            { icon: 'icon-print', iconText: this.captions.Print, id: 7 },
            { icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 },
            { icon: 'icon-recap', iconText: this.captions.RecapAppointment, id: 8 }
            ];
            if (currentGridDate >= currentBusinessDate) {
                result.actionData.splice(2, 0, { icon: 'icon-link', iconText: this.captions.LinkMultipack, id: 11 })
            }


        }
        else if (Appointmentstatus == "RESV") {
            result.legendstatusMsg = this.captions.Scheduled;
            result.actionData = [{ icon: 'icon-recurring', iconText: this.captions.Recurring, id: 1 },
            { icon: 'icon-edit1', iconText: this.captions.Edit, id: 2 },
            { icon: 'icon-cancel', iconText: this.captions.Cancel, id: 3 },
            { icon: 'icon-copy', iconText: this.captions.Copy, id: 4 },
            { icon: 'icon-move', iconText: this.captions.Move, id: 5 },
            { icon: 'icon-swap', iconText: this.captions.Swap, id: 6 },
            { icon: 'icon-print', iconText: this.captions.Print, id: 7 },
            { icon: 'icon-recap', iconText: this.captions.RecapAppointment, id: 8 },
            { icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 },
            { icon: 'icon-serviceaddon', iconText: this.captions.ServiceAddOn, id: 10 },
            { icon: 'icon-link', iconText: this.captions.LinkMultipack, id: 11 },
            { icon: 'icon-lunch', iconText: this.captions.BookLunch, id: 12 },
            { icon: 'icon-changeservice', iconText: this.captions.ChangeService, id: 13 }
            ];
        }
        else {
            result.legendstatusMsg = this.captions.Closed;
            if (params.view == GridViewType.TherapistView) {
                result.actionData = [{ icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 },
                { icon: 'icon-mug', iconText: this.captions.TherapistBreak, id: 14 }];
            }
            else {
                result.actionData = [{ icon: 'icon-overbook', iconText: this.captions.Overbook, id: 9 }];
            }
        }
        return result;
    }

    private LoadOtherDetails(appointmentDetails: appointmentDetail) {
        debugger;
        this.appointmentpopservice.otherdetails.vip = appointmentDetails.isVip;
        this.appointmentpopservice.otherdetails.gender = appointmentDetails.genderPreference;
        this.appointmentpopservice.otherdetails.checkout = appointmentDetails.checkOutComments;
        //this.appointmentpopservice.otherdetails.guestType = appointmentDetails.guestTypeId;
        this.appointmentpopservice.otherdetails.customField1 = appointmentDetails.customField1;
        this.appointmentpopservice.otherdetails.customField2 = appointmentDetails.customField2;
        this.appointmentpopservice.otherdetails.customField3 = appointmentDetails.customField3;
        this.appointmentpopservice.otherdetails.customField4 = appointmentDetails.customField4;
        this.appointmentpopservice.otherdetails.customField5 = appointmentDetails.customField5;
    }


    private formClientIdsObject(result: any) {
        let appClient: number[] = [];
        for (let appt of result) {
            appClient.push(appt.appointmentDetail.clientId);
        }
        return appClient;
    }

    async InvokeAppointmentAction(appointmentId: number, action: number, clientName = '') {
        if (!this.IsAuthorized(action)) {
            return;
        }
        // Check Appointment Lock
        if (await this.appointmentpopservice.IsAppointmentLockedbyOtherSession(appointmentId)) {
            return;
        }
        let [appointments, managementData] = await Promise.all([this.GetAppointmentById(appointmentId), this.GetManagementData()]);
        if (appointments && appointments.appointmentDetail) {
            this.MapAppointmentResources(appointments, managementData, clientName);
            if (action == ActionType.checkout) {
                this.OpenActionsDialog(action, this.localization.captions.bookAppointment.CheckOutAppointment);
            }
        }
    }

    async GetAppointmentById(appointmentId: number): Promise<any> {
        let appointmentData = null;
        let uriParam = { id: appointmentId };
        let apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetAppointmentById', Host.schedule, HttpMethod.Get, uriParam);
        if (apiResponse && apiResponse.successStatus && apiResponse.result) {
            appointmentData = apiResponse.result;
        }
        return appointmentData;
    }

    async GetManagementData(): Promise<any> {
        let apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync("GetManagementData", Host.spaManagement, HttpMethod.Get);
        if (apiResponse && apiResponse.successStatus && apiResponse.result) {
            let response: ManagementData = <any>apiResponse.result;
            //let managementData: any[] = [];
            this.apptService.managementData["Service"] = response.service;
            this.apptService.managementData["Therapist"] = response.therapist;
            this.apptService.managementData["Location"] = response.location;
            this.apptService.managementData["AddOn"] = response.addOn;
            this.apptService.managementData["AppointmentConfigurations"] = response.appointmentConfigurations[0];
            this.apptService.managementData["LinkCode"] = response.linkCode;
            this.apptService.managementData["BreakType"] = response.breakType;
            this.SlideService.fieldData.managementData = this.apptService.managementData;
            //this.apptService.managementData = managementData;
        }
    }

    private GetTherapistName(therapistId: number[]): string[] {
        let allTherapist = this.SlideService.fieldData.managementData["Therapist"];
        let therapName = [];
        therapistId.forEach(r => {
            const therapist: any = allTherapist.find(res => res.id == r);
            if (therapist) {
                therapName.push(`${therapist.firstName} ${therapist.lastName}`);
            }
        });
        return therapName;
    }

    GetServiceDescById(id: number): string {
        let serviceName = '';
        let serviceList: any[] = this.SlideService.fieldData.managementData["Service"];
        if (serviceList) {
            const service: any = serviceList.find(res => res.id == id);
            if (service) {
                serviceName = service.description;
            }
        }
        return serviceName;
    }

    GetLocationNameById(id: number): string {
        let locationName: string = '';
        let locationList: any[] = this.SlideService.fieldData.managementData["Location"];
        if (locationList) {
            let therapist: any = locationList.filter(res => { return res.id == id })[0];
            if (therapist) {
                locationName = therapist.description;
            }
            else {
                locationName = this.localization.captions.setting.Offsite;
            }
        }
        return locationName;
    }

    async MapAppointmentResources(appointmentInfo: any, managementData, clientName) {
        let duration = moment.duration(moment(appointmentInfo.appointmentDetail.endTime).diff(moment(appointmentInfo.appointmentDetail.startTime)));
        let theapistName = this.GetTherapistName(appointmentInfo.appointmentTherapists.map(t => t.therapistId));
        let serviceName = this.GetServiceDescById(appointmentInfo.appointmentDetail.serviceId);
        let locationName = this.GetLocationNameById(appointmentInfo.appointmentDetail.locationId);
        this.SlideService.fieldData = {
            dateField: moment(appointmentInfo.appointmentDetail.startTime).format('YYYY-MM-DDTHH:mm'),
            ServiceGroupId: appointmentInfo.appointmentDetail.serviceGroupId,
            IntakeForm: appointmentInfo.appointmentDetail.IntakeForm,
            ServiceId: appointmentInfo.appointmentDetail.serviceId,
            LocationId: appointmentInfo.appointmentDetail.locationId,
            StartTime: moment(appointmentInfo.appointmentDetail.startTime, "hh:mm").format("hh:mm a"),
            EndTime: moment(appointmentInfo.appointmentDetail.endTime, 'hh:mm a').add(GridTimeInterval, 'm').format('hh:mm a'),
            imageName: '',
            Duration: appointmentInfo.appointmentDetail.duration,
            clientName: clientName,
            TherapistId: appointmentInfo.appointmentTherapists[0].therapistId,
            therapistName: theapistName.join(', '),
            serviceName: serviceName,
            serviceGRPName: '',
            LocationName: locationName, 
            actiondata: [], 
            appointmentId: appointmentInfo.appointmentDetail.id,
            clientId: appointmentInfo.appointmentDetail.clientId ? appointmentInfo.appointmentDetail.clientId : 0,
            managementData: this.apptService.managementData,
            startDTTM: appointmentInfo.appointmentDetail.startTime,
            EndDTTM: appointmentInfo.appointmentDetail.endTime,
            price: appointmentInfo.appointmentDetail.price,
            isTherapistView: true,
            isMultiTherapist: (appointmentInfo.appointmentTherapists.length > 1),
            setupTime: appointmentInfo.appointmentDetail.setUpTime,
            breakdownTime: appointmentInfo.appointmentDetail.breakDownTime,
            isMultiClient: appointmentInfo.appointmentDetail.multiGroupId && appointmentInfo.appointmentDetail.multiGroupId.length > 0,
            appointmentType: appointmentInfo.appointmentDetail.appointmentType,
            DurationPeriod: duration, 
            status: appointmentInfo.appointmentDetail.status,
            clientMultiPackId: appointmentInfo.appointmentDetail.clientMultiPackId,
            transactionId: appointmentInfo.appointmentDetail.transactionId ? appointmentInfo.appointmentDetail.transactionId : 0,
            priceTypeId: appointmentInfo.appointmentDetail.priceTypeId,
            yieldPrice: appointmentInfo.appointmentDetail.yieldPrice,
            linkedAppointmentId: appointmentInfo.appointmentDetail.linkedAppointmentID,
            genderPrefernce : appointmentInfo.appointmentDetail.genderPreference
        }

        this.apptService.otherinfo = `${this.localization.captions.bookAppointment.by} ${theapistName.join(', ')} ${this.localization.captions.bookAppointment.at} ${locationName}`;
        this.apptService.serviceInfo = {
            name: serviceName,
            date: this.utils.FormatAppointmentDateTime(this.SlideService.fieldData.startDTTM, this.SlideService.fieldData.EndDTTM),
            cost: `${this.localization.localizeCurrency(this.SlideService.fieldData.price)}`
        };
    }

    OpenActionsDialog(actionId, popupheader) {
        let panelClass = 'action-dialog-overlay';
        let width = '90%';
        let height = 'auto';
        let maxwidth = "1000px;";

        switch (actionId) {
            case ActionType.changeservice:
            case ActionType.recap:
            case ActionType.booklunch:
            case ActionType.serviceaddon:
            case ActionType.linkmultipack:
            case ActionType.recurring:
            case ActionType.copy:
            case ActionType.move:
                height = '80%';
                break;
            case ActionType.cancel:
            case ActionType.checkin:
            case ActionType.checkout:
            case ActionType.checkin_checkout:
            case ActionType.adddeposit:
                height = '90%';
                break;
            case ActionType.createtherapistbreak:
            case ActionType.modifytherapistbreak:
                height = '60%';
                width = '661px';
                break;
            case ActionType.print:
                width = '350px';
                height = '300px';
                panelClass = '';
                break;
            case ActionType.swap:
                height = '70%';
                break;
        }

        let dialogRef = this.dialog.open(AppointmentActionsDialogComponent, {
            width: width,
            maxWidth: maxwidth,
            height: height,
            hasBackdrop: true,
            data: { id: actionId, headername: popupheader, closebool: true, appointmentInfomation: this.SlideService.fieldData },
            panelClass: panelClass

        });
        dialogRef.afterClosed().pipe(take(1)).subscribe();
    }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this.http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    IsAuthorized(id: ActionType): boolean {
        let isUserAuthorized: boolean = true;
        let breakpointNumber: number[] = [];
        switch (id) {
            case ActionType.changeservice:
                breakpointNumber.push(SPAScheduleBreakPoint.ChangeService);
                break;
            case ActionType.cancel:
                breakpointNumber.push(SPAScheduleBreakPoint.CancelAppointment);
                break;
            case ActionType.checkin:
                breakpointNumber.push(SPAScheduleBreakPoint.CheckInAppointment);
                break;
            case ActionType.undocheckin:
                breakpointNumber.push(SPAScheduleBreakPoint.UndoCheckIn);
                break;
            case ActionType.checkout:
                breakpointNumber.push(SPAScheduleBreakPoint.CheckOutAppointment);
                break;
            case ActionType.checkin_checkout:
                breakpointNumber.push(SPAScheduleBreakPoint.CheckIn_CheckOutAppointment);
                breakpointNumber.push(SPAScheduleBreakPoint.CheckInAppointment);
                breakpointNumber.push(SPAScheduleBreakPoint.CheckOutAppointment);
                break;
            case ActionType.copy:
                breakpointNumber.push(SPAScheduleBreakPoint.CopyAppointment);
                break;
            case ActionType.move:
                breakpointNumber.push(SPAScheduleBreakPoint.MoveAppointment);
                break;
            case ActionType.recurring:
                breakpointNumber.push(SPAScheduleBreakPoint.BookRecurringAppointments);
                break;
            case ActionType.swap:
                breakpointNumber.push(SPAScheduleBreakPoint.SwapAppointment);
                break;
            case ActionType.overbook:
                breakpointNumber.push(SPAScheduleBreakPoint.OverbookTherapist);
                breakpointNumber.push(SPAScheduleBreakPoint.OverbookLocation);
                break;
            case ActionType.adddeposit:
                breakpointNumber.push(SPAScheduleBreakPoint.AddDeposit);
                break;
            case ActionType.linkmultipack:
                if (!this.SlideService.fieldData.clientMultiPackId) {
                    breakpointNumber.push(SPAScheduleBreakPoint.LinkMultipack);
                }
                else {
                    breakpointNumber.push(SPAScheduleBreakPoint.UnlinkMultipack);
                }
                break;
            case ActionType.serviceaddon:
                breakpointNumber.push(SPAScheduleBreakPoint.ServiceAddOn);
                break;
            case ActionType.booklunch:
                breakpointNumber.push(SPAScheduleBreakPoint.BookLunch);
                break;
            case ActionType.edit:
                breakpointNumber.push(SPAScheduleBreakPoint.EditAppointment);
                break;
            case ActionType.recap:
                breakpointNumber.push(SPAScheduleBreakPoint.RecapAppointment);
                break;
        }
        if (breakpointNumber.length > 0) {
            isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
        }
        return isUserAuthorized;
    }

}
