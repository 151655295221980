
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { takeUntil } from 'rxjs/operators';
import { ServiceParams } from '../../Models/http.model';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';
import { rGuesPayRoutes } from '../common-route';
import { AccountingUrl, FolioConstants, HttpResponseConstants, Services } from "../../constants";
import { AlertType, ButtonTypes, Product } from 'src/app/common/enums/shared-enums';
import { environment } from "src/environments/environment";
import { Guid } from "guid-typescript";
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';

export class HttpCallService {

    constructor(host
        , private http: HttpClient
        , private localization: Localization
        , private utilities: CommonUtilities,
        private PropertyInfo: CommonPropertyInformation,
        private appService: AppService) {        
        this.baseURL = host;
    }
    private baseURL: string = '';

    protected get<T>(params: ServiceParams, isLoaderRequired?:boolean,propertyId?:number, isSessionCheckNotRequired?:boolean, delayLoader?: number): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.get<T>(url, { headers: this.setHeaders(isLoaderRequired, propertyId, isSessionCheckNotRequired, url, delayLoader) });
    }

    protected jasperReportGetWithCustomHeaders<T>(params: ServiceParams , responseType: string): Observable<any> {
        let url: string = this.formURL(params);
        if (responseType.toLowerCase() == 'text') {
            return this.http.get(url, { headers: this.setJasperserverCustomHeadersGet(), responseType: 'text' });
        } else {
            return this.http.get(url, { headers: this.setJasperserverCustomHeadersGet(), responseType: 'blob' });
        }
    }
    protected jasperReportPostWithCustomHeaders<T>(params: ServiceParams , responseType: any): Promise<any> {
        this.utilities.ToggleLoader(true);
        let url: string = this.formURL(params);
        return this.http.post(url, params.body,{ headers: this.setJasperserverCustomHeadersPost(), responseType: responseType })
        .toPromise()
        .finally(()=>{
            this.utilities.ToggleLoader(true);
        });
    }
    protected putWithCustomHeaders<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.put<T>(url, params.body, { headers: params.header });
    }

    protected put<T>(params: ServiceParams, isLoaderRequired?:boolean,propertyId?:number, isSessionCheckNotRequired?:boolean, delayLoader?: number): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.put<T>(url, params.body, { headers: this.setHeaders(isLoaderRequired,propertyId, isSessionCheckNotRequired,url, delayLoader) })
    }

    protected reportPut(params: ServiceParams, responseType: string,isLoaderRequired:boolean=false): Observable<any> {        
        if (responseType.toLowerCase() == 'text') {
            return this.http.put(params.route, params.body, { headers: this.setHeaders(isLoaderRequired,undefined,false,params.route), responseType: 'text' });
        } else {
            return this.http.put(params.route, params.body, { headers: this.setHeaders(isLoaderRequired,undefined,false,params.route), responseType: 'blob' });
        }
    }

    protected post<T>(params: ServiceParams, isLoaderRequired?:boolean,propertyId?:number, isSessionCheckNotRequired?:boolean , delayLoader?: number): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.post<T>(url, params.body, { headers: this.setHeaders(isLoaderRequired,propertyId, isSessionCheckNotRequired,url, delayLoader) });
    }

    protected putPromiseWithCustomHeaders<T>(params: ServiceParams): Promise<T> {
        return this.putWithCustomHeaders<T>(params).toPromise();
    }

    protected postWithCustomHeaders<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.post<T>(url, params.body, { headers: params.header });
    }

    protected delete<T>(params: ServiceParams, isLoaderRequired?:boolean, delayLoader?: number): Observable<T> {
        let url: string = this.formURL(params);
        const httpOptions =
        {
            headers: this.setHeaders(isLoaderRequired,undefined,false,url, delayLoader),
            body: params.body
        };
        return this.http.delete<T>(url, httpOptions);
    }

    protected patch<T>(params: ServiceParams, isLoaderRequired?:boolean, delayLoader?: number): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.patch<T>(url, params.body, { headers: this.setHeaders(isLoaderRequired,undefined,false,url, delayLoader) });
    }
    public getPromise<T>(params: ServiceParams, isLoaderRequired?:boolean, isSessionCheckNotRequired?:boolean, handleErr?: boolean, delayLoader?: number): Promise<T> {
        return this.get<T>(params, isLoaderRequired, null,isSessionCheckNotRequired, delayLoader).toPromise();
    }

    public getPromiseWithProperty<T>(params: ServiceParams, propertyId:number): Promise<T> {
        return this.get<T>(params,false,propertyId).toPromise();
    }

    public getCancellablePromise<T>(params: ServiceParams, notifier: Subject<void>): Promise<T> {
        return this.getCancellable<T>(params, notifier).toPromise();
    }


    private getCancellable<T>(params: ServiceParams, notifier: Subject<void>): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.get<T>(url, { headers: this.setHeaders(false,undefined,false,url) })
            .pipe(takeUntil(notifier))
    }

    public putCancellablePromise<T>(params: ServiceParams, notifier: Subject<void>): Promise<T> {
        return this.putCancellable<T>(params, notifier).toPromise();
    }


    private putCancellable<T>(params: ServiceParams, notifier: Subject<void>): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.put<T>(url, params.body, { headers: this.setHeaders(false,undefined,false,url) })
            .pipe(takeUntil(notifier))
    }

    protected putPromise<T>(params: ServiceParams,isLoaderRequired?:boolean, isSessionCheckNotRequired?:boolean, delayLoader?: number): Promise<T> {
        return this.put<T>(params,isLoaderRequired, null, isSessionCheckNotRequired, delayLoader).toPromise();
    }
    protected putPromiseWithProperty<T>(params: ServiceParams,propertyId:number): Promise<T> {
        return this.put<T>(params,false,propertyId).toPromise();
    }

    protected excelPut(params: ServiceParams): Observable<any> {
        let url: string = this.formURL(params);
        return this.http.put(url, params.body, { headers: this.setHeaders(false,undefined,false,url), responseType: 'blob' });
    }

    protected postPromise<T>(params: ServiceParams,isLoaderRequired?:boolean, isSessionCheckNotRequired?:boolean, handleErr?: boolean, delayLoader?: number): Promise<T> {
        return this.post<T>(params,isLoaderRequired, null,isSessionCheckNotRequired, delayLoader).toPromise();
    }

    protected postPromiseWithProperty<T>(params: ServiceParams,propertyId:number,isLoaderRequired?:boolean,): Promise<T> {
        return this.post<T>(params,isLoaderRequired,propertyId).toPromise();
    }
    protected postPromiseWithCustomHeaders<T>(params: ServiceParams): Promise<T> {
        return this.postWithCustomHeaders<T>(params).toPromise();
    }

    protected patchPromise<T>(params: ServiceParams, isLoaderRequired?:boolean, handleErr?: boolean, delayLoader?: number): Promise<T> {
        return this.patch<T>(params, isLoaderRequired, delayLoader).toPromise();
    }

    protected deletePromise<T>(params: ServiceParams, isLoaderRequired?:boolean, handleErr?: boolean, delayLoader?: number): Promise<T> {
        return this.delete<T>(params, isLoaderRequired, delayLoader).toPromise();
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    private formURL(params: ServiceParams): string {
        this.validateRGuestPayURL(params);
        this.validate(params);        
        let url: string = `${this.baseURL}/`;
        if (params.uriParams != undefined && params.uriParams != null && typeof params.uriParams == "object") {
            let route: string = params.route;
            let keys: string[] = Object.keys(params.uriParams);
            for (let key of keys) {
                const escapedKey = this.escapeRegExp(key);
                var regEx = new RegExp("{" + escapedKey + "}", "ig");
                route = route.replace(regEx, params.uriParams[key]);
            }
            url += route;
        } else {
            url += params.route;
        }
        url = this.formatQueryString(url, params);
        if (!(url.indexOf("http://") > -1 || url.indexOf("https://") > -1))
            url = window.location.origin + url;

        return url;
    }

    
    private formatQueryString(url: string, params: ServiceParams): string {
      
        let queryParams: string[] = this.matchQueryStringRegex(url);
        for (var queryParam of queryParams) {
            var paramName = queryParam.split(":")[0];
            paramName = paramName ? paramName : "";
            // paramName = paramName.replace("{", "");
            paramName = paramName.replace(/\{/g, '');
            var qParamValue = params.uriParams[paramName];
            var qParamString = "";
            if (typeof qParamValue == "object" && qParamValue && qParamValue.length > 0) {
                for (var value of qParamValue) {
                    qParamString += `${paramName}=${value}&`
                }
                // To remove last &
                qParamString = qParamString.substr(0, qParamString.length - 1);
            }
            else {
                qParamString = `${paramName}=${qParamValue}`;
            }
            url = url.replace(queryParam, qParamString);
        }
        return url;
    }

    private matchQueryStringRegex(url: string): string[] {
        var regex = /{[A-Z]+:QueryString}/gi;
        var expMatch: RegExpExecArray;
        var result: string[] = [];
        while ((expMatch = regex.exec(url)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (expMatch.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            expMatch.forEach((match, groupIndex) => {
                result.push(match);
            });
        }
        return result;
    }
    private setJasperserverCustomHeadersPost ():HttpHeaders{
            var headers : HttpHeaders = new HttpHeaders()
            .set('pp', sessionStorage.getItem("_jwt"))
            .set('Content-Type', 'application/json')
            .set('X-REMOTE-DOMAIN', '1');
            return headers;
    }
    private setJasperserverCustomHeadersGet ():HttpHeaders{
        var _authtokenprovider = sessionStorage.getItem('authtokenProvider');
        var headers : HttpHeaders = new HttpHeaders()
        .set('pp', sessionStorage.getItem("_jwt"))
        .set('tokenProvider',_authtokenprovider)
        return headers;
}
    private setHeaders(isLoaderRequired?:boolean,propertyId?:number, isSessionCheckNotRequired?:boolean,url?:string, delayLoader?: number): HttpHeaders {
        let token = sessionStorage.getItem("_jwt");
        let userSessionId ='';
        let delayLoaderTime = delayLoader != undefined ?  delayLoader : 0;
        if(propertyId != null && propertyId > 0)
            userSessionId = this.localization.GetMPRCurrentSessionId(propertyId);
        else 
             userSessionId = sessionStorage.getItem("userSession");
        let productId = Number(this.localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
        if (url?.includes(AccountingUrl.accountingService) && (productId == Product.SPA || productId == Product.GOLF || productId == Product.SALESANDCATERING)){
            userSessionId = sessionStorage.getItem("_accUserSession");;
            token = sessionStorage.getItem('_accJwt');
        }
        if (url?.includes(FolioConstants.folioService) && (productId == Product.SPA || productId == Product.GOLF || productId == Product.SALESANDCATERING)){
            userSessionId = sessionStorage.getItem("_folioUserSession") != null ? sessionStorage.getItem("_folioUserSession") : userSessionId;
            token = sessionStorage.getItem('_folioJwt') != null ? sessionStorage.getItem('_folioJwt') : token;
        }
        return new HttpHeaders()
            .set('Accept-Language', navigator.language)
            .set('Content-Type', 'application/json')
            .set("Authorization", token ? 'Bearer ' + token : "")
            .set("SessionId", userSessionId ? userSessionId : "")
            .set("isLoaderRequired", isLoaderRequired? "true": "false")  
            .set("isSessionCheckNotRequired", isSessionCheckNotRequired? "true":"false")
            .set('CorrelationId', Guid.create().toString())
            .set('Request-Origin', 'UI')
            .set('DelayLoader', delayLoaderTime.toString());  
    }
    protected errorHandler(err: HttpErrorResponse): void {
        let urlContents = err.url.split('/');
        let serviceName = urlContents[3];
        if (err.error && err.error.errorCode && err.error.errorCode != -1) {
            this.showBusinessError(err.error.errorCode);
        } 
        else if (err.statusText == HttpResponseConstants.UNKNOWN_ERROR && serviceName == Services.PrintManager) {
            this.utilities.showCommonAlert('Unable to connect Print Service in provided URL', AlertType.Info, ButtonTypes.Ok);
        }
        else {
            const isUnhandledError = true;
            var msg: { errorMessage: string, correlationId: string } = this.utilities.processUnHandledExpection(err);
            this.utilities.showError(msg.errorMessage, isUnhandledError, msg.correlationId);
        }
    }

    private showBusinessError(errorCode: string) {
        let code: number = parseInt(errorCode);
        let message: string = this.localization.getError(code);
        if (code == 48116339)
            this.utilities.showErrorAsWarning(message);
        else
        this.utilities.showError(message);
    }

    private validate(params: ServiceParams): void {
        if (!params) {
            throw new Error('Route not defined');
        }
        if (!this.baseURL) {
            throw new Error(`Host URL not defined for:  ${params.route}`);
        }
        if (params.route.includes('{') && !params.uriParams) {
            let message: string = `Route Param "${params.route.match('\{(.*?)\}')[1]}" is not defined in route "${params.route}"`;
            alert(message);
            throw new Error(message);
        }
    }

    private validateRGuestPayURL(params: ServiceParams) {
        const payUrls = [
            String(rGuesPayRoutes.GetDeviceList),
            String(rGuesPayRoutes.CardCapture),
            String(rGuesPayRoutes.GetCapturedCardInfo),
            String(rGuesPayRoutes.StandBy)
        ];
        if (payUrls.includes(params.route) && !this.baseURL) {
            const v1GiftcardRGuestPayURL = sessionStorage.getItem("v1GiftcardRGuestPayURL");
            this.baseURL = v1GiftcardRGuestPayURL;
        }
    }

    private validateQueryString(url: string) {
        url = url ? url : "";
        if (url.includes("?") && !url.includes(":QueryString")) {
            let message: string = `Url contains query string seperator and does not contain query params {param:QueryString}`;
            alert(message);
            throw new Error(message);
        }
    }

    protected getLetter<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.get<T>(url, { headers: this.setHeaders(false,undefined,false,url) });
    }

    protected getLetterPromise<T>(params: ServiceParams): Promise<T> {
        return this.getLetter<T>(params).toPromise();
    }
    public getVCartHeaders(): any[]
    {
        let headers: any[] = [];
        let token = null;
        let userSessionId = null;
        if (sessionStorage.getItem("quickIdJwt")) {
          token = sessionStorage.getItem("quickIdJwt");
          userSessionId = sessionStorage.getItem("quickIdUserSession");
        }
        else {
          token = sessionStorage.getItem("_jwt");
          userSessionId = sessionStorage.getItem("userSession");
        }
        headers.push({ key: 'Accept-Language', value: navigator.language });
        headers.push({ key: 'Content-Type', value: 'application/json' });
        headers.push({ key: 'Authorization', value: token ? 'Bearer ' + token : "" });
        headers.push({ key: 'SessionId', value: userSessionId ? userSessionId : "" });
        return headers;
    }
    public formVCartURL(params: ServiceParams): string {
        // let url: string = `${baseURL}/`;
        let url: string = `${this.baseURL}/`;
        if (params.uriParams != undefined && params.uriParams != null && typeof params.uriParams == "object") {
          let route: string = params.route;
          let keys: string[] = Object.keys(params.uriParams);
          for (let key of keys) {
            const escapedKey = this.escapeRegExp(key);
            var regEx = new RegExp("{" + escapedKey + "}", "ig");
            route = route.replace(regEx, params.uriParams[key]);
          }
          url += route;
        } else {
          url += params.route;
        }
        url = this.formatQueryString(url, params);
        if (!(url.indexOf("http://") > -1 || url.indexOf("https://") > -1))
          url = window.location.origin + url;
        return url;
      }

}
