import { Injectable } from "@angular/core";
import { DropdownOptions, TableHeaderOptions } from "src/app/common/Models/ag-models";
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { TableActions } from "src/app/common/enums/shared-enums";
import { Localization } from "src/app/common/localization/localization";
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import * as GlobalConst from 'src/app/shared/globalsContant';



@Injectable()
export class DayPassManagementBusiness {
  captions: any;
  constructor(
    private localization: Localization,
    private http: HttpServiceCall
  ) {
    this.captions = this.localization.captions;
  }

  getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: "checked",
        displayName: "",
        templateName: ActionTypeEnum.checkbox,
        hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
      },
      {
        key: "entryPeriodCode",
        displayName: this.captions.hdr_entryPeriodCode,
        displayNameId: "hdr_entryPeriodCode",
        sorting: true,
        searchable: true,
      },
      {
        key: "entryPeriodName",
        displayName: this.captions.hdr_entryPeriodName,
        displayNameId: "hdr_entryPeriodName",
        sorting: true,
        searchable: true,
      },
      {
        key: "entryPeriodDate",
        displayName: this.captions.hdr_entryPeriodDate,
        displayNameId: "hdr_entryPeriodDate",
        sorting: true,
        sortingKey: "startDate",
      },
      {
        key: "entryPeriodTime",
        displayName: this.captions.hdr_entryPeriodTime,
        displayNameId: "hdr_entryPeriodTime",
        sortingKey: "startTime",
        sorting: true,
      },
      {
        key: "status",
        displayName: this.captions.lbl_status,
        displayNameId: "lbl_status",
        sorting: true,
        templateName: ActionTypeEnum.custom
      },
      {
        key: ActionTypeEnum.action,
        displayName: this.captions.tbl_hdr_actions,
        displayNameId: "tbl_hdr_actions",
        templateName: ActionTypeEnum.action,
      },
    ];
  }
  getTableContent(dayPackage) {
    const TableModel: any[] = [];
    dayPackage.forEach((x) => {
      TableModel.push({
        id: x.id,
        checked: false,
        entryPeriodCode: x.code,
        entryPeriodName: x.description,
        entryPeriodDate:
          this.localization.LocalizeDate(x.startDate) +
          " - " +
          this.localization.LocalizeDate(x.endDate),
        entryPeriodTime:
          this.localization.LocalizeTime(x.startDate) +
          " - " +
          this.localization.LocalizeTime(x.endDate),
        status: x.isActive ? "Active" : "InActive",
        statusId: x.isActive ? 0 : 1
      });
    });
    return TableModel;
  }

  getTableOptions(isViewOnly) {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: "entryPeriodCode",
      showTotalRecords: false,
      isHeaderCheckboxAllowed: true,
      isRowClick: false,
      uniqueKey: "entryPeriodCode",
      actions: [
        {
          type: TableActions.edit,
        },
        {
          type: TableActions.delete,
        },
      ],
    };
  }
  async GetAllGuestTypes(includeInactive): Promise<DropdownOptions[]> {
    const guestTypeDropDownValue = [];
    let response = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllGuestType",
      uriParams: { isIncludeInactive: includeInactive },
      method: HttpMethod.Get,
      showError: false,
    });
    var result = response ? response.result : [];
    if (result.length > 0) {
      result.forEach((element) => {
        guestTypeDropDownValue.push({
          id:element.id,
          value:element.name,
          viewValue:element.name
        })
      });
    }
    return guestTypeDropDownValue;
  }

  getGuestTypes(): DropdownOptions[] {
    return [
      {
        id: 1,
        value: "Resort Guest",
        viewValue: "Resort Guest",
      },
      {
        id: 2,
        value: "Hotel Guest",
        viewValue: "Hotel Guest",
      },
      {
        id: 3,
        value: "VIP Member",
        viewValue: "VIP Member",
      },
      {
        id: 4,
        value: "Walk In",
        viewValue: "Walk In",
      },
    ];
  }
}