import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from '../../utilities/common-utilities';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { emailLogsBusiness } from './email-logs.business';
import { CustomEmailCkeditorComponent } from '../custom-email-ckeditor/custom-email-ckeditor.component';
import { map, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { EmailProcessingDataService } from 'src/app/common/dataservices/notification/email-processing-data.service';
import { MsGraphApiCommunication } from 'src/app/common/communication/services/ms-graph.service';
import { AlertType } from 'src/app/common/Models/common.models';
import { AlertAction, ButtonType } from 'src/app/common/enums/shared-enums';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';

@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [emailLogsBusiness, EmailProcessingDataService]
})
export class EmailLogsComponent implements OnInit {

  captions: any;
  title: string = '';
  composeButton: ButtonValue;
  tableOptions: TableOptions;
  tableHeaderOptions: TableHeaderOptions[];
  tableContent: any[] = [];
  emailReferenceNumber: string =''; 
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  createModalData: createModal;
  showEmailLogs: boolean = false;
  mailLogData: any;
  mailFilterForm: UntypedFormGroup;
  emailFilterApplyButton: ButtonValue;
  emailFilterResetButton: ButtonValue;
  floatLabel: string;
  isFilterApplied: boolean = false;
  @ViewChild('mailFilterMenuTrigger') mailFilterMenuTrigger: MatMenuTrigger;
  originalTableContent: any[] = [];
  isViewOnly: boolean = false;
  emailLogCheckedData: any[] = [];
  constructor(private localization: Localization, private utils: CommonUtilities, @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<EmailLogsComponent>, public dialog: MatDialog, private business: emailLogsBusiness, private emailClient: MsGraphApiCommunication, private fb: UntypedFormBuilder) {
    this.captions = this.localization.captions;
    this.title = this.captions.lbl_emailLogs;
    this.emailReferenceNumber = data.emailReferenceNumber;
    this.isViewOnly = data.isViewOnly;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.createModalData = {
      title: this.captions.lbl_viewEmailLog
    };
    this.initializeMailFilterForm();
    this.initializeInputs();
    this.initializeTableInputs();
  }

  initializeMailFilterForm(){
    this.mailFilterForm = this.fb.group({
      fromAddress: ['',Validators.email],
      toAddress:['',Validators.email],
      subject:''
    })

    this.emailFilterApplyButton = {
      label: this.captions.btn_apply,
      type:'primary',
      disabledproperty: true
    }

    this.emailFilterResetButton = {
      label: this.captions.btn_reset,
      type:'secondary',
      disabledproperty: false
    }

    this.mailFilterForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.emailFilterApplyButton.disabledproperty = !(this.mailFilterForm.valid && this.mailFilterForm.dirty);
     })
  }

  async initializeTableInputs() {
    this.tableOptions = this.business.getTableOptions(this.isViewOnly);
    this.tableHeaderOptions = this.business.getTableHeaderOptions();
    await this.getTableContent()
  }

  initializeInputs() {
    this.composeButton = {
      label: this.captions.lbl_compose,
      disabledproperty: false,
      type: 'primary'
    }
  }

  close(Event) {
    this.dialogRef.close();
  }

  async onComposeAction() {
    const result = await this.emailClient.isAuthenticatedToAction(' for sending emails');
    if (!result) {
      return;
    }
    let subject = `[ref:${this.emailReferenceNumber}]`;
    this.composeMail(subject, [], [], '', []);
  }

  async composeMail(subject, ccAddress : string[], toAddress : string[], mailContent: string, attachmentList: any[]) {
    const emailSetting = await this.business.getEmailSetting();
    if (emailSetting === null) {
      const proceed = await this.showEmailTrackingAlert();
      if (!proceed) {
        return;
      }
    } else {
      ccAddress = this.assignCCAddress(ccAddress, emailSetting.sentEmail);
    }
    mailContent = mailContent != '' ? this.business.patchMailBody(this.mailLogData) : '';
    this.business.prepareAndComposeMailMessage(subject, ccAddress, toAddress, mailContent, attachmentList);
  }

  assignCCAddress(ccAddress: string[], sentEmail : string): string[]{
    if(ccAddress.length == 0 || !ccAddress.find(x => x == sentEmail)){
      ccAddress.push(sentEmail);
    }
    return ccAddress;
  }

  async showEmailTrackingAlert() {
    return new Promise<boolean>((resolve) => {
      this.utils.showAlert(
        this.captions.warn_emailTracking,
        AlertType.Warning,
        ButtonType.YesNo,
        (res) => {
          resolve(res === AlertAction.YES);
        }
      );
    });
  }

  async viewEmailLog(element) {
    await this.getMailBodyContent(element);
    this.showLogsViewEvent();
  }

  async getMailBodyContent(element){
    const copiedData = JSON.parse(JSON.stringify(element));
    let message = await this.business.getEmailLogByMessageId(copiedData);
    this.mailLogData = message;
  }

  async onTableAction(eve){
    switch(eve.fromType){
      case FromTypeEnum.allcheckbox:
        this.emailLogCheckedData = eve.checkedData;
        break;
      case FromTypeEnum.rowcheck:
        this.emailLogCheckedData = eve.checkedData;
        break;
      case FromTypeEnum.delete:
        let emailLogIds : number[] = [eve.Obj.id]
        this.deleteEmailLogs(emailLogIds);
        break;
      case FromTypeEnum.reply:
      case FromTypeEnum.replyAll:
      case FromTypeEnum.forward:
        const result = await this.emailClient.isAuthenticatedToAction('for sending emails')
        if (!result) {
          return;
        }
        this.composeReplyAndForwardMail(eve.Obj, eve.fromType);
        break;
    }
  }

  async composeReplyAndForwardMail(element, fromType: FromTypeEnum){
    await this.getMailBodyContent(element);
    let prefixSubject = fromType === FromTypeEnum.forward ? 'FW: ' : 'RE: ';
    let subject = !this.mailLogData.emailSubject.includes(prefixSubject) ? prefixSubject + this.mailLogData.emailSubject : this.mailLogData.emailSubject;
    if(subject.includes('RE: ') && fromType === FromTypeEnum.forward){
      subject = subject.replace('RE: ', '');
    }

    if(subject.includes('FW: ') && (fromType === FromTypeEnum.reply || fromType === FromTypeEnum.replyAll)){
      subject = subject.replace('FW: ', '');
    }
    let attachments = fromType === FromTypeEnum.forward ? this.mailLogData.attachmentList : [];
    let toAddress: string[] = []; let ccAddress: string[] = [];
    switch(fromType){
      case FromTypeEnum.reply:
        toAddress.push(this.mailLogData.fromAddress);
        break;
      case FromTypeEnum.replyAll:
        this.emailClient.currentUser.subscribe(x => {
          if(x != ''){
            let signedInuser = x.replace(this.captions.mail_signedInAs, '');
            toAddress = this.mailLogData.toAddress != null ? this.assignToAddressForReplyAll(signedInuser) : [];
            ccAddress = this.mailLogData.ccAddress != null ? this.mailLogData.ccAddress.filter(x => x != signedInuser) : [];
          }
        })
    }
    this.composeMail(subject, ccAddress, toAddress, this.mailLogData.bodyContent, attachments);

  }

  assignToAddressForReplyAll(signedInuser: string) :string[]{
    let toAddress: string[] = [];
    toAddress = this.mailLogData.toAddress.filter(x => x != signedInuser);
    if(this.mailLogData.fromAddress != signedInuser)
      toAddress.push(this.mailLogData.fromAddress);

    return toAddress;
  }

  back(){
    this.showLogsViewEvent();
  }

  showLogsViewEvent(){
    this.showEmailLogs = !this.showEmailLogs;

  }

  async deleteEmitAction(event){
    this.showLogsViewEvent()
    let ids: number[] = [event.id]
    await this.delete(ids);
  }

  async refreshEmailLogs(){
    await this.business.refreshEmailLogs();
    this.utils.showAlert(this.captions.lbl_refreshSuccess, AlertType.Success, ButtonType.Ok)
    await this.getTableContent();
  }

  async deleteEmailLogs(ids: number[]){
    this.utils.showAlert(this.captions.warn_delete_emailLog, AlertType.Warning, ButtonType.YesNo, async res => {
      if(res === AlertAction.YES){
        await this.delete(ids);
      }
    });
  }

  async delete(ids) {
    await this.business.deleteEmailLogs(ids);
    this.utils.showAlert(this.captions.emailLogDelete, AlertType.Success, ButtonType.Ok);
    await this.getTableContent();
  }

  async getTableContent(){
    this.tableContent = await this.business.getTableContent(this.emailReferenceNumber);
    this.originalTableContent = this.tableContent;
  }

  async deleteMultipleEmailLogs(){
    if(this.emailLogCheckedData && this.emailLogCheckedData.length > 0){
      let ids: number[] = this.emailLogCheckedData.map(x => x.id);
      await this.deleteEmailLogs(ids);      
    }
  }

  applyEmailFilter(){
    if (this.mailFilterMenuTrigger) {
      let filterFormValue = this.mailFilterForm.value;
      let fromAddress = filterFormValue && filterFormValue.fromAddress ? filterFormValue.fromAddress : ''
      let toAddress = filterFormValue && filterFormValue.toAddress ? filterFormValue.toAddress : ''
      let subject = filterFormValue && filterFormValue.subject ? filterFormValue.subject : ''

      this.tableContent = this.originalTableContent.filter(x => {
        return (
          (fromAddress === '' || x.fromAddress.toLowerCase().includes(fromAddress.toLowerCase())) &&
          (toAddress === '' || x.toAddress.some(addr => addr.toLowerCase().includes(toAddress.toLowerCase()))) &&
          (subject === '' || x.emailSubject.toLowerCase().includes(subject.toLowerCase()))
        );
      });
      this.isFilterApplied = true;
      this.mailFilterMenuTrigger.closeMenu();
    }
  }

  applyEMailReset(){
    this.mailFilterForm.reset();
    this.tableContent = this.originalTableContent;
    this.isFilterApplied = false;
    //this.closeAction()

  }

  closeFilter(){
    this.closeAction()
  }

  closeAction(){
    if (this.mailFilterMenuTrigger) {
      this.mailFilterMenuTrigger.closeMenu();
    }
  }

}
