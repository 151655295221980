import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ReplaySubject } from 'rxjs';
import { CreateNewTaxTypeBusiness } from '../create-new-tax-type.business';
import { API as TaxExemptCategoryAPI } from 'src/app/retail/retail-code-setup/tax-exempt-category/tax-exempt-category.model';
import { ActionMode } from 'src/app/common/enums/shared-enums';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { UI as TaxTypeUI, TaxBased } from '../../tax-type.model';
import { TaxType } from 'src/app/common/shared/shared/setupConstants';

@Component({
  selector: 'app-tax-details',
  templateUrl: './tax-details.component.html',
  styleUrls: ['./tax-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CreateNewTaxTypeBusiness]
})
export class TaxDetailsComponent implements OnInit {
  @Input() taxTypeInputs: any;
  captions;
  taxDetailsForm: FormGroup;
  taxExemptCategoiresOptions: { id: number; viewValue: string; value: TaxExemptCategoryAPI.TaxExemptCategory; }[];
  tierLevelOptions: DropdownOptions[] = [];
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent = [];
  tableCopyContent = [];
  currencySymbol: string;
  floatLabel;
  takeUntil;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() parentForm;
  @Output() tableEmit = new EventEmitter();
  @Output() taxDetailsFormOut = new EventEmitter();

  actionButton: { type: string; label: any; disabledproperty: boolean; };
  cancelButton: { type: string; label: any; };
  saveButton: { type: string; label: any; disabledproperty: boolean; };
  rangeCancelButton: { type: string; label: any; };
  addYears: number = 10;
  defaultRangeToDate: Date;
  taxBasedList: { id: number; value: any; }[];
  taxTypes: TaxTypeUI.TaxType[];
  changeInputValues: any = '';
  rangeMaintenanceChangeInputValues: any = '';
  specilizedTaxTypeItems = [];
  isFocusSearchEmitRequired: boolean = true;
  selectedFromList = [];
  specilizedTaxTypes: DropdownOptions[];
  editSpecilizedTaxTypeId: number;
  isValidSpecializedTaxType: boolean = false;
  isSpecializedTaxTypeSelected: boolean = false;
  autoCompleteKeys: string[] = ["name"];
  selectedChipKey: string[] = ["name"];
  selectedNewChipKey: string[] = ["name"];
  searchKey;
  isChipDisabled: boolean;
  searchPlaceholder: string = "";
  taxTypeMaxLength: number;
  taxPercentMinLength: number;
  taxPercentMaxLength: number;
  taxAmountMaxLength: number;
  duplicateTaxType: number;
  searchPostTypeLength: number;
  taxPerMinValue: number;
  taxPerMaxValue: number;
  keywordsMaxLength: number;
  referncekeywordsMaxLength: number;
  listOrderMaxLength: number;
  listOrderMaxValue: number;
  minFromDate: Date;
  minToDate: Date;
  maxToDate: Date
  isTaxChargeEnabled: boolean = false;
  isTaxInclusive: boolean = false;
  allSpecializedTaxes: any;
  tableContentValid : boolean = false;
  isFlatisLessThanMinimumAmount : boolean = false;
  isFlatisGreatThanMaximumAmount : boolean = false;

  constructor(private localization: RetailLocalization, private fb: FormBuilder, private business: CreateNewTaxTypeBusiness, private utilities: RetailUtilities
    , private propertyInfo: RetailPropertyInformation
    , private cd: ChangeDetectorRef) {
    this.captions = this.localization.captions;
    this.currencySymbol = this.localization.currencySymbol;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.doInitialize();
  }


  async doInitialize() {
    this.headerOptions = this.business.getTaxDetailsHeaderOptions();
    this.tableOptions = this.business.getTaxDetailsTableOptions();
    this.generateForm();
    await this.initializeInputs();
    this.patchForm();
    if (this.parentForm) {
      this.parentForm.addControl('taxDetails', this.taxDetailsForm);
    }
  }

  private generateForm() {
    this.taxDetailsForm = this.fb.group({
      id: 0,
      active: true,
      taxType: ['', Validators.required],
      listOrder: [],
      taxExemptCategories: [],
      tierLevel: [],
      thresholdAmt: 0,
      maximumTaxAmt: 0,
      minimumTaxAmt: 0,
      taxInclusive: false,
      chargeTax: false,
      taxName: [],
      taxValue: [],
      taxRevenue: false,
      isSpecialisedUse: false,
      specializedTaxTypeId: 0,
      selectedTaxType: 0,
      excludeFlatTaxOnZeroRate : false
    });
  }

  private async initializeInputs() {
    this.taxExemptCategoiresOptions = await this.business.GetActiveTaxExemptCategories();
    this.setListOrder();
    this.defaultRangeToDate = this.localization.dateAdd.AddYears(this.propertyInfo.CurrentDate, this.addYears);
    this.taxBasedList = this.business.getTaxBasedOptions();
    await this.GetSpecializedTaxTypes();
    this.getValidators();
  }
  async setListOrder() {
    if (this.taxTypeInputs.mode === ActionMode.create || this.taxTypeInputs.mode === ActionMode.copy) {
      this.taxDetailsForm.patchValue({
        listOrder: this.taxTypeInputs.listOrder
      });
    }
  }

  taxAmountValidation(eve, isMax) {
    let maxTaxAmt = this.taxDetailsForm.get("maximumTaxAmt").value;
    let minTaxAmt = this.taxDetailsForm.get("minimumTaxAmt").value;
    if (isMax == true) {
      let isMet = maxTaxAmt >= minTaxAmt;
      if (!isMet) {
        this.taxDetailsForm.get("maximumTaxAmt").setValue(minTaxAmt);
      }
    }
    else {
      let isMet = maxTaxAmt >= minTaxAmt;
      if (!isMet) {
        this.taxDetailsForm.get("minimumTaxAmt").setValue(maxTaxAmt);
      }
    }
    this.taxDetailsForm.updateValueAndValidity();
  }

  activeChange(eve) {
    let isActive: boolean = false;
    if (eve[0] === true) {
      isActive = true;
    }
    this.taxDetailsForm.value.active = isActive;
  }

  taxInclusiveChange(eve) {
    let taxInclusive: boolean = false;
    let id = this.taxDetailsForm.get("id").value;
    if (id > 0) {
      this.utilities.ToggleLoader(true);
      this.business.ValidateIfSpecializedTaxAssociated(this.taxDetailsForm.get("id").value).then(result => {
        if (result) {
          this.taxDetailsForm.get('taxInclusive').setValue(!eve[0])
          this.utilities.showError(this.captions.err_specializedTax);
          this.taxDetailsForm.markAsPristine();
        }
        else {
          this.taxDetailsForm.get('selectedTaxType').setValue("");
          this.taxDetailsForm.get('specializedTaxTypeId').setValue(0);
          this.selectedFromList = [];
          taxInclusive = eve[0];
          this.isTaxInclusive = !this.isTaxInclusive

          if (this.isTaxInclusive) {
            this.tableContent.filter(f => f.taxBasedOn == this.taxBasedList[1].id).map(x => {
              x.taxBasedOn = this.taxBasedList[0].id
              x.value = ''
            });
            this.sortByEffectiveFrom();
            this.emitTaxTableOutputData(false);
          }

          this.specilizedTaxTypes = this.allSpecializedTaxes.filter(f => f.isInclusive == taxInclusive && this.taxDetailsForm.get("id")?.value != f.id).map(items =>
            ({ id: items.id, value: items.id, viewValue: items.taxType, checked: false })
          );
          this.taxDetailsForm.value.taxInclusive = taxInclusive;

          if (this.isTaxChargeEnabled && this.selectedFromList.length == 0) {
            this.taxDetailsForm.get('selectedFromList')?.setValidators([Validators.required]);
            this.taxDetailsForm.get('selectedFromList')?.updateValueAndValidity();
          }
        }
      }).finally(() => {
        this.utilities.ToggleLoader(false);
      });
    }
    else {
      this.taxDetailsForm.get('selectedTaxType').setValue("");
      this.taxDetailsForm.get('specializedTaxTypeId').setValue(0);
      this.selectedFromList = [];
      taxInclusive = eve[0];
      this.taxDetailsForm.value.taxInclusive = taxInclusive;
      this.isTaxInclusive = !this.isTaxInclusive
      if (this.isTaxInclusive) {
        this.tableContent.filter(f => f.taxBasedOn == this.taxBasedList[1].id).map(x => {
          x.taxBasedOn = this.taxBasedList[0].id
          x.value = ''
        });
        this.emitTaxTableOutputData(false);
        this.sortByEffectiveFrom();
      }

      this.specilizedTaxTypes = this.allSpecializedTaxes.filter(f => f.isInclusive == taxInclusive && this.taxDetailsForm.get("id")?.value != f.id).map(items =>
        ({ id: items.id, value: items.id, viewValue: items.taxType, checked: false })
      );
      this.taxDetailsForm.value.taxInclusive = taxInclusive;

      if (this.isTaxChargeEnabled && this.selectedFromList.length == 0) {
        this.taxDetailsForm.get('selectedFromList')?.setValidators([Validators.required]);
        this.taxDetailsForm.get('selectedFromList')?.updateValueAndValidity();
      }
    }
  }

  tableAction(eve) {
    if (eve.fromType === 'delete') {
      this.tableContent = this.tableContent.filter(x => x.id !== eve.Obj.id)
      this.sortByEffectiveFrom();
      this.emitTaxTableOutputData(true)
    }
    if (eve.fromType === 'switch') {
      const index = this.tableContent.findIndex(tableData => tableData.id === eve.Obj.id)
      this.tableContent[index].active= eve.Obj.active;

      this.emitTaxTableOutputData(true);
    }
  }

  emitTableData(event, element, key) {
    this.minToDate = this.localization.getDate(element.effectiveFrom);
    if (this.overlapDateRange(element)) {
      const index = this.tableContent.findIndex(tableData => tableData.id === element.id);
      if (index !== -1) {
        this.tableContent[index] = element;
        if (element.effectiveFrom > element.effectiveUntil) {
          this.tableContent[index].effectiveUntil = element.effectiveFrom
        }
      }

      this.emitTaxTableOutputData(true);
    }
    else {
      this.utilities.showError(this.captions.err_overlapDateRange);

      this.emitTaxTableOutputData(false);
    }
  }

  emitTableData1(event, element, key) {
    this.isFlatisLessThanMinimumAmount = false;
    this.isFlatisGreatThanMaximumAmount=false;
    const index = this.tableContent.findIndex(tableData => tableData.id === element.id);
    if(element.taxBasedOn == TaxBased.TaxAmount && key == 'value'){
      if(this.taxDetailsForm.get('minimumTaxAmt').value > element.value){
        this.isFlatisLessThanMinimumAmount = true;
      }
      else if (this.taxDetailsForm.get('maximumTaxAmt').value < element.value){
        this.isFlatisGreatThanMaximumAmount=true;
      }
      

    }
    if (index !== -1) {
      this.tableContent[index] = element;
      if (this.tableContent[index].taxBasedOn == TaxBased.TaxPercent && this.tableContent[index].value > this.taxPerMaxValue) {
        this.tableContent[index].value = this.taxPerMaxValue;
      }
    }

    this.emitTaxTableOutputData(true);
  }

  taxChargeChange(eve) {
    let isTaxCharge: boolean = false;
    if (eve[0] === true) {
      isTaxCharge = true;
      this.taxDetailsForm.get("isSpecialisedUse").setValue(!eve[0])
    }
    this.taxDetailsForm.get('chargeTax').setValue(isTaxCharge);
    this.isTaxChargeEnabled = !this.isTaxChargeEnabled;
    this.taxDetailsForm.get('selectedTaxType').setValue("");
    this.taxDetailsForm.get('specializedTaxTypeId').setValue(0);
    this.selectedFromList = [];

    if (this.isTaxChargeEnabled) {

      this.taxDetailsForm.get('selectedFromList')?.setValidators([Validators.required]);
      this.taxDetailsForm.get('selectedFromList')?.updateValueAndValidity();

      this.taxDetailsForm.get('chargeTax')?.valueChanges.subscribe((value) => {
        const specializedTaxTypeIdControl = this.taxDetailsForm.get('selectedFromList');
        if (value) {
          specializedTaxTypeIdControl?.setValidators([Validators.required]);
        } else {
          specializedTaxTypeIdControl?.clearValidators();
        }
        specializedTaxTypeIdControl?.updateValueAndValidity();
      });
      this.taxDetailsForm.patchValue({
        specializedTaxTypeId: [],
      })
    }
  }
  taxRevenueChange(eve = true) {
    let isTaxRevenue: boolean = false;
    if (eve[0] === true) {
      isTaxRevenue = true;
    }
    this.taxDetailsForm.get('taxRevenue').setValue(isTaxRevenue);
  }

  excludeFlatTaxOnZeroRateToggleChange(eve) {
    let isExcludeFlatTaxOnZeroRate: boolean = false;
    if (eve.checked) {
      isExcludeFlatTaxOnZeroRate = true;
    }
    this.taxDetailsForm.get('excludeFlatTaxOnZeroRate').setValue(isExcludeFlatTaxOnZeroRate);
  }
  addRow() {
    this.minFromDate = this.localization.getDate(this.propertyInfo.CurrentDate);
    this.minToDate = this.localization.getDate(this.propertyInfo.CurrentDate);
    let currentMaxDate = this.tableContent.length > 0 
  ? this.localization.dateAdd.AddDays(this.localization.getDate(Math.max(...this.tableContent.map(m => this.localization.getDate(m.effectiveUntil).getTime()))),1)
  : this.localization.getDate(this.propertyInfo.CurrentDate);
    let maxDate = this.tableContent.length > 0 ? currentMaxDate : this.localization.getDate(this.defaultRangeToDate) 
  // this.tableCopyContent=[]
  this.tableCopyContent = [{
      effectiveFrom: currentMaxDate,
      effectiveUntil: maxDate,
      taxBasedOn: this.isTaxInclusive ? this.taxBasedList[0].id : this.taxBasedList[1].id,
      active: { isDisabled: false, value: true },
      value: '',
      id: this.tableCopyContent.length > 0 ? Math.max(...this.tableCopyContent.map(x => x.id)) + 1 : 0
    }]
    
    if (!this.overlapDateRange(this.tableCopyContent[0])) {
      this.utilities.showError(this.captions.err_overlapDateRange);
    }

    this.tableContent = this.tableContent.concat(this.tableCopyContent);
    this.sortByEffectiveFrom();
    this.emitTaxTableOutputData(false);
  }
  overlapDateRange(element): boolean {
    const length = this.tableContent.length;
    for (let i = 0; i < length; i++) {
      if (this.tableContent[i].id != element.id) {
        if ((this.localization.getDate(element.effectiveFrom) >= this.localization.getDate(this.tableContent[i].effectiveFrom) && this.localization.getDate(element.effectiveFrom) <= this.localization.getDate(this.tableContent[i].effectiveUntil))
          || (this.localization.getDate(this.tableContent[i].effectiveFrom) >= this.localization.getDate(element.effectiveFrom) && this.localization.getDate(this.tableContent[i].effectiveFrom) <= this.localization.getDate(element.effectiveUntil))
          || (this.localization.getDate(this.tableContent[i].effectiveFrom) >= this.localization.getDate(element.effectiveFrom) && this.localization.getDate(this.tableContent[i].effectiveFrom) <= this.localization.getDate(element.effectiveUntil))
          || (this.localization.getDate(this.tableContent[i].effectiveUntil) >= this.localization.getDate(element.effectiveFrom) && this.localization.getDate(this.tableContent[i].effectiveUntil) <= this.localization.getDate(element.effectiveUntil))
        ) {
          return false;
        }
      }
    }
    return true;
  }
  validateDateGap() {
    const length = this.tableContent.length - 1;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const fromGap = this.utilities.AddDays(this.localization.getDate(this.tableContent[i].effectiveUntil), 1);
        const toGap = this.utilities.AddDays(this.localization.getDate(this.tableContent[i + 1].effectiveFrom), -1);
        if (this.localization.getDate(this.tableContent[i + 1].fromDate).getTime() != fromGap.getTime()) {
          this.utilities.showError(this.captions.err_dateGap + " " + this.localization.localizeDisplayDate(fromGap) + " - " +
            this.localization.localizeDisplayDate(toGap) + ". " + this.captions.err_taxDefinition);
          break;
        }
      }
    }
  }
  async patchForm() {

    if (!this.taxDetailsForm) return;

    this.taxTypes = await this.business.getTaxType(true);
    if ((this.taxTypeInputs.mode === ActionMode.update || this.taxTypeInputs.mode === ActionMode.copy)
      && this.taxTypeInputs.form) {
      this.taxTypes = this.taxTypes.filter(x => x.postTypeId != this.taxTypeInputs.form.taxMaintenance.postTypeId);
      this.bindForm(this.taxTypeInputs.form.taxMaintenance, this.taxTypeInputs.selectedRoomItem);
      this.tableContent = this.taxTypeInputs.form.tableContent.map(item => ({
        active: item.isActive ? { isDisabled: false, value: true } : { isDisabled: false, value: false },
        taxBasedOn: item.taxBasedOn,
        effectiveFrom: item.fromDate,
        effectiveUntil: item.toDate,
        value: item.taxBasedOn == TaxBased.TaxAmount ? item.taxFlatAmount : item.taxPercent,
        id: item.id
      }));
      this.sortByEffectiveFrom();
      this.emitTaxTableOutputData(false);
    }
  }
  private bindForm(data, room) {
    this.isTaxChargeEnabled = room[0].checked;
    this.isTaxInclusive = data.isInclusive
    if (this.isTaxInclusive) {
      this.specilizedTaxTypes = this.allSpecializedTaxes.filter(f => f.isInclusive && f.id != data.id).map(items =>
        ({ id: items.id, value: items.id, viewValue: items.taxType, checked: false })
      );
    }
    else {
      this.specilizedTaxTypes = this.allSpecializedTaxes.filter(f => !f.isInclusive && f.id != data.id).map(items =>
        ({ id: items.id, value: items.id, viewValue: items.taxType, checked: false })
      );
    }
    this.changeInputValues = {
      id: data.id,
      taxType: data.taxType,
      postTypeNumber: data.postTypeNumber ? data.postTypeNumber : '',
      listOrder: data.listOrder,
      active: data.isActive,
      taxExemptCategories: data.taxExemptCategories,
      postTypeId: data.postTypeId,
      taxInclusive: data.isInclusive,
      chargeTax: room[0].checked,
      thresholdAmt: data.minThresholdAmount,
      maximumTaxAmt: data.maxTaxAmount,
      minimumTaxAmt: data.minTaxAmount,
      isSpecialisedUse: room[4].checked,
      specializedTaxTypeId: data.specializedTaxId,
      selectedTaxType: data.selectedTaxType ? data.selectedTaxType : "",
      excludeFlatTaxOnZeroRate : data.excludeFlatTaxOnZeroCharge
    };
    this.taxDetailsForm.patchValue({
      id: data.id,
      taxType: data.taxType,
      postTypeNumber: data.postTypeNumber ? data.postTypeNumber : '',
      listOrder: data.listOrder,
      active: data.isActive,
      chargeTax: room[0].checked,
      taxExemptCategories: data.taxExemptCategories,
      thresholdAmt: data.minThresholdAmount,
      maximumTaxAmt: data.maxTaxAmount,
      minimumTaxAmt: data.minTaxAmount,
      taxInclusive: data.isInclusive,
      isSpecialisedUse: room[4].checked,
      specializedTaxTypeId: data.specializedTaxId,
      selectedTaxType: data.selectedTaxType ? data.selectedTaxType : "",
      excludeFlatTaxOnZeroRate : data.excludeFlatTaxOnZeroRate
    });
    if (data.specializedTaxId > 0) {
      this.taxDetailsForm.get('specializedTaxTypeId').setValue(data.specializedTaxId);
      this.editSpecilizedTaxTypeId = data.specializedTaxId;
      let taxType = this.specilizedTaxTypes.filter(x => x.id == data.specializedTaxId);
      if (taxType.length > 0) {
        this.selectedFromList = [{ id: taxType[0].id, name: `${taxType[0].viewValue}` }];
        this.isValidSpecializedTaxType = true;
        this.isSpecializedTaxTypeSelected = true;
      }
    }
    this.taxDetailsFormOut.emit(this.taxDetailsForm);
  }
  async searchByKeyword(arg) {
    this.searchMasterTaxTypes(arg);
  }

  searchMasterTaxTypes(pattern: string) {
    pattern = (pattern ? pattern.toLowerCase() : "").trim();
    if (this.specilizedTaxTypes && this.specilizedTaxTypes.length > 0) {
      this.specilizedTaxTypeItems = this.specilizedTaxTypes && pattern ? this.specilizedTaxTypes.filter(x => x.viewValue.toLowerCase().includes(pattern) ||
        x.value.toString().toLowerCase().includes(pattern)).map(x => {
          return {
            id: x.id,
            name: `${x.viewValue}`
          };
        }) : [];
    }
    else {
      this.utilities.showError(this.captions.err_searchMasterTaxType);
      this.isFocusSearchEmitRequired = false;
    }
  }
  async GetSpecializedTaxTypes() {
    this.utilities.ToggleLoader(true);
    this.allSpecializedTaxes = await this.business.getAllSpecializedTaxTypes(false)
      .finally(() => this.utilities.ToggleLoader(false));
    if (this.isTaxInclusive) {
      this.specilizedTaxTypes = this.allSpecializedTaxes.filter(f => f.isInclusive && this.taxDetailsForm.get("id")?.value != f.id).map(items =>
        ({ id: items.id, value: items.id, viewValue: items.taxType, checked: false })
      );
    }
    else {
      this.specilizedTaxTypes = this.allSpecializedTaxes.filter(f => !f.isInclusive && this.taxDetailsForm.get("id")?.value != f.id).map(items =>
        ({ id: items.id, value: items.id, viewValue: items.taxType, checked: false })
      );
    }
  }
  selectedChipDataEmit(event: { id: number, name: string }[]) {
    let selectedTaxType = '';
    let taxTypeId = 0;
    if (event && event.length > 0) {
      let isExists = this.taxTypes.some(x => x.postTypeId == event[0].id);
      if (isExists) {
        this.onChipRemoveEvent(event);
        this.isValidSpecializedTaxType = false;
        this.isSpecializedTaxTypeSelected = false;
      }
      else {
        taxTypeId = event[0].id ? event[0].id : 0;
        selectedTaxType = event[0].name ? event[0].name.split('-')[0].trim() : '';
        this.taxDetailsForm.markAsDirty();
        this.taxDetailsForm.get('specializedTaxTypeId').setValue(taxTypeId);
        this.taxDetailsForm.get('selectedTaxType').setValue(selectedTaxType);
        this.isValidSpecializedTaxType = true;
        this.isSpecializedTaxTypeSelected = true;
        this.taxDetailsForm.controls.taxType.markAsTouched();
      }
    }
  }
  onChipRemoveEvent(event) {
    this.isValidSpecializedTaxType = false;
    this.taxDetailsForm.get('specializedTaxTypeId').setValue('');
    this.isSpecializedTaxTypeSelected = false;
  }

  specializedTaxTogglechange(eve) {
    let id = this.taxDetailsForm.get("id").value;
    if(eve.checked){
      this.taxDetailsForm.get("chargeTax").setValue(false);
      this.taxDetailsForm.get('selectedTaxType').setValue("");
      this.taxDetailsForm.get('specializedTaxTypeId').setValue(0);
      this.isTaxChargeEnabled=false;
    }
    if (id > 0) {
      this.utilities.ToggleLoader(true);
      this.business.ValidateIfSpecializedTaxAssociated(this.taxDetailsForm.get("id").value).then(result => {
        if (result) {
          this.utilities.showError(this.captions.err_specializedTax);
          this.taxDetailsForm.get('isSpecialisedUse').setValue(!eve.checked)
          this.taxDetailsForm.markAsPristine();
        }
      }).finally(() => {
        this.utilities.ToggleLoader(false);
      });
    }
    this.taxDetailsFormOut.emit(this.taxDetailsForm);
  }

  getValidators() {
    this.taxTypeMaxLength = TaxType.TAXTYPE_MAXLENGTH;
    this.taxPercentMaxLength = TaxType.TAXPERCENT_MAXLENGTH;
    this.taxAmountMaxLength = TaxType.TAXAMOUNTMAXLENGTH;
    this.duplicateTaxType = TaxType.DUPLICATE_TAXTYPE_NAME;
    this.searchPostTypeLength = TaxType.SEARCH_POSTTYPE_MAXLENGTH;
    this.taxPerMinValue = TaxType.TAXPERCENT_MINVALUE;
    this.taxPerMaxValue = TaxType.TAXPERCENT_MAXVALUE;
    this.keywordsMaxLength = TaxType.KEYWORDS_MAXLENGTH;
    this.referncekeywordsMaxLength = TaxType.REFERNCEKEYWORDS_MAXLENGTH;
    this.listOrderMaxLength = TaxType.LISTORDER_MAXLENGTH;
    this.listOrderMaxValue = TaxType.LISTORDER_MAXVALUE;
  }
  emitTaxTableOutputData(isEdited : boolean)
  {
    this.checkIfTableContentValid();
    this.tableEmit.emit({
      table: this.tableContent,
      edited: isEdited,
      invalid : !this.tableContentValid
    });
  }
  checkIfTableContentValid() {
    let invalid: boolean = false
    let length = this.tableContent?.length ?? 0
    let emptyValueCheck = this.tableContent?.some(x => x.value == "");
    invalid = !(this.isSpecializedTaxTypeSelected == this.isTaxChargeEnabled) || emptyValueCheck

    this.tableContentValid = !invalid;
  }
  sortByEffectiveFrom(){
    this.tableContent = this.tableContent.sort((a, b) => this.localization.getDate(a.effectiveFrom).getTime() - this.localization.getDate(b.effectiveFrom).getTime());
    this.tableContent = [...this.tableContent];
  }
}
