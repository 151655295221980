import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { MatDialog } from '@angular/material/dialog';
import { ActionMode, AlertAction, AlertType, ButtonType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { UntypedFormGroup } from '@angular/forms';
import { CommonUtilities } from '../utilities/common-utilities';
import { MainGroup, MappingSubjectDataType } from '../deposit-policy/deposit-policy.model';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { DepositPolicySharedService } from '../deposit-policy/deposit-policy-shared';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-deposit-policy-mapping',
  templateUrl: './deposit-policy-mapping.component.html',
  styleUrls: ['./deposit-policy-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepositPolicyMappingComponent implements OnInit {

  captions : any;
  @Input() tableContent;
  originalcontent = [];
  tableOptions : any=[];
  searchText = '';
  IsEditModeEnabledFlag: boolean;
  showInactivetog:boolean=false;
  createToggler = false;
  createModalData: createModal;
  crudActionInput;
  customEditCancelled : boolean = false;
  createForm: UntypedFormGroup;
  @Input() guestCategoryOptions;
  @Input() guestTypeOptions;
  @Input() mainGroupList : MainGroup[];
  @Input() productId;
  @Input() searchPlaceholder;
  @Input() isViewOnly = true;
  onEditDisableFunction = true;
  policyOptions: DropdownOptions[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input("tableOptions")
  set tableValues(value: any){
    if (value != null && value.length > 0) {
      this.tableOptions = value;
      this.loadTableData();
    }
  }
  constructor(private localization: Localization,public dialog: MatDialog,public utils: CommonUtilities,
    private depositPolicySharedService : DepositPolicySharedService
  ) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.utils.ToggleLoader(true);
    this.depositPolicySharedService.mappingData$.pipe(takeUntil(this.destroyed$)).subscribe(payload => {
          if (payload != null) {
            switch (payload.type) {
              case MappingSubjectDataType.SendPolicyMapping:
                this.loadEditData(payload.data);
                break;
              case MappingSubjectDataType.APIResponse:
              case MappingSubjectDataType.DeleteAPIResponse:
                if (payload.data == 0) {
                  let alertMessage = payload.type == MappingSubjectDataType.APIResponse ? this.captions.alertPopup.successfullysaved : this.captions.alertPopup.successfullyDeleted;
                  this.utils.showAlert(alertMessage, AlertType.WellDone, ButtonType.Ok);
                  this.backAction();
                }
                else if (payload.data == -1000) {                  
                  this.backAction();
                }
                else {
                  let errorMessage = payload.errorMessage == '' ? this.localization.getError(payload.data) : payload.errorMessage;
                  this.utils.showError(errorMessage);
                }
                break;
              case MappingSubjectDataType.SearchMappingResponse:
                this.policyOptions = payload.data;
                break;              
            }
          }
        })
        this.loadTableData();
        setTimeout(() => {
          this.utils.ToggleLoader(false);
        }, 100)
  }

  loadTableData() {
    this.originalcontent = this.tableOptions[0].TablebodyData;
    this.showInActiveFilter(); 
  }

  loadEditData(data: any){
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_editDepositPolicyMapping
    };
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: data
    };
  }

  createDepositPolicy() {
    this.depositPolicySharedService.clearMainGroupList(this.mainGroupList);
    this.depositPolicySharedService.clearGuestCategoryOptions(this.guestCategoryOptions);
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_save,
      form: undefined
    };
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_createDepositPolicyMapping
    };
  }

  showInactive(eve) {
    this.showInactivetog = !this.showInactivetog;
    this.showInActiveFilter();
  }

  showInActiveFilter(){
    if(this.showInactivetog)
      this.tableContent = this.originalcontent;
    else
     this.tableContent = this.originalcontent.filter(x=>x.isInActive);

     let tableData = [...this.tableOptions];
     tableData[0].TablebodyData = this.tableContent;
     this.tableOptions = tableData;
  }

  searchTextChange(eve) {
    this.searchText = eve;
  }

  formReady(event) {
    this.createForm = event;
  }

  backAction(form?) {
    this.createToggler = false;
    this.IsEditModeEnabledFlag = false;
    this.customEditCancelled = true;
  }

  CancelEvent(event) {
    this.IsEditModeEnabledFlag = false;
    this.customEditCancelled = true;
  }


  async DoneEvent(event) {
    this.IsEditModeEnabledFlag = false;
    this.customEditCancelled = false;
  }

  inactiveSliderChange(e){

  }


  handleClick(event){
    switch (event.from) {
      case ActionMode.create:
        var mappingData = this.depositPolicySharedService.mapPolicyMappingDetails(event.formValues, this.guestCategoryOptions, this.mainGroupList, false);
        this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.CreatePolicyMapping, mappingData);
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
      case ActionMode.update:
        var mappingData = this.depositPolicySharedService.mapPolicyMappingDetails(event.formValues, this.guestCategoryOptions, this.mainGroupList, true);
        this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.UpdatePolicyMapping, mappingData);
        break;

    }
  }

  back(e) {
    this.backAction();
  }

  edit(rowData){
    this.IsEditModeEnabledFlag = true;
    this.customEditCancelled = false;
    this.depositPolicySharedService.clearMainGroupList(this.mainGroupList);
    this.depositPolicySharedService.clearGuestCategoryOptions(this.guestCategoryOptions);
    this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.GetPolicyMapping, rowData[0].mappingGroupId);
  }
  deleteRow(rowData){
    this.utils.showAlert(this.captions.setting.lbl_deleteMsg, AlertType.Warning, ButtonType.YesNo, (res) => {
              if (res == AlertAction.YES) {
                this.depositPolicySharedService.sendMappingData(MappingSubjectDataType.DeletePolicyMapping, rowData[0].mappingGroupId);
              }
            })
  }
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

