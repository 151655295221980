import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { MultiCurrencyConverterService } from "../services/multi-currency-converter.service";

@Directive({
  selector: "[appMultiCurrencyConverter]",
})
export class CurrencyConverterDirective implements OnInit,OnChanges  {
  private defaultCurrency!: string; // Default currency
  private targetCurrency!: string; // Target currency from dropdown

  @Input() originalValue: number = 0;
  isInputElement: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private currencyConverterService: MultiCurrencyConverterService
  ) {
    this.defaultCurrency = sessionStorage.getItem("defaultCurrency");
  }

  ngOnInit(): void {
    this.isInputElement =
      this.el.nativeElement.tagName.toLowerCase() === "input";
    this.currencyConverterService.selectedCurrency$.subscribe((currency) => {
      if (this.originalValue) {
        const checkValid = this.cleanString(this.originalValue)
        this.updateDisplay(checkValid);
      }
    });
  }
  @HostListener("input", ["$event"]) onInputChange(event: any) {
    if (this.isInputElement) {
      this.originalValue = parseFloat(event.target.value);
      if (this.originalValue) {
        const checkValid = this.cleanString(this.originalValue)
        this.updateDisplay(checkValid);
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["originalValue"] && changes["originalValue"].currentValue !== changes["originalValue"].previousValue) {
      this.updateDisplay(this.cleanString(this.originalValue));
    }
  }
  private updateDisplay(inputValue): void {
    this.targetCurrency = sessionStorage.getItem("selectedCurrency") ?? this.defaultCurrency;
    const convertedValue = this.currencyConverterService.convert(
      inputValue.amount,
      this.defaultCurrency,
      this.targetCurrency
    );
    const decimalDigits = Number(sessionStorage.getItem("noOfDecimalDigits"));
    const value = this.currencyConverterService.GetCurrencySymbol(
      convertedValue.toFixed(decimalDigits),
      this.targetCurrency, inputValue.isNegative
    );
      this.renderer.setProperty(
        this.el.nativeElement,
        'innerText',
        `${value}`
      );
  }
  
  cleanString(value): { amount: number, isNegative: boolean } {
    let result = '';
    let isNegative = false;
    const cleanStr = value as string;
    if (cleanStr.includes('(') && cleanStr.includes(')')) {
      isNegative = true;
    }

    if (cleanStr.includes('-')) {
      result = cleanStr.replace(/^(-?[\d,]+(?:\.\d+)?)/, "$1");
    }
    else {
      result = cleanStr?.replace(/[^\d.]/g, "");
    }

    const amount = parseFloat(result.replace(/,/g, '')) || 0;
    return { amount: amount, isNegative: isNegative };
  }
}
