import { LengthConstants } from './../../../../Folio/Model/folioDetails-model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  ButtonValue
} from "src/app/common/Models/ag-models";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
// import { RetailTaxesDataService } from "../retail-taxes-data.service";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { CreateNewTaxTypeBusiness } from "./create-new-tax-type.business";
import { TaxMaintenance, TaxMaintenanceByCriteria, TaxReferenceType } from "../tax-type.model";
import { ActionMode } from "src/app/common/enums/shared-enums";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { TaxType } from "src/app/common/shared/shared/setupConstants";
import { MatDialogRef } from "@angular/material/dialog";
import { VersaTaxTypeComponent } from "./versa-tax-type/versa-tax-type.component";

@Component({
  selector: 'app-create-new-tax-type',
  templateUrl: './create-new-tax-type.component.html',
  styleUrls: ['./create-new-tax-type.component.scss'],
  providers: [CreateNewTaxTypeBusiness]
})
export class CreateNewTaxTypeComponent implements OnInit {
  @Output() reloadTable = new EventEmitter();
  @ViewChild(VersaTaxTypeComponent) versaTaxTypeComponent!: VersaTaxTypeComponent;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  @Input() crudInput;
  taxTypeForm: UntypedFormGroup;
  takeUntil;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  taxRangeMaintenance: any;
  taxRangeMaintenanceByCriteria: any;
  rangeMaintenanceTblContent: any;
  taxTypes: any;
  duplicateTaxType: number;
  zeroTaxValue = 5047010;
  isFormValid : boolean = false;
  rangeMaintanceTblContent : boolean = true;
  rangeMaintanceCriteriaTblContent : boolean = true;


  constructor(
    private localization: RetailLocalization,
    private business: CreateNewTaxTypeBusiness,
    private fb: FormBuilder,
    private utilities: RetailUtilities,
    private dialogRef: MatDialogRef<CreateNewTaxTypeComponent>,
  ) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.actionButton = {
      label: this.crudInput.actionButton,
      type: "primary",
      disabledproperty: true,
    };
    this.cancelButton = {
      label: this.captions.btn_cancel,
      type: "tertiary",
    };

    this.taxTypeForm = this.fb.group({});
    this.taxTypeForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.actionButton.disabledproperty = !(
          this.taxTypeForm.valid && this.taxTypeForm.dirty && this.rangeMaintanceCriteriaTblContent && this.rangeMaintanceTblContent && ((this.taxTypeForm.value.taxDetails.chargeTax && this.taxTypeForm.value.taxDetails.specializedTaxTypeId>0) || !this.taxTypeForm.value.taxDetails.chargeTax)
        );
      });
    this.getTaxTypes();
    this.getValidators();
  }

  async getTaxTypes() {
    this.taxTypes = await this.business.getTaxType(true);
  }
  TaxDetailForm(e){
    if (this.versaTaxTypeComponent){
      this.versaTaxTypeComponent.specializedTaxChange(this.taxTypeForm.value.taxDetails.isSpecialisedUse);
    }
  }
  taxDetailsTableEmit(eve) {
    this.rangeMaintanceTblContent = true;
    this.taxRangeMaintenance = eve.table;
    this.rangeMaintenanceTblContent = [...this.taxRangeMaintenance];
    //this.versaTableEmit(eve);
    this.isFormValid = this.taxTypeForm.value.versaTaxType.postTypeId>0
    this.rangeMaintanceTblContent = !eve.invalid ? true : eve.edited && !eve.invalid ? true : false;
    this.actionButton.disabledproperty = this.isFormValid && eve.edited && !eve.invalid ? false : this.rangeMaintanceTblContent ? this.actionButton.disabledproperty : true;
    if (this.versaTaxTypeComponent){
      this.versaTaxTypeComponent.inclusiveChange(this.taxTypeForm.value.taxDetails.taxInclusive);
    }
  }

  versaTableEmit(eve) {
    let length = this.rangeMaintenanceTblContent?.length ?? 0;
    this.rangeMaintanceCriteriaTblContent = true;
    if (length == 0 && eve.invalid) {
      this.actionButton.disabledproperty = true;
      this.rangeMaintanceCriteriaTblContent = false;
    }
    else{
      this.taxRangeMaintenanceByCriteria = eve.table.map(item => ({
        taxMaintenanceId: item.taxMaintenanceId,
        referenceType: item.referenceType,
        effectiveFrom: item.effectiveFrom,
        effectiveUntil: item.effectiveUntil,
        taxBasedOn: item.taxBasedOn,
        referenceValue: item.referenceType === TaxReferenceType.TaxByAgeCategory ? item.age : item.person,
        taxAmount: item.value,
        id: item.id
      }));     
      this.rangeMaintanceCriteriaTblContent = !eve.invalid ? true : eve.edited && !eve.invalid ? true : false;
      this.actionButton.disabledproperty = eve.edited && !eve.invalid ? false : this.rangeMaintanceCriteriaTblContent ? this.actionButton.disabledproperty : true;
      this.taxTypeForm.valueChanges;
    }
  }
  onAction(eve) {
    const clickReturnValue = {
      from: ActionMode.create,
      taxDetails: this.taxTypeForm.value.taxDetails,
      versaDetails: this.taxTypeForm.value.versaTaxType,
      rangeMaintenance: this.taxRangeMaintenance,
      taxMaintenanceByCriteria: this.taxRangeMaintenanceByCriteria
    };
    if (this.showRangeMaintenancePopUp()) {
      this.utilities.ToggleLoader(true);
      if (ActionMode.create === this.crudInput.mode) {
        this.business.create(clickReturnValue).then(() => {
          this.reloadTable.emit(true);
        }).catch(ex => {
          if (ex.error && (this.duplicateTaxType === ex.error.errorCode || this.zeroTaxValue === ex.error.errorCode)) {
            const code: number = parseInt(ex.error.errorCode);
            const message: string = this.localization.getError(code);
            this.utilities.showError(message);
          } else {
            this.utilities.showError(ex.message);
          }
        }).finally(() => this.utilities.ToggleLoader(false));
      }
      else if (ActionMode.update === this.crudInput.mode) {
        this.business.update(clickReturnValue).then(() => {
          this.reloadTable.emit(true);
        }).finally(() => this.utilities.ToggleLoader(false));
      }
    }
  }
  showRangeMaintenancePopUp(): boolean {
    if (this.taxRangeMaintenance === undefined || this.taxRangeMaintenance.length == 0) {
      this.utilities.showError(this.captions.err_createTaxRange);
      return false;
    }
    let isExists = this.taxTypes.some(x => x.taxType.toLowerCase() == this.taxTypeForm.value.taxDetails.taxType.toLowerCase() && x.id!=this.taxTypeForm.value.taxDetails.id);
    if (isExists) {
      this.utilities.showError(this.captions.err_duplicateTaxType);
      return false;
    }
    if (this.taxTypeForm.value.versaTaxType.postTypeId == "" || this.taxTypeForm.value.versaTaxType.postTypeId == 0) {
      this.utilities.showError(this.captions.err_invalidPostType);
      return false;
    }
    return true;
  }
  getValidators() {
    this.duplicateTaxType = TaxType.DUPLICATE_TAXTYPE_NAME;
  }
  onCancel(eve) {
    this.taxTypeForm.reset();
    this.reloadTable.emit(true);
  }
  onTabChange(eve) {
    if (eve.index === 1 && this.versaTaxTypeComponent) {
      this.versaTaxTypeComponent.rangeMaintenanceByCriteria();
      this.versaTaxTypeComponent.inclusiveChange(this.taxTypeForm.value.taxDetails.taxInclusive);
    }
  }
}
