import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { DialogCloseEnum } from "src/app/common/enums/shared-enums";
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AgFieldConfig, ButtonValue } from "src/app/common/Models/ag-models";
import { ReplaySubject, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { takeUntil } from "rxjs/operators";
import { MatStepper } from "@angular/material/stepper";
import { DayPassManagementBusiness } from "../day-pass-management.business";
import * as GlobalConst from 'src/app/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";

@Component({
  selector: "app-create-day-pass-management",
  templateUrl: "./create-day-pass-management.component.html",
  styleUrls: ["./create-day-pass-management.component.scss"],
})
export class CreateDayPassManagementComponent implements OnInit {
  createDayPassForm: UntypedFormGroup;
  propertyInfo: UntypedFormGroup;
  nextButton: ButtonValue;
  cancelButton: ButtonValue;
  previousButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  title: string;
  isViewOnly: boolean;
  DialogCloseEnum = DialogCloseEnum;
  entryPeriod: any;
  selectedIndex: number = 0;
  editGuestData: any;
  editEntryPeriodData: any;
  isEdit:boolean=false;
  step1Completed:boolean;
  step2Completed:boolean;
  triggerDaysValidation:boolean;

  constructor(
    private localization: Localization,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<CreateDayPassManagementComponent>,
    private http: HttpServiceCall,
    private utils: CommonUtilities,
    private cdRef: ChangeDetectorRef
  ) {
    this.captions = this.localization.captions;
    this.title = this.data.title;
    if (data && data?.fromEdit==true) {
      this.isEdit=true;
      this.editEntryPeriodData = data.entryForm,
      this.editGuestData = data.guestForm;
      
    }
  }

  ngOnInit(): void {
    this.initialize();
    this.createDayPassForm.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => {
        this.nextButton.disabledproperty = !(this.createDayPassForm.valid && this.createDayPassForm.dirty && (
          this.createDayPassForm.controls['EntryForm']['controls'].Sun?.value > 0 || this.createDayPassForm.controls['EntryForm']['controls'].Mon?.value > 0 ||
          this.createDayPassForm.controls['EntryForm']['controls'].Tue?.value > 0 || this.createDayPassForm.controls['EntryForm']['controls'].Wed?.value > 0 ||
          this.createDayPassForm.controls['EntryForm']['controls'].Thu?.value > 0 || this.createDayPassForm.controls['EntryForm']['controls'].Fri?.value > 0 ||
          this.createDayPassForm.controls['EntryForm']['controls'].Sat?.value > 0
        ));
        this.nextButton = { ...this.nextButton };
      });
      if(this.isEdit) {
        this.createDayPassForm.markAsDirty();
        this.nextButton.disabledproperty = false;
        this.nextButton = {...this.nextButton}
      }
  }

  initialize() {
    this.createDayPassForm = this.fb.group({
      caddyType: "",
      yearsofCaddyExperience: "",
      skills: "",
      fileDate: "",
    });
    this.nextButton = {
      type: "primary",
      label: this.captions.btn_next,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.previousButton = {
      type: "secondary",
      label: this.captions.btn_previous,
      disabledproperty: true,
    };
  }

  closePopUp(from) {
    this.dialogRef.close();
  }

 async onAction(e, stepper: MatStepper) {
    if (this.selectedIndex == 0) {
      this.selectedIndex = 1;
      this.step1Completed = true;
      this.triggerDaysValidation = true;
      stepper.next();
      
    } else {
      const entryForm = this.createDayPassForm.get("EntryForm") as FormGroup;
      const guestForm = this.createDayPassForm.get("GuestForm") as FormGroup;
      var DayPackageGuestSetupCount: any = [];
      const dayMapping: { [key: string]: number } = {
        Sun: 0,
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
      };
      let start_time_calc = this.localization.TimeToDate(entryForm.get("startTime")?.value);
      var startDate = this.localization.AddTimeToDate(entryForm.get("startDate")?.value, start_time_calc);
      // var startDate = this.localization.AddTimeToDate(
      //   entryForm.get("startDate")?.value,
      //   entryForm.get("startTime")?.value
      // );
      let end_time_calc = this.localization.TimeToDate(entryForm.get("endTime")?.value);
      var endDate = this.localization.AddTimeToDate(entryForm.get("endDate")?.value, end_time_calc);
      
      // var endDate = this.localization.AddTimeToDate(
      //   entryForm.get("endDate")?.value,
      //   entryForm.get("endTime")?.value
      // );
      var availableDetail = entryForm?.value;
      for (const day in dayMapping) {
        DayPackageGuestSetupCount.push({
          id: 0,
          dayPackageDetailsId: availableDetail.id,
          guestTypeId: 0,
          dayOfWeek: dayMapping[day],
          availableCount:
            availableDetail[day]
              ? parseInt(availableDetail[day], 10) || 0
              : 0,
        });
      } if (guestForm != null) {
        const guestTypeArray = guestForm.get("guestArr") as FormArray;
        var guestArray = guestTypeArray?.value;
        if (guestArray?.length > 0) {
          for (let item of guestArray) {
            for (const day in dayMapping) {
              DayPackageGuestSetupCount.push({
                id: 0,
                dayPackageDetailsId: availableDetail.id,
                guestTypeId: item.guestId,
                dayOfWeek: dayMapping[day],
                availableCount: item[day] ? parseInt(item[day], 10) || 0 : 0,
              });
            }
          }
        }
      }
      const DayPackage: any = {
        id: availableDetail.id,
        code: entryForm.get("code")?.value,
        description: entryForm.get("name")?.value,
        startDate: this.localization.convertDateTimeToAPIDateTime(startDate),
        endDate: this.localization.convertDateTimeToAPIDateTime(endDate),
        isActive: entryForm.get("activeToggle")?.value,
        dayPackageSetupCount :DayPackageGuestSetupCount
      };

      this.isEdit?await this.updateDayPackageByCode(DayPackage, entryForm.get("code")?.value).then(
        x => {
          if (x.successStatus){
            this.dialogRef.close();
          }
        }
      ).catch(
        x => this.catchError(x.error.errorCode)
      ) :await this.createDayPackage(DayPackage).then(
        x => {
          if (x.successStatus){
            this.dialogRef.close();
          }
        }
      ).catch(
        x => this.catchError(x.error.errorCode)
      ) 
    }
    console.log(this.createDayPassForm);
  }
  async createDayPackage(body)
  {
    var result = await this.http.CallApiAsync({
            host: GlobalConst.Host.spaManagement,
            callDesc: "CreateDayPackage",
            method: HttpMethod.Post,
            uriParams: { },
            body: body
    });
    return result;
  }

  async updateDayPackageByCode(body,code)
  {
    var result = await this.http.CallApiAsync({
            host: GlobalConst.Host.spaManagement,
            callDesc: "UpdateDayPackageByCode",
            method: HttpMethod.Put,
            uriParams: {code: code },
            body: body
    });
    return result;
  }
  
  catchError(errorCode){
    //this.utils.showError(this.localization.getError(errorCode));
    this.nextButton = {...this.nextButton};
  }


  onCancel(e) {
    this.dialogRef.close();
  }

  onPrevious(e, stepper: MatStepper) {
    if (this.selectedIndex == 1) {
      this.selectedIndex = 0;
      this.triggerDaysValidation = false;
      stepper.previous();
    }
  }
  getEntryForm(e) {
    this.entryPeriod = e;
    this.entryPeriod = { ...this.entryPeriod };
    this.createDayPassForm.addControl("EntryForm", e);
    // if(this.createDayPassForm.controls['entryPeriodForm']['controls'].sun.value > 0 || this.createDayPassForm.controls['entryPeriodForm']['controls'].mon.value > 0 ||
    //   this.createDayPassForm.controls['entryPeriodForm']['controls'].tue.value > 0 || this.createDayPassForm.controls['entryPeriodForm']['controls'].wed.value > 0 ||
    //   this.createDayPassForm.controls['entryPeriodForm']['controls'].thu.value > 0 || this.createDayPassForm.controls['entryPeriodForm']['controls'].fri.value > 0 ||
    //   this.createDayPassForm.controls['entryPeriodForm']['controls'].sat.value > 0) {
    //     this.nextButton.disabledproperty = false;
    //     this.nextButton = {...this.nextButton};
    //   } else {
    //     this.nextButton.disabledproperty = true;
    //     this.nextButton = {...this.nextButton};
    //   }
  }
  getGuestForm(e) {
    if (e.form) this.createDayPassForm.addControl("GuestForm", e.form);
    if (e.isValid) {
      this.isViewOnly = true;
    } else {
      this.isViewOnly = false;
    }
    this.nextButton = { ...this.nextButton };
  }
  onStepChange(event: StepperSelectionEvent): void {
    this.selectedIndex = event.selectedIndex;
    if (this.selectedIndex == 0) {
      this.nextButton.label = "NEXT";
      this.nextButton = { ...this.nextButton };
      this.previousButton.disabledproperty = true;
      this.previousButton = { ...this.previousButton };
      if(this.createDayPassForm.controls.GuestForm.valid) {
        this.step1Completed = true;
      }
      // } else {
      //   this.selectedIndex = 0;
      // }
    } else if (this.selectedIndex == 1) {
      this.nextButton.label = this.isEdit?"Update":"SAVE";
      // if(this.isViewOnly)
        this.nextButton.disabledproperty = this.isViewOnly;
      this.nextButton = { ...this.nextButton };
      this.previousButton.disabledproperty = false;
      this.previousButton = { ...this.previousButton };
    }
  }
}
