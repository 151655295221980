import { Injectable } from "@angular/core";
import { DropDownData } from "./report.modals";
import * as _ from "lodash";
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ServiceLocator } from 'src/app/common/service.locator';

@Injectable()
export class ReportBusinessService {

  /**
   * This can be used to save the filter data sources
   */
  public FilterDataSource :DropDownData[]=[];

  startingCash:any = 0;
  isClass: boolean = false;
  toggleIncludeInactive(IncludeInactiveToo: boolean, dropDownData: DropDownData[]) {
    let result :DropDownData[];
    result = dropDownData.map(data => {
      if (IncludeInactiveToo) {
        return  {
          id: data.id, code: data.code, showInDropDown: true, description: data.description, isActive: data.isActive
        }
      } else {
        return {
          id: data.id, code: data.code, showInDropDown: data.isActive, description: data.description, isActive: data.isActive
        }
      }
    });
    result = _.orderBy(result, [user => user.description.toLowerCase()], 'asc');
    return result;
  }


  toggleClickbtn(data: DropDownData, dataSourceArray: DropDownData[], selectedDataArray: DropDownData[], defaultData?: DropDownData, allselectedCheck?): DropDownData[] {
    let selectedArray = selectedDataArray;
    const currentlySelectedArray = data;
    if (currentlySelectedArray.id == defaultData.id) { /* For all button click */
      if (allselectedCheck) {
        selectedArray = [];
        selectedArray = dataSourceArray.map(x =>
          x
        );
      } else {
        selectedArray = [];
      }
    } else { /* For other than all button click */
      if (!selectedArray.find(x=>x.id === currentlySelectedArray.id)) {
        selectedArray.push(currentlySelectedArray);
      } else {
        selectedArray.splice(selectedArray.findIndex(x=>x.id === currentlySelectedArray.id), 1);

        if (selectedArray.find(x=>x.id === defaultData.id)) {
          selectedArray.splice(selectedArray.findIndex(x=>x.id === defaultData.id), 1);
        }
      }
    }
    selectedDataArray = selectedArray;
    return selectedArray;
  }

  fillSubCategoryFilterCaptions(data: any, captionAll: string): string {
    if (data) {
      let filterCaption: string;
      let isSpecificFilterSelected : boolean;
          let subcategory1List: any = this.fillSubCatgoryList(data.category[1]);
          let subcategory2List: any = this.fillSubCatgoryList(data.category[2]);
          let subcategory3List: any = this.fillSubCatgoryList(data.category[3]);
          let subcategory4List: any = this.fillSubCatgoryList(data.category[4]);
          let subcategory5List: any = this.fillSubCatgoryList(data.category[5]);

          if (subcategory1List && subcategory1List.length > 0) {
            filterCaption = this.fillSubCategoryName(subcategory1List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = data.category[1].selectedCat.length != data.category[1].catArr.length;
          } else {
            isSpecificFilterSelected = true
          }
          if (subcategory2List && subcategory2List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory2List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[2].selectedCat.length != data.category[3].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }
          if (subcategory3List && subcategory3List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory3List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[3].selectedCat.length != data.category[3].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }
          if (subcategory4List && subcategory4List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory4List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[4].selectedCat.length != data.category[4].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }
          if (subcategory5List && subcategory5List.length > 0 ) {
            filterCaption = this.fillSubCategoryName(subcategory5List, filterCaption);
            //to do: After "all" is removed modify the if condition based on the input
            isSpecificFilterSelected = isSpecificFilterSelected ? isSpecificFilterSelected : data.category[5].selectedCat.length != data.category[5].catArr.length;
          }else {
            isSpecificFilterSelected = true
          }

          if (!isSpecificFilterSelected) {
            filterCaption = captionAll
          }
          return filterCaption;
      }
  }

  getSelectedCategoryCaptions(selectedCategories: any): string[] {
    const categories: string[] = [];

    selectedCategories.category.forEach((a, index) => {
      const IsAll: boolean = selectedCategories.category[index].catArr.length === selectedCategories.category[index].selectedCat.length && selectedCategories.category[index].catArr.length > 0;
      const caption: string = IsAll ? 'ALL' : (a.selectedCat.map(o => o.name).join());
      categories.push(caption);
    });

    return categories;
  }

  private _localization: SpaLocalization;

  protected propertyCaptions:any = this.localization.propertyCaptions.reports;

    protected get localization(): SpaLocalization {
        if (this._localization == undefined) {
          this._localization = ServiceLocator.injector.get(SpaLocalization);
        }
        return this._localization;
    }

  public getFilterCaption(source: DropDownData[], selected: number[]) {
    const isAllSelected: boolean = source.length === selected.length;
    const selectedArr = [];
    selected.forEach((x) => {
      const filteredobj = source.filter((y) => {
        return x === y.id;
      })[0];
      if (filteredobj) {
        selectedArr.push(filteredobj.description);
      }
    });
   return isAllSelected ? this.propertyCaptions.All : selectedArr.join();
  }

  fillSubCatgoryList(customFilterData: any): number[] {
    return customFilterData.selectedCat.map(x => x.name);
  }

  fillSubCategoryName(subCategoryFilterList, filterCaption: string): string {
    subCategoryFilterList.forEach(element => {
      filterCaption = filterCaption == "" || filterCaption == null ? element : filterCaption + ', ' + element
    });
    return filterCaption;
  }
}
