import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from 'src/app/common/shared/shared/service/http-call.service';
// import { SpaUtilities } from '../../../utilities/spa-utilities';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Localization } from 'src/app/common/localization/localization';
// import { BreakPointAccess } from '../../../service/breakpoint.service';
// import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { MatDialog } from '@angular/material/dialog';
// import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
// import * as GlobalConst from 'src/app/shared/globalsContant';
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";

@Injectable()
export class ContactLogService {

    logTypeList: any = [];
    tableHeader: any = [];
    captions: any;
    commonCaptions: any;
    placeholderFormat: string;
    floatLabel: string;
    public tableDataTemp: any = [];
    public tableoptions: any;
    public tableDataArray: any = [];
    // constructor(public dialog: MatDialog, public http: HttpServiceCall, public utils: CommonUtilities,
    //     public localization: Localization, public appoinmentPopupService: AppointmentpopupService, public breakPoint: BreakPointAccess)
    constructor(public dialog: MatDialog, public http: HttpServiceCall, public utils: CommonUtilities,
      public localization: Localization)
        {
            this.captions = this.localization.captions;
            this.commonCaptions = this.localization.captions.common;
            this.placeholderFormat = this.localization.inputDateFormat;
            this.floatLabel = this.localization.setFloatLabel;
      }

    async getAllLogType(includeinactive: boolean,applicableType:number) {
      try {
        const results = await CommonDataAwaiters.GetAllContactLogType(includeinactive,applicableType);
        return results;
        // if (results) {
        //       this.logTypeList = results.result;
        //    }
     } catch (e) {
        console.error(e);
        throw e;
      }
      //   const results = await this.http.CallApiAsync<any[]>({
      //     host: GlobalConst.Host.spaManagement,
      //     callDesc: "GetAllLogType",
      //     method: HttpMethod.Get,
      //     uriParams: { "isIncludeInactive": includeinactive }
      //   });
      //   if (results) {
      //     this.logTypeList = results.result;
      //  }
    }

    BindTable(flag:boolean = false, isViewOnly:boolean=false ,isFromTherapist:boolean=false) {
      if(isFromTherapist){
        this.tableHeader = [
          { 'title': this.captions.tbl_hdr_logType, 'jsonkey': 'logType', 'searchable': true, 'sortable': true, 'Sortable': 'logType' },
            { 'title': this.captions.tbl_hdr_logDate, 'jsonkey': 'logDate', 'searchable': false, 'sortable': true, 'Sortable': 'logDate' },
            { 'title': this.captions.tbl_hdr_logNotes, 'jsonkey': 'logNotes_short', 'searchable': false, 'sortable': true, 'Sortable': 'logNotes',  'hover': 'logNotes' },
            { 'title': this.captions.tbl_hdr_expiryDate, 'jsonkey': 'expireDate', 'searchable': false, 'sortable': true, 'Sortable': 'expireDate' },
        ]
      }
      else{
        if (flag) {
          this.tableHeader = [
            { 'title': this.captions.tbl_hdr_logType, 'jsonkey': 'logType', 'searchable': false, 'sortable': true, 'Sortable': 'logType' },
            { 'title': this.captions.tbl_hdr_logDate, 'jsonkey': 'logDate', 'searchable': false, 'sortable': true, 'Sortable': 'logDate' },
            { 'title': this.captions.tbl_hdr_logNotes, 'jsonkey': 'logNotes_short', 'searchable': false, 'sortable': true, 'Sortable': 'logNotes', 'hover': 'logNotes' },
          ]
        } else {
          this.tableHeader = [
            { 'title': this.captions.tbl_hdr_logType, 'jsonkey': 'logType', 'searchable': true, 'sortable': true, 'Sortable': 'logType' },
            { 'title': this.captions.tbl_hdr_logDate, 'jsonkey': 'logDate', 'searchable': false, 'sortable': true, 'Sortable': 'logDate' },
            { 'title': this.captions.tbl_hdr_logNotes, 'jsonkey': 'logNotes_short', 'searchable': false, 'sortable': true, 'Sortable': 'logNotes',  'hover': 'logNotes' },
            { 'title': this.captions.tbl_hdr_dueDate, 'jsonkey': 'dueDate', 'searchable': false, 'sortable': true, 'Sortable': 'dueDate' },
            { 'title': this.captions.tbl_hdr_expiryDate, 'jsonkey': 'expireDate', 'searchable': false, 'sortable': true, 'Sortable': 'expireDate' },
            { 'title': this.captions.tbl_hdr_alert, 'jsonkey': 'isAlert', 'searchable': false, 'sortable': false, "type": "toggle", 'isDisabled': true },
            { 'title': this.captions.tbl_hdr_private, 'jsonkey': 'isPrivate', 'searchable': false, 'sortable': false, "type": "toggle", 'isDisabled': true },
            // { 'title': this.captions.tbl_hdr_group, 'jsonkey': 'isGroup', 'searchable': false, 'sortable': false, "type": "toggle", 'isDisabled': true }, //For Bug Fix - 93531
          ]
        }
      }

        this.tableoptions = [
          {
            TableHdrData: this.tableHeader,
            TablebodyData: this.tableDataTemp,
            pagination: false,
            sortable: false,
            Editable: true,
            CustomColumn: true,
            PlaceHoldertext: this.captions.lbl_SearchbylogTypeCaption,
            EnableActions: !flag,
            SelectRows: false,
            Searchable: !flag,
            EditMoreOption: false,
            // SelectedSettingId: GridType.waitlist,
            Sortable: 'logDate',
            TableId: '',
            disableDelete: false,
            SelectRow: true,
            SelectOnlyRow: true,
            dropdownOptions: '',
            waitlistIcon: false,
            lostDeniedIcon: false,
            TableDraggable: false,
            doneCancel: false,
            sticky: false,
            IsViewOnly: isViewOnly,
            disableEditButton:isViewOnly
          }
        ];
      }

      PrepareContactLogsData() {
        // if (this.appoinmentPopupService.clientEditData && this.appoinmentPopupService.clientWidowActionType == "EDIT") {
        //   this.appoinmentPopupService.clientContactLogs = this.appoinmentPopupService.clientEditData.clientContactLogs;
        // }
      }

      filterContactLogsByDate(fromDate,toDate) {
        // if(this.tableDataTemp.length == 0)
        //   this.PrepareContactLogsData();
        //   let clientContactLogDateFiltered =  this.appoinmentPopupService.clientContactLogs.filter(x=> fromDate <= x.logDate && toDate >= x.logDate);
        //   if(clientContactLogDateFiltered.length > 0){
        //     clientContactLogDateFiltered.forEach((x,i)=>{
        //       let logType =  this.logTypeList.filter(y=>y.id == x.logTypeId)[0];
        //       let logNotes_shortdata = x?.logNotes.length > 75 ? `${x?.logNotes.substring(0, 75)}...` : x.logNotes;
        //       this.tableDataTemp.push({ 'id': x.id, 'logDate': this.localization.LocalizeDate(x.logDate), 'logType': logType.logTypeInstance, 'logNotes': x.logNotes, 'logNotes_short': logNotes_shortdata, 'dueDate': x.dueDate ? this.localization.LocalizeDate(x.dueDate) : "", 'expireDate': x.expiryDate ? this.localization.LocalizeDate(x.expiryDate) : " ", 'isAlert': x.isAlert, 'isGroup': x.isGroup, 'isPrivate': x.isPrivate });
        //   });
        //   }
        // return this.tableDataTemp;
      }
}
