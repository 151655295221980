import { Injectable } from '@angular/core';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { UI, API, GroupName, EventSchedulerInterval, PatternType, DaysOfWeek, USERPREFERENCE_SCREENTYPE, FormattedReorderFieldDetailsSetup, ReorderFieldDetailsSetup, ExecuteQuery } from './event-scheduler.model';
import { Distribution, DistributionType, TemplateType } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { ReportAPIModel } from './event-scheduler.model';
import { JobSchedulerDataServices } from 'src/app/common/dataservices/notification/job-scheduler.data.service';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from '../utilities/common-utilities';
import { AUTOINVOICE } from 'src/app/common/constants';
import { API as SFTPApi } from 'src/app/common/Models/sftp.model';
import { JobType } from 'src/app/common/Models/notification.model';
import {DEFAULT_SUBJECT_NAME_JOBSCHEDULER} from 'src/app/common/shared/shared/globalsContant';

@Injectable()
export class EventSchedulerBusiness {
    masterGuestTableHeaderDetails: FormattedReorderFieldDetailsSetup[] = [];
    reOrderdetails: FormattedReorderFieldDetailsSetup[] = [];

    captions: any;
    constructor(private localization: Localization
        , private jobSchedulerDataServices: JobSchedulerDataServices
        , private notificationConfigurationService: NotificationConfigurationService
        , private templateDataServices: TemplateDataServices
        , private utilities: CommonUtilities
        ) {
        this.captions = this.localization.captions.ReportSchedular;
    }

    getTableOptions(viewOnly): TableOptions {
        return {
            defaultSortOrder: SorTypeEnum.desc,
            defaultsortingColoumnKey: 'referenceId',
            showTotalRecords:true,
            actions: [
                {
                    type: TableActions.edit,
                    disabled: viewOnly
                },
                {
                    type: TableActions.delete,
                    disabled: viewOnly
                }
            ]
        }
    }

    getTableHeaderOptions(): TableHeaderOptions[] {
        return [
            {
                key: 'referenceId',
                displayNameId: 'tbl_hdr_Reference',
                displayName: this.captions.tbl_hdr_ReferenceID,
                searchable: true,
                sorting: true,
                sortingKey: 'referenceId'
            },
            {
                key: 'eventName',
                displayName: this.captions.tbl_hdr_eventType,
                displayNameId: 'tbl_hdr_eventType',
                sorting: true,
                searchable: true
            },
            {
                key: 'reportName',
                displayName: this.captions.tbl_hdr_eventName,
                displayNameId: 'tbl_hdr_eventName',
                searchable: true,
                sorting: true,
                sortingKey: 'reportName'
            },
            {
                key: 'distributionList',
                displayName: this.captions.tbl_hdr_distributionList,
                displayNameId: 'tbl_hdr_distributionList',
                sorting: true,
                searchable: true,
                templateName: ActionTypeEnum.columnWithNumber
            },
            {
                key: 'user',
                displayName: this.captions.lbl_hdr_NotifyIcon,
                displayNameId: 'lbl_hdr_NotifyIcon',
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'schedule',
                displayName: this.captions.tbl_hdr_schedule,
                displayNameId: 'tbl_hdr_schedule',
                templateName: ActionTypeEnum.custom,
                sorting: true,
                sortingKey: 'schedule'
            },
            {
                key: 'status',
                displayName: this.captions.lbl_ScheduleState,
                displayNameId: 'lbl_ScheduleState',
                sorting: true,
                sortingKey: 'status',
                templateName: ActionTypeEnum.custom
            },
            {
                key: ActionTypeEnum.menu,
                displayName: this.captions.tbl_hdr_actions,
                displayNameId: 'tbl_hdr_actions',
                templateName: ActionTypeEnum.menu
            }
        ]
    }

    getTableMenus(state: boolean): UI.ScheduleActions[] {
        return [
            { id: UI.ScheduleActionOptions.update, menu: this.captions.lbl_updateSchedule, isDisabled: false },
            { id: UI.ScheduleActionOptions.delete, menu: this.captions.lbl_deleteSchedule, isDisabled: false },
            { id: UI.ScheduleActionOptions.refreshDistributionList, menu: this.captions.lbl_refreshDistributionList, isDisabled: !state },
            { id: UI.ScheduleActionOptions.pause, menu: this.captions.lbl_pauseSchedule, isDisabled: false }
        ]
    }

    async getTableContent(): Promise<UI.ScheduleGrid[]> {
        this.utilities.ToggleLoader(true);
        const schedules = await this.jobSchedulerDataServices.getAllSchedule();
        const result = await this.getRequiredServices();
        this.utilities.ToggleLoader(false);
        return this.gridMapper(schedules, result[0], result[1], result[4]);
    }

    async getRequiredServices() {
        const scheduleJobDefaultConfigurtaionsPromise = this.jobSchedulerDataServices.getAllJobDefaultConfiguration();
        const distributionListPromise = this.notificationConfigurationService.getAllDistributionByProductId();
        const emailTemplateListPromise = this.templateDataServices.GetAllTemplates(TemplateType.Email, false);
        const smsTemplateListPromise = this.templateDataServices.GetAllTemplates(TemplateType.SMS, false);
        const SFTPConfigurationListPromise = this.jobSchedulerDataServices.getSFTPConfiguration();
        const result = await Promise
            .all([scheduleJobDefaultConfigurtaionsPromise, distributionListPromise, emailTemplateListPromise, smsTemplateListPromise,SFTPConfigurationListPromise]);
        return result;
    }

    gridMapper(schedules: API.ScheduleJobConfiguration[], scheduleJobDefaultConfigurations: API.JobDefaultConfiguration[], distributionList: Distribution[], SFTPSetupConfigurationList : SFTPApi.SFTPConnection[]):
        UI.ScheduleGrid[] {
        const scheduleGrid: UI.ScheduleGrid[] = [];
        if (schedules && schedules.length) {
            schedules.map((o, index) => {
                const eventName: API.JobDefaultConfiguration = scheduleJobDefaultConfigurations?.find(job => job.id === schedules[index]?.scheduleJobId);
                const grpParticular = this.groupParticularMapper(schedules[index]?.scheduleJobParticular);
                const [guestParticular, userParticular] = grpParticular;
                const grpDistList = this.groupDistListMapper(grpParticular, distributionList);
                const distList = this.formDistributionList(grpDistList);
                const recurrenceRule: API.RecurrenceRule = schedules[index]?.recurrenceDefinition ? JSON.parse(schedules[index]?.recurrenceDefinition) : null;
                const executeQueryConfiguration = schedules[index]?.executeQueryConfiguration != '' ? JSON.parse(schedules[index]?.executeQueryConfiguration) : null;
                const reportConfig: any = schedules[index]?.reportConfiguration != '' ? JSON.parse(schedules[index]?.reportConfiguration) : null;
                const schedule: UI.ScheduleGrid = {
                    id: schedules[index]?.id,
                    referenceId: schedules[index]?.id,
                    eventName: JobType[eventName?.jobType],
                    distributionList: distList,
                    guestIsMessage: guestParticular?.sendSMS,
                    guestIsEmail: guestParticular?.sendMail,
                    userIsMessage: userParticular?.sendSMS,
                    userIsEmail: userParticular?.sendMail,
                    menuList: this.getTableMenus(userParticular?.sendMail),
                    fileUpload: schedules[index]?.isSFTP,
                    schedule: recurrenceRule ? EventSchedulerInterval[recurrenceRule.pattern.patternType] : '',
                    recurring: false,
                    reportName: this.getReportName(eventName?.jobName,reportConfig,executeQueryConfiguration),
                    status: schedules[index]?.scheduleState
                };
                scheduleGrid.push(schedule);
            });
        }
        return scheduleGrid;
    }

    getReportName(reportType: any, reportConfig: any, executeQueryConfiguration: any){
        switch(reportType){
            case 'Reports':
                return reportConfig?.reportAPIOptions?.name ?? reportConfig?.reportAPIOptions?.code;
            case 'AdhocReport':
                return executeQueryConfiguration?.labelName;
            default:
                return executeQueryConfiguration?.methodName ? executeQueryConfiguration.methodDescription : reportType;
        }
    }

    formDistributionList(distributionList: Distribution[]): string[] {
        const [guestEmailDL, guestSMSDL, userEmailDL, userSMSDL] = distributionList;
        const distList: string[] = [];
        if (guestEmailDL) {
            distList.push(guestEmailDL.distributionName);
        }
        if (guestSMSDL) {
            distList.push(guestSMSDL.distributionName);
        }
        if (userEmailDL) {
            distList.push(userEmailDL.distributionName);
        }
        if (userSMSDL) {
            distList.push(userSMSDL.distributionName);
        }
        return distList;
    }

    async createSchedule(scheduleData: any, reportConfigAPIOptions: { reportGroup: number, reportAPIOptions: ReportAPIModel }, executeQuery : ExecuteQuery)
        : Promise<boolean> {
        const data: API.ScheduleJobConfiguration = this.APIMapper(scheduleData, reportConfigAPIOptions,executeQuery);
        return await this.jobSchedulerDataServices.createSchedule(data);
    }


    async updateSchedule(scheduleData: any, reportConfigAPIOptions: { reportGroup: number, reportAPIOptions: ReportAPIModel }, executeQuery : ExecuteQuery)
        : Promise<boolean> {
        const data: API.ScheduleJobConfiguration = this.APIMapper(scheduleData, reportConfigAPIOptions,executeQuery);
        return await this.jobSchedulerDataServices.updateSchedule(data);
    }

    async isServiceAccountExist()
        : Promise<boolean> {
        return await this.jobSchedulerDataServices.isServiceAccountExist();
    }

    APIMapper(data: any, reportConfigAPIOptions: { reportGroup: number, reportAPIOptions: ReportAPIModel }, executeQuery : ExecuteQuery)
        : API.ScheduleJobConfiguration {
        const schedule = {} as API.ScheduleJobConfiguration;
        const scheduleJobParticular: API.ScheduleJobParticular[] = [];
        let recurrenceDefinition: API.RecurrenceRule;
        if(data.notificationScheduleData.value.event !== 2)
            recurrenceDefinition = this.recurrenceDefMapper(data.notificationScheduleData.value);
        schedule.id = data.eventDetailsData.value.id ? data.eventDetailsData.value.id : 0;
        schedule.scheduleJobId = data.eventDetailsData.value.event.id;
        schedule.isRunOnDemand = data.notificationScheduleData.value.event === 2 ? true : false,
        schedule.outputFileName = reportConfigAPIOptions.reportAPIOptions?.code,
        schedule.code = data.eventDetailsData.value.schedulerCode,
        schedule.description =  data.eventDetailsData.value.schedulerName,
        schedule.recurrenceDefinition = recurrenceDefinition ? JSON.stringify(recurrenceDefinition) : null;
        schedule.fileFormat = data.eventDetailsData.value.type.id;
        schedule.sftpDirectory =  data.eventDetailsData.value.sftpRemoteDirectory ? data.eventDetailsData.value.sftpRemoteDirectory : '';
        schedule.isSFTP =  data.eventDetailsData.value.sftp ? data.eventDetailsData.value.sftp : false;
        schedule.ftpDirectory =  data.eventDetailsData.value.ftpRemoteDirectory ? data.eventDetailsData.value.ftpRemoteDirectory : '';
        schedule.isFTP =  data.eventDetailsData.value.ftp ? data.eventDetailsData.value.ftp : false;
        schedule.reportConfiguration = reportConfigAPIOptions ? JSON.stringify(reportConfigAPIOptions) : '';
        schedule.executeQueryConfiguration = executeQuery ? JSON.stringify(executeQuery) : '';
        schedule.isJasperReport = reportConfigAPIOptions.reportAPIOptions?.isJasperReport;
        schedule.sftpSetupId = data.eventDetailsData.value.SFTPSetup ? data.eventDetailsData.value.SFTPSetup.id : reportConfigAPIOptions.reportAPIOptions?.sftpSetupId;
        if(data.scheduleOperationDetailConfig){
            data.scheduleOperationDetailConfig.code = data.eventDetailsData.value.schedulerCode;
            data.scheduleOperationDetailConfig.description = data.eventDetailsData.value.schedulerName;
        schedule.executeQueryConfiguration = data.scheduleOperationDetailConfig ? JSON.stringify(this.mapFromScheduleOperationUI(data.scheduleOperationDetailConfig)): '';
        //schedule.executeQueryConfiguration = data.scheduleOperationDetailConfig ? JSON.stringify(data.eventDetailsData.value.type.id): '';
        schedule.scheduleCustomUrlConfigurationId = data.scheduleOperationDetailConfig.id;
        schedule.outputFileName = data.scheduleOperationDetailConfig.viewValue;
        }
        else if(data.scheduleadhocReportConfiguration)
        {
            schedule.executeQueryConfiguration = data.scheduleadhocReportConfiguration ? JSON.stringify(this.mapFromScheduladhocReportUI(data.scheduleadhocReportConfiguration)): '';
            schedule.isJasperReport = true;
            schedule.outputFileName = data.scheduleadhocReportConfiguration.viewValue;
        }
        //schedule.scheduleOperationConfiguration = data.scheduleOperationDetailConfig ? JSON.stringify(this.mapFromScheduleOperationUI(data.scheduleOperationDetailConfig)): '';
        const guestScheduleJobParticular: API.ScheduleJobParticular = {
            groupName: GroupName[0],
            sendMail: data.eventDetailsData.value.active && data.eventDetailsData.value.guestEmail?true:false,
            sendSMS: data.eventDetailsData.value.active && data.eventDetailsData.value.guestSms?true:false,
            emailTemplateId: data.eventDetailsData.value.guestEmailTemplate?.id,
            smsTemplateId: data.eventDetailsData.value.guestSMSTemplate?.id,
            emailDistributionId: data.eventDetailsData.value.guestEmailDL?.id,
            smsDistributionId: data.eventDetailsData.value.guestSMSDL?.id,
            scheduleJobConfigurationId: 0,
            subject: data.eventDetailsData.value.guestEmailSubj ? data.eventDetailsData.value.guestEmailSubj : DEFAULT_SUBJECT_NAME_JOBSCHEDULER
        };
        const userScheduleJobParticular: API.ScheduleJobParticular = {
            groupName: GroupName[1],
            sendMail: data.eventDetailsData.value.userEmail?true:false,
            sendSMS: data.eventDetailsData.value.userSMS?true:false,
            emailTemplateId: data.eventDetailsData.value.userEmailTemplate?.id,
            smsTemplateId: data.eventDetailsData.value.userSMSTemplate?.id,
            emailDistributionId: data.eventDetailsData.value.userEmailDL?.id,
            smsDistributionId: data.eventDetailsData.value.userSMSDL?.id,
            scheduleJobConfigurationId: 0,
            subject: data.eventDetailsData.value.userEmailSubj ? data.eventDetailsData.value.userEmailSubj : DEFAULT_SUBJECT_NAME_JOBSCHEDULER
        };
        scheduleJobParticular.push(guestScheduleJobParticular);
        scheduleJobParticular.push(userScheduleJobParticular);
        schedule.scheduleJobParticular = scheduleJobParticular;
        return schedule;
    }

    recurrenceDefMapper(recurrenceDefinition: UI.RecurrenceRule): API.RecurrenceRule {
        const patternType = this.getPatternType(recurrenceDefinition);
        const [interval, position, dayOfMonth, month] = this.getRuleInterval(recurrenceDefinition);
        const daysOfWeek = this.getDaysOfWeek(recurrenceDefinition?.specificDays);
        const daysOfWeekTracker = daysOfWeek;
        const pattern: API.Pattern = {
            patternType,
            interval,
            position,
            dayOfMonth,
            month,
            daysOfWeek,
            daysOfWeekTracker
        };
        const range: API.Range = {
            runDate: this.utilities.getAPIDateTimeFromDateAndTimeControls(recurrenceDefinition.startDate.toDateString(), recurrenceDefinition.startTime),
            rangeType: recurrenceDefinition?.end,
            startDate: this.utilities.getAPIDateTimeFromDateAndTimeControls(recurrenceDefinition.startDate.toDateString(), recurrenceDefinition.startTime),
            endDate: recurrenceDefinition.endDate ? this.utilities.getAPIDateTimeFromDateAndTimeControls(recurrenceDefinition.endDate.toDateString(), '23:59') : "",
            noOfOccurence: recurrenceDefinition.noOfOccurance,
            noOfOccurenceCounter: recurrenceDefinition.noOfOccurance
        };
        const recurrenceRule: API.RecurrenceRule = {
            pattern,
            range
        };
        return recurrenceRule;
    }

    getPatternType(recurrenceDefinition: UI.RecurrenceRule): PatternType {
        let patternType: PatternType;
        switch (Number.parseInt(recurrenceDefinition?.scheduleType, 10)) {
            case PatternType.Daily:
                patternType = PatternType.Daily;
                break;
            case PatternType.Weekly:
                patternType = PatternType.Weekly;
                break;
            case PatternType.Monthly:
                patternType = PatternType.Monthly;
                break;
            case PatternType.Yearly:
                patternType = PatternType.Yearly;
                break;
        }
        return patternType;
    }

    getDaysOfWeek(days: boolean[]): string[] {
        const selectedDays: string[] = [];
        if(days){
            days.map((o, index) => {
                if (o) {
                    selectedDays.push(DaysOfWeek[Math.pow(2, index)]);
                }
            });
        }       
        return selectedDays;
    }

    getRuleInterval(recurrenceDefinition: UI.RecurrenceRule): number[] {
        const scheduleType = this.getPatternType(recurrenceDefinition);
        let interval = 0;
        let position = 0;
        let dayOfMonth = 0;
        let month = 0;
        switch (scheduleType) {
            case PatternType.Daily:
                interval = recurrenceDefinition?.days;
                break;
            case PatternType.Weekly:
                interval = recurrenceDefinition?.recurEveryWeek;
                break;
            case PatternType.Monthly:
                interval = recurrenceDefinition?.ofEveryMonth;
                position = recurrenceDefinition?.dayMonth;
                dayOfMonth = recurrenceDefinition?.monthDays;
                break;
            case PatternType.Yearly:
                interval = recurrenceDefinition?.ofEveryYear;
                position = recurrenceDefinition?.yearlyDays;
                month = recurrenceDefinition?.yearlyMonths;
                dayOfMonth = recurrenceDefinition?.yearlyDay;
                break;
            case PatternType.RelativeMonthly:
                break;
            case PatternType.RelativeYearly:
                break;
        }
        return [interval, position, dayOfMonth, month];
    }





    groupDistListMapper(grpParticular: API.ScheduleJobParticular[], distributionList: Distribution[]) {
        const distList: string[] = [];
        const [guestParticular, userParticular] = grpParticular;
        const emailDistributionList: Distribution[] = distributionList.filter(x => (x.distributionType & DistributionType.EMAIL) == DistributionType.EMAIL);
        const smsDistributionList: Distribution[] = distributionList.filter(x => (x.distributionType & DistributionType.SMS) == DistributionType.SMS);
        const guestEmailDL = emailDistributionList?.find(x => guestParticular?.emailDistributionId === x.id);
        const guestSMSDL = smsDistributionList?.find(x => guestParticular?.smsDistributionId === x.id);
        const userEmailDL = emailDistributionList?.find(x => userParticular?.emailDistributionId === x.id);
        const userSMSDL = smsDistributionList?.find(x => userParticular?.smsDistributionId === x.id);
        return [guestEmailDL, guestSMSDL, userEmailDL, userSMSDL];
    }

    groupParticularMapper(scheduleJobParticular: API.ScheduleJobParticular[]): API.ScheduleJobParticular[] {
        const guestParticular: API.ScheduleJobParticular = scheduleJobParticular?.find(x => x.groupName === GroupName[0]);
        const userParticular: API.ScheduleJobParticular = scheduleJobParticular?.find(x => x.groupName === GroupName[1]);
        return [guestParticular, userParticular];
    }

    async deleteSchedule(id: number): Promise<boolean> {
        return await this.jobSchedulerDataServices.deleteSchedule(id);
    }

    async refreshDistributionList(id: number): Promise<boolean> {
        return await this.jobSchedulerDataServices.refreshDistributionList(id);
    }

    async pauseSchedule(id: number): Promise<boolean> {
        return await this.jobSchedulerDataServices.pauseSchedule(id);
    }

    async resumeSchedule(id: number): Promise<boolean> {
        return await this.jobSchedulerDataServices.refreshDistributionList(id);
    }

    getFormattedTableHeaderOptions() {
        let jsonObj = this.getTableHeaderOptions()
        let lstAdvanceSearchField: FormattedReorderFieldDetailsSetup[] = []
        jsonObj.forEach(element => {
            let objAdvanceSearchField: FormattedReorderFieldDetailsSetup = {
                key: element.key,
                displayName: element.displayName,
                checked: true,
                order: 0,
                screenTypeId: USERPREFERENCE_SCREENTYPE.ScheduleConfigure,
                disabled: false,
                show: false
            };
            lstAdvanceSearchField.push(objAdvanceSearchField);
        });
        return lstAdvanceSearchField;
    }
    public uiHDRmapper(APIHDRobj, ScreenTypeId): FormattedReorderFieldDetailsSetup[] {
        let objcoll: FormattedReorderFieldDetailsSetup[] = []
        APIHDRobj.forEach(element => {
            if (element.screenTypeId == ScreenTypeId) {
                let obj: FormattedReorderFieldDetailsSetup = {
                    key: element.key,
                    displayName: element.displayName, // add localization here
                    disabled: false,
                    checked: element.checked,
                    show: true,
                    order: element.order,
                    screenTypeId: 0
                };
                objcoll.push(obj);
            }

        });
        this.masterGuestTableHeaderDetails = objcoll;
        this.masterGuestTableHeaderDetails.forEach(a => {
            a.screenTypeId = USERPREFERENCE_SCREENTYPE.ScheduleConfigure;
        });
        return objcoll;
    }
    getTableConfigureInitData() {
        let APIHDRobj = this.getFormattedTableHeaderOptions();
        this.uiHDRmapper(APIHDRobj, USERPREFERENCE_SCREENTYPE.ScheduleConfigure);
        return this.masterGuestTableHeaderDetails;
    }

    public getAdvanceSearchDataFromJson(): FormattedReorderFieldDetailsSetup[] {
        let jsonObj = this.getjsonobj();
        let lstAdvanceSearchField: FormattedReorderFieldDetailsSetup[] = []
        jsonObj.forEach(element => {
            let objAdvanceSearchField: FormattedReorderFieldDetailsSetup = {
                key: element.ctrlnmeKey,
                displayName: element.placeHldr,
                checked: true,
                order: element.listOrder,
                screenTypeId: USERPREFERENCE_SCREENTYPE.ScheduleConfigure,
                disabled: element.disabledflag,
                show: element.disabledflag
            };
            lstAdvanceSearchField.push(objAdvanceSearchField);
        });
        return lstAdvanceSearchField;
    }
    public async getConfigureHDRDetails() {
        // const apiReOrderModels: ReorderFieldDetailsSetup[] = await this.reorderFieldsDataService.getReorderFieldList(Number(this.localization.GetPropertyInfo('UserId')));
        // this.reOrderdetails = apiReOrderModels.map(x => this.reorderFieldDataMapper(x));
    }

    private reorderFieldDataMapper(ReorderAPIModel: ReorderFieldDetailsSetup): FormattedReorderFieldDetailsSetup {
        const reOrderItem: FormattedReorderFieldDetailsSetup = {} as FormattedReorderFieldDetailsSetup;
        if (ReorderAPIModel === null) { return reOrderItem; }

        reOrderItem.checked = ReorderAPIModel.isChecked;
        reOrderItem.key = ReorderAPIModel.reorderFieldName;
        reOrderItem.order = ReorderAPIModel.listOrderLinkId;
        reOrderItem.screenTypeId = ReorderAPIModel.screenTypeId;

        return reOrderItem;
    }

    public getjsonobj() {
        let obj = [{
            objectType: dynamicChildobject.txtBx,
            ctrlnmeKey: 'EventName',
            ctrlnme: 'EventName',
            placeHldrid: 'EventName',
            placeHldr: 'EventName',
            isRequired: false,
            disabledflag: false,
            listOrder: 112,
            inputType: 'noprespace'
        }
        ]
        return obj;
    }

    getReorderDetails(screenTypeId: number) {
        this.updateMasterReorderDetailsData(this.reOrderdetails, screenTypeId);
        return this.masterGuestTableHeaderDetails.sort((a, b) => a.order - b.order)
    }

    updateMasterReorderDetailsData(APIHDRobj, ScreenTypeId) {
        APIHDRobj.forEach(element => {
            if (this.masterGuestTableHeaderDetails.filter(a => a.screenTypeId == ScreenTypeId && a.key == element.key).length > 0) {
                this.masterGuestTableHeaderDetails[this.masterGuestTableHeaderDetails
                    .findIndex(a => a.screenTypeId == USERPREFERENCE_SCREENTYPE.ScheduleConfigure && a.key == element.key)]
                    .checked = element.checked;
                this.masterGuestTableHeaderDetails[this.masterGuestTableHeaderDetails
                    .findIndex(a => a.screenTypeId == USERPREFERENCE_SCREENTYPE.ScheduleConfigure && a.key == element.key)]
                    .order = element.order;
            }
        });
    }
    public async saveTableOptionSettings(objHeaderOptions, screenTypeId) {
        await this.setConfigureHDR(objHeaderOptions, screenTypeId);
    }
    async setConfigureHDR(objHeaderOptions, screenTypeId) {
        let objReorderDetails = []
        objHeaderOptions.forEach(element => {
            let obj = {
                UserId: Number(this.localization.GetPropertyInfo('UserId')),
                ReorderFieldName: element.key,
                IsChecked: element.checked,
                ListOrderLinkId: element.order,
                ScreenTypeId: screenTypeId
            };
            objReorderDetails.push(obj);
        });

        await this.saveReorderFields(objReorderDetails);
    }

    public async saveReorderFields(objReorderDetails) {
        // await this.reorderFieldsDataService.saveReorderFieldList(objReorderDetails);
    }
    getSortedTableHeaderOptions(): TableHeaderOptions[] {

        let headerOptions: TableHeaderOptions[] = [];
        const defaultTableHeaderList = this.getTableHeaderOptions();
        const sortedList = this.masterGuestTableHeaderDetails.sort((a, b) => a.order - b.order);

        if (sortedList != null && sortedList.length > 0) {
            sortedList.forEach(element => {
                if (element.screenTypeId == USERPREFERENCE_SCREENTYPE.ScheduleConfigure && element.checked == true) {
                    const obj = defaultTableHeaderList.find(x => x.key === element.key);
                    if (obj) {
                        headerOptions.push(obj);
                    }
                }
            });
        } else {
            headerOptions = defaultTableHeaderList;
        }
        return headerOptions.filter(header => header.key !== 'action');
    }
    addTimeStringToDate(time: string, date: Date): Date {
        let timeSplit = time?.split(':');
        let timeReg = /(\d+)\:(\d+) (\w+)/;
        let parts = time.match(timeReg);
        if(parts == null && timeSplit?.length > 0)
        {
            let hour = parseInt(timeSplit[0].trim(), 10);
            let min = parseInt(timeSplit[1].trim(), 10);
            date.setHours(hour);
            date.setMinutes(min);
            return date;
        }
        else
        {
        var hours = /am/i.test(parts[3]) ?
            function (am) { return am < 12 ? am : 0 }(parseInt(parts[1], 10)) :
            function (pm) { return pm < 12 ? pm + 12 : 12 }(parseInt(parts[1], 10));
        var minutes = parseInt(parts[2], 10);
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
        }
    }

    mapFromScheduleOperationUI(scheduleOperationUI): Partial<API.ScheduleOperationDetail> {
        if (scheduleOperationUI) {
            const scheduleOperation: Partial<API.ScheduleOperationDetail> = {
                id: scheduleOperationUI.id,
                methodName: scheduleOperationUI.value,
                methodDescription: scheduleOperationUI.viewValue,
                code : scheduleOperationUI.code,
                description : scheduleOperationUI.description,
            }
            return scheduleOperation;
        }
  }
  mapFromScheduladhocReportUI(scheduleadhocReportUI): API.ScheduleAdhocReport {
    if (scheduleadhocReportUI) {
      const scheduleOperation: API.ScheduleAdhocReport = {
        labelName: scheduleadhocReportUI.viewValue,
        adhocReportURI: scheduleadhocReportUI.value
      }
      return scheduleOperation;
    }
  }
}
export enum dynamicChildobject {
    txtBx = 'txtBx',
    Number = 'Number',
    DD = 'DD',
    emailID = 'emailID',
    dependentctrl = 'dependentctrl'

}
