import { PubSub } from "pubsub-ts";
import { RetailEventParameters, RetailEventType } from "../../../retail/shared/events/event.model";
import { BaseEventSubscriber } from "../../../retail/shared/events/pubsub/base.event.subscriber";
import { retailPublisher } from "../../../retail/shared/events/pubsub/retail.publishers";
import { SubscriptionHandler } from "./subscription.handler";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class RetailEventSubscriber extends BaseEventSubscriber {

    constructor(private _subscriptionHandler: SubscriptionHandler) {
        super();
    }

    registerMe() {
        retailPublisher.addSubscriber(this);
        this.addSubscriber(this.onRetailEmit.bind(this));
        this.start();
    }

    unregisterMe() {
        retailPublisher.delete(this);
    }

    /**
     * Subscriber
     * @param {PubSub.Notification} notification
     * @memberof RetailEventSubscriber
     */
    async onRetailEmit(notification: PubSub.Notification): Promise<void> {

        let eventParams: RetailEventParameters<any> = notification.body;

        switch (eventParams.eventType) {
            case RetailEventType.Deposit:
                await this._subscriptionHandler.handleDeposit(eventParams);
                break;
            case RetailEventType.Void:
            case RetailEventType.Cancel:
                await this._subscriptionHandler.handleVoid(eventParams);
                break;
            case RetailEventType.ReturnWithTicket:
            case RetailEventType.ReOpen:
                await this._subscriptionHandler.handleReOpen(eventParams);
                break;
            case RetailEventType.CancelDeposit:
                await this._subscriptionHandler.handleCancelDeposit(eventParams);
                break;
            case RetailEventType.LinkRetailItem:
                await this._subscriptionHandler.handleAppointmentRetailItem(eventParams);
                break;
            case RetailEventType.CancellationNoShowCharge:
                await this._subscriptionHandler.handleCancellationNoShowCharge(eventParams);
                break;
            case RetailEventType.WaiveOffCancellationNoShowCharge:
                await this._subscriptionHandler.handleCancellationNoShowChargeWaiveOff(eventParams);
                break;
            case RetailEventType.DayPass:
                await this._subscriptionHandler.handleDayPass(eventParams);
                break;
            case RetailEventType.CancelDayPass:
                await this._subscriptionHandler.handleCancelDayPass(eventParams);
                break;
            case RetailEventType.ClassCheckOut:
                await this._subscriptionHandler.handleClassCheckOut(eventParams);
                break;
            case RetailEventType.UpdateCancellationNoShowCharge:
                await this._subscriptionHandler.handleUpdateCancellationNoShowCharge(eventParams);
                break;
            case RetailEventType.Correct:
                await this._subscriptionHandler.handleCorrect(eventParams);
                break;
            case RetailEventType.UpdateAppointmentFee:
                await this._subscriptionHandler.handleUpdateAppointmentFee(eventParams);
                break;
            default:
                break;
        }

        // trigger callback
        if (eventParams.callBack && typeof eventParams.callBack == "function") {
            eventParams.callBack(eventParams);
        }
    }

}
