import { CodeSetups } from './../shared/enums/shared-enums';
import { Injectable } from '@angular/core';
import * as CONSTANTS from '../ag-common/constants';

@Injectable()
export class SettingsSharedService {

  public showRequired = true;
  editAction(eventData, searchHeaderOptionData) {
    return {
      editObj: {
        code: eventData.Obj.code,
        name: eventData.Obj.name,
        isActive: eventData.Obj.isActive ? eventData.Obj.isActive.value : '',
        toggleShow: true,
        id: eventData.Obj.id,
        listOrder: eventData.Obj.listOrder
      },
      isEditMode: true,
      searchHeaderOption: {
        searchPlaceHolder: searchHeaderOptionData.searchPlaceHolder,
        toggleLabel: searchHeaderOptionData.toggleLabel,
        showInactive: false,
        toggleDisabled: false,
        createBtnDisabled: true,
        hasHeaderAction: true,
        searchBarhide: true
      }
    };  
  }

  cancelAction(eve, searchHeaderOption) {
    return {
      isEditMode: false,
      searchHeaderOption: {
        createBtnLabel: searchHeaderOption.createBtnLabel,
        searchPlaceHolder: searchHeaderOption.searchPlaceHolder,
        toggleLabel: searchHeaderOption.toggleLabel,
        showInactive: true,
        toggleDisabled: false,
        createBtnDisabled: true,
        hasHeaderAction: true,
        searchBarhide: false
      }
    }; 
  }

  public getListOrder(data) {
    if (data && data.value && data.value.length) {
      return Math.max.apply(Math, data.value.map(x => x.listOrder)) + 1;
    } else {
      return 1;
    }
  }

  getPhoneValidator() {
    return {
      phoneLength: CONSTANTS.PHONE_LENGTH
    };
  }

  getSettingsSetupValidators(codeSetup: CodeSetups) {
    if (codeSetup == CodeSetups.FunctionRoomSetup) {
      return {
        codeMinLength: CONSTANTS.CODE_MINLENGTH,
        codeMaxLength: CONSTANTS.FUNCTIONROOMSETUP_CODE_MAXLENGTH,
        nameMinLength: CONSTANTS.NAME_MINLENGTH,
        nameMaxLength: CONSTANTS.FUNCTIONROOMSETUP_NAME_MAXLENGTH,
        listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
        listOrderMaxValue: CONSTANTS.LISTORDER_MAXVALUE
      };
    } else {
      return {
        codeMinLength: CONSTANTS.CODE_MINLENGTH,
        codeMaxLength: CONSTANTS.CODE_MAXLENGTH,
        nameMinLength: CONSTANTS.NAME_MINLENGTH,
        nameMaxLength: CONSTANTS.NAME_MAXLENGTH,
        policyMinLength: CONSTANTS.POLICY_MINLENGTH,
        policyMaxLength: CONSTANTS.POLICY_MAXLENGTH,
        listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
        listOrderMaxValue: CONSTANTS.LISTORDER_MAXVALUE
      };
    }
  }

  getManagersSetupValidators() {
    return {
      codeMinLength: CONSTANTS.CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.CODE_MAXLENGTH,
      firstNameMinLength: CONSTANTS.FIRSTNAME_MINLENGTH,
      firstNameMaxLength: CONSTANTS.FIRSTNAME_MAXLENGTH,
      lastNameMinLength: CONSTANTS.LASTNAME_MINLENGTH,
      lastNameMaxLength: CONSTANTS.LASTNAME_MAXLENGTH,
      titleMinLength: CONSTANTS.TITLE_MINLENGTH,
      titleMaxLength: CONSTANTS.TITLE_MAXLENGTH,
      listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
      listOrderMaxValue: CONSTANTS.LISTORDER_MAXVALUE
    };
  }

  getFunctionRoomGroupValidators() {
    return {
      codeMinLength: CONSTANTS.FUNCTIONROOMGROUP_CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.FUNCTIONROOMGROUP_CODE_MAXLENGTH,
      nameMinLength: CONSTANTS.FUNCTIONROOMGROUP_NAME_MINLENGTH,
      nameMaxLength: CONSTANTS.FUNCTIONROOMGROUP_NAME_MAXLENGTH,
      listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
      listOrderMaxValue: CONSTANTS.LISTORDER_MAXVALUE
    };
  }

  getContactsSetupValidators() {
    return {
      searchTextMaxLength: CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      inputTextMaxLength: CONSTANTS.INPUT_MAXLENGTH,
      inputTextMinLength: CONSTANTS.INPUT_MINLENGTH,
      pagrNumberMaxLength: CONSTANTS.PAGERNUMBER_MAXLENGHTH,
      postalCodeMaxLength: CONSTANTS.POSTALCODE_MAXLENGTH,
      titleOfSalesCallMaxLength: CONSTANTS.TITLEOFSALESCALL_MAXLENGTH,
      commentsMaxLength: CONSTANTS.COMMENTS_MAXLENGTH
    };
  }

  getAccountSetupValidators() {
    return {
      searchTextMaxLength: CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      accountNumberMaxLength: CONSTANTS.ACCOUNT_NUMBER_MAXLENGTH,
      accountNumberMinLength: CONSTANTS.ACCOUNT_NUMBER_MINLENGTH,
      websiteMaxLength: CONSTANTS.WEBSITE_MAXLENGTH,
      inputTextMaxLength: CONSTANTS.ACCOUNT_NAME_MAXLENGTH,
      inputTextMinLength: CONSTANTS.ACCOUNT_NAME_MINLENGTH,
      pagrNumberMaxLength: CONSTANTS.PAGERNUMBER_MAXLENGHTH,
      postalCodeMaxLength: CONSTANTS.POSTALCODE_MAXLENGTH,
      titleOfSalesCallMaxLength: CONSTANTS.TITLEOFSALESCALL_MAXLENGTH,
      commentsMaxLength: CONSTANTS.COMMENTS_MAXLENGTH
    };
  }


  getBookingInfoValidators() {
    return {
      meetingNameMaxLength: CONSTANTS.BOOKINGINFO_MEETING_NAME_MAXLENGTH,
      meetingNameMinLength: CONSTANTS.BOOKINGINFO_MEETING_NAME_MINLENGTH,
      bookingIdMaxLength: CONSTANTS.BOOKINGINFO_BOOKINGID_MAXLENGTH,
      bookingIdMinLength: CONSTANTS.BOOKINGINFO_BOOKINGID_MINLENGTH
    };
  }

  getSalesSetupValidators() {
    return {
      salesTitleMaxLength: CONSTANTS.TITLEOFSALESCALL_MAXLENGTH,
      commentsMaxLength: CONSTANTS.COMMENTS_MAXLENGTH,
      searchTextMaxLength: CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      settingssearchTextMaxLength: CONSTANTS.SETTINGS_SEARCH_TEXT_MAXLENGTH
    };
  }

  getCompetitorValidators() {
    return {
      competitorDestinationCodeMaxLength: CONSTANTS.COMPETITOR_DESTINATION_CODE_MAX_LENGTH,
      competitorDestinationNameMaxLength: CONSTANTS.COMPETITOR_DESTINATION_NAME_MAX_LENGTH,
      competitorsNoOfRoomsMaxLength: CONSTANTS.COMPETITOR_NUMBER_OF_ROOMS_MAX_LENGTH,
      competitorsMeetingSpaceMaxLength: CONSTANTS.COMPETITOR_MEETING_SPACE_MAX_LENGTH,
      competitorsExhibitSpaceMaxLength: CONSTANTS.COMPETITOR_EXHIBIT_SPACE_MAX_LENGTH,
      competitorsPostivieCommentMaxLength: CONSTANTS.COMPETITOR_COMMENTS_MAX_LENGTH,
      preDecimalMaxLength: CONSTANTS.MAX_LENGTH_12
    };
  }

  getBlockroomRateMaxLength() {
    return {
      rateMaxLength: CONSTANTS.RATE_TEXT_MAXLENGTH
    };
  }

  GetMaxLenghtOfCurrencyField() {
    return CONSTANTS.MAX_LENGTH_12;
  }

  getCompetitorManageSearchTextMaxLength() {
    return {
      searchHeaderOptionMaxLength: CONSTANTS.COMPETITOR_MANAGE_SEARCH_TEXT_MAXLENGTH
    };
  }

  getAccountSearchTextMaxLength() {
    return {
      searchHeaderOptionMaxLength: CONSTANTS.ACCOUNT_SEARCH_TEXT_MAXLENGTH
    };
  }

  getCommentsValidators() {
    return {
      commentsMaxLength: CONSTANTS.COMMENTS_MAXLENGTH
    };
  }

  getBookingCommentsValidators() {
    return {
      commentsMinLength: CONSTANTS.BOOKING_COMMENTS_MINLENGTH,
      commentsMaxLength: CONSTANTS.BOOKING_COMMENTS_MAXLENGTH
    };
  }

  getBookingContactsValidators() {
    return {
      searchContactsMinLength: CONSTANTS.BOOKING_CONTACTS_SEARCH_MINLENGTH,
      searchContactsMaxLength: CONSTANTS.BOOKING_CONTACTS_SEARCH_MAXLENGTH
    };
  }

  getFunctionRoomValidators(): any {
    return {
      codeMaxLength: 8,
      nameMaxLength: 30,
      descMaxLength: 255,
      rentMaxLength: 12,
      lenMaxLength: 12,
      widthMaxLength: 12,
      heightMaxLength: 12,
      footMaxLength: 12,
      listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
      listOrderMaxValue: CONSTANTS.LISTORDER_MAXVALUE
    };
  }

  getMenuitemValidators() {
    return {
      menuItemMinlength: CONSTANTS.MENU_ITEM_NAME_MIN_LENGTH,
      menuItemMaxlength: CONSTANTS.INPUT_MAXLENGTH,
      menuItemcodeMaxlength: CONSTANTS.MENU_ITEM_CODE_MAX_LENGTH,
      listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
      listOrderMaxValue: CONSTANTS.LISTORDER_MAXVALUE,
      quantityMaxValue: CONSTANTS.QUANTITY_MAXVALUE

    };
  }

  getAccountInquiryValidators() {
    return {
      searchTextMaxLength: CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      accountNumberMaxLength: CONSTANTS.ACCOUNT_NUMBER_MAXLENGTH,
      websiteMaxLength: CONSTANTS.WEBSITE_MAXLENGTH,
      inputTextMaxLength: CONSTANTS.INPUT_MAXLENGTH,
      inputTextMinLength: CONSTANTS.INPUT_MINLENGTH,
      postalCodeMaxLength: CONSTANTS.POSTALCODE_MAXLENGTH,
      noOfRoomsMaxLength: CONSTANTS.CODE_MAXLENGTH,
      commentMaxLength: CONSTANTS.COMMENTS_MAXLENGTH,
      meetingNameMinLength: CONSTANTS.LENGTH_3,
      meetingNameMaxLength: CONSTANTS.LENGTH_50
    };
  }

  getInquiryRoomValidators() {
    return {
      inputMaxLength: CONSTANTS.CODE_MAXLENGTH,
      numberOfNightsMaxLength: CONSTANTS.NUMBER_OF_NIGHTS_MAXLENGTH,
      preDecimalMaxLength: CONSTANTS.MAX_LENGTH_12,
      maxDateDifference: CONSTANTS.MAX_DATE_DIFFERENCE,
      maxRetryCount: CONSTANTS.MAX_RETRY_COUNT
    };
  }

  getPropertyInfoValidators() {
    return {
      nameMaxLength: CONSTANTS.MAX_LENGTH_50,
      postalcodeMaxLength: CONSTANTS.MAX_LENGTH_10,
      cityMaxLength: CONSTANTS.MAX_LENGTH_50,
      stateMaxLength: CONSTANTS.MAX_LENGTH_50,
      countryMaxLength: CONSTANTS.MAX_LENGTH_50,
      tenantIdMinLength: CONSTANTS.MIN_LENGTH_1,
      tenantIdMaxLength: CONSTANTS.MAX_LENGTH_10,
      propCodeMaxLength: CONSTANTS.MAX_LENGTH_25
    };
  }

  getSalesAndCateringGroupValidators() {
    return {
      daysBeforeAutoReleaseMinLength: CONSTANTS.MIN_LENGTH_0,
      daysBeforeAutoReleaseMaxLength: CONSTANTS.MAX_LENGTH_10000000,
      roomBlockDaysRestrictionMinLength: CONSTANTS.MIN_LENGTH_0,
      roomBlockDaysRestrictionMaxLength: CONSTANTS.MAX_LENGTH_10000000,
      maximumRoomsOnAvailabilityGraphMinLength: CONSTANTS.MIN_LENGTH_0,
      maximumRoomsOnAvailabilityGraphMaxLength: CONSTANTS.MAX_LENGTH_10000000,
      maximumNumberOfDailyTasksMinLength: CONSTANTS.MIN_LENGTH_0,
      maximumNumberOfDailyTasksMaxLength: CONSTANTS.MAX_LENGTH_25
    };
  }

  getFunctionTypeValidators() {
    return {
      codeMinLength: CONSTANTS.FUNCTION_TYPE_CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.FUNCTION_TYPE_CODE_MAXLENGTH,
      nameMinLength: CONSTANTS.FUNCTION_TYPE_NAME_MINLENGTH,
      nameMaxLength: CONSTANTS.FUNCTION_TYPE_NAME_MAXLENGTH,
      decimalMaxLength: CONSTANTS.FUNCTION_TYPE_DECIMAL_MAXLENGTH
    };
  }

  public getHotdateHolidayValidators() {
    return {
      codeMinLength: CONSTANTS.HOLIDAY_CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.HOLIDAY_CODE_MAXLENGTH,
      nameMinLength: CONSTANTS.HOLIDAY_NAME_MINLENGTH,
      nameMaxLength: CONSTANTS.HOLIDAY_NAME_MAXLENGTH,
      noOfNightsMaxLength: CONSTANTS.HOLIDAY_NUMBER_OF_NIGHTS_MAXLENGTH
    };
  }

  getSleepingRoomValidation() {
    return {
      codeMinLength: CONSTANTS.SLEEPING_ROOM_CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.SLEEPING_ROOM_CODE_MAXLENGTH,
      nameMinLength: CONSTANTS.SLEEPING_ROOM_NAME_MINLENGTH,
      nameMaxLength: CONSTANTS.SLEEPING_ROOM_NAME_MAXLENGTH,
      roomLength: CONSTANTS.SLEEPING_ROOM_NAME_ROOMLENGTH,
      listOrderMaxLength: CONSTANTS.LISTORDER_MAXLENGTH,
      settingssearchTextMaxLength: CONSTANTS.SETTINGS_SEARCH_TEXT_MAXLENGTH
    };
  }

  getLetterValidators() {
    return {
      codeMinLength: CONSTANTS.LETTER_CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.LETTER_CODE_MAXLENGTH,
      nameMinLength: CONSTANTS.LETTER_NAME_MINLENGTH,
      nameMaxLength: CONSTANTS.LETTER_NAME_MAXLENGTH
    };
  }

  getCommentTemplateValidators() {
    return {
      commenttemplateMinLength: CONSTANTS.COMMENT_TEMPLATE_CODE_MINLENGTH,
      commenttemplateMaxLength: CONSTANTS.COMMENT_TEMPLATE_CODE_MAXLENGTH,
      listOrderMinLength: CONSTANTS.COMMENT_TEMPLATE_LISTORDER_MINLENGTH,
      listOrderMaxLength: CONSTANTS.COMMENT_TEMPLATE_LISTORDER_MAXLENGTH,
      commentMaxLength: CONSTANTS.COMMENT_TEMPLATE_COMMENTS_MAXLENGTH
    };
  }

  getTaskValidators() {
    return {
      taskCodeMinLength: CONSTANTS.TASK_CODE_MINLENGTH,
      taskCodeMaxLength: CONSTANTS.TASK_CODE_MAXLENGTH,
      taskNameMinLength: CONSTANTS.TASK_NAME_MINLENGTH,
      taskNameMaxLength: CONSTANTS.TASK_NAME_MAXLENGTH,
      offsetMinLength: CONSTANTS.TASK_OFFSET_MINLENGTH,
      offsetMaxLength: CONSTANTS.TASK_OFFSET_MAXLENGTH,
      offsetMinValue: CONSTANTS.TASK_OFFSET_MINVALUE,
      offsetMaxValue: CONSTANTS.TASK_OFFSET_MAXVALUE,
      listOrderMinLength: CONSTANTS.TASK_LISTORDER_MINLENGTH,
      listOrderMaxLength: CONSTANTS.TASK_LISTORDER_MAXLENGTH,
      searchTextMaxLength: CONSTANTS.SETTINGS_SEARCH_TEXT_MAXLENGTH
    };
  }

  getTaskIntegrationValidators() {
    return {
      taskDescriptionLength: CONSTANTS.TASK_DESC_MAXLENGTH,
      notesDescriptionLength: CONSTANTS.NOTES_DESC_MAXLENGTH,
    };
  }

  getTaskIntegrationErrorCodes() {
    return {
      completedErrorCode: CONSTANTS.COMPLETE_ERR_CODE,
      updateErrorCode: CONSTANTS.UPDATE_ERR_CODE,
      deleteErrorCode: CONSTANTS.DELETE_ERR_CODE,
      userLimtErrorCode: CONSTANTS.TASK_LIMIT_EXCEEDED
    };
  }

  getDiscountvalidators() {
    return {
      PERCENTAGE_MAXLENGTH : CONSTANTS.LENGTH_3,
      PRICE_MAXLENGTH: CONSTANTS.MAX_LENGTH_12,
      PERCENTAGE_MAXVALUE: CONSTANTS.MAX_LENGTH_100,
      PRICE_MAXVALUE: CONSTANTS.PRICE_MAXVALUE
    };
  }

  public getDefaultsValidators() {
    return {
      defaultFunctionBookDaysMinLength: CONSTANTS.MIN_LENGTH_0,
      defaultFunctionBookDaysMaxLength: CONSTANTS.MAX_LENGTH_25,
      numberOfDaysToResetPostConventionMinLength: CONSTANTS.MIN_LENGTH_0,
      numberOfDaysToResetPostConventionMaxLength: CONSTANTS.MAX_LENGTH_25,
      line1DescriptionMaximumLength: CONSTANTS.MAX_LENGTH_25,
      line2DescriptionMaximumLength: CONSTANTS.MAX_LENGTH_25,
      gratuityMaximumLength: CONSTANTS.LENGTH_DEC_100,
      serviceChargeMaximumLength: CONSTANTS.LENGTH_DEC_100
    };
  }

  getCateringSharedSetupValidators() {
    return {
      codeMinLength: CONSTANTS.ITEM_SETUP_CODE_MINLENGTH,
      codeMaxLength: CONSTANTS.ITEM_SETUP_CODE_MAXLENGTH,
      itemNameMinLength: CONSTANTS.ITEM_SETUP_ITEMNAME_MINLENGTH,
      itemNameMaxLength: CONSTANTS.ITEM_SETUP_ITEMNAME_MAXLENGTH,
      listOrderMaxLength: CONSTANTS.ITEM_SETUP_LISTORDER_MAXLENGTH,
      categoryMaxLength: CONSTANTS.ITEM_SETUP_CATEGORY_MAXLENGTH,
      locationMaxLength: CONSTANTS.ITEM_SETUP_LOCATION_MAXLENGTH,
      quantityMaxLength: CONSTANTS.ITEM_SETUP_QUANTITY_MAXLENGTH,
      priceDecimalMaxLength: CONSTANTS.ITEM_SETUP_PRICE_DECIMAL_MAXLENGTH,
      costDecimalMaxLength: CONSTANTS.ITEM_SETUP_COST_DECIMAL_MAXLENGTH,
      gratuityDecimalMaxLength: CONSTANTS.ITEM_SETUP_GRATUITY_DECIMAL_MAXLENGTH,
      serviceChargeMinLength: CONSTANTS.ITEM_SETUP_SERVICECHARGE_DECIMAL_MAXLENGTH,
      reportBeoMaxLength: CONSTANTS.ITEM_SETUP_REPORTBEO_MAXLENGTH,
      commmentMaxLength: CONSTANTS.ITEM_SETUP_COMMENTS_MAXLENGTH,

      vendorNameMaxLength: CONSTANTS.ITEM_SETUP_VENDORNAME_MAXLENGTH,
      postalCodeMaxLength: CONSTANTS.ITEM_SETUP_POSTAL_MAXLENGTH,
      cityMaxLength: CONSTANTS.ITEM_SETUP_CITY_MAXLENGTH,
      stateMaxLength: CONSTANTS.ITEM_SETUP_STATE_MAXLENGTH,
      countryMaxLength: CONSTANTS.ITEM_SETUP_COUNTRY_MAXLENGTH


    };
  }

  getPackageSearchInputLength() {
    return {
      inputTextMaxLength: CONSTANTS.INPUT_MAXLENGTH,
    };
  }

  // menuItemcodeMaxlength: CONSTANTS.MENU_ITEM_CODE_MAX_LENGTH


  getFunctionRoomTemplateValidators() {
    return {
      functionComponentCodeMaxLength: CONSTANTS.FUNCTION_COMPONENT_CODE_MAX_LENGTH,
      functionComponentNameMaxLength: CONSTANTS.FUNCTION_COMPONENT_NAME_MAX_LENGTH,
      functionNameMaxLength: CONSTANTS.FUNCTION_NAME_MAX_LENGTH,
    };
  }
  getFrMaintenanceValidators() {
    return {
      defaultStartTime: '00:00',
      defaultEndtime: '23:59',
      recurringData: [
        { description: 'daily', id: 1, name: 'Daily', disabled: false },
        { description: 'weekly', id: 2, name: 'Weekly', disabled: false },
        { description: 'monthly', id: 3, name: 'Monthly', disabled: false },
        { description: 'yearly', id: 4, name: 'Yearly', disabled: false }
      ]
    };
  }
  getSelectRevenueSetupValidator() {
    return {
      searchLength: CONSTANTS.INPUT_MAXLENGTH
    };
  }

  getFunctionValidators() {
    return {
      commentMaxLength: CONSTANTS.COMMENTS_MAXLENGTH,
      functionNameMaxLength: CONSTANTS.BOOKING_FUNCTION_NAME_MAX_LENGTH,
    };
  }

  getBEOTemplateValidator() {
    return {
      beoTempNumberMinLength: CONSTANTS.BEO_TEMPLATE_NUMBER_MINLENGTH,
      beoTempNumberMaxLength: CONSTANTS.BEO_TEMPLATE_NUMBER_MAXLENGTH,
      beoTempNameMinLength: CONSTANTS.BEO_TEMPLATE_NAME_MINLENGTH,
      beoTempNameMaxLength: CONSTANTS.BEO_TEMPLATE_NAME_MAXLENGTH,
      listOrderMaxLength: CONSTANTS.BEO_TEMPLATE_LISTORDER_MAXLENGTH
    };
  }

  getContactMergeValidators() {
    return {
      inputMaxLength: CONSTANTS.INPUT_MAXLENGTH,
      searchMaxLength: CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      accountNumberMinLength: CONSTANTS.ACCOUNT_NUMBER_MINLENGTH,
      inputTextMaxLength: CONSTANTS.INPUT_MAXLENGTH,
      inputTextMinLength: CONSTANTS.ACCOUNT_NAME_MINLENGTH,
      commentsMaxLength: CONSTANTS.COMMENTS_MAXLENGTH,
      accountNumberMaxLength: CONSTANTS.ACCOUNT_NUMBER_MAXLENGTH
    };
  }

  getRevenueSetupValidators() {
    return {
      gratuityMaxValue: CONSTANTS.PERCENTAGE_MAXVALUE,
      gratuityMaxLength: CONSTANTS.PERCENTAGE_MAXLENGTH,
      serviceChargeMaxValue: CONSTANTS.PERCENTAGE_MAXVALUE,
      serviceChargeMaxLength: CONSTANTS.PERCENTAGE_MAXLENGTH,
    };
  }

  getBudgetValidators() {
    return {
      maxValue: CONSTANTS.BUDGET_NUMBER_MAXVALUE,
      decimalMaxLength: CONSTANTS.BUDGET_DECIMAL_MAXLENGTH
    };
  }

  getBEOSearchValidators() {
    return {
      settingssearchTextMaxLength: CONSTANTS.SETTINGS_SEARCH_TEXT_MAXLENGTH,
      beoNumberMinlength: CONSTANTS.BEO_NUMBER_MINLENGTH,
      beoNumberMaxlength: CONSTANTS.BEO_NUMBER_MAXLENGTH,
      meetingNameMinlength: CONSTANTS.MEETING_NAME_MINLENGTH,
      meetingNameMaxlength: CONSTANTS.MEETING_NAME_MAXLENGTH,
      accountNameMinlength: CONSTANTS.BEO_ACCOUNT_NAME_MINLENGTH,
      accountNameMaxlength: CONSTANTS.BEO_ACCOUNT_NAME_MAXLENGTH,
      contactNameMinlength: CONSTANTS.CONTACT_NAME_MINLENGTH,
      contactNameMaxlength: CONSTANTS.CONTACT_NAME_MAXLENGTH
    };
  }

  getRoomValidators() {
    return {
      minNumberOfNights: CONSTANTS.MIN_NUMBER_OF_NIGHTS,
      maxNumberOfNights: CONSTANTS.MAX_NUMBER_OF_NIGHTS,
      minNumberOfRooms: CONSTANTS.MIN_NUMBER_OF_ROOMS,
      maxNumberOfRooms: CONSTANTS.MAX_NUMBER_OF_ROOMS
    };
  }
  getBookingActionValidators() {
    return {
      searchAccountMaxLength: CONSTANTS.SEARCH_ACCOUNT_MAXLENGTH
    };
  }

  getUserDefinedFieldErrorCodes() {
    return{
      listOrderErrorCode: CONSTANTS.USERDEFINEDFIELD_LISTORDER_ERRORCODE,
      listOrderExceedsErrorCode: CONSTANTS.USERDEFINEDFIELD_LISTORDER_LIMIT_ERRORCODE
    };
  }

  getUserDefinedFieldValidators() {
    return{
      minValueOfListOrder: CONSTANTS.USERDEFINEDFIELD_LISTORDER_MINVALUE,
      maxValueOfListOrder: CONSTANTS.USERDEFINEDFIELD_LISTORDER_MAXVALUE,
      minLengthOfLabel: CONSTANTS.USERDEFINEDFIELD_LABELFEILD_MINLENGTH,
      maxLengthOfLabel: CONSTANTS.USERDEFINEDFIELD_LABELFEILD_MAXLENGTH,
      minLengthOfText: CONSTANTS.USERDEFINEDFIELD_TEXTFEILD_MINLENGTH,
      maxLengthOfText: CONSTANTS.USERDEFINEDFIELD_TEXTFEILD_MAXLENGTH,
      minLengthOfComment: CONSTANTS.USERDEFINEDFIELD_COMMENTFEILD_MINLENGTH,
      maxLengthOfComment: CONSTANTS.USERDEFINEDFIELD_COMMENTFEILD_MAXLENGTH,
      minLengthOfDropDown: CONSTANTS.USERDEFINEDFIELD_DDFEILD_MINLENGTH,
      maxLengthOfDropDown: CONSTANTS.USERDEFINEDFIELD_DDFEILD_MAXLENGTH
    };
  }

  getTemplateDetailsValidators() {
    return{
      templateCodeMaxLength: 8,  // need to change as per requirement
      templateNameMaxLength: 50   // need to change as per requirement
    };
  }

  getWashPercentage() {
    return{
      washPercentMaxValue : CONSTANTS.WASH_MAXVALUE,
      washPercentMinValue : CONSTANTS.WASH_MINVALUE
    };
  }

  getAdvanceSearchBookingVaidators() {
    return{
      accountNumberMaxLength : CONSTANTS.ACCOUNT_NUMBER_MAXLENGTH,
      bookingIdmaxlength : CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      bookingNameMaxLength : CONSTANTS.SEARCH_TEXT_MAXLENGTH,
      beoSearhMaxLength : CONSTANTS.BEO_SEARCH_MAX_LENGTH
    };
  }
  getCategoryValidators() {
    return {
      nameMinLength: CONSTANTS.CATEGORYNAME_MINLENGTH,
      nameMaxLength: CONSTANTS.CATEGORYNAME_MAXLENGTH,
    };
  }
}

