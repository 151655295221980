import { Component, OnInit } from '@angular/core';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { ActionMode } from 'src/app/common/enums/shared-enums';
import { TableAction, TableOptions, TableSearchHeader } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/shared/localization/Localization';
import { headeroption } from 'src/app/common/shared/shared/components/menu-search/edit-menu-popup/menu-edit.model';
import { RetailTaxGroupsBusiness } from './retail-tax-groups.business';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailTaxGroupDataService } from './retail-tax-groups-data.service';

@Component({
  selector: 'app-retail-tax-groups',
  templateUrl: './retail-tax-groups.component.html',
  styleUrls: ['./retail-tax-groups.component.scss'],
  providers: [RetailTaxGroupsBusiness,RetailTaxGroupDataService]
})
export class RetailTaxGroupsComponent implements OnInit {
  captions: any;
  searchHeaderOption: TableSearchHeader;
  searchText: any;
  IsEditModeEnabledFlag: boolean = false;
  CreationToggler: boolean;
  crudActionInput: { mode: any; actionButton: any; form: any; };
  createModalData: createModal;
  isViewOnly: boolean;
  tableOptions: TableOptions;
  headerOptions: headeroption[];
  tableContent: Observable<any[]>;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showInactiveValue: boolean = false;

  constructor(private localization: Localization, private business: RetailTaxGroupsBusiness
    , private utilities: RetailUtilities
  ) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.pg_title_createTaxgroup,
      searchPlaceHolder: this.captions.lbl_searchByTaxgroupname,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: true,
      toggleDisabled: false,
      createBtnDisabled: false,
      hasHeaderAction: false,
      advancedSearch: this.captions.lbl_advancedSearch,
      searchDisabled: false,
      maxLength: 12
    };
    this.isViewOnly = false;
    this.tableOptions = this.business.getTableOptions();
    this.headerOptions = this.business.getHeaderOptions();
    this.getTableData(false);
  }

  searchChange(e) {
    this.searchText = e;
  }
  back(e) {
    this.IsEditModeEnabledFlag = false;
    this.CreationToggler = false;
  }
  createEvent(e) {

    this.CreationToggler = true;
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.pg_title_createTaxgroup,
      form: undefined,
    };
    this.createModalData = {
      title: this.captions.pg_title_createTaxgroup
    };
  }

  showInactiveToggler(e) {
    this.showInactiveValue = e.checked;
      this.getTableData(e.checked);
  }

  handleClick(eve) {
    switch (eve.from) {
      case ActionMode.create:
        this.getTableData(this.showInactiveValue);
      case ActionMode.update:
        this.back(eve);
        this.getTableData(this.showInactiveValue);
        break;
      case ActionMode.cancel:
        this.back(eve);
        break;
    }
  }

  formReady(e) {

  }

  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.edit:
        this.editEvent(eve.Obj);
        break;
      case FromTypeEnum.delete:
        this.delete(eve.Obj.id);
        break;
      case FromTypeEnum.dragdrop:
        if (eve.fromType === 'dragdrop' && eve.Obj.dragdata.listOrder !== eve.Obj.dropdata.listOrder) {
          eve.Obj.dragdata.listOrder = eve.Obj.dropdata.listOrder;
          const taxgroup = {
            id: eve.Obj.dragdata.id,
            name: eve.Obj.dragdata.taxgroupName,
            listOrder: eve.Obj.dragdata.listOrder,
            isActive: eve.Obj.dragdata.isActive.value,
            taxGroupConfiguration: []
          }
          this.business.UpdateTaxGroupFromGrid(taxgroup).then(() => this.getTableData(this.showInactiveValue));
        } else {
          this.getTableData(this.showInactiveValue);
        }
        break;
      case FromTypeEnum.switch:
        this.toggleSwitch(eve.Obj);
        break;
    }
  }

  editEvent(e) {
    this.GetTableDataById(e).then((data) => {
      let formValue = {
        id: data.id,
        taxgroupName: data.name,
        listOrder: e.listOrder,
        isActive: data.isActive,
        taxes: data.taxGroupConfiguration.map(config => config.taxId),
        taxesObject: data.taxGroupConfiguration.map(config => ({ ...config }))
      }
      this.crudActionInput = {
        mode: ActionMode.update,
        actionButton: this.captions.pg_title_createTaxgroup,
        form: formValue,
      };
      this.createModalData = {
        title: this.captions.pg_title_editTaxgroup
      };
      this.IsEditModeEnabledFlag = true;
      this.CreationToggler = true;
    });
  }

  delete(id: number) {
    this.business.deleteTaxGroup(id).then(() => this.getTableData(this.showInactiveValue));
  }

  ngAfterViewInit() {
    this.getTableData(this.showInactiveValue);
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  getTableData(isActive: boolean = false) {
    this.utilities.ToggleLoader(true);
    var tableContents = this.business.getTableData(isActive).finally(() => {
      this.tableContent = from(tableContents);
      this.utilities.ToggleLoader(false);
    });
  }

  async GetTableDataById(e) {
    return await this.business.getTaxTypeById(e.id);
  }

  toggleSwitch(e) {
    this.utilities.ToggleLoader(true);
    this.GetTableDataById(e).then(data => {
      const taxgroup = {
        id: e.id,
        name: data.name,
        listOrder: e.listOrder,
        isActive: e.isActive.value,
        taxGroupConfiguration: data.taxGroupConfiguration.map(config => ({ ...config }))
      }
      this.business.UpdateTaxGroupFromGrid(taxgroup).catch(ex => {
        if (!ex.valid) {
          this.utilities.showError(ex.message);
        }
      }).then(() => this.getTableData(this.showInactiveValue)).finally(() => {
        this.utilities.ToggleLoader(false);
      });
    })
  }
}
