import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CurrencyPipe } from '@angular/common';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { ConversionRate, CurrencyConversionInfo } from '../Models/currency-conversion.model';
import { CommonApiRoutes } from '../common-route';

@Injectable({
  providedIn: 'root',
})
export class MultiCurrencyConverterService {
  constructor(
   private authenticationcommunication: TenantManagementCommunication,
   private currencyPipe: CurrencyPipe
 ) {
   
}
  public async GetCurrencyDetails(curr?) {
    this.selectedCurrency.next(curr)
    let apiModels: CurrencyConversionInfo[] = await this.GetAllConversions(false);
    this.CurrencyData = apiModels.map(x => this.UIMapper(x));
  }

  public selectedCurrency = new BehaviorSubject<string>('');
   selectedCurrency$ = this.selectedCurrency.asObservable();
  public CurrencyData: ConversionRate[];
  public CurrencyCode: string;
  public CurrencySymbol: string;
  public ExchangeRate: number = 1;

  convert(amount: number, fromCurrency: string, toCurrency: string): number {
    if (fromCurrency === toCurrency) {
      return amount;
    }

    const conversionRate = this.CurrencyData?.filter(x => x.code == toCurrency)[0]?.rate;
    if (conversionRate) {
      return amount * conversionRate;
    }

    throw new Error(`Exchange rate not available for ${toCurrency}`);
  }

  public GetAllConversions(IncludeInActive): Promise<CurrencyConversionInfo[]> {
    const result = this.authenticationcommunication.getPromise<CurrencyConversionInfo[]>(
       { route: CommonApiRoutes.GetAllCurrencyConversion, uriParams: {"includeInActive":IncludeInActive} }, false);
   return result;
}

  public GetCurrencySymbol(amount: string, currencyCode: string, isNegativeNumber: boolean = false): string {
    const currencyWithSymbol = this.currencyPipe.transform(amount, currencyCode, 'symbol') ?? '';
    if (isNegativeNumber) {
      return currencyWithSymbol.replace(/(\D*)(\d.*)/, (_, currency, number) => `${currency}(${number})`);
    } else {
      return currencyWithSymbol;
    }
  }

private UIMapper(ClassesAPIModel: CurrencyConversionInfo): ConversionRate {
  return {
      id: ClassesAPIModel.id,
      code: ClassesAPIModel.currencyCode,
      description: ClassesAPIModel.currencyDescription,
      effectiveDate: ClassesAPIModel.effectiveDate,
      rate: ClassesAPIModel.exchangeRate,
      active: ClassesAPIModel.isActive,
      currencyCode: ClassesAPIModel.currencyCode
  } as ConversionRate;
}
}
