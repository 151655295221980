import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, includes, startsWith } from 'lodash'; // STORAGE THE BACK ARRAY
import { Localization } from '../../../localization/localization';


@Component({
  selector: 'app-filter-data-section',
  templateUrl: './filter-data-section.component.html',
  styleUrls: ['./filter-data-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterDataSectionComponent implements OnInit {
  datalist: any;
  intialDataArray: any;
  selecteddatalist: any;
  @Output() emittedData: EventEmitter<any> = new EventEmitter();
  alphabets: any[];
  allChecked :boolean = false;
  floatLabelNever: string;
  @Input('inputData')
  set inputval(val) {
    this.datalist = val;
    this.intialDataArray = cloneDeep(val);
    this.checkIfAllChecked();
  }

  @Input('inputSelectedListData')
  set inputselval(val) {
    this.selecteddatalist = val.map(o => o.id);
    this.checkIfAllChecked();
  }

  @Input() tabname;



  searchText = '';
  captions: any ;
  CommonplaceHolder='';

  selectedAlphabets: string;

  constructor(public localization: Localization) { 
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.alphabets = this.captions.common.alphabets;
    this.selectedAlphabets = this.captions.common.all;
    this.CommonplaceHolder=this.captions.common.SearchBy +" " +this.tabname; // It seems to be irrelevant while using user id hence removing it // Bug 91715
  }
  
  SearchCategories(e, type, alpabet?) {
    
    let searchvalue, newarr;
    
    if (type === 'search') {
      searchvalue = e.target.value ? e.target.value : '';
      newarr = this.intialDataArray.filter(x => includes((x.desc).toLowerCase(), searchvalue.toLowerCase()));
      this.selectedAlphabets = this.captions.common.all;
    }
    if (type === 'click') {
      searchvalue = e.target ? e.target.textContent : '';
      newarr = this.intialDataArray.filter(x => startsWith((x.desc).toLowerCase(), searchvalue.toLowerCase()));
      this.selectedAlphabets = searchvalue;
     
    }
   
    this.datalist =  newarr;
    return;
  }
  ButtonToggle(ga, gv) {
    ga.splice(ga.indexOf(gv), 1);
    if (ga.indexOf(gv) === -1 && gv !== '') {
      ga.push(gv);
    } else if (gv === '') {
      ga.push(this.captions.common.all);
    }
  }

  checkboxClick(eve, DL) {
    let flag = eve.checked;
    if (flag) {
      this.selecteddatalist.push(DL.id);
    } else {
      let index = this.selecteddatalist.indexOf(DL.id);
      this.selecteddatalist.splice(index, 1);
    }
    this.checkIfAllChecked();
    this.emittedData.emit({'pushFlag': flag, 'obj': DL});
  }

  allCheckboxClick(e){
    this.allChecked = e.checked;
    this.datalist.forEach(x => {
      if(e.checked){
       if(!this.selecteddatalist.find(y=>y==x.id)){
        this.selecteddatalist.push(x.id);
        this.emittedData.emit({'pushFlag': e.checked, 'obj': x});
       }
      }else{
        let index = this.selecteddatalist.indexOf(x.id);
        this.selecteddatalist.splice(index, 1);
        this.emittedData.emit({'pushFlag': e.checked, 'obj': x});
      }
    });
  }

  checkIfAllChecked(){
    if(this.datalist && this.selecteddatalist && this.datalist.length !=0 && this.selecteddatalist.length !=0){
      this.allChecked = this.datalist.every(x => this.selecteddatalist.find(y=>y==x.id));
    }
  }

  clearAllSelectedList(){
    this.allCheckboxClick(false)
}

}
