import { Injectable } from "@angular/core";
import { BaseResponse, TaxGroups } from "../../retail.modals";
import { HttpMethod, ServiceParams } from "src/app/common/Models/http.model";
import { HttpServiceCall } from "../../shared/service/http-call.service";
import { Host } from "../../shared/globalsContant";
import { FolioRoutes } from "../../retail-route";
import { UI } from "../retail-taxes/tax-type/tax-type.model";

@Injectable()
export class RetailTaxGroupDataService {

    constructor(private http: HttpServiceCall) { }

    public async getAllTaxGroups(includeInactive: boolean): Promise<TaxGroups[]> {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.GetAllTaxGroups,
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { includeInactive }
        });

        return response.result;
    }

    public async getTaxGroupById(id: number) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.GetTaxGroupById,
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { id }
        });

        return response.result;
    }

    public async CreateTaxGroup(taxGroups: TaxGroups) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.CreateTaxGroup,
            host: Host.folio,
            method: HttpMethod.Post,
            body: taxGroups,
            uriParams: undefined
        });
        return response.result;
    }

    public async updateTaxGroups(taxGroups: TaxGroups) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.UpdateTaxGroup,
            host: Host.folio,
            method: HttpMethod.Put,
            body: taxGroups,
            uriParams: undefined
        });
        return response.result;
    }

    public async deleteTaxGroup(id: number) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.DeleteTaxGroup,
            host: Host.folio,
            method: HttpMethod.Delete,
            body: undefined,
            uriParams: { id }
        });

        return response.result;
    }

    public async getNextListOrder(): Promise<number> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.GetNextListOrder,
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: undefined
        });
        return response.result;
    }

    public async UpdateTaxGroupFromGrid(TaxGroup: TaxGroups) {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.UpdateTaxGroupFromGrid,
            host: Host.folio,
            method: HttpMethod.Put,
            body: TaxGroup,
            uriParams: undefined
        });

        return response.result;
    }

    public async getAllTaxTypes(IncludeInActive: boolean = true): Promise<UI.TaxType[]> {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: FolioRoutes.GetAllTaxTypes,
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { IncludeInactive: IncludeInActive }
        });

        return response.result;
    }
}
