import { Injectable } from '@angular/core';
import { Host } from 'src/app/shared/globalsContant';
import { HttpServiceCall, HttpMethod } from 'src/app/shared/service/http-call.service';
import { Localization } from 'src/app/common/localization/localization';
import { Guid } from 'guid-typescript';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { DepositPolicy, DepositPolicyBaseDetails, DepositPolicyMapping, DepositPolicyMappingBaseDetails, DepositPolicyMappingTableData,
    GuestType,
    MainGroup, ServiceBaseDetails, ServiceGroupsWithServices, SubGroup } from 'src/app/common/shared/shared/deposit-policy/deposit-policy.model';

@Injectable()
export class SpaDepositPolicyService {

    captions: any;
    getGuestTypeValue: { [Key: number]: string };
    constructor(private http: HttpServiceCall, private localization: Localization) {
        this.captions = this.localization.captions;
        this.getGuestTypeValue = {
            0: this.captions.lbl_existingClient,
            2: this.captions.lbl_member,
            3: this.captions.lbl_hotelReservation
        };
    }

    getGuestCategories() {
        return [
            {
                id: 0,
                name: this.captions.lbl_existingClient,
                selected: false
            },
            {
                id: 3,
                name: this.captions.lbl_hotelReservation,
                selected: false
            },
            {
                id: 2,
                name: this.captions.lbl_member,
                selected: false
            }
        ]
    }

    updateGuestCategories(guestCategoryOptions: any[], selectedOptions: number[]): any[] {
        return guestCategoryOptions.map(o => ({
            ...o,
            selected: selectedOptions.includes(o.id)
        }));
    }

    async getAllGuestTypes(includeInActive: boolean = true) :Promise<GuestType[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllGuestType",
            host: Host.spaManagement,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { includeInactive: includeInActive }
        });
        return response.result;
    }

    async getAllDepositPolicies(includeInActive: boolean = true): Promise<DepositPolicyBaseDetails[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllDepositPolicies",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { includeInactive: includeInActive }
        });
        return response.result;
    }

    async getDepositPolicyDetailsById(id: number): Promise<any> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetDepositPolicyById",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { id: id }
        });
        return response.result;
    }

    async createOrUpdateDepositPolicy(PolicyDetails: DepositPolicy, isUpdate: boolean): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "CreateOrUpdateDepositPolicy",
                host: Host.schedule,
                method: HttpMethod.Put,
                showError: true,
                body: PolicyDetails,
                uriParams: { isUpdate: isUpdate }
            });
            return response;
        }
        catch (error) {
            return error.error
        }
    }

    async deleteDepositPolicy(id: number): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "DeleteDepositPolicy",
                host: Host.schedule,
                method: HttpMethod.Delete,
                showError: true,
                uriParams: { id: id }
            });
            return response;
        }
        catch (error) {
            return error.error;
        }
    }

    async getAllDepositPolicyMappings(includeInActive: boolean = true): Promise<DepositPolicyMappingBaseDetails[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllDepositPolicyMappings",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { includeInactive: includeInActive }
        });
        return response.result;
    }

    async getAllServicesGroupsWithServices(isClass: boolean): Promise<MainGroup[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllServicesGroupsWithServices",
            host: Host.spaManagement,
            method: HttpMethod.Get,
            showError: true,
            queryString: { key: "isClass", value: [isClass] }
        });
        return this.mapIsSelected(response.result);
    }

    mapIsSelected(data: ServiceGroupsWithServices[]): MainGroup[] {
        if (data && data.length > 0) {
            return data.map(serviceGroup => {
                return{
                    id: serviceGroup.id,
                    name: serviceGroup.name,
                    selected: false,
                    subGroup: this.mapServices(serviceGroup.services)
                } as MainGroup
            });
        }
        return [];
    }

    mapServices(services: ServiceBaseDetails[]) {
        if (services && services.length > 0) {
            return services.map(service => {
                return {
                    id: service.id,
                    name: service.name,
                    selected: false,
                } as SubGroup;
            });
        }
        return [];
    }

    async formObjectForPolicyMapping(mappingData: any): Promise<DepositPolicyMapping[]> {
        let mappingObj: DepositPolicyMapping[] = [];
        mappingData?.guestCategory?.forEach(async m => {
            mappingObj.push({
                id: 0,
                startDate: mappingData.startDate,
                endDate: mappingData.endDate,
                policyId: mappingData.policyId,
                guestType: mappingData.guestType,
                guestCategory: m,
                mappingGroupId: mappingData.mappingGroupId,
                policyMapping: await this.mapPolicyMapping(mappingData)

            });
        });
        return mappingObj;
    }
    
    async mapPolicyMapping(mappingData: any) {
        var serviceGroup: number[] = [];
        var service: number[] = [];
        mappingData.policyMapping.forEach(a => {
            if (a.selected) {
                serviceGroup.push(a.id);
                a.subGroup.forEach(s => {
                    if (s.selected)
                        service.push(s.id);
                });
            }
        });
        const jsonObject = {
            "ServiceGroup": serviceGroup,
            "Service": service
        };
        return JSON.stringify(jsonObject);
    }
    
    async mapServiceAndServiceGroup(serviceData: MainGroup[], mappingData: string) {
        const jsonString = mappingData.replace(/(\w+):/g, '"$1":');
        const parsedObject = JSON.parse(jsonString);
        serviceData?.forEach(serviceGroup => {
            if (parsedObject?.ServiceGroup?.includes(serviceGroup.id)) {
                serviceGroup.selected = true;
            }
            serviceGroup?.subGroup?.forEach(service => {
                if (parsedObject?.Service?.includes(service.id)) {
                    service.selected = true;
                }
            });
        });
    }

    async getDepositPolicyMapping(groupId: Guid): Promise<DepositPolicyMappingBaseDetails> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetDepositPolicyMappingByGroupId",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { groupId: groupId }
        });
        return response.result;
    }

    async deleteDepositPolicyMapping(groupId: Guid): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "DeleteDepositPolicyMapping",
                host: Host.schedule,
                method: HttpMethod.Delete,
                showError: true,
                uriParams: { groupId: groupId }
            });
            return response;
        }
        catch (error) {
            return error.error
        }
    }

    async createOrUpdateDepositPolicyMapping(mappingData: DepositPolicyMapping[], isUpdate: boolean = false): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "CreateOrUpdateDepositPolicyMapping",
                host: Host.schedule,
                method: HttpMethod.Put,
                showError: true,
                body: mappingData,
                uriParams: { isUpdate: isUpdate }
            });
            return response;
        }
        catch (error) {
            return error.error
        }
    }

    async updateDepositPolicyActive(policyId: number) {
        let response: any = await this.http.CallApiAsync({
            callDesc: "UpdateDepositPolicyActive",
            host: Host.schedule,
            method: HttpMethod.Put,
            showError: true,
            uriParams: { policyId: policyId }
        });
        return response.result;
    }

    mapMappingData(mappingData: DepositPolicyMappingBaseDetails[]): DepositPolicyMappingTableData[] {
        var result: DepositPolicyMappingTableData[] = [];
        mappingData.forEach(data => {
            result.push({
                id: data.policyId,
                name: data.name,
                code: data.code,
                applyTo: data.guestCategory.map(type => this.getGuestTypeValue[type]).join(', '),                
                dateRange: this.localization.localizedDate(this.localization.ToDate(data.startDate, "YYYY-MM-DD")) + ' - ' + this.localization.localizedDate(this.localization.ToDate(data.endDate, "YYYY-MM-DD")),
                mappingGroupId: data.mappingGroupId,
                isInActive: data.isActive,
                dateSort: new Date(data.startDate).getTime()
            })
        })
        return result;
    }

    getchargeOptions(){
        return [
          {
            id: 1,
            value: this.captions.gratuity,
            viewValue: this.captions.gratuity
          },
          {
            id: 2,
            value: this.captions.serviceCharge,
            viewValue: this.captions.serviceCharge
          },
          {
            id: 3,
            value: this.captions.tax,
            viewValue: this.captions.tax
          },
          {
            id: 4,
            value: this.captions.bookAppointment.CustomFee,
            viewValue: this.captions.bookAppointment.CustomFee
          }
        ]
      }

    policyMapper(data) {
        return data.map(x => this.uiMapper(x));
    }

    uiMapper(option): DropdownOptions {
        return {
            id: option.id,
            value: option.id,
            viewValue: option.name,
            //checked: false
        }
    }
}