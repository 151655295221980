import { Component } from '@angular/core';
import { menuTypes } from 'src/app/shared/shared.modal';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { CancellationNoShowFeeSwitch, EnableDepositPolicySwitch } from 'src/app/shared/globalsContant';

@Component({
  selector: 'app-spa-setup',
  templateUrl: './spa-setup.component.html',
  styleUrls: ['./spa-setup.component.scss']
})
export class SpaSetupComponent  {

  menuList: any;
  menuType = menuTypes;
  routes : any;
  constructor(private routeDataService: RouteLoaderService) {
    this.routes = this.routeDataService.GetChildMenu('/settings/setup', 3);
    let defaultSettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    let isCancellationNoShowFeeEnabled = defaultSettings?.find(x => x.switch == CancellationNoShowFeeSwitch)?.value == 'true';
    let isDepositPolicyEnabled = defaultSettings?.find(x => x.switch == EnableDepositPolicySwitch)?.value == 'true';

    this.routes.linkedElement.map((res) => {
      if (res) {
        if (res.routePath === '/settings/setup/cancellationOrNoShowFee') {
          res.visibility = isCancellationNoShowFeeEnabled;
          res.linkedElement.forEach(child => {
            if (child) {
              child.visibility = isCancellationNoShowFeeEnabled;
            }
          })
        }
        else if (res.routePath === '/settings/setup/depositPolicy') {
          res.visibility = isDepositPolicyEnabled;
          res.linkedElement.forEach(child => {
            if (child) {
              child.visibility = isDepositPolicyEnabled;
            }
          })
        }
      }
 
    });

    this.menuList = {
      menu: this.routes.linkedElement,
      menuType : menuTypes.tertiary
    };
  }

}
