import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, Renderer2, HostListener, ViewEncapsulation} from '@angular/core';
import { ArrowType } from '../../Models/ag-models';

@Component({
  selector: 'app-ag-swiper',
  templateUrl: './ag-swiper.component.html',
  styleUrls: ['./ag-swiper.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AgSwiperComponent implements OnInit {
  @Input() config: any = {
    slidesPerView: 1,
    spaceBetween: 12,
    keyboard: {
      enabled: false,
    },
    mousewheel: false,
    breakpoints: {},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  @ViewChild('swiper', { static: true }) swiper!: ElementRef<HTMLDivElement>;

  slidesPerView: number = 1;
  spaceBetween: number = 12;
  itemWidth: number;
  currentLeft: number = 0;
  private touchStartX = 0;
  private touchEndX = 0;
  isLeftDisabled: boolean = false;
  isRightDisabled: boolean = false;

  constructor(private renderer: Renderer2, private el: ElementRef) { }
  ngOnInit() {
    this.updateConfig();
  }

  ngAfterViewInit() {
    this.updateItemWidths();
    window.addEventListener('resize', () => this.updateItemWidths());
  }

  private updateItemWidths() {
    const container = document.getElementById('swiper-container--wrapper').clientWidth;
    const swiperItems = this.el.nativeElement.querySelectorAll('.swiper-item');
    this.slidesPerView = this.getSlidesPerView();

    const overallGap = ((this.slidesPerView - 1) * this.spaceBetween)
    this.itemWidth = ((container - overallGap) / this.slidesPerView);

    swiperItems.forEach((item: HTMLElement) => {
      this.renderer.setStyle(item, 'width', this.itemWidth + "px");
    });
    this.scrollToFirst();
  }

  private getSlidesPerView(): number {
    const breakpoints = this.config.breakpoints || {};
    const screenWidth = window.innerWidth;

    // Determine the slidesPerView based on breakpoints
    const matchingBreakpoint = Object.keys(breakpoints)
      .sort((a, b) => +a - +b)
      .reverse()
      .find((breakpoint) => screenWidth >= +breakpoint);

    return matchingBreakpoint
      ? breakpoints[matchingBreakpoint].slidesPerView
      : this.config.slidesPerView;
  }

  scrollToFirst(){
    this.currentLeft = 0;
    this.updatePosition();
    this.updateDisable();
  }

  scrollLeft() {
    if (this.currentLeft < 0) {
      this.currentLeft += (this.itemWidth + this.spaceBetween);
      this.updatePosition();
      this.updateDisable();
    }
  }

  scrollRight() {
    const containerWidth = document.getElementById('swiper-inner--wrapper').clientWidth;
    const visibleItemsWidth = (this.itemWidth * this.slidesPerView);
    const visibleRemainingWidth = containerWidth - visibleItemsWidth;
    const maxLeft = -visibleRemainingWidth;
    const maxSpaceBetween = (this.slidesPerView * this.spaceBetween);
    if ((Math.abs(this.currentLeft) + maxSpaceBetween) < Math.abs(maxLeft)) {
      this.currentLeft -= (this.itemWidth + this.spaceBetween);
      this.updatePosition();
      this.updateDisable();
    }
  }

  private updatePosition() {
    document.getElementById('swiper-inner--wrapper').style.left  = `${this.currentLeft}px`;
  }
  
  updateDisable(){
    //Right Disabling
    const containerWidth = document.getElementById('swiper-inner--wrapper').clientWidth;
    const visibleItemsWidth = (this.itemWidth * this.slidesPerView);
    const visibleRemainingWidth = containerWidth - visibleItemsWidth;
    const maxLeft = -visibleRemainingWidth;
    const maxSpaceBetween = (this.slidesPerView * this.spaceBetween);
      this.isRightDisabled = ((Math.abs(this.currentLeft) + maxSpaceBetween) >= Math.abs(maxLeft))? true : false;
    //Left Disabling
      this.isLeftDisabled = (this.currentLeft < 0)? false : true;
  }

  private updateConfig() {
    this.slidesPerView = this.config.slidesPerView || 1;
    this.spaceBetween = this.config.spaceBetween || 0;
  }

  onWheel(event: WheelEvent) {
    if(this.config.mousewheel){
      if (event.deltaY > 0) {
        this.scrollRight();
      } else if (event.deltaY < 0) {
        this.scrollLeft();
      }
    }
  }
  

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].screenX;
    this.checkSwipe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if(this.config.keyboard.enabled){
      if (event.key === 'ArrowRight') {
        this.scrollRight();
      } else if (event.key === 'ArrowLeft') {
        this.scrollLeft();
      }
    }
  }

  private checkSwipe() {
    const swipeDistance = this.touchEndX - this.touchStartX;
    if (swipeDistance > 50) {
      this.scrollLeft();
    } else if (swipeDistance < -50) {
      this.scrollRight();
    }
  }

}
