import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AgDateConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';


@Component({
  selector: 'app-entry-period-utilization',
  templateUrl: './entry-period-utilization.component.html',
  styleUrls: ['./entry-period-utilization.component.scss']
})
export class EntryPeriodUtilizationComponent implements OnInit {

  title: string;
  captions: any;
  DialogCloseEnum = DialogCloseEnum;
  entryPeriodUtilForm : UntypedFormGroup;
  dateInput: AgDateConfig;
  searchButton: ButtonValue;
  showTable:boolean = false;
  utilizationData:any = [];
  minStartDate: any;


  constructor(public localization : Localization, @Inject(MAT_DIALOG_DATA) private data, private fb: UntypedFormBuilder,
  private dialogRef: MatDialogRef<EntryPeriodUtilizationComponent>,
      private http: HttpServiceCall,public PropertyInfo: SpaPropertyInformation) {
    this.captions = this.localization.captions;
      this.title = this.data.title;
   }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.minStartDate = this.PropertyInfo.CurrentDate;
    this.entryPeriodUtilForm = this.fb.group({
      date: this.minStartDate,
    });
    this.dateInput = {
      form: this.entryPeriodUtilForm,
      formControlName: 'date',
      placeHolderId: 'lbl_startDate',
      placeHolder: this.captions.lbl_date,
      automationId:"'Txt_entryPeriodUtilization_date'"
    };
    this.searchButton = {
      type: 'secondary',
      label: this.captions.btn_search,
      // disabledproperty: true
    }
    this.dateChanged();
    
  //   this.utilizationData = [
  //     {
  //     id : '15091',
  //     name : 'earlybird',
  //     startTime :' 07:00AM',
  //     endTime : '08.00AM',
  //     available : '07',
  //     sold :'03',
  //     remaining : '04',
  //     guest : [
  //     { 
  //       name : 'resort GUest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'resort Guest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'VVIP Mmebr',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'Hotel Guest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'Stay Guest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //    ]
  //   },
  //     {
  //     id : '15091',
  //     name : 'earlybird',
  //     startTime :' 07:00AM',
  //     endTime : '08.00AM',
  //     available : '07',
  //     sold :'03',
  //     remaining : '04',
  //     guest : [
  //     { 
  //       name : 'resort GUest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'resort Guest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'VVIP Mmebr',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'Hotel Guest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //     { 
  //       name : 'Stay Guest',
  //       available : '07',
  //       sold :'03',
  //       remaining : '04'
  //     },
  //    ]
  //   }
  // ]
}
  closePopUp(from) {
    this.dialogRef.close();
  }
  async dateChanged() {
    this.showTable = this.entryPeriodUtilForm.controls.date.value ?  true : false;
    if(this.showTable)
      {
        var date=this.localization.convertDateToAPIdate(this.entryPeriodUtilForm.controls.date.value);
        this.utilizationData = await this.GetDayPackageUtilizedByDate(date);
      }
  }
   async GetDayPackageUtilizedByDate(date) {
      var result = await this.http.CallApiAsync<any>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "GetDayPackageUtilizedByDate",
        method: HttpMethod.Get,
        uriParams: { startDate: date },
      });
      return result.result;
    }
  // async onSearchClick() {
  //   this.showTable = this.entryPeriodUtilForm.controls.date.value ?  true : false;
  //   if(this.showTable)
  //   {
  //     var date=this.localization.convertDateToAPIdate(this.entryPeriodUtilForm.controls.date.value);
  //     this.utilizationData = await this.GetDayPackageUtilizedByDate(date);
  //   }
  // }

}
