import { Injectable } from '@angular/core';
import { CommonApiRoutes } from '../common-route';
import { ReportCommunication } from '../communication/services/report.service';
import { TenantManagementCommunication } from '../communication/services/tenantmanagement-communication-service';
import { API, UI ,DiarySearchFilter, DocumentModel, DocumentResponse, DocumentModelResponse, CalenderCount} from "../Models/diary.model";
@Injectable({
  providedIn: 'root'
})
export class DiaryDataService {

  constructor(private _tenantManagementCommunication: TenantManagementCommunication, private _reportCommunication : ReportCommunication) {

  }
  public async CreateDiaryEvent(diaryEvent: API.Diary): Promise<boolean> {
    const result = await this._tenantManagementCommunication.postPromise<boolean>(
      { route: CommonApiRoutes.CreateDiary, body: diaryEvent }, false, true);
    return result;
  }
  public async UpdateDiaryEvent(diaryEvent: API.Diary): Promise<boolean> {
    const result = await this._tenantManagementCommunication.putPromise<boolean>(
      { route: CommonApiRoutes.UpdateDiary, body: diaryEvent }, false, true);
    return result;
  }

  public async DeleteDiaryEvent(diaryId: number): Promise<boolean> {
    const result = await this._tenantManagementCommunication.deletePromise<boolean>(
      { route: CommonApiRoutes.DeleteDiary, uriParams: { diaryId: diaryId } }, false, true);
    return result;
  }

  public async CopyEvents(copyFromYear: number, copyToYear: number) {
    const result = await this._tenantManagementCommunication.putPromise<boolean>(
      { route: CommonApiRoutes.CopyEvents, uriParams: { copyFromYear: copyFromYear, copyToYear: copyToYear } }, false, true);
    return result;
  }

  public UploadImages(doc : DocumentModel[]) : Promise<DocumentModelResponse[]>{
    const result = this._reportCommunication.putPromise<DocumentModelResponse[]>(
      { route: CommonApiRoutes.SaveDocuments, body: doc }, false);
    return result;
  }
  public GetAllImages(docids : string[]) : Promise<DocumentResponse[]>{
    const result = this._reportCommunication.putPromise<DocumentResponse[]>(
      { route: CommonApiRoutes.GetSaveDocuments, body: docids }, false);
    return result;
  }
  public Searchtag(searchKeyWord: string): Promise<API.DiaryTag[]> {
    const result = this._tenantManagementCommunication.getPromise<API.DiaryTag[]>(
        { route: CommonApiRoutes.SearchDiaryTag, uriParams: { name: searchKeyWord } }, false);
    return result;
 }
 public GetDiaryEventByDate(diaryDate: string): Promise<API.DiarySearchResult[]> {
  const result = this._tenantManagementCommunication.getPromise<API.DiarySearchResult[]>(
      { route: CommonApiRoutes.GetDiaryEventByDate, uriParams: { date: diaryDate } }, false);
  return result;
}
public SearchDiary(filter: DiarySearchFilter): Promise<API.DiarySearchResult[]> {
  const result = this._tenantManagementCommunication.putPromise<API.DiarySearchResult[]>(
      { route: CommonApiRoutes.SearchDiary, body : filter }, false);
  return result;
}
public GetDiaryCount(startDate : string,endDate : string): Promise<CalenderCount[]> {
  const result = this._tenantManagementCommunication.getPromise<CalenderCount[]>(
      { route: CommonApiRoutes.GetDiaryCount,   uriParams: { startDate, endDate} }, false);
  return result;
}

public GetPastDiaryEventYears(): Promise<string[]> {
  const result = this._tenantManagementCommunication.getPromise<string[]>(
      { route: CommonApiRoutes.GetPastDiaryEventYears }, false);
  return result;
}

}
